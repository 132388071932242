import { CreateBulkEnrollmentApiParams, CreateBulkEnrollmentServiceParams } from './contracts'

export function createBulkEnrollmentApiSanitizer(
  enrollments: CreateBulkEnrollmentServiceParams['enrollments']
): CreateBulkEnrollmentApiParams['enrollments'] {
  return enrollments.map((enrollment) => ({
    teacher_id: enrollment.teacherId,
    course_id: enrollment.courseId,
    student_id: enrollment.studentId
  }))
}
