import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { WrappedLoader } from '@/common/components/WrappedLoader'
import { i18n } from '@/common/i18n'
import { Footer } from '@/common/layouts/Footer'
import { useAuth } from '@/modules/hub/auth/contexts'
import { usePermissionRole } from '@/modules/hub/security-configuration/hooks'

export function ProfileDetails(): JSX.Element {
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const navigate = useNavigate()

  const { checkPermissionRole, isLoading } = usePermissionRole()

  return (
    <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
      <Div css={{ display: 'flex', flex: 1, padding: '$lg', '@sm': { padding: '$md' } }}>
        {!profile || isLoading ? (
          <WrappedLoader />
        ) : (
          <Grid spacing="$lg">
            <Grid xl={5} md={6} sm={12}>
              <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                <Div
                  css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.profiles.pages.details.myData}
                  </Typography>
                  {checkPermissionRole() && (
                    <Button
                      onClick={() => navigate('/settings/edit-profile')}
                      LeadingIcon={<EditIcon size={18} />}
                      variant="filled"
                      data-testid="edit"
                    >
                      {i18n().modules.hub.profiles.pages.details.edit}
                    </Button>
                  )}
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '$md',
                    flexDirection: 'column'
                  }}
                >
                  {profile.picture ? (
                    <Image
                      alt={i18n().modules.hub.profiles.pages.details.pictureAlt}
                      FallbackImage={() => (
                        <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                          <PersonIcon size={96} fill="$on-primary-container" />
                        </IconWrapper>
                      )}
                      css={{
                        borderRadius: '$full',
                        overflow: 'hidden',
                        objectFit: 'cover',
                        width: '160px',
                        height: '160px'
                      }}
                      src={profile.picture}
                    />
                  ) : (
                    <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                      <PersonIcon size={96} fill="$on-primary-container" />
                    </IconWrapper>
                  )}

                  <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                    {profile.name}
                  </Typography>
                </Div>
                <Grid spacing="$md">
                  <Grid xl={12}>
                    <Typography
                      variant="titleSmall"
                      css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.profiles.pages.details.organization}
                    </Typography>
                    <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                      {profile.organization.name}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} md={6}>
                    <Typography
                      variant="titleSmall"
                      css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.profiles.pages.details.logedProfile}
                    </Typography>
                    <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                      {profile.code}
                    </Typography>
                  </Grid>
                  {profile.phoneFormatted && (
                    <Grid xs={12} sm={6} md={6}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.profiles.pages.details.phone}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {profile.phoneFormatted}
                      </Typography>
                    </Grid>
                  )}

                  <Grid xs={12} sm={6} md={6}>
                    <Typography
                      variant="titleSmall"
                      css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.profiles.pages.details.birthday}
                    </Typography>
                    <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                      {profile.birthdayFormatted}
                    </Typography>
                  </Grid>

                  {profile.cpfFormatted && (
                    <Grid xs={12} sm={6} md={6}>
                      <Typography
                        variant="titleSmall"
                        css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                      >
                        {i18n().modules.hub.profiles.pages.details.cpf}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {profile.cpfFormatted}
                      </Typography>
                    </Grid>
                  )}
                  {profile.genderFormatted && (
                    <Grid xs={12} sm={6} md={6}>
                      <Typography variant="titleSmall" css={{ color: '$on-surface-variant' }}>
                        {i18n().modules.hub.profiles.pages.details.gender}
                      </Typography>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {profile.genderFormatted}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </BaseCard>
            </Grid>
            <Grid md={6} sm={12} xs={12} xl={5} lg={5}>
              <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.profiles.pages.details.infoAccess}
                </Typography>
                <Div css={{ gap: '$4xs' }}>
                  <Typography
                    variant="titleSmall"
                    css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                  >
                    {i18n().modules.hub.profiles.pages.details.login}
                  </Typography>
                  <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                    {profile.login}
                  </Typography>
                </Div>
                {profile.email && (
                  <Div css={{ gap: '$4xs' }}>
                    <Typography
                      variant="titleSmall"
                      css={{ marginBottom: '$4xs', color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.profiles.pages.details.email}
                    </Typography>
                    <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                      {profile.email}
                    </Typography>
                  </Div>
                )}
              </BaseCard>
            </Grid>
          </Grid>
        )}
      </Div>
      {breakpoint === Breakpoint.xs && <Footer />}
    </Div>
  )
}
