export function validateCpf(cpf?: string): boolean {
  if (!cpf?.length) {
    return true
  }

  const currentCpf = cpf.replace(/[^\d]+/g, '')

  if (currentCpf.length !== 11) {
    return false
  }

  if (/^(\d)\1+$/.test(currentCpf)) {
    return false
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(currentCpf.charAt(i)) * (10 - i)
  }
  let remainder = 11 - (sum % 11)
  const firstDigit = remainder > 9 ? 0 : remainder

  if (parseInt(currentCpf.charAt(9)) !== firstDigit) {
    return false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(currentCpf.charAt(i)) * (11 - i)
  }
  remainder = 11 - (sum % 11)
  const secondDigit = remainder > 9 ? 0 : remainder

  if (parseInt(currentCpf.charAt(10)) !== secondDigit) {
    return false
  }

  return true
}
