import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Main } from '@positivote/design-system/components/Main'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptySearch } from '@/common/components/EmptySearch'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import {
  currentDatePtFormatted,
  formatPtBrDateToIsoString,
  getClientTimezone,
  getOldDatePtFormatted
} from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { DeviceDetailsForm } from '@/modules/monitoora/contracts'
import { useShowDevice, useShowMetabaseDeviceDetails } from '@/modules/monitoora/hooks'
import { deviceDetailsFormSanitizer } from '@/modules/monitoora/sanitizers'
import { devicesDetailsSchema } from '@/modules/monitoora/validations'

export function DeviceDetails(): JSX.Element {
  const navigate = useNavigate()
  const params = useParams()
  const daysBefore = 7
  const initialDate = getOldDatePtFormatted(daysBefore)
  const finalDate = currentDatePtFormatted()
  const [emptySearch, setEmptySearch] = useState(false)
  const [deviceDetailsFilter, setDeviceDetailsFilter] = useState<Omit<DeviceDetailsForm, 'period'>>(
    {
      finalDate,
      initialDate
    }
  )

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const { data: device, isLoading: isLoadingShowDevice } = useShowDevice({
    model: {
      serialNumber: params.serialNumber ?? ''
    },
    queryOptions: {
      enabled: !!params.serialNumber
    },
    onError: () => {
      setEmptySearch(true)
    }
  })

  const isEnabledDeviceDetails = !!device?.[0].deviceId
  const { data: embeddedDeviceDetails, isLoading: isLoadingEmbeddedDeviceDetails } =
    useShowMetabaseDeviceDetails({
      model: {
        payload: {
          resource: { dashboard: Number(import.meta.env.VITE_METABASE_DEVICE_DETAILS_DASH_ID) },
          params: {
            dateFrom: formatPtBrDateToIsoString(deviceDetailsFilter.initialDate),
            dateTo: formatPtBrDateToIsoString(deviceDetailsFilter.finalDate),
            deviceId: [device?.[0].deviceId as unknown as string],
            difTime: getClientTimezone()
          }
        }
      },
      queryOptions: {
        enabled: isEnabledDeviceDetails
      }
    })

  const isLoading =
    (isLoadingEmbeddedDeviceDetails && isEnabledDeviceDetails) || isLoadingShowDevice

  const breadcrumbItems = [
    {
      label: i18n().modules.monitoora.pages.deviceDetails.breadcrumbs.devices,
      onClick: () => navigate(-1)
    },
    {
      label: i18n().modules.monitoora.pages.deviceDetails.breadcrumbs.infoDevices(
        params.serialNumber ?? ''
      )
    }
  ]

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<DeviceDetailsForm>({
    mode: 'onSubmit',
    defaultValues: {
      period: 7,
      initialDate: deviceDetailsFilter.initialDate,
      finalDate: deviceDetailsFilter.finalDate
    },
    resolver: async (values, ...args) =>
      yupResolver(devicesDetailsSchema)(deviceDetailsFormSanitizer(values), ...args)
  })

  function handleFilter(data: Omit<DeviceDetailsForm, 'period'>): void {
    setDeviceDetailsFilter(data)
  }
  const selectPeriod = [
    {
      key: 7,
      value: i18n().modules.monitoora.pages.dashboard.devices.selectPeriod.lastSevenDays
    },
    {
      key: 31,
      value: i18n().modules.monitoora.pages.dashboard.devices.selectPeriod.lastThirtyDays
    },
    {
      key: 92,
      value: i18n().modules.monitoora.pages.dashboard.devices.selectPeriod.lastThreeMonths
    },
    {
      key: 183,
      value: i18n().modules.monitoora.pages.dashboard.devices.selectPeriod.lastSixMonths
    },
    {
      key: 365,
      value: i18n().modules.monitoora.pages.dashboard.devices.selectPeriod.lastTwelveMonths
    }
  ]

  function handleChangePeriod(value: number): void {
    setValue('initialDate', getOldDatePtFormatted(value))
    setValue('period', value)
  }

  return (
    <Main
      className="dashboard"
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <AppBar
        title={i18n().modules.monitoora.pages.dashboard.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={breadcrumbItems}
      />
      <Div css={{ overflowY: 'auto', flex: 1 }}>
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$lg',
            padding: '$lg',
            minHeight: '100%',
            '@sm': { padding: '$md' }
          }}
        >
          <FormContainer formHandleSubmit={handleSubmit} onSubmit={handleFilter}>
            <Grid spacing="$md" xl={12} css={{ display: 'flex' }}>
              <FormSelect
                variant="outlined"
                name="period"
                control={control}
                label={i18n().modules.monitoora.pages.deviceDetails.inputs.period}
                optionKeyField="key"
                optionTitleField="value"
                options={selectPeriod}
                onChange={(value) => handleChangePeriod(value?.key ?? 0)}
                gridProps={{ xl: 5 }}
              />
              <FormDatePicker
                name="initialDate"
                control={control}
                value={initialDate}
                label={i18n().modules.monitoora.pages.deviceDetails.inputs.initialDate}
                variant="outlined"
                errorText={errors.initialDate?.message}
                gridProps={{ xl: 3 }}
              />
              <FormDatePicker
                name="finalDate"
                control={control}
                value={finalDate}
                label={i18n().modules.monitoora.pages.deviceDetails.inputs.finalDate}
                variant="outlined"
                errorText={errors.finalDate?.message}
                gridProps={{ xl: 3 }}
              />
              <Grid
                xl={1}
                css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Button type="submit" variant="filled" isLoading={isLoading}>
                  {i18n().modules.monitoora.pages.deviceDetails.buttons.filter}
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
          <Divider />
          {isLoading ? (
            <WrappedLoader />
          ) : emptySearch ? (
            <EmptySearch />
          ) : (
            <Iframe
              scrolling="no"
              src={embeddedDeviceDetails?.url}
              css={{
                width: '100%',
                '@xl': {
                  height: '1260px'
                },
                '@lg': {
                  height: '700px'
                }
              }}
            />
          )}
        </Div>
      </Div>
    </Main>
  )
}
