import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { i18n } from '@/common/i18n'
import { ClassroomFormatted } from '@/modules/hub/classroom/contracts'
import { useListClassroomStudent, useListClassroomTeacher } from '@/modules/hub/classroom/hooks'

import { ListStudent } from './List/Student'
import { ListTeacher } from './List/Teacher'

export function Enrollments(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
    tab: number
  } | null

  const listClassroomStudents = useListClassroomStudent({
    model: {
      classId: locationState?.classroom.id as unknown as string
    },
    queryOptions: {
      enabled: !!locationState?.classroom.id,
      staleTime: 0
    }
  })

  const listClassroomTeachers = useListClassroomTeacher({
    model: {
      classId: locationState?.classroom.id as unknown as string
    },
    queryOptions: {
      enabled: !!locationState?.classroom.id,
      staleTime: 0,
      refetchOnMount: 'always'
    }
  })

  const options = [
    {
      label: `${i18n().modules.hub.classroom.pages.enrollments.list.students} (${listClassroomStudents.data?.total ?? 0})`,
      key: 'students',
      children: <ListStudent />
    },
    {
      label: `${i18n().modules.hub.classroom.pages.enrollments.list.teachers} (${listClassroomTeachers.data?.total ?? 0})`,
      key: 'teacher',
      children: <ListTeacher />
    }
  ]

  useEffect(() => {
    if (!locationState) {
      navigate('/data-management/levels-and-classes/classroom')
    }
  }, [locationState, navigate])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.classroom.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.classroom.pages.enrollments.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate('/data-management')
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.list.appBar.breadcrumbs
                .educationLevelsAndClasses,
            onClick: () => {
              navigate('/data-management/levels-and-classes')
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.list.appBar.breadcrumbs.schoolClasses,
            onClick: () => {
              navigate('/data-management/levels-and-classes/classroom')
            }
          },
          {
            label: locationState?.classroom.name ?? ''
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'scroll'
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '$2xs',
            padding: '$lg',
            '@sm': { padding: '$md' }
          }}
        >
          <Div css={{ display: 'flex', gap: '$md', justifyContent: 'center' }}>
            {locationState?.schoolYear.name && (
              <Chip color="neutral" label={locationState.schoolYear.name} variant="filled" />
            )}
            {locationState?.classroom && (
              <>
                <Chip
                  color="neutral"
                  label={
                    i18n().modules.hub.classroom.fullClassLevel[
                      locationState.classroom.level.code
                    ].split('-')[0]
                  }
                  variant="filled"
                />
                <Chip
                  color="neutral"
                  label={
                    i18n().modules.hub.classroom.fullClassLevel[
                      locationState.classroom.level.code
                    ].split('-')[1]
                  }
                  variant="filled"
                />
              </>
            )}
          </Div>

          <Tabs
            css={{
              '& .Tabs-Content': {
                padding: '$none $none $lg $none'
              },
              '& .Tabs-UL': {
                justifyContent: 'center'
              }
            }}
            options={options}
            currentTab={locationState?.tab}
          />
        </Div>
      </Div>
    </Main>
  )
}
