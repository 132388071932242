import {
  EdtechApplicationApi,
  ListApplicationApiResult
} from '@/modules/hub/applications/contracts/apis'
import { Application, EdtechApplication } from '@/modules/hub/applications/contracts/models'

export function fromApplicationApiSanitizer(
  applications: ListApplicationApiResult['data']
): Application[] {
  return applications.map((application) => ({
    id: application.id,
    code: application.code,
    clientId: application.client_id,
    categoryId: application.category_id,
    loginType: application.login_type,
    shortDescription: application.short_description,
    createdAt: application.created_at,
    name: application.name,
    description: application.short_description,
    iconUrl: application.icon_url,
    compatibleDevices: application.compatible_devices.map((devices) => ({
      name: devices.name,
      compatibleSystems: devices.compatible_systems
    })),
    samlProviderToken: application.signature_methods?.SAML?.provider_token ?? null,
    signatureMethod: application.signature_method,
    signatureMethods: !application.signature_methods?.SAML?.provider_token
      ? null
      : {
          SAML: { providerToken: application.signature_methods.SAML.provider_token }
        }
  }))
}

export function fromEdtechApplicationApiSanitizer(
  applications: EdtechApplicationApi[]
): EdtechApplication[] {
  return applications.map((application) => ({
    ...application,
    clientId: application.client_id,
    loginType: application.login_type,
    iconUrl: application.icon_url,
    launchUrl: application.launch_url,
    launchMethod: application.launch_method,
    edtechId: application.edtech_id,
    edtechName: application.edtech_name,
    edtechCode: application.edtech_code,
    classificationId: application.classification_id,
    categoryId: application.category_id
  }))
}
