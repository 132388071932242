import { Colors } from '@positivote/design-system/tokens'

export const theme = {
  default: 1,
  universe: 2
}

export const whiteLabelColorPalette: Array<keyof Colors> = [
  'sysPrimary',
  'deepPurple',
  'indigo',
  'cyan',
  'pink',
  'deepOrange',
  'orange',
  'amber'
]
