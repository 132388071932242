import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { StatusCodes } from '@/common/contracts'
import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { ListClassroomStudentHookResult } from '@/modules/hub/classroom/contracts'

import {
  AvailableUserHookParams,
  AvailableUserHookResult,
  BulkRemoveUserHookParams,
  CreateUserHookParams,
  CreateUserHookResult,
  ListQrCodeUsersHookParams,
  ListShortStudentHookParams,
  ListShortStudentHookResult,
  ListTeacherHookParams,
  ListTeacherHookResult,
  ListUserHookParams,
  ListUserHookResult,
  ShowUserHookResult,
  UpdateStudentConsentHookParams,
  UpdateUserHookParams,
  UpdateUserHookResult,
  showUserHookParams
} from './contracts'
import {
  availableUserService,
  bulkRemoveUsersService,
  createUserService,
  listQrCodeUsersService,
  listShortStudent,
  listTeacher,
  listUserService,
  showUserService,
  updateStudentConsentService,
  updateUserService
} from './services'

const hookKey = 'users'

export function useListUser({
  model,
  ignoreLogout,
  queryOptions,
  onSuccess,
  onError
}: ListUserHookParams): UseQueryResult<ListUserHookResult, ApplicationException> {
  const { handleError } = useErrorHandler({ ignoreLogout })

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listUsers = await listUserService(model)
        onSuccess?.(listUsers)
        return listUsers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListShortStudent({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListShortStudentHookParams): UseQueryResult<ListShortStudentHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  return useQuery({
    queryKey: [hookKey, 'listShortStudent', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const shortStudent = await listShortStudent(model)
        onSuccess?.(shortStudent)
        return shortStudent
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateStudentConsent(): UseMutationResult<
  void,
  ApplicationException,
  UpdateStudentConsentHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [hookKey, 'updateConsent'],
    mutationFn: async ({
      model,
      onSuccess,
      onError,
      page,
      perPage,
      classId
    }: UpdateStudentConsentHookParams) => {
      try {
        await updateStudentConsentService(model)
        queryClient.setQueryData(
          [
            'classroom',
            'listStudent',
            {
              classId,
              page,
              perPage
            }
          ],
          (oldProfiles: ListClassroomStudentHookResult | undefined) =>
            oldProfiles && {
              ...oldProfiles,
              registers: oldProfiles.registers.map((profile) =>
                Number(profile.id) === model.studentId
                  ? { ...profile, allowedImageUseOnEdtech: model.consent }
                  : profile
              )
            }
        )
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListTeacher({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListTeacherHookParams): UseQueryResult<ListTeacherHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listTeacher', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const teachers = await listTeacher(model)
        onSuccess?.(teachers)
        return teachers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useCreateUser(): UseMutationResult<
  CreateUserHookResult,
  ApplicationException,
  CreateUserHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateUserHookParams) => {
      try {
        const createDiscipline = await createUserService(model)
        onSuccess?.(createDiscipline)
        addAlertMessage({
          severity: 'success',
          subTitle: 'Usuário criado com sucesso!'
        })
        queryClient.setQueryData(
          [hookKey, 'show', { userId: createDiscipline.id }],
          () => createDiscipline
        )

        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              orgId: model.institutionId,
              page: 1,
              perPage: 25
            }
          ],
          (oldData: ListUserHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: [createDiscipline, ...oldData.registers].sort((a, b) =>
                a.firstName.localeCompare(b.firstName)
              )
            }
        )
        return createDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useBulkRemoveUser(): UseMutationResult<
  void,
  ApplicationException,
  BulkRemoveUserHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'bulkRemove'],
    mutationFn: async ({
      model,
      onSuccess,
      onError,
      page,
      perPage,
      institutionId
    }: BulkRemoveUserHookParams) => {
      try {
        await bulkRemoveUsersService(model)

        addAlertMessage({
          severity: 'success',
          subTitle: 'Usuário(s) removido com sucesso!'
        })
        queryClient.setQueryData(
          [hookKey, 'list', { institutionId, page, perPage }],
          (oldData: ListUserHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => !model.ids.includes(register.id))
            }
        )
        void queryClient.invalidateQueries()
        onSuccess?.(null)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowUser({
  model,
  queryOptions,
  onSuccess,
  onError
}: showUserHookParams): UseQueryResult<ShowUserHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showDiscipline = await showUserService(model)
        onSuccess?.(showDiscipline)
        return showDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        if (parsedError.statusCode === StatusCodes.NOT_FOUND) {
          addAlertMessage({
            severity: 'warning',
            subTitle: i18n().common.exceptions.notFound
          })
        } else {
          handleError({ error: parsedError })
        }
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useAvailableUser({
  model,
  queryOptions,
  onSuccess,
  onError
}: AvailableUserHookParams): UseQueryResult<AvailableUserHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'availableUser', model],
    queryFn: async () => {
      try {
        const listBaseDiscipline = await availableUserService(model)
        onSuccess?.(listBaseDiscipline)
        return listBaseDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateUser(): UseMutationResult<
  UpdateUserHookResult,
  ApplicationException,
  UpdateUserHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateUserHookParams) => {
      try {
        const updateSchoolYear = await updateUserService(model)
        onSuccess?.(updateSchoolYear)
        addAlertMessage({
          severity: 'success',
          subTitle: 'Usuário atualizado com sucesso!'
        })

        queryClient.setQueryData(
          [hookKey, 'show', { userId: updateSchoolYear.id }],
          () => updateSchoolYear
        )

        void queryClient.invalidateQueries()

        return updateSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListQrCodeUsers(): UseMutationResult<
  void,
  ApplicationException,
  ListQrCodeUsersHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [hookKey, 'listQrCodeUsers'],
    mutationFn: async ({ model, onSuccess, onError }: ListQrCodeUsersHookParams) => {
      try {
        const qrCodeUsers = await listQrCodeUsersService(model)
        onSuccess?.(qrCodeUsers)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
