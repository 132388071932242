import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { DashboardIcon } from '@positivote/design-system/icons/Dashboard'
import { LinkIcon } from '@positivote/design-system/icons/Link'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SecurityIcon } from '@positivote/design-system/icons/Security'
import { StairsIcon } from '@positivote/design-system/icons/Stairs'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { UploadIcon } from '@/fixme/icons/Upload'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'

export function DataManagementMenu(): JSX.Element {
  changePageTitle(i18n().modules.hub.dataManagement.pages.menu.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()

  const { profile, permissions } = useAuth()

  function handleOpen(target: string): void {
    navigate(target)
  }
  const listSchoolYear = useListSchoolYear({
    model: {
      perPage: 100
    },
    queryOptions: {
      staleTime: 0
    }
  })

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  const allButtonOptions = [
    {
      key: 'my-imports',
      icon: <UploadIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.myImports,
      description: i18n().modules.hub.dataManagement.pages.menu.option.myImportsDescription,
      route: isSchool ? 'my-imports' : 'my-imports/selected-school',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      disabled: !listSchoolYear.data?.registers.length
    },
    {
      key: 'my-users',
      icon: <PersonIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.myUsers,
      description: i18n().modules.hub.dataManagement.pages.menu.option.myUsersDescription,
      route: 'my-users',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      disabled: false
    },
    {
      key: 'school',
      icon: <StairsIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.educationLevelsAndClasses,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.educationLevelsAndClassesDescription,
      route: 'levels-and-classes',
      roles: ['administrador', 'instituicao'],
      permissions: ['onboarding'],
      disabled: false
    },
    {
      key: 'access-configuration',
      icon: <LinkIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.accessConfiguration,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.accessConfigurationDescription,
      route: 'access-configuration',
      roles: ['administrador', 'instituicao'],
      permissions: ['app-access'],
      disabled: false
    },
    {
      key: 'security-configuration',
      icon: <SecurityIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.securityConfiguration,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.securityConfigurationDescription,
      route: 'security-configuration',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      disabled: false
    },
    {
      key: 'onboarding',
      icon: <DashboardIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.onboarding,
      description: i18n().modules.hub.dataManagement.pages.menu.option.onboardingDescription,
      route: 'onboarding',
      roles: [],
      permissions: ['onboarding'],
      disabled: false
    }
  ]

  const filteredOptions = allButtonOptions.filter((buttonOption) => {
    return (
      !!profile &&
      !!permissions &&
      (buttonOption.roles.includes(profile.role.code) ||
        permissions.some((permission) => buttonOption.permissions.includes(permission.key)))
    )
  })

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            gap: '$md',
            overflowY: 'auto',
            '@sm': { padding: '$md' }
          }}
        >
          {listSchoolYear.isFetching ? (
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                padding: '$lg',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader data-testid="Loader-Container-ServiceMappingList" size={80} />
            </Div>
          ) : (
            filteredOptions.map((buttonOption) =>
              buttonOption.disabled ? (
                <Tooltip
                  key={buttonOption.key}
                  data-testid="Tooltip-amountToBeInvoiced"
                  placement="auto"
                  arrow
                  label={i18n().modules.hub.dataManagement.pages.levelsAndClasses.tooltipImport}
                >
                  <Div>
                    <BaseCardButton
                      disabled={buttonOption.disabled}
                      key={buttonOption.key}
                      data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
                      onClick={() => handleOpen(buttonOption.route)}
                      css={{
                        width: '80%',
                        '& .BaseCardButton-StateLayer': {
                          padding: '$md',
                          gap: '$md',
                          justifyContent: 'flex-start'
                        }
                      }}
                    >
                      <>
                        <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                          {buttonOption.icon}
                        </IconWrapper>
                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                          }}
                        >
                          <Typography
                            variant="bodyLarge"
                            css={{ textAlign: 'left', color: '$on-surface' }}
                          >
                            {buttonOption.label}
                          </Typography>
                          <Typography
                            variant="bodyMedium"
                            css={{ textAlign: 'left', color: '$on-surface-variant' }}
                          >
                            {buttonOption.description}
                          </Typography>
                        </Div>
                      </>
                    </BaseCardButton>
                  </Div>
                </Tooltip>
              ) : (
                <BaseCardButton
                  disabled={buttonOption.disabled}
                  key={buttonOption.key}
                  data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
                  onClick={() => handleOpen(buttonOption.route)}
                  css={{
                    width: '80%',
                    '& .BaseCardButton-StateLayer': {
                      padding: '$md',
                      gap: '$md',
                      justifyContent: 'flex-start'
                    }
                  }}
                >
                  <>
                    <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                      {buttonOption.icon}
                    </IconWrapper>
                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        variant="bodyLarge"
                        css={{ textAlign: 'left', color: '$on-surface' }}
                      >
                        {buttonOption.label}
                      </Typography>
                      <Typography
                        variant="bodyMedium"
                        css={{ textAlign: 'left', color: '$on-surface-variant' }}
                      >
                        {buttonOption.description}
                      </Typography>
                    </Div>
                  </>
                </BaseCardButton>
              )
            )
          )}
        </Div>
      )}
    </Main>
  )
}
