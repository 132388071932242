import { Circle, ClipPath, Path, Rect, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function MenuWhiteLabel({ fill, css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="120"
      height="192"
      viewBox="0 0 120 192"
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
      css={{ fill, ...css }}
      {...restProps}
    >
      <Rect width="120" height="192" rx="8" css={{ fill: '$surface-2' }} />
      <Path
        d="M36.7848 21.3849C36.794 21.3517 36.7985 21.2911 36.7322 21.2464C35.5727 20.4727 34.1625 20.0801 32.5406 20.0801C30.5982 20.0801 28.9832 20.6581 27.7413 21.797C26.4857 22.9473 25.8493 24.409 25.8493 26.1407C25.8493 27.8725 26.3678 29.3227 27.3911 30.275C27.9347 30.781 28.6696 31.3052 29.575 31.8306C29.6322 31.8637 29.6814 31.8431 29.7054 31.8283C29.7295 31.8134 29.7707 31.7768 29.765 31.7092C29.686 30.6539 29.67 29.6867 29.7192 28.834C29.7215 28.7974 29.7043 28.7607 29.6757 28.7344C28.8882 28.0362 28.5814 27.2888 28.5814 26.0698C28.5814 24.0919 30.2686 22.6005 32.5063 22.6005C32.7283 22.6005 32.9492 22.6119 33.161 22.6348C33.193 22.6383 33.2228 22.6325 33.248 22.6165C33.2995 22.5856 33.3521 22.5536 33.4059 22.5238C34.4716 21.9092 35.5704 21.5566 36.6715 21.4776C36.7505 21.4719 36.7768 21.417 36.7859 21.3838L36.7848 21.3849Z"
        css={{ fill: '#A970AF' }}
      />
      <Path
        d="M37.839 42.3632C39.0946 41.2129 39.731 39.7512 39.731 38.0194C39.731 36.2876 39.2125 34.8374 38.1892 33.8851C37.6455 33.3792 36.9107 32.855 36.0053 32.3296C35.9481 32.2964 35.8989 32.317 35.8748 32.3319C35.8508 32.3468 35.8096 32.3834 35.8153 32.4509C35.8943 33.5063 35.9103 34.4734 35.8611 35.3262C35.8588 35.3628 35.876 35.3994 35.9046 35.4257C36.6921 36.124 36.9988 36.8714 36.9988 38.0904C36.9988 40.0683 35.3117 41.5597 33.074 41.5597C32.852 41.5597 32.631 41.5482 32.4193 41.5253C32.4136 41.5253 32.4078 41.5253 32.4021 41.5253C32.3769 41.5253 32.3529 41.5322 32.3323 41.5448C32.2808 41.5757 32.2281 41.6066 32.1744 41.6375C31.1099 42.2521 30.0111 42.6047 28.9088 42.6837C28.8298 42.6894 28.8035 42.7443 28.7943 42.7775C28.7852 42.8107 28.7806 42.8714 28.847 42.916C30.0053 43.6898 31.4155 44.0824 33.0374 44.0824C34.9798 44.0824 36.5948 43.5043 37.8367 42.3655L37.839 42.3632Z"
        css={{ fill: '#A970AF' }}
      />
      <Path
        d="M21.5307 33.9675C21.4975 33.9767 21.4426 34.003 21.4369 34.0831C21.3464 35.4738 21.7116 36.8908 22.5219 38.2953C23.4926 39.9767 24.8008 41.0869 26.4079 41.594C28.032 42.1056 29.6162 41.9259 31.1156 41.0606C32.6665 40.1655 33.612 39.0209 33.9256 37.6589C34.0927 36.9343 34.1774 36.037 34.1808 34.9897C34.1808 34.9233 34.1385 34.8912 34.1144 34.8786C34.0893 34.8649 34.0378 34.8477 33.9817 34.8866C33.1072 35.483 32.2774 35.9797 31.5151 36.3643C31.483 36.3803 31.459 36.4135 31.451 36.4525C31.2404 37.4837 30.7459 38.1224 29.6906 38.7325C27.9771 39.7214 25.8424 39.0061 24.723 37.0683C24.612 36.876 24.5112 36.6791 24.4254 36.4845C24.4128 36.4547 24.3922 36.433 24.367 36.4181C24.3144 36.3884 24.2606 36.3586 24.2079 36.3277C23.1423 35.713 22.2884 34.937 21.6692 34.0225C21.6246 33.9561 21.5639 33.9618 21.5307 33.971V33.9675Z"
        css={{ fill: '#EF413D' }}
      />
      <Path
        d="M39.1713 22.5662C37.5471 22.0545 35.963 22.2342 34.4636 23.0995C32.9126 23.9946 31.9672 25.1392 31.6536 26.5013C31.4864 27.2258 31.4017 28.1232 31.3983 29.1705C31.3983 29.2369 31.4407 29.2689 31.4647 29.2815C31.4899 29.2953 31.5414 29.3124 31.5975 29.2735C32.4719 28.6772 33.3018 28.1804 34.0641 27.7958C34.0961 27.7798 34.1202 27.7466 34.1282 27.7077C34.3388 26.6764 34.8333 26.0377 35.8886 25.4277C37.602 24.4387 39.7367 25.1541 40.8561 27.0919C40.9672 27.2842 41.0679 27.4811 41.1537 27.6757C41.156 27.6814 41.1583 27.686 41.1618 27.6905C41.1743 27.7123 41.1915 27.7306 41.2133 27.742C41.2659 27.7718 41.3197 27.8016 41.3724 27.8325C42.4368 28.4471 43.2919 29.2232 43.9111 30.1377C43.9557 30.2041 44.0164 30.1984 44.0496 30.1903C44.0828 30.1812 44.1377 30.1549 44.1434 30.0747C44.2339 28.684 43.8687 27.267 43.0584 25.8626C42.0877 24.18 40.7795 23.0709 39.1724 22.5639L39.1713 22.5662Z"
        css={{ fill: '#EF413D' }}
      />
      <Path
        d="M40.0549 40.8866C40.079 40.9107 40.1305 40.945 40.2014 40.9095C41.4513 40.2926 42.4952 39.2682 43.3067 37.8637C44.2774 36.1823 44.5853 34.494 44.2201 32.8492C43.8516 31.1861 42.9038 29.9042 41.4033 29.0389C39.8523 28.1438 38.3884 27.8977 37.0526 28.3063C36.3418 28.5238 35.5212 28.8992 34.6135 29.42C34.5563 29.4532 34.5494 29.5059 34.5505 29.5333C34.5505 29.562 34.562 29.6146 34.6238 29.6444C35.5772 30.1034 36.422 30.5738 37.1362 31.0419C37.166 31.0614 37.2072 31.066 37.2438 31.0534C38.2419 30.7203 39.0431 30.8279 40.0984 31.438C41.8119 32.4269 42.2594 34.6337 41.1412 36.5726C41.0301 36.7649 40.9099 36.9504 40.784 37.122C40.7646 37.1484 40.7554 37.177 40.7566 37.2056C40.7577 37.2663 40.7589 37.3269 40.7589 37.3887C40.7589 38.6192 40.5139 39.7466 40.0309 40.7401C39.9966 40.8111 40.0309 40.8626 40.0549 40.8855V40.8866Z"
        css={{ fill: '#FCB116' }}
      />
      <Path
        d="M21.3602 31.3109C21.7287 32.974 22.6765 34.256 24.177 35.1213C25.728 36.0164 27.1919 36.2625 28.5277 35.8538C29.2384 35.6364 30.0591 35.2609 30.9668 34.7401C31.024 34.7069 31.0309 34.6543 31.0297 34.6268C31.0297 34.5982 31.0183 34.5456 30.9565 34.5158C30.003 34.0568 29.1583 33.5864 28.4441 33.1182C28.4143 33.0988 28.3731 33.0942 28.3365 33.1068C27.3384 33.4399 26.5372 33.3323 25.4819 32.7222C23.7684 31.7333 23.3209 29.5265 24.4391 27.5875C24.5502 27.3952 24.6703 27.2098 24.7963 27.0381C24.7997 27.0335 24.8031 27.029 24.8054 27.0244C24.818 27.0026 24.8249 26.9786 24.8237 26.9546C24.8226 26.8939 24.8214 26.8332 24.8214 26.7714C24.8214 25.5421 25.0664 24.4147 25.5483 23.42C25.5826 23.3491 25.5483 23.2976 25.5242 23.2747C25.5002 23.2506 25.4487 23.2163 25.3777 23.2518C24.1278 23.8687 23.0839 24.8931 22.2724 26.2976C21.3018 27.9778 20.995 29.665 21.3602 31.3109Z"
        css={{ fill: '#FCB116' }}
      />
      <Path d="M50.575 31.6329C51.5376 31.6329 52.1854 31.095 52.1854 30.2926C52.1854 29.4902 51.6978 29.1606 50.797 28.7886C50.0267 28.475 49.8149 28.285 49.8149 27.905C49.8149 27.5249 50.1057 27.2983 50.5383 27.2983C50.884 27.2983 51.1335 27.4402 51.3006 27.7321C51.3235 27.7722 51.3739 27.7882 51.4162 27.7665L51.786 27.5776C51.8283 27.5559 51.8455 27.5032 51.8237 27.4597C51.5673 26.9561 51.1312 26.6997 50.5292 26.6997C49.7531 26.6997 49.1889 27.2067 49.1889 27.9038C49.1889 28.6009 49.6135 28.942 50.4502 29.3002C50.8531 29.4685 51.1576 29.6287 51.3304 29.7661C51.4815 29.8851 51.5582 30.0625 51.5582 30.2926C51.5582 30.7573 51.1896 31.0343 50.5738 31.0343C49.958 31.0343 49.5288 30.7642 49.3491 30.272C49.3411 30.2502 49.3251 30.2319 49.3033 30.2228C49.2827 30.2125 49.2575 30.2125 49.2358 30.2205L48.8569 30.3647C48.8134 30.3807 48.7905 30.4288 48.8043 30.4723C49.0309 31.1991 49.6913 31.6329 50.5727 31.6329H50.575Z" />
      <Path d="M54.5124 31.6055C55.1751 31.6055 55.7245 31.3468 56.0187 30.9118C56.0816 31.2827 56.3186 31.6055 56.765 31.6055C56.9412 31.6055 57.0729 31.5826 57.1793 31.5345C57.2102 31.5208 57.2308 31.4887 57.2308 31.4544V31.103C57.2308 31.0755 57.2182 31.0492 57.1965 31.0331C57.1747 31.016 57.1461 31.0114 57.1198 31.0194C57.0637 31.0354 56.9893 31.0435 56.892 31.0435C56.8119 31.0435 56.6013 31.0435 56.6013 30.5719V26.9046C56.6013 26.8565 56.5624 26.8176 56.5143 26.8176H56.0633C56.0153 26.8176 55.9763 26.8565 55.9763 26.9046V29.4456C55.9763 30.3945 55.4452 31.0068 54.6223 31.0068C53.8485 31.0068 53.3678 30.4746 53.3678 29.6161V26.9046C53.3678 26.8565 53.3289 26.8176 53.2808 26.8176H52.8298C52.7818 26.8176 52.7428 26.8565 52.7428 26.9046V29.7066C52.7428 30.8603 53.4388 31.6055 54.5147 31.6055H54.5124Z" />
      <Path d="M58.9363 31.0171C58.855 31.0263 58.768 31.0343 58.6936 31.0343C58.499 31.0343 58.412 30.9759 58.412 30.6257V26.9046C58.412 26.8565 58.3731 26.8176 58.3251 26.8176H57.8741C57.826 26.8176 57.7871 26.8565 57.7871 26.9046V30.7069C57.7871 31.4647 58.2163 31.6238 58.5769 31.6238C58.7383 31.6238 58.8642 31.6055 58.9763 31.5642C59.0107 31.5517 59.0336 31.5185 59.0336 31.4818V31.103C59.0336 31.0778 59.0233 31.0549 59.005 31.0377C58.9866 31.0206 58.9626 31.0125 58.9374 31.016L58.9363 31.0171Z" />
      <Path d="M64.19 31.6421C64.9454 31.6421 65.5681 31.3651 66.0397 30.8191C66.0694 30.7836 66.0671 30.731 66.0339 30.6989L65.7363 30.4105C65.7192 30.3945 65.6963 30.3819 65.6734 30.3853C65.6505 30.3853 65.6276 30.3956 65.6116 30.4116C65.2041 30.8363 64.7394 31.0423 64.1877 31.0423C63.6955 31.0423 63.2858 30.8786 62.971 30.5547C62.6688 30.2445 62.5097 29.8565 62.4845 29.37H66.2686C66.3121 29.37 66.3487 29.338 66.3556 29.2956C66.3659 29.227 66.3659 29.1526 66.3659 29.0576C66.3659 28.3914 66.161 27.8443 65.7398 27.3865C65.3312 26.9309 64.8024 26.6997 64.1705 26.6997C63.5387 26.6997 62.9595 26.9366 62.5212 27.4025C62.0862 27.8557 61.8665 28.4555 61.8665 29.1835V29.2109C61.8665 29.9286 62.0771 30.5021 62.5132 30.9656C62.9584 31.42 63.5055 31.6409 64.1888 31.6409L64.19 31.6421ZM62.5086 28.7909C62.5441 28.3868 62.7112 28.0412 63.0076 27.7619C63.3361 27.4505 63.7173 27.2995 64.1728 27.2995C64.6284 27.2995 64.9626 27.4494 65.2636 27.7596C65.5406 28.0446 65.6951 28.3914 65.7215 28.792H62.5086V28.7909Z" />
      <Path d="M61.0183 31.6512C61.1808 31.6512 61.3399 31.6318 61.4899 31.594C61.5288 31.5848 61.5563 31.5494 61.5563 31.5093V31.1041C61.5563 31.0778 61.5448 31.0526 61.5242 31.0366C61.5036 31.0206 61.4773 31.0137 61.4521 31.0183C61.3102 31.0469 61.1545 31.0618 61.0011 31.0618C60.5776 31.0618 60.2961 30.913 60.2961 30.185V27.4162H61.4704C61.5185 27.4162 61.5574 27.3773 61.5574 27.3292V26.9057C61.5574 26.8576 61.5185 26.8187 61.4704 26.8187H60.2961V25.1671C60.2961 25.119 60.2571 25.0801 60.2091 25.0801H59.7581C59.71 25.0801 59.6711 25.119 59.6711 25.1671V30.1861C59.6711 31.1728 60.1129 31.6524 61.0194 31.6524L61.0183 31.6512Z" />
      <Path d="M57.8466 26.4112C57.8581 26.417 57.8707 26.4204 57.8832 26.4204C57.8993 26.4204 57.9153 26.4158 57.929 26.4055L58.4086 26.061C58.4292 26.0461 58.4418 26.0221 58.4418 25.9969V25.1659C58.4418 25.1224 58.4063 25.0869 58.3628 25.0869H57.8832C57.8397 25.0869 57.8043 25.1224 57.8043 25.1659V26.3414C57.8043 26.3712 57.8203 26.3987 57.8466 26.4112Z" />
      <Path d="M48.866 36.7138C48.866 35.9721 49.0858 35.3769 49.5162 34.9282C49.9465 34.4795 50.5051 34.2506 51.1735 34.2506C51.8054 34.2506 52.309 34.4704 52.7027 34.9191C53.0965 35.3586 53.2979 35.8988 53.2979 36.549C53.2979 36.6497 53.2979 36.7504 53.2888 36.842H49.5436C49.5802 37.8584 50.2304 38.5085 51.1918 38.5085C51.8237 38.5085 52.1991 38.3071 52.6112 37.8675L53.0598 38.3071C52.5745 38.8473 51.9519 39.122 51.1918 39.122C50.5051 39.122 49.9374 38.9023 49.507 38.4719C49.0766 38.0415 48.866 37.4647 48.866 36.7504V36.7138ZM52.5745 36.2468C52.5745 35.8714 52.4372 35.5509 52.1625 35.2853C51.8878 35.0198 51.5581 34.8824 51.1735 34.8824C50.7523 34.8824 50.386 35.0198 50.0839 35.2853C49.7817 35.5509 49.6169 35.8714 49.5894 36.2468H52.5745Z" />
      <Path d="M57.8214 35.0747V32.6207H58.499V37.9683C58.499 38.3712 58.5905 38.5452 58.8469 38.5452C58.9202 38.5452 59.0026 38.536 59.085 38.5268V39.0396C58.9751 39.0854 58.8286 39.1129 58.6546 39.1129C58.4532 39.1129 58.2884 39.0671 58.1693 38.9847C57.9954 38.8473 57.8855 38.6276 57.858 38.3254C57.51 38.8656 56.9515 39.1403 56.1823 39.1403C55.5322 39.1403 54.9736 38.9114 54.5249 38.4444C54.0762 37.9774 53.8473 37.3914 53.8473 36.6955C53.8473 35.9996 54.0762 35.4227 54.5249 34.9557C54.9736 34.4887 55.5322 34.2506 56.1823 34.2506C57.0156 34.2506 57.5558 34.6077 57.8214 35.0747ZM57.4093 37.9957C57.739 37.6478 57.9038 37.2174 57.9038 36.6955C57.9038 36.1735 57.739 35.7432 57.4093 35.4044C57.0888 35.0564 56.6768 34.8824 56.1823 34.8824C55.6878 34.8824 55.3124 35.0564 54.9919 35.4044C54.6806 35.7432 54.5249 36.1735 54.5249 36.6955C54.5249 37.2174 54.6806 37.6478 54.9919 37.9957C55.3124 38.3345 55.7062 38.5085 56.1823 38.5085C56.6585 38.5085 57.0888 38.3345 57.4093 37.9957Z" />
      <Path d="M59.6436 34.388H60.3212V37.1899C60.3212 37.9591 60.7607 38.4444 61.4658 38.4444C62.1709 38.4444 62.7203 37.9133 62.7203 37.1442V34.388H63.3979V37.9316C63.3979 38.3345 63.4894 38.5085 63.7458 38.5085C63.8191 38.5085 63.9015 38.4994 63.9839 38.4902V39.003C63.8832 39.0488 63.7458 39.0762 63.5535 39.0762C63.3063 39.0762 63.114 39.003 62.9858 38.8656C62.8759 38.7466 62.8027 38.6001 62.7752 38.4078C62.4822 38.8565 61.9877 39.0762 61.301 39.0762C60.2846 39.0762 59.6436 38.3895 59.6436 37.3365V34.388Z" />
      <Path d="M68.5532 34.9465L68.1411 35.4227C67.7931 35.0656 67.3445 34.8824 66.8042 34.8824C66.264 34.8824 65.8702 35.0564 65.5314 35.4044C65.1926 35.7432 65.0278 36.1735 65.0278 36.6955C65.0278 37.2174 65.1926 37.6478 65.5314 37.9957C65.8702 38.3345 66.2914 38.5085 66.8042 38.5085C67.3445 38.5085 67.7931 38.3254 68.1411 37.9683L68.5532 38.4444C68.1045 38.9114 67.5184 39.1403 66.8042 39.1403C66.09 39.1403 65.5406 38.9114 65.0644 38.4444C64.5883 37.9774 64.3502 37.3914 64.3502 36.6955C64.3502 35.9996 64.5883 35.4227 65.0644 34.9557C65.5406 34.4887 66.1174 34.2506 66.8042 34.2506C67.491 34.2506 68.1045 34.4795 68.5532 34.9465Z" />
      <Path d="M72.9026 35.0747V34.388H73.5802V37.9683C73.5802 38.3712 73.6718 38.5452 73.9282 38.5452C74.0015 38.5452 74.0839 38.536 74.1663 38.5268V39.0396C74.0564 39.0854 73.9099 39.1129 73.7359 39.1129C73.5345 39.1129 73.3696 39.0671 73.2506 38.9847C73.0766 38.8473 72.9667 38.6276 72.9393 38.3254C72.5913 38.8656 72.0327 39.1403 71.2636 39.1403C70.6134 39.1403 70.0549 38.9114 69.6062 38.4444C69.1575 37.9774 68.9286 37.3914 68.9286 36.6955C68.9286 35.9996 69.1575 35.4227 69.6062 34.9557C70.0549 34.4887 70.6134 34.2506 71.2636 34.2506C72.0968 34.2506 72.6371 34.6077 72.9026 35.0747ZM72.4906 37.9957C72.8202 37.6478 72.985 37.2174 72.985 36.6955C72.985 36.1735 72.8202 35.7432 72.4906 35.4044C72.1701 35.0564 71.758 34.8824 71.2636 34.8824C70.7691 34.8824 70.3937 35.0564 70.0732 35.4044C69.7619 35.7432 69.6062 36.1735 69.6062 36.6955C69.6062 37.2174 69.7619 37.6478 70.0732 37.9957C70.3937 38.3345 70.7874 38.5085 71.2636 38.5085C71.7397 38.5085 72.1701 38.3345 72.4906 37.9957Z" />
      <Path d="M78.644 34.9465L78.2319 35.4227C77.8839 35.0656 77.4353 34.8824 76.895 34.8824C76.3548 34.8824 75.961 35.0564 75.6222 35.4044C75.2834 35.7432 75.1186 36.1735 75.1186 36.6955C75.1186 37.2174 75.2834 37.6478 75.6222 37.9957C75.961 38.3345 76.3822 38.5085 76.895 38.5085C77.4353 38.5085 77.8839 38.3254 78.2319 37.9683L78.644 38.4444C78.1953 38.9114 77.6092 39.1403 76.895 39.1403C76.1808 39.1403 75.6314 38.9114 75.1552 38.4444C74.6791 37.9774 74.441 37.3914 74.441 36.6955C74.441 35.9996 74.6791 35.4227 75.1552 34.9557C75.6314 34.4887 76.2082 34.2506 76.895 34.2506C77.5818 34.2506 78.1953 34.4795 78.644 34.9465Z" />
      <Path d="M79.3399 33.4906C79.1567 33.3075 79.1567 33.0144 79.3399 32.8405C79.523 32.6573 79.7977 32.6573 79.9808 32.8405C80.164 33.0144 80.164 33.3075 79.9808 33.4906C79.7977 33.6646 79.523 33.6646 79.3399 33.4906ZM79.3216 34.388H79.9992V38.0141C79.9992 38.3529 80.0907 38.4994 80.3471 38.4994C80.4204 38.4994 80.5028 38.4902 80.5852 38.4811V39.0396C80.4753 39.0854 80.3288 39.1129 80.1548 39.1129C79.5963 39.1129 79.3216 38.7832 79.3216 38.1331V34.388Z" />
      <Path d="M80.8965 36.6955C80.8965 35.9996 81.1346 35.4227 81.6108 34.9557C82.0869 34.4887 82.6638 34.2506 83.3506 34.2506C84.0373 34.2506 84.6142 34.4887 85.0904 34.9557C85.5665 35.4227 85.8046 35.9996 85.8046 36.6955C85.8046 37.3914 85.5665 37.9774 85.0904 38.4444C84.6142 38.9114 84.0373 39.1403 83.3506 39.1403C82.6638 39.1403 82.0869 38.9114 81.6108 38.4444C81.1346 37.9774 80.8965 37.3914 80.8965 36.6955ZM81.5741 36.6955C81.5741 37.2174 81.739 37.6478 82.0778 37.9957C82.4166 38.3345 82.8378 38.5085 83.3506 38.5085C83.8633 38.5085 84.2845 38.3345 84.6234 37.9957C84.9622 37.6478 85.127 37.2174 85.127 36.6955C85.127 36.1735 84.9622 35.7432 84.6234 35.4044C84.2845 35.0564 83.8633 34.8824 83.3506 34.8824C82.8378 34.8824 82.4166 35.0564 82.0778 35.4044C81.739 35.7432 81.5741 36.1735 81.5741 36.6955Z" />
      <Path d="M86.5829 39.003V34.388H87.2605V35.1663C87.5077 34.6535 88.0297 34.3147 88.7164 34.3147C89.7512 34.3147 90.4013 35.0015 90.4013 36.0545V37.9591C90.4013 38.3345 90.4562 38.4444 90.7218 38.4444C90.7676 38.4444 90.8225 38.4353 90.8958 38.4261V38.9847C90.8225 39.0122 90.7218 39.0305 90.6027 39.0305C90.2456 39.0305 89.7237 38.9297 89.7237 38.0049V36.201C89.7237 35.4318 89.275 34.9465 88.5425 34.9465C87.8099 34.9465 87.2605 35.4776 87.2605 36.2468V39.003H86.5829Z" />
      <Path d="M95.2727 35.0747V34.388H95.9503V37.9683C95.9503 38.3712 96.0419 38.5452 96.2983 38.5452C96.3715 38.5452 96.4539 38.536 96.5364 38.5268V39.0396C96.4265 39.0854 96.28 39.1129 96.106 39.1129C95.9045 39.1129 95.7397 39.0671 95.6207 38.9847C95.4467 38.8473 95.3368 38.6276 95.3093 38.3254C94.9614 38.8656 94.4028 39.1403 93.6336 39.1403C92.9835 39.1403 92.4249 38.9114 91.9763 38.4444C91.5276 37.9774 91.2987 37.3914 91.2987 36.6955C91.2987 35.9996 91.5276 35.4227 91.9763 34.9557C92.4249 34.4887 92.9835 34.2506 93.6336 34.2506C94.4669 34.2506 95.0072 34.6077 95.2727 35.0747ZM94.8607 37.9957C95.1903 37.6478 95.3551 37.2174 95.3551 36.6955C95.3551 36.1735 95.1903 35.7432 94.8607 35.4044C94.5402 35.0564 94.1281 34.8824 93.6336 34.8824C93.1392 34.8824 92.7638 35.0564 92.4433 35.4044C92.1319 35.7432 91.9763 36.1735 91.9763 36.6955C91.9763 37.2174 92.1319 37.6478 92.4433 37.9957C92.7638 38.3345 93.1575 38.5085 93.6336 38.5085C94.1098 38.5085 94.5402 38.3345 94.8607 37.9957Z" />
      <Path d="M97.2048 32.6207H97.8824V37.7943C97.8824 38.3071 98.0289 38.5085 98.4593 38.5085C98.615 38.5085 98.7248 38.4994 98.7889 38.4902V39.0671C98.7065 39.1129 98.56 39.1403 98.3677 39.1403C98.0839 39.1403 97.8824 39.0946 97.6169 38.9389C97.3605 38.7832 97.2048 38.3986 97.2048 37.8401V32.6207Z" />
      <Circle cx="24" cy="72" r="8" css={{ fill, stroke: 'none' }} />
      <Rect opacity="0.2" x="40" y="64" width="64" height="16" rx="8" />
      <Circle cx="24" cy="96" r="8" css={{ fill, stroke: 'none' }} />
      <Rect opacity="0.2" x="40" y="88" width="64" height="16" rx="8" />
      <Circle cx="24" cy="120" r="8" css={{ fill, stroke: 'none' }} />
      <Rect opacity="0.2" x="40" y="112" width="64" height="16" rx="8" />
      <ClipPath id="clip0_20717_71250">
        <Rect
          width="77.5776"
          height="24"
          css={{ fill: 'white' }}
          transform="translate(21.2112 20)"
        />
      </ClipPath>
    </Svg>
  )
}
