import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { LI } from '@positivote/design-system/components/LI'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  ListServiceMappingHookParams,
  ServiceMapping
} from '@/modules/billing/service-mapping/contracts'
import {
  useListServiceMapping,
  useRemoveServiceMapping
} from '@/modules/billing/service-mapping/hooks'

export function ServiceMappingList(): JSX.Element {
  changePageTitle(i18n().modules.billing.serviceMapping.pages.list.pageTitle)

  const { breakpoint } = useTheme()
  const [listServiceMappingParams, setListServiceMappingParams] = useState<
    ListServiceMappingHookParams['model']
  >({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })
  const [showFilter, setShowFilter] = useState(false)
  const [registerToRemove, setRegisterToRemove] = useState<ServiceMapping | null>(null)

  const navigate = useNavigate()
  const listServiceMapping = useListServiceMapping({ model: listServiceMappingParams })
  const removeServiceMapping = useRemoveServiceMapping()

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListServiceMappingParams((oldState) => ({ ...oldState, searchBy: search, page: 1 }))
    })()
  }

  function handleRemove(): void {
    removeServiceMapping.mutate({
      model: { id: registerToRemove?.id ?? '' },
      listServiceMappingParams,
      onSuccess: () => {
        setRegisterToRemove(null)
        if (listServiceMapping.data?.registers.length === 1) {
          setShowFilter(false)
          setListServiceMappingParams((oldData) => ({
            ...oldData,
            page: (oldData.page ?? 1) - 1 || 1
          }))
        }
      }
    })
  }

  useEffect(() => {
    if (listServiceMapping.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listServiceMapping.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        data-testid="removeServiceMapping"
        isOpen={!!registerToRemove}
        title={{ label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.title }}
        contentTexts={[i18n().modules.billing.serviceMapping.pages.list.removeDialog.content]}
        onCancel={() => setRegisterToRemove(null)}
        refuseAction={{
          icon: <CancelIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setRegisterToRemove(null)
        }}
        acceptAction={{
          icon: <DeleteIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.accept,
          handle: handleRemove
        }}
        isLoading={removeServiceMapping.isPending}
      />
      <AppBar
        title={i18n().modules.billing.serviceMapping.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={
          <Button
            data-testid="appBarTrailingElement"
            variant="tonal"
            LeadingIcon={<AddCircleIcon size={18} />}
            onClick={() =>
              navigate('/service-mapping/form', { state: { listServiceMappingParams } })
            }
          >
            {i18n().modules.billing.serviceMapping.pages.list.appBar.trailingElement}
          </Button>
        }
      />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.billing.serviceMapping.pages.list.filter.title}
              </Typography>
            </Div>
            <Grid xl={6} md={8} sm={10} xs={12} css={{ marginBottom: '$lg' }}>
              <TextField
                data-testid="searchBy"
                variant="outlined"
                label={i18n().modules.billing.serviceMapping.pages.list.filter.input.label}
                supportingText={
                  i18n().modules.billing.serviceMapping.pages.list.filter.input.supportingText
                }
                leadingIcon={{ icon: SearchIcon }}
                inputProps={{
                  onChange: handleChangeSearchText
                }}
              />
            </Grid>
            {listServiceMapping.isFetching && listServiceMappingParams.searchBy && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searching"
              >
                {i18n().modules.billing.serviceMapping.pages.list.searching}
              </Typography>
            )}
            {!listServiceMapping.isFetching &&
              !!listServiceMapping.data?.registers.length &&
              listServiceMappingParams.searchBy && (
                <Typography
                  variant="titleLarge"
                  css={{ color: '$on-surface', marginBottom: '$lg' }}
                  data-testid="Typography-searchingResult"
                >
                  {i18n().modules.billing.serviceMapping.pages.list.searchingResult}
                </Typography>
              )}
          </>
        )}

        {!listServiceMapping.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {listServiceMapping.isFetching && (
              <Loader data-testid="Loader-Container-ServiceMappingList" size={80} />
            )}
            {!listServiceMapping.isFetching && !listServiceMappingParams.searchBy && (
              <EmptyList title={i18n().modules.billing.serviceMapping.pages.list.emptyList} />
            )}
            {!listServiceMapping.isFetching && listServiceMappingParams.searchBy && <EmptySearch />}
          </Div>
        ) : (
          <>
            <Grid
              spacing="$md"
              css={{
                padding: '$md $lg',
                // DOCS: Sum of buttons with padding and gap
                // gap + editButton + gap + removeButton + paddingRight
                paddingRight: 'calc($md + $2xl + $md + $2xl + $lg)',
                marginBottom: '$2xs'
              }}
            >
              <Grid xl={3}>
                <Typography
                  data-testid="Typography-headerApplication"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.billing.serviceMapping.pages.list.header.application}
                </Typography>
              </Grid>
              <Grid xl={1.5}>
                <Typography
                  data-testid="Typography-headerHubId"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.billing.serviceMapping.pages.list.header.hubId}
                </Typography>
              </Grid>
              <Grid xl={3.75}>
                <Typography
                  data-testid="Typography-headerServices"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.billing.serviceMapping.pages.list.header.services}
                </Typography>
              </Grid>
              <Grid xl={3.75}>
                <Typography
                  data-testid="Typography-headerSapId"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.billing.serviceMapping.pages.list.header.sapId}
                </Typography>
              </Grid>
            </Grid>
            <UL
              css={{
                ...(listServiceMapping.isFetching && {
                  ...(listServiceMapping.data.lastPage <= 1 && { flex: 1 }),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  maxHeight: '43vh'
                })
              }}
            >
              {listServiceMapping.isFetching && (
                <Loader data-testid="Loader-Container-ServiceMappingList" size={80} />
              )}
              {listServiceMapping.data.registers.map((register, index) => (
                <BaseCard
                  key={register.id}
                  data-testid={`BaseCard-Container-serviceMappingListItem-${index}`}
                  component={LI}
                  css={{
                    opacity: listServiceMapping.isFetching ? '$transparent' : '$default',
                    marginBottom: '$2xs',
                    '&:last-child': { marginBottom: '$none' },
                    '& .BaseCard-StateLayer': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '$md',
                      height: 'min-content',
                      padding: '$md $lg'
                    }
                  }}
                >
                  <Grid spacing="$md" css={{ flex: 1 }}>
                    <Grid
                      xl={3}
                      css={{
                        display: 'flex',
                        alignItems: register.sapServices.length > 1 ? 'flex-start' : 'center'
                      }}
                    >
                      <Typography
                        data-testid={`Typography-listItemApplication-${index}`}
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                      >
                        {register.application.name}
                      </Typography>
                    </Grid>
                    <Grid
                      xl={1.5}
                      css={{
                        display: 'flex',
                        alignItems: register.sapServices.length > 1 ? 'flex-start' : 'center'
                      }}
                    >
                      <Typography
                        data-testid={`Typography-listItemHubId-${index}`}
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                      >
                        {register.application.id}
                      </Typography>
                    </Grid>
                    <Grid
                      xl={3.75}
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: register.sapServices.length > 1 ? 'flex-start' : 'center',
                        gap: '$2xs'
                      }}
                    >
                      {register.sapServices.map((sapService, sapServiceIndex) => (
                        <Typography
                          data-testid={`Typography-listItemServices-${index}-${sapServiceIndex}`}
                          key={sapService.id}
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                        >
                          {sapService.name}
                        </Typography>
                      ))}
                    </Grid>
                    <Grid
                      xl={3.75}
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: register.sapServices.length > 1 ? 'flex-start' : 'center',
                        gap: '$2xs'
                      }}
                    >
                      {register.sapServices.map((sapService, sapServiceIndex) => (
                        <Typography
                          data-testid={`Typography-listItemSapId-${index}-${sapServiceIndex}`}
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          key={sapService.id}
                        >
                          {sapService.id}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <IconButton
                    variant="standard"
                    data-testid={`edit-${index}`}
                    onClick={() =>
                      navigate(`/service-mapping/form/${register.id}`, {
                        state: { listServiceMappingParams }
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    variant="standard"
                    data-testid={`delete-${index}`}
                    onClick={() => setRegisterToRemove(register)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </BaseCard>
              ))}
            </UL>
            {listServiceMapping.data.lastPage > 1 && (
              <Pagination
                lastPage={listServiceMapping.data.lastPage}
                page={listServiceMappingParams.page ?? 1}
                setPage={(page) =>
                  setListServiceMappingParams((oldState) => ({ ...oldState, page }))
                }
                css={{ marginTop: '$lg' }}
              />
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
