import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useTheme } from '@positivote/design-system/hooks'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { BotSeePhone } from '@/common/assets/icons/BotSeePhone'
import { changePageTitle } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ResetPasswordForm } from '@/modules/hub/auth/contracts/forms'
import { AuthFlow } from '@/modules/hub/auth/contracts/services'
import { useResetPassword } from '@/modules/hub/auth/hooks'
import { resetPasswordFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { resetPasswordValidationSchema } from '@/modules/hub/auth/validations'

export function ResetPassword(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.resetPassword.pageTitle)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()
  const { createSession, isLoading: authIsLoading } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })
  const location = useLocation()
  const resetPassword = useResetPassword()
  const locationState = location.state as
    | { username: string; password: string; authFlow: AuthFlow; orgId?: number }
    | undefined

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ResetPasswordForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(resetPasswordValidationSchema(locationState?.authFlow === 'ESCOLA'))(
        resetPasswordFormSanitizer(values),
        ...args
      )
  })
  const hasAnyError = !!Object.keys(errors).length

  function onSubmit(model: ResetPasswordForm): void {
    resetPassword.mutate({
      model: {
        username: locationState?.username ?? '',
        password: locationState?.password ?? '',
        institution: locationState?.orgId ?? null,
        newPassword: model.newPassword
      },
      onSuccess: () => {
        void createSession({
          model:
            locationState?.authFlow === 'ESCOLA' && locationState.orgId
              ? {
                  authFlow: 'ESCOLA',
                  username: locationState.username,
                  password: model.newPassword,
                  orgId: locationState.orgId
                }
              : {
                  authFlow: 'EMAIL',
                  username: locationState?.username ?? '',
                  password: model.newPassword
                },
          onError: ({ error }) => {
            handleError({ error })
          }
        })
      }
    })
  }

  const isLoading = resetPassword.isPending || authIsLoading

  useEffect(() => {
    if (!locationState?.username && !locationState?.password) {
      navigate('/', { replace: true, state: { keepLoginProps: true } })
    }
  }, [locationState, navigate])

  return (
    <BaseBackground>
      <Grid xl={10} xs={12}>
        <Grid spacing="$lg" css={{ justifyContent: 'center' }}>
          <Grid
            xl={6}
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '@sm': { display: 'none' }
            }}
          >
            <BotSeePhone />
          </Grid>
          <Grid xl={6} sm={12}>
            <FormContainer
              formHandleSubmit={handleSubmit}
              onSubmit={onSubmit}
              css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
              <Typography
                variant={
                  breakpoint === Breakpoint.sm
                    ? 'headlineSmall'
                    : breakpoint === Breakpoint.xs
                      ? 'titleLarge'
                      : 'headlineMedium'
                }
                css={{
                  color: '$on-surface',
                  width: '100%',
                  '@xl': { textAlign: 'left' },
                  '@sm': { textAlign: 'center' }
                }}
              >
                {i18n().modules.hub.auth.pages.resetPassword.title}
              </Typography>
              <Typography
                variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
                css={{
                  color: '$on-surface-variant',
                  width: '100%',
                  marginTop: '$lg',
                  '@xl': { textAlign: 'left' },
                  '@sm': { textAlign: 'center', marginTop: '$md' }
                }}
              >
                {i18n().modules.hub.auth.pages.resetPassword.subTitle}
              </Typography>
              <Div
                css={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '$lg',
                  '@sm': { marginTop: '$md' }
                }}
              >
                <IconWrapper
                  size="$2xl"
                  css={{
                    backgroundColor: '$primary-container',
                    marginTop: '$2xs',
                    marginRight: '$md',
                    '@xs': { display: 'none' }
                  }}
                >
                  <KeyIcon fill="$on-primary-container" />
                </IconWrapper>
                <FormTextField
                  control={control}
                  name="newPassword"
                  label={i18n().modules.hub.auth.pages.resetPassword.inputs.newPassword}
                  variant="outlined"
                  errorText={errors.newPassword?.message}
                  inputProps={{
                    autoComplete: 'new-password',
                    type: showNewPassword ? 'text' : 'password',
                    autoFocus: true
                  }}
                  trailingIcon={{
                    icon: showNewPassword ? VisibilityIcon : VisibilityOffIcon,
                    onClick: () => setShowNewPassword((oldState) => !oldState),
                    changeIconOnError: false
                  }}
                  required
                />
              </Div>
              <Div
                css={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '$lg',
                  '@sm': { marginTop: '$md' }
                }}
              >
                <IconWrapper
                  size="$2xl"
                  css={{
                    backgroundColor: '$primary-container',
                    marginTop: '$2xs',
                    marginRight: '$md',
                    '@xs': { display: 'none' }
                  }}
                >
                  <KeyIcon fill="$on-primary-container" />
                </IconWrapper>
                <FormTextField
                  control={control}
                  name="confirmNewPassword"
                  label={i18n().modules.hub.auth.pages.resetPassword.inputs.confirmNewPassword}
                  variant="outlined"
                  errorText={errors.confirmNewPassword?.message}
                  inputProps={{
                    autoComplete: 'new-password',
                    type: showConfirmPassword ? 'text' : 'password'
                  }}
                  trailingIcon={{
                    icon: showConfirmPassword ? VisibilityIcon : VisibilityOffIcon,
                    onClick: () => setShowConfirmPassword((oldState) => !oldState),
                    changeIconOnError: false
                  }}
                  required
                />
              </Div>
              <Div
                css={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '$lg',
                  '@sm': { marginTop: '$md' },
                  '@xs': { flexDirection: 'column-reverse' }
                }}
              >
                <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  css={{ flex: 1 }}
                  onClick={() => navigate(-1)}
                >
                  {i18n().modules.hub.auth.pages.resetPassword.buttons.goBack}
                </Button>
                <Button
                  type="submit"
                  disabled={hasAnyError}
                  isLoading={isLoading}
                  variant="filled"
                  css={{
                    flex: 1,
                    marginLeft: '$md',
                    '@xs': { marginLeft: '$none', marginBottom: '$2xs' }
                  }}
                >
                  {i18n().modules.hub.auth.pages.resetPassword.buttons.save}
                </Button>
              </Div>
            </FormContainer>
          </Grid>
        </Grid>
      </Grid>
    </BaseBackground>
  )
}
