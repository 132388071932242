import { cnpjMask, parseValidDateOrDefault } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import {
  OnboardingInRegisterDetails,
  OnboardingInRegisterDetailsFormatted,
  OnboardingInRegisterStage,
  OnboardingInRegisterStageFormatted,
  OnboardingInRegisterStageKey,
  OnboardingInRegisterStageStatus,
  OnboardingPreRegister,
  OnboardingPreRegisterFormatted
} from './contracts'

export function onboardingPreRegisterApiSanitizer(
  registers: OnboardingPreRegister[]
): OnboardingPreRegisterFormatted[] {
  return registers.map((register) => ({
    ...register,
    org: {
      ...register.org,
      typeFormatted: i18n().modules.hub.onboarding.org.type[register.org.type],
      networkTypeFormatted: i18n().modules.hub.onboarding.org.networkType[register.org.networkType],
      cinNumberFormatted: cnpjMask(register.org.cinNumber),
      addressFormatted: `${register.org.address.street} - ${register.org.address.city}`
    }
  }))
}

export function onboardingInRegisterStageSanitizer(
  stage: OnboardingInRegisterStage
): OnboardingInRegisterStageFormatted {
  return {
    ...stage,
    keyFormatted: i18n().modules.hub.onboarding.inRegisterStageKey[stage.key],
    statusFormatted: i18n().modules.hub.onboarding.inRegisterStageStatus[stage.status],
    updatedAtFormatted: stage.updatedAt
      ? parseValidDateOrDefault(stage.updatedAt)
      : i18n().modules.hub.onboarding.notStarted
  }
}

export function onboardingInRegisterDetailsApiSanitizer(
  register: OnboardingInRegisterDetails
): OnboardingInRegisterDetailsFormatted {
  let mostRecentStage = register.stages[0]
    ? onboardingInRegisterStageSanitizer(register.stages[0])
    : null

  let schoolCreatedStage = onboardingInRegisterStageSanitizer({
    key: OnboardingInRegisterStageKey.SCHOOL_CREATED,
    status: OnboardingInRegisterStageStatus.TODO,
    updatedAt: null
  })
  let userMasterCreatedStage = onboardingInRegisterStageSanitizer({
    key: OnboardingInRegisterStageKey.USER_MASTER_CREATED,
    status: OnboardingInRegisterStageStatus.TODO,
    updatedAt: null
  })
  let periodActivatedStage = onboardingInRegisterStageSanitizer({
    key: OnboardingInRegisterStageKey.CURRENT_PERIOD_ACTIVATED,
    status: OnboardingInRegisterStageStatus.TODO,
    updatedAt: null
  })
  let schoolEnrollmentStage = onboardingInRegisterStageSanitizer({
    key: OnboardingInRegisterStageKey.SCHOOL_ENROLLMENTS_STARTED,
    status: OnboardingInRegisterStageStatus.TODO,
    updatedAt: null
  })

  const parsedRegister = {
    ...register,
    stages: register.stages.map((stage) => {
      const formattedStage = onboardingInRegisterStageSanitizer(stage)

      if (
        formattedStage.updatedAt &&
        mostRecentStage?.updatedAt &&
        formattedStage.updatedAt > mostRecentStage.updatedAt
      ) {
        mostRecentStage = formattedStage
      }

      if (formattedStage.key === OnboardingInRegisterStageKey.SCHOOL_CREATED) {
        schoolCreatedStage = formattedStage
      }
      if (formattedStage.key === OnboardingInRegisterStageKey.USER_MASTER_CREATED) {
        userMasterCreatedStage = formattedStage
      }
      if (formattedStage.key === OnboardingInRegisterStageKey.CURRENT_PERIOD_ACTIVATED) {
        periodActivatedStage = formattedStage
      }
      if (formattedStage.key === OnboardingInRegisterStageKey.SCHOOL_ENROLLMENTS_STARTED) {
        schoolEnrollmentStage = formattedStage
      }

      return formattedStage
    })
  }

  return {
    ...parsedRegister,
    professionalProfilesQuantity: register.professionals.reduce(
      (accumulator, professional) => accumulator + professional.total,
      0
    ),
    studentsQuantity: register.students.reduce(
      (accumulator, student) => accumulator + student.total,
      0
    ),
    mostRecentStage,
    schoolCreatedStage,
    userMasterCreatedStage,
    periodActivatedStage,
    schoolEnrollmentStage
  }
}
