export const RESTRICT_NOT_SIGNED_PATHNAMES = [
  // FIXME: remove this 2 mock routes
  'mock-edtech-request',
  'mock-edtech-response',
  'sso',
  'oauth2',
  'login',
  'profiles',
  'organizations',
  'recover-password',
  'change-password',
  'reset-password',
  'qr-code',
  'quick-access',
  'not-found',
  'forbidden',
  'design-system'
]
