import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function KeyboardDoubleArrowRightIcon({
  fill,
  size = '$lg',
  css,
  ...restProps
}: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path d="M6.41 6L5 7.41L9.58 12L5 16.59L6.41 18L12.41 12L6.41 6Z" css={{ fill }} />
      <Path d="M13 6L11.59 7.41L16.17 12L11.59 16.59L13 18L19 12L13 6Z" css={{ fill }} />
    </Svg>
  )
}
