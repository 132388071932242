import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'

import { DesktopAppCard } from './Desktop'
import { MobileAppCard } from './Mobile'
import { AppCardProps } from './styles'

export function AppCard({ applicationIdLoading, ...restProps }: AppCardProps): JSX.Element {
  const { breakpoint } = useTheme()

  const cardProps = {
    enableOptions: ['configureAccess'],
    canClick: !applicationIdLoading,
    applicationIdLoading,
    ...restProps
  }

  return breakpoint <= Breakpoint.sm ? (
    <MobileAppCard {...cardProps} />
  ) : (
    <DesktopAppCard {...cardProps} />
  )
}
