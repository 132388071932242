import { AppBarProps, AppBar as PosiAppBar } from '@positivote/design-system/components/AppBar'
import { Button } from '@positivote/design-system/components/Button'
import { useTheme } from '@positivote/design-system/hooks'
import { LoginIcon } from '@positivote/design-system/icons/Login'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { HubDarkIcon } from '@/common/assets/icons/HubDarkIcon'
import { HubIcon } from '@/common/assets/icons/HubIcon'
import { MonitooraIcon } from '@/common/assets/icons/MonitooraIcon'
import { i18n } from '@/common/i18n'
import { LoginSupportDialog } from '@/modules/hub/auth/components/LoginSupportDialog'
import { useAuth } from '@/modules/hub/auth/contexts'
import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

export function AppBar<Model extends Record<string, string>>({
  trailingElement,
  ...restProps
}: Omit<AppBarProps<Model>, 'icon'>): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { mode, breakpoint } = useTheme()
  const { isLoginSupport, profile, permissions, whiteLabel } = useAuth()
  const shouldShowLoginSupportButton =
    profile?.role.code === 'administrador' &&
    permissions?.some((permission) => permission.key === 'support-login') &&
    !isLoginSupport &&
    breakpoint !== Breakpoint.xs

  const [openLoginSupport, setOpenLoginSupport] = useState(false)

  return (
    <PosiAppBar
      css={{ '& .AppBar-TrailingElementWrapper': { alignItems: 'center' } }}
      icon={{
        onClick: () => location.pathname !== '/' && navigate('/'),
        src:
          profile && MONITOORA_ROLES.includes(profile.role.code) ? (
            <MonitooraIcon data-testid="AppBar-Organization-MonitooraIcon" />
          ) : // DOCS: billing icon is same of hub, so do not need set billing icon
          profile?.role.code !== 'billing' && whiteLabel?.active && whiteLabel.urlIcon ? (
            whiteLabel.urlIcon
          ) : mode === 'light' ? (
            <HubIcon data-testid="AppBar-Organization-HubIcon" />
          ) : (
            <HubDarkIcon data-testid="AppBar-Organization-HubDarkIcon" />
          )
      }}
      profile={
        profile && {
          picture: profile.picture,
          onClick: () => location.pathname !== '/profiles' && navigate('/profiles')
        }
      }
      trailingElement={
        (!!trailingElement || shouldShowLoginSupportButton) && (
          <>
            {trailingElement}
            {shouldShowLoginSupportButton && (
              <>
                <LoginSupportDialog
                  isOpen={openLoginSupport}
                  onClose={() => setOpenLoginSupport(false)}
                />
                <Button
                  data-testid="loginSupport"
                  variant="tonal"
                  LeadingIcon={<LoginIcon size={18} />}
                  onClick={() => setOpenLoginSupport(true)}
                  css={{ minWidth: 'fit-content' }}
                >
                  {i18n().common.components.appBar.loginSupport}
                </Button>
              </>
            )}
          </>
        )
      }
      {...restProps}
    />
  )
}
