export const serviceMappingPt = {
  resources: {
    list: 'Listar mapeamento de serviços',
    create: 'Criar mapeamento de serviço',
    verify: 'Verificar mapeamento de serviço',
    show: 'Visualizar mapeamento de serviço',
    update: 'Atualizar mapeamento de serviço',
    remove: 'Remover mapeamento de serviço'
  },
  components: {
    sapServiceList: {
      service: 'Serviço:',
      sapId: 'ID SAP:'
    }
  },
  pages: {
    list: {
      pageTitle: 'Mapeamento de serviços',
      appBar: {
        title: 'Mapeamento de serviços',
        trailingElement: 'Novo serviço'
      },
      filter: {
        title: 'Opções de filtragem',
        input: {
          label: 'Buscar...',
          supportingText:
            'A busca poderá ser feita pelo nome da aplicação, serviço ou ID SAP do serviço.'
        }
      },
      searching: 'Buscando por resultados...',
      searchingResult: 'Resultado da busca...',
      header: {
        application: 'Aplicação',
        hubId: 'ID HUB',
        services: 'Serviços',
        sapId: 'ID SAP'
      },
      emptyList: 'Ops! Ainda não temos mapeamento de serviços para exibir',
      removeDialog: {
        title: 'Remover serviços',
        content: 'Tem certeza que deseja remover?',
        refuse: 'Cancelar',
        accept: 'Sim, quero remover'
      }
    },
    form: {
      pageTitle: (isEditing: boolean): string =>
        `${isEditing ? 'Editar' : 'Novo'} mapeamento de serviço`,
      appBar: {
        title: 'Mapeamento de serviços',
        breadcrumbs: {
          overview: 'Visão geral',
          newService: 'Novo Serviço',
          editService: 'Editar Serviço'
        }
      },
      stepper: {
        serviceData: {
          title: 'Dados do serviço',
          requiredFields: '* Campo de preenchimento obrigatório',
          addServices: {
            label: 'Adicionar serviços da aplicação:',
            error: 'Adicione ao menos um serviço'
          },
          fields: {
            selectApplication: {
              label: 'Selecione a aplicação',
              error: 'Aplicação já utilizada por outro mapeamento'
            },
            hubId: 'ID HUB',
            name: 'Serviço',
            id: {
              label: 'ID SAP',
              error: 'Este ID já está em uso por outro serviço'
            }
          }
        },
        preview: {
          title: 'Pré-visualização',
          hubId: 'ID HUB:',
          doneButtonLabel: (isEditing: boolean): string =>
            `${isEditing ? 'Salvar' : 'Finalizar'} cadastro`,
          alert: {
            created: 'Serviço criado com sucesso!',
            updated: 'Serviço atualizado com sucesso!',
            removed: 'Serviço removido com sucesso!'
          }
        }
      }
    }
  }
}
