import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundBottomLeft({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="170"
      viewBox="0 0 208 170"
      fill="none"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M145.401 91.1031C145.364 91.2363 145.346 91.4799 145.612 91.6591C150.267 94.7649 155.927 96.3408 162.437 96.3408C170.234 96.3408 176.717 94.0206 181.702 89.4491C186.742 84.8316 189.297 78.9645 189.297 72.013C189.297 65.0615 187.215 59.2403 183.108 55.4177C180.926 53.387 177.976 51.2826 174.342 49.1738C174.112 49.0405 173.914 49.1233 173.818 49.183C173.721 49.2427 173.556 49.3898 173.579 49.6608C173.896 53.8969 173.96 57.7793 173.763 61.2021C173.754 61.3492 173.822 61.4962 173.937 61.6019C177.098 64.4045 178.33 67.4047 178.33 72.2979C178.33 80.2371 171.557 86.2238 162.575 86.2238C161.684 86.2238 160.797 86.1778 159.947 86.0859C159.818 86.0722 159.699 86.0951 159.598 86.1594C159.391 86.2835 159.18 86.4122 158.964 86.5316C154.686 88.9989 150.276 90.4139 145.856 90.731C145.539 90.7539 145.433 90.9745 145.396 91.1077L145.401 91.1031Z"
          css={{ fill }}
        />
        <Path
          d="M141.169 6.89532C136.129 11.5128 133.575 17.3799 133.575 24.3314C133.575 31.2829 135.656 37.1041 139.764 40.9267C141.946 42.9575 144.896 45.0617 148.53 47.1706C148.76 47.3038 148.957 47.2211 149.054 47.1614C149.15 47.1017 149.315 46.9547 149.293 46.6836C148.975 42.4475 148.911 38.5651 149.109 35.1422C149.118 34.9952 149.049 34.8482 148.934 34.7425C145.773 31.9399 144.542 28.9397 144.542 24.0465C144.542 16.1073 151.314 10.1206 160.296 10.1206C161.188 10.1206 162.074 10.1666 162.924 10.2585C162.947 10.2585 162.97 10.2585 162.993 10.2585C163.094 10.2585 163.191 10.2309 163.274 10.1804C163.48 10.0563 163.692 9.93228 163.908 9.80823C168.18 7.34099 172.591 5.92587 177.016 5.60886C177.333 5.58588 177.438 5.36536 177.475 5.23212C177.512 5.09888 177.53 4.85536 177.264 4.67618C172.614 1.5703 166.954 -0.00561523 160.443 -0.00561523C152.646 -0.00561523 146.164 2.31459 141.179 6.88611L141.169 6.89532Z"
          css={{ fill }}
        />
        <Path
          d="M206.632 40.5959C206.765 40.5591 206.985 40.4534 207.008 40.1318C207.371 34.5495 205.906 28.8616 202.653 23.2241C198.757 16.4748 193.505 12.0181 187.055 9.98279C180.535 7.92905 174.176 8.65041 168.157 12.1238C161.932 15.7167 158.137 20.3112 156.878 25.7787C156.207 28.687 155.867 32.289 155.853 36.493C155.853 36.7595 156.023 36.8881 156.12 36.9387C156.221 36.9938 156.428 37.0627 156.653 36.9065C160.163 34.5128 163.494 32.5188 166.554 30.975C166.683 30.9107 166.779 30.7775 166.811 30.6212C167.657 26.4816 169.641 23.9179 173.878 21.469C180.756 17.4994 189.324 20.3709 193.818 28.1494C194.263 28.9213 194.668 29.7116 195.012 30.4926C195.063 30.6121 195.146 30.6994 195.247 30.7591C195.458 30.8786 195.674 30.998 195.885 31.122C200.163 33.5893 203.59 36.7044 206.076 40.3754C206.255 40.6418 206.498 40.6189 206.632 40.5821V40.5959Z"
          css={{ fill }}
        />
        <Path
          d="M135.821 86.3616C142.341 88.4153 148.7 87.694 154.719 84.2206C160.944 80.6277 164.739 76.0332 165.998 70.5657C166.669 67.6574 167.009 64.0553 167.023 59.8514C167.023 59.5849 166.853 59.4562 166.756 59.4057C166.655 59.3506 166.448 59.2817 166.223 59.4379C162.713 61.8316 159.382 63.8256 156.322 65.3694C156.193 65.4337 156.097 65.5669 156.065 65.7231C155.219 69.8628 153.235 72.4265 148.998 74.8754C142.121 78.845 133.552 75.9735 129.058 68.195C128.613 67.4231 128.208 66.6329 127.864 65.8518C127.855 65.8288 127.845 65.8104 127.832 65.7921C127.781 65.7048 127.712 65.6313 127.625 65.5853C127.414 65.4659 127.198 65.3464 126.986 65.2224C122.713 62.7551 119.281 59.6401 116.796 55.9691C116.617 55.7026 116.373 55.7255 116.24 55.7577C116.107 55.7944 115.886 55.9001 115.863 56.2217C115.5 61.804 116.966 67.492 120.219 73.1295C124.115 79.8834 129.366 84.3354 135.817 86.3708L135.821 86.3616Z"
          css={{ fill }}
        />
        <Path
          d="M132.275 12.8222C132.178 12.7257 131.971 12.5879 131.686 12.7303C126.669 15.2067 122.479 19.3188 119.222 24.9562C115.325 31.7055 114.09 38.4824 115.555 45.0847C117.035 51.7605 120.839 56.9063 126.862 60.3798C133.088 63.9726 138.964 64.9605 144.326 63.3203C147.179 62.4473 150.473 60.9403 154.117 58.8498C154.346 58.7166 154.374 58.5052 154.369 58.395C154.369 58.2801 154.323 58.0687 154.075 57.9493C150.248 56.1069 146.857 54.2186 143.99 52.3394C143.871 52.2613 143.706 52.2429 143.559 52.2935C139.552 53.6305 136.336 53.1986 132.1 50.7497C125.222 46.7801 123.426 37.9219 127.914 30.1388C128.36 29.367 128.842 28.6227 129.348 27.9335C129.426 27.8278 129.463 27.7129 129.458 27.5981C129.454 27.3546 129.449 27.1111 129.449 26.8629C129.449 21.9239 130.432 17.3983 132.371 13.4103C132.509 13.1254 132.371 12.9187 132.275 12.8268V12.8222Z"
          css={{ fill }}
        />
        <Path
          d="M207.316 51.2597C205.837 44.5839 202.033 39.4381 196.009 35.9647C189.784 32.3718 183.907 31.3839 178.546 33.0242C175.692 33.8971 172.398 35.4041 168.755 37.4946C168.525 37.6279 168.497 37.8392 168.502 37.9495C168.502 38.0643 168.548 38.2757 168.796 38.3951C172.623 40.2375 176.014 42.1259 178.881 44.005C179 44.0831 179.166 44.1015 179.313 44.051C183.319 42.714 186.535 43.1458 190.772 45.5947C197.649 49.5643 199.446 58.4225 194.957 66.2056C194.511 66.9774 194.029 67.7218 193.524 68.4109C193.51 68.4293 193.496 68.4477 193.487 68.4661C193.436 68.5534 193.409 68.6499 193.413 68.7463C193.418 68.9898 193.423 69.2333 193.423 69.4814C193.423 74.4159 192.439 78.9415 190.505 82.9341C190.367 83.2189 190.505 83.4257 190.602 83.5176C190.698 83.6141 190.905 83.7519 191.19 83.6095C196.207 81.1331 200.397 77.021 203.654 71.3835C207.551 64.6388 208.782 57.8666 207.316 51.2597Z"
          css={{ fill }}
        />
        <Path
          d="M18.3146 166.013C18.2931 166.091 18.2824 166.234 18.4383 166.338C21.1611 168.155 24.4726 169.077 28.2813 169.077C32.8426 169.077 36.6352 167.72 39.5515 165.046C42.5001 162.344 43.9945 158.912 43.9945 154.845C43.9945 150.778 42.7769 147.373 40.374 145.137C39.0973 143.949 37.3716 142.717 35.2455 141.484C35.1111 141.406 34.9956 141.454 34.9391 141.489C34.8827 141.524 34.7859 141.61 34.7993 141.769C34.9848 144.247 35.0224 146.518 34.9069 148.521C34.9015 148.607 34.9418 148.693 35.009 148.754C36.8583 150.394 37.5786 152.149 37.5786 155.012C37.5786 159.656 33.6167 163.159 28.3619 163.159C27.8405 163.159 27.3217 163.132 26.8244 163.078C26.7492 163.07 26.6793 163.083 26.6202 163.121C26.4992 163.194 26.3756 163.269 26.2492 163.339C23.7468 164.782 21.1665 165.61 18.5807 165.796C18.3953 165.809 18.3335 165.938 18.312 166.016L18.3146 166.013Z"
          css={{ fill }}
        />
        <Path
          d="M15.8391 116.75C12.8905 119.451 11.3961 122.884 11.3961 126.95C11.3961 131.017 12.6137 134.423 15.0166 136.659C16.2934 137.847 18.019 139.078 20.1451 140.312C20.2795 140.39 20.3951 140.341 20.4515 140.306C20.5079 140.271 20.6047 140.186 20.5913 140.027C20.4058 137.549 20.3682 135.277 20.4838 133.275C20.4891 133.189 20.4488 133.103 20.3816 133.041C18.5324 131.402 17.812 129.646 17.812 126.784C17.812 122.139 21.7739 118.637 27.0287 118.637C27.5502 118.637 28.0689 118.664 28.5662 118.717C28.5796 118.717 28.5931 118.717 28.6065 118.717C28.6656 118.717 28.7221 118.701 28.7705 118.672C28.8914 118.599 29.0151 118.527 29.1414 118.454C31.6411 117.011 34.2215 116.183 36.8099 115.997C36.9953 115.984 37.0572 115.855 37.0787 115.777C37.1002 115.699 37.1109 115.556 36.955 115.452C34.2349 113.635 30.9234 112.713 27.1147 112.713C22.5534 112.713 18.7608 114.07 15.8445 116.745L15.8391 116.75Z"
          css={{ fill }}
        />
        <Path
          d="M54.1359 136.465C54.2138 136.444 54.3428 136.382 54.3563 136.194C54.5686 132.928 53.7112 129.601 51.8082 126.303C49.5289 122.354 46.4566 119.747 42.6829 118.556C38.8688 117.355 35.1488 117.777 31.6277 119.809C27.9856 121.911 25.7654 124.598 25.0289 127.797C24.6365 129.498 24.4376 131.606 24.4295 134.065C24.4295 134.221 24.529 134.296 24.5854 134.326C24.6446 134.358 24.7655 134.399 24.8972 134.307C26.9508 132.907 28.8995 131.74 30.6896 130.837C30.7649 130.799 30.8213 130.721 30.8401 130.63C31.3347 128.208 32.4958 126.709 34.9741 125.276C38.9978 122.954 44.0107 124.633 46.6394 129.184C46.9001 129.636 47.1367 130.098 47.3383 130.555C47.3678 130.625 47.4162 130.676 47.4753 130.711C47.599 130.781 47.7253 130.851 47.849 130.923C50.3514 132.366 52.3565 134.189 53.8106 136.336C53.9155 136.492 54.0579 136.479 54.1359 136.457V136.465Z"
          css={{ fill }}
        />
        <Path
          d="M12.7104 163.239C16.5245 164.441 20.2445 164.019 23.7656 161.987C27.4077 159.885 29.6279 157.197 30.3644 153.998C30.7568 152.297 30.9557 150.19 30.9638 147.73C30.9638 147.574 30.8643 147.499 30.8079 147.47C30.7487 147.437 30.6278 147.397 30.4961 147.488C28.4425 148.889 26.4938 150.055 24.7037 150.958C24.6284 150.996 24.572 151.074 24.5532 151.165C24.0586 153.587 22.8975 155.087 20.4192 156.52C16.3955 158.842 11.3826 157.162 8.75389 152.612C8.49317 152.16 8.25664 151.698 8.05505 151.241C8.04967 151.227 8.0443 151.217 8.03624 151.206C8.00667 151.155 7.96635 151.112 7.91528 151.085C7.79164 151.015 7.66531 150.945 7.54167 150.872C5.04195 149.429 3.03411 147.607 1.57997 145.459C1.47514 145.303 1.33269 145.317 1.25474 145.335C1.1768 145.357 1.04778 145.419 1.03434 145.607C0.821995 148.873 1.67943 152.2 3.58244 155.498C5.86175 159.449 8.93398 162.054 12.7078 163.245L12.7104 163.239Z"
          css={{ fill }}
        />
        <Path
          d="M10.6354 120.217C10.579 120.161 10.458 120.08 10.2914 120.164C7.3562 121.612 4.90486 124.018 2.99916 127.316C0.719853 131.264 -0.00318509 135.229 0.854244 139.091C1.71974 142.997 3.94529 146.007 7.46909 148.039C11.1111 150.141 14.5489 150.719 17.6857 149.76C19.3548 149.249 21.2821 148.367 23.4135 147.144C23.5479 147.066 23.5641 146.943 23.5614 146.878C23.5614 146.811 23.5345 146.687 23.3893 146.618C21.1503 145.54 19.1667 144.435 17.4895 143.336C17.4196 143.29 17.3228 143.279 17.2368 143.309C14.893 144.091 13.0115 143.838 10.5333 142.406C6.50952 140.083 5.45856 134.901 8.08461 130.348C8.34534 129.896 8.62756 129.461 8.92323 129.058C8.96892 128.996 8.99043 128.929 8.98774 128.861C8.98505 128.719 8.98236 128.577 8.98236 128.431C8.98236 125.542 9.55756 122.894 10.6918 120.561C10.7725 120.395 10.6918 120.274 10.6354 120.22V120.217Z"
          fill="#B971E0"
        />
        <Path
          d="M54.5364 142.704C53.6709 138.799 51.4453 135.788 47.9215 133.756C44.2795 131.654 40.8417 131.076 37.7049 132.036C36.0358 132.547 34.1086 133.428 31.9771 134.651C31.8427 134.729 31.8266 134.853 31.8293 134.917C31.8293 134.984 31.8561 135.108 32.0013 135.178C34.2403 136.256 36.2239 137.361 37.9011 138.46C37.971 138.506 38.0678 138.516 38.1538 138.487C40.4976 137.705 42.3791 137.957 44.8574 139.39C48.8811 141.712 49.9321 146.894 47.306 151.448C47.0453 151.899 46.7631 152.335 46.4674 152.738C46.4593 152.749 46.4513 152.759 46.4459 152.77C46.4163 152.821 46.4002 152.878 46.4029 152.934C46.4056 153.076 46.4083 153.219 46.4083 153.364C46.4083 156.251 45.8331 158.898 44.7015 161.234C44.6208 161.401 44.7015 161.522 44.7579 161.576C44.8143 161.632 44.9353 161.713 45.1019 161.629C48.0371 160.181 50.4884 157.775 52.3941 154.477C54.6735 150.531 55.3938 146.569 54.5364 142.704Z"
          fill="#B971E0"
        />
      </g>
    </Svg>
  )
}
