export const dataIntelligencePt = {
  resources: {
    show: 'Visualizar engajamento e performance'
  },
  pages: {
    engagementPerformance: {
      pageTitle: 'Inteligência de dados',
      appBar: {
        title: 'Inteligência de dados'
      },
      helpText:
        'Para visualizar o relatório de uma turma ou aluno, digite o nome como cadastrado no sistema.'
    }
  }
}
