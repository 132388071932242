import { Navigate, Route, Routes } from 'react-router-dom'

import { ContractRoutes } from './contracts/routes'
import { SalesOrderRoutes } from './sales-orders/routes'
import { ServiceMappingRoutes } from './service-mapping/routes'

export function BillingRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/contracts/*" element={<ContractRoutes />} />
      <Route path="/sales-orders/*" element={<SalesOrderRoutes />} />
      <Route path="/service-mapping/*" element={<ServiceMappingRoutes />} />

      <Route path="*" element={<Navigate replace to="/contracts" />} />
    </Routes>
  )
}
