import { Div } from '@positivote/design-system/components/Div'
import { Typography } from '@positivote/design-system/components/Typography'

import { i18n } from '@/common/i18n'

import { Mascot } from './Mascot'

export function LargerScreensOnly(): JSX.Element {
  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '$2xs'
      }}
    >
      <Mascot />
      <Typography
        data-testid="Typography-emptyListTitle"
        variant="headlineSmall"
        css={{ textAlign: 'center', color: '$on-surface' }}
      >
        {i18n().common.components.largerScreensOnly.title}
      </Typography>
      <Typography
        data-testid="Typography-emptyListSubTitle"
        variant="bodyLarge"
        css={{ textAlign: 'center', maxWidth: '330px', color: '$on-surface-variant' }}
      >
        {i18n().common.components.largerScreensOnly.subTitle}
      </Typography>
    </Div>
  )
}
