import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListApplicationPermissionApiParams,
  ListApplicationPermissionApiResult,
  ListLicenseApiResult,
  ListLicenseByOrganizationApiParams,
  ListLicenseByOrganizationApiResult,
  UpdateApplicationPermissionApiParams,
  UpdateLicenseApiParams
} from './contracts'
import {
  ListApplicationPermissionServiceParams,
  ListApplicationPermissionServiceResult,
  ListLicenseByOrganizationServiceParams,
  ListLicenseByOrganizationServiceResult,
  ListLicenseServiceParams,
  ListLicenseServiceResult,
  UpdateApplicationPermissionServiceParams,
  UpdateLicenseServiceParams
} from './contracts/services'
import {
  applicationPermissionApiResultSanitizer,
  licenseApiResultSanitizer,
  licenseByOrganizationApiResultSanitizer
} from './sanitizers'

export async function listLicenseService(
  params: ListLicenseServiceParams
): Promise<ListLicenseServiceResult> {
  const { data } = await hubApiService<null, ListLicenseApiResult>({
    resource: i18n().modules.hub.licenses.resources.listApplicationLicense,
    method: 'get',
    url: `/licenses/v1/licenses/${params.licenseId}/levels`
  })

  return licenseApiResultSanitizer(data)
}

export async function listLicenseByOrganizationService(
  params: ListLicenseByOrganizationServiceParams
): Promise<ListLicenseByOrganizationServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListLicenseByOrganizationApiParams, ListLicenseByOrganizationApiResult>({
    resource: i18n().modules.hub.dataManagement.resources.listLicensesByOrganization,
    method: 'get',
    url: `/licenses/v1/organization/${params.orgId}/licenses`,
    params: {
      page: params.page,
      size: params.perPage
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      page: params.page,
      data: licenseByOrganizationApiResultSanitizer(data)
    })
  }
}

export async function listApplicationPermissionService(
  params: ListApplicationPermissionServiceParams
): Promise<ListApplicationPermissionServiceResult[]> {
  const {
    data: { data }
  } = await hubApiService<
    ListApplicationPermissionApiParams,
    { data: ListApplicationPermissionApiResult[] }
  >({
    resource: i18n().modules.hub.licenses.resources.listApplicationPermission,
    method: 'get',
    url: '/manager/v1/appDatasets',
    params: {
      app_id: params.applicationId,
      institution_id: params.organizationId
    }
  })

  return applicationPermissionApiResultSanitizer(data)
}

export async function updateLicenseService({
  licenseId,
  ...params
}: UpdateLicenseServiceParams): Promise<void> {
  await hubApiService<UpdateLicenseApiParams, null>({
    resource: i18n().modules.hub.licenses.resources.updateApplicationLicense,
    method: 'put',
    url: `/licenses/v1/licenses/${licenseId}/levels`,
    body: params
  })
}

export async function updateApplicationPermissionService({
  permissionId,
  ...params
}: UpdateApplicationPermissionServiceParams): Promise<void> {
  await hubApiService<UpdateApplicationPermissionApiParams, null>({
    resource: i18n().modules.hub.licenses.resources.updateApplicationPermission,
    method: 'put',
    url: `/manager/v1/appDatasets/${permissionId}`,
    body: params
  })
}
