export function BotSeePhone(): JSX.Element {
  return (
    <svg
      width="215"
      height="296"
      viewBox="0 0 215 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19348_163946)">
        <path
          d="M177.206 142.199C141.416 150.216 124.114 112.451 111.278 95.2696"
          stroke="#BFC4CE"
          strokeWidth="7"
          strokeLinecap="round"
        />
        <ellipse
          cx="84.1982"
          cy="271.755"
          rx="64.0955"
          ry="13.874"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M135.164 252.59C135.164 250.88 136.55 249.494 138.26 249.494H153.854C155.564 249.494 156.95 250.88 156.95 252.59C156.95 254.3 155.564 255.686 153.854 255.686H138.26C136.55 255.686 135.164 254.3 135.164 252.59Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M26.1321 292.429C26.1321 290.719 27.5181 289.333 29.2279 289.333H44.8218C46.5316 289.333 47.9177 290.719 47.9177 292.429C47.9177 294.139 46.5316 295.525 44.8218 295.525H29.2279C27.5181 295.525 26.1321 294.139 26.1321 292.429Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M6.45734 165.229L8.00921 179.23C10.0522 197.658 22.4681 213.226 39.869 219.169L43.574 220.435C65.1095 227.793 88.3111 228.622 110.317 222.821L114.103 221.823C131.884 217.137 145.38 202.496 148.733 184.26L151.281 170.406C156.333 142.899 157.738 114.837 155.458 86.962L152.562 51.5844C151.065 33.3016 139.333 17.5002 122.369 10.9225C98.1478 1.53284 71.5071 0.580498 46.6773 8.21674C29.2868 13.5663 16.4571 28.4866 13.6584 46.6159L8.24302 81.6963C3.97818 109.34 3.37625 137.428 6.45448 165.226L6.45734 165.229Z"
          fill="#DAE0EC"
        />
        <path
          d="M147.745 58.7785L147.286 55.2908C145.7 43.2376 137.924 33.8181 128.115 32.069L122.663 31.0979C113.656 29.4924 104.514 29.5301 95.5354 31.2122L85.9769 33.002C76.0518 34.8596 68.3623 44.5897 67.1218 56.8634L66.8056 59.9892C65.789 70.0326 66.0149 80.2188 67.4757 90.1997C69.1772 101.829 76.6518 110.908 86.1145 112.844L93.0635 114.265C104.528 116.609 116.233 116.275 127.57 113.279L130.46 112.517C139.639 110.094 146.599 100.901 147.928 89.4462L147.981 88.9955C149.143 78.9754 149.064 68.7899 147.747 58.7785L147.745 58.7785Z"
          fill="#545B6D"
        />
        <circle
          cx="4.3018"
          cy="4.3018"
          r="4.3018"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 138.85 77.5258)"
          fill="white"
        />
        <circle
          cx="4.8736"
          cy="4.8736"
          r="4.8736"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 106.25 75.4755)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.927 91.4753C137.334 91.9899 137.247 92.7371 136.732 93.1442L131.461 97.3141C130.946 97.7212 130.199 97.634 129.792 97.1194C129.385 96.6048 129.472 95.8576 129.987 95.4505L135.258 91.2806C135.772 90.8735 136.52 90.9606 136.927 91.4753Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.828 92.1628C143.235 92.6774 143.148 93.4246 142.633 93.8317L137.362 98.0016C136.848 98.4087 136.101 98.3215 135.693 97.8069C135.286 97.2923 135.373 96.5451 135.888 96.138L141.159 91.9681C141.674 91.561 142.421 91.6481 142.828 92.1628Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.571 92.1242C109.978 92.6388 109.891 93.386 109.377 93.7931L104.106 97.963C103.591 98.3701 102.844 98.283 102.437 97.7684C102.03 97.2538 102.117 96.5065 102.631 96.0994L107.902 91.9295C108.417 91.5224 109.164 91.6096 109.571 92.1242Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.492 91.2975C102.899 91.8121 102.812 92.5593 102.297 92.9664L97.0264 97.1363C96.5118 97.5435 95.7646 97.4563 95.3575 96.9417C94.9504 96.4271 95.0376 95.6799 95.5522 95.2728L100.823 91.1029C101.338 90.6958 102.085 90.7829 102.492 91.2975Z"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          width="29.3547"
          height="34.6681"
          rx="14.6774"
          transform="matrix(-0.99937 -0.0354915 -0.0354915 0.99937 61.3499 103.763)"
          fill="#CCD3E0"
        />
        <circle
          cx="6.2903"
          cy="6.2903"
          r="6.2903"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 51.6028 114.1)"
          fill="#635F68"
        />
        <circle
          cx="7.33869"
          cy="7.33869"
          r="7.33869"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 99.3323 127.882)"
          fill="#B177D1"
        />
        <circle
          cx="2.09677"
          cy="2.09677"
          r="2.09677"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 93.7874 133.021)"
          fill="white"
        />
        <rect
          width="17.9117"
          height="3.79151"
          rx="1.89575"
          transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 151.308 133.754)"
          fill="#CCD3E0"
        />
        <rect
          width="17.9117"
          height="3.79151"
          rx="1.89575"
          transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 151.308 140.044)"
          fill="#CCD3E0"
        />
        <rect
          width="17.9117"
          height="3.79151"
          rx="1.89575"
          transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 151.308 127.464)"
          fill="#CCD3E0"
        />
        <rect
          width="21.3465"
          height="15.5785"
          rx="3"
          transform="matrix(0.115413 -0.993318 -0.993318 -0.115413 187.362 154.353)"
          fill="#9EA3AC"
        />
        <rect
          x="189.366"
          y="154.743"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 189.366 154.743)"
          fill="#A49EA9"
        />
        <rect
          x="193.382"
          y="154.876"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 193.382 154.876)"
          fill="#9EA3AC"
        />
        <rect
          x="191.83"
          y="147.595"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 191.83 147.595)"
          fill="#A49EA9"
        />
        <rect
          x="197.253"
          y="147.435"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 197.253 147.435)"
          fill="#9EA3AC"
        />
        <rect
          x="191.355"
          y="140.05"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 191.355 140.05)"
          fill="#A49EA9"
        />
        <rect
          x="196.435"
          y="139.92"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 196.435 139.92)"
          fill="#9EA3AC"
        />
        <path
          d="M84.39 10.7128C60.9682 9.01575 40.3593 21.4943 34.7179 29.3416C34.1824 30.0865 34.9541 30.8351 35.7808 30.4375C59.0496 19.2459 109.382 12.5235 84.39 10.7128Z"
          fill="#F9F4FF"
        />
        <circle
          cx="3.14515"
          cy="3.14515"
          r="3.14515"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 28.9534 33.3617)"
          fill="#F9F4FF"
        />
        <path
          d="M110.927 209.805C122.625 212.7 144.273 190.058 143.787 163.687C143.767 162.573 142.215 162.44 141.861 163.497C134.872 184.334 115.416 203.714 110.599 208.289C110.081 208.782 110.233 209.633 110.927 209.805Z"
          fill="#CCD3E0"
        />
        <rect
          x="192.135"
          y="101.864"
          width="23.0644"
          height="50.3224"
          rx="2"
          transform="rotate(18.3388 192.135 101.864)"
          fill="#505050"
        />
        <rect
          x="192.806"
          y="106.504"
          width="14.6774"
          height="39.8386"
          transform="rotate(18.3388 192.806 106.504)"
          fill="white"
        />
        <circle
          cx="184.257"
          cy="148.954"
          r="1.04838"
          transform="rotate(18.3388 184.257 148.954)"
          fill="#7E7E7E"
        />
        <path
          d="M46.6711 119.495C40.0314 138.715 28.2196 183.027 34.0905 206.51"
          stroke="#BFC4CE"
          strokeWidth="7"
          strokeLinecap="round"
        />
        <rect
          width="21.3465"
          height="15.5785"
          rx="3"
          transform="matrix(0.948575 -0.316551 -0.316551 -0.948575 25.7336 218.61)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 26.22 228.196)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 41.8889 225.109)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.942584 -0.333968 -0.333968 -0.942584 47.3 209.285)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 27.3958 232.735)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 34.8616 227.896)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 36.0364 232.435)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 43.0637 229.647)"
          fill="#9EA3AC"
        />
        <rect
          x="179.124"
          y="130.916"
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="rotate(-87.9322 179.124 130.916)"
          fill="#9EA3AC"
        />
        <rect
          width="6.00732"
          height="10.1374"
          rx="3.00366"
          transform="matrix(0.942584 -0.333968 -0.333968 -0.942584 27.4202 214.927)"
          fill="#878C96"
        />
        <path
          d="M126.176 90.735C126.176 94.1796 123.49 96.972 120.176 96.972C116.863 96.972 114.176 94.1796 114.176 90.735C114.176 87.2904 116.863 89.9554 120.176 89.9554C123.49 89.9554 126.176 87.2904 126.176 90.735Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_19348_163946">
          <rect
            width="213.056"
            height="295.049"
            fill="white"
            transform="translate(0.97168 0.475464)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
