import { yupResolver } from '@hookform/resolvers/yup'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { ValueInput } from '@positivote/design-system/components/ValueInput'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CachedIcon } from '@positivote/design-system/icons/Cached'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { RemoveCircleIcon } from '@positivote/design-system/icons/RemoveCircle'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { currencyUnmask } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  SalesOrderFormatted,
  SalesOrderOperationType,
  SalesOrderServiceFormatted,
  ServiceOperationForm
} from '@/modules/billing/sales-orders/contracts'
import { useUpdateSalesOrder } from '@/modules/billing/sales-orders/hooks'
import { serviceOperationFormSanitizer } from '@/modules/billing/sales-orders/sanitizers'
import { makeServiceOperationValidationSchema } from '@/modules/billing/sales-orders/validations'

interface ServiceOperationDialogProps {
  salesOrder: SalesOrderFormatted | null
  service: SalesOrderServiceFormatted | null
  operationType: SalesOrderOperationType
  isUpdating: boolean
  onClose: () => void
}

export function ServiceOperationDialog({
  salesOrder,
  service,
  operationType,
  isUpdating,
  onClose
}: ServiceOperationDialogProps): JSX.Element {
  const updateSalesOrder = useUpdateSalesOrder()
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<ServiceOperationForm>({
    mode: 'onSubmit',
    resolver: async (value, ...args) =>
      yupResolver(
        makeServiceOperationValidationSchema(
          operationType,
          service?.total ?? 0,
          i18n().modules.billing.salesOrders.components.serviceOperationDialog.totalValue(
            service?.totalFormatted ?? '0'
          )
        )
      )(serviceOperationFormSanitizer(value), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  function handleCloseDialog(): void {
    onClose()
    clearErrors()
    reset({ value: '' })
  }

  function onSubmit(model: ServiceOperationForm): void {
    if (salesOrder && service) {
      updateSalesOrder.mutate({
        model: {
          ...salesOrder,
          services: salesOrder.services.map((salesOrderService) =>
            salesOrderService.serviceId === service.serviceId
              ? {
                  ...salesOrderService,
                  operation: model.value
                    ? { type: operationType, value: currencyUnmask(model.value) }
                    : null
                }
              : salesOrderService
          )
        },
        onSuccess: () => {
          handleCloseDialog()
        }
      })
    }
  }

  useEffect(() => {
    if (service?.operationFormatted) {
      reset({ value: service.operationFormatted.value })
    }
  }, [reset, service?.operationFormatted])

  return (
    <Dialog.Container
      xl={3.5}
      md={5}
      sm={7}
      xs={12}
      data-testid="ServiceOperation"
      isOpen={!!salesOrder}
      onCancel={() => handleCloseDialog()}
    >
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={onSubmit}>
        <Dialog.Header align="center">
          <Dialog.HeaderTitle data-testid="ServiceOperation">
            {i18n().modules.billing.salesOrders.components.serviceOperationDialog.title(
              operationType,
              isUpdating
            )}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton data-testid="ServiceOperation" onCancel={handleCloseDialog} />
        </Dialog.Header>
        <Dialog.Content align="center">
          <Dialog.ContentText data-testid="ServiceOperation-indicateValue">
            {i18n().modules.billing.salesOrders.components.serviceOperationDialog.indicateValue}
          </Dialog.ContentText>
          <FormTextField
            control={control}
            name="value"
            label={i18n().modules.billing.salesOrders.components.serviceOperationDialog.inputs.value(
              operationType
            )}
            variant="outlined"
            leadingIcon={{ icon: operationType === 'addition' ? AddCircleIcon : RemoveCircleIcon }}
            trailingIcon={{
              icon: CloseIcon,
              onClick: () => reset({ value: '' })
            }}
            CustomInput={ValueInput}
            errorText={errors.value?.message}
          />
        </Dialog.Content>
        <Dialog.Footer align="center">
          <Dialog.FooterRefuseButton
            data-testid="ServiceOperation"
            LeadingIcon={<CancelIcon size={18} />}
            onClick={onClose}
            disabled={updateSalesOrder.isPending}
          >
            {i18n().modules.billing.salesOrders.components.serviceOperationDialog.buttons.refuse}
          </Dialog.FooterRefuseButton>
          <Dialog.FooterAcceptButton
            data-testid="ServiceOperation"
            LeadingIcon={<CachedIcon size={18} />}
            type="submit"
            isLoading={updateSalesOrder.isPending}
            disabled={hasAnyError}
          >
            {i18n().modules.billing.salesOrders.components.serviceOperationDialog.buttons.accept}
          </Dialog.FooterAcceptButton>
        </Dialog.Footer>
      </FormContainer>
    </Dialog.Container>
  )
}
