import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  CreateQuickAccessHookParams,
  RevokeQuickAccessHookParams,
  ShowQuickAccessHookParams,
  ShowQuickAccessHookResult
} from './contracts/hooks'
import {
  createQuickAccessService,
  revokeQuickAccessService,
  showQuickAccessService
} from './services'

const hookKey = 'quick-accesses'

export function useCreateQuickAccess(): UseMutationResult<
  void,
  ApplicationException,
  CreateQuickAccessHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateQuickAccessHookParams) => {
      try {
        const quickAccess = await createQuickAccessService(model)
        onSuccess?.(quickAccess)
        queryClient.setQueryData([hookKey, 'show', { classId: model.classId }], quickAccess)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowQuickAccess({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowQuickAccessHookParams): UseQueryResult<ShowQuickAccessHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showQuickAccess = await showQuickAccessService(model)
        onSuccess?.(showQuickAccess)
        return showQuickAccess
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useRevokeQuickAccess(): UseMutationResult<
  void,
  ApplicationException,
  RevokeQuickAccessHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'revoke'],
    mutationFn: async ({ model, onSuccess, onError }: RevokeQuickAccessHookParams) => {
      try {
        await revokeQuickAccessService(model)
        onSuccess?.(null)
        queryClient.setQueryData([hookKey, 'show', { classId: model.classId }], null)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useClearQuickAccess(): UseMutationResult<
  void,
  ApplicationException,
  ShowQuickAccessHookParams
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'clear'],
    mutationFn: async ({ model }: ShowQuickAccessHookParams) => {
      await Promise.resolve(
        queryClient.setQueryData([hookKey, 'show', { classId: model.classId }], null)
      )
    }
  })
}
