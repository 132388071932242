import { AddSapServiceForm, ServiceMapping, ServiceMappingApi } from './contracts'

export function fromServiceMappingApiSanitizer(services: ServiceMappingApi[]): ServiceMapping[] {
  return services.map((service) => ({
    id: service.id,
    createdAt: service.created_at,
    updatedAt: service.updated_at,
    application: { id: service.app_id, name: service.app_name },
    sapServices: service.sap_services.map((sapService) => ({
      id: sapService.id,
      name: sapService.name
    }))
  }))
}

export function toServiceMappingApiSanitizer(
  service: Omit<ServiceMapping, 'createdAt' | 'id' | 'updatedAt'>
): Omit<ServiceMappingApi, 'created_at' | 'id' | 'updated_at'> {
  return {
    app_id: service.application.id,
    app_name: service.application.name,
    sap_services: service.sapServices.map((sapService) => ({
      id: sapService.id,
      name: sapService.name
    }))
  }
}

export function addServiceFormSanitizer(params: Partial<AddSapServiceForm>): AddSapServiceForm {
  return {
    id: (params.id ?? '').trim(),
    name: (params.name ?? '').trim()
  }
}
