import { LtiApplication } from './contracts/models'

const storageLtiApplicationKey = '@hub/lti:application'

export function getLtiApplicationSessionRepository(): LtiApplication | null {
  const token = sessionStorage.getItem(storageLtiApplicationKey)
  if (token !== null) {
    return JSON.parse(token) as LtiApplication
  }
  return token
}

export function saveLtiApplicationSessionRepository(ssoApplication: LtiApplication): void {
  sessionStorage.setItem(storageLtiApplicationKey, JSON.stringify(ssoApplication))
}

export function removeLtiApplicationSessionRepository(): void {
  sessionStorage.removeItem(storageLtiApplicationKey)
}
