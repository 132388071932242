import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListChildrenOrganizationApiParams,
  ListChildrenOrganizationApiResult,
  ListChildrenOrganizationServiceParams,
  ListChildrenOrganizationServiceResult,
  ListOrganizationPermissionApiResult,
  ListOrganizationPermissionServiceParams,
  ListOrganizationPermissionServiceResult,
  ListShortOrganizationApiParams,
  ListShortOrganizationApiResult,
  ListShortOrganizationServiceParams,
  ListShortOrganizationServiceResult,
  UpdateOrganizationPermissionApiParams,
  UpdateOrganizationPermissionServiceParams
} from './contracts'
import {
  fromOrganizationPermissionApiSanitizer,
  fromShortOrganizationApiSanitizer
} from './sanitizers'

export async function listShortOrganizationService(
  params: ListShortOrganizationServiceParams
): Promise<ListShortOrganizationServiceResult> {
  const { data } = await hubApiService<
    ListShortOrganizationApiParams,
    ListShortOrganizationApiResult
  >({
    resource: i18n().modules.hub.organizations.resources.listShort,
    method: 'get',
    url: `/organization/v1/organization${params.callPublic ? '/public' : ''}`,
    params: {
      page: params.page,
      paginate_by: params.perPage,
      search: params.search,
      id_org_kind: params.kind,
      is_in_register: params.isInRegister
    }
  })
  return listHubApiResultSanitizer({
    page: params.page,
    content_range: params.perPage,
    total_pages: Math.ceil(data.total / (params.perPage ?? 1)),
    total: data.total,
    orderBy: 'id',
    order: 'asc',
    data: fromShortOrganizationApiSanitizer(data.data)
  })
}

export async function listChildrenOrganizationService({
  organizationId,
  search,
  cityId,
  state
}: ListChildrenOrganizationServiceParams): Promise<ListChildrenOrganizationServiceResult[]> {
  const {
    data: { data }
  } = await hubApiService<ListChildrenOrganizationApiParams, ListChildrenOrganizationApiResult>({
    resource: i18n().modules.hub.organizations.resources.listShort,
    method: 'get',
    url: `/organization/v1/organization/${organizationId}/children`,
    params: {
      city_id: cityId,
      search,
      state_code: state
    }
  })

  return fromShortOrganizationApiSanitizer(data)
}

export async function listOrganizationPermissionService({
  organizationId
}: ListOrganizationPermissionServiceParams): Promise<ListOrganizationPermissionServiceResult> {
  const { data } = await hubApiService<undefined, ListOrganizationPermissionApiResult>({
    resource: i18n().modules.hub.organizations.resources.listPermission,
    method: 'get',
    url: `/organization/v1/organization/${organizationId}/roles-permissions`
  })

  return fromOrganizationPermissionApiSanitizer(data)
}

export async function updateOrganizationPermissionService(
  params: UpdateOrganizationPermissionServiceParams
): Promise<void> {
  await hubApiService<UpdateOrganizationPermissionApiParams, undefined>({
    resource: i18n().modules.hub.organizations.resources.updatePermission,
    method: 'patch',
    url: `/organization/v1/organization/${params.institutionId}/roles-permissions/${params.permissionId}`,
    body: {
      enable: params.enable
    }
  })
}
