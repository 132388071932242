export const licensesPt = {
  resources: {
    listApplicationLicense: 'Listar licenças de aplicação',
    updateApplicationLicense: 'Atualizar licenças de aplicação',
    listApplicationPermission: 'Listar permissões de acesso',
    updateApplicationPermission: 'Atualizar permissões de acesso'
  },
  pages: {
    applicationAccess: {
      pageTitle: 'Configuração de acesso da aplicação',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          accessConfiguration: 'Configuração de Acesso'
        }
      },
      tabs: {
        accessDistribution: 'Distribuir acessos',
        applicationPermission: 'Permissões do App'
      },
      accessDistribution: {
        applicationImageAlt: 'Imagem da aplicação',
        cardTitle:
          'Selecione abaixo os níveis de ensino que você deseja permitir ter acesso à aplicação',
        totalStudents: (params: number): string => ` ${params} alunos`,
        allLevels: 'Todos os níveis',
        activeAccess: (params: { totalLicenses: number; totalAvailableLicenses: number }): string =>
          `${params.totalLicenses} de ${params.totalAvailableLicenses} Acessos Ativos`,
        applyChanges: 'Aplicar alterações',
        alert: {
          updateApplicationLicenses: 'Licenças alteradas com sucesso!',
          updateAccessDistribution: 'permissões alteradas com sucesso!'
        }
      },
      applicationPermission: {
        permissionsPerStudents: 'Permissões por aluno',
        helpSendData:
          'As opções marcadas como "Ligado" serão enviadas à Edtech. Caso você o desligue, algumas funções podem não funcionar corretamente.',
        helpSendPermissible:
          'Quando marcado como "Ligado", parte de seus dados serão ocultados para a Edtech. Ex.: 098.***.***-15.',
        minimization: 'Minimização',
        sendData: 'Envio de dados',
        recommended: 'Recomendável',
        switchLabel: (permission: boolean): string => (permission ? 'Ligado' : 'Desligado'),
        anonymizedExample: (anonymizedExample: string): string => `Exemplo: ${anonymizedExample}`,
        alert: {
          updated: 'Permissões alteradas com sucesso!'
        }
      }
    }
  }
}
