import { AccountPermissionMeApi } from '@/modules/hub/accounts/contracts/apis'

import { ResetPasswordApiParams } from './contracts/apis'
import {
  ChangePasswordForm,
  OrganizationLoginForm,
  PasswordLoginForm,
  ResetPasswordForm,
  UsernameLoginForm
} from './contracts/forms'
import { Permission } from './contracts/models'
import { RecoverPasswordServiceParams, ResetPasswordServiceParams } from './contracts/services'

export function usernameLoginFormSanitizer(params: UsernameLoginForm): UsernameLoginForm {
  return {
    username: (params.username || '').trim()
  }
}

export function passwordLoginFormSanitizer(params: PasswordLoginForm): PasswordLoginForm {
  return {
    username: (params.username || '').trim(),
    password: (params.password || '').trim()
  }
}

export function organizationLoginFormSanitizer(
  params: OrganizationLoginForm
): OrganizationLoginForm {
  return {
    organizationId: params.organizationId
  }
}

export function recoverPasswordFormSanitizer(
  params: RecoverPasswordServiceParams
): RecoverPasswordServiceParams {
  return {
    email: (params.email || '').trim()
  }
}

export function changePasswordFormSanitizer(params: ChangePasswordForm): ChangePasswordForm {
  return {
    code: (params.code || '').trim(),
    newPassword: (params.newPassword || '').trim(),
    confirmNewPassword: (params.confirmNewPassword || '').trim()
  }
}

export function resetPasswordFormSanitizer(params: ResetPasswordForm): ResetPasswordForm {
  return {
    newPassword: (params.newPassword || '').trim(),
    confirmNewPassword: (params.confirmNewPassword || '').trim()
  }
}

export function resetPasswordApiParamsSanitizer(
  params: ResetPasswordServiceParams
): ResetPasswordApiParams {
  return {
    AuthFlow: 'NEW_PASSWORD_REQUIRED',
    AuthParameters: {
      USERNAME: params.username,
      PASSWORD: params.password,
      INSTITUTION: params.institution,
      NEW_PASSWORD: params.newPassword
    }
  }
}

export function fromPermissionMeApiSanitizer(
  permissions: AccountPermissionMeApi[][]
): Permission[] {
  return (
    permissions[0]?.map((permission) => ({
      id: permission.id,
      key: permission.key,
      type: permission.type
    })) ?? []
  )
}
