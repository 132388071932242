import { useAuth } from '@/modules/hub/auth/contexts'
import { useListOrganizationPermission } from '@/modules/hub/organizations/hooks'

import { PermissionRoleHookResult } from './contracts'
import { roleToPermissionRole } from './helpers'

export const hookKey = 'security'

export function usePermissionRole(): PermissionRoleHookResult {
  const { profile, session } = useAuth()

  const { data: listOrganizationPermissions, isLoading } = useListOrganizationPermission({
    model: {
      organizationId: session?.orgId ?? null
    },
    queryOptions: {
      enabled: !!session?.schoolId
    }
  })

  const filteredPermissions = listOrganizationPermissions?.find(
    (permission) => permission.name === roleToPermissionRole(profile?.role.code ?? '')
  )

  const disabledFields =
    filteredPermissions?.permissions
      .filter((permission) => !permission.enable)
      .map((permission) => permission.code) ?? []

  function checkPermissionRole(field?: string): boolean {
    if (profile?.role.code === 'administrador') {
      return true
    }
    if (!field && filteredPermissions?.permissions.some((permission) => permission.enable)) {
      return true
    }
    if (field) {
      return !!filteredPermissions?.permissions.find((permission) => permission.code === field)
        ?.enable
    }
    return false
  }

  return { checkPermissionRole, isLoading, disabledFields }
}
