import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListChildrenOrganizationHookParams,
  ListChildrenOrganizationHookResult,
  ListOrganizationPermissionHookParams,
  ListOrganizationPermissionHookResult,
  ListShortOrganizationHookParams,
  ListShortOrganizationHookResult,
  UpdateOrganizationPermissionHookParams
} from './contracts'
import {
  listChildrenOrganizationService,
  listOrganizationPermissionService,
  listShortOrganizationService,
  updateOrganizationPermissionService
} from './services'

export const hookKey = 'organizations'

export function useListShortOrganization({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListShortOrganizationHookParams): UseQueryResult<
  ListShortOrganizationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listShort', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listShortOrganizations = await listShortOrganizationService(model)
        onSuccess?.(listShortOrganizations)
        return listShortOrganizations
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListChildrenOrganization({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListChildrenOrganizationHookParams): UseQueryResult<
  ListChildrenOrganizationHookResult[],
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listChildren', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listShortOrganizations = await listChildrenOrganizationService(model)
        onSuccess?.(listShortOrganizations)
        return listShortOrganizations
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListOrganizationPermission({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListOrganizationPermissionHookParams): UseQueryResult<
  ListOrganizationPermissionHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listPermission', model],
    queryFn: async () => {
      try {
        const listPermissions = await listOrganizationPermissionService(model)
        onSuccess?.(listPermissions)
        return listPermissions
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateOrganizationPermission(): UseMutationResult<
  void,
  ApplicationException,
  UpdateOrganizationPermissionHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [hookKey, 'updatePermission'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateOrganizationPermissionHookParams) => {
      try {
        await updateOrganizationPermissionService(model)
        queryClient.setQueryData(
          [hookKey, 'listPermission', { organizationId: model.institutionId }],
          (oldRoles: ListOrganizationPermissionHookResult | undefined) =>
            oldRoles?.map((role) => ({
              ...role,
              permissions: role.permissions.map((permission) =>
                permission.id === model.permissionId && role.id === model.roleId
                  ? { ...permission, enable: model.enable }
                  : permission
              )
            }))
        )
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
