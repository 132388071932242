import CryptoJS from 'crypto-js'

export function encryptValue(value: string, secretKey: string): string {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16))
  const encryptedValue = CryptoJS.AES.encrypt(value, key, { iv, mode: CryptoJS.mode.OFB })
  return encryptedValue.toString()
}

export function decryptValue(value: string, secretKey: string): string {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16))
  const decryptedValue = CryptoJS.AES.decrypt(value, key, { iv, mode: CryptoJS.mode.OFB })
  return decryptedValue.toString(CryptoJS.enc.Utf8)
}
