export const securityConfigurationPt = {
  pages: {
    list: {
      pageTitle: 'Configuração de Segurança',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          securityConfiguration: 'Configuração de Segurança'
        }
      },
      switchLabel: (enable: boolean): string => (enable ? 'Permitido' : 'Restrito'),
      roles: {
        permissions: 'Permissões',
        student: 'Alunos',
        guardian: 'Responsáveis',
        teacher: 'Professores',
        director: 'Diretores',
        coordinator: 'Coordenadores',
        staff: 'Colaboradores'
      },
      permissions: {
        'user.name': 'Alterar nome',
        'user.password': 'Alterar senha',
        'user.picture': 'Alterar foto de perfil',
        'user.birthday': 'Alterar data de nascimento',
        'user.gender': 'Alterar genero',
        'user.document': 'Alterar documento',
        'user.phone': 'Alterar telefone'
      }
    }
  }
}
