import { applicationLicenseDictionaryColorPercent } from './constants'

export function checkProgressBarColor(percent: number): string {
  let color = '$success'

  if (percent >= applicationLicenseDictionaryColorPercent.warning) {
    color = '$warning'
  }

  if (percent >= applicationLicenseDictionaryColorPercent.critical) {
    color = '$critical'
  }

  return color
}
