export function Error404(): JSX.Element {
  return (
    <svg
      width="376"
      height="142"
      viewBox="0 0 376 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: '80%' }}
    >
      <g opacity="0.38">
        <path
          d="M2 121.09C0.89543 121.09 0 120.194 0 119.09V86.5544C0 86.1431 0.126826 85.7417 0.363196 85.4051L56.1298 5.98324C56.5042 5.44996 57.115 5.13253 57.7666 5.13253H100.223C101.328 5.13253 102.223 6.02796 102.223 7.13253V82.5917C102.223 83.6963 103.118 84.5917 104.223 84.5917H113.929C115.033 84.5917 115.929 85.4871 115.929 86.5917V119.09C115.929 120.194 115.033 121.09 113.929 121.09H104.223C103.118 121.09 102.223 121.985 102.223 123.09V140C102.223 141.105 101.328 142 100.223 142H61.5825C60.4779 142 59.5825 141.105 59.5825 140V123.09C59.5825 121.985 58.6871 121.09 57.5825 121.09H2ZM63.009 59.9259C63.009 57.939 60.4255 57.1677 59.3363 58.8295L44.4797 81.4953C43.6079 82.8254 44.5621 84.5917 46.1524 84.5917H61.009C62.1135 84.5917 63.009 83.6963 63.009 82.5917V59.9259Z"
          fill="#5D6675"
        />
        <path
          d="M130.225 70.5248C130.225 48.6006 134.857 31.3655 144.121 18.8193C153.512 6.2731 167.916 0 187.333 0C206.623 0 220.963 6.33646 230.354 19.0094C239.745 31.5556 244.441 48.7274 244.441 70.5248C244.441 92.4489 239.745 109.747 230.354 122.42C220.963 134.967 206.623 141.24 187.333 141.24C167.916 141.24 153.512 134.967 144.121 122.42C134.857 109.747 130.225 92.4489 130.225 70.5248ZM202.752 70.5248C202.752 60.3864 201.737 52.7827 199.706 47.7135C197.676 42.5176 193.551 39.9197 187.333 39.9197C180.988 39.9197 176.8 42.5176 174.769 47.7135C172.739 52.7827 171.724 60.3864 171.724 70.5248C171.724 80.7898 172.739 88.5203 174.769 93.7162C176.8 98.7854 180.988 101.32 187.333 101.32C193.551 101.32 197.676 98.7854 199.706 93.7162C201.737 88.5203 202.752 80.7898 202.752 70.5248Z"
          fill="#5D6675"
        />
        <path
          d="M262.071 121.09C260.967 121.09 260.071 120.194 260.071 119.09V86.5544C260.071 86.1431 260.198 85.7417 260.434 85.4051L316.201 5.98324C316.575 5.44996 317.186 5.13253 317.838 5.13253H360.294C361.399 5.13253 362.294 6.02796 362.294 7.13253V82.5917C362.294 83.6963 363.19 84.5917 364.294 84.5917H374C375.105 84.5917 376 85.4871 376 86.5917V119.09C376 120.194 375.105 121.09 374 121.09H364.294C363.19 121.09 362.294 121.985 362.294 123.09V140C362.294 141.105 361.399 142 360.294 142H321.654C320.549 142 319.654 141.105 319.654 140V123.09C319.654 121.985 318.758 121.09 317.654 121.09H262.071ZM323.08 59.9259C323.08 57.939 320.497 57.1678 319.407 58.8295L304.551 81.4953C303.679 82.8254 304.633 84.5917 306.224 84.5917H321.08C322.185 84.5917 323.08 83.6963 323.08 82.5917V59.9259Z"
          fill="#5D6675"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M47.7163 25.6485L57.2922 11.8592C57.666 11.321 58.2797 11 58.9349 11H62.2336C64.6011 11 65.9504 16.7421 64.1991 18.3352C63.5888 18.8903 62.9667 19.4914 62.3333 20.1429C59.0045 23.5668 55.3641 26.029 50.0115 28.4899C48.2208 29.3132 46.5921 27.2674 47.7163 25.6485Z"
          fill="white"
        />
        <path
          d="M309.813 23.6612L319.687 9.83752C320.063 9.31193 320.669 9 321.315 9H324.768C327.143 9 328.486 14.8163 326.71 16.3937C326.104 16.9324 325.486 17.5142 324.857 18.1429C321.412 21.588 317.642 24.0596 312.08 26.5358C310.276 27.3391 308.666 25.2682 309.813 23.6612Z"
          fill="white"
        />
        <path
          d="M147.638 23.3134C151.163 16.0868 157.412 11.7932 162.273 9.61469C163.921 8.87606 165.194 10.6401 164.174 12.1307L145.034 40.1038C143.892 41.7731 141.669 40.9263 142.119 38.9543C143.102 34.6439 144.82 29.0927 147.638 23.3134Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
