import { useAlert } from '@positivote/design-system/hooks'
import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  ChangePasswordHookParams,
  ChangePasswordHookResult,
  RecoverPasswordHookParams,
  RecoverPasswordHookResult,
  ResetPasswordHookParams
} from './contracts/hooks'
import { changePasswordService, recoverPasswordService, resetPasswordService } from './services'

const hookKey = 'auth'

export function useRecoverPassword(): UseMutationResult<
  RecoverPasswordHookResult,
  ApplicationException,
  RecoverPasswordHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'recoverPassword'],
    mutationFn: async ({ model, onSuccess, onError }: RecoverPasswordHookParams) => {
      try {
        const recoverPasswordResult = await recoverPasswordService(model)
        onSuccess?.(recoverPasswordResult)
        addAlertMessage({
          alertKey: 'recoverPassword',
          subTitle: i18n().modules.hub.auth.pages.recoverPassword.alert.sendRecoveryCode,
          severity: 'success'
        })
        return recoverPasswordResult
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useChangePassword(): UseMutationResult<
  ChangePasswordHookResult,
  ApplicationException,
  ChangePasswordHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'changePassword'],
    mutationFn: async ({ model, onSuccess, onError }: ChangePasswordHookParams) => {
      try {
        const changePasswordResult = await changePasswordService(model)
        onSuccess?.(changePasswordResult)
        addAlertMessage({
          alertKey: 'changePassword',
          subTitle: i18n().modules.hub.auth.pages.changePassword.alert.successChangePassword,
          severity: 'success'
        })
        return changePasswordResult
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useResetPassword(): UseMutationResult<
  void,
  ApplicationException,
  ResetPasswordHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'resetPassword'],
    mutationFn: async ({ model, onSuccess, onError }: ResetPasswordHookParams) => {
      try {
        await resetPasswordService(model)
        onSuccess?.(null)

        addAlertMessage({
          alertKey: 'resetPassword',
          subTitle: i18n().modules.hub.auth.pages.resetPassword.alert.successChangePassword,
          severity: 'success'
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
