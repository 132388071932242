export const dataManagementPt = {
  resources: {
    listLicensesByOrganization: 'Listar licenças por organização'
  },
  pages: {
    menu: {
      pageTitle: 'Gerenciamento de dados',
      appBar: {
        title: 'Gerenciamento de dados'
      },
      option: {
        myInstitutions: 'Minhas instituições',
        myInstitutionsDescription: 'Cadastre, edite e gerencie suas instituições',
        myUsers: 'Meus usuários',
        myImports: 'Minhas importações',
        myImportsDescription:
          'Envie arquivos de planilhas para cadastros em massa de usuários, turmas e disciplinas.',
        myUsersDescription: 'Gerencie seus usuários',
        teachingLevelsAndClasses: 'Níveis de ensino e turmas',
        teachingLevelsAndClassesDescription: 'Defina o ano letivo, ensalamento e níveis de ensino',
        accessConfiguration: 'Configuração de acesso',
        accessConfigurationDescription: 'Distribua acessos e permissões',
        securityConfiguration: 'Configuração de Segurança',
        securityConfigurationDescription:
          'Defina o nível de permissões e restrições para seus usuários',
        onboarding: 'Acompanhamento de Cadastros',
        educationLevelsAndClasses: 'Níveis de ensino e turmas',
        educationLevelsAndClassesDescription:
          'Defina o ano letivo, disciplinas, ensalamento e níveis de ensino',
        onboardingDescription:
          'Acompanhamento de escolas e redes ques estão em processo de cadastro ou pré cadastro'
      }
    },
    levelsAndClasses: {
      pageTitle: 'Níveis de ensino e turmas',
      tooltip: 'Para criar turmas é necessário possuir um ano letivo criado',
      tooltipImport: 'Para importação de planilha é necessário possuir um ano letivo criado',
      option: {
        schoolYear: 'Ano letivo',
        schoolYearDescription: 'Defina o ano letivo',
        discipline: 'Disciplinas',
        disciplineDescription: 'Cadastre, edite e gerencie suas disciplinas',
        schoolClasses: {
          title: 'Turmas',
          description: 'Cadastre, edite e gerencie suas turmas'
        }
      },
      appBar: {
        breadcrumbs: {
          overview: 'Visão geral',
          title: 'Níveis de ensino e turmas'
        }
      }
    },
    accessConfiguration: {
      appBar: {
        breadcrumbs: {
          overview: 'Visão geral',
          title: 'Configuração de Acesso'
        }
      },
      emptyResult: {
        title: 'Ops! Esta Instituição ainda não foi validada',
        subTitle: 'Aguarde o processamento do sistema para começar gerenciar os acessos'
      }
    }
  }
}
