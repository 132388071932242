import * as yup from 'yup'

import { isDateSmallerToNow, validateCpf } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import { UpdateProfileForm } from './contracts'

export function makeUpdateStudentProfileValidationSchema(
  disabledFields: string[]
): yup.ObjectSchema<UpdateProfileForm> {
  return yup.object().shape({
    name: disabledFields.includes('user.name')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup.string().required(i18n().common.validators.required),
    birthday: disabledFields.includes('user.birthday')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup
          .string()
          .required(i18n().common.validators.required)
          .test({
            name: 'isDateSmallerToNow',
            message: i18n().common.validators.isDateSmallerToNow,
            test: (value) => isDateSmallerToNow(value)
          }),
    gender: disabledFields.includes('user.gender')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup.string().required(i18n().common.validators.required),
    cpf: disabledFields.includes('user.document')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup
          .string()
          .optional()
          .test({
            name: 'validateCpf',
            message: i18n().common.validators.cpfInvalid,
            test: (value) => validateCpf(value)
          }),
    picture: disabledFields.includes('user.picture')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup.string().optional(),
    isDpoUser: yup.boolean().optional(),
    phone: disabledFields.includes('user.phone')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup.string().optional().min(11, i18n().common.validators.phoneInvalid)
  })
}

export function makeUpdateProfileValidationSchema(
  disabledFields: string[]
): yup.ObjectSchema<UpdateProfileForm> {
  return makeUpdateStudentProfileValidationSchema(disabledFields).shape({
    cpf: disabledFields.includes('user.document')
      ? (yup.string().optional() as yup.StringSchema<string>)
      : yup
          .string()
          .required(i18n().common.validators.required)
          .test({
            name: 'validateCpf',
            message: i18n().common.validators.cpfInvalid,
            test: (value) => validateCpf(value)
          })
  })
}
