import { i18n } from '@/common/i18n'

import { SalesOrderStatus } from './contracts/models'

export const SALE_ORDER_STATUS_OPTIONS: Array<{
  key: SalesOrderStatus
  value: string
}> = [
  {
    key: 'CREATED',
    value: i18n().modules.billing.salesOrders.pages.list.filter.input.status.CREATED
  },
  {
    key: 'PROCESSED',
    value: i18n().modules.billing.salesOrders.pages.list.filter.input.status.PROCESSED
  },
  { key: 'SENT', value: i18n().modules.billing.salesOrders.pages.list.filter.input.status.SENT },
  { key: 'ERROR', value: i18n().modules.billing.salesOrders.pages.list.filter.input.status.ERROR }
]
