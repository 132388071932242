import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'

import { CreateBulkEnrollmentApiParams, CreateBulkEnrollmentServiceParams } from './contracts'
import { createBulkEnrollmentApiSanitizer } from './sanitizers'

export async function createBulkEnrollmentService({
  classroomId,
  enrollments
}: CreateBulkEnrollmentServiceParams): Promise<void> {
  await hubApiService<CreateBulkEnrollmentApiParams, null>({
    resource: i18n().modules.hub.classroom.resources.bulkEnrollments,
    method: 'post',
    url: '/enrollment/v1/enrollment/bulk',
    body: {
      classroom_id: classroomId,
      enrollments: createBulkEnrollmentApiSanitizer(enrollments)
    }
  })
}

export async function removeBulkEnrollmentService({
  classroomId,
  enrollments
}: CreateBulkEnrollmentServiceParams): Promise<void> {
  await hubApiService<CreateBulkEnrollmentApiParams, null>({
    resource: i18n().modules.hub.classroom.resources.removeBulkEnrollments,
    method: 'delete',
    url: '/enrollment/v1/enrollment/bulk',
    body: {
      classroom_id: classroomId,
      enrollments: createBulkEnrollmentApiSanitizer(enrollments)
    }
  })
}
