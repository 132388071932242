import { BottomNavigation } from '@positivote/design-system/components/BottomNavigation'
import {
  DrawerNavigation,
  DrawerProps
} from '@positivote/design-system/components/DrawerNavigation'
import { RailNavigation } from '@positivote/design-system/components/RailNavigation'
import { useTheme } from '@positivote/design-system/hooks'
import { AreaChartIcon } from '@positivote/design-system/icons/AreaChart'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { DashboardIcon } from '@positivote/design-system/icons/Dashboard'
import { DescriptionIcon } from '@positivote/design-system/icons/Description'
import { HomeIcon } from '@positivote/design-system/icons/Home'
import { InsertChartIcon } from '@positivote/design-system/icons/InsertChart'
import { InsightsIcon } from '@positivote/design-system/icons/Insights'
import { LogoutIcon } from '@positivote/design-system/icons/Logout'
import { MapIcon } from '@positivote/design-system/icons/Map'
import { QuickAccessIcon } from '@positivote/design-system/icons/QuickAccess'
import { SellIcon } from '@positivote/design-system/icons/Sell'
import { SettingsIcon } from '@positivote/design-system/icons/Settings'
import { SettingsEthernetIcon } from '@positivote/design-system/icons/SettingsEthernet'
import { Breakpoint } from '@positivote/design-system/theme'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { HubDarkIcon } from '@/common/assets/icons/HubDarkIcon'
import { HubIcon } from '@/common/assets/icons/HubIcon'
import { HubLogo } from '@/common/assets/icons/HubLogo'
import { MonitooraIcon } from '@/common/assets/icons/MonitooraIcon'
import { TextDialog } from '@/common/components/TextDialog'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

import { BillingLogo } from './BillingLogo'
import { HubDarkLogo } from './HubDarkLogo'
import { MonitooraLogo } from './MonitooraLogo'

const roleKindsDict: { hub: string[]; billing: string[]; monitoora: string[] } = {
  hub: [
    'administrador',
    'instituicao',
    'diretor',
    'coordenador',
    'professor',
    'responsavel',
    'aluno',
    'colaborador'
  ],
  billing: ['billing'],
  monitoora: MONITOORA_ROLES
}

export function Drawer(): JSX.Element {
  const { logout, profile, whiteLabel, isLoading: isAuthLoading, permissions } = useAuth()
  const navigate = useNavigate()
  const { breakpoint, mode } = useTheme()
  const location = useLocation()
  const [isOpenDrawer, setIsOpenDrawer] = useState(breakpoint > Breakpoint.md)
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)

  const isLoading = isAuthLoading || !profile

  const handleNavigate = useCallback(
    (path: string) => {
      if (path !== location.pathname) {
        navigate(path)
      }
    },
    [location.pathname, navigate]
  )

  useEffect(() => {
    const divRoot = document.getElementById('root')
    if (breakpoint <= Breakpoint.xs) {
      if (divRoot) {
        divRoot.style.flexDirection = 'column-reverse'
      }
    } else {
      if (divRoot) {
        divRoot.style.flexDirection = 'row'
      }
      if (breakpoint <= Breakpoint.md) {
        setIsOpenDrawer(false)
      } else {
        setIsOpenDrawer(true)
      }
    }
  }, [breakpoint])

  const drawerOptions = useMemo<DrawerProps['options']>(() => {
    const allOptions: Array<{
      key: string
      icon: React.ReactNode
      label: string
      action: () => void
      selected: boolean
      roles: string[]
      permissions: string[]
      organizations: string[]
      roleKind: keyof typeof roleKindsDict
      breakpoint: Breakpoint
    }> = [
      // HUB
      {
        key: 'home',
        icon: <HomeIcon data-testid="Drawer-HomeIcon" />,
        label: i18n().common.components.drawer.options.home,
        action: () => handleNavigate('/'),
        selected: location.pathname === '/',
        roles: [
          'administrador',
          'instituicao',
          'diretor',
          'coordenador',
          'professor',
          'responsavel',
          'aluno',
          'colaborador'
        ],
        permissions: [],
        organizations: [],
        roleKind: 'hub',
        breakpoint: Breakpoint.xs
      },
      {
        key: 'dataIntelligence',
        icon: <InsightsIcon data-testid="Drawer-InsightsIcon" />,
        label: i18n().common.components.drawer.options.dataIntelligence,
        action: () => handleNavigate('/data-intelligence'),
        selected: location.pathname.startsWith('/data-intelligence'),
        roles: ['administrador', 'instituicao', 'diretor', 'coordenador', 'professor'],
        permissions: [],
        organizations: [],
        roleKind: 'hub',
        breakpoint: Breakpoint.lg
      },
      {
        key: 'dataAnalysis',
        icon: <AreaChartIcon data-testid="Drawer-AreaChartIcon" />,
        label: i18n().common.components.drawer.options.dataAnalysis,
        action: () => handleNavigate('/data-analysis'),
        selected: location.pathname.startsWith('/data-analysis'),
        roles: [
          'administrador',
          'instituicao',
          'diretor',
          'coordenador',
          'professor',
          'colaborador'
        ],
        permissions: [],
        organizations: import.meta.env.VITE_NEDU_SCHOOLS_CAN_SEE_ANALYTICS.split(','),
        roleKind: 'hub',
        breakpoint: Breakpoint.sm
      },
      {
        key: 'monitoora',
        icon: <DashboardIcon data-testid="Drawer-DashboardIcon" />,
        label: i18n().common.components.drawer.options.monitoora,
        action: () => handleNavigate('/monitoora'),
        selected: location.pathname.startsWith('/monitoora'),
        roles: [
          'administrador',
          'instituicao',
          'diretor',
          'coordenador',
          'professor',
          'colaborador'
        ],
        permissions: [],
        organizations: import.meta.env.VITE_CIASC_SCHOOLS_CAN_SEE_ANALYTICS.split(','),
        roleKind: 'hub',
        breakpoint: Breakpoint.sm
      },
      {
        key: 'accessReports',
        icon: <InsertChartIcon data-testid="Drawer-InsertChartIcon" />,
        label: i18n().common.components.drawer.options.accessReports,
        action: () => handleNavigate('/reports/access'),
        selected: location.pathname.startsWith('/reports'),
        roles: [
          'administrador',
          'instituicao',
          'coordenador',
          'diretor',
          'professor',
          'colaborador'
        ],
        permissions: [],
        organizations: [],
        roleKind: 'hub',
        breakpoint: Breakpoint.lg
      },
      {
        key: 'dataManagement',
        icon: <SettingsEthernetIcon data-testid="Drawer-SettingsEthernetIcon" />,
        label: i18n().common.components.drawer.options.dataManagement,
        action: () => handleNavigate('/data-management'),
        selected: location.pathname.startsWith('/data-management'),
        roles: ['administrador', 'instituicao'],
        permissions: ['app-access'],
        organizations: [],
        roleKind: 'hub',
        breakpoint: Breakpoint.lg
      },
      {
        key: 'quickAccess',
        icon: <QuickAccessIcon data-testid="Drawer-QuickAccessIcon" />,
        label: i18n().common.components.drawer.options.quickAccess,
        action: () => handleNavigate('/quick-access/classroom'),
        selected: location.pathname.startsWith('/quick-access'),
        roles: ['professor'],
        permissions: [],
        organizations: [],
        roleKind: 'hub',
        breakpoint: Breakpoint.md
      },

      //  MONITOORA
      {
        key: 'dashboard',
        icon: <DashboardIcon data-testid="Drawer-DashboardIcon" />,
        label: i18n().common.components.drawer.options.dashboard,
        action: () => handleNavigate('/'),
        selected: location.pathname === '/' || location.pathname.startsWith('/devices-details'),
        roles: MONITOORA_ROLES,
        permissions: [],
        organizations: [],
        roleKind: 'monitoora',
        breakpoint: Breakpoint.md
      },

      // BILLING
      {
        key: 'contracts',
        icon: <DescriptionIcon data-testid="Drawer-DescriptionIcon" />,
        label: i18n().modules.billing.contracts.pages.list.pageTitle,
        action: () => handleNavigate('/contracts'),
        selected: location.pathname.startsWith('/contracts'),
        roles: ['billing'],
        permissions: [],
        organizations: [],
        roleKind: 'billing',
        breakpoint: Breakpoint.md
      },
      {
        key: 'salesOrders',
        icon: <SellIcon data-testid="Drawer-SellIcon" />,
        label: i18n().modules.billing.salesOrders.pages.list.pageTitle,
        action: () => handleNavigate('/sales-orders'),
        selected: location.pathname.startsWith('/sales-orders'),
        roles: ['billing'],
        permissions: [],
        organizations: [],
        roleKind: 'billing',
        breakpoint: Breakpoint.md
      },
      {
        key: 'serviceMapping',
        icon: <MapIcon data-testid="Drawer-MapIcon" />,
        label: i18n().modules.billing.serviceMapping.pages.list.pageTitle,
        action: () => handleNavigate('/service-mapping'),
        selected: location.pathname.startsWith('/service-mapping'),
        roles: ['billing'],
        permissions: [],
        organizations: [],
        roleKind: 'billing',
        breakpoint: Breakpoint.md
      }
    ]

    const defaultFooterOptions = [
      {
        key: 'settings',
        icon: <SettingsIcon data-testid="Drawer-SettingsIcon" />,
        label: i18n().common.components.drawer.options.settings,
        action: () => handleNavigate('/settings'),
        selected: location.pathname.startsWith('/settings')
      },
      {
        key: 'logout',
        icon: <LogoutIcon data-testid="Drawer-LogoutIcon" />,
        label: i18n().common.components.drawer.options.logout,
        action: () => setIsLogoutDialogOpen(true),
        selected: false
      }
    ]

    const filteredOptions = allOptions.filter((drawerOption) => {
      return (
        !!profile &&
        !!permissions &&
        breakpoint >= drawerOption.breakpoint &&
        roleKindsDict[drawerOption.roleKind].includes(profile.role.code) &&
        (drawerOption.roles.includes(profile.role.code) ||
          permissions.some((permission) => drawerOption.permissions.includes(permission.key))) &&
        (!drawerOption.organizations.length ||
          drawerOption.organizations.includes(profile.organization.code))
      )
    })

    return [...filteredOptions, ...defaultFooterOptions]
  }, [location.pathname, handleNavigate, breakpoint, profile, permissions])

  const profileData = profile && {
    picture: profile.picture,
    title: profile.name,
    subTitle1: profile.role.name,
    subTitle2: profile.organization.name,
    onClick: () => handleNavigate('/profiles'),
    selected: location.pathname.startsWith('/profiles')
  }

  return (
    <>
      <TextDialog
        data-testid="logout"
        css={{ maxWidth: 424 }}
        align="center"
        isOpen={isLogoutDialogOpen}
        onCancel={() => setIsLogoutDialogOpen(false)}
        title={{ label: i18n().common.components.drawer.signOutDialog.title }}
        contentTexts={[i18n().common.components.drawer.signOutDialog.content]}
        refuseAction={{
          handle: () => setIsLogoutDialogOpen(false),
          label: i18n().common.components.drawer.signOutDialog.refuse,
          icon: <CancelIcon size={18} />
        }}
        acceptAction={{
          handle: () => {
            logout()
            setIsLogoutDialogOpen(false)
          },
          label: i18n().common.components.drawer.signOutDialog.accept,
          icon: <CheckCircleIcon size={18} />
        }}
      />
      {breakpoint <= Breakpoint.xs ? (
        <BottomNavigation isLoading={isLoading} options={drawerOptions} />
      ) : isOpenDrawer ? (
        <DrawerNavigation
          logo={
            profile && MONITOORA_ROLES.includes(profile.role.code) ? (
              <MonitooraLogo data-testid="Drawer-MonitooraLogo" />
            ) : profile?.role.code === 'billing' ? (
              <BillingLogo data-testid="Drawer-BillingLogo" />
            ) : whiteLabel?.active && whiteLabel.urlLogo ? (
              whiteLabel.urlLogo
            ) : mode === 'light' ? (
              <HubLogo data-testid="Drawer-HubLogo" />
            ) : (
              <HubDarkLogo data-testid="Drawer-HubDarkLogo" />
            )
          }
          onClose={() => setIsOpenDrawer(false)}
          isLoading={isLoading}
          profile={profileData}
          options={drawerOptions}
        />
      ) : (
        <RailNavigation
          icon={
            profile && MONITOORA_ROLES.includes(profile.role.code) ? (
              <MonitooraIcon data-testid="Rail-MonitooraIcon" />
            ) : // DOCS: billing icon is same of hub, so do not need set billing icon
            profile?.role.code !== 'billing' && whiteLabel?.active && whiteLabel.urlIcon ? (
              whiteLabel.urlIcon
            ) : mode === 'light' ? (
              <HubIcon data-testid="Rail-HubIcon" />
            ) : (
              <HubDarkIcon data-testid="Rail-HubDarkIcon" />
            )
          }
          onOpen={() => setIsOpenDrawer(true)}
          isLoading={isLoading}
          profile={profileData}
          options={drawerOptions}
        />
      )}
    </>
  )
}
