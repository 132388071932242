import { Div } from '@positivote/design-system/components/Div'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'

import { i18n } from '@/common/i18n'

export function Footer(): JSX.Element {
  const { breakpoint } = useTheme()

  return (
    <Div
      data-testid="Footer-Container"
      css={{
        padding: '$4xs',
        display: 'flex',
        alignItems: 'center',
        justifyContent: breakpoint === Breakpoint.xs ? 'center' : 'flex-end',
        '@xs': {
          flexDirection: 'column'
        }
      }}
    >
      <Typography
        data-testid="Typography-hub"
        variant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
        css={{
          color: '$on-surface-variant',
          marginRight: breakpoint === Breakpoint.xs ? '$none' : '$2xs'
        }}
      >
        {i18n().common.layouts.baseBackground.footer.hub}
      </Typography>

      {breakpoint !== Breakpoint.xs && (
        <Typography
          data-testid="Typography-pipe-1"
          variant="bodySmall"
          css={{ color: '$on-surface-variant' }}
        >
          |
        </Typography>
      )}

      <LinkButton
        data-testid="systemRequirementsChecker"
        typographyVariant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
        onClick={() => window.open(import.meta.env.VITE_HUB_REQUIREMENTS_SYSTEM_CHECKER_URL)}
      >
        {i18n().common.layouts.baseBackground.footer.systemRequirementsChecker}
      </LinkButton>

      {breakpoint !== Breakpoint.xs && (
        <Typography
          data-testid="Typography-pipe-2"
          variant="bodySmall"
          css={{ color: '$on-surface-variant' }}
        >
          |
        </Typography>
      )}

      <LinkButton
        data-testid="terms"
        typographyVariant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
        onClick={() => window.open(import.meta.env.VITE_HUB_TERMS_URL, '_blank')}
      >
        {i18n().common.layouts.baseBackground.footer.terms}
      </LinkButton>
    </Div>
  )
}
