export const profilePt = {
  resources: {
    update: 'Atualizar perfil de usuário',
    list: 'Listar perfis de usuário',
    listRoles: 'Listar os tipos de perfis de usuário',
    updateConsent: 'Atualizar consentimento de imagem do usuário'
  },
  pages: {
    details: {
      myData: 'Meus dados',
      edit: 'Editar',
      pictureAlt: 'Foto do usuário logado',
      organization: 'Instituição',
      logedProfile: 'Perfil atual logado',
      phone: 'Telefone',
      birthday: 'Data de nascimento',
      cpf: 'CPF',
      gender: 'Gênero',
      infoAccess: 'Informações de Acesso',
      login: 'Login',
      email: 'E-mail'
    },
    form: {
      pageTitle: 'Editar Meus dados',
      appBar: {
        title: 'Configurações',
        breadcrumbs: {
          myData: 'Meus dados',
          editMyData: 'Editar Meus dados'
        }
      },
      editMyData: 'Editar Meus dados',
      pictureAlt: 'Foto do usuário logado',
      imageSupporting: 'Imagem: 160x160 até 1MB',
      name: 'Nome completo',
      organization: 'Instituição',
      phone: 'Telefone',
      cpf: (role: string): string => (role !== 'aluno' ? 'CPF *' : 'CPF'),
      birthday: 'Data de nascimento',
      gender: 'Gênero',
      DPO: 'Responsável pela segurança da informação (DPO).',
      buttons: {
        cancel: 'Cancelar',
        save: 'Salvar alterações'
      },
      alert: {
        updated: 'Dados alterados com sucesso!'
      }
    },
    list: {
      pageTitle: 'Perfis',
      appBar: {
        title: 'Perfis'
      },
      profileClick: 'Clique em um perfil abaixo para mudar seu acesso',
      searchInput: 'Pesquisar escola ou perfil',
      loggedProfile: 'Perfil atual logado',
      confirmModal: {
        title: 'Você está trocando de perfil',
        content:
          'Ao clicar em continuar você passará a utilizar um usuário diferente do qual você está no momento. Para continuar clique em "Entendido" ou "Cancelar" para não realizar a ação.',
        refuse: 'Cancelar',
        accept: 'Entendido'
      }
    }
  },
  components: {
    attentionDialog: {
      title: 'Pontos de atenção!',
      subtitle:
        'Seus dados foram alterados com sucesso, porém há campos aos quais você não possui permissão de edição.',
      subtitleBody: 'Entre em contato com o suporte da sua escola para corrigir os dados abaixo:',
      acceptAction: 'Fechar',
      fields: {
        name: 'Nome',
        birthday: 'Data nascimento',
        gender: 'Gênero',
        cpf: 'CPF',
        picture: 'Foto perfil',
        isDpoUser: 'Responsável pela segurança da informação (DPO).',
        phone: 'Telefone'
      }
    }
  }
}
