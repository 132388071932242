import { Navigate, Route, Routes } from 'react-router-dom'

import { DetailsAcademicSync } from './pages/Details'
import { ListAcademicSync } from './pages/List'
import { SelectInstitution } from './pages/SelectInstitution'

export function AcademicSyncRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ListAcademicSync />} />
      <Route path="/:id" element={<DetailsAcademicSync />} />
      <Route path="/selected-school" element={<SelectInstitution />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
