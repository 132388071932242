import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListContractHookParams,
  ListContractHookResult,
  ShowContractHookParams,
  ShowContractHookResult
} from './contracts/hooks'
import { listContractService, showContractService } from './services'

export const hookKey = 'contracts'

export function useListContract({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListContractHookParams): UseQueryResult<ListContractHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listContracts = await listContractService(model)
        onSuccess?.(listContracts)
        return listContracts
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useShowContract({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowContractHookParams): UseQueryResult<ShowContractHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showContracts = await showContractService(model)
        onSuccess?.(showContracts)
        return showContracts
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
