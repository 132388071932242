import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListAccountLoginSupportHookParams,
  ListAccountLoginSupportHookResult
} from './contracts/hooks'
import { listAccountLoginSupportService } from './services'

const hookKey = 'account'

export function useListAccountsLoginSupport({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListAccountLoginSupportHookParams): UseQueryResult<
  ListAccountLoginSupportHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listLoginSupport', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listAccountLoginSupport = await listAccountLoginSupportService(model)
        onSuccess?.(listAccountLoginSupport)
        return listAccountLoginSupport
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
