import { TextDialog } from '@/common/components/TextDialog'
import { i18n } from '@/common/i18n'
import { SalesOrderFormatted } from '@/modules/billing/sales-orders/contracts'
import { useUpdateSalesOrder } from '@/modules/billing/sales-orders/hooks'

type UpdateSalesOrderStatus = 'CREATED' | 'ERROR' | 'SENT'

const salesOrderStatusTransitions: Record<UpdateSalesOrderStatus, UpdateSalesOrderStatus> = {
  SENT: 'CREATED',
  ERROR: 'CREATED',
  CREATED: 'SENT'
}

interface UpdateSalesOrderFormatted extends Omit<SalesOrderFormatted, 'status'> {
  status: UpdateSalesOrderStatus
}

interface ChangeSalesOrderStatusDialogProps {
  salesOrderToChangeStatus: SalesOrderFormatted | null
  onClose: () => void
}

export function ChangeSalesOrderStatusDialog({
  salesOrderToChangeStatus,
  onClose
}: ChangeSalesOrderStatusDialogProps): JSX.Element {
  const updateSalesOrder = useUpdateSalesOrder()
  const parsedStatus = salesOrderToChangeStatus as UpdateSalesOrderFormatted

  function onAccept(): void {
    updateSalesOrder.mutate({
      model: {
        ...parsedStatus,
        status: salesOrderStatusTransitions[parsedStatus.status]
      },
      onSuccess: () => {
        onClose()
      }
    })
  }

  return (
    <>
      {salesOrderToChangeStatus && (
        <TextDialog
          xl={4}
          lg={4}
          md={6}
          data-testid="SalesOrder"
          align="center"
          isOpen={!!salesOrderToChangeStatus}
          isLoading={updateSalesOrder.isPending}
          onCancel={() => onClose()}
          title={{
            label:
              i18n().modules.billing.salesOrders.components.textDialog[parsedStatus.status].title
          }}
          contentTexts={
            i18n().modules.billing.salesOrders.components.textDialog[parsedStatus.status]
              .contentTexts
          }
          refuseAction={{
            handle: () => onClose(),
            label: i18n().modules.billing.salesOrders.components.textDialog.buttons.refuse
          }}
          acceptAction={{
            handle: onAccept,
            label: i18n().modules.billing.salesOrders.components.textDialog.buttons.accept
          }}
        />
      )}
    </>
  )
}
