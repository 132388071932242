import { i18n } from '@/common/i18n'

import { ContractOrgType } from './contracts'

export const CONTRACT_ORG_TYPE_OPTIONS: Array<{
  key: ContractOrgType
  value: string
}> = [
  {
    key: 'PRIVADO',
    value: i18n().modules.billing.contracts.typeSchool.PRIVADO
  },
  {
    key: 'PUBLICO',
    value: i18n().modules.billing.contracts.typeSchool.PUBLICO
  }
]
