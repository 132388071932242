import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { LI } from '@positivote/design-system/components/LI'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { EmailIcon } from '@positivote/design-system/icons/Email'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint, CSS } from '@positivote/design-system/theme'
import { useState } from 'react'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  ListOnboardingPreRegisterHookParams,
  OnboardingOrgType
} from '@/modules/hub/onboarding/contracts'
import {
  useListOnboardingPreRegister,
  useResendOnboardingCode
} from '@/modules/hub/onboarding/hooks'

const selectedButtonProps: CSS = {
  backgroundColor: '$primary',
  '& .Button-StateLayer': {
    '&:hover, &[data-hovered=true]': {
      backgroundColor: '$state-layer-on-primary-8'
    }
  },
  '&:focus, &:active': {
    '& .Button-StateLayer': {
      backgroundColor: '$state-layer-on-primary-12'
    }
  },
  '&[data-selected=true]': {
    '& .Button-StateLayer': {
      backgroundColor: '$state-layer-primary-12'
    }
  }
}

export function OnboardingPreRegisterList(): JSX.Element {
  const { breakpoint } = useTheme()
  const [listOnboardingPreRegisterParams, setListOnboardingPreRegisterParams] = useState<
    ListOnboardingPreRegisterHookParams['model']
  >({
    page: 1,
    type: OnboardingOrgType.SCHOOL,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })

  const listOnboardingPreRegister = useListOnboardingPreRegister({
    model: listOnboardingPreRegisterParams
  })
  const resendOnboardingCode = useResendOnboardingCode()

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListOnboardingPreRegisterParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '$lg',
        overflowY: 'auto',
        '@sm': { padding: '$md' }
      }}
    >
      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
        <FilterListIcon size={18} />
        <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
          {i18n().modules.hub.onboarding.pages.list.filter.title}
        </Typography>
      </Div>
      <Grid xl={12} css={{ marginBottom: '$lg', display: 'flex', alignItems: 'flex-start' }}>
        <TextField
          variant="outlined"
          label={i18n().modules.hub.onboarding.pages.list.filter.input.label}
          inputProps={{
            onChange: handleChangeSearchText
          }}
          supportingText={i18n().modules.hub.onboarding.pages.list.filter.input.supportingText(
            listOnboardingPreRegisterParams.type
          )}
          leadingIcon={{ icon: SearchIcon }}
          css={{ flex: 1 }}
        />
        <Button
          variant="outlined"
          color={
            listOnboardingPreRegisterParams.type === OnboardingOrgType.SCHOOL
              ? '$on-primary'
              : '$primary'
          }
          onClick={() =>
            setListOnboardingPreRegisterParams((oldState) => ({
              ...oldState,
              type: OnboardingOrgType.SCHOOL,
              page: 1
            }))
          }
          css={{
            marginTop: '$2xs',
            marginLeft: '$md',
            borderTopRightRadius: '$none',
            borderBottomRightRadius: '$none',
            ...(listOnboardingPreRegisterParams.type === OnboardingOrgType.SCHOOL &&
              selectedButtonProps)
          }}
        >
          {i18n().modules.hub.onboarding.pages.list.filter.schools}
        </Button>
        <Button
          variant="outlined"
          color={
            listOnboardingPreRegisterParams.type === OnboardingOrgType.TEACHING_NETWORK
              ? '$on-primary'
              : '$primary'
          }
          onClick={() =>
            setListOnboardingPreRegisterParams((oldState) => ({
              ...oldState,
              type: OnboardingOrgType.TEACHING_NETWORK,
              page: 1
            }))
          }
          css={{
            marginTop: '$2xs',
            borderTopLeftRadius: '$none',
            borderBottomLeftRadius: '$none',
            ...(listOnboardingPreRegisterParams.type === OnboardingOrgType.TEACHING_NETWORK &&
              selectedButtonProps)
          }}
        >
          {i18n().modules.hub.onboarding.pages.list.filter.networks}
        </Button>
      </Grid>
      {listOnboardingPreRegister.isFetching && listOnboardingPreRegisterParams.search && (
        <Typography variant="titleLarge" css={{ color: '$on-surface', marginBottom: '$lg' }}>
          {i18n().modules.hub.onboarding.pages.list.tabs.common.searching}
        </Typography>
      )}
      {!listOnboardingPreRegister.isFetching &&
        !!listOnboardingPreRegister.data?.registers.length &&
        listOnboardingPreRegisterParams.search && (
          <Typography variant="titleLarge" css={{ color: '$on-surface', marginBottom: '$lg' }}>
            {i18n().modules.hub.onboarding.pages.list.tabs.common.searchingResult}
          </Typography>
        )}

      {!listOnboardingPreRegister.data?.registers.length ? (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          {listOnboardingPreRegister.isFetching && <Loader size={80} />}
          {!listOnboardingPreRegister.isFetching && !listOnboardingPreRegisterParams.search && (
            <EmptyList
              title={i18n().modules.hub.onboarding.pages.list.tabs.preRegister.emptyList(
                listOnboardingPreRegisterParams.type
              )}
            />
          )}
          {!listOnboardingPreRegister.isFetching && listOnboardingPreRegisterParams.search && (
            <EmptySearch />
          )}
        </Div>
      ) : (
        <>
          <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
            <Grid xl={5}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.preRegister.header.name}
              </Typography>
            </Grid>
            <Grid xl={3}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.preRegister.header.address}
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.preRegister.header.type}
              </Typography>
            </Grid>
          </Grid>
          <UL
            css={{
              ...(listOnboardingPreRegister.isFetching && {
                ...(listOnboardingPreRegister.data.lastPage <= 1 && { flex: 1 }),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              })
            }}
          >
            {listOnboardingPreRegister.isFetching && <Loader size={80} />}
            {listOnboardingPreRegister.data.registers.map((register, index) => (
              <BaseCard
                key={`${register.org.cinNumber}-${index}`}
                component={LI}
                css={{
                  opacity: listOnboardingPreRegister.isFetching ? '$transparent' : '$default',
                  marginBottom: '$2xs',
                  '&:last-child': { marginBottom: '$none' },
                  '& .BaseCard-StateLayer': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '$md',
                    height: 'min-content',
                    padding: '$md $lg'
                  }
                }}
              >
                <Grid spacing="$md">
                  <Grid xl={5} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.org.name}
                    </Typography>
                  </Grid>
                  <Grid xl={3} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.org.addressFormatted}
                    </Typography>
                  </Grid>
                  <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.org.typeFormatted}
                    </Typography>
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <LinkButton
                      LeadingIcon={<EmailIcon size={18} />}
                      typographyVariant="labelMedium"
                      isLoading={
                        resendOnboardingCode.isPending &&
                        resendOnboardingCode.variables.model.code === register.control.code
                      }
                      onClick={() =>
                        resendOnboardingCode.mutate({ model: { code: register.control.code } })
                      }
                    >
                      {i18n().modules.hub.onboarding.pages.list.tabs.preRegister.body.resendEmail}
                    </LinkButton>
                  </Grid>
                </Grid>
              </BaseCard>
            ))}
          </UL>
          {listOnboardingPreRegister.data.lastPage > 1 && (
            <Pagination
              lastPage={listOnboardingPreRegister.data.lastPage}
              page={listOnboardingPreRegisterParams.page ?? 1}
              setPage={(page) =>
                setListOnboardingPreRegisterParams((oldState) => ({ ...oldState, page }))
              }
              css={{ marginTop: '$lg' }}
            />
          )}
        </>
      )}
    </Div>
  )
}
