import { Accordion } from '@positivote/design-system/components/Accordion'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { LI } from '@positivote/design-system/components/LI'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useAlert } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ClearAllIcon } from '@positivote/design-system/icons/ClearAll'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { ReceiptIcon } from '@positivote/design-system/icons/Receipt'
import { RemoveCircleIcon } from '@positivote/design-system/icons/RemoveCircle'
import { CSS } from '@positivote/design-system/theme'
import { forwardRef, useImperativeHandle, useState } from 'react'

import { base64PNGToPDF, downloadLink } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  SalesOrderFormatted,
  SalesOrderOperationType,
  SalesOrderServiceFormatted
} from '@/modules/billing/sales-orders/contracts/models'
import { useGenerateInvoice } from '@/modules/billing/sales-orders/hooks'

import { ChangeSalesOrderStatusDialog } from './ChangeSalesOrderStatusDialog'
import { GenerateInvoiceDialog } from './GenerateInvoiceDialog'
import { ServiceOperationDialog } from './ServiceOperationDialog'

interface SalesOrderAccordionListProps {
  salesOrders: SalesOrderFormatted[]
  isLoading: boolean
  isOpen?: boolean
  accordionCss?: CSS
  showContractId?: boolean
}

export interface SalesOrderAccordionListRef {
  setOpenSalesOrders: (params: Record<string, SalesOrderFormatted | null>) => void
}

export const SalesOrderAccordionList = forwardRef(function SalesOrderAccordionList(
  { salesOrders, isLoading, accordionCss, showContractId = true }: SalesOrderAccordionListProps,
  ref
): JSX.Element {
  const [openSalesOrders, setOpenSalesOrders] = useState<
    Record<string, SalesOrderFormatted | null>
  >({})
  const [salesOrderToChangeStatus, setSalesOrderToChangeStatus] =
    useState<SalesOrderFormatted | null>(null)
  const [rpsDownloadLoading, setRpsDownloadLoading] = useState(false)

  const [salesOrderToGenerateInvoice, setSalesOrderToGenerateInvoice] =
    useState<SalesOrderFormatted | null>(null)
  const [serviceOperationDialogData, setServiceOperationDialogData] = useState<{
    operationType: SalesOrderOperationType
    salesOrder: SalesOrderFormatted
    service: SalesOrderServiceFormatted
  } | null>(null)

  const { addAlertMessage } = useAlert()
  const generateInvoice = useGenerateInvoice()

  function handleDownloadNf(orderId: string): void {
    generateInvoice.mutate({
      model: { salesOrderId: orderId },
      onSuccess: (base64PDF) => {
        base64PNGToPDF(base64PDF, orderId)
      }
    })
  }

  function handleDownloadRps(salesOrder: SalesOrderFormatted): void {
    if (!salesOrder.invoice?.rps) {
      return addAlertMessage({
        severity: 'warning',
        subTitle: i18n().modules.billing.salesOrders.components.accordionList.alert.withoutRpsError
      })
    }
    setRpsDownloadLoading(true)
    downloadLink(
      [
        {
          name: i18n().modules.billing.salesOrders.components.accordionList.rpsFileName,
          link: salesOrder.invoice.rps
        }
      ],
      i18n().modules.billing.salesOrders.components.accordionList.rpsFileName
    )
      .then(() => {
        setRpsDownloadLoading(false)
      })
      .catch(() => {
        addAlertMessage({
          severity: 'warning',
          subTitle:
            i18n().modules.billing.salesOrders.components.accordionList.alert.downloadRpsError
        })
        setRpsDownloadLoading(false)
      })
  }

  useImperativeHandle(ref, () => ({ setOpenSalesOrders }), [])

  return (
    <UL
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: '$2xs',
        ...(isLoading && { alignItems: 'center', justifyContent: 'center' })
      }}
    >
      <GenerateInvoiceDialog
        salesOrder={salesOrderToGenerateInvoice}
        onClose={() => setSalesOrderToGenerateInvoice(null)}
      />
      <ChangeSalesOrderStatusDialog
        onClose={() => setSalesOrderToChangeStatus(null)}
        salesOrderToChangeStatus={salesOrderToChangeStatus}
      />

      <ServiceOperationDialog
        salesOrder={serviceOperationDialogData?.salesOrder ?? null}
        service={serviceOperationDialogData?.service ?? null}
        operationType={serviceOperationDialogData?.operationType ?? 'addition'}
        isUpdating={!!serviceOperationDialogData?.service.operation}
        onClose={() => setServiceOperationDialogData(null)}
      />
      {isLoading && <Loader data-testid="Loader-Container-SalesOrderAccordionList" size={80} />}
      {salesOrders.map((salesOrder, index) => (
        <Accordion
          key={salesOrder.id}
          isOpen={!!openSalesOrders[salesOrder.id]}
          onOpen={() =>
            setOpenSalesOrders((oldState) => ({ ...oldState, [salesOrder.id]: salesOrder }))
          }
          onClose={() => setOpenSalesOrders((oldState) => ({ ...oldState, [salesOrder.id]: null }))}
          data-testid={`${index}`}
          component={LI}
          css={{
            border: 'none',
            opacity: isLoading ? '$transparent' : '$default',
            '& .Accordion-Details': {
              padding: '$none $md'
            },
            ...accordionCss
          }}
          Summary={
            <Div
              data-testid={`Accordion-Summary-salesOrder-${index}`}
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
                alignItems: 'center',
                minHeight: '26px'
              }}
            >
              <Div css={{ display: 'flex', gap: '$md', alignItems: 'center' }}>
                <Div
                  data-testid={`Typography-salesOrderListItemPeriod-${index}`}
                  css={{
                    display: 'flex',
                    gap: '$2xs',
                    alignItems: 'center',
                    minWidth: '120px'
                  }}
                >
                  <Typography
                    data-testid={`Typography-salesOrderListItemPeriod-Label-${index}`}
                    variant="labelMedium"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.billing.salesOrders.components.accordionList.period}
                  </Typography>
                  <Typography
                    data-testid={`Typography-salesOrderListItemPeriod-Value-${index}`}
                    variant="titleSmall"
                    css={{ color: '$on-surface' }}
                  >
                    {salesOrder.orderDateFormatted}
                  </Typography>
                </Div>

                <Div
                  css={{ display: 'flex', gap: '$2xs', alignItems: 'center', maxWidth: '152px' }}
                >
                  <Typography
                    data-testid={`Typography-salesOrderListItemCreated-Label-${index}`}
                    variant="labelMedium"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.billing.salesOrders.components.accordionList.created}
                  </Typography>
                  <Typography
                    data-testid={`Typography-salesOrderListItemCreated-Value-${index}`}
                    variant="titleSmall"
                    css={{ color: '$on-surface' }}
                  >
                    {salesOrder.createdAtFormatted}
                  </Typography>
                </Div>
                {showContractId && (
                  <Div
                    css={{ display: 'flex', gap: '$2xs', alignItems: 'center', minWidth: '176px' }}
                  >
                    <Typography
                      data-testid={`Typography-salesOrderListItemContract-Label-${index}`}
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.salesOrders.components.accordionList.contract}
                    </Typography>

                    <Typography
                      data-testid={`Typography-salesOrderListItemContract-Value-${index}`}
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {salesOrder.contractId}
                    </Typography>
                  </Div>
                )}
              </Div>
              <Div css={{ display: 'flex', justifyContent: 'end', gap: '$2xs' }}>
                <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                  <Typography
                    data-testid={`Typography-salesOrderListItemStatus-Label-${index}`}
                    variant="labelMedium"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.billing.salesOrders.components.accordionList.status}
                  </Typography>
                  <Typography
                    data-testid={`Typography-salesOrderListItemStatus-Value-${index}`}
                    variant="titleSmall"
                    css={{ color: salesOrder.statusColor }}
                  >
                    {salesOrder.statusFormatted}
                  </Typography>
                  {salesOrder.status === 'ERROR' && openSalesOrders[salesOrder.id] && (
                    <Tooltip
                      placement="top-end"
                      arrow
                      label={salesOrder.errorsLog?.map((error) => (
                        <Typography
                          key={error.message}
                          className="Tooltip-Typography"
                          data-testid="Tooltip-Typography"
                          variant="labelMedium"
                          css={{ color: '$inverse-on-surface' }}
                        >
                          • {error.message}
                        </Typography>
                      ))}
                    >
                      <Div css={{ display: 'flex' }}>
                        <InfoIcon size={18} fill="$critical" data-testid="InfoIcon" />
                      </Div>
                    </Tooltip>
                  )}
                </Div>
                {['ERROR', 'SENT'].includes(salesOrder.status) &&
                  openSalesOrders[salesOrder.id] && (
                    <LinkButton
                      data-testid={`clearStatus-${index}`}
                      stopPropagation
                      LeadingIcon={<ClearAllIcon size={18} />}
                      onClick={() => setSalesOrderToChangeStatus(salesOrder)}
                    >
                      {
                        i18n().modules.billing.salesOrders.components.accordionList.buttons
                          .clearStatus
                      }
                    </LinkButton>
                  )}
                {salesOrder.status === 'CREATED' && openSalesOrders[salesOrder.id] && (
                  <LinkButton
                    data-testid={`send-${index}`}
                    stopPropagation
                    LeadingIcon={<CheckCircleIcon size={18} />}
                    onClick={() => setSalesOrderToChangeStatus(salesOrder)}
                  >
                    {i18n().modules.billing.salesOrders.components.accordionList.buttons.send}
                  </LinkButton>
                )}
              </Div>
            </Div>
          }
        >
          <Divider css={{ marginBottom: '$md' }} />
          <Typography
            data-testid={`Typography-Services-${index}`}
            variant="labelSmall"
            css={{ color: '$on-surface-variant' }}
          >
            {i18n().modules.billing.salesOrders.components.accordionList.services}
          </Typography>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$2xs'
            }}
          >
            {salesOrder.servicesFormatted.map((service, serviceIndex) => (
              <Div
                className="SalesOrderAccordionList-Container-Service"
                key={service.appSisId}
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '$sm',
                  padding: '$md',
                  gap: '$md'
                }}
              >
                <Typography
                  variant="titleMedium"
                  data-testid={`Typography-AppSisName-Label-${index}-${serviceIndex}`}
                  css={{ color: '$on-surface' }}
                >
                  {service.appSisName}
                </Typography>
                <Divider />
                <Grid xl={12} spacing="$md">
                  <Grid xl={6} css={{ display: 'flex', flexDirection: 'column', gap: '$4xs' }}>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-idSap-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.idSap}
                      </Typography>
                      <Typography
                        data-testid={`Typography-idSap-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.appSisId}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-numberLicense-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.numberLicense}
                      </Typography>
                      <Typography
                        data-testid={`Typography-numberLicense-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.licenses}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-numberLicenseMonth-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {
                          i18n().modules.billing.salesOrders.components.accordionList
                            .numberLicenseMonth
                        }
                      </Typography>
                      <Typography
                        data-testid={`Typography-numberLicenseMonth-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.licensesMonth}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-costLicenseStudent-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {
                          i18n().modules.billing.salesOrders.components.accordionList
                            .costLicenseStudent
                        }
                      </Typography>
                      <Typography
                        data-testid={`Typography-costLicenseStudent-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.studentValueFormatted}
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid xl={6} css={{ display: 'flex', flexDirection: 'column', gap: '$4xs' }}>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-discount-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.discount}
                      </Typography>
                      <Typography
                        data-testid={`Typography-discount-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.discountFormatted}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-addition-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.addition}
                      </Typography>
                      <Typography
                        data-testid={`Typography-addition-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.additionFormatted}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-subTotal-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.subTotal}
                      </Typography>
                      <Typography
                        data-testid={`Typography-subTotal-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.subtotalFormatted}
                      </Typography>
                    </Div>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Typography
                        data-testid={`Typography-total-Label-${index}-${serviceIndex}`}
                        variant="labelMedium"
                        css={{ color: '$on-surface-variant' }}
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.total}
                      </Typography>
                      <Typography
                        data-testid={`Typography-total-Value-${index}-${serviceIndex}`}
                        variant="titleSmall"
                        css={{ color: '$on-surface' }}
                      >
                        {service.totalFormatted}
                      </Typography>
                    </Div>
                  </Grid>
                </Grid>
                {salesOrder.status === 'CREATED' && (
                  <>
                    <Divider />
                    <Div css={{ display: 'flex', justifyContent: 'end', gap: '$2xs' }}>
                      <LinkButton
                        data-testid={`addition-${index}-${serviceIndex}`}
                        LeadingIcon={<AddCircleIcon size={18} />}
                        disabled={service.operation?.type === 'discount'}
                        onClick={() =>
                          setServiceOperationDialogData({
                            operationType: 'addition',
                            salesOrder,
                            service
                          })
                        }
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.buttons.addition(
                          service.operation?.type === 'addition'
                        )}
                      </LinkButton>
                      <LinkButton
                        data-testid={`discount-${index}-${serviceIndex}`}
                        disabled={service.operation?.type === 'addition'}
                        LeadingIcon={<RemoveCircleIcon size={18} />}
                        onClick={() =>
                          setServiceOperationDialogData({
                            operationType: 'discount',
                            salesOrder,
                            service
                          })
                        }
                      >
                        {i18n().modules.billing.salesOrders.components.accordionList.buttons.discount(
                          service.operation?.type === 'discount'
                        )}
                      </LinkButton>
                    </Div>
                  </>
                )}
              </Div>
            ))}
          </Div>
          <Divider css={{ margin: '$md $none' }} />
          <Div css={{ display: 'flex', margin: '$md $none', gap: '$md' }}>
            <Div css={{ display: 'flex', gap: '$2xs', flex: 1, alignItems: 'center' }}>
              <Div
                css={{
                  display: 'flex',
                  gap: '$2xs',
                  padding: '$4xs $2xs',
                  alignItems: 'center'
                }}
              >
                <Typography
                  data-testid={`Typography-subTotalContract-Label-${index}`}
                  variant="labelMedium"
                  css={{ color: '$on-surface-variant' }}
                >
                  {i18n().modules.billing.salesOrders.components.accordionList.subTotal}
                </Typography>
                <Typography
                  data-testid={`Typography-subTotalContract-Value-${index}`}
                  variant="titleSmall"
                  css={{ color: '$on-surface' }}
                >
                  {salesOrder.subtotalFormatted}
                </Typography>
              </Div>
              <Div
                css={{
                  display: 'flex',
                  gap: '$2xs',
                  padding: '$4xs $2xs',
                  alignItems: 'center'
                }}
              >
                <Typography
                  data-testid={`Typography-discountContract-Label-${index}`}
                  variant="labelMedium"
                  css={{ color: '$on-surface-variant' }}
                >
                  {i18n().modules.billing.salesOrders.components.accordionList.discount}
                </Typography>
                <Typography
                  data-testid={`Typography-discountContract-Value-${index}`}
                  variant="titleSmall"
                  css={{ color: '$on-surface' }}
                >
                  {salesOrder.discountFormatted}
                </Typography>
              </Div>
              <Div
                css={{
                  display: 'flex',
                  gap: '$2xs',
                  padding: '$4xs $2xs',
                  alignItems: 'center'
                }}
              >
                <Typography
                  data-testid={`Typography-additionContract-Label-${index}`}
                  variant="labelMedium"
                  css={{ color: '$on-surface-variant' }}
                >
                  {i18n().modules.billing.salesOrders.components.accordionList.addition}
                </Typography>
                <Typography
                  data-testid={`Typography-additionContract-Value-${index}`}
                  variant="titleSmall"
                  css={{ color: '$on-surface' }}
                >
                  {salesOrder.additionFormatted}
                </Typography>
              </Div>
              <Div
                css={{
                  display: 'flex',
                  gap: '$2xs',
                  backgroundColor: '$surface-variant',
                  padding: '$2xs $md',
                  borderRadius: '$sm',
                  marginLeft: '$2xs',
                  alignItems: 'center'
                }}
              >
                <Typography
                  data-testid={`Typography-totalContract-Label-${index}`}
                  variant="labelMedium"
                  css={{ color: '$on-surface-variant' }}
                >
                  {i18n().modules.billing.salesOrders.components.accordionList.total}
                </Typography>
                <Typography
                  data-testid={`Typography-totalContract-Value-${index}`}
                  variant="titleLarge"
                  css={{ color: '$on-surface' }}
                >
                  {salesOrder.totalFormatted}
                </Typography>
              </Div>
            </Div>

            <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
              <LinkButton
                data-testid={`rps-${index}`}
                disabled={salesOrder.status !== 'PROCESSED'}
                LeadingIcon={<ReceiptIcon size={18} />}
                onClick={() => handleDownloadRps(salesOrder)}
                isLoading={rpsDownloadLoading}
              >
                {i18n().modules.billing.salesOrders.components.accordionList.buttons.rps}
              </LinkButton>
              <LinkButton
                data-testid={`nf-${index}`}
                disabled={salesOrder.status !== 'PROCESSED'}
                LeadingIcon={<ReceiptIcon size={18} />}
                onClick={() => salesOrder.invoice && handleDownloadNf(salesOrder.invoice.id)}
                isLoading={
                  generateInvoice.isPending &&
                  generateInvoice.variables.model.salesOrderId === salesOrder.invoice?.id
                }
              >
                {i18n().modules.billing.salesOrders.components.accordionList.buttons.nf}
              </LinkButton>
              <LinkButton
                data-testid={`generateInvoice-${index}`}
                disabled={salesOrder.status !== 'PROCESSED'}
                LeadingIcon={<ReceiptIcon size={18} />}
                onClick={() => setSalesOrderToGenerateInvoice(salesOrder)}
              >
                {
                  i18n().modules.billing.salesOrders.components.accordionList.buttons
                    .generateInvoice
                }
              </LinkButton>
            </Div>
          </Div>
        </Accordion>
      ))}
    </UL>
  )
})
