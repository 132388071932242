import { LoginProps, Token } from '@/modules/hub/auth/contracts/models'

const storageTokenKey = '@hub/auth:token'
const storageLoginPropsKey = '@hub/auth:loginProps'

export function getTokenSessionRepository(): Token | null {
  const token = sessionStorage.getItem(storageTokenKey)
  if (token !== null) {
    return JSON.parse(token) as Token
  }
  return token
}

export function saveTokenSessionRepository(token: Token): void {
  sessionStorage.setItem(storageTokenKey, JSON.stringify(token))
}

export function removeTokenSessionRepository(): void {
  sessionStorage.removeItem(storageTokenKey)
}

export function getLoginPropsSessionRepository(): LoginProps | null {
  const token = sessionStorage.getItem(storageLoginPropsKey)
  if (token !== null) {
    return JSON.parse(token) as LoginProps
  }
  return token
}

export function saveLoginPropsSessionRepository(loginProps: LoginProps): void {
  sessionStorage.setItem(storageLoginPropsKey, JSON.stringify(loginProps))
}

export function removeLoginPropsSessionRepository(): void {
  sessionStorage.removeItem(storageLoginPropsKey)
}
