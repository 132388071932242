import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Switch } from '@positivote/design-system/components/Switch'
import { Typography } from '@positivote/design-system/components/Typography'
import { CalendarTodayIcon } from '@positivote/design-system/icons/CalendarToday'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { convertDateToISO } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { SchoolYearStepperState } from '@/modules/hub/school-year/contracts/form'
import { useCreateSchoolYear, useUpdateSchoolYear } from '@/modules/hub/school-year/hooks'

interface SchoolYearPreviewStepProps {
  stepperState: SchoolYearStepperState
  setStepState: (stepperState: Partial<SchoolYearStepperState['preview']>) => void
}
export const SchoolYearPreviewStep = forwardRef(function SchoolYearPreviewStep(
  { setStepState, stepperState }: SchoolYearPreviewStepProps,
  ref
) {
  const params = useParams()
  const createSchoolYear = useCreateSchoolYear()
  const updateSchoolYear = useUpdateSchoolYear()
  const location = useLocation()
  const locationState = location.state as { page: number } | null
  const { profile } = useAuth()
  const navigate = useNavigate()

  function handleSelect(): void {
    const active = stepperState.preview.form?.status === 'active' ? 'inactive' : 'active'
    setStepState({
      form: {
        status: active
      }
    })
  }

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  const onSubmit = useCallback(
    (setIsLoading: (isLoading: boolean) => void): void => {
      if (stepperState.schoolYearData.form) {
        setIsLoading(true)
        const formData = {
          status: stepperState.preview.form?.status ? stepperState.preview.form.status : undefined,
          code: stepperState.schoolYearData.form.name,
          startDate: convertDateToISO(stepperState.schoolYearData.form.initialDate),
          endDate: convertDateToISO(stepperState.schoolYearData.form.finalDate),
          title: stepperState.schoolYearData.form.name,
          institutionId:
            stepperState.selectedSchool.form?.idSchool ??
            (profile?.organizationId as unknown as string),
          copy: stepperState.schoolYearData.form.isCopy
            ? {
                teachers: stepperState.schoolYearData.form.teacher!,
                termId: String(stepperState.schoolYearData.form.schoolYear)
              }
            : undefined
        }
        if (params.id) {
          updateSchoolYear.mutate({
            model: {
              idSchoolYear: params.id,
              ...formData,
              status: stepperState.preview.form!.status!
            },
            page: locationState?.page ?? 1,
            onSuccess: () => {
              navigate(-1)
            }
          })
        } else {
          createSchoolYear.mutate({
            model: formData,
            onSuccess: () => {
              navigate(-1)
            }
          })
        }
      }
    },
    [
      createSchoolYear,
      navigate,
      params.id,
      profile?.organizationId,
      stepperState.preview.form,
      stepperState.schoolYearData.form,
      stepperState.selectedSchool.form?.idSchool,
      updateSchoolYear
    ]
  )

  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$lg'
      }}
    >
      <Div
        css={{
          display: 'flex',
          borderWidth: '$thin',
          borderStyle: 'solid',
          borderRadius: '$xl',
          borderColor: '$outline-variant',
          padding: '$lg',
          gap: '$lg'
        }}
      >
        <IconWrapper size={72} css={{ backgroundColor: '$primary-container' }}>
          <CalendarTodayIcon size={40} fill="$primary" />
        </IconWrapper>
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md', flex: 1 }}>
          <Div css={{ display: 'flex', alignItems: 'center', gap: '$lg' }}>
            <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
              {stepperState.schoolYearData.form?.name}
            </Typography>
            {params.id && (
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$sm' }}>
                <Switch
                  data-testid={`Status`}
                  inputProps={{
                    checked: stepperState.preview.form?.status === 'active',
                    onChange: () => handleSelect()
                  }}
                />
                <Typography
                  variant="bodyMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface', marginTop: '5px' }}
                >
                  {stepperState.preview.form?.status === 'active'
                    ? i18n().modules.hub.schoolYear.pages.form.stepper.preview.active
                    : i18n().modules.hub.schoolYear.pages.form.stepper.preview.inactive}
                </Typography>
              </Div>
            )}
          </Div>

          <Divider />
          <Div
            css={{
              display: 'flex',
              gap: '$lg',
              alignItems: 'center',
              justifyContent: !isSchool ? 'none' : 'space-between'
            }}
          >
            {!isSchool && (
              <Div css={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Chip color="neutral" label={stepperState.selectedSchool.form?.schoolName ?? ''} />
              </Div>
            )}

            <Div css={{ display: 'flex', alignItems: 'center', flex: 1, gap: '$4xs' }}>
              <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.schoolYear.pages.form.stepper.preview.startDate}
              </Typography>
              <Typography variant="bodyLarge">
                {stepperState.schoolYearData.form?.initialDate}
              </Typography>
            </Div>
            <Div css={{ display: 'flex', alignItems: 'center', flex: 1, gap: '$4xs' }}>
              <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.schoolYear.pages.form.stepper.preview.endDate}
              </Typography>
              <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                {stepperState.schoolYearData.form?.finalDate}
              </Typography>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
})
