import { useAlert } from '@positivote/design-system/hooks'

import {
  ApplicationException,
  ForbiddenException,
  NotFoundException,
  UnauthorizedException,
  UnprocessableEntityException,
  ValidationException
} from '@/common/exceptions'
import { useAuth } from '@/modules/hub/auth/contexts'

interface HandleErrorProps {
  error: ApplicationException
  setValidations?: (validations: Record<string, string>) => void
}
interface ErrorHandlerHookProps {
  ignoreLogout?: boolean
}
interface ErrorHandlerHook {
  handleError: (data: HandleErrorProps) => void
}

export function useErrorHandler(props?: ErrorHandlerHookProps): ErrorHandlerHook {
  const { addAlertMessage } = useAlert()
  const { logout } = useAuth()

  function handleError({ error, setValidations }: HandleErrorProps): void {
    switch (error.constructor) {
      case ValidationException:
        setValidations?.(
          (error as ValidationException).validations.reduce(
            (accumulatedValidations, currentValidation) => ({
              ...accumulatedValidations,
              [currentValidation.field]: currentValidation.message
            }),
            {}
          ) as Record<string, string>
        )
        break
      case UnauthorizedException:
        addAlertMessage({
          alertKey: error.code ?? error.name,
          subTitle: error.message,
          severity: 'warning'
        })
        if (props?.ignoreLogout !== true) {
          logout()
        }
        break
      case NotFoundException:
        addAlertMessage({
          alertKey: error.code ?? error.name,
          subTitle: error.message,
          severity: 'warning'
        })
        break
      case UnprocessableEntityException:
        addAlertMessage({
          alertKey: error.code ?? error.name,
          subTitle: error.message,
          severity: 'warning'
        })
        break
      case ForbiddenException:
        addAlertMessage({
          alertKey: error.code ?? error.name,
          subTitle: error.message,
          severity: 'warning'
        })
        break
      default: {
        const parsedError = new ApplicationException({ ...error, originalError: error })
        addAlertMessage({
          alertKey: parsedError.code ?? parsedError.name,
          subTitle: parsedError.message,
          severity: 'warning'
        })
        break
      }
    }
  }

  return { handleError }
}
