import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'

import { AppBaseCard, AppBaseCardProps } from '@/modules/hub/applications/components/AppBaseCard'

export function DesktopAppCard({
  application,
  applicationIdLoading,
  css,
  ...restProps
}: AppBaseCardProps): JSX.Element {
  const isLoading = application.id === applicationIdLoading
  return (
    <AppBaseCard
      application={application}
      css={{ width: '236px', height: '236px', ...css }}
      {...restProps}
    >
      <IconWrapper size={80}>
        {isLoading && <Loader size={20} strokeWidth={4} />}
        <Image
          alt="Application Icon"
          src={application.iconUrl}
          css={{
            width: '100%',
            height: '100%',
            opacity: isLoading ? '25%' : 'unset',
            filter: isLoading ? 'grayscale(100%)' : 'none',
            objectFit: 'contain'
          }}
        />
      </IconWrapper>

      <Div
        className="DesktopAppCard-Content"
        css={{
          display: 'flex',
          gap: '$4xs',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          className="DesktopAppCard-Application-Name"
          variant="titleMedium"
          lineClamp={1}
          css={{ textAlign: 'center', color: '$on-surface' }}
        >
          {application.name}
        </Typography>
        <Typography
          className="DesktopAppCard-Application-Description"
          variant="bodySmall"
          lineClamp={3}
          css={{ textAlign: 'center', color: '$on-surface-variant' }}
        >
          {application.shortDescription}
        </Typography>
      </Div>
    </AppBaseCard>
  )
}
