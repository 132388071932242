import { yupResolver } from '@hookform/resolvers/yup'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { LoginIcon } from '@positivote/design-system/icons/Login'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { MAX_PER_PAGE } from '@/common/constants/react-query'
import { UnprocessableEntityException } from '@/common/exceptions'
import { debounceEvent } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { AccountLoginSupport } from '@/modules/hub/accounts/contracts/models'
import { useListAccountsLoginSupport } from '@/modules/hub/accounts/hooks'
import { useAuth } from '@/modules/hub/auth/contexts'
import { LoginSupportForm } from '@/modules/hub/auth/contracts/forms'
import { loginSupportValidationSchema } from '@/modules/hub/auth/validations'
import { useListShortOrganization } from '@/modules/hub/organizations/hooks'

interface LoginSupportDialogProps {
  isOpen: boolean
  onClose: () => void
}

export function LoginSupportDialog({ isOpen, onClose }: LoginSupportDialogProps): JSX.Element {
  const { createSession, account } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })

  const [searchTextOrganization, setSearchTextOrganization] = useState('')
  const [searchTextAccount, setSearchTextAccount] = useState('')
  const [selectedAccount, setSelectedAccount] = useState<AccountLoginSupport | null>(null)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<LoginSupportForm>({
    mode: 'onSubmit',
    resolver: async (value, ...args) => yupResolver(loginSupportValidationSchema)(value, ...args)
  })
  const organizationId = watch('organizationId')

  const { data, isFetching: organizationIsFetching } = useListShortOrganization({
    model: {
      perPage: MAX_PER_PAGE,
      callPublic: false,
      search: searchTextOrganization || undefined
    },
    queryOptions: {
      enabled: isOpen
    }
  })
  const { data: dataAccount, isFetching: isLoadingAccount } = useListAccountsLoginSupport({
    model: {
      perPage: MAX_PER_PAGE,
      search: searchTextAccount || undefined,
      organizationId
    },
    queryOptions: {
      enabled: !!organizationId
    }
  })
  const parsedAccounts =
    dataAccount?.registers.map((register) => ({
      ...register,
      leadingElement: (
        <Image
          alt={register.profileNameShort}
          src={register.profilePicture}
          FallbackImage={() => (
            <IconWrapper
              data-testid="Drawer-FallbackImage"
              size="$2xl"
              css={{ backgroundColor: '$primary-container' }}
            >
              <PersonIcon fill="$on-primary-container" />
            </IconWrapper>
          )}
          css={{ height: '$2xl', width: '$2xl', borderRadius: '$full', objectFit: 'cover' }}
        />
      ),
      trailingElement: (
        <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
          {register.profileRole}
        </Typography>
      ),
      subtitle: i18n().modules.hub.auth.components.loginSupport.inputs.userId.codLogin(
        register.codLogin
      )
    })) ?? []

  function handleChangeSearchTextOrganization(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchTextOrganization(value)
    })()
  }

  function handleChangeSearchTextAccount(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchTextAccount(value)
    })()
  }

  function handleClose(): void {
    reset()
    onClose()
    setSearchTextOrganization('')
    setSearchTextAccount('')
  }

  function onSubmit(model: LoginSupportForm): void {
    if (account && selectedAccount) {
      void createSession({
        model: {
          authFlow: 'SUPORTE',
          username: account.code,
          accountPersonificationId: selectedAccount.codUser,
          institution: model.organizationId,
          profileId: selectedAccount.profileId
        },
        onError: ({ error }) => {
          if (error.code === 'LOGIN.USER_NOT_FOUND') {
            handleError({
              error: new UnprocessableEntityException({
                message: i18n().modules.hub.auth.components.loginSupport.errors.neverLoginBefore
              })
            })
          } else {
            handleError({ error })
          }
        }
      })
    }
  }

  return (
    <Dialog.Container
      xl={5}
      md={8}
      sm={10}
      xs={12}
      data-testid="loginSupport"
      isOpen={isOpen}
      onCancel={handleClose}
    >
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={onSubmit}>
        <Dialog.Header align="center">
          <Dialog.HeaderTitle data-testid="loginSupport">
            {i18n().modules.hub.auth.components.loginSupport.title}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton data-testid="loginSupport" onCancel={handleClose} />
        </Dialog.Header>
        <Dialog.Content align="center">
          <Dialog.ContentText data-testid="loginSupport-subTitle">
            {i18n().modules.hub.auth.components.loginSupport.subTitle}
          </Dialog.ContentText>
          <FormAutocomplete
            control={control}
            name="organizationId"
            label={i18n().modules.hub.auth.components.loginSupport.inputs.organizationId}
            variant="outlined"
            required
            errorText={errors.organizationId?.message}
            isLoading={organizationIsFetching}
            options={data?.registers ?? []}
            optionKeyField="id"
            optionTitleField="name"
            optionSubTitleField="address"
            inputProps={{ autoFocus: true, onChange: handleChangeSearchTextOrganization }}
          />
          <FormAutocomplete
            control={control}
            name="userCode"
            label={i18n().modules.hub.auth.components.loginSupport.inputs.userId.label}
            supportingText={
              organizationId
                ? undefined
                : i18n().modules.hub.auth.components.loginSupport.inputs.userId.supportingText
            }
            variant="outlined"
            required
            errorText={errors.userCode?.message}
            isLoading={isLoadingAccount}
            options={parsedAccounts}
            optionKeyField="profileId"
            optionTitleField="profileNameShort"
            optionSubTitleField="subtitle"
            inputProps={{ onChange: handleChangeSearchTextAccount }}
            disabled={!organizationId}
            onChange={(option) => {
              setSelectedAccount(option)
            }}
            leadingElementField="leadingElement"
            trailingElementField="trailingElement"
          />
        </Dialog.Content>
        <Dialog.Footer align="center">
          <Dialog.FooterRefuseButton data-testid="loginSupport" onClick={handleClose}>
            {i18n().modules.hub.auth.components.loginSupport.buttons.cancel}
          </Dialog.FooterRefuseButton>
          <Dialog.FooterAcceptButton
            data-testid="loginSupport"
            LeadingIcon={<LoginIcon size={18} />}
            type="submit"
          >
            {i18n().modules.hub.auth.components.loginSupport.buttons.login}
          </Dialog.FooterAcceptButton>
        </Dialog.Footer>
      </FormContainer>
    </Dialog.Container>
  )
}
