import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundTopLeft({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="275"
      height="311"
      viewBox="0 0 275 311"
      fill="none"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M219.111 9.85565C219.178 9.61325 219.211 9.17025 218.726 8.84426C210.259 3.19388 199.961 0.326904 188.117 0.326904C173.933 0.326904 162.139 4.54797 153.07 12.8647C143.901 21.2651 139.253 31.9389 139.253 44.5854C139.253 57.2319 143.04 67.8222 150.512 74.7765C154.482 78.471 159.849 82.2992 166.46 86.1357C166.878 86.3781 167.238 86.2277 167.413 86.119C167.589 86.0104 167.89 85.7429 167.848 85.2497C167.271 77.5432 167.154 70.4802 167.513 64.2531C167.53 63.9856 167.405 63.7181 167.196 63.5259C161.445 58.4272 159.205 52.969 159.205 44.0672C159.205 29.6236 171.526 18.7324 187.866 18.7324C189.488 18.7324 191.101 18.816 192.648 18.9832C192.882 19.0083 193.099 18.9665 193.283 18.8494C193.659 18.6238 194.043 18.3897 194.436 18.1724C202.218 13.6839 210.242 11.1094 218.283 10.5327C218.86 10.4909 219.052 10.0897 219.119 9.84729L219.111 9.85565Z"
          css={{ fill }}
        />
        <Path
          d="M226.809 163.051C235.978 154.651 240.626 143.977 240.626 131.33C240.626 118.684 236.839 108.094 229.367 101.139C225.396 97.4449 220.03 93.6166 213.419 89.7801C213.001 89.5377 212.641 89.6881 212.466 89.7968C212.29 89.9054 211.989 90.1729 212.031 90.6661C212.608 98.3727 212.725 105.436 212.365 111.663C212.349 111.93 212.474 112.198 212.683 112.39C218.434 117.489 220.674 122.947 220.674 131.849C220.674 146.292 208.353 157.183 192.012 157.183C190.391 157.183 188.778 157.1 187.231 156.933C187.189 156.933 187.148 156.933 187.106 156.933C186.922 156.933 186.746 156.983 186.596 157.075C186.22 157.3 185.835 157.526 185.442 157.752C177.669 162.24 169.645 164.815 161.596 165.391C161.019 165.433 160.827 165.834 160.76 166.077C160.693 166.319 160.659 166.762 161.144 167.088C169.603 172.739 179.901 175.606 191.745 175.606C205.929 175.606 217.723 171.385 226.792 163.068L226.809 163.051Z"
          css={{ fill }}
        />
        <Path
          d="M107.716 101.741C107.474 101.808 107.073 102 107.031 102.585C106.371 112.741 109.037 123.089 114.955 133.345C122.043 145.624 131.597 153.731 143.332 157.434C155.193 161.17 166.761 159.858 177.711 153.539C189.037 147.003 195.941 138.644 198.231 128.697C199.451 123.407 200.07 116.853 200.095 109.205C200.095 108.721 199.786 108.486 199.61 108.395C199.426 108.294 199.05 108.169 198.641 108.453C192.255 112.808 186.195 116.435 180.628 119.244C180.394 119.361 180.218 119.603 180.16 119.888C178.622 127.419 175.011 132.083 167.304 136.538C154.792 143.76 139.203 138.535 131.028 124.384C130.218 122.98 129.482 121.543 128.855 120.122C128.763 119.904 128.613 119.745 128.429 119.637C128.044 119.419 127.652 119.202 127.267 118.976C119.485 114.488 113.25 108.821 108.728 102.142C108.402 101.658 107.959 101.699 107.716 101.766V101.741Z"
          css={{ fill }}
        />
        <Path
          d="M236.538 18.4817C224.677 14.7454 213.109 16.0577 202.16 22.3767C190.834 28.9131 183.93 37.2717 181.639 47.2183C180.419 52.5093 179.8 59.0624 179.775 66.7105C179.775 67.1953 180.085 67.4293 180.26 67.5213C180.444 67.6216 180.82 67.7469 181.23 67.4628C187.616 63.1079 193.676 59.4803 199.242 56.6719C199.476 56.5549 199.652 56.3124 199.711 56.0283C201.248 48.4972 204.859 43.8331 212.566 39.378C225.079 32.1562 240.667 37.3803 248.842 51.5314C249.653 52.9356 250.388 54.3733 251.015 55.7942C251.032 55.836 251.049 55.8694 251.074 55.9029C251.166 56.0617 251.291 56.1954 251.45 56.279C251.834 56.4963 252.227 56.7137 252.612 56.9393C260.385 61.4279 266.629 67.095 271.151 73.7735C271.477 74.2582 271.92 74.2165 272.162 74.1579C272.405 74.0911 272.806 73.8988 272.848 73.3137C273.508 63.1581 270.842 52.8102 264.924 42.5543C257.836 30.2672 248.282 22.1678 236.547 18.465L236.538 18.4817Z"
          css={{ fill }}
        />
        <Path
          d="M242.991 152.269C243.167 152.444 243.543 152.695 244.061 152.436C253.188 147.93 260.811 140.45 266.738 130.194C273.826 117.915 276.074 105.586 273.408 93.5748C270.716 81.4299 263.795 72.0683 252.837 65.7492C241.512 59.2129 230.821 57.4158 221.067 60.3998C215.876 61.9879 209.883 64.7295 203.255 68.5326C202.837 68.775 202.786 69.1595 202.795 69.3601C202.795 69.5691 202.878 69.9536 203.33 70.1709C210.292 73.5227 216.461 76.9581 221.677 80.3767C221.894 80.5188 222.195 80.5522 222.462 80.4603C229.751 78.028 235.602 78.8137 243.309 83.2688C255.821 90.4905 259.09 106.606 250.923 120.765C250.112 122.169 249.235 123.524 248.315 124.777C248.173 124.97 248.106 125.179 248.115 125.387C248.123 125.83 248.132 126.273 248.132 126.725C248.132 135.71 246.343 143.943 242.816 151.199C242.565 151.717 242.816 152.093 242.991 152.26V152.269Z"
          css={{ fill }}
        />
        <Path
          d="M106.471 82.341C109.162 94.4859 116.083 103.848 127.041 110.167C138.367 116.703 149.058 118.5 158.812 115.516C164.003 113.928 169.996 111.186 176.624 107.383C177.042 107.141 177.092 106.756 177.084 106.556C177.084 106.347 177 105.962 176.549 105.745C169.586 102.393 163.418 98.9578 158.202 95.5391C157.985 95.397 157.684 95.3636 157.416 95.4555C150.128 97.8879 144.277 97.1021 136.57 92.647C124.057 85.4253 120.789 69.31 128.955 55.1506C129.766 53.7464 130.644 52.3923 131.563 51.1385C131.588 51.1051 131.613 51.0716 131.63 51.0382C131.722 50.8794 131.772 50.7039 131.764 50.5283C131.756 50.0853 131.747 49.6423 131.747 49.191C131.747 40.2139 133.536 31.9807 137.055 24.7171C137.306 24.1989 137.055 23.8228 136.879 23.6556C136.704 23.4801 136.328 23.2293 135.809 23.4884C126.682 27.9937 119.059 35.4746 113.133 45.7305C106.045 58.0009 103.805 70.3214 106.471 82.341Z"
          css={{ fill }}
        />
        <Path
          d="M-10.1848 303.879C-10.2319 304.05 -10.2554 304.362 -9.91399 304.591C-3.95058 308.571 3.30206 310.59 11.6438 310.59C21.6338 310.59 29.9402 307.617 36.3275 301.76C42.7854 295.843 46.0585 288.326 46.0585 279.419C46.0585 270.512 43.3918 263.053 38.1289 258.155C35.3326 255.553 31.5532 252.857 26.8967 250.155C26.6024 249.984 26.3492 250.09 26.2256 250.167C26.102 250.243 25.89 250.432 25.9195 250.779C26.3257 256.207 26.4081 261.181 26.155 265.567C26.1432 265.755 26.2315 265.944 26.3787 266.079C30.4288 269.67 32.0065 273.514 32.0065 279.784C32.0065 289.956 23.3292 297.627 11.8204 297.627C10.6783 297.627 9.54216 297.568 8.45309 297.45C8.28825 297.433 8.1352 297.462 8.00569 297.545C7.74078 297.703 7.46998 297.868 7.19329 298.021C1.7126 301.183 -3.93881 302.996 -9.60199 303.402C-10.0082 303.431 -10.1436 303.714 -10.1907 303.885L-10.1848 303.879Z"
          css={{ fill }}
        />
        <Path
          d="M-15.6066 195.984C-22.0645 201.9 -25.3376 209.418 -25.3376 218.325C-25.3376 227.232 -22.6709 234.69 -17.408 239.588C-14.6117 242.19 -10.8323 244.886 -6.17582 247.588C-5.88147 247.759 -5.62834 247.653 -5.50471 247.577C-5.38108 247.5 -5.16916 247.312 -5.1986 246.964C-5.60479 241.537 -5.68721 236.562 -5.43407 232.177C-5.4223 231.988 -5.51059 231.8 -5.65776 231.664C-9.70794 228.073 -11.2856 224.229 -11.2856 217.96C-11.2856 207.787 -2.60837 200.117 8.90049 200.117C10.0425 200.117 11.1787 200.175 12.2678 200.293C12.2972 200.293 12.3267 200.293 12.3561 200.293C12.4856 200.293 12.6092 200.258 12.7152 200.193C12.9801 200.034 13.2509 199.875 13.5276 199.716C19.0024 196.555 24.6538 194.742 30.3229 194.336C30.7291 194.306 30.8645 194.024 30.9116 193.853C30.9586 193.682 30.9822 193.37 30.6408 193.141C24.6832 189.161 17.4306 187.142 9.08887 187.142C-0.901173 187.142 -9.20757 190.115 -15.5948 195.972L-15.6066 195.984Z"
          css={{ fill }}
        />
        <Path
          d="M68.2697 239.164C68.4405 239.117 68.723 238.982 68.7524 238.57C69.2175 231.417 67.3396 224.129 63.1717 216.906C58.1796 208.258 51.4509 202.548 43.1857 199.94C34.8322 197.309 26.6848 198.233 18.973 202.683C10.9962 207.287 6.13366 213.174 4.52065 220.179C3.66116 223.905 3.22554 228.521 3.20787 233.907C3.20787 234.249 3.42569 234.414 3.54931 234.478C3.67882 234.549 3.94374 234.637 4.23219 234.437C8.72977 231.37 12.9978 228.815 16.9184 226.837C17.0833 226.755 17.2069 226.584 17.2481 226.384C18.3313 221.08 20.8744 217.795 26.3021 214.657C35.1148 209.571 46.0938 213.25 51.8512 223.217C52.4222 224.206 52.9403 225.218 53.3818 226.219C53.4466 226.372 53.5525 226.484 53.682 226.56C53.9528 226.714 54.2295 226.867 54.5003 227.026C59.981 230.187 64.3726 234.178 67.5574 238.882C67.787 239.223 68.099 239.194 68.2697 239.147V239.164Z"
          css={{ fill }}
        />
        <Path
          d="M-22.4589 297.804C-14.1054 300.435 -5.958 299.511 1.75381 295.06C9.73054 290.457 14.5931 284.57 16.2061 277.564C17.0656 273.838 17.5012 269.223 17.5189 263.836C17.5189 263.495 17.3011 263.33 17.1775 263.265C17.0479 263.195 16.783 263.106 16.4946 263.306C11.997 266.374 7.729 268.928 3.80834 270.906C3.64351 270.989 3.51988 271.16 3.47867 271.36C2.39548 276.664 -0.14764 279.949 -5.57535 283.086C-14.388 288.173 -25.3671 284.493 -31.1244 274.527C-31.6955 273.538 -32.2135 272.525 -32.655 271.525C-32.6668 271.495 -32.6786 271.472 -32.6962 271.448C-32.761 271.336 -32.8493 271.242 -32.9611 271.183C-33.2319 271.03 -33.5086 270.877 -33.7794 270.718C-39.2542 267.557 -43.6517 263.565 -46.8365 258.862C-47.0661 258.52 -47.3781 258.55 -47.5488 258.591C-47.7196 258.638 -48.0021 258.774 -48.0316 259.186C-48.4966 266.338 -46.6187 273.626 -42.4508 280.849C-37.4587 289.503 -30.73 295.207 -22.4648 297.815L-22.4589 297.804Z"
          css={{ fill }}
        />
        <Path
          d="M-27.0036 203.578C-27.1273 203.454 -27.3922 203.278 -27.7571 203.46C-34.1856 206.633 -39.5545 211.902 -43.7283 219.125C-48.7203 227.773 -50.3039 236.456 -48.426 244.916C-46.5304 253.469 -41.6561 260.063 -33.9384 264.513C-25.9617 269.117 -18.4323 270.382 -11.5623 268.281C-7.90657 267.162 -3.68567 265.231 0.982632 262.553C1.27697 262.382 1.3123 262.111 1.30641 261.97C1.30641 261.823 1.24754 261.552 0.929646 261.399C-3.97412 259.038 -8.31865 256.619 -11.9921 254.211C-12.1451 254.111 -12.3571 254.088 -12.5454 254.152C-17.6788 255.865 -21.7996 255.312 -27.2273 252.174C-36.04 247.088 -38.3418 235.738 -32.5903 225.766C-32.0192 224.777 -31.4011 223.823 -30.7536 222.94C-30.6535 222.805 -30.6064 222.657 -30.6123 222.51C-30.6182 222.198 -30.6241 221.886 -30.6241 221.568C-30.6241 215.24 -29.3643 209.441 -26.88 204.332C-26.7034 203.967 -26.88 203.702 -27.0036 203.584V203.578Z"
          css={{ fill }}
        />
        <Path
          d="M69.1469 252.828C67.2513 244.274 62.377 237.681 54.6592 233.23C46.6825 228.627 39.1532 227.361 32.2832 229.463C28.6274 230.581 24.4065 232.512 19.7382 235.191C19.4439 235.361 19.4086 235.632 19.4145 235.773C19.4145 235.921 19.4733 236.191 19.7912 236.344C24.695 238.705 29.0395 241.125 32.7129 243.532C32.866 243.632 33.0779 243.656 33.2663 243.591C38.3997 241.878 42.5205 242.432 47.9482 245.569C56.7609 250.656 59.0626 262.005 53.3112 271.978C52.7401 272.967 52.122 273.921 51.4744 274.804C51.4568 274.827 51.4391 274.851 51.4274 274.874C51.3626 274.986 51.3273 275.11 51.3332 275.233C51.3391 275.545 51.3449 275.857 51.3449 276.175C51.3449 282.498 50.0851 288.296 47.6068 293.412C47.4302 293.777 47.6068 294.042 47.7304 294.16C47.854 294.283 48.1189 294.46 48.4839 294.277C54.9124 291.104 60.2812 285.836 64.455 278.612C69.4471 269.97 71.0248 261.293 69.1469 252.828Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
