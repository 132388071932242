import { ClipPath, Rect, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function FrameWhiteLabel({ fill, css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="195"
      height="112"
      viewBox="0 0 195 112"
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
      css={css}
      {...restProps}
    >
      <Rect width="195" height="112" rx="8" css={{ fill: '$surface-2' }} />
      <Rect
        opacity="0.2"
        x="16"
        y="16"
        width="163"
        height="16"
        rx="8"
        css={{ fill: '$on-surface-variant' }}
      />
      <Rect x="16" y="80" width="77.5" height="16" rx="8" css={{ fill }} />
      <Rect x="101.5" y="80" width="77.5" height="16" rx="8" css={{ fill }} />
      <ClipPath id="clip0_20717_71261">
        <Rect width="163" height="16" fill="white" transform="translate(16 80)" />
      </ClipPath>
    </Svg>
  )
}
