import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundBottomRight({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="453"
      viewBox="0 0 201 453"
      fill="none"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M176.443 236.102C176.546 235.727 176.598 235.042 175.848 234.538C162.754 225.8 146.829 221.366 128.513 221.366C106.578 221.366 88.3394 227.894 74.3147 240.755C60.1349 253.746 52.9481 270.252 52.9481 289.809C52.9481 309.366 58.8035 325.744 70.3593 336.498C76.4992 342.211 84.7976 348.131 95.0221 354.064C95.6684 354.439 96.2242 354.207 96.4957 354.038C96.7671 353.87 97.2324 353.457 97.1678 352.694C96.2759 340.776 96.095 329.854 96.6508 320.224C96.6766 319.811 96.4827 319.397 96.1596 319.1C87.2665 311.215 83.8024 302.774 83.8024 289.008C83.8024 266.672 102.855 249.829 128.126 249.829C130.633 249.829 133.128 249.958 135.519 250.217C135.881 250.256 136.217 250.191 136.502 250.01C137.083 249.661 137.678 249.299 138.285 248.963C150.319 242.022 162.728 238.041 175.163 237.149C176.055 237.084 176.352 236.464 176.456 236.089L176.443 236.102Z"
          css={{ fill }}
        />
        <Path
          d="M188.348 473.009C202.527 460.019 209.714 443.512 209.714 423.955C209.714 404.398 203.859 388.021 192.303 377.267C186.163 371.553 177.865 365.633 167.64 359.7C166.994 359.325 166.438 359.558 166.167 359.726C165.895 359.894 165.43 360.308 165.494 361.07C166.386 372.988 166.567 383.91 166.012 393.54C165.986 393.954 166.18 394.368 166.503 394.665C175.396 402.55 178.86 410.99 178.86 424.757C178.86 447.093 159.807 463.935 134.537 463.935C132.029 463.935 129.534 463.806 127.143 463.547C127.079 463.547 127.014 463.547 126.949 463.547C126.665 463.547 126.393 463.625 126.161 463.767C125.579 464.116 124.985 464.465 124.377 464.814C112.356 471.755 99.9469 475.737 87.4992 476.628C86.6073 476.693 86.31 477.313 86.2066 477.688C86.1032 478.063 86.0515 478.748 86.8012 479.252C99.8823 487.99 115.807 492.424 134.123 492.424C156.059 492.424 174.297 485.896 188.322 473.035L188.348 473.009Z"
          css={{ fill }}
        />
        <Path
          d="M4.17835 378.197C3.8035 378.301 3.18305 378.598 3.11842 379.503C2.09727 395.208 6.22066 411.21 15.3722 427.07C26.3335 446.059 41.1079 458.597 59.2559 464.323C77.5979 470.101 95.4874 468.072 112.42 458.299C129.935 448.191 140.612 435.265 144.154 419.883C146.041 411.701 146.997 401.567 147.036 389.74C147.036 388.99 146.558 388.628 146.286 388.486C146.002 388.331 145.42 388.137 144.787 388.577C134.912 395.311 125.54 400.921 116.932 405.264C116.57 405.445 116.298 405.82 116.208 406.259C113.829 417.906 108.245 425.118 96.3276 432.008C76.9774 443.176 52.8705 435.097 40.2289 413.214C38.9751 411.042 37.8376 408.819 36.8681 406.621C36.726 406.285 36.4933 406.04 36.2089 405.872C35.6143 405.536 35.0068 405.199 34.4122 404.85C22.3781 397.909 12.7353 389.145 5.74239 378.818C5.23828 378.068 4.5532 378.133 4.17835 378.236V378.197Z"
          css={{ fill }}
        />
        <Path
          d="M203.393 249.441C185.051 243.664 167.162 245.693 150.229 255.465C132.714 265.573 122.037 278.499 118.496 293.881C116.608 302.063 115.652 312.197 115.613 324.024C115.613 324.774 116.091 325.136 116.363 325.278C116.647 325.433 117.229 325.627 117.862 325.188C127.738 318.453 137.109 312.843 145.718 308.5C146.08 308.319 146.351 307.944 146.442 307.505C148.82 295.859 154.404 288.646 166.322 281.756C185.672 270.588 209.779 278.667 222.42 300.551C223.674 302.722 224.812 304.946 225.781 307.143C225.807 307.208 225.833 307.259 225.872 307.311C226.014 307.557 226.208 307.763 226.453 307.893C227.048 308.229 227.656 308.565 228.25 308.914C240.271 315.855 249.927 324.619 256.92 334.947C257.424 335.697 258.109 335.632 258.484 335.541C258.859 335.438 259.479 335.141 259.544 334.236C260.565 318.531 256.442 302.528 247.29 286.668C236.329 267.667 221.554 255.142 203.406 249.416L203.393 249.441Z"
          css={{ fill }}
        />
        <Path
          d="M213.372 456.335C213.644 456.606 214.225 456.994 215.027 456.593C229.142 449.626 240.93 438.057 250.095 422.197C261.056 403.209 264.533 384.143 260.41 365.568C256.248 346.787 245.545 332.31 228.599 322.538C211.084 312.43 194.552 309.651 179.467 314.265C171.44 316.721 162.173 320.961 151.922 326.842C151.276 327.217 151.198 327.812 151.211 328.122C151.211 328.445 151.341 329.04 152.039 329.376C162.806 334.559 172.345 339.872 180.411 345.158C180.747 345.378 181.212 345.43 181.626 345.288C192.898 341.526 201.946 342.741 213.864 349.631C233.214 360.799 238.268 385.72 225.639 407.617C224.385 409.788 223.028 411.882 221.606 413.821C221.386 414.118 221.283 414.442 221.296 414.765C221.309 415.45 221.322 416.135 221.322 416.833C221.322 430.728 218.556 443.46 213.101 454.68C212.713 455.482 213.101 456.063 213.372 456.322V456.335Z"
          css={{ fill }}
        />
        <Path
          d="M2.25238 348.196C6.41454 366.977 17.1173 381.454 34.0632 391.227C51.5779 401.335 68.1102 404.114 83.1948 399.499C91.2219 397.043 100.49 392.803 110.74 386.922C111.386 386.547 111.464 385.953 111.451 385.642C111.451 385.319 111.322 384.725 110.624 384.389C99.8564 379.205 90.317 373.893 82.2512 368.606C81.9151 368.386 81.4498 368.335 81.0362 368.477C69.7647 372.238 60.7166 371.023 48.7988 364.134C29.4486 352.966 24.3946 328.044 37.0233 306.148C38.2771 303.976 39.6343 301.882 41.0562 299.943C41.0949 299.892 41.1337 299.84 41.1596 299.788C41.3017 299.543 41.3793 299.271 41.3664 299C41.3535 298.315 41.3405 297.629 41.3405 296.931C41.3405 283.049 44.1067 270.317 49.5485 259.084C49.9363 258.283 49.5485 257.701 49.2771 257.443C49.0056 257.171 48.424 256.783 47.6226 257.184C33.5074 264.151 21.7189 275.72 12.5544 291.58C1.59316 310.556 -1.87101 329.608 2.25238 348.196Z"
          css={{ fill }}
        />
        <Path
          d="M71.3307 96.9106C71.2919 97.0513 71.2725 97.3085 71.554 97.4978C76.4698 100.778 82.4485 102.443 89.3249 102.443C97.5602 102.443 104.407 99.9921 109.673 95.1636C114.996 90.2865 117.694 84.0895 117.694 76.7472C117.694 69.4049 115.496 63.2564 111.158 59.2188C108.853 57.0739 105.737 54.8513 101.899 52.6239C101.656 52.4832 101.447 52.5705 101.345 52.6336C101.243 52.6967 101.069 52.852 101.093 53.1383C101.428 57.6126 101.496 61.7132 101.287 65.3285C101.277 65.4838 101.35 65.6391 101.471 65.7507C104.81 68.7109 106.111 71.8798 106.111 77.0481C106.111 85.4337 98.9578 91.7569 89.4705 91.7569C88.5291 91.7569 87.5925 91.7084 86.6947 91.6114C86.5588 91.5968 86.4327 91.6211 86.3259 91.689C86.1075 91.82 85.8843 91.9559 85.6562 92.0821C81.1382 94.688 76.4796 96.1827 71.8112 96.5175C71.4763 96.5418 71.3647 96.7747 71.3259 96.9155L71.3307 96.9106Z"
          css={{ fill }}
        />
        <Path
          d="M66.8613 7.96835C61.5378 12.8454 58.8396 19.0425 58.8396 26.3848C58.8396 33.7271 61.0379 39.8756 65.3763 43.9131C67.6814 46.0581 70.7969 48.2806 74.6355 50.5081C74.8781 50.6488 75.0868 50.5614 75.1887 50.4984C75.2906 50.4353 75.4653 50.28 75.4411 49.9937C75.1062 45.5194 75.0383 41.4188 75.2469 37.8034C75.2567 37.6481 75.1839 37.4928 75.0625 37.3812C71.7238 34.421 70.4232 31.2521 70.4232 26.0839C70.4232 17.6982 77.5763 11.375 87.0635 11.375C88.005 11.375 88.9416 11.4235 89.8393 11.5206C89.8636 11.5206 89.8879 11.5206 89.9121 11.5206C90.0189 11.5206 90.1208 11.4915 90.2081 11.4381C90.4265 11.3071 90.6497 11.1761 90.8778 11.045C95.3909 8.43909 100.05 6.94444 104.723 6.60959C105.058 6.58533 105.169 6.35237 105.208 6.21164C105.247 6.07091 105.266 5.81371 104.985 5.62445C100.074 2.34395 94.0952 0.679443 87.2188 0.679443C78.9836 0.679443 72.1363 3.1301 66.871 7.95865L66.8613 7.96835Z"
          css={{ fill }}
        />
        <Path
          d="M136.004 43.5637C136.145 43.5249 136.378 43.4133 136.402 43.0736C136.785 37.1774 135.237 31.1696 131.802 25.2152C127.686 18.0865 122.14 13.3792 115.326 11.2294C108.44 9.06023 101.724 9.82212 95.3667 13.4908C88.7911 17.2857 84.7827 22.1385 83.453 27.9134C82.7445 30.9852 82.3854 34.7898 82.3709 39.2301C82.3709 39.5116 82.5504 39.6475 82.6523 39.7009C82.7591 39.7591 82.9775 39.8319 83.2153 39.6669C86.9228 37.1386 90.4411 35.0325 93.673 33.4019C93.8089 33.334 93.9108 33.1933 93.9448 33.0283C94.8377 28.6559 96.9341 25.948 101.408 23.3615C108.673 19.1686 117.724 22.2017 122.47 30.4174C122.94 31.2327 123.367 32.0674 123.731 32.8924C123.785 33.0185 123.872 33.1107 123.979 33.1738C124.202 33.3 124.43 33.4262 124.653 33.5572C129.171 36.1632 132.792 39.4534 135.417 43.3308C135.606 43.6122 135.863 43.588 136.004 43.5491V43.5637Z"
          css={{ fill }}
        />
        <Path
          d="M61.2126 91.9025C68.0988 94.0717 74.815 93.3098 81.1722 89.6411C87.7478 85.8462 91.7562 80.9934 93.0859 75.2186C93.7944 72.1467 94.1535 68.3421 94.168 63.9018C94.168 63.6204 93.9885 63.4845 93.8866 63.4311C93.7798 63.3729 93.5614 63.3001 93.3236 63.4651C89.6161 65.9934 86.0978 68.0995 82.8658 69.73C82.73 69.798 82.6281 69.9387 82.5941 70.1037C81.7012 74.4761 79.6048 77.184 75.1305 79.7705C67.8658 83.9633 58.8153 80.9303 54.0693 72.7145C53.5986 71.8992 53.1715 71.0646 52.8076 70.2396C52.7979 70.2153 52.7882 70.1959 52.7736 70.1765C52.7202 70.0843 52.6474 70.0067 52.5552 69.9581C52.332 69.832 52.1039 69.7058 51.8807 69.5747C47.3676 66.9688 43.7425 63.6786 41.1172 59.8012C40.9279 59.5197 40.6707 59.544 40.53 59.578C40.3893 59.6168 40.1563 59.7284 40.132 60.0681C39.7487 65.9642 41.2967 71.972 44.7325 77.9264C48.8477 85.0601 54.3944 89.7624 61.2078 91.9122L61.2126 91.9025Z"
          css={{ fill }}
        />
        <Path
          d="M57.4663 14.2285C57.3643 14.1266 57.146 13.981 56.8451 14.1314C51.5458 16.7471 47.1201 21.0903 43.6794 27.0447C39.5643 34.1735 38.2589 41.3314 39.8069 48.3049C41.3695 55.356 45.3876 60.7912 51.7496 64.4599C58.3252 68.2548 64.532 69.2981 70.1952 67.5657C73.2088 66.6436 76.6882 65.0519 80.5365 62.8439C80.7791 62.7032 80.8083 62.4799 80.8034 62.3635C80.8034 62.2421 80.7549 62.0189 80.4928 61.8928C76.4504 59.9468 72.8691 57.9523 69.8409 55.9675C69.7147 55.885 69.54 55.8656 69.3848 55.9189C65.1531 57.3311 61.7561 56.8749 57.2819 54.2884C50.0172 50.0956 48.1198 40.7394 52.8609 32.5187C53.3317 31.7034 53.8412 30.9173 54.375 30.1894C54.4575 30.0778 54.4963 29.9564 54.4915 29.8351C54.4866 29.5779 54.4818 29.3207 54.4818 29.0587C54.4818 23.8419 55.5203 19.0619 57.5682 14.8496C57.7138 14.5488 57.5682 14.3304 57.4663 14.2333V14.2285Z"
          css={{ fill }}
        />
        <Path
          d="M136.727 54.8271C135.165 47.7759 131.146 42.3408 124.784 38.6721C118.209 34.8772 112.002 33.8338 106.339 35.5663C103.325 36.4883 99.8458 38.08 95.9975 40.2881C95.7549 40.4288 95.7258 40.652 95.7306 40.7685C95.7306 40.8898 95.7792 41.113 96.0412 41.2392C100.084 43.1852 103.665 45.1797 106.693 47.1645C106.819 47.247 106.994 47.2664 107.149 47.213C111.381 45.8008 114.778 46.257 119.252 48.8436C126.517 53.0364 128.414 62.3926 123.673 70.6133C123.202 71.4285 122.693 72.2147 122.159 72.9426C122.144 72.962 122.13 72.9814 122.12 73.0008C122.067 73.093 122.038 73.1949 122.043 73.2968C122.047 73.554 122.052 73.8112 122.052 74.0733C122.052 79.2852 121.014 84.0652 118.971 88.2823C118.825 88.5832 118.971 88.8016 119.073 88.8986C119.175 89.0005 119.393 89.1461 119.694 88.9957C124.993 86.38 129.419 82.0368 132.859 76.0824C136.975 68.9584 138.275 61.8054 136.727 54.8271Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
