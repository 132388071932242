import { yupResolver } from '@hookform/resolvers/yup'
import { Accordion } from '@positivote/design-system/components/Accordion'
import { AlertCard } from '@positivote/design-system/components/AlertCard'
import { Breadcrumbs } from '@positivote/design-system/components/Breadcrumbs'
import { Button } from '@positivote/design-system/components/Button'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Chip, ChipProps } from '@positivote/design-system/components/Chip'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { Div } from '@positivote/design-system/components/Div'
import { DrawerNavigation } from '@positivote/design-system/components/DrawerNavigation'
import { Grid } from '@positivote/design-system/components/Grid'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { ProgressBar } from '@positivote/design-system/components/ProgressBar'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Switch } from '@positivote/design-system/components/Switch'
import { Table } from '@positivote/design-system/components/Table'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { TextArea } from '@positivote/design-system/components/TextArea'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormCheckbox } from '@positivote/design-system/components-form/Checkbox'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormMediaInput } from '@positivote/design-system/components-form/MediaInput'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTagInput } from '@positivote/design-system/components-form/TagInput'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useAlert } from '@positivote/design-system/hooks'
import { AccountBoxIcon } from '@positivote/design-system/icons/AccountBox'
import { AppsIcon } from '@positivote/design-system/icons/Apps'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { DashboardIcon } from '@positivote/design-system/icons/Dashboard'
import { HomeIcon } from '@positivote/design-system/icons/Home'
import { InsertChartIcon } from '@positivote/design-system/icons/InsertChart'
import { InsightsIcon } from '@positivote/design-system/icons/Insights'
import { LogoutIcon } from '@positivote/design-system/icons/Logout'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { SettingsIcon } from '@positivote/design-system/icons/Settings'
import { SettingsEthernetIcon } from '@positivote/design-system/icons/SettingsEthernet'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { HubIcon } from '@/common/assets/icons/HubIcon'
import { changePageTitle, isValidDate, ptBrDateRegex } from '@/common/helpers'
import { i18n } from '@/common/i18n'

interface FormParams {
  outlinedTextField: string
  outlinedTextArea: string
  outlinedSelect: string
  outlinedAutocomplete: string
  outlinedMultipleAutocomplete: string[]
  outlinedTagInput: string[]
  outlinedDatePicker: string
  mediaInput: string
  checkBox: boolean
}

const formParamsValidationSchema = yup.object().shape({
  outlinedTextField: yup
    .string()
    .required('Campo obrigatório')
    .min(2, 'Mínimo de 2 caracteres')
    .max(5, 'Máximo de 5 caracteres'),
  outlinedTextArea: yup
    .string()
    .required('Campo obrigatório')
    .min(2, 'Mínimo de 2 caracteres')
    .max(5, 'Máximo de 5 caracteres'),
  outlinedSelect: yup
    .string()
    .required('Campo obrigatório')
    .oneOf(['01', '02', '03'], 'Só é possível escolher de 1 à 3'),
  outlinedAutocomplete: yup
    .string()
    .required('Campo obrigatório')
    .oneOf(['01', '02', '03'], 'Só é possível escolher de 1 à 3'),
  outlinedMultipleAutocomplete: yup
    .array()
    .of(yup.string().required().oneOf(['01', '02', '03'], 'Só é possível escolher de 1 à 3'))
    .required(i18n().common.validators.required)
    .min(1, i18n().common.validators.min(1)),
  outlinedTagInput: yup
    .array()
    .of(yup.string().required().oneOf(['1', '2', '3'], 'Só é possível escolher de 1 à 3'))
    .required(i18n().common.validators.required)
    .min(1, i18n().common.validators.min(1)),
  outlinedDatePicker: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'isValidDate',
      message: 'Data invalida',
      test: (value) => isValidDate(value, ptBrDateRegex).isValid
    }),
  mediaInput: yup.string().required(i18n().common.validators.required),
  checkBox: yup
    .boolean()
    .required(i18n().common.validators.required)
    .oneOf([true], i18n().common.validators.required)
})

function formParamsSanitizer(params: Partial<FormParams>): FormParams {
  return {
    outlinedTextField: (params.outlinedTextField ?? '').trim(),
    outlinedTextArea: (params.outlinedTextArea ?? '').trim(),
    outlinedSelect: (params.outlinedSelect ?? '').trim(),
    outlinedAutocomplete: (params.outlinedAutocomplete ?? '').trim(),
    outlinedMultipleAutocomplete: params.outlinedMultipleAutocomplete ?? [],
    outlinedTagInput: params.outlinedTagInput ?? [],
    outlinedDatePicker: (params.outlinedDatePicker ?? '').trim(),
    mediaInput: params.mediaInput ?? '',
    checkBox: params.checkBox ?? false
  }
}

const selectOptions = [
  { key: '01', value: 'Test 01 Test 01 Test 01 Test 01 Test 01 Test 01' },
  { key: '02', value: 'Test 02 Test 02 Test 02 Test 02 Test 02 Test 02' },
  { key: '03', value: 'Test 03 Test 03 Test 03 Test 03 Test 03 Test 03' },
  { key: '04', value: 'Test 04 Test 04 Test 04 Test 04 Test 04 Test 04' },
  { key: '05', value: 'Test 05 Test 05 Test 05 Test 05 Test 05 Test 05' },
  { key: '06', value: 'Test 06 Test 06 Test 06 Test 06 Test 06 Test 06' },
  { key: '07', value: 'Test 07 Test 07 Test 07 Test 07 Test 07 Test 07' },
  { key: '08', value: 'Test 08 Test 08 Test 08 Test 08 Test 08 Test 08' },
  { key: '09', value: 'Test 09 Test 09 Test 09 Test 09 Test 09 Test 09' },
  { key: '10', value: 'Test 10 Test 10 Test 10 Test 10 Test 10 Test 10' }
]

const stepperSteps: StepProps[] = [
  {
    position: 1,
    title: 'Title 1',
    caption: 'Caption 1',
    status: 'active',
    canGoNext: true,
    children: <Typography>Children 1</Typography>
  },
  {
    position: 2,
    title: 'Title 2',
    caption: 'Caption 2',
    status: 'error',
    canGoNext: true,
    children: <Typography>Children 2</Typography>
  },
  {
    position: 3,
    title: 'Title 3',
    caption: 'Caption 3',
    status: 'active',
    canGoNext: true,
    children: <Typography>Children 3</Typography>
  },
  {
    position: 4,
    title: 'Title 4',
    caption: 'Caption 4',
    status: 'active',
    canGoNext: true,
    children: <Typography>Children 4</Typography>
  },
  {
    position: 5,
    title: 'Title 5',
    caption: 'Caption 5',
    status: 'active',
    canGoNext: true,
    children: <Typography>Children 5</Typography>
  }
]

export function DesignSystem(): JSX.Element {
  changePageTitle(i18n().common.pages.designSystem.title)

  const { addAlertMessage } = useAlert()
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormParams>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(formParamsValidationSchema)(formParamsSanitizer(values), ...args)
  })

  const [selectedDrawerOption, setSelectedDrawerOption] = useState<string | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [checked, setChecked] = useState(false)
  const [currentStepPosition, setCurrentStepPosition] = useState(3)
  const navigate = useNavigate()

  const disabled = false

  function onSubmit(model: FormParams): void {
    addAlertMessage({
      alertKey: 'onSubmit',
      severity: 'success',
      title: 'Formulário válido!',
      subTitle: JSON.stringify(model)
    })
  }

  const drawerOptions = [
    {
      key: 'home',
      icon: <HomeIcon />,
      label: i18n().common.components.drawer.options.home,
      action: () => setSelectedDrawerOption('home'),
      selected: selectedDrawerOption === 'home'
    },
    {
      key: 'dashboard',
      icon: <DashboardIcon />,
      label: i18n().common.components.drawer.options.dashboard,
      action: () => setSelectedDrawerOption('dashboard'),
      selected: selectedDrawerOption === 'dashboard'
    },
    {
      key: 'mySolutions',
      icon: <AppsIcon />,
      label: i18n().common.components.drawer.options.mySolutions,
      action: () => setSelectedDrawerOption('mySolutions'),
      selected: selectedDrawerOption === 'mySolutions'
    },
    {
      key: 'dataIntelligence',
      icon: <InsightsIcon />,
      label: i18n().common.components.drawer.options.dataIntelligence,
      action: () => setSelectedDrawerOption('dataIntelligence'),
      selected: selectedDrawerOption === 'dataIntelligence'
    },
    {
      key: 'credentials',
      icon: <AccountBoxIcon />,
      label: i18n().common.components.drawer.options.credentials,
      action: () => setSelectedDrawerOption('credentials'),
      selected: selectedDrawerOption === 'credentials'
    },
    {
      key: 'accessReports',
      icon: <InsertChartIcon />,
      label: i18n().common.components.drawer.options.accessReports,
      action: () => setSelectedDrawerOption('accessReports'),
      selected: selectedDrawerOption === 'accessReports'
    },
    {
      key: 'settings',
      icon: <SettingsIcon />,
      label: i18n().common.components.drawer.options.settings,
      action: () => setSelectedDrawerOption('settings'),
      selected: selectedDrawerOption === 'settings'
    },
    {
      key: 'dataManagement',
      icon: <SettingsEthernetIcon />,
      label: i18n().common.components.drawer.options.dataManagement,
      action: () => setSelectedDrawerOption('dataManagement'),
      selected: selectedDrawerOption === 'dataManagement'
    },
    {
      key: 'logout',
      icon: <LogoutIcon />,
      label: i18n().common.components.drawer.options.logout,
      action: () => setSelectedDrawerOption('logout'),
      selected: false
    }
  ]

  function handleChecked(): void {
    setChecked(!checked)
  }

  const optionsTabs = [
    {
      label: 'TESTE 1',
      key: 'key1',
      children: <>RENDER PAGE 1</>,
      icon: HomeIcon
    },
    {
      label: 'TESTE 2',
      key: 'Key2',
      children: <>RENDER PAGE 2</>,
      icon: HomeIcon
    }
  ]

  const items = [
    {
      label: 'Breadcrumb 1'
    },
    {
      label: 'Breadcrumb 2',
      onClick: () => {
        navigate('/')
      }
    },
    { label: 'Breadcrumb 3' }
  ]

  return (
    <>
      <DrawerNavigation
        logo="https://www.pngmart.com/files/10/Tesla-Logo-PNG-File.png"
        profile={{
          picture:
            'https://media.vanityfair.com/photos/609d51288fee0e2d204a86ea/master/w_2560%2Cc_limit/Elon5.13.jpg',
          title: 'Profile Name',
          subTitle1: 'Profile Role Name',
          subTitle2: 'Profile Organization Name',
          onClick: () => setSelectedDrawerOption('profile'),
          selected: selectedDrawerOption === 'profile'
        }}
        options={drawerOptions}
      />
      <Grid
        spacing="$md"
        css={{
          overflowY: 'auto',
          flex: 1,
          alignItems: 'flex-start',
          padding: '$lg'
        }}
      >
        <Grid xl={12}>
          <Typography variant="displaySmall">Design System</Typography>
        </Grid>

        <Grid xl={12}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">AlertCard</Typography>
            <Grid xl={12} spacing="$md">
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="standard"
                  severity="success"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="standard"
                  severity="info"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="standard"
                  severity="warning"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="standard"
                  severity="critical"
                  hideInMs={false}
                />
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="filled"
                  severity="success"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="filled"
                  severity="info"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="filled"
                  severity="warning"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="filled"
                  severity="critical"
                  hideInMs={false}
                />
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="outlined"
                  severity="success"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="outlined"
                  severity="info"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="outlined"
                  severity="warning"
                  hideInMs={false}
                />
                <AlertCard
                  title="title"
                  subTitle="subTitle"
                  variant="outlined"
                  severity="critical"
                  hideInMs={false}
                />
              </Grid>
            </Grid>
          </Div>
        </Grid>

        <Grid xl={12}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Typography</Typography>
            <Grid xl={12} spacing="$md">
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <Typography variant="displayLarge">displayLarge</Typography>
                <Typography variant="displayMedium">displayMedium</Typography>
                <Typography variant="displaySmall">displaySmall</Typography>
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <Typography variant="headlineLarge">headlineLarge</Typography>
                <Typography variant="headlineMedium">headlineMedium</Typography>
                <Typography variant="headlineSmall">headlineSmall</Typography>
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <Typography variant="titleLarge">titleLarge</Typography>
                <Typography variant="titleMedium">titleMedium</Typography>
                <Typography variant="titleSmall">titleSmall</Typography>
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <Typography variant="labelLarge">labelLarge</Typography>
                <Typography variant="labelMedium">labelMedium</Typography>
                <Typography variant="labelSmall">labelSmall</Typography>
              </Grid>
              <Grid
                xl={4}
                css={{ display: 'flex', flexDirection: 'column', gap: '$lg', overflow: 'hidden' }}
              >
                <Typography variant="bodyLarge">bodyLarge</Typography>
                <Typography variant="bodyMedium">bodyMedium</Typography>
                <Typography variant="bodySmall">bodySmall</Typography>
              </Grid>
            </Grid>
          </Div>
        </Grid>

        <Grid xl={12}>
          <FormContainer
            formHandleSubmit={handleSubmit}
            onSubmit={onSubmit}
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$lg',
              padding: '$lg',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Form</Typography>
            <FormTextField
              control={control}
              name="outlinedTextField"
              label="TextField - Outlined"
              variant="outlined"
              errorText={errors.outlinedTextField?.message}
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              trailingIcon={{ icon: VisibilityIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormTextField
              control={control}
              name="outlinedTextArea"
              label="TextArea - Outlined"
              variant="outlined"
              errorText={errors.outlinedTextArea?.message}
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              trailingIcon={{ icon: VisibilityIcon }}
              CustomInput={TextArea}
              css={{
                width: '100%',
                '& .TextField-Input': { paddingTop: '$xs', resize: 'none', height: '100px' }
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormSelect
              control={control}
              name="outlinedSelect"
              label="Select - Outlined"
              options={selectOptions}
              optionKeyField="key"
              optionTitleField="value"
              hasNoneOption
              variant="outlined"
              errorText={errors.outlinedSelect?.message}
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormAutocomplete
              control={control}
              name="outlinedAutocomplete"
              label="Autocomplete - Outlined"
              options={selectOptions}
              optionKeyField="key"
              optionTitleField="value"
              hasNoneOption
              variant="outlined"
              errorText={errors.outlinedAutocomplete?.message}
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormAutocomplete
              control={control}
              name="outlinedMultipleAutocomplete"
              label="MultipleAutocomplete - Outlined"
              options={selectOptions}
              optionKeyField="key"
              optionTitleField="value"
              hasNoneOption
              variant="outlined"
              errorText={
                errors.outlinedMultipleAutocomplete?.message ??
                errors.outlinedMultipleAutocomplete?.find?.((error) => !!error)?.message
              }
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
              multiple
            />
            <FormTagInput
              control={control}
              name="outlinedTagInput"
              label="TagInput - Outlined"
              variant="outlined"
              errorText={
                errors.outlinedTagInput?.message ??
                errors.outlinedTagInput?.find?.((error) => !!error)?.message
              }
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormDatePicker
              control={control}
              name="outlinedDatePicker"
              label="DatePicker - Outlined"
              variant="outlined"
              errorText={errors.outlinedDatePicker?.message}
              supportingText="supportingText"
              required
              disabled={disabled}
              leadingIcon={{ icon: SearchIcon }}
              css={{
                width: '100%'
                // '& .TextField-FieldSet': { borderColor: 'red' },
                // '& .TextField-Label': { color: 'red' },
                // '& .TextField-Input': { color: 'red' },
                // '& .TextField-SupportingText': { color: 'red' },
              }}
            />
            <FormCheckbox
              name="checkBox"
              register={register}
              hasError={!!errors.checkBox?.message}
            />
            <Typography variant="titleLarge">Media Upload</Typography>
            <FormMediaInput
              control={control}
              accept=".svg,.png,.svg+xml"
              name="mediaInput"
              htmlFor="urlLogo"
              alt="urlLogo"
              errorText={errors.mediaInput?.message}
            />

            <Dialog.Container isOpen={isDialogOpen} onCancel={() => setIsDialogOpen(false)}>
              <Dialog.Header align="default">
                <HubIcon size={18} />
                <Dialog.HeaderTitle>
                  Title label Title label Title label Title label Title label Title label Title
                  label Title label Title label
                </Dialog.HeaderTitle>
                <Dialog.HeaderCloseButton onCancel={() => setIsDialogOpen(false)} />
              </Dialog.Header>
              <Dialog.Content align="default">
                {[
                  'Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 Content text 1 ',
                  'Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 Content text 2 '
                ].map((contentText) => (
                  <Dialog.ContentText key={contentText}>{contentText}</Dialog.ContentText>
                ))}
              </Dialog.Content>
              <Dialog.Footer align="default">
                <Dialog.FooterRefuseButton
                  LeadingIcon={<CancelIcon size={18} />}
                  onClick={() => setIsDialogOpen(false)}
                >
                  Refuse label
                </Dialog.FooterRefuseButton>
                <Dialog.FooterAcceptButton
                  LeadingIcon={<CheckCircleIcon size={18} />}
                  onClick={() => setIsDialogOpen(false)}
                >
                  Accept label
                </Dialog.FooterAcceptButton>
              </Dialog.Footer>
            </Dialog.Container>
            <Button variant="text" disabled={disabled} onClick={() => setIsDialogOpen(true)}>
              Abrir Dialog
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                reset({
                  outlinedTextField: '12345',
                  outlinedTextArea: '12345',
                  outlinedSelect: '01',
                  outlinedAutocomplete: '01',
                  outlinedMultipleAutocomplete: ['01'],
                  outlinedTagInput: ['1'],
                  outlinedDatePicker: '01/01/2000',
                  mediaInput:
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII=',
                  checkBox: true
                })
              }
              disabled={disabled}
            >
              Preencher
            </Button>
            <Button
              variant="filled"
              type="submit"
              disabled={disabled}
              // isLoading
            >
              Enviar
            </Button>
          </FormContainer>
        </Grid>

        <Grid xl={12}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Table</Typography>
            <Table
              registers={selectOptions}
              registerKeyField="key"
              onRegisterClick={(clickedRegister) => {
                addAlertMessage({
                  alertKey: `onRegisterClick-${clickedRegister.key}`,
                  subTitle: `Clicou no registro: ${clickedRegister.value}`
                })
              }}
              columns={[
                { keyField: 'key', headerLabel: 'Código', width: '50%' },
                { keyField: 'value', headerLabel: 'Nome', width: '50%' }
              ]}
              paginationProps={{
                registersTotal: 20,
                perPage,
                setPerPage: (newPerPage) => {
                  setPerPage(newPerPage)
                  addAlertMessage({
                    subTitle: `Por pagina alterado: ${newPerPage}`
                  })
                },
                page,
                setPage: (newPage) => {
                  setPage(newPage)
                  addAlertMessage({
                    subTitle: `Pagina alterada: ${newPage}`
                  })
                }
              }}
              CustomFooter={
                <Pagination
                  lastPage={10}
                  size="lg"
                  variant="outlined"
                  page={page}
                  setPage={(newPage) => {
                    setPage(newPage)
                    addAlertMessage({
                      subTitle: `Pagina alterada: ${newPage}`
                    })
                  }}
                />
              }
              css={{ maxHeight: 300 }}
            />
          </Div>
        </Grid>

        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">ProgressBar</Typography>
            <ProgressBar
              filledPercentage={50}
              css={{
                width: '100px'
              }}
            />
          </Div>
        </Grid>
        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Switch</Typography>
            <Switch checked={checked} onChange={handleChecked} size="lg" />
          </Div>
        </Grid>

        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Tabs</Typography>
            <Tabs options={optionsTabs} />
          </Div>
        </Grid>
        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Tabs</Typography>
            <Div>
              <Typography variant="bodyLarge">lg</Typography>
              <Breadcrumbs items={items} size="lg" />
              <Typography variant="bodyLarge">sm</Typography>
              <Breadcrumbs items={items} size="sm" />
            </Div>
          </Div>
        </Grid>

        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Checkbox</Typography>
            <Checkbox inputProps={{ checked: true }} />
            <Checkbox inputProps={{ checked: false }} />
            <Checkbox label="Label" inputProps={{ checked: true }} />
            <Checkbox label="Label" hasError inputProps={{ checked: true }} />
            <Checkbox label="Label" inputProps={{ checked: true }} indeterminate />
            <Checkbox label="Label" inputProps={{ checked: true }} indeterminate hasError />
            <Checkbox label="Label" disabled />
            <Checkbox label="Label" disabled indeterminate inputProps={{ checked: true }} />
          </Div>
        </Grid>
        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography>Accordion</Typography>
            <Accordion Summary={<Typography>1º Bimestre</Typography>}>
              <Accordion
                Summary={<Typography>Título tema</Typography>}
                css={{ backgroundColor: '$surface-2' }}
              >
                <Accordion
                  Summary={<Typography variant="labelLarge">Accordion</Typography>}
                  css={{ backgroundColor: '$on-primary' }}
                ></Accordion>
              </Accordion>
            </Accordion>
          </Div>
        </Grid>

        <Grid xl={6}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography variant="titleLarge">Chip</Typography>
            <Grid spacing="$xs">
              {(
                [
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'neutral',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'neutral',
                    hasError: true,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'neutral',
                    hasError: false,
                    disabled: true,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'sm',
                    color: 'neutral',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'neutral',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'neutral',
                    hasError: true,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'neutral',
                    hasError: false,
                    disabled: true,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'sm',
                    color: 'neutral',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'primary',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'primary',
                    hasError: true,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'lg',
                    color: 'primary',
                    hasError: false,
                    disabled: true,
                    showRemove: true
                  },
                  {
                    variant: 'filled',
                    size: 'sm',
                    color: 'primary',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'primary',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'primary',
                    hasError: true,
                    disabled: false,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'lg',
                    color: 'primary',
                    hasError: false,
                    disabled: true,
                    showRemove: true
                  },
                  {
                    variant: 'outlined',
                    size: 'sm',
                    color: 'primary',
                    hasError: false,
                    disabled: false,
                    showRemove: true
                  }
                ] as Array<Omit<ChipProps, 'label' | 'onRemove'> & { showRemove: boolean }>
              ).map(({ showRemove, ...restProps }, index) => (
                <Grid key={index} xl={3}>
                  <Chip
                    label="Label"
                    onRemove={
                      showRemove
                        ? () =>
                            addAlertMessage({
                              alertKey: `onRemove-${JSON.stringify(restProps)}`,
                              subTitle: `Removeu o: ${JSON.stringify(restProps)}`
                            })
                        : undefined
                    }
                    {...restProps}
                  />
                </Grid>
              ))}
            </Grid>
          </Div>
        </Grid>

        <Grid xl={12}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              padding: '$lg',
              gap: '$lg',
              overflow: 'hidden'
            }}
          >
            <Typography>Stepper</Typography>
            <Grid spacing="$md">
              <Grid
                xl={12}
                css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Stepper
                  orientation="horizontal"
                  position="start"
                  stepOrientation="vertical"
                  mobileProps={{ progressType: 'dots', breakpoint: Breakpoint.sm }}
                  previousButtonProps={{
                    onClick: (newStep) => setCurrentStepPosition(newStep.position)
                  }}
                  nextButtonProps={{
                    onClick: (newStep) => setCurrentStepPosition(newStep.position)
                  }}
                  cancelButtonProps={{
                    onClick: () =>
                      addAlertMessage({
                        alertKey: 'onClickCancel-Stepper',
                        subTitle: 'Clicou em cancelar'
                      })
                  }}
                  doneButtonProps={{
                    onClick: () =>
                      addAlertMessage({
                        alertKey: 'onClickDone-Stepper',
                        subTitle: 'Clicou em Finalizar cadastro'
                      })
                  }}
                  currentStepPosition={currentStepPosition}
                  steps={stepperSteps}
                  css={{ flex: 1 }}
                />
              </Grid>
              <Grid
                xl={12}
                css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Stepper
                  orientation="vertical"
                  position="start"
                  stepOrientation="horizontal"
                  mobileProps={{ progressType: 'progress', breakpoint: Breakpoint.sm }}
                  previousButtonProps={{
                    onClick: (newStep) => setCurrentStepPosition(newStep.position)
                  }}
                  nextButtonProps={{
                    onClick: (newStep) => setCurrentStepPosition(newStep.position)
                  }}
                  cancelButtonProps={{
                    onClick: () =>
                      addAlertMessage({
                        alertKey: 'onClickCancel-Stepper',
                        subTitle: 'Clicou em cancelar'
                      })
                  }}
                  doneButtonProps={{
                    onClick: () =>
                      addAlertMessage({
                        alertKey: 'onClickDone-Stepper',
                        subTitle: 'Clicou em Finalizar cadastro'
                      })
                  }}
                  currentStepPosition={currentStepPosition}
                  steps={stepperSteps}
                  css={{ flex: 1, '& .Stepper-ChildrenWrapper': { flex: 1 } }}
                />
              </Grid>
            </Grid>
          </Div>
        </Grid>
      </Grid>
    </>
  )
}
