import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { IconProps } from '@positivote/design-system/icons/types'

import {
  OnboardingInRegisterStageFormatted,
  OnboardingInRegisterStageStatus
} from '@/modules/hub/onboarding/contracts'

export function StageCard({
  stage,
  Icon
}: {
  stage?: OnboardingInRegisterStageFormatted
  Icon: (props: IconProps) => JSX.Element
}): JSX.Element {
  return (
    <Grid xl={6}>
      <BaseCard
        css={{
          borderRadius: '$md',
          '& .BaseCard-StateLayer': { flexDirection: 'row', gap: '$md' }
        }}
      >
        <IconWrapper
          size="$3xl"
          css={{
            backgroundColor:
              stage?.status === OnboardingInRegisterStageStatus.COMPLETED
                ? '$success-container'
                : '$surface-variant'
          }}
        >
          <Icon
            fill={
              stage?.status === OnboardingInRegisterStageStatus.COMPLETED
                ? '$on-success-container'
                : '$on-surface-variant'
            }
          />
        </IconWrapper>
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$5xs' }}>
          <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
            {stage?.keyFormatted}
          </Typography>
          <Typography variant="bodySmall" css={{ color: '$on-surface' }}>
            {stage?.statusFormatted}
          </Typography>
          <Typography variant="bodySmall" css={{ color: '$on-surface' }}>
            {stage?.updatedAtFormatted}
          </Typography>
        </Div>
      </BaseCard>
    </Grid>
  )
}
