export const contractPt = {
  typeSchool: {
    PRIVADO: 'Privado',
    PUBLICO: 'Público'
  },
  frequencyMonths: {
    MENSAL: 'Mensal',
    BIMESTRAL: 'Bimestral',
    TRIMESTRAL: 'Trimestral',
    SEMESTRAL: 'Semestral',
    ANUAL: 'Anual'
  },
  resources: {
    list: 'Listar contratos'
  },
  pages: {
    list: {
      pageTitle: 'Contratos',
      appBar: {
        title: 'Contratos'
      },
      filter: {
        searchLabel: 'Buscar por contrato...',
        supportingText:
          'A busca poderá ser feita pelo nome da instituição ou rede, CNPJ, códigos SAP de contrato ou de cliente.',
        title: 'Opções de filtragem',
        select: 'Selecionar setor',
        searching: 'Buscando por resultados...',
        searchingResult: 'Resultado da busca...'
      },
      header: {
        orgName: 'Instituições',
        contractNumber: 'Nº Contrato',
        cnpj: 'CNPJ',
        end_date: 'Finalize em:'
      },
      body: {
        showContract: 'Visualizar contrato'
      },
      emptyList: 'Ops! Ainda não temos contratos para exibir'
    },
    details: {
      pageTitle: 'Detalhes do contrato',
      appBar: {
        title: 'Contratos',
        breadcrumbs: {
          overview: 'Visão geral',
          contract: (contract: string): string => `Contrato ${contract}`
        }
      },
      salesOrder: 'Ordens de venda',
      contract: 'Contrato',
      endContract: 'Término do contrato:',
      information: 'Informações',
      overview: 'Visão geral',
      numberContract: 'Número do contrato:',
      sapClient: 'SAP do Cliente:',
      cnpj: 'CNPJ:',
      location: 'Localidade:',
      typeInvoice: 'Fatura:',
      typeSchoolLabel: 'Tipo de Escola:',
      totalContract: 'Total do contrato',
      invoicedAmount: 'Valor faturado',
      amountToBeInvoiced: 'Valor a faturar',
      tooltipTotalContract:
        'Total do contrato é o valor original acordado inicialmente no início do contrato.',
      tooltipInvoicedAmount: 'Valor faturado é o valor já pago pela instituição.',
      tooltipAmountToBeInvoiced: 'Valor a faturar é o valor previsto a ser pago pela instituição.'
    }
  }
}
