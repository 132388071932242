import { Autocomplete } from '@positivote/design-system/components/Autocomplete'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { CheckIcon } from '@positivote/design-system/icons/Check'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useEffect, useMemo, useState } from 'react'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListCity, useListState } from '@/modules/hub/locales/hooks'
import { useListChildrenOrganization } from '@/modules/hub/organizations/hooks'
import { SchoolYearStepperState } from '@/modules/hub/school-year/contracts'

interface SchoolYearListSchoolsStepProps {
  setCurrentStepPosition: (position: number) => void
  stepState: SchoolYearStepperState['selectedSchool']
  setStepState: (stepperState: SchoolYearStepperState['selectedSchool']) => void
}

export function SchoolYearListSchoolStep({
  setStepState,
  stepState,
  setCurrentStepPosition
}: SchoolYearListSchoolsStepProps): JSX.Element {
  const { session } = useAuth()

  const [showFilter, setShowFilter] = useState(false)

  const [filters, setFilters] = useState<{
    codeState?: string
    cityId?: string
    search?: string
  }>({
    codeState: undefined,
    cityId: undefined,
    search: undefined
  })
  const listStates = useListState({})

  const listCities = useListCity({
    model: {
      state: filters.codeState as unknown as string
    },
    queryOptions: {
      enabled: !!filters.codeState && filters.codeState !== 'all'
    }
  })
  const listSelectStates = useMemo(
    () => [
      {
        stateCode: 'all',
        name: i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.filter.allStates
      },
      ...(listStates.data?.registers ?? [])
    ],
    [listStates]
  )

  const listSelectCities = useMemo(
    () => [
      {
        id: 'all',
        name: i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.filter.allcitys
      },
      ...(listCities.data?.registers ?? [])
    ],
    [listCities]
  )

  const listSchools = useListChildrenOrganization({
    model: {
      organizationId: session?.orgId as unknown as number,
      cityId: filters.cityId === 'all' ? undefined : filters.cityId,
      state: filters.codeState === 'all' ? undefined : filters.codeState,
      search: filters.search
    },
    queryOptions: {
      enabled: !!session?.orgId
    }
  })

  function handleSelectSchool(idSchool: string, schoolName: string): void {
    setStepState({
      ...stepState,
      form: {
        idSchool,
        schoolName
      }
    })

    setCurrentStepPosition(2)
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setFilters((oldFilters) => ({ ...oldFilters, search: searchBy! }))
    })()
  }

  useEffect(() => {
    if (listSchools.data?.length && !showFilter && listSelectStates.length > 1) {
      setShowFilter(true)
    }
  }, [listSchools.data?.length, listSelectStates.length, showFilter])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden',
        alignItems: 'center',
        gap: '$lg'
      }}
    >
      <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
        {i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.title}
      </Typography>

      {showFilter && (
        <Grid css={{ display: 'flex', gap: '$lg' }}>
          <Grid xl={3}>
            <Autocomplete
              data-testid={'Status'}
              isLoading={listStates.isFetching}
              variant="outlined"
              label={i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.filter.state}
              optionKeyField="stateCode"
              optionTitleField="name"
              options={listSelectStates}
              onChange={(value) => {
                setFilters((oldFilters) => ({
                  ...oldFilters,
                  codeState: value?.stateCode,
                  cityId: undefined
                }))
              }}
            />
          </Grid>

          <Grid xl={3}>
            <Autocomplete
              disabled={!filters.codeState || filters.codeState === 'all'}
              isLoading={listCities.isLoading}
              variant="outlined"
              label={i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.filter.city}
              optionKeyField="id"
              optionTitleField="name"
              options={listSelectCities}
              value={filters.cityId}
              onChange={(value) =>
                setFilters((oldFilters) => ({
                  ...oldFilters,
                  cityId: value?.id === undefined ? undefined : value.id
                }))
              }
            />
          </Grid>

          <Grid xl={6}>
            <TextField
              leadingIcon={{ icon: SearchIcon }}
              variant="outlined"
              label={i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.filter.search}
              inputProps={{
                onChange: handleChangeSearchText
              }}
            />
          </Grid>
        </Grid>
      )}

      {!listSchools.data?.length || listSelectStates.length === 1 ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {(listSchools.isFetching || listSelectStates.length === 1) && <Loader size={80} />}
          {!listSchools.isFetching &&
            listSelectStates.length > 1 &&
            !filters.codeState &&
            !filters.search &&
            !filters.cityId && (
              <EmptyList
                title={i18n().modules.hub.schoolYear.pages.list.emptyList.title}
                subTitle={i18n().modules.hub.schoolYear.pages.list.emptyList.subTitle}
              />
            )}
          {!listSchools.isFetching &&
            listSelectStates.length > 1 &&
            (!!filters.search || !!filters.codeState || !!filters.cityId) && <EmptySearch />}
        </Div>
      ) : (
        <>
          <Grid spacing="$md" css={{ padding: '$none $lg' }}>
            <Grid xl={3}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.schoolName}
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.city}
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.uf}
              </Typography>
            </Grid>
          </Grid>

          <Grid css={{ display: 'flex', flexDirection: 'column', gap: '$sm' }}>
            {listSchools.data.map((school) => (
              <BaseCard
                key={school.id}
                css={{
                  borderRadius: '$lg',
                  backgroundColor: '$surface-1',
                  '& .BaseCard-StateLayer': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '$lg',
                    maxHeight: '64px',
                    height: 'min-content',
                    padding: '$md $lg'
                  }
                }}
              >
                <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                  <Grid xl={3}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {school.name}
                    </Typography>
                  </Grid>
                  <Grid xl={2}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {school.city}
                    </Typography>
                  </Grid>
                  <Grid xl={2}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {school.stateCode}
                    </Typography>
                  </Grid>

                  <Grid xl={5} css={{ display: 'flex', justifyContent: 'end', gap: '$2xs' }}>
                    <LinkButton
                      LeadingIcon={<CheckIcon size={18} />}
                      onClick={() => handleSelectSchool(String(school.id), school.name)}
                    >
                      {i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.select}
                    </LinkButton>
                  </Grid>
                </Grid>
              </BaseCard>
            ))}
          </Grid>
        </>
      )}
    </Div>
  )
}
