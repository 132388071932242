import { Autocomplete } from '@positivote/design-system/components/Autocomplete'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Switch } from '@positivote/design-system/components/Switch'
import { TextField } from '@positivote/design-system/components/TextField'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { StairsIcon } from '@positivote/design-system/icons/Stairs'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { useListChildrenOrganization } from '@/modules/hub/organizations/hooks'
import { ButtonCreateSchoolYear } from '@/modules/hub/school-year/components/Button'
import { ListSchoolYearHookParams } from '@/modules/hub/school-year/contracts'
import {
  useActivateSchoolYear,
  useListSchoolYear,
  useRemoveSchoolYear
} from '@/modules/hub/school-year/hooks'

export function ListSchoolYear(): JSX.Element {
  changePageTitle(i18n().modules.hub.schoolYear.pages.list.pageTitle)
  const [schoolYearToRemove, setSchoolYearToRemove] = useState<{
    id: number
    title: string
  } | null>(null)
  const [showFilter, setShowFilter] = useState(false)
  const { breakpoint } = useTheme()
  const [schoolYearParams, setSchoolYearParams] = useState<ListSchoolYearHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })
  const navigate = useNavigate()
  const location = useLocation()
  const listSchoolYears = useListSchoolYear({
    model: {
      ...schoolYearParams,
      institutionId:
        schoolYearParams.institutionId === 'all' ? undefined : schoolYearParams.institutionId
    }
  })
  const activeSchoolYear = useActivateSchoolYear()
  const removeSchoolYear = useRemoveSchoolYear()
  const { session, profile } = useAuth()
  const listSchools = useListChildrenOrganization({
    model: {
      organizationId: session?.orgId as unknown as number
    },
    queryOptions: {
      enabled: !!session?.orgId
    }
  })

  const listSelectSchools = [
    { id: 'all', name: i18n().modules.hub.schoolYear.pages.list.allSchools },
    ...(listSchools.data ?? [])
  ]

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  function handleActivateSchoolYear(id: number, status: string, institutionId: string): void {
    activeSchoolYear.mutate({
      model: {
        idSchoolYear: id,
        status: status === 'active' ? 'inactive' : 'active',
        institutionId,
        ...schoolYearParams
      }
    })
  }

  function handleRemoveSchoolYear(): void {
    removeSchoolYear.mutate({
      model: {
        idSchoolYear: schoolYearToRemove?.id as unknown as number,
        ...schoolYearParams
      },
      onSuccess: () => {
        setSchoolYearToRemove(null)
        navigate(location.pathname, { replace: true, state: null })
      }
    })
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setSchoolYearParams((oldFilters) => ({ ...oldFilters, search: searchBy! }))
    })()
  }

  function handleSetListSalesOrderParams(params: Partial<ListSchoolYearHookParams['model']>): void {
    setSchoolYearParams((oldState) => ({ ...oldState, ...params }))
  }

  useEffect(() => {
    if (listSchoolYears.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listSchoolYears.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        data-testid={`Remove-Paragraph`}
        isOpen={!!schoolYearToRemove?.id}
        title={{ label: schoolYearToRemove?.title ?? '' }}
        contentTexts={[
          i18n().modules.hub.schoolYear.pages.list.textDialog.contentText(
            schoolYearToRemove?.title ?? ''
          )
        ]}
        onCancel={() => setSchoolYearToRemove(null)}
        refuseAction={{
          icon: <CancelIcon size={18} data-testid={`Return-School-Year`} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setSchoolYearToRemove(null)
        }}
        acceptAction={{
          icon: <DeleteIcon size={18} data-testid={`Delete-School-Year`} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.accept,
          handle: handleRemoveSchoolYear
        }}
        isLoading={removeSchoolYear.isPending}
        css={{ maxWidth: 600 }}
      />
      <AppBar
        title={i18n().modules.hub.schoolYear.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={!!listSchoolYears.data?.registers.length && <ButtonCreateSchoolYear />}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.educationLevelsAndClasses,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.schoolYear
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && !isSchool && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.schoolYear.pages.list.filter.title}
              </Typography>
            </Div>
            <Div
              css={{
                display: 'flex',
                gap: '$md'
              }}
            >
              <Autocomplete
                variant="outlined"
                label={i18n().modules.hub.schoolYear.pages.list.filter.school}
                optionKeyField="id"
                optionTitleField="name"
                options={listSelectSchools}
                css={{ width: 350 }}
                onChange={(values) =>
                  setSchoolYearParams((oldFilters) => ({
                    ...oldFilters,
                    institutionId: String(values?.id)
                  }))
                }
              />
              <TextField
                leadingIcon={{ icon: SearchIcon }}
                label={i18n().modules.hub.schoolYear.pages.list.filter.search}
                variant="outlined"
                css={{ width: 550 }}
                inputProps={{
                  onChange: handleChangeSearchText
                }}
              />
            </Div>
          </>
        )}

        {!listSchoolYears.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {listSchoolYears.isLoading && (
              <Loader size={80} data-testid={`Loader-Container-SchoolYearList`} />
            )}
            {!listSchoolYears.isLoading &&
              !schoolYearParams.institutionId &&
              !schoolYearParams.search && (
                <>
                  <EmptyList
                    title={i18n().modules.hub.schoolYear.pages.list.emptyList.title}
                    subTitle={i18n().modules.hub.schoolYear.pages.list.emptyList.subTitle}
                  />
                  <ButtonCreateSchoolYear />
                </>
              )}
            {!listSchoolYears.isFetching &&
              (schoolYearParams.search || schoolYearParams.institutionId) && <EmptySearch />}
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$sm',
              flex: 1
            }}
          >
            <Grid spacing="$md" css={{ padding: '$md $lg' }}>
              <Grid xl={isSchool ? 3 : 2} lg={2} data-testid={`Year`}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.schoolYear.pages.list.schoolYear}
                </Typography>
              </Grid>
              {!isSchool && (
                <Grid xl={2.5}>
                  <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.schoolYear.pages.list.school}
                  </Typography>
                </Grid>
              )}

              <Grid xl={isSchool ? 2 : 1.5} lg={isSchool ? 2 : 1.5} data-testid={`Start`}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.schoolYear.pages.list.start}
                </Typography>
              </Grid>
              <Grid xl={isSchool ? 2 : 1.5} lg={isSchool ? 2 : 1.5} data-testid={`Finish`}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.schoolYear.pages.list.finish}
                </Typography>
              </Grid>
              <Grid xl={2} lg={isSchool ? 2 : 1.5} data-testid={`Status`}>
                <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.schoolYear.pages.list.status}
                </Typography>
              </Grid>
            </Grid>
            {listSchoolYears.data.registers.map((schoolYear, index) => (
              <BaseCard
                key={schoolYear.id}
                css={{
                  borderRadius: '$lg',
                  backgroundColor: '$surface-1',
                  '& .BaseCard-StateLayer': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '$lg',
                    maxHeight: '64px',
                    height: 'min-content',
                    padding: '$md $lg'
                  }
                }}
              >
                <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                  <Grid xl={isSchool ? 3 : 2} lg={2}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {schoolYear.title}
                    </Typography>
                  </Grid>
                  {!isSchool && (
                    <Grid xl={2.5}>
                      <Tooltip label={schoolYear.schoolName}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                        >
                          {schoolYear.schoolName}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  )}

                  <Grid xl={isSchool ? 2 : 1.5} lg={isSchool ? 2 : 1.5}>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {schoolYear.startDate}
                    </Typography>
                  </Grid>
                  <Grid xl={isSchool ? 2 : 1.5} lg={isSchool ? 2 : 1.5}>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {schoolYear.endDate}
                    </Typography>
                  </Grid>
                  <Grid
                    xl={isSchool ? 2 : 1.5}
                    lg={isSchool ? 3 : 1.5}
                    css={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                  >
                    <Switch
                      data-testid={`Switch-${index}`}
                      isLoading={
                        activeSchoolYear.isPending &&
                        schoolYear.id === activeSchoolYear.variables.model.idSchoolYear
                      }
                      inputProps={{
                        checked: schoolYear.status === 'active',
                        onClick: () =>
                          handleActivateSchoolYear(
                            schoolYear.id,
                            schoolYear.status,
                            schoolYear.institutionId
                          )
                      }}
                    />
                    <Typography
                      data-testid={`Status-${index}`}
                      variant="bodyMedium"
                      lineClamp={1}
                      css={{ color: '$on-surface' }}
                    >
                      {schoolYear.statusFormatted}
                    </Typography>
                  </Grid>
                  <Grid
                    xl={isSchool ? 3 : 3}
                    lg={3}
                    css={{ display: 'flex', justifyContent: 'end', gap: '$2xs' }}
                  >
                    {schoolYear.status !== 'active' && schoolYear.totalClassrooms > 0 && (
                      <Tooltip label={i18n().modules.hub.schoolYear.pages.list.classProgression}>
                        <LinkButton
                          onClick={() =>
                            navigate(
                              `/data-management/levels-and-classes/school-year/${schoolYear.id}/promote`,
                              {
                                state: schoolYear
                              }
                            )
                          }
                          LeadingIcon={<StairsIcon size={18} />}
                        >
                          {i18n().modules.hub.schoolYear.pages.list.classProgression}
                        </LinkButton>
                      </Tooltip>
                    )}
                    <IconButton
                      data-testid={`edit-${index}`}
                      variant="standard"
                      onClick={() =>
                        navigate(
                          `/data-management/levels-and-classes/school-year/form/${schoolYear.id}`,
                          { state: { page: schoolYearParams.page } }
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      data-testid={`delete-${index}`}
                      disabled={schoolYear.status === 'active'}
                      variant="standard"
                      onClick={() =>
                        setSchoolYearToRemove({
                          title: schoolYear.title,
                          id: schoolYear.id
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </BaseCard>
            ))}
            {listSchoolYears.data.lastPage > 1 && (
              <Pagination
                lastPage={listSchoolYears.data.lastPage}
                page={schoolYearParams.page ?? 1}
                setPage={(page) => handleSetListSalesOrderParams({ page })}
              />
            )}
          </Div>
        )}
      </Div>
    </Main>
  )
}
