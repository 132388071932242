import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CalendarTodayIcon } from '@positivote/design-system/icons/CalendarToday'
import { HistoryEduIcon } from '@positivote/design-system/icons/HistoryEdu'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SchoolIcon } from '@positivote/design-system/icons/School'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { NotFoundException } from '@/common/exceptions'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { AccessConfigurationList } from '@/modules/hub/data-management/components/AccessConfigurationList'
import { useListLicenseByOrganization } from '@/modules/hub/licenses/hooks'
import { StageCard } from '@/modules/hub/onboarding/components/StageCard'
import { OnboardingOrgType } from '@/modules/hub/onboarding/contracts'
import { useShowOnboardingInRegisterDetails } from '@/modules/hub/onboarding/hooks'

export function OnboardingDetails(): JSX.Element {
  changePageTitle(i18n().modules.hub.onboarding.pages.list.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()
  const params = useParams()
  const showOnboardingInRegisterDetails = useShowOnboardingInRegisterDetails({
    model: { id: params.id ?? '' },
    onError: ({ error }) => {
      if (error instanceof NotFoundException) {
        navigate('/not-found', { replace: true })
      }
    },
    queryOptions: { enabled: !!params.id }
  })
  const listLicenseByOrganization = useListLicenseByOrganization({
    model: {
      perPage:
        breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE,
      page: 1,
      orgId: showOnboardingInRegisterDetails.data?.id as unknown as number
    },
    queryOptions: {
      enabled: !!showOnboardingInRegisterDetails.data?.id
    }
  })

  const breadcrumbItems = [
    {
      label: i18n().modules.hub.onboarding.pages.list.appBar.breadcrumbs.overview
    },
    {
      label: i18n().modules.hub.onboarding.pages.list.appBar.breadcrumbs.registrationTracking
    },
    {
      label: showOnboardingInRegisterDetails.data?.name ?? '...'
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <>
          <AppBar
            title={i18n().modules.hub.onboarding.pages.list.appBar.title}
            goBackFunction={() => navigate(-1)}
            breadcrumbItems={breadcrumbItems.map((breadcrumbItem, index) => ({
              ...breadcrumbItem,
              onClick:
                breadcrumbItems.length === index + 1
                  ? undefined
                  : () => navigate(-(breadcrumbItems.length - index - 1))
            }))}
          />

          {showOnboardingInRegisterDetails.isFetching ? (
            <WrappedLoader />
          ) : (
            <Div
              css={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '$lg',
                gap: '$lg',
                overflowY: 'auto',
                '@sm': { padding: '$md' }
              }}
            >
              <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.details.userActivities}
              </Typography>
              <Grid spacing="$lg">
                <Grid xl={4}>
                  <BaseCard css={{ borderRadius: '$md' }}>
                    <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.onboarding.pages.details.recentUpdate}
                    </Typography>
                    <Typography
                      variant="labelMedium"
                      css={{ color: '$on-surface', marginTop: '$2xs' }}
                    >
                      {i18n().modules.hub.onboarding.pages.details.stage}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {showOnboardingInRegisterDetails.data?.mostRecentStage?.keyFormatted}
                    </Typography>
                    <Typography
                      variant="labelMedium"
                      css={{ color: '$on-surface', marginTop: '$md' }}
                    >
                      {i18n().modules.hub.onboarding.pages.details.status}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {showOnboardingInRegisterDetails.data?.mostRecentStage?.statusFormatted}
                    </Typography>
                    <Typography
                      variant="labelMedium"
                      css={{ color: '$on-surface', marginTop: '$md' }}
                    >
                      {i18n().modules.hub.onboarding.pages.details.lastUpdate}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {showOnboardingInRegisterDetails.data?.mostRecentStage?.updatedAtFormatted}
                    </Typography>
                  </BaseCard>
                </Grid>
                <Grid xl={8} spacing="$lg">
                  <StageCard
                    stage={showOnboardingInRegisterDetails.data?.schoolCreatedStage}
                    Icon={HistoryEduIcon}
                  />
                  <StageCard
                    stage={showOnboardingInRegisterDetails.data?.userMasterCreatedStage}
                    Icon={PersonIcon}
                  />
                  <StageCard
                    stage={showOnboardingInRegisterDetails.data?.periodActivatedStage}
                    Icon={CalendarTodayIcon}
                  />
                  {showOnboardingInRegisterDetails.data?.type !==
                    OnboardingOrgType.TEACHING_NETWORK && (
                    <StageCard
                      stage={showOnboardingInRegisterDetails.data?.schoolEnrollmentStage}
                      Icon={SchoolIcon}
                    />
                  )}
                </Grid>
              </Grid>

              <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.details.hubRegisteredUsers}
              </Typography>
              <Grid spacing="$lg" css={{ justifyContent: 'center' }}>
                <Grid xl={6}>
                  <BaseCard css={{ borderRadius: '$md', height: '200px' }}>
                    <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.onboarding.pages.details.professionalProfiles}
                    </Typography>
                    <Typography
                      variant="displayMedium"
                      css={{ color: '$on-surface', margin: 'auto' }}
                    >
                      {showOnboardingInRegisterDetails.data?.professionalProfilesQuantity}
                    </Typography>
                  </BaseCard>
                </Grid>
                <Grid xl={6}>
                  <BaseCard css={{ borderRadius: '$md', height: '200px' }}>
                    <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.onboarding.pages.details.studentsQuantity}
                    </Typography>
                    <Typography
                      variant="displayMedium"
                      css={{ color: '$on-surface', margin: 'auto' }}
                    >
                      {showOnboardingInRegisterDetails.data?.studentsQuantity}
                    </Typography>
                  </BaseCard>
                </Grid>
              </Grid>

              {listLicenseByOrganization.isFetching ? (
                <WrappedLoader css={{ minHeight: '80px' }} />
              ) : !listLicenseByOrganization.data?.registers.length ? (
                <></>
              ) : (
                <>
                  <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.onboarding.pages.details.applicationLicenses}
                  </Typography>
                  <AccessConfigurationList
                    orgId={showOnboardingInRegisterDetails.data?.id}
                    breadcrumbItems={breadcrumbItems}
                  />
                </>
              )}
            </Div>
          )}
        </>
      )}
    </Main>
  )
}
