export const termsPt = {
  resources: {
    accept: 'Aceitar termos de consentimento'
  },
  page: {
    agreement: {
      title:
        'Os Termos e Condições Gerais de Tratamento e Proteção de Dados Pessoais foram atualizados',
      strongTitle: 'e necessitamos de seu aceite para esta instituição.',
      warning: 'Esta mensagem será exibida até que um gestor da instituição aceite aos termos.',
      buttons: {
        doItLater: 'Fazer isso depois',
        accept: 'Aceitar termos',
        goBack: 'Voltar ao início'
      }
    }
  }
}
