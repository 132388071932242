import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { LI } from '@positivote/design-system/components/LI'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Select } from '@positivote/design-system/components/Select'
import { TextField } from '@positivote/design-system/components/TextField'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { DescriptionIcon } from '@positivote/design-system/icons/Description'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { CONTRACT_ORG_TYPE_OPTIONS } from '@/modules/billing/contracts/constants'
import { ContractOrgType, ListContractHookParams } from '@/modules/billing/contracts/contracts'
import { useListContract } from '@/modules/billing/contracts/hooks'

export function ContractList(): JSX.Element {
  changePageTitle(i18n().modules.billing.contracts.pages.list.pageTitle)

  const { breakpoint } = useTheme()
  const [listContractParams, setListContractParams] = useState<ListContractHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })
  const hasAnyFilter = !!listContractParams.searchBy || !!listContractParams.orgType
  const [showFilter, setShowFilter] = useState(false)

  const navigate = useNavigate()
  const listContract = useListContract({ model: listContractParams })

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setListContractParams((oldState) => ({ ...oldState, searchBy, page: 1 }))
    })()
  }

  function handleChangeOrgType(orgType: { key: ContractOrgType; value: string } | null): void {
    setListContractParams((oldState) => ({
      ...oldState,
      orgType: orgType?.key ?? undefined,
      page: 1
    }))
  }

  useEffect(() => {
    if (listContract.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listContract.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.billing.contracts.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <TextField
              data-testid="searchBy"
              label={i18n().modules.billing.contracts.pages.list.filter.searchLabel}
              variant="outlined"
              supportingText={i18n().modules.billing.contracts.pages.list.filter.supportingText}
              inputProps={{
                onChange: handleChangeSearchText
              }}
              css={{ marginBottom: '$md' }}
              leadingIcon={{ icon: SearchIcon }}
            />
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.billing.contracts.pages.list.filter.title}
              </Typography>
            </Div>
            <Grid xl={3} md={5} sm={8} xs={12} css={{ marginBottom: '$lg' }}>
              <Select
                label={i18n().modules.billing.contracts.pages.list.filter.select}
                hasNoneOption
                data-testid="orgType"
                optionKeyField="key"
                optionTitleField="value"
                options={CONTRACT_ORG_TYPE_OPTIONS}
                onChange={handleChangeOrgType}
                variant="outlined"
              />
            </Grid>
            {listContract.isFetching && hasAnyFilter && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searching"
              >
                {i18n().modules.billing.contracts.pages.list.filter.searching}
              </Typography>
            )}
            {!listContract.isFetching && !!listContract.data?.registers.length && hasAnyFilter && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searchingResult"
              >
                {i18n().modules.billing.contracts.pages.list.filter.searchingResult}
              </Typography>
            )}
          </>
        )}

        {!listContract.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {listContract.isFetching && (
              <Loader data-testid="Loader-Container-ContractList" size={80} />
            )}
            {!listContract.isFetching && !hasAnyFilter && (
              <EmptyList title={i18n().modules.billing.contracts.pages.list.emptyList} />
            )}
            {!listContract.isFetching && hasAnyFilter && <EmptySearch />}
          </Div>
        ) : (
          <>
            <Grid
              spacing="$md"
              css={{
                padding: '$md $lg',
                // DOCS: Sum of buttons with padding and gap
                // gap + showButton + paddingRight
                paddingRight: 'calc($md + 144px + $lg)',
                marginBottom: '$2xs'
              }}
            >
              <Grid xl={5}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerOrgName"
                >
                  {i18n().modules.billing.contracts.pages.list.header.orgName}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerContractNumber"
                >
                  {i18n().modules.billing.contracts.pages.list.header.contractNumber}
                </Typography>
              </Grid>
              <Grid xl={3}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerCnpj"
                >
                  {i18n().modules.billing.contracts.pages.list.header.cnpj}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerEndDate"
                >
                  {i18n().modules.billing.contracts.pages.list.header.end_date}
                </Typography>
              </Grid>
            </Grid>
            <UL
              css={{
                ...(listContract.isFetching && {
                  ...(listContract.data.lastPage <= 1 && { flex: 1 }),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listContract.isFetching && (
                <Loader data-testid="Loader-Container-ContractList" size={80} />
              )}
              {listContract.data.registers.map((register, index) => (
                <BaseCard
                  key={register.id}
                  data-testid={`BaseCard-Container-contractListItem-${index}`}
                  component={LI}
                  css={{
                    opacity: listContract.isFetching ? '$transparent' : '$default',
                    marginBottom: '$2xs',
                    '&:last-child': { marginBottom: '$none' },
                    '& .BaseCard-StateLayer': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '$md',
                      height: 'min-content',
                      padding: '$md $lg'
                    }
                  }}
                >
                  <Grid spacing="$md" css={{ flex: 1 }}>
                    <Grid xl={5}>
                      <Tooltip placement="bottom-start" label={register.institution.name}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemOrgName-${index}`}
                        >
                          {register.institution.name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2}>
                      <Tooltip placement="bottom-start" label={register.id}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemContractNumber-${index}`}
                        >
                          {register.id}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={3}>
                      <Tooltip placement="bottom-start" label={register.institution.cinNumber}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemCinNumber-${index}`}
                        >
                          {register.institution.cinNumber}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-listItemEndDate-${index}`}
                      >
                        {register.endDate}
                      </Typography>
                    </Grid>
                  </Grid>
                  <LinkButton
                    LeadingIcon={<DescriptionIcon size={18} />}
                    typographyVariant="labelMedium"
                    onClick={() => navigate(`/contracts/${register.id}`)}
                    data-testid={`showContract-${index}`}
                  >
                    {i18n().modules.billing.contracts.pages.list.body.showContract}
                  </LinkButton>
                </BaseCard>
              ))}
            </UL>
            {listContract.data.lastPage > 1 && (
              <Pagination
                lastPage={listContract.data.lastPage}
                page={listContractParams.page ?? 1}
                setPage={(page) => setListContractParams((oldState) => ({ ...oldState, page }))}
                css={{ marginTop: '$lg' }}
              />
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
