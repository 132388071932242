import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Loader } from '@positivote/design-system/components/Loader'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  currentDatePtFormatted,
  formatPtBrDateToIsoString,
  getClientTimezone,
  getOldDatePtFormatted
} from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListChildrenOrganization } from '@/modules/hub/organizations/hooks'
import { GeneralInformationForm } from '@/modules/monitoora/contracts'
import { useShowMetabaseGeneralInformation } from '@/modules/monitoora/hooks'
import { generalInformationFormSanitizer } from '@/modules/monitoora/sanitizers'
import { generalInformationSchema } from '@/modules/monitoora/validations'

export function GeneralInformation(): JSX.Element {
  const { profile, session } = useAuth()
  const daysBefore = 7
  const initialDate = getOldDatePtFormatted(daysBefore)
  const finalDate = currentDatePtFormatted()
  const [filter, setFilter] = useState<GeneralInformationForm>({
    organization: session?.schoolId ?? '',
    initialDate,
    finalDate
  })

  const { data: listSchools } = useListChildrenOrganization({
    model: {
      organizationId: profile?.organizationId as unknown as number
    },
    queryOptions: {
      enabled: !!profile
    }
  })

  const { data: embeddedGeneralInformation, isLoading: isLoadingEmbeddedGeneralInformation } =
    useShowMetabaseGeneralInformation({
      model: {
        payload: {
          resource: {
            dashboard: Number(import.meta.env.VITE_METABASE_GENERAL_INFORMATION_DASH_ID)
          },
          params: {
            dateFrom: formatPtBrDateToIsoString(filter.initialDate),
            dateTo: formatPtBrDateToIsoString(filter.finalDate),
            orgId: [filter.organization],
            difTime: getClientTimezone()
          }
        }
      },
      queryOptions: {
        enabled: !!session
      }
    })

  const optionsOrganization = useMemo(() => {
    const filterOrganizations = [
      {
        key: 'all',
        value: i18n().modules.monitoora.pages.dashboard.generalInformation.allUnits
      },
      ...(listSchools?.map((school) => ({
        key: school.code,
        value: school.address ? `${school.name} - ${school.address}` : `${school.name}`
      })) ?? [])
    ]

    return filterOrganizations
  }, [listSchools])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<GeneralInformationForm>({
    mode: 'onSubmit',
    defaultValues: { organization: 'all', initialDate, finalDate },
    resolver: async (values, ...args) =>
      yupResolver(generalInformationSchema)(generalInformationFormSanitizer(values), ...args)
  })

  function handleFilter(data: GeneralInformationForm): void {
    setFilter({
      initialDate: data.initialDate,
      finalDate: data.finalDate,
      organization:
        data.organization === 'all' && session?.schoolId ? session.schoolId : data.organization
    })
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: '$lg',
        padding: '$lg',
        '@sm': { padding: '$md' }
      }}
    >
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={handleFilter}>
        <Grid spacing="$md" css={{ display: 'flex' }}>
          <FormSelect
            variant="outlined"
            name="organization"
            control={control}
            label={i18n().modules.monitoora.pages.dashboard.generalInformation.organizationalUnits}
            optionKeyField="key"
            optionTitleField="value"
            options={optionsOrganization}
            errorText={errors.organization?.message}
            gridProps={{ xl: 5 }}
          />
          <FormDatePicker
            name="initialDate"
            control={control}
            label={i18n().modules.monitoora.pages.dashboard.generalInformation.initialDate}
            variant="outlined"
            errorText={errors.initialDate?.message}
            gridProps={{ xl: 3 }}
          />
          <FormDatePicker
            name="finalDate"
            control={control}
            label={i18n().modules.monitoora.pages.dashboard.generalInformation.finalDate}
            variant="outlined"
            errorText={errors.finalDate?.message}
            gridProps={{ xl: 3 }}
          />
          <Grid xl={1} css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="submit" variant="filled" isLoading={isLoadingEmbeddedGeneralInformation}>
              {i18n().modules.monitoora.pages.dashboard.generalInformation.buttons.filter}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>

      <Divider />
      <Grid
        xl={12}
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'scroll'
        }}
      >
        {isLoadingEmbeddedGeneralInformation ? (
          <Loader size={80} />
        ) : (
          <Iframe
            scrolling="no"
            src={embeddedGeneralInformation?.url}
            css={{
              width: '100%',
              '@xl': {
                height: '2000px'
              },
              '@lg': {
                height: '1500px'
              }
            }}
          />
        )}
      </Grid>
    </Div>
  )
}
