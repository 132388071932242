import axios, { AxiosError, AxiosResponse } from 'axios'

import { ApplicationException } from '@/common/exceptions'
import { getParsedException } from '@/common/helpers'

interface ApiServiceParams<RequestData> {
  resource: string
  method: 'delete' | 'get' | 'patch' | 'post' | 'put'
  url: string
  baseURL?: string
  params?: RequestData
  body?: RequestData
  headers?: Record<string, unknown>
}

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_HUB_API_URL,
  timeout: 30000
})

export async function apiService<RequestData, ResponseData>({
  resource,
  method,
  url,
  baseURL,
  params,
  body,
  headers
}: ApiServiceParams<RequestData>): Promise<ResponseData> {
  return axiosClient
    .request<ApiServiceParams<RequestData>['body'], AxiosResponse<ResponseData>>({
      method,
      url,
      baseURL,
      params,
      data: body,
      headers: {
        ...axiosClient.defaults.headers,
        ...headers
      } as Record<string, string>
    })
    .then((res) => res.data)
    .catch((error: AxiosError<ApplicationException>) => {
      const parsedError = (error.response?.data ?? error) as ApplicationException
      parsedError.statusCode = error.response?.status ?? 500
      throw getParsedException(resource, parsedError)
    })
}
