import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Table } from '@positivote/design-system/components/Table'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { EmptySearch } from '@/common/components/EmptySearch'
import {
  currentDatePtFormatted,
  formatPtBrDateToIsoString,
  getOldDatePtFormatted
} from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListChildrenOrganization } from '@/modules/hub/organizations/hooks'
import {
  Device,
  DevicesFilterForm,
  DevicesSearchForm,
  ListDeviceServiceParams
} from '@/modules/monitoora/contracts'
import { useListDevice, useShowMetabaseDevice } from '@/modules/monitoora/hooks'
import {
  deviceFilterFormSanitizer,
  devicesSearchFormSanitizer
} from '@/modules/monitoora/sanitizers'
import { devicesFilterSchema, devicesSearchSchema } from '@/modules/monitoora/validations'

export function Devices(): JSX.Element {
  const { profile, session } = useAuth()
  const navigate = useNavigate()
  const daysBefore = 7
  const initialDate = getOldDatePtFormatted(daysBefore)
  const finalDate = currentDatePtFormatted()
  const [deviceParams, setDeviceParams] = useState<ListDeviceServiceParams>({
    endDate: formatPtBrDateToIsoString(finalDate),
    startDate: formatPtBrDateToIsoString(initialDate),
    orgId: session?.schoolId as unknown as string,
    page: 1,
    paginateBy: 10,
    status: undefined
  })
  const [embeddedDeviceParams, setEmbeddedDeviceParams] = useState<DevicesFilterForm>({
    finalDate: formatPtBrDateToIsoString(finalDate),
    initialDate: formatPtBrDateToIsoString(initialDate),
    organization: session?.schoolId as unknown as string,
    situation: 'all'
  })

  const {
    data: listDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices
  } = useListDevice({
    model: deviceParams,
    queryOptions: {
      enabled: !!session?.schoolId
    }
  })

  const { data: listSchools } = useListChildrenOrganization({
    model: {
      organizationId: profile?.organizationId as unknown as number
    },
    queryOptions: {
      enabled: !!profile
    }
  })

  const {
    data: embeddedDevice,
    isLoading: isLoadingEmbeddedDevice,
    isFetching: isFetchingEmbeddedDevice
  } = useShowMetabaseDevice({
    model: {
      payload: {
        resource: { dashboard: Number(import.meta.env.VITE_METABASE_DEVICE_DASH_ID) },
        params: {
          dateFrom: embeddedDeviceParams.initialDate,
          dateTo: embeddedDeviceParams.finalDate,
          orgId: [embeddedDeviceParams.organization],
          situacao: [
            embeddedDeviceParams.situation === 'all' ? null : embeddedDeviceParams.situation
          ]
        }
      }
    },
    queryOptions: {
      enabled: !!session?.schoolId
    }
  })

  const optionsOrganization = useMemo(() => {
    const filterOrganizations = [
      {
        key: 'all',
        value: i18n().modules.monitoora.pages.dashboard.generalInformation.allUnits
      },
      ...(listSchools?.map((school) => ({
        key: school.code,
        value: school.address ? `${school.name} - ${school.address}` : `${school.name}`
      })) ?? [])
    ]

    return filterOrganizations
  }, [listSchools])

  const situationOptions = [
    { key: 'all', value: i18n().modules.monitoora.pages.dashboard.devices.inputs.all },
    {
      key: 'ACTIVE',
      value: i18n().modules.monitoora.pages.dashboard.devices.inputs.provisioned
    },
    {
      key: 'INACTIVE',
      value: i18n().modules.monitoora.pages.dashboard.devices.inputs.deprovisioned
    }
  ]

  const isLoading = isLoadingDevices && isLoadingEmbeddedDevice

  const {
    control,
    handleSubmit: handleSubmitDevicesFilterForm,
    formState: { errors: errorsDevicesFilterForm }
  } = useForm<DevicesFilterForm>({
    mode: 'onSubmit',
    defaultValues: {
      initialDate,
      finalDate,
      organization: 'all',
      situation: 'all'
    },
    resolver: async (values, ...args) =>
      yupResolver(devicesFilterSchema)(deviceFilterFormSanitizer(values), ...args)
  })
  const hasAnyDevicesFilterError = !!Object.keys(errorsDevicesFilterForm).length

  const {
    control: deviceSearchControl,
    handleSubmit: handleSubmitDevicesSearchForm,
    formState: { errors: errorsDevicesSearchForm }
  } = useForm<DevicesSearchForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(devicesSearchSchema)(devicesSearchFormSanitizer(values), ...args)
  })
  const hasAnyDevicesSearchError = !!Object.keys(errorsDevicesSearchForm).length

  function handleFilterDevices(data: DevicesFilterForm): void {
    setDeviceParams((oldData) => ({
      ...oldData,
      ...data,
      endDate: formatPtBrDateToIsoString(data.finalDate),
      startDate: formatPtBrDateToIsoString(data.initialDate),
      orgId:
        data.organization === 'all' && session?.schoolId ? session.schoolId : data.organization,
      status: data.situation === 'all' ? undefined : data.situation
    }))
    setEmbeddedDeviceParams((oldData) => ({
      ...oldData,
      ...data,
      finalDate: formatPtBrDateToIsoString(data.finalDate),
      initialDate: formatPtBrDateToIsoString(data.initialDate),
      organization:
        data.organization === 'all' && session?.schoolId ? session.schoolId : data.organization,
      status: data.situation === 'all' ? undefined : data.situation
    }))
  }

  function handleSelectTable(devices: Device): void {
    navigate(`/devices-details/${devices.serialNumber}`)
  }

  function handleSearchDevice(data: DevicesSearchForm): void {
    navigate(`/devices-details/${data.searchDevice}`)
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: '$lg',
        padding: '$lg',
        '@sm': { padding: '$md' }
      }}
    >
      <FormContainer formHandleSubmit={handleSubmitDevicesSearchForm} onSubmit={handleSearchDevice}>
        <Grid spacing="$lg" xl={12} css={{ display: 'flex' }}>
          <FormTextField
            control={deviceSearchControl}
            name="searchDevice"
            leadingIcon={{ icon: SearchIcon }}
            label={i18n().modules.monitoora.pages.dashboard.devices.inputs.searchDevice}
            variant="outlined"
            errorText={errorsDevicesSearchForm.searchDevice?.message}
            supportingText={i18n().modules.monitoora.pages.dashboard.devices.inputs.supportingText}
            gridProps={{ xl: 10, lg: 9 }}
          />

          <Grid
            xl={2}
            lg={3}
            css={{ marginTop: '$2xs', display: 'flex', justifyContent: 'center' }}
          >
            <Div>
              <Button type="submit" variant="filled" disabled={hasAnyDevicesSearchError}>
                {i18n().modules.monitoora.pages.dashboard.devices.buttons.searchDevice}
              </Button>
            </Div>
          </Grid>

          <Grid>
            <Divider />
          </Grid>
        </Grid>
      </FormContainer>
      <FormContainer
        formHandleSubmit={handleSubmitDevicesFilterForm}
        onSubmit={handleFilterDevices}
      >
        <Grid spacing="$lg" xl={12} css={{ display: 'flex' }}>
          <FormSelect
            name="organization"
            variant="outlined"
            label={i18n().modules.monitoora.pages.dashboard.devices.inputs.organization}
            control={control}
            optionKeyField="key"
            optionTitleField="value"
            options={optionsOrganization}
            errorText={errorsDevicesFilterForm.organization?.message}
            gridProps={{ xl: 2.75, lg: 3 }}
          />
          <FormSelect
            name="situation"
            variant="outlined"
            label={i18n().modules.monitoora.pages.dashboard.devices.inputs.situation}
            control={control}
            optionKeyField="key"
            optionTitleField="value"
            options={situationOptions}
            errorText={errorsDevicesFilterForm.organization?.message}
            gridProps={{ xl: 2.75, lg: 3 }}
          />
          <FormDatePicker
            name="initialDate"
            control={control}
            value={initialDate}
            label={i18n().modules.monitoora.pages.dashboard.devices.inputs.initialDate}
            variant="outlined"
            errorText={errorsDevicesFilterForm.initialDate?.message}
            gridProps={{ xl: 2.75, lg: 2 }}
          />
          <FormDatePicker
            name="finalDate"
            control={control}
            value={finalDate}
            label={i18n().modules.monitoora.pages.dashboard.devices.inputs.finalDate}
            variant="outlined"
            errorText={errorsDevicesFilterForm.finalDate?.message}
            gridProps={{ xl: 2.75, lg: 2 }}
          />
          <Grid
            xl={1}
            lg={2}
            css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              type="submit"
              variant="filled"
              isLoading={isLoadingEmbeddedDevice || isFetchingEmbeddedDevice}
              disabled={hasAnyDevicesFilterError}
            >
              {i18n().modules.monitoora.pages.dashboard.generalInformation.buttons.filter}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
      <Divider />
      <Grid
        xl={12}
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'scroll',
          gap: '$lg'
        }}
      >
        {isLoading ? (
          <Loader size={80} />
        ) : !listDevices?.registers.length && !isLoadingEmbeddedDevice && !isFetchingDevices ? (
          <EmptySearch />
        ) : (
          <>
            {isLoadingEmbeddedDevice ? (
              <Loader size={80} />
            ) : (
              <Iframe
                scrolling="no"
                src={embeddedDevice?.url}
                css={{
                  width: '100%',
                  '@xl': {
                    height: '880px'
                  },
                  '@lg': {
                    height: '650px'
                  }
                }}
              />
            )}
            <Div
              css={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                minHeight: '80px',
                width: '100%'
              }}
            >
              {isLoadingDevices ? (
                <Loader size={80} />
              ) : (
                <Div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderWidth: '$thin',
                    borderStyle: 'solid',
                    borderColor: '$outline-variant',
                    borderRadius: '$xl',
                    padding: '$lg',
                    gap: '$lg',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  <Typography variant="titleLarge">
                    {i18n().modules.monitoora.pages.dashboard.devices.table.registeredDevices}
                  </Typography>
                  <Table
                    registers={listDevices?.registers ?? []}
                    CustomFooter={
                      <Pagination
                        lastPage={listDevices?.lastPage ?? 1}
                        page={deviceParams.page}
                        setPage={(page) => setDeviceParams((oldState) => ({ ...oldState, page }))}
                      />
                    }
                    registerKeyField="serialNumber"
                    onRegisterClick={handleSelectTable}
                    columns={[
                      {
                        keyField: 'model',
                        headerLabel: i18n().modules.monitoora.pages.dashboard.devices.table.model
                      },
                      {
                        keyField: 'serialNumber',
                        headerLabel:
                          i18n().modules.monitoora.pages.dashboard.devices.table.serialNumber
                      },
                      {
                        keyField: 'status',
                        headerLabel: i18n().modules.monitoora.pages.dashboard.devices.table.status
                      },
                      {
                        keyField: 'lastUpdate',
                        headerLabel:
                          i18n().modules.monitoora.pages.dashboard.devices.table.changedIn
                      },
                      {
                        keyField: 'totalUsers',
                        headerLabel: i18n().modules.monitoora.pages.dashboard.devices.table.users
                      },
                      {
                        keyField: 'schoolName',
                        headerLabel: i18n().modules.monitoora.pages.dashboard.devices.table.School
                      },
                      {
                        keyField: 'lastAccess',
                        headerLabel:
                          i18n().modules.monitoora.pages.dashboard.devices.table.lastAcess
                      }
                    ]}
                    css={{ maxHeight: 500 }}
                  />
                </Div>
              )}
            </Div>
          </>
        )}
      </Grid>
    </Div>
  )
}
