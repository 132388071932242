import { Navigate, Route, Routes } from 'react-router-dom'

import { ProfileList } from './pages/List'

export function ProfileRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ProfileList />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
