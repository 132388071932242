import { Navigate, Route, Routes } from 'react-router-dom'

import { Dashboard } from './pages/Dashboard'
import { DeviceDetails } from './pages/DeviceDetails'

export function MonitooraRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/devices-details/:serialNumber" element={<DeviceDetails />} />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
