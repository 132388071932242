import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { BreadcrumbsProps } from '@positivote/design-system/components/Breadcrumbs'
import { Div, DivProps } from '@positivote/design-system/components/Div'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { ProgressBar } from '@positivote/design-system/components/ProgressBar'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { i18n } from '@/common/i18n'
import { MascotNotValidated } from '@/modules/hub/data-management/icons/MascotNotValidated'
import { ListLicenseByOrganizationHookParams } from '@/modules/hub/licenses/contracts'
import { checkProgressBarColor } from '@/modules/hub/licenses/helpers'
import { useListLicenseByOrganization } from '@/modules/hub/licenses/hooks'

interface AccessConfigurationListProps extends DivProps {
  orgId?: number
  breadcrumbItems?: BreadcrumbsProps['items']
}

export function AccessConfigurationList({
  orgId,
  css,
  breadcrumbItems,
  ...restProps
}: AccessConfigurationListProps): JSX.Element {
  const { breakpoint } = useTheme()
  const [listLicenseByOrganizationParams, setListLicenseByOrganizationParams] = useState<
    ListLicenseByOrganizationHookParams['model']
  >({
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE,
    page: 1,
    orgId: orgId as unknown as number
  })

  const navigate = useNavigate()
  const listLicenseByOrganization = useListLicenseByOrganization({
    model: listLicenseByOrganizationParams,
    queryOptions: {
      enabled: !!orgId
    },
    onError: () => {
      navigate(-1)
    }
  })

  return !listLicenseByOrganization.isFetching &&
    !listLicenseByOrganization.data?.registers.length ? (
    <EmptyList
      CustomMascot={<MascotNotValidated />}
      title={i18n().modules.hub.dataManagement.pages.accessConfiguration.emptyResult.title}
      subTitle={i18n().modules.hub.dataManagement.pages.accessConfiguration.emptyResult.subTitle}
    />
  ) : (
    <Div
      css={{
        ...(listLicenseByOrganization.isFetching && {
          ...((listLicenseByOrganization.data?.lastPage ?? 1) <= 1 && { flex: 1 }),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        }),
        ...css
      }}
      {...restProps}
    >
      {listLicenseByOrganization.isFetching && <Loader size={80} />}
      {listLicenseByOrganization.data?.registers.map((license) => (
        <BaseCardButton
          key={license.id}
          onClick={() =>
            navigate(`/data-management/access-configuration/${license.id}`, {
              state: { breadcrumbItems }
            })
          }
          css={{
            opacity: listLicenseByOrganization.isFetching ? '$transparent' : '$default',
            marginBottom: '$md',
            width: '100%',
            '&:last-child': { marginBottom: '$none' },
            '& .BaseCardButton-StateLayer': { padding: '$md', gap: '$md' }
          }}
        >
          <Image
            alt={license.name}
            src={license.icon}
            css={{
              width: 56,
              height: 56,
              objectFit: 'contain',
              objectPosition: 'center',
              borderRadius: '$full'
            }}
          />
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginRight: 'auto'
            }}
          >
            <Typography variant="bodyLarge" css={{ textAlign: 'left', color: '$on-surface' }}>
              {license.name}
            </Typography>
            <Typography
              variant="bodyMedium"
              css={{ textAlign: 'left', color: '$on-surface-variant' }}
            >
              {license.description}
            </Typography>
          </Div>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '$2xs'
            }}
          >
            <Typography variant="bodySmall" css={{ color: '$on-surface-variant' }}>
              {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.activeAccess({
                totalAvailableLicenses: license.total,
                totalLicenses: license.used
              })}
            </Typography>

            <ProgressBar
              filledPercentage={license.percentUsed}
              css={{
                width: 200,
                '& .ProgressBar-Content': {
                  backgroundColor: checkProgressBarColor(license.percentUsed)
                }
              }}
            />
          </Div>
        </BaseCardButton>
      ))}
      {(listLicenseByOrganization.data?.lastPage ?? 1) > 1 && (
        <Pagination
          lastPage={listLicenseByOrganization.data?.lastPage ?? 1}
          page={listLicenseByOrganization.data?.page ?? 1}
          setPage={(page) =>
            setListLicenseByOrganizationParams((oldState) => ({ ...oldState, page }))
          }
        />
      )}
    </Div>
  )
}
