import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { LinkIcon } from '@positivote/design-system/icons/Link'
import { SchoolIcon } from '@positivote/design-system/icons/School'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ListTeachers } from '@/modules/hub/classroom/components/ListTeachers'
import {
  ClassroomFormatted,
  ListClassroomTeacherHookParams
} from '@/modules/hub/classroom/contracts'
import { useListClassroomTeacher } from '@/modules/hub/classroom/hooks'
import { ListDisciplineHookParams } from '@/modules/hub/disciplines/contracts'
import { useListDiscipline } from '@/modules/hub/disciplines/hooks'
import { useListTeacher } from '@/modules/hub/users/hooks'

export function ListTeacher(): JSX.Element {
  const navigate = useNavigate()

  const location = useLocation()

  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
  } | null

  const { breakpoint } = useTheme()
  const { profile } = useAuth()

  const [listClassroomTeachersParams, setListClassroomTeachersParams] = useState<
    ListClassroomTeacherHookParams['model']
  >({
    page: 1,
    perPage: 15,
    classId: locationState?.classroom.id as unknown as string
  })

  const listClassroomTeachers = useListClassroomTeacher({
    model: listClassroomTeachersParams,
    queryOptions: {
      enabled: !!locationState?.classroom.id
    }
  })

  const allTeachersToEnroll = useListTeacher({
    model: {
      ignoreClassroomId: locationState?.classroom.id
    },
    queryOptions: {
      enabled: !!locationState
    }
  })

  const [disciplineParams] = useState<ListDisciplineHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE,
    institutionId: profile!.organizationId
  })

  const listDisciplines = useListDiscipline({
    model: disciplineParams,
    queryOptions: {
      enabled: !!profile?.organizationId
    }
  })

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        gap: '$2xs',
        overflowY: 'auto',
        ...((listClassroomTeachers.isFetching || listDisciplines.isFetching) && { flex: 1 })
      }}
    >
      {!listClassroomTeachers.data?.registers.length ? (
        listClassroomTeachers.isFetching || listDisciplines.isFetching ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loader data-testid="Loader-Container-DisciplineList" size={80} />
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              borderColor: '$outline-variant'
            }}
          >
            <Div
              css={{ display: 'flex', flexDirection: 'column', gap: '$md', alignItems: 'center' }}
            >
              <Div css={{ display: 'flex', gap: '$2xs', alignSelf: 'start' }}>
                <SchoolIcon />
                <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.classroom.pages.enrollments.teacher.list.title}
                </Typography>
              </Div>
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '$md',
                  width: 'fill',
                  gap: '$md'
                }}
              >
                <EmptyList
                  title={
                    i18n().modules.hub.classroom.pages.enrollments.teacher.list.withoutTeachers
                  }
                  subTitle={
                    !listDisciplines.data?.registers.length
                      ? i18n().modules.hub.classroom.pages.enrollments.teacher.list
                          .withoutDisciplines
                      : !allTeachersToEnroll.data?.registers.length
                        ? i18n().modules.hub.classroom.pages.enrollments.teacher.list
                            .withoutSchoolTeachers
                        : ''
                  }
                />

                {listDisciplines.data?.registers.length &&
                allTeachersToEnroll.data?.registers.length ? (
                  <Button
                    variant="tonal"
                    LeadingIcon={<AddCircleIcon />}
                    onClick={() =>
                      navigate(
                        '/data-management/levels-and-classes/classroom/classroom-users/form-teacher',
                        {
                          state: {
                            classroom: locationState?.classroom,
                            schoolYear: {
                              name: locationState?.schoolYear.name
                            }
                          }
                        }
                      )
                    }
                  >
                    {i18n().modules.hub.classroom.pages.enrollments.teacher.list.includeTeachers}
                  </Button>
                ) : !allTeachersToEnroll.data?.registers.length ? (
                  <></>
                ) : (
                  <Button variant="outlined" LeadingIcon={<LinkIcon />}>
                    {
                      i18n().modules.hub.classroom.pages.enrollments.teacher.list
                        .goToDisciplineRegistration
                    }
                  </Button>
                )}
              </Div>
            </Div>
          </Div>
        )
      ) : (
        <>
          {listClassroomTeachers.isFetching ? (
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderRadius: '$xl',
                borderColor: '$outline-variant'
              }}
            >
              <Loader data-testid="Loader-Container-DisciplineList" size={80} />
            </Div>
          ) : (
            <>
              <ListTeachers
                data={listClassroomTeachers.data.registers}
                originalData={listClassroomTeachers.data.registers}
              />
              {listClassroomTeachers.data.lastPage > 1 && (
                <Pagination
                  lastPage={listClassroomTeachers.data.lastPage}
                  page={listClassroomTeachersParams.page ?? 1}
                  setPage={(page) =>
                    setListClassroomTeachersParams((oldState) => ({ ...oldState, page }))
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </Div>
  )
}
