import { Circle, Line, Rect, Svg } from '@positivote/design-system/icons/Base'

export function MenuSkeleton(): JSX.Element {
  return (
    <Svg
      width="120"
      height="136"
      viewBox="0 0 120 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Rect width="120" height="136" rx="8" css={{ fill: '#F3F4F6' }} />
      <Circle cx="24" cy="16" r="8" css={{ fill: '#DEE4ED' }} />
      <Rect opacity="0.2" x="40" y="8" width="64" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Circle cx="24" cy="40" r="8" css={{ fill: '#DEE4ED' }} />
      <Rect opacity="0.2" x="40" y="32" width="64" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Line x1="8" y1="55.5" x2="112" y2="55.5" stroke="#CAD3E0" />
      <Circle cx="24" cy="72" r="8" css={{ fill: '#DEE4ED' }} />
      <Rect opacity="0.2" x="40" y="64" width="64" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Circle cx="24" cy="96" r="8" css={{ fill: '#DEE4ED' }} />
      <Rect opacity="0.2" x="40" y="88" width="64" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Circle cx="24" cy="120" r="8" css={{ fill: '#DEE4ED' }} />
      <Rect opacity="0.2" x="40" y="112" width="64" height="16" rx="8" css={{ fill: '#5D6675' }} />
    </Svg>
  )
}
