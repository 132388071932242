import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'
import { CSS } from '@positivote/design-system/theme'

import { AppBaseCard, AppBaseCardProps } from '@/modules/hub/applications/components/AppBaseCard'

export function MobileAppCard({
  application,
  applicationIdLoading,
  css,
  ...restProps
}: AppBaseCardProps): JSX.Element {
  const isLoading = application.id === applicationIdLoading
  return (
    <AppBaseCard
      application={application}
      wrapperProps={{ css: { '@xs': { width: 'calc(50% - $2xs)' } } }}
      css={{
        width: '152px',
        height: '160px',
        ...css,
        '& .Button-StateLayer': { padding: '$md', ...(css?.['& .Button-StateLayer'] as CSS) },
        '@xs': { width: '100%', ...(css?.['@xs'] as CSS) }
      }}
      {...restProps}
    >
      <IconWrapper className="MobileAppCard-Application-Icon" size={64}>
        {isLoading && <Loader size={20} strokeWidth={4} />}
        <Image
          alt="Application Icon"
          src={application.iconUrl}
          css={{
            width: '100%',
            height: '100%',
            opacity: isLoading ? '25%' : 'unset',
            filter: isLoading ? 'grayscale(100%)' : 'none',
            objectFit: 'contain'
          }}
        />
      </IconWrapper>

      <Typography
        className="MobileAppCard-Application-Name"
        variant="titleSmall"
        lineClamp={1}
        css={{ textAlign: 'center', color: '$on-surface', wordBreak: 'break-all' }}
      >
        {application.name}
      </Typography>
    </AppBaseCard>
  )
}
