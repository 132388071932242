import { Navigate, Route, Routes } from 'react-router-dom'

import { Agreement } from './pages/Agreement'

export function TermRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Agreement />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
