import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { TextField } from '@positivote/design-system/components/TextField'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { useAlert } from '@positivote/design-system/hooks'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { KeyboardDoubleArrowLeftIcon } from '@/fixme/icons/KeyboardDoubleArrowLeft'
import { KeyboardDoubleArrowRightIcon } from '@/fixme/icons/KeyboardDoubleArrowRight'
import { ClassroomFormatted } from '@/modules/hub/classroom/contracts'
import { useCreateBulkEnrollment, useRemoveBulkEnrollment } from '@/modules/hub/enrollments/hooks'
import { ListShortStudent, ListShortStudentHookParams } from '@/modules/hub/users/contracts'
import { useListShortStudent } from '@/modules/hub/users/hooks'

export function Students(): JSX.Element {
  const [initialStudentsToEnrollments, setInitialStudentsToEnrollments] = useState<
    ListShortStudent[]
  >([])
  const [initialStudentsAlreadyEnrollments, setInitialStudentsAlreadyEnrollments] = useState<
    ListShortStudent[]
  >([])
  const [studentsToEnrollments, setStudentsToEnrollments] = useState<ListShortStudent[]>([])
  const [currentStudentsSelected, setCurrentStudentsSelected] = useState<number[]>([])
  const [enrollments, setEnrollment] = useState<ListShortStudent[]>([])

  const [studentsAlreadyEnrollments, setStudentsAlreadyEnrollments] = useState<ListShortStudent[]>(
    []
  )
  const [currentStudentRemoveSelected, setCurrentStudentRemoveSelected] = useState<number[]>([])
  const [removeEnrollments, setRemoveEnrollments] = useState<ListShortStudent[]>([])

  const [nameStudentEnrollments, setNameStudentEnrollments] = useState<string | undefined>('')
  const [nameAlreadyEnrollments, setNameAlreadyEnrollments] = useState<string | undefined>('')

  const createBulkEnrollment = useCreateBulkEnrollment()
  const removeBulkEnrollment = useRemoveBulkEnrollment()

  const [onSuccess, setOnSuccess] = useState(false)

  const { ref: refStudentsToEnrollments, inView: inViewStudentsToEnrollments } = useInView()
  const { ref: refStudentsAlreadyEnrollments, inView: inViewStudentsAlreadyEnrollments } =
    useInView()

  const navigate = useNavigate()
  const location = useLocation()
  const { addAlertMessage } = useAlert()

  const maxSelectedStudents = 200
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
  } | null

  changePageTitle(
    i18n().modules.hub.classroom.pages.enrollments.form.pageTitle(
      locationState?.classroom.name ?? ''
    )
  )

  const [classroomStudentsToEnrollParams, setClassroomStudentsToEnrollParams] = useState<
    ListShortStudentHookParams['model']
  >({
    page: 1,
    perPage: 50
  })

  const [classroomStudentsAlreadyEnrolledParams, setClassroomStudentsAlreadyEnrolledParams] =
    useState<ListShortStudentHookParams['model']>({
      page: 1,
      perPage: 50
    })

  const allStudentsToEnroll = useListShortStudent({
    model: {
      ignoreClassroomId: locationState?.classroom.id,
      ...classroomStudentsToEnrollParams
    },
    queryOptions: {
      enabled: !!locationState
    }
  })

  const allStudentsAlreadyEnrolled = useListShortStudent({
    model: {
      classroomId: locationState?.classroom.id,
      ...classroomStudentsAlreadyEnrolledParams
    },
    queryOptions: {
      enabled: !!locationState
    }
  })

  const isLoading = allStudentsToEnroll.isFetching || allStudentsAlreadyEnrolled.isFetching

  function handleSelectAllStudents(): void {
    const studentsIds = studentsToEnrollments.map((student) => student.id)

    const allSelected = studentsIds.every((id) => currentStudentsSelected.includes(id))

    if (allSelected) {
      setCurrentStudentsSelected((prevSelected) =>
        prevSelected.filter((id) => !studentsIds.includes(id))
      )
    } else {
      setCurrentStudentsSelected((prevSelected) => [...prevSelected, ...studentsIds])
    }
  }

  function handleSelectAllRemoveStudents(): void {
    const studentsIds = studentsAlreadyEnrollments.map((student) => student.id)

    const allSelected = studentsIds.every((id) => currentStudentRemoveSelected.includes(id))

    if (allSelected) {
      setCurrentStudentRemoveSelected((prevSelected) =>
        prevSelected.filter((id) => !studentsIds.includes(id))
      )
    } else {
      setCurrentStudentRemoveSelected((prevSelected) => [...prevSelected, ...studentsIds])
    }
  }

  function handleMoveToEnrollments(): void {
    if (currentStudentsSelected.length + enrollments.length > maxSelectedStudents) {
      addAlertMessage({
        severity: 'warning',
        subTitle: i18n().modules.hub.classroom.pages.enrollments.form.alertMessageTitle
      })
    } else {
      const selectedItems = studentsToEnrollments.filter((item) =>
        currentStudentsSelected.includes(item.id)
      )
      const remainingItems = studentsToEnrollments.filter(
        (item) => !currentStudentsSelected.includes(item.id)
      )
      setStudentsToEnrollments(remainingItems)
      setStudentsAlreadyEnrollments((oldData) => [...selectedItems, ...oldData])
      setEnrollment((oldData) => [...selectedItems, ...oldData])
      setCurrentStudentsSelected([])
      const filteredRemoveStudentsEnrollments = removeEnrollments.filter(
        (student) => !currentStudentsSelected.includes(student.id)
      )
      setRemoveEnrollments(filteredRemoveStudentsEnrollments)
    }
  }

  function handleRemoveToEnrollments(): void {
    const selectedItems = studentsAlreadyEnrollments.filter((item) =>
      currentStudentRemoveSelected.includes(item.id)
    )
    const remainingItems = studentsAlreadyEnrollments.filter(
      (item) => !currentStudentRemoveSelected.includes(item.id)
    )

    setStudentsToEnrollments((oldData) => [...selectedItems, ...oldData])
    setStudentsAlreadyEnrollments(remainingItems)
    setRemoveEnrollments((oldData) => [...selectedItems, ...oldData])
    setCurrentStudentRemoveSelected([])
    const filteredStudentsEnrollments = enrollments.filter(
      (student) => !currentStudentRemoveSelected.includes(student.id)
    )
    setEnrollment(filteredStudentsEnrollments)
  }

  const isAllStudentsSelected = useMemo(() => {
    const studentsIds = studentsToEnrollments.map((student) => student.id)
    return studentsIds.every((id) => currentStudentsSelected.includes(id))
  }, [studentsToEnrollments, currentStudentsSelected])

  const isAllSelectedStudentsToRemove = useMemo(() => {
    const studentsIds = studentsAlreadyEnrollments.map((student) => student.id)
    return studentsIds.every((id) => currentStudentRemoveSelected.includes(id))
  }, [currentStudentRemoveSelected, studentsAlreadyEnrollments])

  function handleSelectStudent(id: number): void {
    setCurrentStudentsSelected((old) => {
      if (old.includes(id)) {
        return old.filter((studentId) => studentId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleSelectRemoveStudent(id: number): void {
    setCurrentStudentRemoveSelected((old) => {
      if (old.includes(id)) {
        return old.filter((studentId) => studentId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined

    debounceEvent(() => {
      setNameStudentEnrollments(searchBy)
      setClassroomStudentsToEnrollParams((oldData) => ({ ...oldData, search: searchBy, page: 1 }))
      if (!searchBy) {
        const filteredStudents = initialStudentsToEnrollments.filter(
          (studentToEnroll) =>
            !studentsAlreadyEnrollments.some(
              (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
            )
        )
        setStudentsToEnrollments(filteredStudents)
      }
    })()
  }

  function handleChangeNameAlreadyText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setNameAlreadyEnrollments(searchBy)
      setClassroomStudentsAlreadyEnrolledParams((oldData) => ({
        ...oldData,
        search: searchBy,
        page: 1
      }))
      if (!searchBy) {
        const filteredStudents = initialStudentsAlreadyEnrollments.filter(
          (studentToEnroll) =>
            !studentsToEnrollments.some(
              (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
            )
        )
        setStudentsAlreadyEnrollments(filteredStudents)
      }
    })()
  }

  function handleCreateBulkEnrollment(): void {
    const isSuccess = false
    if (enrollments.length) {
      const newStudentsIntegrated = enrollments.map((student) => ({
        studentId: student.id
      }))
      createBulkEnrollment.mutate({
        model: {
          classroomId: locationState!.classroom.id,
          enrollments: newStudentsIntegrated
        },
        onSuccess: () => {
          if (enrollments.length && !removeEnrollments.length) {
            navigate('/data-management/levels-and-classes/classroom/classroom-users', {
              state: {
                classroom: locationState?.classroom,
                schoolYear: {
                  name: locationState?.schoolYear.name
                }
              }
            })
          }
        }
      })
    }
    if (removeEnrollments.length) {
      const newStudentsIntegrated = removeEnrollments.map((student) => ({
        studentId: student.id
      }))
      removeBulkEnrollment.mutate({
        model: {
          classroomId: locationState!.classroom.id,
          enrollments: newStudentsIntegrated
        },
        onSuccess: () => {
          if (removeEnrollments.length && !enrollments.length) {
            navigate('/data-management/levels-and-classes/classroom/classroom-users', {
              state: {
                classroom: locationState?.classroom,
                schoolYear: {
                  name: locationState?.schoolYear.name
                }
              }
            })
          }
          if (removeEnrollments.length && enrollments.length) {
            navigate('/data-management/levels-and-classes/classroom/classroom-users', {
              state: {
                classroom: locationState?.classroom,
                schoolYear: {
                  name: locationState?.schoolYear.name
                }
              }
            })
          }
        }
      })
    }
    setOnSuccess(isSuccess)
  }

  useEffect(() => {
    if (classroomStudentsToEnrollParams.search?.length) {
      const filteredStudents = allStudentsToEnroll.data?.registers.filter(
        (studentToEnroll) =>
          !studentsAlreadyEnrollments.some(
            (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
          )
      )
      setStudentsToEnrollments(filteredStudents ?? [])
    } else {
      if (!initialStudentsToEnrollments.length) {
        setInitialStudentsToEnrollments(allStudentsToEnroll.data?.registers ?? [])
      }
      setStudentsToEnrollments((oldData) => {
        const newStudents = [...removeEnrollments, ...(allStudentsToEnroll.data?.registers ?? [])]
        const uniqueStudents = newStudents.filter(
          (newStudent) => !oldData.some((oldStudent) => oldStudent.id === newStudent.id)
        )
        const filteredStudents = uniqueStudents.filter(
          (studentToEnroll) =>
            !studentsAlreadyEnrollments.some(
              (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
            )
        )
        return [...filteredStudents, ...oldData]
      })
    }
    // DOCS: rerender only allStudentsToEnroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStudentsToEnroll.data?.registers])

  useEffect(() => {
    if (classroomStudentsAlreadyEnrolledParams.search?.length) {
      const filteredStudents = allStudentsAlreadyEnrolled.data?.registers.filter(
        (studentToEnroll) =>
          !studentsToEnrollments.some(
            (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
          )
      )
      setStudentsAlreadyEnrollments(filteredStudents ?? [])
    } else {
      if (!initialStudentsAlreadyEnrollments.length) {
        setInitialStudentsAlreadyEnrollments(allStudentsAlreadyEnrolled.data?.registers ?? [])
      }
      setStudentsAlreadyEnrollments((oldData) => {
        const newStudents = [...enrollments, ...(allStudentsAlreadyEnrolled.data?.registers ?? [])]
        const uniqueStudents = newStudents.filter(
          (newStudent) => !oldData.some((oldStudent) => oldStudent.id === newStudent.id)
        )
        const filteredStudents = uniqueStudents.filter(
          (studentToEnroll) =>
            !studentsToEnrollments.some(
              (enrolledStudent) => enrolledStudent.id === studentToEnroll.id
            )
        )
        return [...filteredStudents, ...oldData]
      })
    }

    // DOCS: rerender only allStudentsAlreadyEnrolled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStudentsAlreadyEnrolled.data?.registers])

  useEffect(() => {
    if (
      inViewStudentsToEnrollments &&
      !allStudentsToEnroll.isFetching &&
      allStudentsToEnroll.data!.lastPage > classroomStudentsToEnrollParams.page!
    ) {
      setClassroomStudentsToEnrollParams((oldData) => ({
        ...oldData,
        page: oldData.page! + 1
      }))
    }
    // DOCS: rerender only inViewStudentsToEnrollments
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewStudentsToEnrollments])

  useEffect(() => {
    if (
      inViewStudentsAlreadyEnrollments &&
      !allStudentsAlreadyEnrolled.isFetching &&
      allStudentsAlreadyEnrolled.data!.lastPage > classroomStudentsAlreadyEnrolledParams.page!
    ) {
      setClassroomStudentsAlreadyEnrolledParams((oldData) => ({
        ...oldData,
        page: oldData.page! + 1
      }))
    }
    // DOCS: rerender only inViewStudentsAlreadyEnrollments
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewStudentsAlreadyEnrollments])

  if (!locationState) {
    navigate('/data-management/levels-and-classes/classroom')
  }

  useEffect(() => {
    if (onSuccess) {
      navigate(-1)
    }
  }, [navigate, onSuccess])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.classroom.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-3)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs
                .educationLevelsAndClasses,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.schoolClasses,
            onClick: () => {
              navigate('/data-management/levels-and-classes/classroom')
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.includeStudent(
                locationState?.classroom.name ?? ''
              )
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'scroll'
        }}
      >
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '$lg',
            padding: '$lg',
            '@sm': { padding: '$md' }
          }}
        >
          <Div css={{ display: 'flex', gap: '$md', justifyContent: 'center' }}>
            {locationState?.schoolYear.name && (
              <Chip color="neutral" label={locationState.schoolYear.name} variant="filled" />
            )}
            {locationState?.classroom && (
              <>
                <Chip
                  label={
                    i18n().modules.hub.classroom.fullClassLevel[
                      locationState.classroom.level.code
                    ].split('-')[0]
                  }
                  color="neutral"
                />
                <Chip
                  color="neutral"
                  label={
                    i18n().modules.hub.classroom.fullClassLevel[
                      locationState.classroom.level.code
                    ].split('-')[1]
                  }
                  variant="filled"
                />
              </>
            )}
          </Div>
          <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
            {isLoading &&
            !nameStudentEnrollments?.length &&
            !nameAlreadyEnrollments?.length &&
            classroomStudentsToEnrollParams.page! <= 1 &&
            classroomStudentsAlreadyEnrolledParams.page! <= 1 ? (
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '$lg',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loader size={80} />
              </Div>
            ) : (
              <>
                {!allStudentsToEnroll.data?.registers.length &&
                !allStudentsAlreadyEnrolled.data?.registers.length ? (
                  <Div css={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <Div
                      css={{
                        display: 'flex',
                        flex: 1,
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderRadius: '$xl',
                        borderColor: '$outline-variant',
                        padding: '$md',
                        gap: '$md'
                      }}
                    >
                      <Div css={{ display: 'flex', gap: '$2xs' }}>
                        <AddCircleIcon />
                        <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                          {i18n().modules.hub.classroom.pages.enrollments.form.includeStudents}
                        </Typography>
                      </Div>
                      <EmptyList
                        title={i18n().modules.hub.classroom.pages.enrollments.list.emptyList.title}
                        subTitle={
                          i18n().modules.hub.classroom.pages.enrollments.list.emptyList.subTitle
                        }
                      />
                    </Div>
                  </Div>
                ) : (
                  <Grid css={{ justifyContent: 'space-between', display: 'flex' }}>
                    <Grid xl={5.7}>
                      <Div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderRadius: '$xl',
                          borderColor: '$outline-variant',
                          padding: '$md',
                          gap: '$md',
                          height: 600,
                          overflow: 'hidden'
                        }}
                      >
                        <Div css={{ display: 'flex', gap: '$2xs' }}>
                          <AddCircleIcon />
                          <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                            {i18n().modules.hub.classroom.pages.enrollments.form.includeStudents}
                          </Typography>
                        </Div>
                        {studentsToEnrollments.length || nameStudentEnrollments?.length ? (
                          <>
                            <TextField
                              variant="outlined"
                              label={
                                i18n().modules.hub.classroom.pages.enrollments.form.textFieldLabel
                              }
                              leadingIcon={{ icon: SearchIcon }}
                              inputProps={{
                                onChange: handleChangeSearchText
                              }}
                            />
                            {allStudentsToEnroll.isFetching &&
                            classroomStudentsToEnrollParams.page! <= 1 ? (
                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  padding: '$lg',
                                  flex: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Loader size={80} />
                              </Div>
                            ) : (
                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '$2xs',
                                  flex: 1,
                                  overflow: 'auto'
                                }}
                              >
                                {!studentsToEnrollments.length ? (
                                  <EmptySearch />
                                ) : (
                                  <>
                                    <Div
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '$md',
                                        padding: '$md'
                                      }}
                                    >
                                      <Checkbox
                                        inputProps={{ checked: isAllStudentsSelected }}
                                        onClick={handleSelectAllStudents}
                                      />
                                      <Typography
                                        variant="titleMedium"
                                        css={{ color: '$on-surface' }}
                                      >
                                        {
                                          i18n().modules.hub.classroom.pages.enrollments.form
                                            .students
                                        }
                                      </Typography>
                                    </Div>
                                    <Div
                                      css={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '$2xs',
                                        flex: 1,
                                        overflow: 'auto'
                                      }}
                                    >
                                      {studentsToEnrollments.map((option) => (
                                        <BaseCard
                                          key={option.id}
                                          css={{
                                            borderRadius: '$lg',
                                            backgroundColor: '$surface-2',
                                            minHeight: '70px',
                                            '& .BaseCard-StateLayer': {
                                              padding: '$md $lg',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              gap: '$md',
                                              justifyContent: 'space-between'
                                            }
                                          }}
                                        >
                                          <Div
                                            css={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '$md'
                                            }}
                                          >
                                            <Checkbox
                                              inputProps={{
                                                checked: currentStudentsSelected.includes(
                                                  option.id
                                                ),
                                                onClick: () => handleSelectStudent(option.id)
                                              }}
                                            />
                                            <Image
                                              FallbackImage={() => (
                                                <IconWrapper
                                                  data-testid="Drawer-FallbackImage"
                                                  size="$2xl"
                                                  css={{ backgroundColor: '$primary-container' }}
                                                >
                                                  <PersonIcon fill="$on-primary-container" />
                                                </IconWrapper>
                                              )}
                                              alt="img"
                                              height={32}
                                              width={32}
                                              css={{ borderRadius: '$full' }}
                                              src={option.picture}
                                            />
                                            <Typography
                                              variant="bodyMedium"
                                              css={{ color: '$on-surface' }}
                                            >
                                              {option.name}
                                            </Typography>
                                          </Div>

                                          {!!option.classrooms.length && (
                                            <Tooltip
                                              css={{ background: '$inverse-surface' }}
                                              label={
                                                <Div>
                                                  <Typography
                                                    variant="labelMedium"
                                                    css={{ color: '$inverse-on-surface' }}
                                                  >
                                                    {
                                                      i18n().modules.hub.classroom.pages.enrollments
                                                        .form.tooltipLabel
                                                    }
                                                  </Typography>
                                                  {option.classrooms.map((classroom) => (
                                                    <Typography
                                                      key={classroom.code}
                                                      variant="labelMedium"
                                                      css={{ color: '$inverse-on-surface' }}
                                                    >
                                                      • {classroom.name}
                                                    </Typography>
                                                  ))}
                                                </Div>
                                              }
                                              placement="top"
                                              arrow
                                            >
                                              <InfoIcon />
                                            </Tooltip>
                                          )}
                                        </BaseCard>
                                      ))}

                                      <Div
                                        ref={refStudentsToEnrollments}
                                        css={{
                                          minHeight:
                                            allStudentsToEnroll.data!.lastPage >
                                            classroomStudentsToEnrollParams.page!
                                              ? '70px'
                                              : '$none',
                                          display: 'flex',
                                          position: 'relative',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        {allStudentsToEnroll.isFetching &&
                                          classroomStudentsToEnrollParams.page! > 1 && (
                                            <Loader size={20} />
                                          )}
                                      </Div>
                                    </Div>
                                  </>
                                )}
                              </Div>
                            )}
                          </>
                        ) : (
                          <>
                            {!studentsToEnrollments.length &&
                              !!studentsAlreadyEnrollments.length && (
                                <EmptyList
                                  title={
                                    i18n().modules.hub.classroom.pages.enrollments.form
                                      .emptyListStudent
                                  }
                                />
                              )}
                            {!studentsToEnrollments.length &&
                              !studentsAlreadyEnrollments.length && (
                                <EmptyList
                                  title={
                                    i18n().modules.hub.classroom.pages.enrollments.form
                                      .emptyListClassroomStudent
                                  }
                                />
                              )}
                          </>
                        )}
                      </Div>
                    </Grid>
                    <Grid
                      xl={0.6}
                      css={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '$2xs'
                      }}
                    >
                      <IconButton
                        variant="tonal"
                        onClick={() => handleMoveToEnrollments()}
                        disabled={!currentStudentsSelected.length}
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </IconButton>

                      <IconButton
                        variant="tonal"
                        onClick={() => handleRemoveToEnrollments()}
                        disabled={!currentStudentRemoveSelected.length}
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </IconButton>
                    </Grid>
                    <Grid xl={5.7}>
                      <Div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderRadius: '$xl',
                          borderColor: '$outline-variant',
                          padding: '$md',
                          gap: '$md',
                          height: 600,
                          overflow: 'hidden'
                        }}
                      >
                        <Div css={{ display: 'flex', gap: '$2xs' }}>
                          <AddCircleIcon />
                          <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                            {i18n().modules.hub.classroom.pages.enrollments.form.classroomStudents}
                          </Typography>
                        </Div>
                        {studentsAlreadyEnrollments.length || nameAlreadyEnrollments?.length ? (
                          <>
                            <TextField
                              variant="outlined"
                              label={
                                i18n().modules.hub.classroom.pages.enrollments.form.textFieldLabel
                              }
                              leadingIcon={{ icon: SearchIcon }}
                              inputProps={{
                                onChange: handleChangeNameAlreadyText
                              }}
                            />
                            {allStudentsAlreadyEnrolled.isFetching &&
                            classroomStudentsAlreadyEnrolledParams.page! <= 1 ? (
                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  padding: '$lg',
                                  flex: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Loader size={80} />
                              </Div>
                            ) : (
                              <Div
                                css={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '$2xs',
                                  flex: 1,
                                  overflow: 'auto'
                                }}
                              >
                                {!studentsAlreadyEnrollments.length ? (
                                  <EmptySearch />
                                ) : (
                                  <>
                                    <Div
                                      css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '$md',
                                        padding: '$md'
                                      }}
                                    >
                                      <Checkbox
                                        inputProps={{ checked: isAllSelectedStudentsToRemove }}
                                        onClick={handleSelectAllRemoveStudents}
                                      />
                                      <Typography
                                        variant="titleMedium"
                                        css={{ color: '$on-surface' }}
                                      >
                                        {
                                          i18n().modules.hub.classroom.pages.enrollments.form
                                            .students
                                        }
                                      </Typography>
                                    </Div>
                                    <Div
                                      css={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '$2xs',
                                        flex: 1,
                                        overflow: 'scroll'
                                      }}
                                    >
                                      {studentsAlreadyEnrollments.map((option) => (
                                        <BaseCard
                                          key={option.id}
                                          css={{
                                            borderRadius: '$lg',
                                            backgroundColor: '$surface-2',
                                            minHeight: '70px',

                                            '& .BaseCard-StateLayer': {
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              gap: '$md'
                                            }
                                          }}
                                        >
                                          <Checkbox
                                            inputProps={{
                                              checked: currentStudentRemoveSelected.includes(
                                                option.id
                                              ),
                                              onClick: () => handleSelectRemoveStudent(option.id)
                                            }}
                                          />
                                          <Image
                                            FallbackImage={() => (
                                              <IconWrapper
                                                data-testid="Drawer-FallbackImage"
                                                size="$2xl"
                                                css={{ backgroundColor: '$primary-container' }}
                                              >
                                                <PersonIcon fill="$on-primary-container" />
                                              </IconWrapper>
                                            )}
                                            alt="img"
                                            height={32}
                                            width={32}
                                            css={{ borderRadius: '$full' }}
                                            src={option.picture}
                                          />
                                          <Typography
                                            variant="bodyMedium"
                                            css={{ color: '$on-surface' }}
                                          >
                                            {option.name}
                                          </Typography>
                                        </BaseCard>
                                      ))}
                                      <Div
                                        ref={refStudentsAlreadyEnrollments}
                                        css={{
                                          minHeight:
                                            allStudentsToEnroll.data!.lastPage >
                                            classroomStudentsToEnrollParams.page!
                                              ? '70px'
                                              : '$none',
                                          display: 'flex',
                                          position: 'relative',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        {allStudentsAlreadyEnrolled.isFetching &&
                                          classroomStudentsAlreadyEnrolledParams.page! > 1 && (
                                            <Loader size={20} />
                                          )}
                                      </Div>
                                    </Div>
                                  </>
                                )}
                              </Div>
                            )}
                          </>
                        ) : (
                          <>
                            {!!removeEnrollments.length && !!studentsToEnrollments.length && (
                              <EmptyList
                                title={
                                  i18n().modules.hub.classroom.pages.enrollments.form
                                    .emptyListStudent
                                }
                              />
                            )}
                            {!removeEnrollments.length && !studentsAlreadyEnrollments.length && (
                              <EmptyList
                                title={
                                  i18n().modules.hub.classroom.pages.enrollments.form
                                    .classroomStudents
                                }
                              />
                            )}
                          </>
                        )}
                      </Div>
                    </Grid>
                  </Grid>
                )}

                <Div css={{ display: 'flex', justifyContent: 'space-between', marginTop: '$lg' }}>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    LeadingIcon={<CancelIcon size={18} />}
                  >
                    {i18n().modules.hub.classroom.pages.enrollments.form.cancel}
                  </Button>
                  <Button
                    disabled={
                      (!allStudentsToEnroll.data?.registers.length &&
                        !allStudentsAlreadyEnrolled.data?.registers.length) ||
                      (!enrollments.length && !removeEnrollments.length)
                    }
                    isLoading={createBulkEnrollment.isPending || removeBulkEnrollment.isPending}
                    onClick={() => handleCreateBulkEnrollment()}
                    LeadingIcon={<CheckCircleIcon size={18} />}
                  >
                    {i18n().modules.hub.classroom.pages.enrollments.form.saveClassroom}
                  </Button>
                </Div>
              </>
            )}
          </Div>
        </Div>
      </Div>
    </Main>
  )
}
