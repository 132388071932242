import * as yup from 'yup'

import { i18n } from '@/common/i18n'

export const usernameLoginValidationSchema = yup.object().shape({
  username: yup
    .string()
    .min(4, i18n().common.validators.min(4))
    .max(100, i18n().common.validators.max(100))
    .required(i18n().common.validators.required)
})

export const passwordLoginValidationSchema = usernameLoginValidationSchema.shape({
  password: yup
    .string()
    .min(6, i18n().common.validators.min(6))
    .max(100, i18n().common.validators.max(100))
    .required(i18n().common.validators.required)
})

export const organizationLoginValidationSchema = yup.object().shape({
  organizationId: yup.string().required(i18n().common.validators.required)
})

export const recoverPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(i18n().common.validators.emailInvalid)
    .required(i18n().common.validators.required)
})

export const changePasswordValidationSchema = yup.object().shape({
  code: yup
    .string()
    .min(6, i18n().common.validators.codeInvalid)
    .required(i18n().common.validators.required),
  newPassword: yup
    .string()
    .min(6, i18n().common.validators.minPasswordInvalid)
    .matches(/[a-zA-Z]/, i18n().common.validators.passwordNotLetter)
    .matches(/[!@#$%&.]/, i18n().common.validators.passwordNotSpecialCharacter)
    .matches(/\d/, i18n().common.validators.passwordNotNumber)
    .required(i18n().common.validators.required),
  confirmNewPassword: yup
    .string()
    .required(i18n().common.validators.required)
    .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
})

export const resetPasswordValidationSchema = (
  isSchool: boolean
): yup.ObjectSchema<{ newPassword: string; confirmNewPassword: string }> => {
  if (isSchool) {
    return yup.object().shape({
      newPassword: yup
        .string()
        .min(6, i18n().common.validators.minPasswordInvalid)
        .required(i18n().common.validators.required),
      confirmNewPassword: yup
        .string()
        .required(i18n().common.validators.required)
        .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
    })
  } else {
    return yup.object().shape({
      newPassword: yup
        .string()
        .min(6, i18n().common.validators.minPasswordInvalid)
        .matches(/[a-zA-Z]/, i18n().common.validators.passwordNotLetter)
        .matches(/[!@#$%&.]/, i18n().common.validators.passwordNotSpecialCharacter)
        .matches(/\d/, i18n().common.validators.passwordNotNumber)
        .required(i18n().common.validators.required),
      confirmNewPassword: yup
        .string()
        .required(i18n().common.validators.required)
        .oneOf([yup.ref('newPassword')], i18n().common.validators.notEqualsPasswordInvalid)
    })
  }
}

export const quickAccessValidationSchema = yup.object().shape({
  username: yup.string().required(i18n().common.validators.required),
  code: yup
    .array()
    .of(yup.string().required(i18n().common.validators.required))
    .required(i18n().common.validators.required)
})

export const loginSupportValidationSchema = yup.object().shape({
  organizationId: yup
    .number()
    .typeError(i18n().common.validators.required)
    .required(i18n().common.validators.required),
  userCode: yup.string().required(i18n().common.validators.required)
})
