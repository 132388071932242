export const ltiPt = {
  resources: {
    makeLaunch: 'Realizar o login LTI',
    showApplication: 'Verificar se aplicação de SSO ou oAuth2 existe',
    createOAuth2Code: 'Criar código de oAuth2'
  },
  hooks: {
    showApplication: {
      exceptions: {
        INVALID_REDIRECT_URI: 'Uri de redirecionamento inválida'
      }
    }
  }
}
