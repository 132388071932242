import { ApplicationException } from '@/common/exceptions'
import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'

import {
  CreateQuickAccessApiParams,
  CreateQuickAccessApiResult,
  ShowQuickAccessApiResult
} from './contracts/api'
import {
  CreateQuickAccessServiceParams,
  CreateQuickAccessServiceResult,
  RevokeQuickAccessServiceParams,
  ShowQuickAccessServiceParams
} from './contracts/services'
import { fromQuickAccessApiSanitizer } from './sanitizers'

export async function createQuickAccessService({
  classId,
  duration
}: CreateQuickAccessServiceParams): Promise<CreateQuickAccessServiceResult> {
  const { data } = await hubApiService<CreateQuickAccessApiParams, CreateQuickAccessApiResult>({
    resource: i18n().modules.hub.quickAccess.resources.create,
    method: 'post',
    url: '/quick-access/v1/code',
    body: {
      class_id: classId,
      duration
    }
  })
  return fromQuickAccessApiSanitizer(data)
}

export async function showQuickAccessService({
  classId
}: ShowQuickAccessServiceParams): Promise<CreateQuickAccessServiceResult> {
  const { data } = await hubApiService<null, ShowQuickAccessApiResult>({
    resource: i18n().modules.hub.quickAccess.resources.show,
    method: 'get',
    url: `/quick-access/v1/code/classes/${classId}`
  }).catch((error) => {
    const parsedError = error as ApplicationException
    if (parsedError.code === 'QUICK_ACCESS.CODE_NOT_FOUND') {
      return { data: null }
    }
    throw error
  })

  return data && fromQuickAccessApiSanitizer(data)
}

export async function revokeQuickAccessService({
  quickAccessId
}: RevokeQuickAccessServiceParams): Promise<void> {
  await hubApiService<null, null>({
    resource: i18n().modules.hub.quickAccess.resources.revoke,
    method: 'patch',
    url: `/quick-access/v1/code/${quickAccessId}/revoke`
  })
}
