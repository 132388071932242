import { yupResolver } from '@hookform/resolvers/yup'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormMediaInput } from '@positivote/design-system/components-form/MediaInput'
import { FormSwitch } from '@positivote/design-system/components-form/Switch'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { SCROLL_BAR_SIZE } from '@positivote/design-system/constants'
import { CheckIcon } from '@positivote/design-system/icons/Check'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { CircleIcon } from '@positivote/design-system/icons/Circle'
import { IconProps } from '@positivote/design-system/icons/types'
import { Colors, colors } from '@positivote/design-system/tokens'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { whiteLabelColorPalette } from '@/modules/hub/white-label/constants'
import { WhiteLabelForm } from '@/modules/hub/white-label/contracts/form'
import {
  useCreateWhiteLabel,
  useUpdateWhiteLabel,
  useVerifyWhiteLabelAlias
} from '@/modules/hub/white-label/hooks'
import { FrameWhiteLabel } from '@/modules/hub/white-label/icons/FrameWhiteLabel'
import { MenuWhiteLabel } from '@/modules/hub/white-label/icons/MenuWhiteLabel'
import { whiteLabelFormSanitizer } from '@/modules/hub/white-label/sanitizers'
import { whiteLabelSchema } from '@/modules/hub/white-label/validations'

export function WhiteLabelCustomizationForm(): JSX.Element {
  const [themeActive, setThemeActive] = useState<keyof Colors>('sysPrimary')
  const [alias, setAlias] = useState('')
  const { whiteLabel, session } = useAuth()
  const createWhiteLabel = useCreateWhiteLabel()
  const updateWhiteLabel = useUpdateWhiteLabel()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { errors }
  } = useForm<WhiteLabelForm>({
    mode: 'onSubmit',
    defaultValues: whiteLabel && whiteLabelFormSanitizer(whiteLabel),
    resolver: async (values, ...args) =>
      yupResolver(whiteLabelSchema)(whiteLabelFormSanitizer(values), ...args)
  })

  const hasAnyError = !!Object.keys(errors).length

  const { isFetching } = useVerifyWhiteLabelAlias({
    model: { alias },
    queryOptions: {
      enabled: !!alias && !errors.alias,
      gcTime: 0
    },
    onSuccess: (data) => {
      if (!data.available && alias !== whiteLabel?.alias) {
        setError('alias', {
          message:
            i18n().modules.hub.whiteLabel.page.customization.customAddress.inputs.alias.unavailable
        })
      } else {
        clearErrors('alias')
      }
    }
  })

  function handleSelectPrimaryColor(color: keyof Colors): void {
    setThemeActive(color)
    setValue('primaryColor', color)
  }

  function handleChangeText(event: React.ChangeEvent<HTMLInputElement>): void {
    void trigger('alias')
    const value = event.target.value
    debounceEvent(() => {
      setAlias(value)
    })()
  }

  function handleSave(data: WhiteLabelForm): void {
    if (session?.schoolId) {
      if (whiteLabel?.id) {
        updateWhiteLabel.mutate({
          model: {
            ...data,
            id: whiteLabel.id,
            orgId: session.schoolId
          }
        })
      } else {
        createWhiteLabel.mutate({
          model: {
            ...data,
            orgId: session.schoolId
          }
        })
      }
    }
  }

  useEffect(() => {
    setThemeActive(whiteLabel?.primaryColor ? whiteLabel.primaryColor : 'sysPrimary')
  }, [whiteLabel, whiteLabel?.primaryColor])

  return (
    <Main
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
    >
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={handleSave}>
        <Grid
          xl={10}
          md={12}
          spacing="$lg"
          css={{
            padding: watch('active') ? '$lg' : `$lg calc($lg + ${SCROLL_BAR_SIZE}) $lg $lg`
          }}
        >
          <Grid>
            <BaseCard
              css={{
                '& .BaseCard-StateLayer': {
                  padding: '$lg',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '$lg',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }
              }}
            >
              <Div css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
                <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.whiteLabel.page.customization.activeTheme.title}
                </Typography>
                <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                  {i18n().modules.hub.whiteLabel.page.customization.activeTheme.subTitle}
                </Typography>
              </Div>
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                  {i18n().modules.hub.whiteLabel.page.customization.activeTheme.switchTitle(
                    watch('active')
                  )}
                </Typography>
                <FormSwitch register={register} name="active" checked={watch('active')} />
              </Div>
            </BaseCard>
          </Grid>
          {watch('active') && (
            <>
              <Grid>
                <BaseCard
                  css={{
                    '& .BaseCard-StateLayer': {
                      padding: '$lg',
                      display: 'flex',
                      gap: '$lg'
                    }
                  }}
                >
                  <Div css={{ display: 'flex', gap: '$md', flexDirection: 'column' }}>
                    <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.chooseTheme.title}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.chooseTheme.subTitle}
                    </Typography>
                  </Div>
                  <Grid
                    xl={12}
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: '$2xs'
                    }}
                  >
                    <Grid
                      css={{
                        display: 'flex',
                        backgroundColor: 'white',
                        padding: '$md',
                        borderRadius: '$md',
                        alignContent: 'start',
                        justifyContent: 'center',
                        gap: '$md',
                        borderWidth: '$thin',
                        borderStyle: 'solid',
                        borderColor: '$outline-variant'
                      }}
                    >
                      <MenuWhiteLabel fill={colors.light[themeActive].main} />
                      <FrameWhiteLabel fill={colors.light[themeActive].main} />
                    </Grid>
                    <Typography variant="labelSmall" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.chooseTheme.preview}
                    </Typography>
                  </Grid>
                  <Grid css={{ display: 'flex', justifyContent: 'center', gap: '$2xs' }}>
                    {whiteLabelColorPalette.map((color) => (
                      <Div
                        onClick={() => handleSelectPrimaryColor(color)}
                        key={color}
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: 40,
                          width: 40,
                          backgroundColor: colors.light[color].main,
                          borderRadius: '$full',
                          cursor: 'pointer'
                        }}
                      >
                        {themeActive === color && (
                          <Div
                            css={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'relative'
                            }}
                          >
                            <CircleIcon fill="$on-primary" size={38} />
                            <CheckIcon fill="$on-primary" css={{ position: 'absolute' }} />
                          </Div>
                        )}
                      </Div>
                    ))}
                  </Grid>
                </BaseCard>
              </Grid>
              <Grid>
                <BaseCard
                  css={{
                    '& .BaseCard-StateLayer': {
                      padding: '$lg',
                      display: 'flex',
                      gap: '$md'
                    }
                  }}
                >
                  <Div css={{ display: 'flex', gap: '$md', flexDirection: 'column' }}>
                    <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.uploadImage.title}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.uploadImage.subTitle}
                    </Typography>
                  </Div>
                  <Div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '$3xl'
                    }}
                  >
                    <FormMediaInput
                      name="urlLogo"
                      control={control}
                      accept=".png,.svg"
                      htmlFor="logo"
                      alt="logo"
                      label={
                        i18n().modules.hub.whiteLabel.page.customization.uploadImage.inputs.logo
                      }
                      height={100}
                      width={200}
                      css={{
                        maxWidth: 200
                      }}
                      errorText={errors.urlLogo?.message}
                    />

                    <FormMediaInput
                      name="urlIcon"
                      control={control}
                      htmlFor="icon"
                      alt="icon"
                      label={
                        i18n().modules.hub.whiteLabel.page.customization.uploadImage.inputs.icone
                      }
                      height={100}
                      width={100}
                      css={{
                        maxWidth: 100
                      }}
                      errorText={errors.urlIcon?.message}
                    />
                  </Div>
                  <Grid css={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid xl={6}>
                      <Typography
                        variant="labelSmall"
                        css={{
                          textAlign: 'center',
                          color: '$on-surface-variant'
                        }}
                      >
                        {
                          i18n().modules.hub.whiteLabel.page.customization.uploadImage.inputs
                            .helpText
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </BaseCard>
              </Grid>
              <Grid>
                <BaseCard
                  css={{
                    '& .BaseCard-StateLayer': {
                      padding: '$lg',
                      display: 'flex',
                      gap: '$md'
                    }
                  }}
                >
                  <Div css={{ display: 'flex', gap: '$md', flexDirection: 'column' }}>
                    <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.customAddress.title}
                    </Typography>
                    <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
                      {i18n().modules.hub.whiteLabel.page.customization.customAddress.subTitle}
                    </Typography>
                  </Div>
                  <Grid css={{ display: 'flex', justifyContent: 'center' }}>
                    <Div css={{ display: 'flex', alignItems: 'flex-start', gap: '$2xs' }}>
                      <Typography
                        variant="bodyLarge"
                        css={{ color: '$on-surface', marginTop: '$md' }}
                      >
                        {i18n().modules.hub.whiteLabel.page.customization.customAddress.url}
                      </Typography>
                      <FormTextField
                        control={control}
                        name="alias"
                        variant="outlined"
                        label={
                          i18n().modules.hub.whiteLabel.page.customization.customAddress.inputs
                            .alias.label
                        }
                        inputProps={{ onChange: handleChangeText }}
                        errorText={errors.alias?.message}
                        trailingIcon={{
                          icon: (props: IconProps): JSX.Element =>
                            isFetching ? <Loader /> : <CheckCircleIcon {...props} fill="$success" />
                        }}
                      />
                    </Div>
                  </Grid>
                </BaseCard>
              </Grid>
            </>
          )}
        </Grid>
        <Button
          type="submit"
          css={{ position: 'fixed', right: 24, bottom: 24, marginBottom: '96px' }}
          isLoading={updateWhiteLabel.isPending || createWhiteLabel.isPending}
          LeadingIcon={<CheckCircleIcon size={18} />}
          disabled={hasAnyError}
        >
          {i18n().modules.hub.whiteLabel.page.customization.buttons.save}
        </Button>
      </FormContainer>
    </Main>
  )
}
