import { Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function HubDarkIcon(props: Omit<IconProps, 'fill' | 'ref'>): JSX.Element {
  return (
    <Svg
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ fill: 'none', pointerEvents: 'none', width: 34, height: 34 }}
      {...props}
    >
      <path
        d="M22.7014 2.02656C22.7142 1.98009 22.7206 1.89517 22.6277 1.83268C21.0046 0.749536 19.0305 0.199951 16.7601 0.199951C14.041 0.199951 11.7802 1.00911 10.0417 2.60338C8.28399 4.21369 7.39312 6.25981 7.39312 8.68407C7.39312 11.1083 8.11896 13.1384 9.5514 14.4715C10.3125 15.1798 11.3412 15.9136 12.6086 16.6491C12.6887 16.6955 12.7576 16.6667 12.7912 16.6458C12.8249 16.625 12.8826 16.5737 12.8746 16.4792C12.764 15.0019 12.7416 13.648 12.8105 12.4543C12.8137 12.403 12.7896 12.3517 12.7496 12.3149C11.6472 11.3375 11.2178 10.2912 11.2178 8.58473C11.2178 5.81597 13.5796 3.72819 16.712 3.72819C17.0229 3.72819 17.3321 3.74421 17.6285 3.77626C17.6734 3.78107 17.7151 3.77306 17.7503 3.75062C17.8224 3.70736 17.8961 3.6625 17.9714 3.62084C19.4632 2.76041 21.0014 2.2669 22.5428 2.15635C22.6533 2.14833 22.6902 2.07142 22.703 2.02496L22.7014 2.02656Z"
        fill="#E0C0F1"
      />
      <path
        d="M24.1771 31.3933C25.9348 29.783 26.8257 27.7369 26.8257 25.3126C26.8257 22.8884 26.0998 20.8583 24.6674 19.5252C23.9063 18.8169 22.8776 18.0831 21.6102 17.3476C21.5301 17.3012 21.4612 17.33 21.4276 17.3509C21.3939 17.3717 21.3362 17.423 21.3443 17.5175C21.4548 18.9948 21.4772 20.3487 21.4083 21.5424C21.4051 21.5937 21.4292 21.645 21.4692 21.6818C22.5716 22.6592 23.001 23.7055 23.001 25.412C23.001 28.1807 20.6392 30.2685 17.5068 30.2685C17.1959 30.2685 16.8867 30.2525 16.5903 30.2204C16.5822 30.2204 16.5742 30.2204 16.5662 30.2204C16.531 30.2204 16.4973 30.2301 16.4685 30.2477C16.3964 30.2909 16.3227 30.3342 16.2474 30.3775C14.7572 31.2379 13.219 31.7314 11.676 31.842C11.5655 31.85 11.5286 31.9269 11.5158 31.9733C11.503 32.0198 11.4966 32.1047 11.5895 32.1672C13.211 33.2504 15.1851 33.7999 17.4555 33.7999C20.1746 33.7999 22.4354 32.9908 24.1739 31.3965L24.1771 31.3933Z"
        fill="#E0C0F1"
      />
      <path
        d="M1.34768 19.6405C1.30122 19.6533 1.22431 19.6902 1.2163 19.8024C1.08972 21.7491 1.60085 23.7328 2.73527 25.6988C4.09401 28.0525 5.92542 29.6068 8.17504 30.3166C10.4487 31.0328 12.6663 30.7812 14.7653 29.5699C16.9364 28.3169 18.2598 26.7146 18.6989 24.8079C18.9328 23.7937 19.0514 22.5375 19.0562 21.0714C19.0562 20.9784 18.9969 20.9336 18.9633 20.9159C18.928 20.8967 18.8559 20.8727 18.7774 20.9272C17.5532 21.762 16.3916 22.4573 15.3245 22.9957C15.2796 23.0181 15.2459 23.0646 15.2347 23.1191C14.9399 24.5628 14.2477 25.4568 12.7704 26.3109C10.3718 27.6952 7.38351 26.6938 5.81647 23.9811C5.66105 23.7119 5.52004 23.4363 5.39987 23.164C5.38225 23.1223 5.35341 23.0919 5.31816 23.071C5.24445 23.0294 5.16914 22.9877 5.09544 22.9444C3.60371 22.084 2.4084 20.9977 1.54156 19.7174C1.47907 19.6245 1.39415 19.6325 1.34768 19.6453V19.6405Z"
        fill="white"
      />
      <path
        d="M26.0422 3.68012C23.7685 2.9639 21.5509 3.21546 19.4519 4.42679C17.2808 5.67978 15.9574 7.28207 15.5183 9.18879C15.2844 10.203 15.1658 11.4592 15.161 12.9253C15.161 13.0183 15.2203 13.0631 15.254 13.0808C15.2892 13.1 15.3613 13.124 15.4398 13.0695C16.664 12.2347 17.8256 11.5394 18.8927 11.001C18.9376 10.9785 18.9713 10.9321 18.9825 10.8776C19.2773 9.43394 19.9695 8.53987 21.4468 7.68585C23.8454 6.30147 26.8337 7.3029 28.4007 10.0156C28.5562 10.2848 28.6972 10.5604 28.8173 10.8327C28.8205 10.8408 28.8237 10.8472 28.8285 10.8536C28.8462 10.884 28.8702 10.9097 28.9006 10.9257C28.9744 10.9673 29.0497 11.009 29.1234 11.0523C30.6135 11.9127 31.8104 12.999 32.6772 14.2793C32.7397 14.3722 32.8246 14.3642 32.8711 14.353C32.9176 14.3402 32.9945 14.3033 33.0025 14.1911C33.1291 12.2444 32.618 10.2607 31.4835 8.29471C30.1248 5.93935 28.2934 4.38673 26.0438 3.67692L26.0422 3.68012Z"
        fill="white"
      />
      <path
        d="M27.2791 29.3264C27.3128 29.36 27.3849 29.4081 27.4842 29.3584C29.2339 28.4948 30.6952 27.0607 31.8312 25.0947C33.19 22.741 33.621 20.3776 33.1099 18.0751C32.5939 15.747 31.2672 13.9524 29.1666 12.7411C26.9955 11.4881 24.9462 11.1436 23.0763 11.7156C22.0813 12.02 20.9325 12.5456 19.6618 13.2746C19.5817 13.3211 19.5721 13.3948 19.5737 13.4333C19.5737 13.4733 19.5897 13.547 19.6763 13.5887C21.011 14.2312 22.1935 14.8897 23.1933 15.5451C23.235 15.5723 23.2926 15.5787 23.3439 15.5611C24.7411 15.0948 25.8627 15.2454 27.34 16.0995C29.7386 17.4838 30.3651 20.5731 28.7997 23.2873C28.6443 23.5565 28.476 23.8161 28.2998 24.0564C28.2726 24.0933 28.2597 24.1333 28.2613 24.1734C28.2629 24.2583 28.2645 24.3432 28.2645 24.4298C28.2645 26.1522 27.9216 27.7305 27.2455 29.1213C27.1974 29.2206 27.2455 29.2927 27.2791 29.3248V29.3264Z"
        fill="white"
      />
      <path
        d="M1.10894 15.9216C1.62488 18.2497 2.95158 20.0443 5.05218 21.2556C7.22328 22.5086 9.2726 22.8531 11.1425 22.2811C12.1375 21.9767 13.2863 21.4511 14.557 20.7221C14.6371 20.6756 14.6467 20.6019 14.6451 20.5634C14.6451 20.5234 14.6291 20.4497 14.5425 20.408C13.2078 19.7655 12.0253 19.107 11.0255 18.4516C10.9838 18.4244 10.9262 18.418 10.8749 18.4356C9.4777 18.9019 8.3561 18.7513 6.87879 17.8972C4.48016 16.5129 3.85366 13.4236 5.4191 10.7094C5.57452 10.4402 5.74276 10.1806 5.91901 9.94027C5.92382 9.93386 5.92863 9.92745 5.93183 9.92104C5.94946 9.8906 5.95907 9.85695 5.95747 9.8233C5.95587 9.73838 5.95427 9.65346 5.95427 9.56693C5.95427 7.84607 6.29715 6.26782 6.97172 4.87543C7.01979 4.77609 6.97172 4.70399 6.93807 4.67194C6.90442 4.63829 6.83232 4.59022 6.73298 4.63989C4.98328 5.50353 3.52199 6.93758 2.38597 8.90358C1.02723 11.2557 0.597813 13.6175 1.10894 15.9216Z"
        fill="white"
      />
      <path
        d="M22.7014 2.02656C22.7142 1.98009 22.7206 1.89517 22.6277 1.83268C21.0046 0.749536 19.0305 0.199951 16.7601 0.199951C14.041 0.199951 11.7802 1.00911 10.0417 2.60338C8.28399 4.21369 7.39312 6.25981 7.39312 8.68407C7.39312 11.1083 8.11896 13.1384 9.5514 14.4715C10.3125 15.1798 11.3412 15.9136 12.6086 16.6491C12.6887 16.6955 12.7576 16.6667 12.7912 16.6458C12.8249 16.625 12.8826 16.5737 12.8746 16.4792C12.764 15.0019 12.7416 13.648 12.8105 12.4543C12.8137 12.403 12.7896 12.3517 12.7496 12.3149C11.6472 11.3375 11.2178 10.2912 11.2178 8.58473C11.2178 5.81597 13.5796 3.72819 16.712 3.72819C17.0229 3.72819 17.3321 3.74421 17.6285 3.77626C17.6734 3.78107 17.7151 3.77306 17.7503 3.75062C17.8224 3.70736 17.8961 3.6625 17.9714 3.62084C19.4632 2.76041 21.0014 2.2669 22.5428 2.15635C22.6533 2.14833 22.6902 2.07142 22.703 2.02496L22.7014 2.02656Z"
        fill="white"
      />
      <path
        d="M24.1771 31.3933C25.9348 29.783 26.8257 27.7369 26.8257 25.3126C26.8257 22.8884 26.0998 20.8583 24.6674 19.5252C23.9063 18.8169 22.8776 18.0831 21.6102 17.3476C21.5301 17.3012 21.4612 17.33 21.4276 17.3509C21.3939 17.3717 21.3362 17.423 21.3443 17.5175C21.4548 18.9948 21.4772 20.3487 21.4083 21.5424C21.4051 21.5937 21.4292 21.645 21.4692 21.6818C22.5716 22.6592 23.001 23.7055 23.001 25.412C23.001 28.1807 20.6392 30.2685 17.5068 30.2685C17.1959 30.2685 16.8867 30.2525 16.5903 30.2204C16.5822 30.2204 16.5742 30.2204 16.5662 30.2204C16.531 30.2204 16.4973 30.2301 16.4685 30.2477C16.3964 30.2909 16.3227 30.3342 16.2474 30.3775C14.7572 31.2379 13.219 31.7314 11.676 31.842C11.5655 31.85 11.5286 31.9269 11.5158 31.9733C11.503 32.0198 11.4966 32.1047 11.5895 32.1672C13.211 33.2504 15.1851 33.7999 17.4555 33.7999C20.1746 33.7999 22.4354 32.9908 24.1739 31.3965L24.1771 31.3933Z"
        fill="white"
      />
      <path
        d="M1.34768 19.6405C1.30122 19.6533 1.22431 19.6902 1.2163 19.8024C1.08972 21.7491 1.60085 23.7328 2.73527 25.6988C4.09401 28.0525 5.92542 29.6068 8.17504 30.3166C10.4487 31.0328 12.6663 30.7812 14.7653 29.5699C16.9364 28.3169 18.2598 26.7146 18.6989 24.8079C18.9328 23.7937 19.0514 22.5375 19.0562 21.0714C19.0562 20.9784 18.9969 20.9336 18.9633 20.9159C18.928 20.8967 18.8559 20.8727 18.7774 20.9272C17.5532 21.762 16.3916 22.4573 15.3245 22.9957C15.2796 23.0181 15.2459 23.0646 15.2347 23.1191C14.9399 24.5628 14.2477 25.4568 12.7704 26.3109C10.3718 27.6952 7.38351 26.6938 5.81647 23.9811C5.66105 23.7119 5.52004 23.4363 5.39987 23.164C5.38225 23.1223 5.35341 23.0919 5.31816 23.071C5.24445 23.0294 5.16914 22.9877 5.09544 22.9444C3.60371 22.084 2.4084 20.9977 1.54156 19.7174C1.47907 19.6245 1.39415 19.6325 1.34768 19.6453V19.6405Z"
        fill="white"
      />
      <path
        d="M26.0422 3.68012C23.7685 2.9639 21.5509 3.21546 19.4519 4.42679C17.2808 5.67978 15.9574 7.28207 15.5183 9.18879C15.2844 10.203 15.1658 11.4592 15.161 12.9253C15.161 13.0183 15.2203 13.0631 15.254 13.0808C15.2892 13.1 15.3613 13.124 15.4398 13.0695C16.664 12.2347 17.8256 11.5394 18.8927 11.001C18.9376 10.9785 18.9713 10.9321 18.9825 10.8776C19.2773 9.43394 19.9695 8.53987 21.4468 7.68585C23.8454 6.30147 26.8337 7.3029 28.4007 10.0156C28.5562 10.2848 28.6972 10.5604 28.8173 10.8327C28.8205 10.8408 28.8237 10.8472 28.8285 10.8536C28.8462 10.884 28.8702 10.9097 28.9006 10.9257C28.9744 10.9673 29.0497 11.009 29.1234 11.0523C30.6135 11.9127 31.8104 12.999 32.6772 14.2793C32.7397 14.3722 32.8246 14.3642 32.8711 14.353C32.9176 14.3402 32.9945 14.3033 33.0025 14.1911C33.1291 12.2444 32.618 10.2607 31.4835 8.29471C30.1248 5.93935 28.2934 4.38673 26.0438 3.67692L26.0422 3.68012Z"
        fill="white"
      />
      <path
        d="M27.2791 29.3264C27.3128 29.36 27.3849 29.4081 27.4842 29.3584C29.2339 28.4948 30.6952 27.0607 31.8312 25.0947C33.19 22.741 33.621 20.3776 33.1099 18.0751C32.5939 15.747 31.2672 13.9524 29.1666 12.7411C26.9955 11.4881 24.9462 11.1436 23.0763 11.7156C22.0813 12.02 20.9325 12.5456 19.6618 13.2746C19.5817 13.3211 19.5721 13.3948 19.5737 13.4333C19.5737 13.4733 19.5897 13.547 19.6763 13.5887C21.011 14.2312 22.1935 14.8897 23.1933 15.5451C23.235 15.5723 23.2926 15.5787 23.3439 15.5611C24.7411 15.0948 25.8627 15.2454 27.34 16.0995C29.7386 17.4838 30.3651 20.5731 28.7997 23.2873C28.6443 23.5565 28.476 23.8161 28.2998 24.0564C28.2726 24.0933 28.2597 24.1333 28.2613 24.1734C28.2629 24.2583 28.2645 24.3432 28.2645 24.4298C28.2645 26.1522 27.9216 27.7305 27.2455 29.1213C27.1974 29.2206 27.2455 29.2927 27.2791 29.3248V29.3264Z"
        fill="white"
      />
      <path
        d="M1.10894 15.9216C1.62488 18.2497 2.95158 20.0443 5.05218 21.2556C7.22328 22.5086 9.2726 22.8531 11.1425 22.2811C12.1375 21.9767 13.2863 21.4511 14.557 20.7221C14.6371 20.6756 14.6467 20.6019 14.6451 20.5634C14.6451 20.5234 14.6291 20.4497 14.5425 20.408C13.2078 19.7655 12.0253 19.107 11.0255 18.4516C10.9838 18.4244 10.9262 18.418 10.8749 18.4356C9.4777 18.9019 8.3561 18.7513 6.87879 17.8972C4.48016 16.5129 3.85366 13.4236 5.4191 10.7094C5.57452 10.4402 5.74276 10.1806 5.91901 9.94027C5.92382 9.93386 5.92863 9.92745 5.93183 9.92104C5.94946 9.8906 5.95907 9.85695 5.95747 9.8233C5.95587 9.73838 5.95427 9.65346 5.95427 9.56693C5.95427 7.84607 6.29715 6.26782 6.97172 4.87543C7.01979 4.77609 6.97172 4.70399 6.93807 4.67194C6.90442 4.63829 6.83232 4.59022 6.73298 4.63989C4.98328 5.50353 3.52199 6.93758 2.38597 8.90358C1.02723 11.2557 0.597813 13.6175 1.10894 15.9216Z"
        fill="white"
      />
    </Svg>
  )
}
