import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function MonitooraLogo(props: Omit<IconProps, 'fill' | 'ref'>): JSX.Element {
  return (
    <Svg
      width="214"
      height="39"
      viewBox="0 0 214 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M146.31 18.8136C146.31 16.2256 147.195 14.0803 148.966 12.3436C150.737 10.6069 152.882 9.72151 155.436 9.72151C157.99 9.72151 160.135 10.6069 161.906 12.3436C163.677 14.0803 164.562 16.2256 164.562 18.8136C164.562 21.4016 163.677 23.581 161.906 25.3177C160.135 27.0544 157.99 27.9057 155.436 27.9057C152.882 27.9057 150.737 27.0544 148.966 25.3177C147.195 23.581 146.31 21.4016 146.31 18.8136ZM148.83 18.8136C148.83 20.7546 149.443 22.3551 150.702 23.6491C151.962 24.909 153.529 25.556 155.436 25.556C157.343 25.556 158.909 24.909 160.169 23.6491C161.429 22.3551 162.042 20.7546 162.042 18.8136C162.042 16.8726 161.429 15.2721 160.169 14.0122C158.909 12.7182 157.343 12.0712 155.436 12.0712C153.529 12.0712 151.962 12.7182 150.702 14.0122C149.443 15.2721 148.83 16.8726 148.83 18.8136Z"
        css={{ fill: '#B971E0' }}
      />
      <Path
        d="M157.898 18.8136C157.898 16.2256 158.783 14.0803 160.554 12.3436C162.325 10.6069 164.47 9.72151 167.024 9.72151C169.578 9.72151 171.723 10.6069 173.494 12.3436C175.265 14.0803 176.15 16.2256 176.15 18.8136C176.15 21.4016 175.265 23.581 173.494 25.3177C171.723 27.0544 169.578 27.9057 167.024 27.9057C164.47 27.9057 162.325 27.0544 160.554 25.3177C158.783 23.581 157.898 21.4016 157.898 18.8136ZM160.418 18.8136C160.418 20.7546 161.031 22.3551 162.291 23.6491C163.551 24.909 165.117 25.556 167.024 25.556C168.931 25.556 170.497 24.909 171.757 23.6491C173.017 22.3551 173.63 20.7546 173.63 18.8136C173.63 16.8726 173.017 15.2721 171.757 14.0122C170.497 12.7182 168.931 12.0712 167.024 12.0712C165.117 12.0712 163.551 12.7182 162.291 14.0122C161.031 15.2721 160.418 16.8726 160.418 18.8136Z"
        css={{ fill: '#FCB116' }}
      />
      <Path
        d="M162.891 24.1786C162.6 24.5751 162.272 24.953 161.906 25.3121C161.686 25.5274 161.461 25.7291 161.23 25.9173C160.999 25.7291 160.773 25.5274 160.554 25.3121C160.188 24.953 159.86 24.5751 159.569 24.1786C159.776 24.0146 159.976 23.8362 160.169 23.6435C160.597 23.2042 160.95 22.7297 161.23 22.2185C161.51 22.7297 161.863 23.2042 162.291 23.6435C162.483 23.8362 162.683 24.0146 162.891 24.1786Z"
        css={{ fill: '#B971E0' }}
      />
      <Path
        d="M17.1284 12.3425C19.7655 12.3425 21.8943 14.4713 21.8943 17.1084C21.8943 19.7455 19.7655 21.8742 17.1284 21.8742C14.4913 21.8742 12.3626 19.7455 12.3626 17.1084C12.3626 14.4713 14.4913 12.3425 17.1284 12.3425Z"
        fill="#5D6675"
        css={{ fill: '#5D6675' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8415 2.49233C16.9699 -0.636116 22.0421 -0.636117 25.1706 2.49233L36.4997 13.8214C39.6281 16.9499 39.6281 22.0221 36.4997 25.1505L25.1706 36.4797C22.0421 39.6081 16.9699 39.6081 13.8415 36.4797L2.51235 25.1506C-0.616097 22.0221 -0.616095 16.9499 2.51235 13.8214L13.8415 2.49233ZM17.1284 23.9924C18.8335 23.9924 20.4009 23.3675 21.6083 22.3296L22.3496 21.5883C23.3875 20.3809 24.0124 18.8135 24.0124 17.1084C24.0124 13.3063 20.9305 10.2244 17.1284 10.2244C13.3263 10.2244 10.2444 13.3063 10.2444 17.1084C10.2444 20.9105 13.3263 23.9924 17.1284 23.9924ZM21.8942 22.6262V23.4628L27.1896 28.7476L28.7676 27.1696L23.4829 21.8742L22.6462 21.8742L21.8942 22.6262Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.1119 18.8136C81.1119 16.2256 81.9973 14.0803 83.768 12.3436C85.5388 10.6069 87.6841 9.72151 90.2381 9.72151C92.792 9.72151 94.9374 10.6069 96.7081 12.3436C98.4788 14.0803 99.3642 16.2256 99.3642 18.8136C99.3642 21.4016 98.4788 23.581 96.7081 25.3177C94.9374 27.0544 92.792 27.9057 90.2381 27.9057C87.6841 27.9057 85.5388 27.0544 83.768 25.3177C81.9973 23.581 81.1119 21.4016 81.1119 18.8136ZM85.5047 23.6491C84.2448 22.3551 83.6318 20.7546 83.6318 18.8136C83.6318 16.8726 84.2448 15.2721 85.5047 14.0122C86.7647 12.7182 88.3311 12.0712 90.2381 12.0712C92.145 12.0712 93.7114 12.7182 94.9714 14.0122C96.2313 15.2721 96.8443 16.8726 96.8443 18.8136C96.8443 20.7546 96.2313 22.3551 94.9714 23.6491C93.7114 24.909 92.145 25.556 90.2381 25.556C88.3311 25.556 86.7647 24.909 85.5047 23.6491Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        d="M49.5272 10.6409V27.8035H52.0471V17.5536C52.0471 14.6932 54.0903 12.7181 56.8145 12.7181C59.4342 12.7181 61.0778 14.3871 61.2 17.0588V27.8035H63.7199L63.7272 17.2587C63.8626 14.5583 65.8577 12.7181 68.4873 12.7181C71.2115 12.7181 72.8801 14.5229 72.8801 17.3833V24.0917C72.8801 27.531 74.8211 27.9056 76.1491 27.9056C76.5918 27.9056 76.9664 27.8375 77.2388 27.7353V25.6581C77.175 25.6661 77.1148 25.6741 77.058 25.6817C76.8725 25.7063 76.7222 25.7262 76.5918 25.7262C75.6043 25.7262 75.4 25.3176 75.4 23.9214V16.8385C75.4 12.9224 72.9822 10.3685 69.1343 10.3685C66.5803 10.3685 64.6393 11.6284 63.7199 13.5354L63.3443 14.3333C62.5202 11.869 60.411 10.3685 57.4615 10.3685C54.9076 10.3685 52.9665 11.6284 52.0471 13.5354V10.6409H49.5272Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        d="M103.95 27.8035V10.6409H106.469V13.5354C107.389 11.6284 109.33 10.3685 111.884 10.3685C115.732 10.3685 118.15 12.9224 118.15 16.8385V23.9214C118.15 25.3176 118.354 25.7262 119.341 25.7262C119.512 25.7262 119.716 25.6922 119.988 25.6581V27.7353C119.716 27.8375 119.341 27.9056 118.899 27.9056C117.571 27.9056 115.63 27.531 115.63 24.0917V17.3833C115.63 14.5229 113.961 12.7181 111.237 12.7181C108.513 12.7181 106.469 14.6932 106.469 17.5536V27.8035H103.95Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        d="M124.99 10.3344H127.509V23.8193C127.509 25.0792 127.85 25.6241 128.803 25.6241C129.076 25.6241 129.382 25.59 129.689 25.556V27.6332C129.28 27.8035 128.735 27.9056 128.088 27.9056C126.011 27.9056 124.99 26.6797 124.99 24.262V10.3344Z"
        fill="#5D6675"
      />
      <Path
        d="M125.058 6.99726C124.377 6.3162 124.377 5.22652 125.058 4.57952C125.739 3.89846 126.76 3.89846 127.441 4.57952C128.122 5.22652 128.122 6.3162 127.441 6.99726C126.76 7.64426 125.739 7.64426 125.058 6.99726Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.437 25.7239L142.437 27.5146C142.457 27.4715 142.469 27.4235 142.469 27.3736V25.8667C142.469 25.8167 142.458 25.7677 142.437 25.7239Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        d="M140.468 27.9014C141.073 27.9014 141.664 27.829 142.222 27.6886C142.318 27.6659 142.396 27.6 142.437 27.5146L142.437 25.7239C142.416 25.682 142.387 25.6447 142.35 25.6156C142.273 25.556 142.175 25.5305 142.081 25.5475C141.554 25.6539 140.975 25.7092 140.404 25.7092C138.829 25.7092 137.782 25.1559 137.782 22.4487V12.152H142.15C142.328 12.152 142.473 12.0073 142.473 11.8285V10.2536C142.473 10.0748 142.328 9.93007 142.15 9.93007H137.782V3.78781C137.782 3.60903 137.638 3.46431 137.459 3.46431H135.782C135.603 3.46431 135.458 3.60903 135.458 3.78781V22.453C135.458 26.1221 137.101 27.9056 140.472 27.9056L140.468 27.9014Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        d="M180.914 27.8035V10.6409H183.434V13.5354C184.353 11.6284 186.294 10.3685 188.848 10.3685L188.201 12.7181C185.477 12.7181 183.434 14.6932 183.434 17.5536V27.8035H180.914Z"
        css={{ fill: '#5D6675' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.548 12.7863V10.2323H211.068V23.5469C211.068 25.0452 211.408 25.6922 212.362 25.6922C212.634 25.6922 212.941 25.6582 213.247 25.6241V27.5311C212.839 27.7013 212.294 27.8035 211.647 27.8035C210.898 27.8035 210.285 27.6332 209.842 27.3268C209.195 26.816 208.786 25.9987 208.684 24.875C207.39 26.8841 205.313 27.9057 202.452 27.9057C200.035 27.9057 197.957 27.0544 196.289 25.3177C194.62 23.581 193.769 21.4016 193.769 18.8136C193.769 16.2256 194.62 14.0803 196.289 12.3436C197.957 10.6069 200.035 9.72151 202.452 9.72151C205.551 9.72151 207.56 11.0496 208.548 12.7863ZM208.854 18.8136C208.854 20.7546 208.241 22.3551 207.016 23.6491C205.824 24.909 204.223 25.556 202.452 25.556C200.682 25.556 199.217 24.909 198.026 23.6491C196.868 22.3551 196.289 20.7546 196.289 18.8136C196.289 16.8726 196.868 15.2721 198.026 14.0122C199.217 12.7182 200.614 12.0712 202.452 12.0712C204.291 12.0712 205.824 12.7182 207.016 14.0122C208.241 15.2721 208.854 16.8726 208.854 18.8136Z"
        css={{ fill: '#5D6675' }}
      />
    </Svg>
  )
}
