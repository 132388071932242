import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { MapIcon } from '@positivote/design-system/icons/Map'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { i18n } from '@/common/i18n'
import { SapServiceList } from '@/modules/billing/service-mapping/components/ServiceList'
import {
  ListServiceMappingHookParams,
  ServiceMappingStepperState
} from '@/modules/billing/service-mapping/contracts'
import {
  useCreateServiceMapping,
  useUpdateServiceMapping
} from '@/modules/billing/service-mapping/hooks'

interface ServiceMappingPreviewStepProps {
  stepperState: ServiceMappingStepperState
}

export const ServiceMappingPreviewStep = forwardRef(function ServiceMappingPreviewStep(
  { stepperState }: ServiceMappingPreviewStepProps,
  ref
): JSX.Element {
  const createServiceMapping = useCreateServiceMapping()
  const updateServiceMapping = useUpdateServiceMapping()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as {
    listServiceMappingParams: ListServiceMappingHookParams['model']
  } | null
  const listServiceMappingParams = locationState?.listServiceMappingParams

  const onSubmit = useCallback(
    (setIsLoading: (isLoading: boolean) => void): void => {
      if (stepperState.serviceData.form) {
        setIsLoading(true)
        const hookParams = {
          model: stepperState.serviceData.form,
          listServiceMappingParams,
          onSuccess: () => {
            setIsLoading(false)
            navigate(-1)
          },
          onError: () => {
            setIsLoading(false)
          }
        }
        if (params.id) {
          updateServiceMapping.mutate({
            ...hookParams,
            model: { ...hookParams.model, id: params.id }
          })
        } else {
          createServiceMapping.mutate(hookParams)
        }
      }
    },
    [
      createServiceMapping,
      listServiceMappingParams,
      navigate,
      params.id,
      stepperState.serviceData.form,
      updateServiceMapping
    ]
  )

  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        padding: '$lg',
        gap: '$lg',
        borderRadius: '$xl',
        borderStyle: 'solid',
        borderWidth: '$thin',
        borderColor: '$outline-variant',
        '@sm': { padding: '$md' }
      }}
    >
      <IconWrapper size={72} css={{ backgroundColor: '$primary-container' }}>
        <MapIcon size={40} fill="$on-primary-container" />
      </IconWrapper>
      <Div css={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '$md' }}>
        <Typography
          data-testid="Typography-applicationName"
          variant="titleLarge"
          css={{ color: '$on-surface' }}
        >
          {stepperState.serviceData.form?.application.name}
        </Typography>
        <Div css={{ display: 'flex', alignItems: 'center', gap: '$4xs' }}>
          <Typography
            data-testid="Typography-hubIdLabel"
            variant="labelLarge"
            css={{ color: '$on-surface' }}
          >
            {i18n().modules.billing.serviceMapping.pages.form.stepper.preview.hubId}
          </Typography>
          <Typography
            data-testid="Typography-hubIdValue"
            variant="bodyLarge"
            css={{ color: '$on-surface' }}
          >
            {stepperState.serviceData.form?.application.id}
          </Typography>
        </Div>
        <Divider />
        <SapServiceList sapServices={stepperState.serviceData.form?.sapServices ?? []} />
      </Div>
    </Div>
  )
})
