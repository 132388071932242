import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListCityHookParams,
  ListCityHookResult,
  ListStateHookResult,
  ListStatesHookParams
} from './contracts'
import { listCityService, listStateService } from './services'

const hookKey = 'locales'

export function useListState({
  queryOptions,
  onSuccess,
  onError
}: ListStatesHookParams): UseQueryResult<ListStateHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listState'],
    queryFn: async () => {
      try {
        const listedStates = await listStateService()
        onSuccess?.(listedStates)
        return listedStates
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListCity({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListCityHookParams): UseQueryResult<ListCityHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listCity', model],
    queryFn: async () => {
      try {
        const listedCities = await listCityService(model)
        onSuccess?.(listedCities)
        return listedCities
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
