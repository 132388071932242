import { Navigate, Route, Routes } from 'react-router-dom'

import { Enrollments } from '@/modules/hub/enrollments/pages'
import { Students } from '@/modules/hub/enrollments/pages/Form/Student'
import { Teacher } from '@/modules/hub/enrollments/pages/Form/Teacher/Teacher'

import { FormClassroom } from './pages/Form'
import { ListClassroom } from './pages/List'

export function ClassroomRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ListClassroom />} />
      <Route path="/classroom-users" element={<Enrollments />} />
      <Route path="/classroom-users/form-student" element={<Students />} />
      <Route path="/classroom-users/form-teacher" element={<Teacher />} />
      <Route path="/form" element={<FormClassroom />} />
      <Route path="/form/:id" element={<FormClassroom />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
