import {
  ListApplicationPermissionApiResult,
  ListLicenseApiResult,
  ListLicenseByOrganizationApiResult
} from './contracts'
import {
  ApplicationPermission,
  LicenseByOrganizationFormatted,
  LicenseFormatted
} from './contracts/models'

export function licenseApiResultSanitizer(license: ListLicenseApiResult): LicenseFormatted {
  return {
    id: license.id,
    app: {
      id: license.app.id,
      iconUrl: license.app.icon_url,
      name: license.app.name,
      shortDescription: license.app.short_description,
      totalAvailableLicenses: license.app.total_available_licenses,
      totalLicenses: license.app.total_licenses,
      totalStudents: license.app.total_students,
      totalLicensePercentFomated:
        (license.app.total_licenses * 100) / license.app.total_available_licenses
    },
    settings: license.settings.map((setting) => ({
      id: setting.id,
      isActive: setting.is_active,
      name: setting.name,
      stageId: setting.stage_id,
      students: setting.students
    }))
  }
}

export function licenseByOrganizationApiResultSanitizer(
  licenses: ListLicenseByOrganizationApiResult['data']
): LicenseByOrganizationFormatted[] {
  return licenses.map((license) => ({
    id: license.license_id,
    applicationId: license.app_id,
    name: license.app_name,
    icon: license.app_icon_url,
    description: license.app_short_description,
    total: license.total,
    used: license.used,
    percentUsed: (license.used * 100) / license.total
  }))
}

export function applicationPermissionApiResultSanitizer(
  applicationPermissions: ListApplicationPermissionApiResult[]
): ApplicationPermission[] {
  return applicationPermissions.map((permission) => ({
    ...permission,
    anonymizedExample: permission.anonymized_example
  }))
}
