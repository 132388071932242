import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { InclusionTeachersStepperState } from '@/modules/hub/classroom/contracts/forms'
import { ClassroomFormatted } from '@/modules/hub/classroom/contracts/models'

import { EnrollmentTeacherStep } from './EnrollmentTeacherStep'
import { ListTeachersStep } from './ListTeachersStep'

export function Teacher(): JSX.Element {
  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
    beforeHistory: string
  } | null

  changePageTitle(
    i18n().modules.hub.classroom.pages.enrollments.form.pageTitleTeacher(
      locationState?.classroom.name ?? ''
    )
  )

  const [currentStepPosition, setCurrentStepPosition] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [stepperState, setStepperState] = useState<InclusionTeachersStepperState>({
    selectTeachers: {
      canGoNext: true,
      data: [],
      enrollments: [],
      removeEnrollments: [],
      teachersToEnroll: []
    },
    selectDiscipline: { form: null, canGoNext: true }
  })

  function onClickNext(newStep: StepProps): void {
    setStepperState((oldState) => ({
      ...oldState,
      selectTeachers: {
        ...oldState.selectTeachers
      }
    }))
    setCurrentStepPosition(newStep.position)
  }

  const stepperRef = useRef<{
    handleCancel: () => void
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  const integrationStepProps: StepProps[] = [
    {
      position: 1,
      title:
        i18n().modules.hub.classroom.pages.enrollments.teacher.form.stepper.selectTeachers
          .stepTitle,
      status: 'active',
      canGoNext: stepperState.selectTeachers.canGoNext,
      showPreviousButton: true,
      showBottomDivider: false,
      children: (
        <EnrollmentTeacherStep
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              selectTeachers: { ...oldState.selectTeachers, ...stepState }
            }))
          }
        />
      )
    },
    {
      position: 2,
      title:
        i18n().modules.hub.classroom.pages.enrollments.teacher.form.stepper.selectDiscipline
          .stepTitle,
      status: 'active',
      canGoNext: stepperState.selectDiscipline.canGoNext,
      showBottomDivider: false,
      children: (
        <ListTeachersStep
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              selectTeachers: { ...oldState.selectTeachers, ...stepState },
              selectDiscipline: { ...oldState.selectDiscipline, ...stepState }
            }))
          }
        />
      )
    }
  ]

  function handleCancel(): void {
    stepperRef.current?.handleCancel()
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.classroom.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-3)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs
                .educationLevelsAndClasses,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.schoolClasses,
            onClick: () => {
              navigate('/data-management/levels-and-classes/classroom')
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.enrollments.form.appBar.breadcrumbs.includeTeacher(
                locationState?.classroom.name ?? ''
              )
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: '$2xs',
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        <Div css={{ display: 'flex', gap: '$md', justifyContent: 'center' }}>
          {locationState?.schoolYear.name && (
            <Chip label={locationState.schoolYear.name} variant="filled" color="neutral" />
          )}
          {locationState?.classroom && (
            <>
              <Chip
                label={
                  i18n().modules.hub.classroom.fullClassLevel[
                    locationState.classroom.level.code
                  ].split('-')[0]
                }
                variant="filled"
                color="neutral"
              />
              {i18n().modules.hub.classroom.fullClassLevel[
                locationState.classroom.level.code
              ].split('-').length > 0 && (
                <Chip
                  label={
                    i18n().modules.hub.classroom.fullClassLevel[
                      locationState.classroom.level.code
                    ].split('-')[1]
                  }
                  variant="filled"
                  color="neutral"
                />
              )}
            </>
          )}
        </Div>
        <Stepper
          orientation="horizontal"
          position="start"
          stepOrientation="horizontal"
          isLoading={isLoading}
          nextButtonProps={{ onClick: onClickNext }}
          previousButtonProps={{
            onClick: (newStep) => setCurrentStepPosition(newStep.position)
          }}
          doneButtonProps={{
            label:
              i18n().modules.hub.classroom.pages.enrollments.teacher.form.stepper.selectDiscipline
                .done,
            onClick: () => stepperRef.current?.onSubmit(setIsLoading)
          }}
          cancelButtonProps={{
            onClick: () => handleCancel()
          }}
          mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
          currentStepPosition={currentStepPosition}
          steps={integrationStepProps}
          css={{
            flex: 1,
            padding: '$lg',
            overflowY: 'unset',
            '& .StepperHeader-Container': { width: 670, padding: '$none' },
            '& .Stepper-ChildrenWrapper': {
              display: 'flex',
              flex: 1,
              overflowY: 'unset'
            }
          }}
        />
      </Div>
    </Main>
  )
}
