import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useTheme } from '@positivote/design-system/hooks'
import { CodeIcon } from '@positivote/design-system/icons/Code'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { BotSeePhone } from '@/common/assets/icons/BotSeePhone'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { ChangePasswordForm } from '@/modules/hub/auth/contracts/forms'
import { useChangePassword } from '@/modules/hub/auth/hooks'
import { changePasswordFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { changePasswordValidationSchema } from '@/modules/hub/auth/validations'

export function ChangePassword(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.changePassword.pageTitle)

  const [isSuccessChangePassword, setIsSuccessChangePassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const emailParams = searchParams.get('email')
  const codeParams = searchParams.get('code')

  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const changePassword = useChangePassword()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ChangePasswordForm>({
    mode: 'onSubmit',
    defaultValues: { code: codeParams ?? '' },
    resolver: async (values, ...args) =>
      yupResolver(changePasswordValidationSchema)(changePasswordFormSanitizer(values), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  function onSubmit(model: ChangePasswordForm): void {
    if (emailParams) {
      changePassword.mutate({
        model: {
          code: model.code,
          newPassword: model.newPassword,
          email: emailParams
        },
        onSuccess: () => {
          setIsSuccessChangePassword(true)
        }
      })
    }
  }

  useEffect(() => {
    if (!emailParams) {
      navigate('/', { replace: true })
    }
  }, [emailParams, navigate])

  return (
    <BaseBackground>
      {isSuccessChangePassword ? (
        <Grid
          xl={4}
          md={6}
          xs={12}
          css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Typography
            variant={
              breakpoint === Breakpoint.sm
                ? 'headlineSmall'
                : breakpoint === Breakpoint.xs
                  ? 'titleLarge'
                  : 'headlineMedium'
            }
            css={{ color: '$on-surface', textAlign: 'center' }}
          >
            {i18n().modules.hub.auth.pages.changePassword.successChangePassword.title}
          </Typography>
          <Typography
            variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
            css={{
              color: '$on-surface-variant',
              textAlign: 'center',
              marginTop: '$lg',
              '@sm': { marginTop: '$md' }
            }}
          >
            {i18n().modules.hub.auth.pages.changePassword.successChangePassword.subTitle}
          </Typography>
          <Button
            variant="filled"
            css={{ width: '100%', marginTop: '$lg', '@sm': { marginTop: '$md' } }}
            onClick={() => navigate('/', { replace: true, state: { keepLoginProps: true } })}
          >
            {i18n().modules.hub.auth.pages.changePassword.successChangePassword.goBack}
          </Button>
        </Grid>
      ) : (
        <Grid xl={10} md={12}>
          <Grid spacing="$lg" css={{ justifyContent: 'center' }}>
            <Grid
              xl={6}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '@sm': { display: 'none' }
              }}
            >
              <BotSeePhone />
            </Grid>
            <Grid xl={6} sm={10} xs={12}>
              <FormContainer
                formHandleSubmit={handleSubmit}
                onSubmit={onSubmit}
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant={
                    breakpoint === Breakpoint.sm
                      ? 'headlineSmall'
                      : breakpoint === Breakpoint.xs
                        ? 'titleLarge'
                        : 'headlineMedium'
                  }
                  css={{
                    color: '$on-surface',
                    width: '100%',
                    textAlign: 'left',
                    '@sm': { textAlign: 'center' }
                  }}
                >
                  {i18n().modules.hub.auth.pages.changePassword.title}
                </Typography>
                <Typography
                  variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
                  css={{
                    color: '$on-surface-variant',
                    width: '100%',
                    textAlign: 'left',
                    marginTop: '$lg',
                    '@sm': { textAlign: 'center', marginTop: '$md' }
                  }}
                >
                  {i18n().modules.hub.auth.pages.changePassword.subTitle}
                </Typography>
                <Div
                  css={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '$lg',
                    '@sm': { marginTop: '$md' }
                  }}
                >
                  <IconWrapper
                    size="$2xl"
                    css={{
                      backgroundColor: '$primary-container',
                      marginTop: '$2xs',
                      marginRight: '$md',
                      '@xs': { display: 'none' }
                    }}
                  >
                    <CodeIcon fill="$on-primary-container" />
                  </IconWrapper>
                  <FormTextField
                    control={control}
                    name="code"
                    label={i18n().modules.hub.auth.pages.changePassword.inputs.recoverCode}
                    variant="outlined"
                    errorText={errors.code?.message}
                    inputProps={{ autoComplete: 'new-password', autoFocus: true, maxLength: 6 }}
                    required
                  />
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '$lg',
                    '@sm': { marginTop: '$md' }
                  }}
                >
                  <IconWrapper
                    size="$2xl"
                    css={{
                      backgroundColor: '$primary-container',
                      marginTop: '$2xs',
                      marginRight: '$md',
                      '@xs': { display: 'none' }
                    }}
                  >
                    <KeyIcon fill="$on-primary-container" />
                  </IconWrapper>
                  <FormTextField
                    control={control}
                    name="newPassword"
                    label={i18n().modules.hub.auth.pages.changePassword.inputs.newPassword}
                    variant="outlined"
                    errorText={errors.newPassword?.message}
                    inputProps={{
                      autoComplete: 'new-password',
                      type: showNewPassword ? 'text' : 'password'
                    }}
                    trailingIcon={{
                      icon: showNewPassword ? VisibilityIcon : VisibilityOffIcon,
                      onClick: () => setShowNewPassword((oldState) => !oldState),
                      changeIconOnError: false
                    }}
                    required
                  />
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '$lg',
                    '@sm': { marginTop: '$md' }
                  }}
                >
                  <IconWrapper
                    size="$2xl"
                    css={{
                      backgroundColor: '$primary-container',
                      marginTop: '$2xs',
                      marginRight: '$md',
                      '@xs': { display: 'none' }
                    }}
                  >
                    <KeyIcon fill="$on-primary-container" />
                  </IconWrapper>
                  <FormTextField
                    control={control}
                    name="confirmNewPassword"
                    label={i18n().modules.hub.auth.pages.changePassword.inputs.confirmNewPassword}
                    variant="outlined"
                    errorText={errors.confirmNewPassword?.message}
                    inputProps={{
                      autoComplete: 'new-password',
                      type: showConfirmPassword ? 'text' : 'password'
                    }}
                    trailingIcon={{
                      icon: showConfirmPassword ? VisibilityIcon : VisibilityOffIcon,
                      onClick: () => setShowConfirmPassword((oldState) => !oldState),
                      changeIconOnError: false
                    }}
                    required
                  />
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '$lg',
                    '@sm': { marginTop: '$md' },
                    '@xs': { flexDirection: 'column-reverse' }
                  }}
                >
                  <IconWrapper
                    size="$2xl"
                    css={{ marginRight: '$md', '@xs': { display: 'none' } }}
                  />
                  <Button
                    variant="outlined"
                    css={{ flex: 1 }}
                    onClick={() =>
                      navigate('/', { replace: true, state: { keepLoginProps: true } })
                    }
                    disabled={changePassword.isPending}
                  >
                    {i18n().modules.hub.auth.pages.recoverPassword.buttons.goBack}
                  </Button>
                  <Button
                    data-testid="continue"
                    type="submit"
                    variant="filled"
                    css={{
                      flex: 1,
                      marginLeft: '$md',
                      '@xs': { marginLeft: '$none', marginBottom: '$2xs' }
                    }}
                    disabled={hasAnyError}
                    isLoading={changePassword.isPending}
                  >
                    {i18n().modules.hub.auth.pages.changePassword.buttons.continue}
                  </Button>
                </Div>
              </FormContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </BaseBackground>
  )
}
