import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  GenerateInvoiceHookParams,
  ListSalesOrderHookParams,
  ListSalesOrderHookResult,
  ListYearSalesOrderHookParams,
  ListYearSalesOrderHookResult,
  SendInvoiceByEmailHookParams,
  SetListSalesOrderCacheHookParams,
  UpdateSalesOrderHookParams,
  UpdateSalesOrderHookResult
} from './contracts/hooks'
import {
  generateInvoiceSalesOrderService,
  listSalesOrderService,
  listYearSalesOrderService,
  sendInvoiceByEmailService,
  updateSalesOrderService
} from './services'

export const hookKey = 'sales-order'

export function useListYearSalesOrder({
  queryOptions,
  onSuccess,
  onError
}: ListYearSalesOrderHookParams): UseQueryResult<
  ListYearSalesOrderHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listYear'],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listYears = await listYearSalesOrderService()
        onSuccess?.(listYears)
        return listYears
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSalesOrder({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSalesOrderHookParams): UseQueryResult<ListSalesOrderHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listSalesOrder = await listSalesOrderService(model)
        onSuccess?.(listSalesOrder)
        return listSalesOrder
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useSendInvoiceByEmail(): UseMutationResult<
  void,
  ApplicationException,
  SendInvoiceByEmailHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const setListSalesOrderCache = useSetListSalesOrderCache()

  return useMutation({
    mutationKey: [hookKey, 'sendInvoiceByEmail'],
    mutationFn: async ({
      model,
      salesOrderId,
      onSuccess,
      onError
    }: SendInvoiceByEmailHookParams) => {
      try {
        await sendInvoiceByEmailService(model)
        onSuccess?.()
        addAlertMessage({
          severity: 'success',
          subTitle:
            i18n().modules.billing.salesOrders.components.generateInvoiceDialog.alert
              .sendInvoiceByEmail
        })
        if (model.shouldSave) {
          setListSalesOrderCache.mutate({
            model: { id: salesOrderId, emailsToInvoice: model.emailsToInvoice }
          })
        }
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useSetListSalesOrderCache(): UseMutationResult<
  void,
  ApplicationException,
  SetListSalesOrderCacheHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'setListCache'],
    mutationFn: async ({ model, onSuccess, onError }) => {
      try {
        const queriesData = queryClient.getQueriesData<ListSalesOrderHookResult>({
          queryKey: [hookKey, 'list']
        })
        queriesData.forEach(([queryKey, queryData]) => {
          if (queryData && queryData.registers.some((register) => register.id === model.id)) {
            queryClient.setQueryData<ListSalesOrderHookResult>(queryKey, {
              ...queryData,
              registers: queryData.registers.map((register) =>
                register.id === model.id ? { ...register, ...model } : register
              )
            })
          }
        })
        onSuccess?.()
        // DOCS: mutationFn must return a promise
        await Promise.resolve()
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateSalesOrder(): UseMutationResult<
  UpdateSalesOrderHookResult,
  ApplicationException,
  UpdateSalesOrderHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const setListSalesOrderCache = useSetListSalesOrderCache()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError }) => {
      try {
        const updatedSalesOrder = await updateSalesOrderService(model)
        setListSalesOrderCache.mutate({ model: updatedSalesOrder })
        onSuccess?.(updatedSalesOrder)
        addAlertMessage({
          severity: 'success',
          subTitle:
            i18n().modules.billing.salesOrders.components.generateInvoiceDialog.alert.updated
        })
        return updatedSalesOrder
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useGenerateInvoice(): UseMutationResult<
  void,
  ApplicationException,
  GenerateInvoiceHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [hookKey, 'generateInvoice'],
    mutationFn: async ({ model, onSuccess, onError }: GenerateInvoiceHookParams) => {
      try {
        const base64PDF = await generateInvoiceSalesOrderService(model)
        onSuccess?.(base64PDF)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
