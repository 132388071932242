import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Grid } from '@positivote/design-system/components/Grid'
import { TextArea } from '@positivote/design-system/components/TextArea'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { changePageTitle, safetyJSONParse } from '@/common/helpers'
import { BaseBackground } from '@/common/layouts/BaseBackground'

interface MockEdtechRequestForm {
  type: string
  client_id?: string
  school_alias?: string
  launch_method?: string
  redirect_uri?: string
  response_type?: string
  state?: string
  scope?: string
}

const mockEdtechRequestValidationSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  client_id: yup.string(),
  school_alias: yup.string(),
  launch_method: yup.string(),
  redirect_uri: yup.string(),
  response_type: yup.string(),
  state: yup.string().test({
    message: 'Precisa ser um JSON válido preenchido',
    test: (value) => {
      if (!value) {
        return true
      }
      const parsedJSON = safetyJSONParse(value) ?? {}
      if (!Object.keys(parsedJSON).length) {
        return false
      }
      return true
    }
  }),
  scope: yup.string()
})

export function MockEdtechRequest(): JSX.Element {
  changePageTitle('Mock Edtech Request')

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<MockEdtechRequestForm>({
    mode: 'onSubmit',
    defaultValues: {
      type: 'oauth2',
      client_id: 'hub.tools.estantemagica',
      school_alias: 'escola-do-bernardino',
      launch_method: 'post',
      redirect_uri: `${window.location.origin}/mock-edtech-response`,
      response_type: 'id_token',
      state: JSON.stringify(
        {
          client_id: 'hub.tools.estantemagica',
          client_secret: 'hub.tools.estantemagica',
          redirect_uri: `${window.location.origin}/mock-edtech-response`
        },
        null,
        2
      ),
      scope: 'user.info'
    },
    resolver: yupResolver(mockEdtechRequestValidationSchema)
  })
  const hasAnyError = !!Object.keys(errors).length

  function onSubmit(model: MockEdtechRequestForm): void {
    const parsedModel = { ...model } as unknown as Record<string, string>
    Reflect.deleteProperty(parsedModel, 'type')
    Reflect.deleteProperty(parsedModel, 'state')
    if (model.state) {
      parsedModel.state = JSON.stringify(JSON.parse(model.state))
    }
    const pathname = model.type === 'oauth2' ? 'oauth2' : model.type === 'sso' ? 'sso' : ''
    const queryParams = new URLSearchParams(parsedModel).toString()
    return window.location.assign(`${window.location.origin}/${pathname}?${queryParams}`)
  }

  return (
    <BaseBackground>
      <Grid
        xl={5}
        lg={6}
        md={7}
        sm={9}
        xs={12}
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography
          variant="headlineMedium"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          Preencha os dados da aplicação da edtech
        </Typography>
        <FormContainer
          formHandleSubmit={handleSubmit}
          onSubmit={onSubmit}
          css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <FormSelect
            control={control}
            name="type"
            label="type"
            variant="outlined"
            errorText={errors.type?.message}
            required
            css={{ marginBottom: '$sm' }}
            optionKeyField="key"
            optionTitleField="value"
            options={[
              { key: 'oauth2', value: 'oauth2' },
              { key: 'sso', value: 'sso' },
              { key: 'normal', value: 'normal' }
            ]}
          />
          <FormTextField
            control={control}
            name="client_id"
            label="client_id"
            variant="outlined"
            errorText={errors.client_id?.message}
            css={{ marginBottom: '$sm' }}
          />
          <FormTextField
            control={control}
            name="school_alias"
            label="school_alias"
            variant="outlined"
            errorText={errors.school_alias?.message}
            css={{ marginBottom: '$sm' }}
          />
          <FormSelect
            control={control}
            name="launch_method"
            label="launch_method"
            variant="outlined"
            errorText={errors.launch_method?.message}
            hasNoneOption
            css={{ marginBottom: '$sm' }}
            optionKeyField="key"
            optionTitleField="value"
            options={[
              { key: 'get', value: 'get' },
              { key: 'post', value: 'post' }
            ]}
          />
          <FormTextField
            control={control}
            name="redirect_uri"
            label="redirect_uri"
            variant="outlined"
            errorText={errors.redirect_uri?.message}
            css={{ marginBottom: '$sm' }}
          />
          <FormSelect
            control={control}
            name="response_type"
            label="response_type"
            variant="outlined"
            errorText={errors.response_type?.message}
            hasNoneOption
            css={{ marginBottom: '$sm' }}
            optionKeyField="key"
            optionTitleField="value"
            options={[
              { key: 'id_token', value: 'id_token' },
              { key: 'access_token', value: 'access_token' },
              { key: 'code', value: 'code' }
            ]}
          />
          <FormTextField
            control={control}
            name="state"
            label="state"
            variant="outlined"
            errorText={errors.state?.message}
            CustomInput={TextArea}
            css={{
              marginBottom: '$sm',
              '& .TextField-Input': { resize: 'none', height: '120px' }
            }}
          />
          <FormTextField
            control={control}
            name="scope"
            label="scope"
            variant="outlined"
            errorText={errors.scope?.message}
            css={{ marginBottom: '$sm' }}
          />
          <Button type="submit" variant="filled" disabled={hasAnyError} data-testid="submitButton">
            Enviar
          </Button>
        </FormContainer>
      </Grid>
    </BaseBackground>
  )
}
