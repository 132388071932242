import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { changePageTitle, emailRegex } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { PasswordLoginForm } from '@/modules/hub/auth/contracts/forms'
import { passwordLoginFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { passwordLoginValidationSchema } from '@/modules/hub/auth/validations'

interface PasswordFormLoginProps {
  scrollIntoView: () => void
  username: string
}

export function PasswordFormLogin({
  scrollIntoView,
  username
}: PasswordFormLoginProps): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.login.pageTitle)

  const navigate = useNavigate()
  const { createSession, isLoading, isLoadingGoogle, isLoadingMicrosoft, whiteLabel } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })

  const [errorMessage, setErrorMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<PasswordLoginForm>({
    mode: 'onSubmit',
    defaultValues: { username },
    resolver: async (values, ...args) =>
      yupResolver(passwordLoginValidationSchema)(passwordLoginFormSanitizer(values), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  function clearCreateSessionError(): void {
    if (errorMessage) {
      setErrorMessage('')
    }
  }

  function onSubmit(model: PasswordLoginForm): void {
    const isEmail = emailRegex.test(model.username)
    const orgId = whiteLabel?.org.id
    if (isEmail || orgId) {
      void createSession({
        model:
          orgId && !isEmail
            ? {
                authFlow: 'ESCOLA',
                username: model.username,
                password: model.password,
                orgId
              }
            : {
                authFlow: 'EMAIL',
                username: model.username,
                password: model.password
              },
        onError: ({ error }) => {
          if (error.code) {
            if (['LOGIN.USER_NOT_FOUND', 'LOGIN.PASSWORD_ERROR'].includes(error.code)) {
              setErrorMessage(i18n().modules.hub.auth.pages.login.errors.wrongUserOrPassword)
            } else if (error.code === 'LOGIN.PROFILE_NOT_FOUND') {
              setErrorMessage(i18n().modules.hub.auth.pages.login.errors.profileNotFound)
            } else {
              handleError({ error })
            }
          }
        }
      })
    } else {
      navigate('/organizations', { state: model })
    }
  }

  return (
    <FormContainer
      formHandleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading || isLoadingGoogle || isLoadingMicrosoft}
      css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Div css={{ display: 'flex' }}>
        <IconWrapper
          size="$2xl"
          css={{
            backgroundColor: '$primary-container',
            marginTop: '$2xs',
            marginRight: '$md',
            '@xs': { display: 'none' }
          }}
        >
          <PersonIcon fill="$on-primary-container" />
        </IconWrapper>
        <FormTextField
          control={control}
          name="username"
          label={i18n().modules.hub.auth.pages.login.inputs.username}
          variant="outlined"
          hasError={!!errorMessage}
          errorText={errors.username?.message}
          inputProps={{
            // TODO: put these props by default in text fields input
            autoComplete: 'new-password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: false,
            inputMode: 'email',
            onChange: clearCreateSessionError,
            onFocus: scrollIntoView
          }}
          required
        />
      </Div>
      <Div css={{ display: 'flex', marginTop: '$lg' }}>
        <IconWrapper
          size="$2xl"
          css={{
            backgroundColor: '$primary-container',
            marginTop: '$2xs',
            marginRight: '$md',
            '@xs': { display: 'none' }
          }}
        >
          <KeyIcon fill="$on-primary-container" />
        </IconWrapper>
        <FormTextField
          control={control}
          name="password"
          label={i18n().modules.hub.auth.pages.login.inputs.password}
          variant="outlined"
          errorText={errors.password?.message ?? errorMessage}
          css={
            // DOCS: its necessary due to ios issue
            // https://stackoverflow.com/questions/66151183/inputtype-password-ios-not-rendering-keyboard-safarichrome
            !isIOS
              ? undefined
              : showPassword
                ? undefined
                : {
                    '& .TextField-Input': {
                      WebkitTextSecurity: 'disc',
                      MozWebkitTextSecurity: 'disc',
                      MozTextSecurity: 'disc'
                    }
                  }
          }
          inputProps={{
            // DOCS: its necessary due to ios issue
            // https://stackoverflow.com/questions/66151183/inputtype-password-ios-not-rendering-keyboard-safarichrome
            type: isIOS ? undefined : showPassword ? 'text' : 'password',
            // TODO: put these props by default in text fields input
            autoComplete: 'new-password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: false,
            autoFocus: true,
            onChange: clearCreateSessionError,
            onFocus: scrollIntoView
          }}
          trailingIcon={{
            icon: showPassword ? VisibilityIcon : VisibilityOffIcon,
            onClick: () => setShowPassword((oldState) => !oldState),
            changeIconOnError: false
          }}
          required
        />
      </Div>
      <Div css={{ display: 'flex', marginTop: '$lg', '@xs': { flexDirection: 'column-reverse' } }}>
        <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
        <Button
          data-testid="forgotPassword"
          variant="outlined"
          css={{
            flex: 1,
            marginRight: '$md',
            '@xs': { marginRight: '$none', marginTop: '$2xs' }
          }}
          disabled={isLoading || isLoadingGoogle || isLoadingMicrosoft}
          onClick={() => navigate('/recover-password')}
        >
          {i18n().modules.hub.auth.pages.login.buttons.forgotPassword}
        </Button>
        <Button
          type="submit"
          variant="filled"
          css={{ flex: 1 }}
          disabled={hasAnyError || isLoadingGoogle || isLoadingMicrosoft}
          isLoading={isLoading}
          data-testid="submitButton"
        >
          {i18n().modules.hub.auth.pages.login.buttons.next}
        </Button>
      </Div>
    </FormContainer>
  )
}
