import { i18n } from '@/common/i18n'
import { apiService, hubApiService } from '@/common/services'

import {
  CreateCredentialApiParams,
  CreateCredentialApiResult,
  CreateOAuthTokenApiResult,
  ListDeviceApiParams,
  ListDeviceApiResult,
  ShowDeviceApiResult,
  ShowMetabaseDeviceApiParams,
  ShowMetabaseDeviceApiResult,
  ShowMetabaseDeviceDetailsApiParams,
  ShowMetabaseDeviceDetailsApiResult,
  ShowMetabaseGeneralInformationApiParams,
  ShowMetabaseGeneralInformationApiResult,
  ShowMyCredentialApiResult
} from './contracts/api'
import {
  CreateCredentialServiceParams,
  CreateCredentialServiceResult,
  CreateOAuthTokenServiceParams,
  CreateOAuthTokenServiceResult,
  ListDeviceServiceParams,
  ListDeviceServiceResult,
  ShowDeviceServiceParams,
  ShowDeviceServiceResult,
  ShowMetabaseDeviceDetailsServiceParams,
  ShowMetabaseDeviceDetailsServiceResult,
  ShowMetabaseDeviceServiceParams,
  ShowMetabaseDeviceServiceResult,
  ShowMetabaseGeneralInformationServiceParams,
  ShowMetabaseGeneralInformationServiceResult,
  ShowMyCredentialServiceResult
} from './contracts/services'
import {
  createOAuthTokenApiResultSanitizer,
  listDeviceApiParamsSanitizer,
  listDeviceApiResultSanitizer,
  showDeviceApiResultSanitizer,
  showMetabaseDeviceApiParamsSanitizer,
  showMetabaseDeviceDetailsApiParamsSanitizer,
  showMetabaseGeneralinformationApiParamsSanitizer
} from './sanitizers'

export async function showMyCredentialService(): Promise<ShowMyCredentialServiceResult> {
  const { data } = await hubApiService<null, ShowMyCredentialApiResult>({
    resource: i18n().modules.monitoora.resources.showMyCredential,
    method: 'get',
    url: '/ceu-dash/v1/credentials/my'
  })

  return data
}

export async function createCredentialService(
  params: CreateCredentialServiceParams
): Promise<CreateCredentialServiceResult> {
  const { data } = await hubApiService<CreateCredentialApiParams, CreateCredentialApiResult>({
    resource: i18n().modules.monitoora.resources.createCredential,
    method: 'post',
    url: '/ceu-dash/v1/credentials',
    body: params
  })

  return data
}

export async function listDeviceService(
  params: ListDeviceServiceParams
): Promise<ListDeviceServiceResult> {
  const apiParams = listDeviceApiParamsSanitizer(params)
  const { data } = await hubApiService<ListDeviceApiParams, ListDeviceApiResult>({
    resource: i18n().modules.monitoora.resources.listDevices,
    method: 'get',
    url: '/ceu-dash/v1/devices',
    params: apiParams
  })

  return listDeviceApiResultSanitizer(data)
}

export async function showDeviceService({
  serialNumber
}: ShowDeviceServiceParams): Promise<ShowDeviceServiceResult[]> {
  const { data } = await hubApiService<null, ShowDeviceApiResult[]>({
    resource: i18n().modules.monitoora.resources.embeddedDevices,
    method: 'get',
    url: `/ceu-dash/v1/devices/serialNumber/${serialNumber}`
  })

  return showDeviceApiResultSanitizer(data)
}

export async function showMetabaseGeneralInformationService(
  params: ShowMetabaseGeneralInformationServiceParams
): Promise<ShowMetabaseGeneralInformationServiceResult> {
  const apiParams = showMetabaseGeneralinformationApiParamsSanitizer(params)
  const { data } = await hubApiService<
    ShowMetabaseGeneralInformationApiParams,
    ShowMetabaseGeneralInformationApiResult
  >({
    resource: i18n().modules.monitoora.resources.embeddedGeneralInformation,
    method: 'post',
    url: '/v2/metabase/linkembedded',
    body: apiParams,
    baseURL: import.meta.env.VITE_METABASE_URL
  })

  return data
}

export async function showMetabaseDeviceService(
  params: ShowMetabaseDeviceServiceParams
): Promise<ShowMetabaseDeviceServiceResult> {
  const apiParams = showMetabaseDeviceApiParamsSanitizer(params)
  const { data } = await hubApiService<ShowMetabaseDeviceApiParams, ShowMetabaseDeviceApiResult>({
    resource: i18n().modules.monitoora.resources.listDevices,
    method: 'post',
    url: '/v2/metabase/linkembedded',
    body: apiParams,
    baseURL: import.meta.env.VITE_METABASE_URL
  })

  return data
}

export async function showMetabaseDeviceDetailsService(
  params: ShowMetabaseDeviceDetailsServiceParams
): Promise<ShowMetabaseDeviceDetailsServiceResult> {
  const apiParams = showMetabaseDeviceDetailsApiParamsSanitizer(params)
  const { data } = await hubApiService<
    ShowMetabaseDeviceDetailsApiParams,
    ShowMetabaseDeviceDetailsApiResult
  >({
    resource: i18n().modules.monitoora.resources.embeddedDeviceDetail,
    method: 'post',
    url: '/v2/metabase/linkembedded',
    body: apiParams,
    baseURL: import.meta.env.VITE_METABASE_URL
  })

  return data
}

export async function createOAuthTokenService(
  params: CreateOAuthTokenServiceParams
): Promise<CreateOAuthTokenServiceResult> {
  const oauthData = new URLSearchParams({
    code: params.code,
    client_id: params.clientId,
    client_secret: params.clientSecret,
    redirect_uri: `${window.location.origin}/callback`,
    grant_type: 'authorization_code'
  }).toString()

  const data = await apiService<string, CreateOAuthTokenApiResult>({
    resource: i18n().modules.monitoora.resources.embeddedDeviceDetail,
    method: 'post',
    url: '/token',
    baseURL: 'https://oauth2.googleapis.com',
    body: oauthData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })

  return createOAuthTokenApiResultSanitizer(data)
}
