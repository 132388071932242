import { i18n } from '@/common/i18n'
import { listBillingApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  GenerateInvoiceApiResult,
  ListSalesOrderApiParams,
  ListSalesOrderApiResult,
  ListYearSalesOrderApiResult,
  SendInvoiceByEmailApiParams,
  UpdateSalesOrderApiParams,
  UpdateSalesOrderApiResult
} from './contracts'
import {
  GenerateInvoiceServiceParams,
  ListSalesOrderServiceParams,
  ListSalesOrderServiceResult,
  ListYearSalesOrderServiceResult,
  SendInvoiceByEmailServiceParams,
  UpdateSalesOrderServiceParams,
  UpdateSalesOrderServiceResult
} from './contracts/services'
import { fromSalesOrderApiSanitizer } from './sanitizers'

export async function listYearSalesOrderService(): Promise<ListYearSalesOrderServiceResult> {
  const { data } = await hubApiService<undefined, ListYearSalesOrderApiResult>({
    resource: i18n().modules.billing.salesOrders.resources.listYear,
    method: 'get',
    url: '/billing/v1/order/years'
  })
  return data
}

export async function listSalesOrderService(
  params: ListSalesOrderServiceParams
): Promise<ListSalesOrderServiceResult> {
  const { data, headers } = await hubApiService<ListSalesOrderApiParams, ListSalesOrderApiResult>({
    resource: i18n().modules.billing.salesOrders.resources.list,
    method: 'get',
    url: '/billing/v1/order',
    params: {
      limit: params.perPage,
      search_by: params.searchBy,
      offset: params.page,
      order_month: params.orderMonth,
      order_status: params.orderStatus,
      order_year: params.orderYear,
      contract_id: params.contractId
    }
  })

  return listBillingApiResultSanitizer({
    headers,
    data: fromSalesOrderApiSanitizer(data.orders)
  })
}

export async function sendInvoiceByEmailService(
  params: SendInvoiceByEmailServiceParams
): Promise<void> {
  await hubApiService<SendInvoiceByEmailApiParams, null>({
    resource: i18n().modules.billing.salesOrders.resources.sendInvoiceByEmail,
    method: 'post',
    url: `/billing/v1/invoice/${params.id}/resend`,
    body: {
      emails_to_invoice: params.emailsToInvoice,
      is_save: params.shouldSave
    }
  })
}

export async function updateSalesOrderService({
  id,
  ...params
}: UpdateSalesOrderServiceParams): Promise<UpdateSalesOrderServiceResult> {
  let additionSum = 0
  let discountSum = 0
  let subtotalSum = 0

  const { data } = await hubApiService<UpdateSalesOrderApiParams, UpdateSalesOrderApiResult>({
    resource: i18n().modules.billing.salesOrders.resources.update,
    method: 'put',
    url: `/billing/v1/order/${id}`,
    body: {
      contract_id: params.contractId,
      order_date: params.orderDate,
      services: params.services.map((service) => {
        let serviceAdditionValue = 0
        let serviceDiscountValue = 0
        subtotalSum += service.subtotal
        additionSum += service.operation?.type === 'addition' ? service.operation.value : 0
        discountSum += service.operation?.type === 'discount' ? service.operation.value : 0
        serviceAdditionValue = service.operation?.type === 'addition' ? service.operation.value : 0
        serviceDiscountValue = service.operation?.type === 'discount' ? service.operation.value : 0
        return {
          app_sis_id: service.appSisId,
          app_sis_name: service.appSisName,
          licenses_month: service.licensesMonth,
          licenses: service.licenses,
          operation: service.operation,
          service_id: service.serviceId,
          student_value: service.studentValue,
          subtotal: service.subtotal,
          total: service.subtotal + serviceAdditionValue - serviceDiscountValue
        }
      }),
      status: params.status,
      total: subtotalSum + additionSum - discountSum
    }
  })

  return fromSalesOrderApiSanitizer([data.order])[0]
}

export async function generateInvoiceSalesOrderService({
  salesOrderId
}: GenerateInvoiceServiceParams): Promise<string> {
  const { data } = await hubApiService<undefined, GenerateInvoiceApiResult>({
    resource: i18n().modules.billing.salesOrders.resources.generateInvoice,
    method: 'get',
    url: `/billing/v1/invoice/${salesOrderId}/download-nfs`
  })
  return data.data
}
