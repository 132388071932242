import { ListHubApiResult, ListServiceResult } from '@/common/contracts'

export function listHubApiResultSanitizer<Model>(
  listHubApiResult: ListHubApiResult<Model>
): ListServiceResult<Model> {
  return {
    page: listHubApiResult.page ?? 1,
    perPage: listHubApiResult.content_range ?? 1,
    lastPage: listHubApiResult.total_pages ?? 1,
    total: listHubApiResult.total ?? 1,
    orderBy: listHubApiResult.orderBy ?? 'id',
    order: listHubApiResult.order ?? 'asc',
    registers: listHubApiResult.data
  }
}

export function listBillingApiResultSanitizer<Model>({
  data,
  headers
}: {
  data: Model[]
  headers: Record<string, string | undefined>
}): ListServiceResult<Model> {
  return {
    page: Number(headers['x-page'] ?? 1),
    perPage: Number(headers['x-per-page'] ?? 1),
    lastPage: Number(headers['x-pages'] ?? 1),
    total: Number(headers['x-total-count'] ?? 1),
    orderBy: headers['x-order-by'] ?? 'createdAt',
    order: headers['x-order'] === 'desc' ? 'desc' : 'asc',
    registers: data
  }
}
