import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

export function roleToPermissionRole(role: string): string | undefined {
  const roles: Record<string, string | undefined> = {
    aluno: 'student',
    responsavel: 'guardian',
    professor: 'teacher',
    edtech: undefined,
    instituicao: undefined,
    te: undefined,
    diretor: 'director',
    coordenador: 'coordinator',
    administrador: undefined,
    colaborador: 'staff',
    ...MONITOORA_ROLES.reduce(
      (prevRoles, monitooraRole) => ({ ...prevRoles, [monitooraRole]: undefined }),
      {}
    )
  }
  return roles[role]
}
