import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { StatusCodes } from '@/common/contracts'
import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  CreateCredentialHookParams,
  CreateCredentialHookResult,
  CreateOAuthTokenHookParams,
  CreateOAuthTokenHookResult,
  ListDeviceHookParams,
  ListDeviceHookResult,
  ShowDeviceHookParams,
  ShowDeviceHookResult,
  ShowMetabaseDeviceDetailsHookParams,
  ShowMetabaseDeviceDetailsHookResult,
  ShowMetabaseDeviceHookParams,
  ShowMetabaseDeviceHookResult,
  ShowMetabaseGeneralInformationHookParams,
  ShowMetabaseGeneralInformationHookResult,
  ShowMyCredentialHookParams,
  ShowMyCredentialHookResult
} from './contracts/hooks'
import {
  createCredentialService,
  createOAuthTokenService,
  listDeviceService,
  showDeviceService,
  showMetabaseDeviceDetailsService,
  showMetabaseDeviceService,
  showMetabaseGeneralInformationService,
  showMyCredentialService
} from './services'

export const hookKey = 'monitoora'

export function useShowMyCredential({
  queryOptions,
  onSuccess,
  onError
}: ShowMyCredentialHookParams): UseQueryResult<ShowMyCredentialHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  return useQuery({
    queryKey: [hookKey, 'showMyCredential'],
    queryFn: async () => {
      try {
        const credential = await showMyCredentialService()
        onSuccess?.(credential)
        return credential
      } catch (error) {
        const parsedError = error as ApplicationException
        parsedError.statusCode !== StatusCodes.NOT_FOUND && handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useCreateCredential(): UseMutationResult<
  CreateCredentialHookResult,
  ApplicationException,
  CreateCredentialHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'createCredential'],
    mutationFn: async ({ model, onSuccess, onError }: CreateCredentialHookParams) => {
      try {
        const credential = await createCredentialService(model)
        onSuccess?.(credential)
        void queryClient.invalidateQueries({ queryKey: [hookKey, 'showMyCredential'] })
        return credential
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListDevice({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListDeviceHookParams): UseQueryResult<ListDeviceHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listDevice', model],
    queryFn: async () => {
      try {
        const listDevices = await listDeviceService(model)
        onSuccess?.(listDevices)
        return listDevices
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}

export function useShowDevice({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowDeviceHookParams): UseQueryResult<ShowDeviceHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showDevice', model],
    queryFn: async () => {
      try {
        const deviceShown = await showDeviceService(model)
        onSuccess?.(deviceShown)
        return deviceShown
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}

export function useShowMetabaseGeneralInformation({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowMetabaseGeneralInformationHookParams): UseQueryResult<
  ShowMetabaseGeneralInformationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showMetabaseGeneralInformation', model],
    queryFn: async () => {
      try {
        const generalinformationUrl = await showMetabaseGeneralInformationService(model)
        onSuccess?.(generalinformationUrl)
        return generalinformationUrl
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}

export function useShowMetabaseDevice({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowMetabaseDeviceHookParams): UseQueryResult<
  ShowMetabaseDeviceHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showMetabaseDevice', model],
    queryFn: async () => {
      try {
        const deviceUrl = await showMetabaseDeviceService(model)
        onSuccess?.(deviceUrl)
        return deviceUrl
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}

export function useShowMetabaseDeviceDetails({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowMetabaseDeviceDetailsHookParams): UseQueryResult<
  ShowMetabaseDeviceDetailsHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showMetabaseDeviceDetails', model],
    queryFn: async () => {
      try {
        const deviceDetailsUrl = await showMetabaseDeviceDetailsService(model)
        onSuccess?.(deviceDetailsUrl)
        return deviceDetailsUrl
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}

export function useCreateOAuthToken(): UseMutationResult<
  CreateOAuthTokenHookResult,
  ApplicationException,
  CreateOAuthTokenHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [hookKey, 'createOAuthToken'],
    mutationFn: async ({ model, onSuccess, onError }: CreateOAuthTokenHookParams) => {
      try {
        const oauthToken = await createOAuthTokenService(model)
        onSuccess?.(oauthToken)
        return oauthToken
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
