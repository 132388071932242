import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { NotFoundException } from '@/common/exceptions'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  ServiceMappingServiceDataStepForm,
  ServiceMappingStepperState
} from '@/modules/billing/service-mapping/contracts'
import { useShowServiceMapping } from '@/modules/billing/service-mapping/hooks'

import { ServiceMappingPreviewStep } from './Preview'
import { ServiceMappingServiceDataStep } from './ServiceData'

export function ServiceMappingForm(): JSX.Element {
  const params = useParams()
  const isEditing = !!params.id
  changePageTitle(i18n().modules.billing.serviceMapping.pages.form.pageTitle(isEditing))

  const [isLoading, setIsLoading] = useState(false)
  const [currentStepPosition, setCurrentStepPosition] = useState(1)
  const [stepperState, setStepperState] = useState<ServiceMappingStepperState>({
    serviceData: { form: null, hasError: false, isValid: false, canGoNext: true },
    preview: { canGoNext: true }
  })
  const stepperRef = useRef<{
    validateServiceDataForm: () => ServiceMappingServiceDataStepForm | null
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  const navigate = useNavigate()
  const showServiceMapping = useShowServiceMapping({
    model: { id: params.id ?? '' },
    onError: ({ error }) => {
      if (error instanceof NotFoundException) {
        navigate('/not-found', { replace: true })
      }
    },
    queryOptions: { enabled: isEditing }
  })

  function onClickNext(newStep: StepProps): void {
    const serviceDataStepFormData = stepperRef.current?.validateServiceDataForm()
    if (!serviceDataStepFormData) {
      return
    }
    setStepperState((oldState) => ({
      ...oldState,
      serviceData: { ...oldState.serviceData, form: serviceDataStepFormData }
    }))
    setCurrentStepPosition(newStep.position)
  }

  useEffect(() => {
    if (showServiceMapping.data) {
      const { application, sapServices } = showServiceMapping.data
      setStepperState((oldData) => ({
        ...oldData,
        serviceData: { ...oldData.serviceData, form: { application, sapServices }, isValid: true }
      }))
    }
  }, [showServiceMapping.data])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.billing.serviceMapping.pages.form.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.billing.serviceMapping.pages.form.appBar.breadcrumbs.overview,
            onClick: () => navigate(-1)
          },
          {
            label:
              i18n().modules.billing.serviceMapping.pages.form.appBar.breadcrumbs[
                isEditing ? 'editService' : 'newService'
              ]
          }
        ]}
      />
      {showServiceMapping.isFetching && <WrappedLoader />}
      {(!isEditing || showServiceMapping.data) && (
        <Stepper
          orientation="horizontal"
          position="start"
          stepOrientation="horizontal"
          isLoading={isLoading}
          mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
          previousButtonProps={{
            onClick: (newStep) => setCurrentStepPosition(newStep.position)
          }}
          nextButtonProps={{ onClick: onClickNext }}
          cancelButtonProps={{ onClick: () => navigate(-1) }}
          doneButtonProps={{
            label:
              i18n().modules.billing.serviceMapping.pages.form.stepper.preview.doneButtonLabel(
                isEditing
              ),
            onClick: () => stepperRef.current?.onSubmit(setIsLoading)
          }}
          currentStepPosition={currentStepPosition}
          css={{
            padding: '$lg',
            '& .StepperHeader-Container': { width: 500 },
            '@sm': { padding: '$md' }
          }}
          steps={[
            {
              position: 1,
              title: i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.title,
              caption: undefined,
              status: stepperState.serviceData.hasError ? 'error' : 'active',
              canGoNext: stepperState.serviceData.canGoNext,
              children: (
                <ServiceMappingServiceDataStep
                  ref={stepperRef}
                  stepState={stepperState.serviceData}
                  setStepState={(stepState) =>
                    setStepperState((oldState) => ({
                      ...oldState,
                      serviceData: { ...oldState.serviceData, ...stepState }
                    }))
                  }
                />
              )
            },
            {
              position: 2,
              title: i18n().modules.billing.serviceMapping.pages.form.stepper.preview.title,
              caption: undefined,
              status:
                stepperState.serviceData.canGoNext && stepperState.serviceData.isValid
                  ? 'active'
                  : 'disabled',
              canGoNext: stepperState.preview.canGoNext,
              children: <ServiceMappingPreviewStep ref={stepperRef} stepperState={stepperState} />
            }
          ]}
        />
      )}
    </Main>
  )
}
