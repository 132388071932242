import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { useTheme } from '@positivote/design-system/hooks'
import { AccountBalanceIcon } from '@positivote/design-system/icons/AccountBalance'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { MAX_PER_PAGE } from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { INPUT_SCROLL_DELAY_IN_MS } from '@/modules/hub/auth/constants'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationLoginForm } from '@/modules/hub/auth/contracts/forms'
import { organizationLoginFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { organizationLoginValidationSchema } from '@/modules/hub/auth/validations'
import { useListShortOrganization } from '@/modules/hub/organizations/hooks'

export function OrganizationList(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.organizationList.pageTitle)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [searchText, setSearchText] = useState('')

  const { breakpoint } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as { username?: string; password?: string } | null
  const { createSession, isLoading: authIsLoading, ssoApplication } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })

  const listShortOrganizationEnabled =
    !!locationState?.username && !!locationState.password && !!searchText
  const { data, isFetching: organizationIsFetching } = useListShortOrganization({
    model: { perPage: MAX_PER_PAGE, callPublic: true, search: searchText || undefined },
    queryOptions: {
      enabled: listShortOrganizationEnabled
    }
  })
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<OrganizationLoginForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(organizationLoginValidationSchema)(
        organizationLoginFormSanitizer(values),
        ...args
      )
  })
  const hasAnyError = !!Object.keys(errors).length
  const organizationIsLoadingParsed = organizationIsFetching && listShortOrganizationEnabled

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchText(value)
    })()
  }

  function onSubmit(model: OrganizationLoginForm): void {
    if (locationState?.username && locationState.password) {
      void createSession({
        model: {
          authFlow: 'ESCOLA',
          username: locationState.username,
          password: locationState.password,
          orgId: parseInt(model.organizationId)
        },
        onError: ({ error }) => {
          if (error.code === 'LOGIN.USER_NOT_FOUND') {
            setError('organizationId', {
              message: i18n().modules.hub.auth.pages.login.errors.wrongUserOrPassword
            })
          } else {
            handleError({ error })
          }
        }
      })
    }
  }

  useEffect(() => {
    if (!locationState?.username || !locationState.password) {
      navigate('/', { replace: true })
    }
  }, [locationState, navigate])

  return (
    <BaseBackground>
      <Grid
        ref={wrapperRef}
        xl={5}
        lg={6}
        md={7}
        sm={9}
        xs={12}
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {ssoApplication && (
          <Image
            alt={ssoApplication.name}
            src={ssoApplication.iconUrl}
            css={{
              width: 96,
              height: 96,
              borderRadius: '$full',
              borderStyle: 'solid',
              borderColor: '$outline-variant',
              borderWidth: '$thin',
              objectFit: 'cover',
              marginBottom: '$lg',
              '@sm': { marginBottom: '$md' }
            }}
          />
        )}
        <Typography
          variant={
            breakpoint === Breakpoint.sm
              ? 'headlineSmall'
              : breakpoint === Breakpoint.xs
                ? 'titleLarge'
                : 'headlineMedium'
          }
          css={{ color: '$on-surface', textAlign: 'center' }}
        >
          {i18n().modules.hub.auth.pages.organizationList.title}
        </Typography>
        <FormContainer
          formHandleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={authIsLoading}
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '$lg',
            '@sm': { marginTop: '$md' }
          }}
        >
          <Div css={{ display: 'flex' }}>
            <IconWrapper
              size="$2xl"
              css={{
                backgroundColor: '$primary-container',
                marginTop: '$2xs',
                marginRight: '$md',
                '@xs': { display: 'none' }
              }}
            >
              <AccountBalanceIcon fill="$on-primary-container" />
            </IconWrapper>
            <FormAutocomplete
              control={control}
              name="organizationId"
              label={i18n().modules.hub.auth.pages.organizationList.searchInput.label}
              options={data?.registers ?? []}
              optionKeyField="id"
              optionTitleField="name"
              optionSubTitleField="address"
              variant="outlined"
              leadingIcon={{ icon: SearchIcon }}
              errorText={errors.organizationId?.message}
              isLoading={organizationIsLoadingParsed}
              inputProps={{
                // TODO: put these props by default in text fields input
                autoComplete: 'new-password',
                autoCapitalize: 'off',
                autoCorrect: 'off',
                spellCheck: false,
                autoFocus: true,
                onChange: handleChangeSearchText,
                onFocus: () =>
                  debounceEvent(
                    () => wrapperRef.current?.scrollIntoView({ behavior: 'smooth' }),
                    INPUT_SCROLL_DELAY_IN_MS
                  )()
              }}
            />
          </Div>
          <Div
            css={{
              display: 'flex',
              marginTop: '$lg',
              '@sm': { marginTop: '$md' },
              '@xs': { flexDirection: 'column-reverse' }
            }}
          >
            <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
            <Button
              variant="outlined"
              css={{
                flex: 1,
                marginRight: '$md',
                '@xs': { marginRight: '$none', marginTop: '$2xs' }
              }}
              disabled={authIsLoading}
              onClick={() => navigate(-1)}
            >
              {i18n().modules.hub.auth.pages.organizationList.buttons.goBack}
            </Button>
            <Button
              type="submit"
              variant="filled"
              css={{ flex: 1 }}
              disabled={hasAnyError}
              isLoading={authIsLoading}
            >
              {i18n().modules.hub.auth.pages.organizationList.buttons.next}
            </Button>
          </Div>
        </FormContainer>
      </Grid>
    </BaseBackground>
  )
}
