import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundTopLeftMobile({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="98"
      height="198"
      viewBox="0 0 98 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M69.8814 -9.34305C69.9148 -9.46425 69.9315 -9.68575 69.6891 -9.84874C65.4556 -12.6739 60.3067 -14.1074 54.3847 -14.1074C47.2924 -14.1074 41.3955 -11.9969 36.861 -7.83851C32.2763 -3.63834 29.9526 1.69859 29.9526 8.02183C29.9526 14.3451 31.8458 19.6402 35.5821 23.1174C37.5673 24.9646 40.2504 26.8787 43.5562 28.797C43.7651 28.9182 43.9448 28.843 44.0326 28.7886C44.1204 28.7343 44.2708 28.6006 44.2499 28.354C43.9616 24.5007 43.903 20.9692 44.0827 17.8557C44.0911 17.7219 44.0284 17.5882 43.9239 17.4921C41.0486 14.9427 39.9286 12.2136 39.9286 7.76272C39.9286 0.540932 46.0888 -4.90466 54.2593 -4.90466C55.0701 -4.90466 55.8766 -4.86287 56.6498 -4.77928C56.7668 -4.76675 56.8755 -4.78764 56.9674 -4.84615C57.1555 -4.95899 57.3478 -5.07601 57.5442 -5.18467C61.4351 -7.42894 65.4472 -8.71616 69.4676 -9.00453C69.756 -9.02543 69.8521 -9.22603 69.8856 -9.34723L69.8814 -9.34305Z"
          css={{ fill }}
        />
        <Path
          d="M73.7305 67.2547C78.3152 63.0545 80.6388 57.7176 80.6388 51.3943C80.6388 45.0711 78.7456 39.7759 75.0094 36.2988C73.0242 34.4516 70.3411 32.5374 67.0353 30.6192C66.8263 30.498 66.6466 30.5732 66.5589 30.6275C66.4711 30.6819 66.3207 30.8156 66.3416 31.0622C66.6299 34.9155 66.6884 38.4469 66.5087 41.5605C66.5004 41.6942 66.563 41.828 66.6675 41.9241C69.5429 44.4735 70.6629 47.2025 70.6629 51.6534C70.6629 58.8752 64.5027 64.3208 56.3322 64.3208C55.5214 64.3208 54.7148 64.279 53.9417 64.1954C53.9208 64.1954 53.8999 64.1954 53.879 64.1954C53.787 64.1954 53.6993 64.2205 53.624 64.2665C53.436 64.3793 53.2437 64.4922 53.0473 64.605C49.1606 66.8493 45.1485 68.1365 41.1238 68.4249C40.8355 68.4458 40.7393 68.6464 40.7059 68.7676C40.6725 68.8888 40.6557 69.1103 40.8981 69.2733C45.1276 72.0984 50.2764 73.5319 56.1985 73.5319C63.2907 73.5319 69.1876 71.4214 73.7221 67.263L73.7305 67.2547Z"
          css={{ fill }}
        />
        <Path
          d="M14.1842 36.5997C14.063 36.6331 13.8624 36.7293 13.8415 37.0218C13.5114 42.0996 14.8446 47.2736 17.8035 52.4015C21.3475 58.5409 26.1244 62.5948 31.9921 64.4462C37.9225 66.3143 43.7066 65.6582 49.1815 62.4987C54.8444 59.2305 58.2965 55.0512 59.4416 50.0779C60.0517 47.4324 60.361 44.1558 60.3736 40.3318C60.3736 40.0894 60.2189 39.9724 60.1311 39.9264C60.0392 39.8762 59.8511 39.8136 59.6464 39.9557C56.4534 42.1331 53.4234 43.9469 50.64 45.3511C50.523 45.4096 50.4352 45.5308 50.406 45.6729C49.637 49.4384 47.8316 51.7705 43.9783 53.998C37.7219 57.6089 29.9276 54.9969 25.8402 47.9214C25.4348 47.2192 25.0671 46.5004 24.7536 45.7899C24.7076 45.6813 24.6324 45.6019 24.5405 45.5475C24.3482 45.4389 24.1518 45.3302 23.9596 45.2174C20.0687 42.9731 16.9509 40.1395 14.6899 36.8003C14.5269 36.5579 14.3054 36.5788 14.1842 36.6122V36.5997Z"
          css={{ fill }}
        />
        <Path
          d="M78.5952 -5.03004C72.6648 -6.89818 66.8807 -6.24203 61.4058 -3.0825C55.7429 0.185692 52.2908 4.36497 51.1457 9.3383C50.5356 11.9838 50.2263 15.2603 50.2137 19.0844C50.2137 19.3268 50.3684 19.4438 50.4561 19.4898C50.5481 19.5399 50.7361 19.6026 50.9409 19.4605C54.1339 17.2831 57.1639 15.4693 59.9473 14.0651C60.0643 14.0066 60.1521 13.8853 60.1813 13.7433C60.9503 9.97773 62.7557 7.6457 66.609 5.41814C72.8654 1.80725 80.6597 4.41929 84.7471 11.4948C85.1524 12.1969 85.5202 12.9158 85.8337 13.6262C85.842 13.6471 85.8504 13.6638 85.8629 13.6806C85.9089 13.76 85.9716 13.8268 86.051 13.8686C86.2432 13.9773 86.4397 14.086 86.6319 14.1988C90.5186 16.4431 93.6405 19.2766 95.9015 22.6159C96.0645 22.8582 96.286 22.8374 96.4072 22.8081C96.5284 22.7747 96.729 22.6785 96.7499 22.386C97.0801 17.3082 95.7469 12.1342 92.788 7.00627C89.2439 0.862735 84.467 -3.18698 78.5993 -5.0384L78.5952 -5.03004Z"
          css={{ fill }}
        />
        <Path
          d="M81.8216 61.8634C81.9093 61.9512 82.0974 62.0765 82.3565 61.947C86.9203 59.6944 90.7318 55.9539 93.6949 50.8259C97.2389 44.6866 98.3631 38.5222 97.0299 32.5165C95.6842 26.4441 92.2238 21.7633 86.7448 18.6037C81.0818 15.3356 75.7366 14.437 70.8593 15.929C68.264 16.7231 65.2675 18.0939 61.9533 19.9954C61.7443 20.1166 61.7193 20.3089 61.7235 20.4092C61.7235 20.5137 61.7652 20.7059 61.9909 20.8146C65.4723 22.4905 68.5566 24.2082 71.1644 25.9175C71.2731 25.9885 71.4235 26.0052 71.5573 25.9593C75.2016 24.7431 78.1271 25.136 81.9804 27.3635C88.2367 30.9744 89.8708 39.032 85.7877 46.1117C85.3823 46.8138 84.9435 47.4909 84.4838 48.1178C84.4127 48.2139 84.3793 48.3184 84.3835 48.4229C84.3876 48.6444 84.3918 48.8659 84.3918 49.0915C84.3918 53.5843 83.4975 57.7009 81.7338 61.3285C81.6084 61.5876 81.7338 61.7756 81.8216 61.8592V61.8634Z"
          css={{ fill }}
        />
        <Path
          d="M13.5615 26.8996C14.9073 32.9721 18.3677 37.6529 23.8467 40.8124C29.5096 44.0806 34.8549 44.9791 39.7321 43.4871C42.3275 42.6931 45.324 41.3223 48.6382 39.4207C48.8471 39.2995 48.8722 39.1073 48.868 39.007C48.868 38.9025 48.8262 38.7102 48.6005 38.6016C45.1192 36.9257 42.0349 35.208 39.427 33.4987C39.3184 33.4276 39.1679 33.4109 39.0342 33.4569C35.3899 34.6731 32.4644 34.2802 28.6111 32.0527C22.3547 28.4418 20.7206 20.3841 24.8038 13.3044C25.2092 12.6023 25.648 11.9253 26.1077 11.2984C26.1202 11.2817 26.1328 11.2649 26.1411 11.2482C26.1871 11.1688 26.2122 11.0811 26.208 10.9933C26.2038 10.7718 26.1996 10.5503 26.1996 10.3246C26.1996 5.83607 27.094 1.71949 28.8535 -1.9123C28.9789 -2.17142 28.8535 -2.35948 28.7657 -2.44307C28.678 -2.53084 28.4899 -2.65621 28.2308 -2.52666C23.667 -0.274028 19.8555 3.46642 16.8924 8.59439C13.3484 14.7296 12.2283 20.8898 13.5615 26.8996Z"
          css={{ fill }}
        />
        <Path
          d="M3.23366 193.669C3.21011 193.754 3.19834 193.91 3.36905 194.025C6.35076 196.014 9.97708 197.024 14.1479 197.024C19.143 197.024 23.2962 195.538 26.4898 192.609C29.7188 189.651 31.3553 185.892 31.3553 181.439C31.3553 176.985 30.0219 173.256 27.3905 170.807C25.9924 169.506 24.1027 168.158 21.7744 166.807C21.6272 166.721 21.5007 166.774 21.4388 166.813C21.377 166.851 21.2711 166.945 21.2858 167.119C21.4889 169.833 21.5301 172.32 21.4035 174.513C21.3976 174.607 21.4418 174.701 21.5154 174.769C23.5405 176.564 24.3293 178.486 24.3293 181.621C24.3293 186.707 19.9907 190.543 14.2362 190.543C13.6652 190.543 13.0971 190.513 12.5526 190.454C12.4702 190.445 12.3936 190.46 12.3289 190.501C12.1964 190.581 12.061 190.663 11.9227 190.74C9.18235 192.32 6.35665 193.227 3.52506 193.43C3.32196 193.445 3.25426 193.586 3.23071 193.671L3.23366 193.669Z"
          css={{ fill }}
        />
        <Path
          d="M0.522741 139.721C-2.70622 142.679 -4.34277 146.438 -4.34277 150.891C-4.34277 155.345 -3.00939 159.074 -0.377951 161.523C1.02018 162.824 2.90988 164.172 5.23814 165.523C5.38531 165.609 5.51188 165.556 5.57369 165.517C5.63551 165.479 5.74147 165.385 5.72675 165.211C5.52365 162.497 5.48245 160.01 5.60901 157.817C5.6149 157.723 5.57075 157.629 5.49717 157.561C3.47208 155.766 2.68323 153.844 2.68323 150.709C2.68323 145.623 7.02186 141.787 12.7763 141.787C13.3473 141.787 13.9154 141.817 14.4599 141.876C14.4747 141.876 14.4894 141.876 14.5041 141.876C14.5689 141.876 14.6307 141.858 14.6836 141.826C14.8161 141.746 14.9515 141.667 15.0898 141.587C17.8272 140.007 20.6529 139.1 23.4875 138.897C23.6906 138.882 23.7583 138.741 23.7818 138.656C23.8054 138.57 23.8172 138.414 23.6464 138.299C20.6677 136.31 17.0413 135.3 12.8705 135.3C7.87546 135.3 3.72227 136.786 0.528627 139.715L0.522741 139.721Z"
          css={{ fill }}
        />
        <Path
          d="M42.4609 161.311C42.5463 161.288 42.6876 161.22 42.7023 161.014C42.9348 157.438 41.9958 153.794 39.9119 150.182C37.4159 145.858 34.0515 143.003 29.9189 141.699C25.7422 140.383 21.6684 140.846 17.8125 143.071C13.8242 145.373 11.3929 148.316 10.5864 151.819C10.1566 153.682 9.93882 155.99 9.92999 158.683C9.92999 158.853 10.0389 158.936 10.1007 158.968C10.1655 159.004 10.2979 159.048 10.4421 158.948C12.6909 157.414 14.8249 156.137 16.7853 155.148C16.8677 155.106 16.9295 155.021 16.9501 154.921C17.4917 152.269 18.7633 150.627 21.4771 149.058C25.8834 146.515 31.373 148.354 34.2517 153.337C34.5372 153.832 34.7962 154.338 35.0169 154.839C35.0493 154.915 35.1023 154.971 35.1671 155.009C35.3025 155.086 35.4408 155.162 35.5762 155.242C38.3165 156.823 40.5124 158.818 42.1048 161.17C42.2196 161.341 42.3756 161.326 42.4609 161.302V161.311Z"
          css={{ fill }}
        />
        <Path
          d="M-2.90342 190.631C1.27332 191.947 5.34705 191.485 9.20296 189.259C13.1913 186.958 15.6226 184.014 16.4291 180.511C16.8588 178.648 17.0767 176.34 17.0855 173.647C17.0855 173.477 16.9766 173.394 16.9148 173.362C16.85 173.326 16.7176 173.282 16.5733 173.382C14.3245 174.916 12.1905 176.193 10.2302 177.182C10.1478 177.224 10.086 177.309 10.0654 177.409C9.52379 180.061 8.25223 181.703 5.53837 183.272C1.13204 185.815 -4.35748 183.976 -7.23617 178.993C-7.52168 178.498 -7.7807 177.992 -8.00146 177.491C-8.00735 177.477 -8.01324 177.465 -8.02207 177.453C-8.05445 177.397 -8.0986 177.35 -8.15452 177.321C-8.28992 177.244 -8.42826 177.168 -8.56366 177.088C-11.3011 175.508 -13.4998 173.512 -15.0922 171.16C-15.207 170.989 -15.363 171.004 -15.4484 171.025C-15.5337 171.048 -15.675 171.116 -15.6897 171.322C-15.9223 174.898 -14.9833 178.542 -12.8994 182.154C-10.4033 186.481 -7.03896 189.333 -2.90636 190.637L-2.90342 190.631Z"
          css={{ fill }}
        />
        <Path
          d="M-5.17576 143.518C-5.23758 143.456 -5.37003 143.368 -5.55252 143.459C-8.76676 145.046 -11.4512 147.68 -13.5381 151.292C-16.0341 155.616 -16.8259 159.957 -15.8869 164.187C-14.9392 168.464 -12.502 171.76 -8.64314 173.986C-4.65478 176.288 -0.890108 176.92 2.54489 175.87C4.37277 175.31 6.48322 174.345 8.81737 173.006C8.96454 172.92 8.9822 172.785 8.97925 172.714C8.97925 172.641 8.94982 172.505 8.79087 172.429C6.33899 171.248 4.16672 170.039 2.33002 168.835C2.25348 168.785 2.14752 168.773 2.05333 168.805C-0.513353 169.662 -2.57376 169.385 -5.28761 167.816C-9.69394 165.273 -10.8448 159.598 -7.96909 154.612C-7.68357 154.117 -7.37451 153.641 -7.05073 153.199C-7.0007 153.131 -6.97715 153.058 -6.98009 152.984C-6.98303 152.828 -6.98598 152.672 -6.98598 152.513C-6.98598 149.349 -6.35608 146.45 -5.11395 143.895C-5.02565 143.712 -5.11395 143.58 -5.17576 143.521V143.518Z"
          css={{ fill }}
        />
        <Path
          d="M42.8995 168.143C41.9517 163.866 39.5145 160.57 35.6557 158.344C31.6673 156.043 27.9026 155.41 24.4677 156.46C22.6398 157.02 20.5293 157.985 18.1952 159.324C18.048 159.41 18.0303 159.545 18.0333 159.616C18.0333 159.689 18.0627 159.825 18.2217 159.901C20.6736 161.082 22.8458 162.291 24.6825 163.495C24.759 163.545 24.865 163.557 24.9592 163.525C27.5259 162.668 29.5863 162.945 32.3002 164.514C36.7065 167.057 37.8574 172.732 34.9816 177.718C34.6961 178.213 34.3871 178.689 34.0633 179.131C34.0544 179.143 34.0456 179.154 34.0397 179.166C34.0073 179.222 33.9897 179.284 33.9926 179.346C33.9956 179.502 33.9985 179.658 33.9985 179.817C33.9985 182.978 33.3686 185.877 32.1294 188.435C32.0411 188.618 32.1294 188.75 32.1912 188.809C32.2531 188.871 32.3855 188.959 32.568 188.868C35.7822 187.281 38.4667 184.647 40.5536 181.035C43.0496 176.714 43.8384 172.376 42.8995 168.143Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
