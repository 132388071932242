import { Navigate, Route, Routes } from 'react-router-dom'

import { FormSchoolYear } from './pages/Form'
import { ListSchoolYear } from './pages/List'
import { SchoolYearPromotion } from './pages/Promotion'

export function SchoolYearRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ListSchoolYear />} />
      <Route path="/form" element={<FormSchoolYear />} />
      <Route path="/form/:id" element={<FormSchoolYear />} />
      <Route path="/:id/promote" element={<SchoolYearPromotion />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
