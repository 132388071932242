import { i18n } from '@/common/i18n'
import { listBillingApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListContractApiParams,
  ListContractApiResult,
  ListContractServiceParams,
  ListContractServiceResult,
  ShowContractApiResult,
  ShowContractServiceParams,
  ShowContractServiceResult
} from './contracts'
import { fromContractFormatSanitizer, fromShortContractFormatSanitizer } from './sanitizers'

export async function listContractService(
  params: ListContractServiceParams
): Promise<ListContractServiceResult> {
  const { data, headers } = await hubApiService<ListContractApiParams, ListContractApiResult>({
    resource: i18n().modules.billing.contracts.resources.list,
    method: 'get',
    url: '/billing/v1/contract',
    params: {
      search_by: params.searchBy,
      org_type: params.orgType,
      limit: params.perPage,
      offset: params.page
    }
  })
  return listBillingApiResultSanitizer({
    headers,
    data: fromShortContractFormatSanitizer(data.contracts)
  })
}

export async function showContractService({
  contractId
}: ShowContractServiceParams): Promise<ShowContractServiceResult> {
  const { data } = await hubApiService<null, ShowContractApiResult>({
    resource: i18n().modules.billing.contracts.resources.list,
    method: 'get',
    url: `/billing/v1/contract/${contractId}`
  })
  return fromContractFormatSanitizer(data.contract)
}
