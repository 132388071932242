import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useTheme } from '@positivote/design-system/hooks'
import { ClearAllIcon } from '@positivote/design-system/icons/ClearAll'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { MONTH_OPTIONS } from '@/common/constants/date'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  SalesOrderAccordionList,
  SalesOrderAccordionListRef
} from '@/modules/billing/sales-orders/components/AccordionList'
import { SALE_ORDER_STATUS_OPTIONS } from '@/modules/billing/sales-orders/constants'
import { ListSalesOrderHookParams } from '@/modules/billing/sales-orders/contracts'
import { useListSalesOrder, useListYearSalesOrder } from '@/modules/billing/sales-orders/hooks'
import { listSalesOrderFormSanitizer } from '@/modules/billing/sales-orders/sanitizers'

export function SalesOrderList(): JSX.Element {
  changePageTitle(i18n().modules.billing.salesOrders.pages.list.pageTitle)

  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const salesOrderAccordionListRef = useRef<SalesOrderAccordionListRef>(null)

  const [showFilter, setShowFilter] = useState(false)
  const [listSalesOrderParams, setListSalesOrderParams] = useState<
    ListSalesOrderHookParams['model']
  >({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })

  const listSalesOrder = useListSalesOrder({ model: listSalesOrderParams })
  const listYearSalesOrder = useListYearSalesOrder({})

  const hasAnyFilter =
    !!listSalesOrderParams.searchBy ||
    !!listSalesOrderParams.orderStatus ||
    !!listSalesOrderParams.orderMonth ||
    !!listSalesOrderParams.orderYear

  const salesOrderYears = useMemo<Array<{ key: number; value: number }> | undefined>(
    () => listYearSalesOrder.data?.years.map((year) => ({ key: year, value: year })),
    [listYearSalesOrder.data?.years]
  )

  function handleSetListSalesOrderParams(params: Partial<ListSalesOrderHookParams['model']>): void {
    salesOrderAccordionListRef.current?.setOpenSalesOrders({})
    setListSalesOrderParams((oldState) => ({ ...oldState, ...params }))
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      handleSetListSalesOrderParams({ searchBy, page: 1 })
    })()
  }

  const { control, handleSubmit, reset } = useForm<ListSalesOrderHookParams['model']>({
    mode: 'onSubmit',
    resolver: (values) => ({ values: listSalesOrderFormSanitizer(values), errors: {} })
  })

  function handleFilter(data: ListSalesOrderHookParams['model']): void {
    handleSetListSalesOrderParams({ page: 1, ...data })
  }

  function handleClearFilter(): void {
    reset({ orderMonth: '', orderStatus: '', orderYear: '', searchBy: '' })
    handleSetListSalesOrderParams({
      page: 1,
      orderMonth: undefined,
      orderStatus: undefined,
      orderYear: undefined,
      searchBy: undefined
    })
  }

  useEffect(() => {
    if (listSalesOrder.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listSalesOrder.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        goBackFunction={() => navigate(-1)}
        title={i18n().modules.billing.salesOrders.pages.list.appBar.title}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.billing.salesOrders.pages.list.filter.title}
              </Typography>
            </Div>

            <FormContainer
              formHandleSubmit={handleSubmit}
              onSubmit={handleFilter}
              css={{ display: 'flex', flexDirection: 'column', gap: '$md', marginBottom: '$lg' }}
            >
              <FormTextField
                control={control}
                name="searchBy"
                label={i18n().modules.billing.salesOrders.pages.list.filter.input.searchBy.label}
                variant="outlined"
                supportingText={
                  i18n().modules.billing.salesOrders.pages.list.filter.input.searchBy.supportingText
                }
                inputProps={{
                  onChange: handleChangeSearchText
                }}
                leadingIcon={{ icon: SearchIcon }}
              />
              <Div css={{ display: 'flex', gap: '$lg', alignItems: 'center' }}>
                <Grid spacing="$lg" css={{ width: 'unset', flex: 1 }}>
                  <FormSelect
                    name="orderYear"
                    data-testid="orderYear"
                    control={control}
                    gridProps={{ xl: 4 }}
                    label={i18n().modules.billing.salesOrders.pages.list.filter.input.orderYear}
                    hasNoneOption
                    optionKeyField="key"
                    optionTitleField="value"
                    options={salesOrderYears ?? []}
                    variant="outlined"
                  />
                  <FormSelect
                    name="orderMonth"
                    data-testid="orderMonth"
                    control={control}
                    label={i18n().modules.billing.salesOrders.pages.list.filter.input.orderMonth}
                    hasNoneOption
                    optionKeyField="key"
                    optionTitleField="value"
                    options={MONTH_OPTIONS}
                    gridProps={{ xl: 4 }}
                    variant="outlined"
                  />
                  <FormSelect
                    name="orderStatus"
                    data-testid="orderStatus"
                    control={control}
                    label={i18n().modules.billing.salesOrders.pages.list.filter.input.orderStatus}
                    hasNoneOption
                    optionKeyField="key"
                    optionTitleField="value"
                    options={SALE_ORDER_STATUS_OPTIONS}
                    gridProps={{ xl: 4 }}
                    variant="outlined"
                    inputProps={{ value: listSalesOrderParams.orderStatus }}
                  />
                </Grid>

                <Button
                  data-testid="ClearFilter"
                  variant="outlined"
                  LeadingIcon={<ClearAllIcon size={18} />}
                  onClick={handleClearFilter}
                >
                  {i18n().modules.billing.salesOrders.pages.list.filter.buttons.clearFilters}
                </Button>
                <Button
                  data-testid="Filter"
                  type="submit"
                  variant="filled"
                  LeadingIcon={<FilterListIcon size={18} />}
                >
                  {i18n().modules.billing.salesOrders.pages.list.filter.buttons.filterList}
                </Button>
              </Div>
            </FormContainer>
            {listSalesOrder.isFetching && hasAnyFilter && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searching"
              >
                {i18n().modules.billing.salesOrders.pages.list.searching}
              </Typography>
            )}
            {!listSalesOrder.isFetching &&
              !!listSalesOrder.data?.registers.length &&
              hasAnyFilter && (
                <Typography
                  variant="titleLarge"
                  css={{ color: '$on-surface', marginBottom: '$lg' }}
                  data-testid="Typography-searchingResult"
                >
                  {i18n().modules.billing.salesOrders.pages.list.searchingResult}
                </Typography>
              )}
          </>
        )}

        {!listSalesOrder.data?.registers.length ? (
          <Div css={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            {listSalesOrder.isFetching && (
              <Loader data-testid="Loader-Container-SalesOrderAccordionList" size={80} />
            )}
            {!listSalesOrder.isFetching && !listSalesOrderParams.searchBy && (
              <EmptyList title={i18n().modules.billing.salesOrders.pages.list.emptyList} />
            )}
            {!listSalesOrder.isFetching && listSalesOrderParams.searchBy && <EmptySearch />}
          </Div>
        ) : (
          <>
            <SalesOrderAccordionList
              ref={salesOrderAccordionListRef}
              isLoading={listSalesOrder.isFetching}
              salesOrders={listSalesOrder.data.registers}
              accordionCss={{
                borderRadius: '$lg',
                backgroundColor: '$surface-2',
                '& .SalesOrderAccordionList-Container-Service': {
                  backgroundColor: '$surface-4'
                }
              }}
            />

            {listSalesOrder.data.lastPage > 1 && (
              <Pagination
                lastPage={listSalesOrder.data.lastPage}
                page={listSalesOrderParams.page ?? 1}
                setPage={(page) => handleSetListSalesOrderParams({ page })}
                css={{ marginTop: '$lg' }}
              />
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
