import { ShowUserMeApiResult } from '@/modules/hub/auth/contracts/apis'
import { whiteLabelColorPalette } from '@/modules/hub/white-label/constants'

import { WhiteLabel } from './contracts'
import {
  CreateWhiteLabelApiParams,
  ShowWhiteLabelApiResult,
  UpdateWhiteLabelApiParams
} from './contracts/api'
import { WhiteLabelForm } from './contracts/form'
import { ShowWhiteLabelServiceResult, UpdateWhiteLabelServiceParams } from './contracts/service'

export function fromWhiteLabelApiResultSanitizer(
  params: ShowWhiteLabelApiResult
): ShowWhiteLabelServiceResult {
  return {
    ...params,
    urlIcon: params.url_icon,
    urlLogo: params.url_logo_main,
    primaryColor:
      params.primary_color && whiteLabelColorPalette.includes(params.primary_color)
        ? params.primary_color
        : 'sysPrimary'
  }
}

export function whiteLabelFormSanitizer(params: Omit<WhiteLabel, 'id' | 'org'>): WhiteLabelForm {
  return {
    active: params.active,
    alias: (params.alias || '').trim(),
    primaryColor: params.primaryColor,
    urlLogo: params.urlLogo ?? '',
    urlIcon: params.urlIcon ?? ''
  }
}

export function createWhiteLabelApiParamsSanitizer(
  params: WhiteLabelForm
): CreateWhiteLabelApiParams {
  return {
    enabled: params.active.toString(),
    alias: params.alias,
    primaryColor: params.primaryColor,
    logo: params.urlLogo,
    icon: params.urlIcon
  }
}

export function updateWhiteLabelApiParamsSanitizer(
  params: Omit<UpdateWhiteLabelServiceParams, 'orgId'>
): UpdateWhiteLabelApiParams {
  return {
    id: params.id,
    enabled: params.active.toString(),
    alias: params.alias,
    primaryColor: params.primaryColor,
    logo: params.urlLogo,
    icon: params.urlIcon
  }
}

export function fromShowUserMeApiSanitizer(params: ShowUserMeApiResult): WhiteLabel | null {
  if (!params.theme) {
    return null
  }

  return {
    id: params.theme.id,
    org: {
      id: params.profile.institution.id,
      code: params.profile.institution.code,
      name: params.profile.institution.name
    },
    active: params.theme.active,
    urlIcon: params.theme.urlIcon,
    urlLogo: params.theme.urlLogoMain,
    alias: params.theme.urlOrg,
    primaryColor:
      params.theme.primaryColor && whiteLabelColorPalette.includes(params.theme.primaryColor)
        ? params.theme.primaryColor
        : 'sysPrimary'
  }
}
