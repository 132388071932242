import { yupResolver } from '@hookform/resolvers/yup'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { Div } from '@positivote/design-system/components/Div'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { CheckIcon } from '@positivote/design-system/icons/Check'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { ElipseIcon } from '@positivote/design-system/icons/Elipse'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { i18n } from '@/common/i18n'
import { Application } from '@/modules/hub/applications/contracts/models'
import { UserFlow } from '@/modules/hub/hubot/contracts'
import { useUpdateUserFlow } from '@/modules/hub/hubot/hooks'
import { userFlowFormSanitizer } from '@/modules/hub/hubot/sanitizers'
import { makeUserFlowFormSchema } from '@/modules/hub/hubot/validations'

interface HubotConfigureAccessDialogProps {
  application: Application
  userFlow?: UserFlow
  isOpen: boolean
  onClose: () => void
  isLoading: boolean
}

const minimumInputs = 2

export function ConfigureAccessDialog({
  application,
  userFlow,
  isOpen,
  onClose,
  isLoading
}: HubotConfigureAccessDialogProps): JSX.Element {
  // DOCS: Always returns only a profile of the current logged user
  const userFlowProfile = userFlow?.profiles[0]
  const inputs = useMemo(() => userFlowProfile?.inputs ?? [], [userFlowProfile?.inputs])
  const inputIds = inputs.map((input) => input.id)
  const [showPassword, setShowPassword] = useState<Record<string, boolean>>({})
  const [dialogState, setDialogState] = useState<'editing' | 'error' | 'success'>('editing')

  const updateUserFlow = useUpdateUserFlow()
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<Record<string, string>>({
    mode: 'onSubmit',
    resolver: async (value, ...args) =>
      yupResolver(makeUserFlowFormSchema(inputIds))(userFlowFormSanitizer(value), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length
  const isAnyLoading = isLoading || updateUserFlow.isPending

  function handleCloseDialog(): void {
    onClose()
    clearErrors()
    setShowPassword({})
    setDialogState('editing')
    reset(
      inputIds.reduce(
        (previousValue, currentValue) => ({ ...previousValue, [currentValue]: undefined }),
        {}
      )
    )
  }

  function onSubmit(model: Record<string, string>): void {
    if (userFlow && userFlowProfile) {
      updateUserFlow.mutate({
        model: {
          ...userFlow,
          profiles: [
            {
              ...userFlowProfile,
              inputs: inputs.map((input) => ({ ...input, data: model[input.id] }))
            }
          ]
        },
        onSuccess: () => setDialogState('success'),
        onError: () => setDialogState('error')
      })
    }
  }

  useEffect(() => {
    if (inputs.length) {
      reset(
        inputs.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.id]: currentValue.data
          }),
          {}
        )
      )
    }
  }, [inputs, reset])

  return (
    <Dialog.Container isLoading={isAnyLoading} onCancel={handleCloseDialog} isOpen={isOpen}>
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={onSubmit}>
        <Dialog.Header align="center" css={{ flexDirection: 'column' }}>
          <Image
            alt="Application Icon"
            src={application.iconUrl}
            css={{ width: '100px', height: '100px', borderRadius: '$full', objectFit: 'cover' }}
          />
          <Dialog.HeaderTitle align="center">
            {i18n().modules.hub.hubot.components.configureAccessDialog.accessTo(application.name)}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton onCancel={handleCloseDialog} isLoading={isAnyLoading} />
        </Dialog.Header>
        <Dialog.Content align="center">
          <Dialog.ContentText align="center">
            {
              i18n().modules.hub.hubot.components.configureAccessDialog.contentTexts[
                updateUserFlow.isPending ? 'loading' : dialogState
              ]
            }
          </Dialog.ContentText>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$lg',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              // DOCS: 2 inputs minimum height
              minHeight: `calc(68px * ${inputs.length || minimumInputs})`
            }}
          >
            {isAnyLoading ? (
              <Loader size={80} css={{ position: 'unset' }} />
            ) : dialogState === 'success' ? (
              <>
                <ElipseIcon size={80} fill="$primary" />
                <CheckIcon size={40} fill="$primary" css={{ position: 'absolute' }} />
              </>
            ) : dialogState === 'error' ? (
              <>
                <ElipseIcon size={80} fill="$primary" />
                <CloseIcon size={40} fill="$primary" css={{ position: 'absolute' }} />
              </>
            ) : (
              inputs.map((input, index) => (
                <FormTextField
                  control={control}
                  key={input.id}
                  name={input.id}
                  label={input.label}
                  errorText={errors[input.id]?.message}
                  inputProps={{
                    type:
                      input.type === 'password' && !showPassword[input.id] ? 'password' : 'text',
                    autoFocus: index === 0,
                    autoComplete: input.type === 'password' ? 'new-password' : 'off'
                  }}
                  trailingIcon={
                    input.type === 'password'
                      ? {
                          icon: showPassword[input.id] ? VisibilityIcon : VisibilityOffIcon,
                          onClick: () =>
                            setShowPassword((oldState) => ({
                              ...oldState,
                              [input.id]: !oldState[input.id]
                            })),
                          changeIconOnError: false
                        }
                      : undefined
                  }
                  variant="outlined"
                  required
                />
              ))
            )}
          </Div>
        </Dialog.Content>
        <Dialog.Footer align="center">
          <Dialog.FooterRefuseButton
            disabled={isAnyLoading}
            onClick={
              dialogState !== 'editing' ? () => setDialogState('editing') : handleCloseDialog
            }
          >
            {i18n().modules.hub.hubot.components.configureAccessDialog.buttons.refuse}
          </Dialog.FooterRefuseButton>
          <Dialog.FooterAcceptButton
            type={dialogState === 'success' ? 'button' : 'submit'}
            disabled={hasAnyError || dialogState === 'error'}
            onClick={dialogState === 'success' ? handleCloseDialog : undefined}
            isLoading={isAnyLoading}
          >
            {i18n().modules.hub.hubot.components.configureAccessDialog.buttons.accept}
          </Dialog.FooterAcceptButton>
        </Dialog.Footer>
      </FormContainer>
    </Dialog.Container>
  )
}
