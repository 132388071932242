import { useAlert, useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { StatusCodes } from '@/common/contracts'
import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  AvailableClassroomHookParams,
  AvailableClassroomHookResult,
  CreateClassroomHookParams,
  CreateClassroomHookResult,
  ListClassLevelHookParams,
  ListClassLevelHookResult,
  ListClassroomHookParams,
  ListClassroomHookResult,
  ListClassroomStudentHookParams,
  ListClassroomStudentHookResult,
  ListClassroomTeacherHookParams,
  ListClassroomTeacherHookResult,
  RemoveClassroomHookParams,
  ShowClassroomHookParams,
  ShowClassroomHookResult,
  UpdateClassroomHookParams,
  UpdateClassroomHookResult
} from './contracts/hooks'
import {
  availableClassroomService,
  createClassroomService,
  listClassLevelService,
  listClassroomService,
  listClassroomStudentsService,
  listClassroomTeachersService,
  removeClassroomService,
  showClassroomService,
  updateClassroomService
} from './services'

export const hookKey = 'classroom'

export function useListClassLevel({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListClassLevelHookParams): UseQueryResult<ListClassLevelHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listClassLevel', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedClassLevel = await listClassLevelService(model)
        onSuccess?.(listedClassLevel)
        return listedClassLevel
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListClassroom({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListClassroomHookParams): UseQueryResult<ListClassroomHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedClassroom = await listClassroomService(model)
        onSuccess?.(listedClassroom)
        return listedClassroom
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListClassroomStudent({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListClassroomStudentHookParams): UseQueryResult<
  ListClassroomStudentHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listStudent', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedClassroomStudents = await listClassroomStudentsService(model)
        onSuccess?.(listedClassroomStudents)
        return listedClassroomStudents
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListClassroomTeacher({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListClassroomTeacherHookParams): UseQueryResult<
  ListClassroomTeacherHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listClassroomTeachers', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedClassroomTeachers = await listClassroomTeachersService(model)
        onSuccess?.(listedClassroomTeachers)
        return listedClassroomTeachers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useRemoveClassroom(): UseMutationResult<
  void,
  ApplicationException,
  RemoveClassroomHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'remove'],
    mutationFn: async ({ model, onSuccess, onError, page, perPage }: RemoveClassroomHookParams) => {
      try {
        await removeClassroomService(model)

        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.classroom.pages.list.alert.remove
        })
        queryClient.setQueryData(
          [hookKey, 'list', { page, perPage }],
          (oldData: ListClassroomHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => !model.ids.includes(register.id))
            }
        )
        void queryClient.invalidateQueries()
        onSuccess?.(null)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useCreateClassroom(): UseMutationResult<
  CreateClassroomHookResult,
  ApplicationException,
  CreateClassroomHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateClassroomHookParams) => {
      try {
        const createClassroom = await createClassroomService(model)
        onSuccess?.(createClassroom)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.classroom.pages.list.alert.createClassroom
        })
        queryClient.setQueryData(
          [hookKey, 'show', { classroomId: createClassroom.id }],
          () => createClassroom
        )
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              schoolYearId: model.schoolYearId,
              page: 1,
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE
            }
          ],
          (oldData: ListClassroomHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: [createClassroom, ...oldData.registers].sort((a, b) =>
                a.name.localeCompare(b.name)
              )
            }
        )
        return createClassroom
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowClassroom({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowClassroomHookParams): UseQueryResult<ShowClassroomHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showClassroom = await showClassroomService(model)
        onSuccess?.(showClassroom)
        return showClassroom
      } catch (error) {
        const parsedError = error as ApplicationException
        if (parsedError.statusCode === StatusCodes.NOT_FOUND) {
          addAlertMessage({
            severity: 'warning',
            subTitle: i18n().common.exceptions.notFound
          })
        } else {
          handleError({ error: parsedError })
        }
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateClassroom(): UseMutationResult<
  UpdateClassroomHookResult,
  ApplicationException,
  UpdateClassroomHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError, page }: UpdateClassroomHookParams) => {
      try {
        const updateClassroom = await updateClassroomService(model)
        onSuccess?.(updateClassroom)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.classroom.pages.list.alert.updateClassroom
        })
        queryClient.setQueryData(
          [hookKey, 'show', { classroomId: updateClassroom.id }],
          () => updateClassroom
        )
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              schoolYearId: model.schoolYearId,
              page,
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE
            }
          ],
          (oldData: ListClassroomHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers
                .map((classroom) => {
                  if (model.classroomId === classroom.id) {
                    return {
                      ...updateClassroom
                    }
                  } else {
                    return {
                      ...classroom
                    }
                  }
                })
                .sort((firstClassroom, secondClassroom) =>
                  firstClassroom.name.localeCompare(secondClassroom.name)
                )
            }
        )
        return updateClassroom
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useAvailableClassroom({
  model,
  queryOptions,
  onSuccess,
  onError
}: AvailableClassroomHookParams): UseQueryResult<
  AvailableClassroomHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'available-classroom', model],
    queryFn: async () => {
      try {
        const availableClassrooms = await availableClassroomService(model)
        onSuccess?.(availableClassrooms)
        return availableClassrooms
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
