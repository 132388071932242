import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'

import { AppBaseCard, AppBaseCardProps } from '@/modules/hub/applications/components/AppBaseCard'

export function DesktopToolCard({
  application,
  css,
  applicationIdLoading,
  ...restProps
}: AppBaseCardProps): JSX.Element {
  const isLoading = applicationIdLoading === application.id
  return (
    <AppBaseCard
      application={application}
      css={{
        width: 236,
        '& .Button-StateLayer': { flexDirection: 'row', justifyContent: 'start', gap: '$md' },
        ...css
      }}
      {...restProps}
    >
      <IconWrapper size={56}>
        {isLoading && <Loader size={20} strokeWidth={4} />}
        <Image
          alt="Application Icon"
          src={application.iconUrl}
          css={{
            width: '100%',
            height: '100%',
            opacity: isLoading ? '25%' : 'unset',
            filter: isLoading ? 'grayscale(100%)' : 'none',
            objectFit: 'contain'
          }}
        />
      </IconWrapper>
      <Typography variant="titleSmall" css={{ color: '$on-surface' }} lineClamp={2}>
        {application.name}
      </Typography>
    </AppBaseCard>
  )
}
