import { Main } from '@positivote/design-system/components/Main'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { AccessConfigurationList } from '@/modules/hub/data-management/components/AccessConfigurationList'

export function AccessConfiguration(): JSX.Element {
  const { session } = useAuth()
  const navigate = useNavigate()

  const breadcrumbItems = [
    {
      label:
        i18n().modules.hub.dataManagement.pages.accessConfiguration.appBar.breadcrumbs.overview,
      onClick: () => navigate(-1)
    },
    {
      label: i18n().modules.hub.dataManagement.pages.accessConfiguration.appBar.breadcrumbs.title
    }
  ]

  return (
    <Main
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
    >
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={breadcrumbItems}
      />
      <AccessConfigurationList
        orgId={session?.orgId}
        css={{ padding: '$lg', overflowY: 'auto', '@sm': { padding: '$md' } }}
      />
    </Main>
  )
}
