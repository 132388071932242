import { useAlert } from '@positivote/design-system/hooks'
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { theme } from '@/modules/hub/white-label/constants'

import {
  CreateWhiteLabelHookParams,
  UpdateSkinHookParams,
  UpdateWhiteLabelHookParams,
  VerifyWhiteLabelAliasHookParams,
  VerifyWhiteLabelAliasServiceResult
} from './contracts'
import {
  createWhiteLabelService,
  updateSkinService,
  updateWhiteLabelService,
  verifyWhiteLabelAliasService
} from './services'

export const hookKey = 'white-label'

export function useVerifyWhiteLabelAlias({
  model,
  queryOptions,
  onSuccess,
  onError
}: VerifyWhiteLabelAliasHookParams): UseQueryResult<
  VerifyWhiteLabelAliasServiceResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'verifyAlias', model],
    queryFn: async () => {
      try {
        const showVerifyAliasResult = await verifyWhiteLabelAliasService(model)
        onSuccess?.(showVerifyAliasResult)
        return showVerifyAliasResult
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useCreateWhiteLabel(): UseMutationResult<
  void,
  ApplicationException,
  CreateWhiteLabelHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const { saveWhiteLabel } = useAuth()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateWhiteLabelHookParams) => {
      try {
        const createWhiteLabelResult = await createWhiteLabelService(model)
        saveWhiteLabel(createWhiteLabelResult)
        onSuccess?.(createWhiteLabelResult)
        addAlertMessage({
          alertKey: 'whiteLabelCreated',
          subTitle: i18n().modules.hub.whiteLabel.page.customization.alert.create,
          severity: 'success'
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateWhiteLabel(): UseMutationResult<
  void,
  ApplicationException,
  UpdateWhiteLabelHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const { saveWhiteLabel } = useAuth()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateWhiteLabelHookParams) => {
      try {
        const showWhiteLabel = await updateWhiteLabelService(model)
        saveWhiteLabel(showWhiteLabel)
        onSuccess?.(showWhiteLabel)
        addAlertMessage({
          alertKey: 'whiteLabelUpdated',
          subTitle: i18n().modules.hub.whiteLabel.page.customization.alert.update,
          severity: 'success'
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateSkin(): UseMutationResult<
  void,
  ApplicationException,
  UpdateSkinHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const { account, saveAccount } = useAuth()

  return useMutation({
    mutationKey: [hookKey, 'updateSkin'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateSkinHookParams) => {
      try {
        await updateSkinService(model)
        if (account) {
          saveAccount({
            ...account,
            skin: model.skin === theme.universe ? theme.universe : theme.default
          })
        }
        onSuccess?.(null)
        addAlertMessage({
          alertKey: 'skinUpdated',
          subTitle: i18n().modules.hub.whiteLabel.page.universe.alert.updateWhiteLabel,
          severity: 'success'
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
