import { ObjectSchema, ValidationError } from 'yup'

import { UpdateProfileForm } from '@/modules/hub/profiles/contracts/form'

export function validateForm(
  schema: ObjectSchema<UpdateProfileForm>,
  values: UpdateProfileForm
): { isValid: boolean; errorFields: Array<{ key: string; value: string }> } {
  try {
    schema.validateSync(values, { abortEarly: false })
    return { isValid: true, errorFields: [] }
  } catch (error) {
    const errorFields: Array<{ key: string; value: string }> = []

    if (error instanceof ValidationError) {
      error.inner.forEach((validationError: ValidationError) => {
        if (validationError.path) {
          errorFields.push({
            key: validationError.path,
            value: validationError.message
          })
        }
      })
    }
    return { isValid: false, errorFields }
  }
}
