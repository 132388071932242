import { Div, DivProps } from '@positivote/design-system/components/Div'
import { Loader } from '@positivote/design-system/components/Loader'

export function WrappedLoader({ css, ...restProps }: DivProps): JSX.Element {
  return (
    <Div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        position: 'relative',
        ...css
      }}
      {...restProps}
    >
      <Loader size={80} />
    </Div>
  )
}
