import { Navigate, Route, Routes } from 'react-router-dom'

import { ProfileForm } from '@/modules/hub/profiles/pages/Form'
import { SettingsList } from '@/modules/hub/settings/pages/List'

export function SettingsRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<SettingsList />} />
      <Route path="/edit-profile" element={<ProfileForm />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
