import { CSS } from '@positivote/design-system/theme/styles'

export enum OrganizationKind {
  NATIONAL = 1,
  STATE = 2,
  DISTRICT = 3,
  LOCAL = 4,
  DEPARTMENT = 5,
  GROUP = 6,
  SCHOOL = 7,
  COMPANY = 8,
  EDTECH = 9,
  TEACHING_NETWORK = 10
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface ShortOrganization {
  id: number
  code: string
  name: string
  address: string
  kind: OrganizationKind
  status: OrganizationStatus
  codHub: string
  city: string
  stateCode: string
}

export interface ShortOrganizationFormatted extends ShortOrganization {
  kindFormatted: string
  statusColor: CSS['color']
  statusFormatted: string
}

export interface OrganizationPermission {
  roleId: string
  roleName: string
  institutionId: string
  permissions: Array<{ id: number; code: string; name: string; enable: boolean }>
}

export interface Organization {
  idInstituicao: number
  nameInstituicao: string
  dscEndereco: string
  codHub: string
  city: string
  stateCode: string
}
