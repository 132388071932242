import { SalesOrderOperationType } from '@/modules/billing/sales-orders/contracts'

export const salesOrderPt = {
  resources: {
    list: 'Listar ordens de venda',
    update: 'Atualizar orden de venda',
    listYear: 'Listar anos',
    sendInvoiceByEmail: 'Enviar boleto por email',
    generateInvoice: 'Gerar boleto'
  },
  components: {
    accordionList: {
      rpsFileName: 'rps',
      period: 'Período:',
      created: 'Criado em:',
      contract: 'Contrato SAP:',
      status: 'Status:',
      services: 'Serviços',
      idSap: 'ID Serviço SAP:',
      numberLicense: 'Nº de licenças',
      numberLicenseMonth: 'Nº total licenças/mês:',
      costLicenseStudent: 'Custo licença/aluno:',
      discount: 'Descontos:',
      addition: 'Acréscimos:',
      subTotal: 'Subtotal:',
      total: 'Total:',
      buttons: {
        clearStatus: 'Limpar status',
        send: 'Enviar',
        delete: 'Excluir',
        generateInvoice: 'Gerar boleto',
        nf: 'NF',
        rps: 'RPS',
        addition: (isUpdating: boolean): string =>
          `${isUpdating ? 'Alterar' : 'Incluir'} acréscimo`,
        discount: (isUpdating: boolean): string => `${isUpdating ? 'Alterar' : 'Incluir'} desconto`
      },
      alert: {
        withoutRpsError: 'Não há RPS nesta ordem de venda para baixar!',
        downloadRpsError: 'Ocorreu um erro ao tentar baixar o RPS!'
      }
    },
    generateInvoiceDialog: {
      title: 'Gerar boletos',
      sendBillEmail:
        'Os boletos serão enviados para o e-mail cadastrado da instituição. Caso opte por enviar para outros e-mails, indique no campo abaixo:',
      inputs: {
        emailsToInvoice: {
          label: 'Inserir e-mails',
          supportingText: 'Separar os e-mails por vírgula.'
        },
        shouldSave: 'Guardar e-mails para ações futuras'
      },
      preferDownloadBill:
        'Caso prefira apenas salvar os boletos e não enviar para a instituição, clique em "Baixar boletos".',
      buttons: { refuse: 'Baixar boletos', accept: 'Enviar boletos' },
      alert: {
        sendInvoiceByEmail: 'Boleto(s) enviado(s) com sucesso!',
        downloadInvoiceError: 'Ocorreu um erro ao tentar baixar os boletos!',
        withoutInvoiceError: (action: 'download' | 'sendByEmail'): string =>
          `Não há boletos nesta ordem de venda para ${
            action === 'download' ? 'baixar' : 'enviar por e-mail'
          }!`,
        updated: 'Ordem de venda atualizada com sucesso!'
      },
      fileName: {
        single: 'boleto',
        zip: 'boletos'
      }
    },
    serviceOperationDialog: {
      title: (operationType: SalesOrderOperationType, isUpdating: boolean): string =>
        `${isUpdating ? 'Alterar' : 'Incluir'} ${
          operationType === 'addition' ? 'acréscimo' : 'desconto'
        } no serviço`,
      indicateValue: 'Indique um valor fixo nos campos abaixo:',
      inputs: {
        value: (operationType: SalesOrderOperationType): string =>
          `Incluir um valor de ${operationType === 'addition' ? 'acréscimo' : 'desconto'}`
      },
      buttons: {
        refuse: 'Cancelar',
        accept: 'Atualizar valor total'
      },
      totalValue: (totalValue: string): string => `valor total (${totalValue})`
    },
    textDialog: {
      CREATED: {
        title: 'Enviar Ordem de Venda?',
        contentTexts: ['Tem certeza que deseja processar manualmente esta ordem de venda?']
      },
      ERROR: {
        title: 'Deseja limpar o status?',
        contentTexts: [
          'Para voltar ao status de novo é necessário que seja realizada todas as correções dos erros indicados.',
          'Tem certeza que deseja continuar?'
        ]
      },
      SENT: {
        title: 'Deseja limpar o status?',
        contentTexts: [
          'Para voltar ao status de novo, o processamento atual da ordem de venda será cancelado. Com isso, será necessário realizar o envio novamente da ordem para processá-lo.',
          'Tem certeza que deseja continuar?'
        ]
      },
      buttons: {
        refuse: 'Não',
        accept: 'Sim'
      }
    }
  },
  pages: {
    list: {
      pageTitle: 'Ordens de venda',
      appBar: {
        title: 'Ordens de venda'
      },
      filter: {
        title: 'Opções de filtragem',
        input: {
          searchBy: {
            label: 'Buscar ordens por contrato...',
            supportingText:
              'A busca poderá ser feita pelo nome da instituição ou rede, CNPJ, códigos SAP de contrato ou de cliente.'
          },
          orderYear: 'Selecionar por ano',
          orderMonth: 'Selecionar por mês',
          orderStatus: 'Selecionar por status',
          status: {
            CREATED: 'Novo',
            SENT: 'Enviada',
            PROCESSED: 'Processada',
            ERROR: 'Erro'
          }
        },
        buttons: {
          filterList: 'Filtrar',
          clearFilters: 'Limpar filtros'
        }
      },
      emptyList: 'Ops! Ainda não temos ordens de venda para exibir',
      searching: 'Buscando por resultados...',
      searchingResult: 'Resultado da busca...'
    }
  }
}
