import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  AvailableClassroomApiParams,
  AvailableClassroomApiResult,
  AvailableClassroomServiceParams,
  AvailableClassroomServiceResult,
  CreateClassroomApiParams,
  CreateClassroomApiResult,
  CreateClassroomServiceParams,
  CreateClassroomServiceResult,
  ListClassLevelApiParams,
  ListClassLevelApiResult,
  ListClassLevelServiceParams,
  ListClassLevelServiceResult,
  ListClassroomApiParams,
  ListClassroomApiResult,
  ListClassroomServiceParams,
  ListClassroomServiceResult,
  ListClassroomStudentApiParams,
  ListClassroomStudentApiResult,
  ListClassroomStudentServiceParams,
  ListClassroomStudentServiceResult,
  ListClassroomTeacherApiParams,
  ListClassroomTeacherApiResult,
  ListClassroomTeacherServiceParams,
  ListClassroomTeacherServiceResult,
  RemoveClassroomApiParams,
  RemoveClassroomServiceParams,
  ShowClassroomApiResult,
  ShowClassroomServiceParams,
  ShowClassroomServiceResult,
  UpdateClassroomApiParams,
  UpdateClassroomApiResult,
  UpdateClassroomServiceParams,
  UpdateClassroomServiceResult
} from './contracts'
import {
  classroomFormatSanitizer,
  classroomSanitizer,
  listClassLevelSanitizer,
  listClassroomStudentApiSanitizer,
  listClassroomTeacherApiSanitizer
} from './sanitizers'

export async function listClassLevelService({
  termCode,
  institutionId,
  schoolYearId
}: ListClassLevelServiceParams): Promise<ListClassLevelServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListClassLevelApiParams, ListClassLevelApiResult>({
    resource: i18n().modules.hub.classroom.resources.listClassLevel,
    method: 'get',
    url: '/classrooms/v1/levels',
    params: {
      term_code: termCode,
      institution_id: institutionId,
      school_year_id: schoolYearId
    }
  })
  return {
    ...listHubApiResultSanitizer({
      ...result,
      data: listClassLevelSanitizer(data)
    })
  }
}

export async function listClassroomService({
  levelId,
  search,
  page,
  perPage,
  schoolYearId,
  code,
  name
}: ListClassroomServiceParams): Promise<ListClassroomServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListClassroomApiParams, ListClassroomApiResult>({
    resource: i18n().modules.hub.classroom.resources.listClassroom,
    method: 'get',
    url: '/classrooms/v1/classrooms',
    params: {
      level_id: levelId,
      limit: perPage,
      offset: page,
      school_year_id: schoolYearId,
      code,
      name,
      search
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: classroomFormatSanitizer(data)
    })
  }
}

export async function listClassroomStudentsService({
  classId,
  perPage,
  page
}: ListClassroomStudentServiceParams): Promise<ListClassroomStudentServiceResult> {
  const { data } = await hubApiService<
    ListClassroomStudentApiParams,
    ListClassroomStudentApiResult
  >({
    resource: i18n().modules.hub.classroom.resources.listClassLevel,
    method: 'get',
    url: `/classrooms/v1/classrooms/${classId}/students`,
    params: {
      size: perPage,
      page
    }
  })

  return {
    classroom: data.class,
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listClassroomStudentApiSanitizer(data.students)
    })
  }
}

export async function listClassroomTeachersService({
  classId,
  perPage,
  page
}: ListClassroomTeacherServiceParams): Promise<ListClassroomTeacherServiceResult> {
  const { data } = await hubApiService<
    ListClassroomTeacherApiParams,
    ListClassroomTeacherApiResult
  >({
    resource: i18n().modules.hub.classroom.resources.listClassLevel,
    method: 'get',
    url: `/classrooms/v1/classrooms/${classId}/teachers`,
    params: {
      limit: perPage,
      offset: page
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: listClassroomTeacherApiSanitizer(data.data)
    })
  }
}

export async function removeClassroomService(params: RemoveClassroomServiceParams): Promise<void> {
  await hubApiService<RemoveClassroomApiParams, null>({
    resource: i18n().modules.hub.classroom.resources.removeClassroom,
    method: 'delete',
    url: '/classrooms/v1/classrooms',
    body: params
  })
}

export async function createClassroomService({
  code,
  institutionId,
  serieId,
  name,
  schoolYearId
}: CreateClassroomServiceParams): Promise<CreateClassroomServiceResult> {
  const { data } = await hubApiService<CreateClassroomApiParams, CreateClassroomApiResult>({
    resource: i18n().modules.hub.classroom.resources.createClassroom,
    method: 'post',
    url: '/classrooms/v1/classrooms',
    body: {
      code,
      institution_id: institutionId,
      serie_id: serieId,
      name,
      school_year_id: schoolYearId
    }
  })

  return classroomSanitizer(data)
}

export async function showClassroomService({
  classroomId
}: ShowClassroomServiceParams): Promise<ShowClassroomServiceResult> {
  const { data } = await hubApiService<null, ShowClassroomApiResult>({
    resource: i18n().modules.hub.classroom.resources.showClassroom,
    method: 'get',
    url: `/classrooms/v1/classrooms/${classroomId}`
  })

  return classroomSanitizer(data)
}

export async function updateClassroomService({
  code,
  institutionId,
  serieId,
  name,
  schoolYearId,
  classroomId
}: UpdateClassroomServiceParams): Promise<UpdateClassroomServiceResult> {
  const { data } = await hubApiService<UpdateClassroomApiParams, UpdateClassroomApiResult>({
    resource: i18n().modules.hub.classroom.resources.updateClassroom,
    method: 'put',
    url: `/classrooms/v1/classrooms/${classroomId}`,
    body: {
      code,
      institution_id: institutionId,
      serie_id: serieId,
      name,
      school_year_id: schoolYearId
    }
  })

  return classroomSanitizer(data)
}

export async function availableClassroomService({
  code,
  name,
  institutionId,
  schoolYearId
}: AvailableClassroomServiceParams): Promise<AvailableClassroomServiceResult> {
  const { data } = await hubApiService<AvailableClassroomApiParams, AvailableClassroomApiResult>({
    resource: i18n().modules.hub.disciplines.resources.availableDiscipline,
    method: 'get',
    url: '/classrooms/v1/classrooms/available',
    params: {
      code,
      name,
      institution_id: institutionId,
      school_year_id: schoolYearId
    }
  })

  return data
}
