import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundTopRight({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="156"
      height="204"
      viewBox="0 0 156 204"
      fill="none"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M16.06 200.93C16.0415 200.997 16.0323 201.119 16.166 201.209C18.5018 202.767 21.3425 203.558 24.6099 203.558C28.5228 203.558 31.7763 202.394 34.2781 200.1C36.8076 197.782 38.0896 194.838 38.0896 191.349C38.0896 187.86 37.0451 184.939 34.9837 183.021C33.8884 182.001 32.4081 180.945 30.5842 179.887C30.4689 179.82 30.3698 179.862 30.3214 179.892C30.2729 179.922 30.1899 179.995 30.2015 180.131C30.3605 182.257 30.3928 184.206 30.2937 185.924C30.2891 185.997 30.3237 186.071 30.3813 186.124C31.9677 187.531 32.5857 189.036 32.5857 191.492C32.5857 195.477 29.1869 198.481 24.679 198.481C24.2317 198.481 23.7867 198.458 23.3601 198.412C23.2956 198.405 23.2356 198.416 23.1849 198.449C23.0811 198.511 22.9751 198.576 22.8667 198.635C20.72 199.874 18.5064 200.584 16.2882 200.743C16.1291 200.755 16.0761 200.865 16.0576 200.932L16.06 200.93Z"
          css={{ fill }}
        />
        <Path
          d="M13.9363 158.669C11.4068 160.986 10.1248 163.931 10.1248 167.419C10.1248 170.908 11.1693 173.83 13.2307 175.748C14.326 176.767 15.8063 177.823 17.6302 178.882C17.7455 178.948 17.8446 178.907 17.8931 178.877C17.9415 178.847 18.0245 178.773 18.013 178.637C17.8539 176.511 17.8216 174.563 17.9207 172.845C17.9253 172.771 17.8908 172.697 17.8331 172.644C16.2467 171.238 15.6288 169.732 15.6288 167.276C15.6288 163.292 19.0275 160.288 23.5354 160.288C23.9827 160.288 24.4277 160.311 24.8543 160.357C24.8658 160.357 24.8773 160.357 24.8889 160.357C24.9396 160.357 24.988 160.343 25.0295 160.318C25.1333 160.255 25.2394 160.193 25.3477 160.131C27.4921 158.893 29.7057 158.182 31.9262 158.023C32.0853 158.012 32.1383 157.901 32.1568 157.834C32.1752 157.767 32.1844 157.645 32.0507 157.555C29.7172 155.996 26.8765 155.206 23.6092 155.206C19.6962 155.206 16.4427 156.37 13.9409 158.664L13.9363 158.669Z"
          css={{ fill }}
        />
        <Path
          d="M46.7894 175.582C46.8563 175.564 46.967 175.511 46.9785 175.349C47.1606 172.548 46.4251 169.693 44.7926 166.864C42.8373 163.477 40.2017 161.24 36.9644 160.218C33.6924 159.188 30.5012 159.55 27.4806 161.293C24.3562 163.096 22.4516 165.402 21.8198 168.146C21.4832 169.605 21.3126 171.413 21.3057 173.523C21.3057 173.657 21.391 173.721 21.4394 173.747C21.4901 173.774 21.5939 173.809 21.7069 173.73C23.4685 172.529 25.1402 171.528 26.6759 170.754C26.7404 170.721 26.7889 170.655 26.805 170.576C27.2293 168.499 28.2254 167.212 30.3513 165.983C33.8031 163.991 38.1034 165.432 40.3585 169.336C40.5822 169.723 40.7851 170.12 40.958 170.512C40.9834 170.571 41.0249 170.615 41.0756 170.645C41.1817 170.705 41.2901 170.765 41.3961 170.827C43.5428 172.066 45.263 173.629 46.5104 175.471C46.6003 175.605 46.7225 175.594 46.7894 175.575V175.582Z"
          css={{ fill }}
        />
        <Path
          d="M11.2523 198.55C14.5243 199.581 17.7155 199.219 20.7361 197.476C23.8605 195.672 25.7651 193.367 26.3969 190.623C26.7335 189.163 26.9041 187.355 26.9111 185.246C26.9111 185.112 26.8258 185.047 26.7773 185.022C26.7266 184.994 26.6228 184.96 26.5099 185.038C24.7482 186.239 23.0765 187.24 21.5408 188.015C21.4763 188.047 21.4279 188.114 21.4117 188.192C20.9875 190.27 19.9914 191.557 17.8654 192.786C14.4136 194.778 10.1133 193.337 7.8582 189.433C7.63454 189.046 7.43163 188.649 7.2587 188.257C7.25408 188.245 7.24947 188.236 7.24256 188.227C7.21719 188.183 7.1826 188.146 7.13879 188.123C7.03273 188.063 6.92435 188.003 6.81829 187.941C4.67389 186.703 2.95145 185.14 1.70401 183.297C1.61408 183.164 1.49188 183.175 1.42501 183.191C1.35814 183.21 1.24746 183.263 1.23593 183.424C1.05377 186.226 1.78933 189.08 3.42184 191.909C5.37716 195.299 8.01269 197.533 11.25 198.555L11.2523 198.55Z"
          css={{ fill }}
        />
        <Path
          d="M9.47226 161.643C9.42384 161.595 9.32008 161.526 9.17712 161.597C6.65918 162.84 4.55629 164.904 2.92148 167.733C0.966153 171.12 0.345888 174.521 1.08144 177.835C1.82391 181.185 3.73312 183.768 6.75603 185.511C9.88039 187.314 12.8295 187.81 15.5204 186.987C16.9523 186.548 18.6056 185.792 20.4341 184.743C20.5494 184.676 20.5632 184.57 20.5609 184.515C20.5609 184.457 20.5378 184.351 20.4133 184.291C18.4926 183.366 16.7909 182.419 15.3521 181.476C15.2921 181.436 15.2091 181.427 15.1353 181.453C13.1247 182.124 11.5106 181.907 9.38464 180.678C5.93285 178.686 5.03129 174.24 7.28406 170.334C7.50772 169.947 7.74983 169.573 8.00347 169.227C8.04267 169.174 8.06111 169.117 8.05881 169.059C8.0565 168.937 8.05419 168.814 8.05419 168.69C8.05419 166.211 8.54763 163.94 9.52068 161.939C9.58986 161.796 9.52068 161.692 9.47226 161.646V161.643Z"
          css={{ fill }}
        />
        <Path
          d="M47.133 180.934C46.3905 177.583 44.4813 175.001 41.4584 173.258C38.334 171.455 35.3849 170.959 32.694 171.782C31.2621 172.22 29.6089 172.976 27.7804 174.026C27.6651 174.092 27.6512 174.199 27.6535 174.254C27.6535 174.312 27.6766 174.418 27.8011 174.478C29.7218 175.402 31.4235 176.35 32.8624 177.293C32.9223 177.332 33.0053 177.341 33.0791 177.316C35.0898 176.645 36.7038 176.862 38.8298 178.091C42.2816 180.083 43.1831 184.529 40.9304 188.435C40.7067 188.822 40.4646 189.195 40.2109 189.541C40.204 189.551 40.1971 189.56 40.1925 189.569C40.1671 189.613 40.1533 189.661 40.1556 189.71C40.1579 189.832 40.1602 189.954 40.1602 190.079C40.1602 192.555 39.6668 194.826 38.696 196.83C38.6269 196.973 38.696 197.077 38.7445 197.123C38.7929 197.171 38.8966 197.24 39.0396 197.169C41.5575 195.926 43.6604 193.862 45.2952 191.033C47.2506 187.648 47.8685 184.25 47.133 180.934Z"
          css={{ fill }}
        />
        <Path
          d="M102.845 47.9338C102.814 48.0455 102.799 48.2498 103.022 48.4001C106.926 51.0051 111.674 52.3269 117.134 52.3269C123.674 52.3269 129.111 50.3808 133.293 46.5465C137.52 42.6736 139.663 37.7525 139.663 31.922C139.663 26.0914 137.917 21.2089 134.472 18.0027C132.641 16.2994 130.167 14.5344 127.119 12.7656C126.926 12.6538 126.761 12.7232 126.68 12.7733C126.599 12.8234 126.46 12.9467 126.479 13.1741C126.745 16.7271 126.799 19.9834 126.634 22.8544C126.626 22.9777 126.684 23.101 126.78 23.1896C129.431 25.5403 130.464 28.0568 130.464 32.1609C130.464 38.8199 124.784 43.8412 117.25 43.8412C116.502 43.8412 115.759 43.8027 115.046 43.7256C114.938 43.7141 114.838 43.7333 114.753 43.7873C114.579 43.8913 114.402 43.9992 114.221 44.0994C110.633 46.1688 106.934 47.3557 103.227 47.6216C102.961 47.6409 102.872 47.8259 102.841 47.9376L102.845 47.9338Z"
          css={{ fill }}
        />
        <Path
          d="M99.296 -22.6955C95.0685 -18.8226 92.9259 -13.9015 92.9259 -8.07098C92.9259 -2.24044 94.6716 2.6421 98.1168 5.84831C99.9472 7.55162 102.421 9.31658 105.469 11.0854C105.662 11.1972 105.828 11.1278 105.909 11.0777C105.99 11.0276 106.128 10.9043 106.109 10.6769C105.843 7.12386 105.789 3.86755 105.955 0.996601C105.963 0.873283 105.905 0.749962 105.809 0.661331C103.157 -1.68938 102.125 -4.2058 102.125 -8.30991C102.125 -14.969 107.805 -19.9902 115.339 -19.9902C116.086 -19.9902 116.83 -19.9517 117.543 -19.8746C117.562 -19.8746 117.581 -19.8746 117.601 -19.8746C117.685 -19.8746 117.766 -19.8978 117.836 -19.9402C118.009 -20.0442 118.186 -20.1482 118.368 -20.2523C121.951 -22.3217 125.651 -23.5086 129.362 -23.7745C129.628 -23.7938 129.716 -23.9788 129.747 -24.0905C129.778 -24.2023 129.794 -24.4065 129.57 -24.5568C125.67 -27.1618 120.923 -28.4836 115.462 -28.4836C108.922 -28.4836 103.485 -26.5376 99.3037 -22.7032L99.296 -22.6955Z"
          css={{ fill }}
        />
        <Path
          d="M154.202 5.57085C154.314 5.54003 154.499 5.4514 154.518 5.18164C154.823 0.499485 153.594 -4.27131 150.865 -8.99971C147.597 -14.6607 143.193 -18.3987 137.782 -20.1059C132.314 -21.8284 126.98 -21.2234 121.932 -18.3101C116.71 -15.2965 113.527 -11.4429 112.472 -6.85709C111.909 -4.41775 111.624 -1.3965 111.612 2.12957C111.612 2.35308 111.755 2.46098 111.836 2.50337C111.92 2.54961 112.094 2.60741 112.283 2.47639C115.227 0.468651 118.021 -1.20382 120.587 -2.49864C120.695 -2.55259 120.776 -2.66434 120.803 -2.79537C121.512 -6.26749 123.177 -8.41781 126.73 -10.4718C132.499 -13.8013 139.686 -11.3928 143.455 -4.86862C143.828 -4.22121 144.168 -3.55839 144.457 -2.90327C144.499 -2.80308 144.568 -2.72986 144.653 -2.67976C144.83 -2.57957 145.012 -2.47937 145.189 -2.37532C148.777 -0.305927 151.651 2.30684 153.736 5.38588C153.886 5.60939 154.091 5.59013 154.202 5.5593V5.57085Z"
          css={{ fill }}
        />
        <Path
          d="M94.8103 43.9568C100.279 45.6794 105.612 45.0744 110.66 42.161C115.882 39.1475 119.065 35.2939 120.121 30.7081C120.684 28.2687 120.969 25.2475 120.98 21.7214C120.98 21.4979 120.838 21.39 120.757 21.3476C120.672 21.3014 120.499 21.2436 120.31 21.3746C117.366 23.3823 114.572 25.0548 112.005 26.3496C111.897 26.4036 111.816 26.5153 111.789 26.6463C111.08 30.1185 109.416 32.2688 105.863 34.3228C100.094 37.6523 92.9067 35.2438 89.1378 28.7196C88.764 28.0722 88.4249 27.4094 88.1359 26.7542C88.1282 26.735 88.1205 26.7196 88.1089 26.7041C88.0665 26.6309 88.0087 26.5693 87.9355 26.5307C87.7582 26.4305 87.5771 26.3303 87.3998 26.2263C83.816 24.1569 80.9373 21.5441 78.8525 18.4651C78.7022 18.2416 78.498 18.2608 78.3862 18.2878C78.2745 18.3186 78.0895 18.4073 78.0702 18.677C77.7658 23.3592 78.9951 28.13 81.7234 32.8584C84.9913 38.5232 89.396 42.2574 94.8065 43.9645L94.8103 43.9568Z"
          css={{ fill }}
        />
        <Path
          d="M91.8353 -17.7243C91.7544 -17.8052 91.581 -17.9209 91.3421 -17.8014C87.1339 -15.7243 83.6194 -12.2753 80.8872 -7.54689C77.6193 -1.88591 76.5827 3.79819 77.812 9.33585C79.0529 14.9352 82.2437 19.2512 87.2958 22.1646C92.5174 25.1781 97.4462 26.0066 101.943 24.6309C104.337 23.8987 107.1 22.6347 110.155 20.8813C110.348 20.7696 110.371 20.5923 110.367 20.4998C110.367 20.4035 110.329 20.2262 110.121 20.126C106.911 18.5807 104.067 16.9969 101.662 15.4207C101.562 15.3552 101.423 15.3398 101.3 15.3822C97.9395 16.5036 95.242 16.1413 91.6889 14.0874C85.92 10.7578 84.4133 3.32804 88.1783 -3.2C88.5521 -3.84741 88.9567 -4.4717 89.3806 -5.04974C89.4461 -5.13837 89.4769 -5.23471 89.4731 -5.33105C89.4692 -5.5353 89.4654 -5.73954 89.4654 -5.94764C89.4654 -10.0903 90.29 -13.8861 91.9163 -17.2311C92.0319 -17.47 91.9163 -17.6434 91.8353 -17.7205V-17.7243Z"
          css={{ fill }}
        />
        <Path
          d="M154.777 14.5151C153.536 8.9158 150.345 4.59974 145.293 1.6864C140.071 -1.32714 135.142 -2.15567 130.645 -0.779922C128.252 -0.0477333 125.489 1.21626 122.433 2.96965C122.24 3.08141 122.217 3.25868 122.221 3.35117C122.221 3.44751 122.26 3.62477 122.468 3.72496C125.678 5.27027 128.522 6.85411 130.927 8.43024C131.027 8.49575 131.165 8.51117 131.289 8.46878C134.649 7.34738 137.347 7.70962 140.9 9.7636C146.669 13.0931 148.175 20.5229 144.41 27.051C144.037 27.6984 143.632 28.3227 143.208 28.9007C143.196 28.9161 143.185 28.9315 143.177 28.947C143.135 29.0202 143.112 29.1011 143.116 29.182C143.119 29.3863 143.123 29.5905 143.123 29.7986C143.123 33.9374 142.299 37.7332 140.676 41.082C140.561 41.3209 140.676 41.4944 140.757 41.5714C140.838 41.6524 141.011 41.768 141.25 41.6485C145.459 39.5714 148.973 36.1224 151.705 31.394C154.973 25.7369 156.006 20.0566 154.777 14.5151Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
