import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'
import {
  ListApplicationApiParams,
  ListApplicationApiResult,
  ListEdtechApplicationApiParams,
  ListEdtechApplicationApiResult
} from '@/modules/hub/applications/contracts/apis'
import {
  ListApplicationServiceParams,
  ListApplicationServiceResult,
  ListEdtechApplicationServiceParams,
  ListEdtechApplicationServiceResult
} from '@/modules/hub/applications/contracts/services'
import {
  fromApplicationApiSanitizer,
  fromEdtechApplicationApiSanitizer
} from '@/modules/hub/applications/sanitizers'

export async function listApplicationService(
  params: ListApplicationServiceParams
): Promise<ListApplicationServiceResult> {
  const { data } = await hubApiService<ListApplicationApiParams, ListApplicationApiResult>({
    resource: i18n().modules.hub.applications.resources.list,
    method: 'get',
    url: '/licenses/v1/libraries',
    params: {
      page: params.page,
      search: params.search,
      serie: params.serie,
      size: params.size
    }
  })

  return listHubApiResultSanitizer({
    content_range: data.content_range,
    total: data.total,
    total_pages: data.total_pages,
    data: fromApplicationApiSanitizer(data.data)
  })
}

export async function listEdtechApplicationService(
  params: ListEdtechApplicationServiceParams
): Promise<ListEdtechApplicationServiceResult> {
  const { data } = await hubApiService<
    ListEdtechApplicationApiParams,
    ListEdtechApplicationApiResult
  >({
    resource: i18n().modules.hub.applications.resources.list,
    method: 'get',
    url: '/edtechs/v1/applications',
    params
  })

  return listHubApiResultSanitizer({
    page: data.page ?? params.page,
    content_range: data.content_range,
    total: data.total,
    total_pages: data.total_pages,
    order: data.order ?? params.order,
    orderBy: data.orderBy ?? params.orderBy,
    data: fromEdtechApplicationApiSanitizer(data.data)
  })
}
