import { AlertCard } from '@positivote/design-system/components/AlertCard'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Grid } from '@positivote/design-system/components/Grid'
import { Object } from '@positivote/design-system/components/Object'
import { Typography } from '@positivote/design-system/components/Typography'
import { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { useAuth } from '@/modules/hub/auth/contexts'
import { Term } from '@/modules/hub/terms/contracts'
import { useAcceptTerm } from '@/modules/hub/terms/hook'

export function Agreement(): JSX.Element {
  const { profile, logout } = useAuth()
  const acceptTerm = useAcceptTerm()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as { previousPath?: string; term?: Term } | null
  const term = locationState?.term

  const handleNavigate = useCallback(() => {
    navigate(locationState?.previousPath ? locationState.previousPath : '/', {
      replace: true
    })
  }, [locationState?.previousPath, navigate])

  function handleAccept(): void {
    acceptTerm.mutate({
      model: {
        role: profile!.role.code,
        termId: term!.id,
        name: profile!.name
      },
      onSuccess: () => {
        handleNavigate()
      }
    })
  }

  useEffect(() => {
    if (!term) {
      handleNavigate()
    }
  }, [handleNavigate, term])

  return (
    <BaseBackground>
      {term && (
        <Grid xl={8} lg={10} md={12} css={{ display: 'flex', flex: 1 }}>
          <BaseCard
            css={{
              width: '100%',
              '& .BaseCard-StateLayer': {
                gap: '$lg',
                height: '100%',
                alignItems: 'center'
              }
            }}
          >
            <Grid
              xl={7}
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="bodyLarge"
                css={{
                  textAlign: 'center',
                  color: '$on-surface-variant'
                }}
              >
                {i18n().modules.hub.terms.page.agreement.title}{' '}
                <Typography component="strong">
                  {i18n().modules.hub.terms.page.agreement.strongTitle}
                </Typography>
              </Typography>
            </Grid>
            <Grid css={{ flex: 1 }}>
              <Object data={term.docUrl} css={{ height: '100%', width: '100%' }} />
            </Grid>
            <Grid
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '$2xs'
              }}
            >
              {term.mandatory ? (
                <Grid
                  xl={3}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    css={{ flex: 1 }}
                    variant="filled"
                    onClick={handleAccept}
                    isLoading={acceptTerm.isPending}
                  >
                    {i18n().modules.hub.terms.page.agreement.buttons.accept}
                  </Button>
                </Grid>
              ) : (
                <Grid
                  xl={7}
                  spacing="$lg"
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'flex-reverse'
                  }}
                >
                  <Grid xl={6} css={{ display: 'flex' }}>
                    <Button
                      css={{ flex: 1 }}
                      variant="outlined"
                      onClick={handleNavigate}
                      disabled={acceptTerm.isPending}
                    >
                      {i18n().modules.hub.terms.page.agreement.buttons.doItLater}
                    </Button>
                  </Grid>

                  <Grid xl={6} css={{ display: 'flex' }}>
                    <Button
                      css={{ flex: 1 }}
                      variant="filled"
                      onClick={handleAccept}
                      isLoading={acceptTerm.isPending}
                    >
                      {i18n().modules.hub.terms.page.agreement.buttons.accept}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid xl={term.mandatory ? 3 : 7} css={{ display: 'flex' }}>
                <Button
                  variant="text"
                  css={{ flex: 1 }}
                  onClick={logout}
                  disabled={acceptTerm.isPending}
                >
                  {i18n().modules.hub.terms.page.agreement.buttons.goBack}
                </Button>
              </Grid>
              <AlertCard
                variant="outlined"
                severity="warning"
                subTitle={i18n().modules.hub.terms.page.agreement.warning}
                hideInMs={false}
              />
            </Grid>
          </BaseCard>
        </Grid>
      )}
    </BaseBackground>
  )
}
