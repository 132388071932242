import { Div } from '@positivote/design-system/components/Div'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { useMemo } from 'react'

import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

export function ListReports(): JSX.Element {
  changePageTitle(i18n().modules.hub.reports.pages.list.pageTitle)
  const { getToken, profile } = useAuth()
  const token = useMemo(() => getToken(), [getToken])
  const { breakpoint } = useTheme()

  let url = ''
  if (token && profile) {
    url = `${import.meta.env.VITE_HUB_REPORT_URL}/?token_access=${token.idToken}&hub_identity=${
      profile.id
    }&id_org=${profile.organizationId}&inst_name=${profile.organization.name}`
  }

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : !profile ? (
        <WrappedLoader />
      ) : (
        <Iframe src={url} css={{ width: '100%', height: '100%' }} />
      )}
    </Div>
  )
}
