import { Navigate, Route, Routes } from 'react-router-dom'

import { OnboardingDetails } from './pages/Details'
import { OnboardingList } from './pages/List'

export function OnboardingRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<OnboardingList />} />
      <Route path="/:id" element={<OnboardingDetails />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
