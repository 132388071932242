import { useAlert } from '@positivote/design-system/hooks'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  ShowUserFlowHookParams,
  ShowUserFlowHookResult,
  UpdateUserFlowHookParams,
  UpdateUserFlowHookResult
} from './contracts'
import { showUserFlowService, updateUserFlowService } from './services'

const hookKey = 'hubot'

export function useShowUserFlow(): UseMutationResult<
  ShowUserFlowHookResult,
  ApplicationException,
  ShowUserFlowHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'showUserFlow'],
    mutationFn: async ({ model, onSuccess, onError }: ShowUserFlowHookParams) => {
      try {
        const queryKey = [hookKey, 'showUserFlow', model.applicationId]
        const queryData = queryClient.getQueryData<ShowUserFlowHookResult>(queryKey)
        if (queryData) {
          onSuccess?.(queryData)
          return queryData
        }
        const shownUserFlow = await showUserFlowService(model)
        queryClient.setQueryData(queryKey, shownUserFlow)
        onSuccess?.(shownUserFlow)
        return shownUserFlow
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateUserFlow(): UseMutationResult<
  UpdateUserFlowHookResult,
  ApplicationException,
  UpdateUserFlowHookParams
> {
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'updateUserFlow'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateUserFlowHookParams) => {
      try {
        const updatedUserFlow = await updateUserFlowService(model)
        queryClient.setQueryData([hookKey, 'showUserFlow', model.appId], updatedUserFlow)
        onSuccess?.(updatedUserFlow)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.hubot.components.configureAccessDialog.alert.updated
        })
        return updatedUserFlow
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
