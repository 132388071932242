import { RESTRICT_NOT_SIGNED_PATHNAMES } from '@/common/constants/page'

export function changePageTitle(prefix: string): void {
  const suffix = 'Hub Educacional'
  document.title = `${prefix} | ${suffix}`
}

export function clearPageUrl(): void {
  window.history.replaceState('', document.title, window.location.pathname)
}

export function getHashParams(): Record<string, string> {
  const hashParamsList = decodeURI(
    location.hash.replace('#', '').replaceAll('%3a', ':').replaceAll('%2c', ',')
  ).split('&')
  const parsedHashParams: Record<string, string> = {}
  hashParamsList.forEach((hashParam) => {
    const [paramKey, paramValue] = hashParam.split('=')
    if (paramKey && paramValue) {
      parsedHashParams[paramKey] = paramValue
    }
  })
  return parsedHashParams
}

export function getAliasFromUrl(): string | undefined {
  const [, alias] = location.pathname.split('/')

  if (!alias) {
    return
  }

  if (RESTRICT_NOT_SIGNED_PATHNAMES.includes(alias)) {
    return
  }

  return alias
}
