import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { ArrowBackIcon } from '@positivote/design-system/icons/ArrowBack'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { KeyboardDoubleArrowLeftIcon } from '@/fixme/icons/KeyboardDoubleArrowLeft'
import { KeyboardDoubleArrowRightIcon } from '@/fixme/icons/KeyboardDoubleArrowRight'
import {
  ClassroomFormatted,
  InclusionTeachersStepperState
} from '@/modules/hub/classroom/contracts'
import { ListTeacherHookParams, Teacher } from '@/modules/hub/users/contracts'
import { useListTeacher } from '@/modules/hub/users/hooks'

interface EnrollmentTeacherStepProps {
  stepperState: InclusionTeachersStepperState
  setStepState: (stepperState: Partial<InclusionTeachersStepperState['selectTeachers']>) => void
}

export const EnrollmentTeacherStep = forwardRef(function EnrollmentTeacherStep(
  { setStepState }: EnrollmentTeacherStepProps,
  ref
) {
  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
    beforeHistory: string
  } | null

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [search, setSearch] = useState<string | undefined>('')
  const [teachersToEnroll, setTeachersToEnroll] = useState<Teacher[]>([])
  const [currentTeachersSelected, setCurrentTeachersSelected] = useState<number[]>([])
  const [currentTeachersRemoveSelected, setCurrentTeachersRemoveSelected] = useState<number[]>([])
  const [teachersAlreadyEnrolled, setTeachersAlreadyEnrolled] = useState<Teacher[]>([])
  const [nameAlreadyIntegrated, setNameAlreadyIntegrated] = useState<string | undefined>('')
  const [enrollments, setEnrollment] = useState<Teacher[]>([])
  const [removeEnrolled, setRemoveEnrolled] = useState<Teacher[]>([])

  const { ref: refViewToEnrollment, inView: inViewToEnrollment } = useInView()
  const { ref: refViewAlreadyEnrolled, inView: inViewAlreadyEnrolled } = useInView()

  const [classroomTeachersToEnrollParams, setClassroomTeachersToEnrollParams] = useState<
    ListTeacherHookParams['model']
  >({
    page: 1,
    perPage: 50
  })

  const [classroomTeachersAlreadyEnrolledParams, setClassroomTeachersAlreadyEnrolledParams] =
    useState<ListTeacherHookParams['model']>({
      page: 1,
      perPage: 50
    })

  const allTeachersToEnroll = useListTeacher({
    model: {
      ignoreClassroomId: locationState?.classroom.id,
      ...classroomTeachersToEnrollParams
    },
    queryOptions: {
      enabled: !!locationState
    }
  })

  const allTeachersAlreadyEnrolled = useListTeacher({
    model: {
      classroomId: locationState?.classroom.id,
      ...classroomTeachersAlreadyEnrolledParams
    },
    queryOptions: {
      enabled: !!locationState
    }
  })

  const isLoading = allTeachersToEnroll.isFetching || allTeachersAlreadyEnrolled.isFetching

  const isAllTeachersSelected = useMemo(() => {
    const teachersIds = teachersToEnroll.map((teacher) => teacher.id)
    return teachersIds.every((id) => currentTeachersSelected.includes(id))
  }, [teachersToEnroll, currentTeachersSelected])

  const isAllToRemoveSelected = useMemo(() => {
    const teachersIds = teachersAlreadyEnrolled.map((teacher) => teacher.id)
    return teachersIds.every((id) => currentTeachersRemoveSelected.includes(id))
  }, [currentTeachersRemoveSelected, teachersAlreadyEnrolled])

  function handleSelectAllTeachers(): void {
    const teachersIds = teachersToEnroll.map((teacher) => teacher.id)

    const allSelected = teachersIds.every((id) => currentTeachersSelected.includes(id))

    if (allSelected) {
      setCurrentTeachersSelected((prevSelected) =>
        prevSelected.filter((id) => !teachersIds.includes(id))
      )
    } else {
      setCurrentTeachersSelected((prevSelected) => [...prevSelected, ...teachersIds])
    }
  }

  function handleSelectAllRemoveTeachers(): void {
    const teachersIds = teachersAlreadyEnrolled.map((teacher) => teacher.id)

    const allSelected = teachersIds.every((id) => currentTeachersRemoveSelected.includes(id))

    if (allSelected) {
      setCurrentTeachersRemoveSelected((prevSelected) =>
        prevSelected.filter((id) => !teachersIds.includes(id))
      )
    } else {
      setCurrentTeachersRemoveSelected((prevSelected) => [...prevSelected, ...teachersIds])
    }
  }

  function handleMoveToIntegration(): void {
    const selectedItems = teachersToEnroll.filter((item) =>
      currentTeachersSelected.includes(item.id)
    )
    const remainingItems = teachersToEnroll.filter(
      (item) => !currentTeachersSelected.includes(item.id)
    )
    setTeachersToEnroll(remainingItems)
    setTeachersAlreadyEnrolled((oldData) => [...selectedItems, ...oldData])
    setCurrentTeachersSelected([])
    setEnrollment((oldData) => [...selectedItems, ...oldData])
    const filteredRemoveTeachersEnrollments = removeEnrolled.filter(
      (student) => !currentTeachersSelected.includes(student.id)
    )
    setRemoveEnrolled(filteredRemoveTeachersEnrollments)
  }

  function handleRemoveToIntegration(): void {
    const selectedItems = teachersAlreadyEnrolled.filter((item) =>
      currentTeachersRemoveSelected.includes(item.id)
    )
    const remainingItems = teachersAlreadyEnrolled.filter(
      (item) => !currentTeachersRemoveSelected.includes(item.id)
    )
    setTeachersToEnroll((oldData) => [...selectedItems, ...oldData])
    setTeachersAlreadyEnrolled(remainingItems)
    setCurrentTeachersRemoveSelected([])
    setRemoveEnrolled((oldData) => [...selectedItems, ...oldData])
    const filteredTeachersEnrollments = enrollments.filter(
      (student) => !currentTeachersRemoveSelected.includes(student.id)
    )
    setEnrollment(filteredTeachersEnrollments)
  }

  function handleSelectTeacher(id: number): void {
    setCurrentTeachersSelected((old) => {
      if (old.includes(id)) {
        return old.filter((teacherId) => teacherId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleSelectRemoveTeacher(id: number): void {
    setCurrentTeachersRemoveSelected((old) => {
      if (old.includes(id)) {
        return old.filter((teacherId) => teacherId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setSearch(searchBy)
      setClassroomTeachersToEnrollParams((oldData) => ({ ...oldData, search: searchBy }))
    })()
  }

  function handleChangeNameAlreadyText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setNameAlreadyIntegrated(searchBy)
      setClassroomTeachersAlreadyEnrolledParams((oldData) => ({ ...oldData, search: searchBy }))
    })()
  }

  function handleCancelNavigate(): void {
    if (locationState?.beforeHistory) {
      navigate('/data-management/levels-and-classes/classroom/classroom-users', {
        state: {
          tab: 1,
          classroom: locationState.classroom,
          schoolYear: {
            name: locationState.schoolYear.name
          }
        }
      })
    } else {
      navigate(-1)
    }
  }

  const handleCancel = useCallback((): void => {
    if (
      !(
        enrollments.length ||
        currentTeachersSelected.length ||
        currentTeachersRemoveSelected.length ||
        removeEnrolled.length
      )
    ) {
      handleCancelNavigate()
    } else {
      setIsConfirmDialogOpen(true)
    }
    // DOCS: only re render enrollments currentTeachersSelected currentTeachersRemoveSelected removeEnrolled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enrollments.length,
    currentTeachersSelected.length,
    currentTeachersRemoveSelected.length,
    removeEnrolled.length
  ])

  useImperativeHandle(ref, () => ({ handleCancel }), [handleCancel])

  useEffect(() => {
    setStepState({
      data: teachersAlreadyEnrolled,
      enrollments: teachersAlreadyEnrolled,
      removeEnrollments: removeEnrolled,
      teachersToEnroll
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollments, removeEnrolled, teachersAlreadyEnrolled])

  useEffect(() => {
    const filteredTeachersToEnroll = allTeachersToEnroll.data?.registers.filter(
      (teacherToEnroll) =>
        !teachersAlreadyEnrolled.some(
          (enrolledTeacher) => enrolledTeacher.id === teacherToEnroll.id
        )
    )
    setTeachersToEnroll(filteredTeachersToEnroll ?? [])
    // DOCS: rerender only allTeachersToEnroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTeachersToEnroll.data?.registers])

  useEffect(() => {
    const filteredTeachersToEnroll =
      allTeachersToEnroll.data?.registers.filter(
        (teacherToEnroll) =>
          !teachersToEnroll.some((enrolledTeacher) => enrolledTeacher.id === teacherToEnroll.id)
      ) ?? []
    const filteredTeachersAlreadyEnrolled =
      allTeachersAlreadyEnrolled.data?.registers.filter(
        (teacherToEnroll) =>
          !teachersToEnroll.some((enrolledTeacher) => enrolledTeacher.id === teacherToEnroll.id)
      ) ?? []
    if (
      classroomTeachersAlreadyEnrolledParams.search?.length ||
      filteredTeachersToEnroll.length === allTeachersToEnroll.data?.registers.length
    ) {
      setTeachersAlreadyEnrolled(filteredTeachersAlreadyEnrolled)
    } else {
      setTeachersAlreadyEnrolled([...filteredTeachersToEnroll, ...filteredTeachersAlreadyEnrolled])
    }
    // DOCS: rerender only allTeachersAlreadyEnrolled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTeachersAlreadyEnrolled.data?.registers])

  useEffect(() => {
    if (
      inViewToEnrollment &&
      allTeachersToEnroll.data!.lastPage > classroomTeachersToEnrollParams.page!
    ) {
      setClassroomTeachersToEnrollParams((oldData) => ({
        ...oldData,
        page: oldData.page! + 1
      }))
    }
    // DOCS: rerender only inViewToEnrollment
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewToEnrollment])

  useEffect(() => {
    if (
      inViewAlreadyEnrolled &&
      allTeachersAlreadyEnrolled.data!.lastPage > classroomTeachersAlreadyEnrolledParams.page!
    ) {
      setClassroomTeachersAlreadyEnrolledParams((oldData) => ({
        ...oldData,
        page: oldData.page! + 1
      }))
    }
    // DOCS: rerender only inViewAlreadyEnrolled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inViewAlreadyEnrolled])

  useEffect(() => {
    setStepState({
      canGoNext: !(
        !allTeachersToEnroll.data?.registers.length &&
        !allTeachersAlreadyEnrolled.data?.registers.length
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allTeachersToEnroll.data?.registers.length,
    allTeachersAlreadyEnrolled.data?.registers.length
  ])

  return (
    <Grid css={{ justifyContent: 'space-between', display: 'flex' }}>
      <TextDialog
        isOpen={isConfirmDialogOpen}
        title={{
          label: i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialog.title
        }}
        contentTexts={[
          i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialog.contentText
        ]}
        onCancel={() => setIsConfirmDialogOpen(false)}
        refuseAction={{
          icon: <ArrowBackIcon size={18} />,
          label:
            i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialog.refuseAction,
          handle: () => setIsConfirmDialogOpen(false)
        }}
        acceptAction={{
          icon: <CloseIcon size={18} />,
          label:
            i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialog.acceptAction,
          handle: () => handleCancelNavigate()
        }}
        css={{ maxWidth: 600 }}
      />
      {isLoading &&
      !search?.length &&
      !nameAlreadyIntegrated?.length &&
      classroomTeachersAlreadyEnrolledParams.page! <= 1 &&
      classroomTeachersToEnrollParams.page! <= 1 ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            height: 600
          }}
        >
          <Loader size={80} />
        </Div>
      ) : !allTeachersToEnroll.data?.registers.length &&
        !allTeachersAlreadyEnrolled.data?.registers.length ? (
        <Div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            borderWidth: '$thin',
            borderStyle: 'solid',
            borderRadius: '$xl',
            borderColor: '$outline-variant',
            padding: '$md',
            gap: '$md'
          }}
        >
          <Div css={{ display: 'flex', gap: '$2xs' }}>
            <AddCircleIcon />
            <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
              {i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment.addTeaches}
            </Typography>
          </Div>
          <EmptyList
            title={
              i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment.withoutTeachers
            }
            subTitle={
              i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment.youNeedRegister
            }
          />
        </Div>
      ) : (
        <>
          <Grid
            xl={5.7}
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              borderColor: '$outline-variant',
              padding: '$md',
              gap: '$md',
              height: 600,
              overflow: 'hidden'
            }}
          >
            <Div css={{ display: 'flex', gap: '$2xs' }}>
              <AddCircleIcon />
              <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment.addTeaches}
              </Typography>
            </Div>
            {teachersToEnroll.length || search?.length ? (
              <>
                <TextField
                  variant="outlined"
                  label={i18n().modules.hub.classroom.pages.enrollments.form.textFieldLabel}
                  leadingIcon={{ icon: SearchIcon }}
                  inputProps={{
                    onChange: handleChangeSearchText
                  }}
                />
                {allTeachersToEnroll.isFetching && classroomTeachersToEnrollParams.page! <= 1 ? (
                  <Div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '$lg',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loader size={80} />
                  </Div>
                ) : (
                  <Div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '$2xs',
                      flex: 1,
                      overflow: 'auto'
                    }}
                  >
                    {!teachersToEnroll.length ? (
                      <EmptySearch />
                    ) : (
                      <>
                        <Div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '$md',
                            padding: '$md'
                          }}
                        >
                          <Checkbox
                            inputProps={{
                              checked: isAllTeachersSelected || !!currentTeachersSelected.length
                            }}
                            onClick={handleSelectAllTeachers}
                            indeterminate={
                              isAllTeachersSelected ? false : !!currentTeachersSelected
                            }
                          />
                          <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                            {
                              i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                                .title
                            }
                          </Typography>
                        </Div>
                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '$2xs',
                            flex: 1,
                            overflow: 'scroll'
                          }}
                        >
                          {teachersToEnroll.map((option) => (
                            <BaseCard
                              key={option.id}
                              css={{
                                borderRadius: '$lg',
                                backgroundColor: '$surface-2',
                                minHeight: '70px',

                                '& .BaseCard-StateLayer': {
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '$md'
                                }
                              }}
                            >
                              <Checkbox
                                inputProps={{
                                  checked: currentTeachersSelected.includes(option.id),
                                  onClick: () => handleSelectTeacher(option.id)
                                }}
                              />
                              <Image
                                FallbackImage={() => (
                                  <IconWrapper
                                    data-testid="Drawer-FallbackImage"
                                    size="$2xl"
                                    css={{ backgroundColor: '$primary-container' }}
                                  >
                                    <PersonIcon fill="$on-primary-container" />
                                  </IconWrapper>
                                )}
                                alt="img"
                                height={32}
                                width={32}
                                css={{ borderRadius: '$full' }}
                                src={option.picture}
                              />
                              <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                                {option.name}
                              </Typography>
                            </BaseCard>
                          ))}
                          <Div ref={refViewToEnrollment} />
                        </Div>
                      </>
                    )}
                  </Div>
                )}
              </>
            ) : (
              <>
                {!teachersToEnroll.length && !allTeachersToEnroll.isFetching && (
                  <EmptyList
                    title={
                      i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                        .noHaveTeachers
                    }
                  />
                )}
              </>
            )}
          </Grid>
          <Grid
            xl={0.6}
            css={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '$2xs'
            }}
          >
            <IconButton
              variant="tonal"
              onClick={() => handleMoveToIntegration()}
              disabled={!currentTeachersSelected.length}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            <IconButton
              variant="tonal"
              onClick={() => handleRemoveToIntegration()}
              disabled={!currentTeachersRemoveSelected.length}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Grid>
          <Grid
            xl={5.7}
            css={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '$thin',
              borderStyle: 'solid',
              borderRadius: '$xl',
              borderColor: '$outline-variant',
              padding: '$md',
              gap: '$md',
              height: 600,
              overflow: 'hidden'
            }}
          >
            <Div css={{ display: 'flex', gap: '$2xs' }}>
              <AddCircleIcon />
              <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                {
                  i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                    .addedTeachers
                }
              </Typography>
            </Div>

            {teachersAlreadyEnrolled.length || nameAlreadyIntegrated?.length ? (
              <>
                <TextField
                  variant="outlined"
                  label={
                    i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                      .searchByName
                  }
                  leadingIcon={{ icon: SearchIcon }}
                  inputProps={{
                    onChange: handleChangeNameAlreadyText
                  }}
                />
                {allTeachersAlreadyEnrolled.isFetching &&
                classroomTeachersAlreadyEnrolledParams.page! <= 1 ? (
                  <Div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '$lg',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Loader size={80} />
                  </Div>
                ) : (
                  <Div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '$2xs',
                      flex: 1,
                      overflow: 'auto'
                    }}
                  >
                    {!teachersAlreadyEnrolled.length ? (
                      <EmptySearch />
                    ) : (
                      <>
                        <Div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '$md',
                            padding: '$md'
                          }}
                        >
                          <Checkbox
                            inputProps={{
                              checked:
                                isAllToRemoveSelected || !!currentTeachersRemoveSelected.length
                            }}
                            onClick={handleSelectAllRemoveTeachers}
                            indeterminate={
                              isAllToRemoveSelected ? false : !!currentTeachersRemoveSelected
                            }
                          />
                          <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                            {
                              i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                                .title
                            }
                          </Typography>
                        </Div>
                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '$2xs',
                            flex: 1,
                            overflow: 'scroll'
                          }}
                        >
                          {teachersAlreadyEnrolled.map((option) => (
                            <BaseCard
                              key={option.id}
                              css={{
                                borderRadius: '$lg',
                                backgroundColor: '$surface-2',
                                minHeight: '70px',

                                '& .BaseCard-StateLayer': {
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '$md'
                                }
                              }}
                            >
                              <Checkbox
                                inputProps={{
                                  checked: currentTeachersRemoveSelected.includes(option.id),
                                  onClick: () => handleSelectRemoveTeacher(option.id)
                                }}
                              />
                              <Image
                                FallbackImage={() => (
                                  <IconWrapper
                                    data-testid="Drawer-FallbackImage"
                                    size="$2xl"
                                    css={{ backgroundColor: '$primary-container' }}
                                  >
                                    <PersonIcon fill="$on-primary-container" />
                                  </IconWrapper>
                                )}
                                alt="img"
                                height={32}
                                width={32}
                                css={{ borderRadius: '$full' }}
                                src={option.picture}
                              />
                              <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                                {option.name}
                              </Typography>
                            </BaseCard>
                          ))}
                          <Div ref={refViewAlreadyEnrolled} />
                        </Div>
                      </>
                    )}
                  </Div>
                )}
              </>
            ) : (
              <>
                {!teachersAlreadyEnrolled.length &&
                  !allTeachersAlreadyEnrolled.data?.registers.length &&
                  !allTeachersAlreadyEnrolled.isFetching && (
                    <EmptyList
                      title={
                        i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                          .withoutTeachers
                      }
                    />
                  )}
                {!teachersAlreadyEnrolled.length &&
                  !!allTeachersAlreadyEnrolled.data?.registers.length && (
                    <EmptyList
                      title={
                        i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment
                          .noHaveTeachers
                      }
                    />
                  )}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
})
