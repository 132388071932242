import { Div } from '@positivote/design-system/components/Div'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Main } from '@positivote/design-system/components/Main'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

export function Nedu(): JSX.Element {
  changePageTitle(i18n().modules.hub.dataAnalysis.pages.nedu.pageTitle)

  const navigate = useNavigate()
  const { profile } = useAuth()

  return (
    <Main css={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <AppBar
        title={i18n().modules.hub.dataAnalysis.pages.nedu.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {!profile ? (
          <WrappedLoader />
        ) : (
          <Iframe src={import.meta.env.VITE_NEDU_URL} css={{ width: '100%', height: '100%' }} />
        )}
      </Div>
    </Main>
  )
}
