import { Navigate, Route, Routes } from 'react-router-dom'

import { Nedu } from './pages/Nedu'

export function DataAnalysisRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Nedu />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
