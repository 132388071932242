import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  ApplicationPermission,
  ListApplicationPermissionHookParams,
  ListApplicationPermissionHookResult,
  ListLicenseByOrganizationHookParams,
  ListLicenseByOrganizationHookResult,
  ListLicenseHookParams,
  ListLicenseHookResult,
  UpdateApplicationPermissionHookParams,
  UpdateLicenseHookParams
} from './contracts'
import {
  listApplicationPermissionService,
  listLicenseByOrganizationService,
  listLicenseService,
  updateApplicationPermissionService,
  updateLicenseService
} from './services'

export const hookKey = 'licenses'

export function useListLicense({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListLicenseHookParams): UseQueryResult<ListLicenseHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    queryFn: async () => {
      try {
        const listedLicenses = await listLicenseService(model)
        onSuccess?.(listedLicenses)
        return listedLicenses
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListLicenseByOrganization({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListLicenseByOrganizationHookParams): UseQueryResult<
  ListLicenseByOrganizationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listByOrganization', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedLicensesByOrganization = await listLicenseByOrganizationService(model)
        onSuccess?.(listedLicensesByOrganization)
        return listedLicensesByOrganization
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListApplicationPermission({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListApplicationPermissionHookParams): UseQueryResult<
  ListApplicationPermissionHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listApplicationPermission', model],
    queryFn: async () => {
      try {
        const listedApplicationPermissions = await listApplicationPermissionService(model)
        onSuccess?.(listedApplicationPermissions)
        return listedApplicationPermissions
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateLicense(): UseMutationResult<
  void,
  ApplicationException,
  UpdateLicenseHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateLicenseHookParams) => {
      try {
        await updateLicenseService(model)
        await queryClient.invalidateQueries({
          queryKey: [hookKey, 'show', { licenseId: model.licenseId }]
        })
        addAlertMessage({
          alertKey: 'licenseUpdated',
          subTitle:
            i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.alert
              .updateApplicationLicenses,
          severity: 'success'
        })
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateApplicationPermission(): UseMutationResult<
  void,
  ApplicationException,
  UpdateApplicationPermissionHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [hookKey, 'updateApplicationPermission'],
    mutationFn: async ({
      model: { organizationId, applicationId, ...model },
      onSuccess,
      onError
    }: UpdateApplicationPermissionHookParams) => {
      try {
        await updateApplicationPermissionService(model)
        queryClient.setQueryData(
          [hookKey, 'listApplicationPermission', { applicationId, organizationId }],
          (oldPermission: ApplicationPermission[] | undefined) =>
            oldPermission?.map((permission) =>
              permission.id === model.permissionId
                ? {
                    ...permission,
                    permissible: model.permissible ?? permission.permissible,
                    anonymizable: model.anonymizable ?? permission.anonymizable
                  }
                : permission
            )
        )
        addAlertMessage({
          alertKey: 'applicationPermissionUpdated',
          subTitle:
            i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission.alert.updated,
          severity: 'success'
        })
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
