import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'

import {
  CreateWhiteLabelApiParams,
  ShowWhiteLabelApiResult,
  UpdateSkinApiParams,
  UpdateWhiteLabelApiParams,
  VerifyWhiteLabelAliasApiResult
} from './contracts/api'
import {
  CreateWhiteLabelServiceParams,
  ShowWhiteLabelByAliasServiceParams,
  ShowWhiteLabelByAliasServiceResult,
  ShowWhiteLabelServiceParams,
  ShowWhiteLabelServiceResult,
  UpdateSkinServiceParams,
  UpdateWhiteLabelServiceParams,
  VerifyWhiteLabelAliasServiceParams,
  VerifyWhiteLabelAliasServiceResult
} from './contracts/service'
import {
  createWhiteLabelApiParamsSanitizer,
  fromWhiteLabelApiResultSanitizer,
  updateWhiteLabelApiParamsSanitizer
} from './sanitizers'

export async function showWhiteLabelService({
  orgId
}: ShowWhiteLabelServiceParams): Promise<ShowWhiteLabelServiceResult> {
  const { data } = await hubApiService<null, ShowWhiteLabelApiResult>({
    resource: i18n().modules.hub.whiteLabel.resources.show,
    method: 'get',
    url: `/theme/v2/theme/organizations/${orgId}`,
    ignoreRefreshing: true
  })

  return fromWhiteLabelApiResultSanitizer(data)
}

export async function verifyWhiteLabelAliasService({
  alias
}: VerifyWhiteLabelAliasServiceParams): Promise<VerifyWhiteLabelAliasServiceResult> {
  const { data } = await hubApiService<null, VerifyWhiteLabelAliasApiResult>({
    resource: i18n().modules.hub.whiteLabel.resources.showAlias,
    method: 'get',
    url: `/theme/v2/theme/alias/${alias}/verify`
  })

  return data
}

export async function showWhiteLabelByAliasService({
  alias
}: ShowWhiteLabelByAliasServiceParams): Promise<ShowWhiteLabelByAliasServiceResult> {
  const { data } = await hubApiService<null, ShowWhiteLabelApiResult>({
    resource: i18n().modules.hub.whiteLabel.resources.showAlias,
    method: 'get',
    url: `/theme/v2/theme/alias/${alias}`
  })

  return fromWhiteLabelApiResultSanitizer(data)
}

export async function createWhiteLabelService({
  orgId,
  ...restProps
}: CreateWhiteLabelServiceParams): Promise<ShowWhiteLabelServiceResult> {
  const { data } = await hubApiService<CreateWhiteLabelApiParams, ShowWhiteLabelApiResult>({
    resource: i18n().modules.hub.whiteLabel.resources.create,
    method: 'post',
    url: `/theme/v2/theme/organizations/${orgId}`,
    body: createWhiteLabelApiParamsSanitizer(restProps)
  })
  return fromWhiteLabelApiResultSanitizer(data)
}

export async function updateWhiteLabelService({
  orgId,
  ...restProps
}: UpdateWhiteLabelServiceParams): Promise<ShowWhiteLabelServiceResult> {
  const { data } = await hubApiService<UpdateWhiteLabelApiParams, ShowWhiteLabelApiResult>({
    resource: i18n().modules.hub.whiteLabel.resources.update,
    method: 'put',
    url: `/theme/v2/theme/organizations/${orgId}`,
    body: updateWhiteLabelApiParamsSanitizer(restProps)
  })
  return fromWhiteLabelApiResultSanitizer(data)
}

export async function updateSkinService({
  accountCode,
  skin
}: UpdateSkinServiceParams): Promise<void> {
  await hubApiService<UpdateSkinApiParams, null>({
    resource: i18n().modules.hub.whiteLabel.resources.updateSkin,
    method: 'patch',
    url: `/users/v1/account/${accountCode}/skin`,
    body: { skin }
  })
}
