import { Badges } from '@positivote/design-system/components/Badges'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Select } from '@positivote/design-system/components/Select'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { Typography } from '@positivote/design-system/components/Typography'
import { AlertIcon } from '@positivote/design-system/icons/Alert'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { i18n } from '@/common/i18n'
import { UploadFileIcon } from '@/fixme/icons/uploadFile'

import { useAuth } from '../../auth/contexts'
import { ListDetailsDiscipline } from '../components/ListDetailsDiscipline'
import { ListDetailsEnrollmentStudent } from '../components/ListDetailsEnrollmentStudent'
import { ListDetailsEnrollmentTeacher } from '../components/ListDetailsEnrollmentTeacher'
import { ListDetailsGuardian } from '../components/ListDetailsGuardian'
import { ListDetailsSchoolClass } from '../components/ListDetailsSchoolClass'
import { ListDetailsStaff } from '../components/ListDetailsStaff'
import { ListDetailsStudent } from '../components/ListDetailsStudent'
import { ListDetailsTeacher } from '../components/ListDetailsTeacher'
import { useShowDiscipline } from '../hooks'
import { statusAcademicSync } from '../sanitizers'

export function DetailsAcademicSync(): JSX.Element {
  const { profile } = useAuth()
  const params = useParams()
  const navigate = useNavigate()
  const [activeTab] = useState(0)
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('all')

  const showAcademicSync = useShowDiscipline({
    model: {
      importId: params.id!,
      institutionId: profile?.organizationId as unknown as number
    },
    queryOptions: {
      enabled: !!params.id && !!profile
    }
  })

  const filterStatus = [
    {
      key: 'all',
      label: 'Todos os status'
    },
    { key: 'SUCCESS', label: 'Dados corretos', icon: <CheckCircleIcon fill="$success" /> },

    { key: 'ERROR', label: 'Dados ausentes ou incorretos', icon: <ErrorIcon fill="$critical" /> }
  ]

  const options = [
    {
      label: `Estudantes (${showAcademicSync.data?.student.success}/${showAcademicSync.data?.student.total})`,
      key: 'student',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsStudent
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.student}
          />
        </Div>
      )
    },
    {
      label: `Disciplinas (${showAcademicSync.data?.course.success}/${showAcademicSync.data?.course.total})`,
      key: 'course',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsDiscipline
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.course}
          />
        </Div>
      )
    },
    {
      label: `Turmas (${showAcademicSync.data?.classes.success}/${showAcademicSync.data?.classes.total})`,
      key: 'classes',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsSchoolClass
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.classes}
          />
        </Div>
      )
    },
    {
      label: `Responsáveis(${showAcademicSync.data?.guardian.success}/${showAcademicSync.data?.guardian.total})`,
      key: 'guardian',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsGuardian
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.guardian}
          />
        </Div>
      )
    },
    {
      label: `Professores(${showAcademicSync.data?.teacher.success}/${showAcademicSync.data?.teacher.total})`,
      key: 'teacher',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsTeacher
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.teacher}
          />
        </Div>
      )
    },
    {
      label: `Vínculos professores(${showAcademicSync.data?.enrollmentTeacher.success}/${showAcademicSync.data?.enrollmentTeacher.total})`,
      key: 'enrollmentTeacher',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsEnrollmentTeacher
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.enrollmentTeacher}
          />
        </Div>
      )
    },
    {
      label: `Colaboradores(${showAcademicSync.data?.staff.success}/${showAcademicSync.data?.staff.total})`,
      key: 'staff',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsStaff
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.staff}
          />
        </Div>
      )
    },
    {
      label: `Vínculos Estudantes(${showAcademicSync.data?.enrollmentStudent.success}/${showAcademicSync.data?.enrollmentStudent.total})`,
      key: 'enrollmentStudent',
      children: (
        <Div css={{ display: 'flex', flex: 1, padding: '$lg' }}>
          <ListDetailsEnrollmentStudent
            filterStatus={selectedFilterStatus}
            data={showAcademicSync.data?.enrollmentStudent}
          />
        </Div>
      )
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.schoolYear.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label: 'Minhas importações',
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: showAcademicSync.data?.filename
              ? `Arquivo - ${showAcademicSync.data.filename}`
              : '...',
            onClick: () => {
              navigate(-1)
            }
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {!showAcademicSync.data ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {showAcademicSync.isFetching && (
              <Loader data-testid="Loader-Container-DisciplineList" size={80} />
            )}
          </Div>
        ) : (
          <Div css={{ display: 'flex', gap: '$lg', flexDirection: 'column' }}>
            <BaseCard
              css={{
                padding: '24px',
                borderRadius: '$md',
                '& .BaseCard-StateLayer': { gap: '$md' }
              }}
            >
              <Div
                css={{
                  display: 'flex',
                  gap: '$md',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Div css={{ display: 'flex', gap: '$md' }}>
                  <Typography variant="titleMedium">{showAcademicSync.data.filename}</Typography>
                  <Chip label={showAcademicSync.data.schoolYearTitle} />
                </Div>
              </Div>
              <Divider />
              <Div css={{ display: 'flex', gap: '$lg' }}>
                <UploadFileIcon fill="$primary" size={64} />
                <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm', flex: 1 }}>
                  <Div css={{ display: 'flex', gap: '$sm' }}>
                    <Typography variant="titleMedium">Processado em: </Typography>
                    <Div css={{ display: 'flex', gap: '$sm' }}>
                      <Typography variant="bodyLarge">
                        {showAcademicSync.data.endOfProcessingFormatted}
                      </Typography>
                      <Div css={{ display: 'flex', alignItems: 'center' }}>
                        <Badges fill="$on-surface-variant" />
                      </Div>
                      <Typography variant="bodyLarge">
                        {showAcademicSync.data.timeFormatted}
                      </Typography>
                    </Div>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$sm' }}>
                    <Typography variant="titleMedium">Enviado por: </Typography>
                    <Div css={{ display: 'flex', alignItems: 'center', gap: '$sm' }}>
                      <IconWrapper size="$lg" css={{ backgroundColor: '$primary-container' }}>
                        <PersonIcon size={18} />
                      </IconWrapper>
                      <Typography variant="bodyLarge">
                        {showAcademicSync.data.userFullname}
                      </Typography>
                    </Div>
                  </Div>
                </Div>
                <Div css={{ display: 'flex', flexDirection: 'column', gap: '$sm', flex: 1 }}>
                  <Div css={{ display: 'flex', gap: '$sm', alignItems: 'center' }}>
                    <Typography variant="titleMedium">Status do arquivo: </Typography>
                    {showAcademicSync.data.statusFormatted ===
                      statusAcademicSync.PROCESS_SUCCESS && <CheckCircleIcon fill="$success" />}
                    {showAcademicSync.data.statusFormatted === statusAcademicSync.PROCESS_ERROR && (
                      <ErrorIcon fill="$critical" />
                    )}
                    {showAcademicSync.data.statusFormatted ===
                      statusAcademicSync.PROCESSED_WITH_ERROS && <AlertIcon fill="$warning" />}
                    <Typography variant="bodyLarge">
                      {showAcademicSync.data.statusFormatted}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$sm' }}>
                    <Typography variant="titleMedium">Total de usuários: </Typography>
                    <Typography variant="bodyLarge">
                      {showAcademicSync.data.totalUsers} usuários
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$sm' }}>
                    <Typography variant="titleMedium">Total de vínculos:</Typography>
                    <Typography variant="bodyLarge">
                      {showAcademicSync.data.totalEnrollments} associações
                    </Typography>
                  </Div>
                </Div>
              </Div>
            </BaseCard>
            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}>
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                <FilterListIcon size={18} />
                <Typography
                  data-testid="Typography-titleFilter"
                  variant="titleMedium"
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.hub.disciplines.pages.list.filter.title}
                </Typography>
              </Div>
              <Select
                label="Filtrar por status"
                variant="outlined"
                optionKeyField="key"
                leadingElementField="icon"
                optionTitleField="label"
                options={filterStatus}
                value={selectedFilterStatus}
                onChange={(event) => {
                  setSelectedFilterStatus(event!.key)
                }}
                css={{ width: '400px' }}
              />
            </Div>
            <Div css={{ display: 'flex', overflowX: 'scroll' }}>
              <Tabs options={options} currentTab={activeTab} />
            </Div>
          </Div>
        )}
      </Div>
    </Main>
  )
}
