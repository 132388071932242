import { Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function HubIcon(props: Omit<IconProps, 'fill' | 'ref'>): JSX.Element {
  return (
    <Svg
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ fill: 'none', pointerEvents: 'none', width: 34, height: 34 }}
      {...props}
    >
      <path
        d="M12.1111 16.9799C12.1903 17.0283 12.2607 16.9975 12.2915 16.9799C12.3267 16.9579 12.3839 16.9095 12.3751 16.8127C12.2651 15.3346 12.2431 13.9796 12.3091 12.7874C12.3091 12.739 12.2871 12.6862 12.2475 12.6466C11.1433 11.67 10.7166 10.6229 10.7166 8.91603C10.7166 6.1445 13.079 4.05925 16.2112 4.05925C16.5236 4.05925 16.8315 4.07685 17.1263 4.10764C17.1703 4.11204 17.2143 4.10764 17.2495 4.08125C17.3242 4.03725 17.3946 3.99326 17.4694 3.94927C18.9608 3.08701 20.5005 2.5943 22.0403 2.48431C22.1502 2.47552 22.1898 2.40073 22.1986 2.35234C22.2118 2.30834 22.2162 2.22036 22.1238 2.15877C20.5005 1.07655 18.5252 0.526642 16.2552 0.526642C13.5365 0.526642 11.2753 1.33611 9.53316 2.92864C7.77345 4.53877 6.8848 6.58443 6.8848 9.01282C6.8848 11.4412 7.61068 13.4693 9.04484 14.8022C9.80591 15.5105 10.8353 16.2452 12.1023 16.9799H12.1111Z"
        fill="url(#paint0_linear_24016_6836)"
      />
      <path
        d="M21.1119 17.6793C21.0327 17.631 20.9623 17.6618 20.9315 17.6793C20.8963 17.7013 20.8391 17.7497 20.8479 17.8465C20.9579 19.3247 20.9799 20.6796 20.9139 21.8718C20.9139 21.9202 20.9359 21.973 20.9755 22.0126C22.0797 22.9893 22.5065 24.0363 22.5065 25.7432C22.5065 28.5147 20.1441 30.6 17.0118 30.6C16.6994 30.6 16.3915 30.5824 16.0967 30.5516C16.0879 30.5516 16.0791 30.5516 16.0747 30.5516C16.0396 30.5516 16.0044 30.5604 15.978 30.578C15.9076 30.622 15.8328 30.666 15.758 30.71C14.2667 31.5722 12.7269 32.0649 11.1872 32.1749C11.0772 32.1837 11.0376 32.2585 11.0288 32.3069C11.0156 32.3509 11.0112 32.4389 11.1036 32.5005C12.7269 33.5827 14.7022 34.1326 16.9722 34.1326C19.6909 34.1326 21.9522 33.3231 23.6943 31.7306C25.454 30.1205 26.3426 28.0748 26.3426 25.6464C26.3426 23.218 25.6167 21.19 24.1826 19.857C23.4215 19.1487 22.3921 18.414 21.1251 17.6793H21.1119Z"
        fill="url(#paint1_linear_24016_6836)"
      />
      <path
        d="M32.6161 18.4096C32.1014 16.0824 30.7728 14.2875 28.6743 13.0733C26.5011 11.8195 24.4555 11.4764 22.5814 12.0483C21.5871 12.3519 20.4389 12.8798 19.1675 13.6056C19.0884 13.6496 19.0796 13.7244 19.0796 13.764C19.0796 13.8036 19.0972 13.8784 19.1807 13.918C20.5137 14.5603 21.6971 15.2202 22.7001 15.8757C22.7441 15.9021 22.7969 15.9109 22.8497 15.8933C24.2487 15.4269 25.3705 15.5765 26.8443 16.43C29.2419 17.8157 29.8709 20.904 28.3048 23.6184C28.1508 23.8867 27.9793 24.1463 27.8033 24.3882C27.7769 24.4234 27.7637 24.463 27.7637 24.507C27.7637 24.5906 27.7637 24.6786 27.7637 24.7622C27.7637 26.4823 27.4206 28.0616 26.7431 29.4562C26.6947 29.5574 26.7431 29.6278 26.7783 29.6586C26.8135 29.6938 26.8838 29.7421 26.985 29.6894C28.7359 28.8271 30.1965 27.3929 31.3315 25.4265C32.6909 23.0729 33.122 20.7105 32.6117 18.4052L32.6161 18.4096Z"
        fill="url(#paint2_linear_24016_6836)"
      />
      <path
        d="M4.55319 21.5903C6.72643 22.8441 8.77208 23.1872 10.6462 22.6153C11.6404 22.3118 12.7886 21.7839 14.06 21.058C14.1392 21.014 14.148 20.9392 14.148 20.8996C14.148 20.86 14.1304 20.7852 14.0468 20.7457C12.7138 20.1034 11.5304 19.4435 10.5274 18.788C10.4834 18.7616 10.4306 18.7528 10.3778 18.7704C8.97885 19.2367 7.85704 19.0871 6.38328 18.2337C3.98569 16.8479 3.35659 13.7596 4.92273 11.0453C5.0767 10.7769 5.24827 10.5174 5.42424 10.2754C5.42864 10.271 5.43304 10.2622 5.43744 10.2578C5.45504 10.227 5.46384 10.1918 5.46384 10.161C5.46384 10.0774 5.46384 9.98946 5.46384 9.90587C5.46384 8.18576 5.80698 6.60642 6.48007 5.21186C6.52846 5.11067 6.48007 5.04028 6.44487 5.00949C6.40968 4.9743 6.33929 4.9259 6.23811 4.9787C4.4872 5.84095 3.02665 7.27511 1.89164 9.24158C0.527867 11.5864 0.0967394 13.9488 0.607054 16.254C1.12177 18.5812 2.45034 20.3761 4.54879 21.5903H4.55319Z"
        fill="url(#paint3_linear_24016_6836)"
      />
      <path
        d="M18.1647 25.1317C18.3979 24.1155 18.5166 22.8617 18.521 21.3923C18.521 21.3 18.4639 21.256 18.4287 21.234C18.3935 21.2164 18.3231 21.19 18.2439 21.2428C17.0209 22.0786 15.8595 22.7737 14.7905 23.3104C14.7465 23.3324 14.7113 23.3808 14.7025 23.4336C14.4077 24.8766 13.717 25.774 12.2389 26.6275C9.8413 28.0132 6.8498 27.0102 5.28366 24.2959C5.12969 24.0275 4.98891 23.7503 4.86573 23.4776C4.84814 23.438 4.81734 23.4028 4.78215 23.3852C4.70736 23.3456 4.63257 23.3016 4.55778 23.2576C3.06643 22.3954 1.86984 21.3088 1.00318 20.0286C0.941591 19.9362 0.853606 19.945 0.809613 19.9538C0.765621 19.967 0.686434 20.0022 0.677636 20.1166C0.550057 22.0654 1.06037 24.0495 2.19538 26.016C3.55475 28.3696 5.38485 29.9225 7.63727 30.6352C9.91169 31.3523 12.1289 31.1015 14.2274 29.8873C16.4006 28.6335 17.7204 27.0322 18.1603 25.1229L18.1647 25.1317Z"
        fill="url(#paint4_linear_24016_6836)"
      />
      <path
        d="M14.9838 9.50993C14.7507 10.5262 14.6319 11.78 14.6275 13.2493C14.6275 13.3417 14.6847 13.3857 14.7199 13.4033C14.7551 13.4209 14.8255 13.4473 14.9047 13.3945C16.1277 12.5586 17.2891 11.8635 18.3581 11.3268C18.4021 11.3048 18.4373 11.2564 18.4461 11.2037C18.7408 9.76069 19.4315 8.86324 20.9096 8.00979C23.3072 6.62402 26.2987 7.62705 27.8649 10.3414C28.0189 10.6097 28.1596 10.8869 28.2828 11.1597C28.2828 11.1685 28.2916 11.1729 28.2916 11.1817C28.3092 11.2124 28.3356 11.2388 28.362 11.252C28.4368 11.2916 28.5116 11.3356 28.5864 11.3796C30.0777 12.2419 31.2743 13.3285 32.141 14.6087C32.2026 14.7011 32.2905 14.6923 32.3345 14.6835C32.3785 14.6703 32.4577 14.6351 32.4665 14.5207C32.5941 12.5718 32.0838 10.5878 30.9488 8.62128C29.5894 6.26768 27.7593 4.71474 25.5069 4.00206C23.2325 3.28498 21.0152 3.53574 18.9168 4.74993C16.7435 6.00372 15.4238 7.60505 14.9838 9.51433V9.50993Z"
        fill="url(#paint5_linear_24016_6836)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_24016_6836"
          x1="-0.451858"
          y1="17.3282"
          x2="34.9555"
          y2="17.3282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
      </defs>
    </Svg>
  )
}
