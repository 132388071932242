import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'
import {
  AcceptTermApiParams,
  AcceptTermApiResult,
  AcceptTermServiceParams,
  AcceptTermServiceResult
} from '@/modules/hub/terms/contracts'

export async function acceptTermService({
  termId,
  ...restProps
}: AcceptTermServiceParams): Promise<AcceptTermServiceResult> {
  const { data } = await hubApiService<AcceptTermApiParams, AcceptTermApiResult>({
    resource: i18n().modules.hub.terms.resources.accept,
    method: 'post',
    url: `/terms-of-service/v1/terms/${termId}/accepted`,
    body: restProps
  })
  return data
}
