import {
  OnboardingInRegisterStageKey,
  OnboardingInRegisterStageStatus,
  OnboardingOrgNetworkType,
  OnboardingOrgType
} from '@/modules/hub/onboarding/contracts'

export const onboardingPt = {
  resources: {
    listPreRegisterService: 'Listar escolas/redes em pré-registro',
    resendCode: 'Reenviar código por e-mail',
    showInRegisterDetails: 'Visualizar detalhes de escolas/redes em pré-registro'
  },
  notStarted: 'Não iniciado',
  org: {
    type: {
      [OnboardingOrgType.SCHOOL]: 'Escola',
      [OnboardingOrgType.TEACHING_NETWORK]: 'Rede de ensino'
    },
    networkType: {
      [OnboardingOrgNetworkType.PRIVATE]: 'Privada',
      [OnboardingOrgNetworkType.PUBLIC]: 'Pública'
    }
  },
  inRegisterStageKey: {
    [OnboardingInRegisterStageKey.USER_MASTER_CREATED]: 'Cadastro de Admins',
    [OnboardingInRegisterStageKey.USER_MASTER_NOT_CREATED]: 'Cadastro de admins não iniciado',
    [OnboardingInRegisterStageKey.SCHOOL_CREATED]: 'Cadastro de Escola',
    [OnboardingInRegisterStageKey.SCHOOL_NOT_CREATED]: 'Cadastro de escola não iniciado',
    [OnboardingInRegisterStageKey.CURRENT_PERIOD_ACTIVATED]: 'Cadastro de Ano Letivo',
    [OnboardingInRegisterStageKey.CURRENT_PERIOD_NOT_ACTIVATED]: 'Ano letivo não iniciado',
    [OnboardingInRegisterStageKey.SCHOOL_ENROLLMENTS_STARTED]: 'Ensalamento',
    [OnboardingInRegisterStageKey.SCHOOL_ENROLLMENTS_NOT_STARTED]: 'Matrículas não iniciado',
    [OnboardingInRegisterStageKey.IMPORT_MODE_SETUP_CONFIGURED]:
      'Importação configurada manualmente',
    [OnboardingInRegisterStageKey.IMPORT_MODE_DEFAULT_CONFIGURED]:
      'Importação configurada por padrão',
    [OnboardingInRegisterStageKey.LICENSES_CONFIGURED]: 'Licenças configuradas',
    [OnboardingInRegisterStageKey.LICENSES_NOT_CONFIGURED]: 'Licenças não configuradas'
  },
  inRegisterStageStatus: {
    [OnboardingInRegisterStageStatus.COMPLETED]: 'Concluiu o cadastro',
    [OnboardingInRegisterStageStatus.TODO]: 'Pendente'
  },
  pages: {
    list: {
      pageTitle: 'Acompanhamento de Cadastros',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          registrationTracking: 'Acompanhamento de Cadastros'
        }
      },
      filter: {
        title: 'Opções de filtragem',
        input: {
          label: 'Buscar ...',
          supportingText: (organizationKind?: string): string =>
            `A busca poderá ser feita pelo nome da ${
              organizationKind === 'SCHOOL' ? 'escola' : 'rede'
            }.`
        },
        schools: 'Escolas',
        networks: 'Redes'
      },
      tabs: {
        common: {
          searching: 'Buscando por resultados ...',
          searchingResult: 'Resultado da busca ...'
        },
        inRegister: {
          title: 'Em cadastro',
          emptyList: (organizationKind?: string): string =>
            `Ops! Ainda não temos ${
              organizationKind === 'SCHOOL' ? 'escola' : 'rede'
            } em cadastro para exibir`,
          header: {
            id: 'Código',
            name: 'Nome',
            address: 'Endereço',
            kind: 'Tipo',
            status: 'Status'
          }
        },
        preRegister: {
          title: 'Pré-cadastro',
          emptyList: (organizationKind?: string): string =>
            `Ops! Ainda não temos ${
              organizationKind === 'SCHOOL' ? 'escola' : 'rede'
            } em pré-cadastro para exibir`,
          header: {
            name: 'Nome',
            address: 'Endereço',
            type: 'Tipo'
          },
          body: {
            resendEmail: 'Reenviar e-mail'
          },
          alert: {
            codeResent: 'E-mail reenviado com sucesso!'
          }
        }
      }
    },
    details: {
      pageTitle: 'Detalhes do Cadastro',
      userActivities: 'Atividades dos Usuários',
      recentUpdate: 'Atualização Recente',
      stage: 'Etapa',
      status: 'Status',
      lastUpdate: 'Última Atualização',
      hubRegisteredUsers: 'Usuários cadastrados no HUB',
      professionalProfiles: 'Perfis Profissionais',
      studentsQuantity: 'Quantidade de Alunos',
      applicationLicenses: 'Licenças das Aplicações'
    }
  }
}
