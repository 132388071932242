import { ListStateApiResult, State } from './contracts'

export function listStateFormatSanitizer(states: ListStateApiResult['data']): State[] {
  return states.map((state) => ({
    id: String(state.id),
    countryCode: state.country_code,
    name: state.name,
    stateCode: state.state_code
  }))
}
