import { contractPt } from '@/modules/billing/contracts/i18n'
import { salesOrderPt } from '@/modules/billing/sales-orders/i18n'
import { serviceMappingPt } from '@/modules/billing/service-mapping/i18n'
import { academicSyncPt } from '@/modules/hub/academic-sync/i18n'
import { accountPt } from '@/modules/hub/accounts/i18n'
import { applicationPt } from '@/modules/hub/applications/i18n'
import { authPt } from '@/modules/hub/auth/i18n'
import { classroomPt } from '@/modules/hub/classroom/i18n'
import { dataAnalysisPt } from '@/modules/hub/data-analysis/i18n'
import { dataIntelligencePt } from '@/modules/hub/data-intelligence/i18n'
import { dataManagementPt } from '@/modules/hub/data-management/i18n'
import { disciplinePt } from '@/modules/hub/disciplines/i18n'
import { hubotPt } from '@/modules/hub/hubot/i18n'
import { licensesPt } from '@/modules/hub/licenses/i18n'
import { localesPt } from '@/modules/hub/locales/i18n'
import { ltiPt } from '@/modules/hub/lti/i18n'
import { ciascPt } from '@/modules/hub/monitoora/i18n'
import { onboardingPt } from '@/modules/hub/onboarding/i18n'
import { organizationPt } from '@/modules/hub/organizations/i18n'
import { profilePt } from '@/modules/hub/profiles/i18n'
import { quickAcessPt } from '@/modules/hub/quick-access/i18n'
import { reportsPt } from '@/modules/hub/reports/i18n'
import { schoolYearPt } from '@/modules/hub/school-year/i18n'
import { securityConfigurationPt } from '@/modules/hub/security-configuration/i18n'
import { settingsPt } from '@/modules/hub/settings/i18n'
import { termsPt } from '@/modules/hub/terms/i18n'
import { usersPt } from '@/modules/hub/users/i18n'
import { whiteLabelPt } from '@/modules/hub/white-label/i18n'
import { monitooraPt } from '@/modules/monitoora/i18n'

import { commonPt } from './pt'

const i18nDict = {
  pt: {
    common: commonPt,
    modules: {
      billing: {
        contracts: contractPt,
        salesOrders: salesOrderPt,
        serviceMapping: serviceMappingPt
      },
      monitoora: monitooraPt,
      hub: {
        accounts: accountPt,
        applications: applicationPt,
        auth: authPt,
        dataAnalysis: dataAnalysisPt,
        dataIntelligence: dataIntelligencePt,
        dataManagement: dataManagementPt,
        hubot: hubotPt,
        licenses: licensesPt,
        lti: ltiPt,
        onboarding: onboardingPt,
        organizations: organizationPt,
        profiles: profilePt,
        quickAccess: quickAcessPt,
        reports: reportsPt,
        classroom: classroomPt,
        securityConfiguration: securityConfigurationPt,
        settings: settingsPt,
        terms: termsPt,
        users: usersPt,
        whiteLabel: whiteLabelPt,
        schoolYear: schoolYearPt,
        locales: localesPt,
        disciplines: disciplinePt,
        ciasc: ciascPt,
        academicSync: academicSyncPt
      }
    }
  }
}

export function i18n(): typeof i18nDict.pt {
  const currentLanguage = 'pt'

  return i18nDict[currentLanguage]
}
