export const disciplinePt = {
  resources: {
    listDiscipline: 'Listar disciplinas',
    removeDiscipline: 'Remover disciplina',
    bulkRemoveDiscipline: 'Remover disciplinas',
    showDiscipline: 'Exibir disciplina',
    updateDiscipline: 'Atualizar disciplina',
    createDiscipline: 'Criar disciplina',
    listBaseDiscipline: 'Listar disciplinas base',
    availableDiscipline: 'Avaliar disciplina'
  },
  components: {
    assingDisciplineDialog: {
      title: 'Atribuir disciplina a professor(a)',
      subtitle: (teacherName: string): string =>
        `Selecione abaixo, qual ou quais disciplinas a Prof.ª ${teacherName} lecionará para esta turma.`,
      selectDiscipline: 'Selecionar disciplinas',
      canSelectMultipleOptions: 'É possível selecionar uma ou mais opções.',
      cancel: 'Cancelar',
      add: 'Adicionar'
    }
  },
  pages: {
    list: {
      pageTitle: 'Disciplinas',
      emptyList: 'Ops! Ainda não temos disciplinas para exibir',
      discipline: 'Disciplina',
      code: 'Código',
      disciplineBase: 'Disciplina base',
      includeNewDiscipline: 'Incluir nova disciplina',
      removeDialog: {
        title: (isBulk: boolean): string => (isBulk ? 'Remover disciplinas' : 'Remover disciplina'),
        contentTextMain: 'Todas as turmas e usuários vinculados serão apagados.',
        contentText: `Tem certeza que deseja remover o cadastro da disciplina `,
        selectedName: (name: string | undefined): string => `${name}?`,
        bulkContentTextMain: 'Todas as turmas e usuários vinculados serão apagados.',
        bulkContentText: 'Tem certeza que deseja remover o cadastro das disciplinas selecionadas?',
        refuse: 'Cancelar',
        accept: 'Sim, quero remover'
      },
      filter: {
        title: 'Opções de filtragem',
        school: 'Selecionar escola',
        search: 'Buscar...'
      },
      appBar: {
        title: 'Disciplinas',
        breadcrumbs: {
          overview: 'Visão geral',
          educationLevelsAndClasses: 'Níveis de ensino e turmas',
          disciplines: 'Disciplinas'
        },
        buttonTitle: 'Incluir novo ano letivo'
      },
      alert: {
        remove: 'Disciplina removida com sucesso!',
        bulkRemove: 'Disciplinas removido com sucesso!',
        createDisciplina: 'Disciplina criada com sucesso!',
        updateDisciplina: 'Disciplina atualizada com sucesso!'
      }
    },
    form: {
      pageTitle: (isEditing: boolean): string => `${isEditing ? 'Editar' : 'Nova'} disciplina`,
      appBar: {
        breadcrumbs: {
          overview: 'Visão geral',
          educationLevelsAndClasses: 'Níveis de ensino e turmas',
          disciplines: 'Disciplinas',
          newDiscipline: 'Nova disciplina',
          editDiscipline: 'Editar disciplina'
        },
        buttonTitle: 'Incluir novo ano letivo'
      },
      stepper: {
        disciplineData: {
          title: '* Campo de preenchimento obrigatório',
          stepTitle: 'Dados da disciplina',
          discipline: 'Nome da disciplina',
          baseDiscipline: 'Disciplina base',
          code: 'Código',

          validators: {
            requiredName: 'Parece que você não inseriu o nome da disciplina ainda',
            minNameLength: 'É necessário inserir um nome com no mínimo 3 caracteres',
            requiredDisciplineBase: 'Você precisa selecionar uma disciplina base',
            requiredCode: 'Parece que você não inseriu o código da disciplina ainda',
            minCodeLength: 'É necessário inserir um código com no mínimo 3 caracteres',
            nameDisciplineExist: 'Este nome de disciplina já existe',
            codeDisciplineExist: 'Este código já existe'
          }
        },
        preview: {
          stepTitle: 'Pré-visualização',
          saveDiscipline: 'Finalizar cadastro',
          baseDiscipline: 'Disciplina base:',
          code: 'Código:'
        }
      }
    }
  }
}
