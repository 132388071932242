import { Navigate, Route, Routes } from 'react-router-dom'

import { ApplicationAccess } from '@/modules/hub/licenses/pages/ApplicationAccess'

export function LicenseRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/:licenseId" element={<ApplicationAccess />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
