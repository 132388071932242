import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { useTheme } from '@positivote/design-system/hooks'
import { BrushIcon } from '@positivote/design-system/icons/Brush'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { Role } from '@/modules/hub/profiles/contracts'
import { ProfileDetails } from '@/modules/hub/profiles/pages/Details'
import { WhiteLabelCustomizationForm } from '@/modules/hub/white-label/pages/Customization/Form'
import { WhiteLabelCustomizationUniverse } from '@/modules/hub/white-label/pages/Customization/Universe'
import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

export function SettingsList(): JSX.Element {
  changePageTitle(i18n().modules.hub.settings.pages.list.pageTitle)
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('myData')
  const { breakpoint } = useTheme()

  const { profile } = useAuth()

  const profileTabs: Record<Role['code'], string[]> = {
    administrador: ['personalization'],
    instituicao: ['personalization'],
    aluno: ['universe'],
    professor: [],
    colaborador: [],
    responsavel: [],
    diretor: [],
    coordenador: [],
    billing: [],
    ...MONITOORA_ROLES.reduce((prevRoles, role) => ({ ...prevRoles, [role]: [] }), {})
  }

  const tabs = [
    {
      label: i18n().modules.hub.settings.pages.list.tabs.personalization,
      key: 'personalization',
      disable: true,
      icon: BrushIcon,
      children:
        breakpoint <= Breakpoint.md ? <LargerScreensOnly /> : <WhiteLabelCustomizationForm />,
      breakpoint: Breakpoint.lg
    },
    {
      label: i18n().modules.hub.settings.pages.list.tabs.personalization,
      key: 'universe',
      disable: false,
      icon: BrushIcon,
      children: <WhiteLabelCustomizationUniverse />,
      breakpoint: Breakpoint.lg
    }
  ]

  const currentTabOptions = (profile?.role.code && profileTabs[profile.role.code]) ?? []

  const filteredTabs = [
    {
      label: i18n().modules.hub.settings.pages.list.tabs.myData,
      key: 'myData',
      disable: false,
      icon: PersonIcon,
      children: <ProfileDetails />,
      breakpoint: null
    },
    ...tabs.filter(
      (tab) =>
        currentTabOptions.includes(tab.key) &&
        (breakpoint >= tab.breakpoint || tab.key === activeTab)
    )
  ]

  return (
    <Main
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden'
      }}
    >
      <AppBar
        title={i18n().modules.hub.settings.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      <Tabs options={filteredTabs} onChangeTab={(tab) => setActiveTab(tab)} />
    </Main>
  )
}
