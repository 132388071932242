import { Navigate, Route, Routes } from 'react-router-dom'

import { EngagementPerformance } from './pages/EngagementPerformance'

export function DataIntelligenceRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<EngagementPerformance />} />

      <Route path="*" element={<Navigate replace to="not-found" />} />
    </Routes>
  )
}
