import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ShowEngagementPerformanceHookParams,
  ShowEngagementPerformanceHookResult
} from './contracts/hooks'
import { showEngagementPerformanceService } from './services'

export const hookKey = 'data-intelligence'

export function useShowEngagementPerformance({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowEngagementPerformanceHookParams): UseQueryResult<
  ShowEngagementPerformanceHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showEngagementPerformance', model],
    queryFn: async () => {
      try {
        const resultEngagementPerformanceService = await showEngagementPerformanceService(model)
        onSuccess?.(resultEngagementPerformanceService)
        return resultEngagementPerformanceService
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw error
      }
    },
    ...queryOptions
  })
}
