import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { changePageTitle } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { CodeTextField } from '@/modules/hub/auth/components/CodeTextField'
import { useAuth } from '@/modules/hub/auth/contexts'
import { QuickAccessCodeError } from '@/modules/hub/auth/contracts/apis'
import { QuickAccessForm } from '@/modules/hub/auth/contracts/forms'
import { quickAccessValidationSchema } from '@/modules/hub/auth/validations'

export function QuickAccess(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.quickAccess.pageTitle)

  const [codeError, setCodeError] = useState<QuickAccessCodeError>(null)

  const navigate = useNavigate()
  const { createSession, isLoading } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })

  const {
    control,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<QuickAccessForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) => yupResolver(quickAccessValidationSchema)(values, ...args)
  })
  const hasAnyError = !!Object.keys(errors).length || !!codeError

  function onSubmit(model: QuickAccessForm): void {
    void createSession({
      model: {
        authFlow: 'QUICK_ACCESS',
        username: model.username,
        code: model.code.join('')
      },
      onError: ({ error }) => {
        if (
          error.code &&
          ['LOGIN.EXPIRED_CODE', 'LOGIN.INVALID_CODE', 'LOGIN.USER_NOT_FOUND'].includes(error.code)
        ) {
          setCodeError(error.code as QuickAccessCodeError)
        } else {
          handleError({ error })
        }
      }
    })
  }

  return (
    <BaseBackground>
      <Grid xl={4} lg={6} sm={10} xs={12}>
        <FormContainer
          formHandleSubmit={handleSubmit}
          onSubmit={onSubmit}
          css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Typography
            data-testid="Typography-title"
            variant="headlineMedium"
            css={{ color: '$on-surface', textAlign: 'center' }}
          >
            {i18n().modules.hub.auth.pages.quickAccess.title}
          </Typography>
          <Div css={{ display: 'flex', marginTop: '$lg', '@sm': { marginTop: '$md' } }}>
            <IconWrapper
              size="$2xl"
              css={{
                backgroundColor: '$primary-container',
                marginTop: '$2xs',
                marginRight: '$md',
                '@xs': { display: 'none' }
              }}
            >
              <PersonIcon fill="$on-primary-container" />
            </IconWrapper>
            <FormTextField
              control={control}
              name="username"
              label={i18n().modules.hub.auth.pages.quickAccess.inputs.username}
              variant="outlined"
              hasError={codeError === 'LOGIN.INVALID_CODE' || codeError === 'LOGIN.USER_NOT_FOUND'}
              errorText={errors.username?.message}
              inputProps={{
                autoComplete: 'new-password',
                autoFocus: true,
                inputMode: 'email',
                onChange: () => setCodeError(null)
              }}
            />
          </Div>
          <Typography
            data-testid="Typography-instructions"
            variant="bodyLarge"
            css={{
              color: '$on-surface-variant',
              textAlign: 'center',
              marginTop: '$lg',
              '@sm': { marginTop: '$md' }
            }}
          >
            {i18n().modules.hub.auth.pages.quickAccess.instructions}
          </Typography>
          <Div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '$lg',
              '@sm': { marginTop: '$md' }
            }}
          >
            <CodeTextField
              index={0}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
            />
            <CodeTextField
              index={1}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
              css={{ marginLeft: '$2xs' }}
            />
            <CodeTextField
              index={2}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
              css={{ marginLeft: '$2xs' }}
            />
            <Typography
              data-testid="Typography-dash"
              variant="headlineMedium"
              css={{ color: '$on-surface-variant', textAlign: 'center', marginLeft: '$2xs' }}
            >
              -
            </Typography>
            <CodeTextField
              index={3}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
              css={{ marginLeft: '$2xs' }}
            />
            <CodeTextField
              index={4}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
              css={{ marginLeft: '$2xs' }}
            />
            <CodeTextField
              index={5}
              control={control}
              setFocus={setFocus}
              setValue={setValue}
              errors={errors}
              codeError={codeError}
              setCodeError={setCodeError}
              css={{ marginLeft: '$2xs' }}
            />
          </Div>
          {(codeError ?? errors.code?.some?.((error) => !!error)) && (
            <Typography
              data-testid="Typography-expiredCode"
              variant="bodySmall"
              lineClamp={2}
              css={{
                color: '$critical',
                textAlign: 'center',
                marginTop: '$lg',
                '@sm': { marginTop: '$md' }
              }}
            >
              {errors.code?.some?.((error) => !!error) ? (
                i18n().common.validators.required
              ) : codeError === 'LOGIN.EXPIRED_CODE' ? (
                <>
                  {i18n().modules.hub.auth.pages.quickAccess.error.expiredCode}
                  <br />
                  {i18n().modules.hub.auth.pages.quickAccess.error.generateCode}
                </>
              ) : (
                i18n().modules.hub.auth.pages.quickAccess.error.invalidCode
              )}
            </Typography>
          )}
          <Div
            css={{
              display: 'flex',
              marginTop: '$lg',
              '@xs': { flexDirection: 'column-reverse', marginTop: '$md' }
            }}
          >
            <Button
              data-testid="goBack"
              variant="outlined"
              css={{ flex: 1 }}
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              {i18n().modules.hub.auth.pages.quickAccess.buttons.goBack}
            </Button>
            <Button
              data-testid="continue"
              type="submit"
              variant="filled"
              css={{
                flex: 1,
                marginLeft: '$lg',
                '@xs': { marginLeft: '$none', marginBottom: '$md' }
              }}
              isLoading={isLoading}
              disabled={hasAnyError}
            >
              {i18n().modules.hub.auth.pages.quickAccess.buttons.continue}
            </Button>
          </Div>
        </FormContainer>
      </Grid>
    </BaseBackground>
  )
}
