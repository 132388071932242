export const usersPt = {
  resources: {
    updateUser: 'Atualizar usuário',
    createUser: 'Criar usuário',
    availableUser: 'Avaliar usuário',
    showUser: 'Exibir usuário',
    bulkRemoveUser: 'Remover usuário',
    listTeacher: 'Listar professores'
  },
  pages: {
    list: {
      pageTitle: 'Meus usuários',
      appBar: {
        title: 'Gerenciamento de dados',
        newUser: 'Novo usuário',
        breadcrumbs: {
          overview: 'Visão geral',
          title: 'Meus usuários'
        }
      },
      removeDialog: {
        title: (isBulk: boolean): string => (isBulk ? 'Remover usuários' : 'Remover usuário'),
        contentTextMain: 'Tem certeza que deseja remover o(a) usuário(a) ',
        contentText: `Tem certeza que deseja remover o cadastro da disciplina `,
        selectedName: (name: string | undefined): string => `${name}?`,
        bulkContentTextMain: 'Tem certeza que deseja remover os usuários ',
        refuse: 'Cancelar',
        selections: 'selecionados?',
        accept: 'Sim, quero remover'
      },
      filter: {
        searchLabel: 'Buscar...',
        title: 'Opções de filtragem',
        select: 'Selecionar perfil',
        searching: 'Buscando por resultados...',
        searchingResult: 'Resultado da busca...',
        selectUserLinks: 'Vínculos de usuários',
        bonds: {
          studentsWithClasses: 'Estudantes vinculados a turmas',
          studentsWithoutClasses: 'Estudantes sem vínculo à turma',
          teachersWithClasses: 'Professores vinculados a turmas',
          teachersWithoutClasses: 'Professores sem vínculos a turmas',
          guardiansWithStudents: 'Responsável vinculado a estudantes',
          guardiansWithoutStudents: 'Responsável sem vínculos a estudantes'
        }
      },
      header: {
        username: 'Nome do usuário',
        enrollment: 'Código/Matrícula',
        email: 'E-mail',
        imageUsageTerm: 'Uso de imagem',
        profile: 'Perfis'
      },
      body: {
        switchLabel: (permission: boolean): string => (permission ? 'Permite' : 'Não permite')
      },
      emptyList: 'Ops! Ainda não temos usuários para exibir'
    },
    form: {
      pageTitle: (isEditing: boolean): string => `${isEditing ? 'Editar' : 'Novo'} usuário`,
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          myUsers: 'Meus usuários',
          newUsers: 'Novo usuário',
          editUser: 'Editar usuário'
        }
      },
      stepper: {
        registerData: {
          title: '* Campo de preenchimento obrigatório',
          stepTitle: 'Dados do Usuário',
          discipline: 'Nome da disciplina',
          baseDiscipline: 'Disciplina base',
          profilePhoto: 'Foto de perfil',
          userProfiles: 'Perfis do usuário *',
          assignProfile: 'Atribuir Perfil',
          name: 'Nome',
          lastName: 'Sobrenome',
          registration: 'Matricula',
          login: 'Login',
          birthday: 'Data de nascimento',
          email: 'E-mail',
          gender: 'Gênero',
          document: 'CPF',
          phone: 'Celular',
          linkStudents: 'Vincular estudantes',
          searchStudents: 'Buscar estudante...',
          searchResult: 'Resultado da busca:',
          searchStudentsSupportingText: 'Digite o nome ou a matrícula do estudante.',
          allowedImageUseOnEdtech:
            'Este usuário permite o uso de imagem na plataforma e em demais aplicações.',
          emptyList: 'Ops! Não temos mais estudantes para exibir',
          responsibleFor: 'Responsável por',
          validators: {
            minRoles: 'É necessário atribuir ao menos 1 perfil para este usuário.',
            requiredName: 'Parece que você não inseriu o nome.',
            minName: 'É necessário inserir um nome com no mínimo 2 caracteres',
            requiredLastName: 'Parece que você não inseriu o sobrenome.',
            minLastName: 'É necessário inserir um sobrenome. com no mínimo 2 caracteres',
            requiredCode: 'Você ainda não inseriu um código ou matrícula.',
            minCode: 'É necessário inserir um matrícula com no mínimo 3 caracteres',
            requiredLogin: 'Você precisa inserir um login para este usuário.',
            minLogin: 'É necessário inserir um login com no mínimo 3 caracteres',
            requiredEmail: 'Você precisa inserir um e-mail para este usuário.',
            requiredBirthday: 'Selecione uma data de nascimento.',
            invalidBirthday: 'Selecione uma data válida',
            requiredDocument: 'Você precisa indicar o CPF.',
            invalidLogin: 'Este login já existe nesta escola',
            invalidCode: 'Este matrícula já existe nesta escola',
            invalidCharacterLogin: 'Login não pode conter espaços ou caracteres especiais',
            invalidCharacterCode: 'Matricula não pode conter espaços ou caracteres especiais',
            invalidRole: 'O perfil estudante não pode ter outros perfis atribuídos.'
          }
        },
        preview: {
          title: '* Campo de preenchimento obrigatório',
          stepTitle: 'Pré-visualização',
          saveUser: 'Finalizar cadastro',
          login: 'Login:',
          email: 'E-mail',
          code: 'Código/Matrícula:',
          cpf: 'CPF:',
          birthday: 'Data de nascimento:',
          gender: 'Gênero:',
          phone: 'Celular:',
          userProfiles: 'Perfis do usuário',
          allowedImageUseOnEdtech:
            'Este usuário permite o uso de imagem na plataforma e em demais aplicações.'
        }
      }
    }
  }
}
