import { typographyStyleVariants } from '@positivote/design-system/components/Typography'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { CSS } from '@positivote/design-system/theme'
import { Control, FieldErrors, UseFormSetFocus, UseFormSetValue } from 'react-hook-form'

import { CODE_TEXT_FIELD_LENGTH } from '@/modules/hub/auth/constants'
import { QuickAccessCodeError } from '@/modules/hub/auth/contracts/apis'
import { QuickAccessForm } from '@/modules/hub/auth/contracts/forms'

export function CodeTextField({
  index,
  control,
  setFocus,
  setValue,
  errors,
  codeError,
  setCodeError,
  css
}: {
  index: number
  control: Control<QuickAccessForm>
  setFocus: UseFormSetFocus<QuickAccessForm>
  setValue: UseFormSetValue<QuickAccessForm>
  errors: FieldErrors<QuickAccessForm>
  codeError: QuickAccessCodeError
  setCodeError: (codeError: QuickAccessCodeError) => void
  css?: CSS
}): JSX.Element {
  return (
    <FormTextField
      control={control}
      name={`code.${index}`}
      inputProps={{
        onKeyUp: (event) => {
          if (event.key === 'Backspace') {
            setFocus(`code.${index - 1}`)
          }
        },
        onChange: (event) => {
          setCodeError(null)
          const value = event.currentTarget.value.toUpperCase()

          if (value !== '' && index < CODE_TEXT_FIELD_LENGTH - 1) {
            setFocus(`code.${index + 1}`)
          }
        },
        onPaste: (event) => {
          const values = event.clipboardData
            .getData('Text')
            .split('')
            .filter((char) => char !== '-')
            .slice(0, CODE_TEXT_FIELD_LENGTH)
          values.forEach((value, indexValue) => {
            setValue(`code.${indexValue}`, value)
          })
        },
        maxLength: 1
      }}
      variant="outlined"
      gridProps={{
        css: {
          ...css,
          '@xl': { width: 56 },
          '@lg': { width: 56 },
          '@md': { width: 56 },
          '@sm': { width: 56 },
          '@xs': { width: 45 }
        }
      }}
      css={{
        '.TextField-Input': {
          ...typographyStyleVariants.headlineLarge,
          color: '$on-surface-variant',
          textAlign: 'center',
          textTransform: 'uppercase'
        }
      }}
      showTrailingErrorIcon={false}
      hasError={
        !!errors.code?.message || !!errors.code?.some?.((error) => !!error?.message) || !!codeError
      }
    />
  )
}
