import { Session, Token } from '@/modules/hub/auth/contracts/models'

const storageSessionKey = '@hub/auth:session'
const storageSessionSupportKey = '@hub/auth:sessionSupport'
const storageTokenKey = '@hub/auth:token'
const storageTokenSupportKey = '@hub/auth:tokenSupport'

export function getTokenRepository(): Token | null {
  const token = localStorage.getItem(storageTokenKey)
  if (token !== null) {
    return JSON.parse(token) as Token
  }
  return token
}

export function saveTokenRepository(token: Token): void {
  localStorage.setItem(storageTokenKey, JSON.stringify(token))
}

export function getTokenSupportRepository(): Token | null {
  const token = localStorage.getItem(storageTokenSupportKey)
  if (token !== null) {
    return JSON.parse(token) as Token
  }
  return token
}

export function saveTokenSupportRepository(tokenSupport: Token): void {
  localStorage.setItem(storageTokenSupportKey, JSON.stringify(tokenSupport))
}

export function getSessionRepository(): Session | null {
  const session = localStorage.getItem(storageSessionKey)
  if (session !== null) {
    return JSON.parse(session) as Session
  }
  return session
}

export function saveSessionRepository(session: Session): void {
  localStorage.setItem(storageSessionKey, JSON.stringify(session))
}

export function getSessionSupportRepository(): Session | null {
  const session = localStorage.getItem(storageSessionSupportKey)
  if (session !== null) {
    return JSON.parse(session) as Session
  }
  return session
}

export function saveSessionSupportRepository(session: Session): void {
  localStorage.setItem(storageSessionSupportKey, JSON.stringify(session))
}

export function removeSessionRepository(): void {
  localStorage.removeItem(storageSessionKey)
  localStorage.removeItem(storageTokenKey)
}

export function removeSessionSupportRepository(): void {
  localStorage.removeItem(storageSessionSupportKey)
  localStorage.removeItem(storageTokenSupportKey)
}
