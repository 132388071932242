import { yupResolver } from '@hookform/resolvers/yup'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useAlert } from '@positivote/design-system/hooks'
import { useForm } from 'react-hook-form'

import { i18n } from '@/common/i18n'
import { SetupForm } from '@/modules/monitoora/contracts'
import { useCreateCredential, useCreateOAuthToken } from '@/modules/monitoora/hooks'
import { FrameSkeleton } from '@/modules/monitoora/icons/FrameSkeleton'
import { MenuSkeleton } from '@/modules/monitoora/icons/MenuSkeleton'
import { setupFormSanitizer } from '@/modules/monitoora/sanitizers'
import { setupSchema } from '@/modules/monitoora/validations'

export function Setup(): JSX.Element {
  const { addAlertMessage } = useAlert()
  const oauthToken = useCreateOAuthToken()
  const createCredential = useCreateCredential()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SetupForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(setupSchema)(setupFormSanitizer(values), ...args)
  })

  function getGoogleOAuthURL(clientId: string): string {
    const oauthData = new URLSearchParams({
      redirect_uri: `${window.location.origin}/callback`,
      client_id: clientId,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'select_account consent',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/admin.directory.device.chromeos',
        'https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly',
        'https://www.googleapis.com/auth/chrome.management.telemetry.readonly',
        'https://www.googleapis.com/auth/admin.reports.audit.readonly'
      ].join(' ')
    }).toString()

    return `${import.meta.env.VITE_GOOGLE_OAUTH2_URL}/v2/auth?${oauthData}`
  }

  function handleGoogleOAuth(data: SetupForm): void {
    const width = 500
    const height = 600
    const left = window.screenX + (window.outerWidth - width) / 2
    const top = window.screenY + (window.outerHeight - height) / 2.5
    const title = 'Login Google'
    const url = getGoogleOAuthURL(data.clientId)
    const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`)

    const timer = setInterval(() => {
      const urlParams = new URLSearchParams(popup?.location.search)
      const googleCode = urlParams.get('code')
      const googleError = urlParams.get('authError')

      if (!popup?.window || popup.window.closed) {
        addAlertMessage({
          title: i18n().modules.monitoora.pages.dashboard.setup.alert.failSetup,
          severity: 'warning'
        })
        clearInterval(timer)
      }

      if (googleCode) {
        oauthToken.mutate({
          model: {
            code: googleCode,
            clientId: data.clientId,
            clientSecret: data.secret
          },
          onSuccess(serviceResult) {
            createCredential.mutate({
              model: {
                clientId: data.clientId,
                clientSecret: data.secret,
                customerId: data.customerId,
                idToken: serviceResult.idToken,
                accessToken: serviceResult.accessToken,
                refreshToken: serviceResult.refreshToken,
                active: true
              }
            })
          }
        })

        popup?.close()
        clearInterval(timer)
      }
      if (googleError) {
        addAlertMessage({
          title: i18n().modules.monitoora.pages.dashboard.setup.alert.failSetup,
          severity: 'warning'
        })
        popup?.close()
        clearInterval(timer)
      }
    }, 100)
  }

  return (
    <Main className="dashboard" css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Grid
        xl={12}
        spacing="$lg"
        css={{ padding: '$lg', display: 'flex', flex: 1, '@sm': { padding: '$md' } }}
      >
        <Grid sm={12} md={6} xl={7}>
          <BaseCard
            css={{
              '& .BaseCard-StateLayer': {
                gap: '$lg'
              }
            }}
          >
            <Typography variant="titleLarge">
              {i18n().modules.monitoora.pages.dashboard.setup.title}
            </Typography>
            <Typography variant="bodyLarge">
              {i18n().modules.monitoora.pages.dashboard.setup.subTitle}
            </Typography>
            <FormContainer
              formHandleSubmit={handleSubmit}
              onSubmit={handleGoogleOAuth}
              css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}
            >
              <Grid spacing="$md" xl={12}>
                <FormTextField
                  control={control}
                  name="clientId"
                  label={i18n().modules.monitoora.pages.dashboard.setup.inputs.clientId}
                  variant="outlined"
                  errorText={errors.clientId?.message}
                />
                <FormTextField
                  control={control}
                  name="secret"
                  label={i18n().modules.monitoora.pages.dashboard.setup.inputs.secret}
                  variant="outlined"
                  errorText={errors.secret?.message}
                />
                <FormTextField
                  control={control}
                  name="customerId"
                  label={i18n().modules.monitoora.pages.dashboard.setup.inputs.customerId}
                  variant="outlined"
                  errorText={errors.clientId?.message}
                />
              </Grid>
              <Grid xl={12} css={{ display: 'flex', justifyContent: 'end' }}>
                <Button type="submit" variant="filled" isLoading={createCredential.isPending}>
                  {i18n().modules.monitoora.pages.dashboard.setup.buttons.sendData}
                </Button>
              </Grid>
            </FormContainer>
          </BaseCard>
        </Grid>
        <Grid sm={12} md={6} xl={5}>
          <BaseCard
            css={{
              '& .BaseCard-StateLayer': {
                gap: '$lg'
              }
            }}
          >
            <Typography variant="titleLarge">
              {i18n().modules.monitoora.pages.dashboard.setup.helpText}
            </Typography>
            <Div
              css={{
                backgroundColor: '$background',
                padding: '$md',
                borderRadius: '$lg',
                display: 'flex',
                gap: '$md',
                justifyContent: 'center'
              }}
            >
              <Div>
                <MenuSkeleton />
              </Div>
              <Div>
                <FrameSkeleton />
              </Div>
            </Div>
            <Grid xl={12} css={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                variant="outlined"
                onClick={() => window.open(import.meta.env.VITE_MONITOORA_DOC_URL, '_blank')}
              >
                {i18n().modules.monitoora.pages.dashboard.setup.buttons.accessTutorial}
              </Button>
            </Grid>
          </BaseCard>
        </Grid>
      </Grid>
    </Main>
  )
}
