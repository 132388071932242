import { parseValidDateOrDefault } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { Teacher } from '@/modules/hub/users/contracts'

import {
  ClassLevelFormatted,
  Classroom,
  ClassroomFormatted,
  ListClassLevelApiResult,
  ListClassroomApiResult,
  ListClassroomStudentApiResult,
  ListClassroomTeacherApiResult,
  Student
} from './contracts'

export function classroomFormatSanitizer(
  schoolClasses: ListClassroomApiResult['data']
): ClassroomFormatted[] {
  return schoolClasses.map((schoolClass) => ({
    ...schoolClass,
    levelFormatted: {
      ...schoolClass.level,
      code: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel[schoolClass.level.code]
    }
  }))
}

export function classroomSanitizer(classroom: Classroom): ClassroomFormatted {
  return {
    ...classroom,
    levelFormatted: {
      ...classroom.level,
      code: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel[classroom.level.code]
    }
  }
}

export function listClassLevelSanitizer(
  classLevel: ListClassLevelApiResult['data']
): ClassLevelFormatted[] {
  return classLevel.map((level) => ({
    ...level,
    fullNameClassLevelFormatted: i18n().modules.hub.classroom.fullClassLevel[level.code]
  }))
}

export function listClassroomStudentApiSanitizer(
  ClassroomStudent: ListClassroomStudentApiResult['students']
): Student[] {
  return ClassroomStudent.map((student) => ({
    ...student,
    allowedImageUseOnEdtech: student.allowed_image_use_on_edtech,
    enrollmentDate: parseValidDateOrDefault(student.enrollment_date)
  }))
}

export function listClassroomTeacherApiSanitizer(
  ClassroomTeacher: ListClassroomTeacherApiResult['data']
): Teacher[] {
  return ClassroomTeacher.map((teacher) => ({
    ...teacher,
    lastEnrollmentDate: teacher.last_enrollment_date
  }))
}
