import {
  BaseCardButton,
  BaseCardButtonProps
} from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { Breakpoint } from '@positivote/design-system/theme'

import { ShortProfile } from '@/modules/hub/profiles/contracts'

interface ProfileCardButtonProps extends BaseCardButtonProps {
  profile: ShortProfile
}

export function ProfileCardButton({
  profile,
  css,
  ...restProps
}: ProfileCardButtonProps): JSX.Element {
  const { breakpoint } = useTheme()

  return (
    <BaseCardButton
      css={{
        backgroundColor: '$surface',
        width: '100%',
        marginBottom: '$2xs',
        '&:last-child': { marginBottom: '$none' },
        '& .BaseCardButton-StateLayer': { padding: '$md' },
        '&[data-selected=true]': {
          '& .BaseCardButton-StateLayer': { backgroundColor: '$state-layer-primary-16' }
        },
        '@xs': { marginBottom: '$4xs' },
        ...css
      }}
      {...restProps}
    >
      {breakpoint !== Breakpoint.xs && (
        <Image
          alt={profile.name}
          FallbackImage={() => (
            <IconWrapper size="$2xl" css={{ backgroundColor: '$primary-container' }}>
              <PersonIcon fill="$on-primary-container" />
            </IconWrapper>
          )}
          src={profile.picture}
          css={{ height: '$2xl', width: '$2xl', borderRadius: '$full', objectFit: 'cover' }}
        />
      )}
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: 1,
          marginLeft: breakpoint !== Breakpoint.xs ? '$md' : '$none'
        }}
      >
        {breakpoint < Breakpoint.md && (
          <Typography variant="labelMedium" css={{ color: '$on-surface-variant' }} lineClamp={1}>
            {profile.role.name}
          </Typography>
        )}
        <Typography
          variant="bodyLarge"
          css={{ color: '$on-surface', wordBreak: 'break-all' }}
          lineClamp={1}
        >
          {profile.organization.name}
        </Typography>
        <Typography
          variant="bodyMedium"
          css={{ color: '$on-surface-variant', wordBreak: 'break-all' }}
          lineClamp={1}
        >
          {profile.organization.address}
        </Typography>
      </Div>
      {breakpoint > Breakpoint.sm && (
        <Typography
          variant="labelSmall"
          css={{ color: '$on-surface-variant', marginLeft: '$md' }}
          lineClamp={1}
        >
          {profile.role.name}
        </Typography>
      )}
    </BaseCardButton>
  )
}
