import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint, CSS } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ListShortOrganizationHookParams } from '@/modules/hub/organizations/contracts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts/models'
import { useListShortOrganization } from '@/modules/hub/organizations/hooks'

const selectedButtonProps: CSS = {
  backgroundColor: '$primary',
  '& .Button-StateLayer': {
    '&:hover, &[data-hovered=true]': {
      backgroundColor: '$state-layer-on-primary-8'
    }
  },
  '&:focus, &:active': {
    '& .Button-StateLayer': {
      backgroundColor: '$state-layer-on-primary-12'
    }
  },
  '&[data-selected=true]': {
    '& .Button-StateLayer': {
      backgroundColor: '$state-layer-primary-12'
    }
  }
}

export function OnboardingInRegisterList(): JSX.Element {
  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const [listShortOrganizationParams, setListShortOrganizationParams] = useState<
    ListShortOrganizationHookParams['model']
  >({
    page: 1,
    callPublic: false,
    kind: OrganizationKind.SCHOOL,
    isInRegister: true,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })

  const listShortOrganization = useListShortOrganization({
    model: listShortOrganizationParams
  })

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListShortOrganizationParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '$lg',
        overflowY: 'auto',
        '@sm': { padding: '$md' }
      }}
    >
      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
        <FilterListIcon size={18} />
        <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
          {i18n().modules.hub.onboarding.pages.list.filter.title}
        </Typography>
      </Div>
      <Grid xl={12} css={{ marginBottom: '$lg', display: 'flex', alignItems: 'flex-start' }}>
        <TextField
          variant="outlined"
          label={i18n().modules.hub.onboarding.pages.list.filter.input.label}
          inputProps={{
            onChange: handleChangeSearchText
          }}
          supportingText={i18n().modules.hub.onboarding.pages.list.filter.input.supportingText(
            listShortOrganizationParams.kind === OrganizationKind.SCHOOL
              ? 'SCHOOL'
              : 'TEACHING_NETWORK'
          )}
          leadingIcon={{ icon: SearchIcon }}
          css={{ flex: 1 }}
        />
        <Button
          variant="outlined"
          color={
            listShortOrganizationParams.kind === OrganizationKind.SCHOOL
              ? '$on-primary'
              : '$primary'
          }
          onClick={() =>
            setListShortOrganizationParams((oldState) => ({
              ...oldState,
              kind: OrganizationKind.SCHOOL,
              page: 1
            }))
          }
          css={{
            marginTop: '$2xs',
            marginLeft: '$md',
            borderTopRightRadius: '$none',
            borderBottomRightRadius: '$none',
            ...(listShortOrganizationParams.kind === OrganizationKind.SCHOOL && selectedButtonProps)
          }}
        >
          {i18n().modules.hub.onboarding.pages.list.filter.schools}
        </Button>
        <Button
          variant="outlined"
          color={
            listShortOrganizationParams.kind === OrganizationKind.TEACHING_NETWORK
              ? '$on-primary'
              : '$primary'
          }
          onClick={() =>
            setListShortOrganizationParams((oldState) => ({
              ...oldState,
              kind: OrganizationKind.TEACHING_NETWORK,
              page: 1
            }))
          }
          css={{
            marginTop: '$2xs',
            borderTopLeftRadius: '$none',
            borderBottomLeftRadius: '$none',
            ...(listShortOrganizationParams.kind === OrganizationKind.TEACHING_NETWORK &&
              selectedButtonProps)
          }}
        >
          {i18n().modules.hub.onboarding.pages.list.filter.networks}
        </Button>
      </Grid>
      {listShortOrganization.isFetching && listShortOrganizationParams.search && (
        <Typography variant="titleLarge" css={{ color: '$on-surface', marginBottom: '$lg' }}>
          {i18n().modules.hub.onboarding.pages.list.tabs.common.searching}
        </Typography>
      )}
      {!listShortOrganization.isFetching &&
        !!listShortOrganization.data?.registers.length &&
        listShortOrganizationParams.search && (
          <Typography variant="titleLarge" css={{ color: '$on-surface', marginBottom: '$lg' }}>
            {i18n().modules.hub.onboarding.pages.list.tabs.common.searchingResult}
          </Typography>
        )}

      {!listShortOrganization.data?.registers.length ? (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          {listShortOrganization.isFetching && <Loader size={80} />}
          {!listShortOrganization.isFetching && !listShortOrganizationParams.search && (
            <EmptyList
              title={i18n().modules.hub.onboarding.pages.list.tabs.inRegister.emptyList(
                listShortOrganizationParams.kind === OrganizationKind.SCHOOL
                  ? 'SCHOOL'
                  : 'TEACHING_NETWORK'
              )}
            />
          )}
          {!listShortOrganization.isFetching && listShortOrganizationParams.search && (
            <EmptySearch />
          )}
        </Div>
      ) : (
        <>
          <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
            <Grid xl={1.5}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.inRegister.header.id}
              </Typography>
            </Grid>
            <Grid xl={3.5}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.inRegister.header.name}
              </Typography>
            </Grid>
            <Grid xl={3}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.inRegister.header.address}
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.inRegister.header.kind}
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.onboarding.pages.list.tabs.inRegister.header.status}
              </Typography>
            </Grid>
          </Grid>
          <UL
            css={{
              ...(listShortOrganization.isFetching && {
                ...(listShortOrganization.data.lastPage <= 1 && { flex: 1 }),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              })
            }}
          >
            {listShortOrganization.isFetching && <Loader size={80} />}
            {listShortOrganization.data.registers.map((register) => (
              <BaseCardButton
                key={register.id}
                onClick={() => navigate(`${register.code}`)}
                css={{
                  width: '100%',
                  opacity: listShortOrganization.isFetching ? '$transparent' : '$default',
                  marginBottom: '$2xs',
                  '&:last-child': { marginBottom: '$none' },
                  '& .BaseCardButton-StateLayer': {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '$md',
                    height: 'min-content',
                    padding: '$md $lg'
                  }
                }}
              >
                <Grid spacing="$md">
                  <Grid xl={1.5} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.id}
                    </Typography>
                  </Grid>
                  <Grid xl={3.5} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.name}
                    </Typography>
                  </Grid>
                  <Grid xl={3} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.address}
                    </Typography>
                  </Grid>
                  <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodyMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                      {register.kindFormatted}
                    </Typography>
                  </Grid>
                  <Grid xl={2} css={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="bodyMedium"
                      lineClamp={1}
                      css={{ color: register.statusColor }}
                    >
                      {register.statusFormatted}
                    </Typography>
                  </Grid>
                </Grid>
              </BaseCardButton>
            ))}
          </UL>
          {listShortOrganization.data.lastPage > 1 && (
            <Pagination
              lastPage={listShortOrganization.data.lastPage}
              page={listShortOrganizationParams.page ?? 1}
              setPage={(page) =>
                setListShortOrganizationParams((oldState) => ({ ...oldState, page }))
              }
              css={{ marginTop: '$lg' }}
            />
          )}
        </>
      )}
    </Div>
  )
}
