import { OrganizationKind, OrganizationStatus } from '@/modules/hub/organizations/contracts/models'

export const organizationPt = {
  resources: {
    listShort: 'Listar escolas reduzidas',
    listPermission: 'Listar permissões',
    updatePermission: 'Atualizar permissão',
    listOrganization: 'Listar Escolas'
  },
  kind: {
    [OrganizationKind.NATIONAL]: 'Nacional',
    [OrganizationKind.STATE]: 'Estado',
    [OrganizationKind.DISTRICT]: 'Distrito',
    [OrganizationKind.LOCAL]: 'Local',
    [OrganizationKind.DEPARTMENT]: 'Departamento',
    [OrganizationKind.GROUP]: 'Grupo',
    [OrganizationKind.SCHOOL]: 'Escola',
    [OrganizationKind.COMPANY]: 'Empresa',
    [OrganizationKind.EDTECH]: 'Edtech',
    [OrganizationKind.TEACHING_NETWORK]: 'Rede de Ensino'
  },
  status: {
    [OrganizationStatus.ACTIVE]: 'Ativo',
    [OrganizationStatus.INACTIVE]: 'Inativo'
  }
}
