import { Ellipse, Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function Hubot({ fill, css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="410"
      height="299"
      viewBox="0 0 410 299"
      fill="none"
      xmlns="http://www.w3.org/2000/Svg"
      css={{ fill, ...css }}
      {...restProps}
    >
      <Ellipse opacity="0.05" cx="149" cy="149.5" rx="149" ry="149.5" fill="white" />
      <Path
        d="M236.151 241.1C261.271 241.1 281.635 221.154 281.635 196.55C281.635 171.946 261.271 152 236.151 152C211.031 152 190.667 171.946 190.667 196.55C190.667 221.154 211.031 241.1 236.151 241.1Z"
        css={{ fill: '#FAF5CA', fillOpacity: '0.05' }}
      />
      <Path
        d="M232.016 224.601C250.285 224.601 265.095 209.087 265.095 189.951C265.095 170.814 250.285 155.301 232.016 155.301C213.747 155.301 198.937 170.814 198.937 189.951C198.937 209.087 213.747 224.601 232.016 224.601Z"
        css={{ fill: '#FAF5CA', fillOpacity: '0.05' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.5427 135.376C94.1853 134.156 92.0937 134.265 90.8709 135.619C90.7518 135.751 90.6326 135.883 90.5132 136.015C86.6325 140.312 82.6017 144.775 79.1067 149.198C75.5174 153.74 72.3445 158.422 70.4449 163.048C68.5377 167.693 67.7848 172.611 69.5573 177.348C71.3291 182.082 75.3709 185.958 81.5996 189.065C83.2337 189.88 85.2207 189.219 86.0377 187.589C86.8547 185.959 86.1924 183.976 84.5583 183.161C79.2093 180.493 76.7386 177.666 75.7553 175.039C74.7728 172.414 75.0223 169.313 76.5671 165.55C78.1196 161.769 80.8484 157.655 84.3024 153.284C87.66 149.035 91.5626 144.713 95.4931 140.361C95.5908 140.253 95.6885 140.145 95.7862 140.037C97.0091 138.683 96.9 136.596 95.5427 135.376Z"
        css={{ fill: '#BFC4CE' }}
      />
      <Path
        d="M231.236 173.319L231.169 205.259C231.164 207.405 228.24 208.034 227.348 206.081L214.501 177.947C214.489 177.921 214.478 177.896 214.468 177.869C214.187 177.145 210.156 166.289 220.488 164.282C231.101 162.22 231.235 173.153 231.236 173.31V173.319Z"
        css={{ fill: '#FF9F46' }}
      />
      <Path
        d="M227.941 171.879L227.903 189.858C227.898 192.004 224.974 192.633 224.082 190.68L216.897 174.945C216.885 174.919 216.876 174.897 216.865 174.87C216.647 174.304 214.069 167.226 220.844 165.91C227.825 164.554 227.939 171.713 227.941 171.87V171.879Z"
        css={{ fill: '#F7E549' }}
      />
      <Path
        d="M226.91 162.272L214.036 165.398C211.822 165.935 210.465 168.166 211.005 170.379C211.545 172.593 213.778 173.951 215.992 173.414L228.865 170.288C231.08 169.75 232.437 167.52 231.897 165.306C231.357 163.093 229.124 161.734 226.91 162.272Z"
        css={{ fill: '#635F68' }}
      />
      <Path
        d="M212.746 101.71L199.61 104.622C195.836 105.458 193.457 109.196 194.298 112.969L205.964 165.343C206.805 169.117 210.546 171.498 214.32 170.661L227.456 167.749C231.23 166.912 233.609 163.175 232.768 159.401L221.102 107.027C220.261 103.254 216.52 100.873 212.746 101.71Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M214.786 172.441L215.332 183.512C216.05 198.085 208.331 211.732 195.524 218.525L192.797 219.972C176.947 228.38 158.982 231.925 141.117 230.169L138.043 229.867C123.608 228.449 111.263 218.761 106.366 205.012L102.646 194.567C95.2645 173.827 90.6537 152.2 88.9365 130.258L86.7594 102.41C85.6343 88.0185 92.7907 74.2754 105.178 67.0444C122.865 56.7214 143.493 52.6512 163.788 55.4802C178.002 57.4625 189.863 67.4508 194.315 81.1862L202.928 107.764C209.714 128.709 213.702 150.456 214.788 172.438L214.786 172.441Z"
        css={{ fill: '#DAE0EC' }}
      />
      <Path
        d="M90.3859 115.917L90.262 113.483C89.8345 105.072 93.1331 97.9021 98.3946 95.8077L101.319 94.6445C106.15 92.7217 111.235 91.9138 116.408 92.2484L121.914 92.6041C127.632 92.9728 132.962 98.9448 134.985 107.25L135.5 109.365C137.156 116.16 138.136 123.167 138.409 130.146C138.726 138.277 135.558 145.186 130.509 147.376L126.801 148.985C120.684 151.638 114.143 152.477 107.517 151.456L105.828 151.197C100.464 150.372 95.5971 144.702 93.6148 136.967L93.5364 136.663C91.8025 129.897 90.7401 122.904 90.3846 115.917H90.3859Z"
        css={{ fill: '#545B6D' }}
      />
      <Path
        d="M100.529 128.899C101.899 128.899 103.01 127.791 103.01 126.424C103.01 125.057 101.899 123.949 100.529 123.949C99.1591 123.949 98.0483 125.057 98.0483 126.424C98.0483 127.791 99.1591 128.899 100.529 128.899Z"
        css={{ fill: 'white' }}
      />
      <Path
        d="M118.719 125.6C120.089 125.6 121.2 124.492 121.2 123.125C121.2 121.758 120.089 120.65 118.719 120.65C117.349 120.65 116.238 121.758 116.238 123.125C116.238 124.492 117.349 125.6 118.719 125.6Z"
        css={{ fill: 'white' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.454 128.654C122.791 128.962 122.813 129.484 122.505 129.82L119.344 133.26C119.035 133.596 118.512 133.619 118.175 133.311C117.838 133.003 117.816 132.481 118.124 132.145L121.286 128.705C121.594 128.369 122.117 128.346 122.454 128.654Z"
        css={{ fill: 'white', fillOpacity: '0.1' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.585 128.475C126.922 128.782 126.944 129.304 126.636 129.64L123.475 133.08C123.166 133.416 122.643 133.439 122.306 133.131C121.969 132.823 121.947 132.301 122.255 131.965L125.417 128.525C125.725 128.189 126.248 128.167 126.585 128.475Z"
        css={{ fill: 'white', fillOpacity: '0.1' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.211 131.879C105.548 132.187 105.571 132.709 105.262 133.044L102.101 136.485C101.792 136.82 101.269 136.843 100.932 136.535C100.596 136.227 100.573 135.705 100.882 135.37L104.043 131.929C104.352 131.594 104.875 131.571 105.211 131.879Z"
        css={{ fill: 'white', fillOpacity: '0.1' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.254 132.094C100.591 132.402 100.613 132.923 100.305 133.259L97.1436 136.699C96.835 137.035 96.3118 137.058 95.9752 136.75C95.6385 136.442 95.6158 135.92 95.9244 135.584L99.0856 132.144C99.3942 131.808 99.9174 131.786 100.254 132.094Z"
        css={{ fill: 'white', fillOpacity: '0.1' }}
      />
      <Path
        d="M181.868 126.85L167.545 131.785C165.456 132.505 164.349 134.78 165.071 136.868L172.713 158.941C173.435 161.028 175.714 162.137 177.803 161.418L192.126 156.483C194.215 155.763 195.322 153.488 194.6 151.4L186.959 129.327C186.236 127.24 183.957 126.131 181.868 126.85Z"
        css={{ fill: '#CCD3E0', fillOpacity: '0.1' }}
      />
      <Path
        d="M178.182 148.259C180.923 148.259 183.144 146.043 183.144 143.309C183.144 140.576 180.923 138.359 178.182 138.359C175.442 138.359 173.22 140.576 173.22 143.309C173.22 146.043 175.442 148.259 178.182 148.259Z"
        css={{ fill: '#635F68' }}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.123 141.697C177.015 140.107 179.031 139.539 180.625 140.43C188.596 144.883 193.349 153.118 195.414 161.771C197.48 170.433 196.982 179.99 193.957 187.698C190.931 195.409 185.093 201.809 176.295 202.665C167.716 203.499 157.554 198.899 146.1 187.746C144.793 186.473 144.767 184.384 146.043 183.079C147.319 181.775 149.414 181.75 150.721 183.023C161.659 193.674 169.975 196.648 175.654 196.096C181.114 195.565 185.313 191.619 187.797 185.292C190.281 178.961 190.765 170.789 188.978 163.299C187.189 155.801 183.253 149.462 177.393 146.188C175.799 145.298 175.23 143.287 176.123 141.697Z"
        css={{ fill: '#BFC4CE' }}
      />
      <Path
        d="M144.553 177.194L139.057 186.501C138.214 187.927 138.689 189.765 140.117 190.604L145.532 193.787C146.961 194.627 148.801 194.151 149.644 192.724L155.141 183.418C155.983 181.991 155.509 180.154 154.08 179.314L148.665 176.132C147.237 175.292 145.396 175.768 144.553 177.194Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M134.304 183.773C133.111 183.243 131.712 183.78 131.18 184.973C130.648 186.166 131.185 187.562 132.378 188.092L135.362 189.416C136.556 189.946 137.954 189.408 138.486 188.216C139.018 187.023 138.482 185.626 137.288 185.097L134.304 183.773Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M78.922 172.024C77.7419 172.583 77.2404 173.993 77.8019 175.172C78.3634 176.351 79.7753 176.853 80.9555 176.294L83.9046 174.896C85.0848 174.337 85.5863 172.928 85.0247 171.748C84.4632 170.569 83.0513 170.067 81.8711 170.626L78.922 172.024Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M139.266 172.224C138.073 171.694 136.674 172.232 136.142 173.424C135.61 174.617 136.147 176.013 137.34 176.543L140.324 177.867C141.518 178.397 142.916 177.859 143.448 176.667C143.98 175.474 143.444 174.078 142.25 173.548L139.266 172.224Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M152.776 171.227C151.662 170.545 150.204 170.893 149.519 172.006C148.834 173.118 149.182 174.573 150.296 175.255L153.076 176.959C154.19 177.642 155.648 177.293 156.333 176.181C157.018 175.069 156.67 173.614 155.556 172.931L152.776 171.227Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M103.833 170.15C107.03 170.15 109.622 167.564 109.622 164.375C109.622 161.185 107.03 158.6 103.833 158.6C100.636 158.6 98.0444 161.185 98.0444 164.375C98.0444 167.564 100.636 170.15 103.833 170.15Z"
        css={{ fill: '#B177D1' }}
      />
      <Path
        d="M104.66 165.2C105.574 165.2 106.314 164.462 106.314 163.55C106.314 162.639 105.574 161.9 104.66 161.9C103.747 161.9 103.006 162.639 103.006 163.55C103.006 164.462 103.747 165.2 104.66 165.2Z"
        css={{ fill: 'white' }}
      />
      <Path
        d="M140.114 157.824L129.201 160.079C128.394 160.246 127.906 161.028 128.111 161.826C128.317 162.624 129.137 163.136 129.944 162.969L140.857 160.714C141.664 160.547 142.152 159.765 141.947 158.967C141.742 158.169 140.921 157.657 140.114 157.824Z"
        css={{ fill: '#CCD3E0' }}
      />
      <Path
        d="M138.536 153.951L127.623 156.206C126.816 156.373 126.328 157.155 126.534 157.953C126.739 158.751 127.559 159.263 128.366 159.096L139.279 156.841C140.086 156.674 140.574 155.892 140.369 155.094C140.164 154.296 139.343 153.784 138.536 153.951Z"
        css={{ fill: '#CCD3E0' }}
      />
      <Path
        d="M141.844 162.849L130.931 165.104C130.124 165.271 129.636 166.053 129.841 166.851C130.047 167.649 130.867 168.161 131.674 167.994L142.587 165.739C143.394 165.573 143.882 164.79 143.677 163.992C143.472 163.194 142.651 162.683 141.844 162.849Z"
        css={{ fill: '#CCD3E0' }}
      />
      <Path
        d="M112.103 191.6C123.522 191.6 132.778 182.365 132.778 170.975C132.778 159.584 123.522 150.35 112.103 150.35C100.685 150.35 91.4287 159.584 91.4287 170.975C91.4287 182.365 100.685 191.6 112.103 191.6Z"
        css={{ fill: '#CCD3E0', fillOpacity: '0.4' }}
      />
      <Path
        d="M111.276 198.2C126.805 198.2 139.394 185.641 139.394 170.15C139.394 154.658 126.805 142.1 111.276 142.1C95.7473 142.1 83.1587 154.658 83.1587 170.15C83.1587 185.641 95.7473 198.2 111.276 198.2Z"
        css={{ fill: '#F5E7FF', fillOpacity: '0.4' }}
      />
      <Path
        d="M108.767 173.043C108.584 172.812 108.584 172.504 108.767 172.273L112.314 167.794C112.614 167.415 113.246 167.415 113.547 167.794L117.093 172.273C117.276 172.504 117.276 172.812 117.093 173.043L113.547 177.521C113.246 177.901 112.614 177.901 112.314 177.521L108.767 173.043Z"
        css={{ fill: 'white' }}
      />
      <Path
        d="M117.892 168.499C118.805 168.499 119.546 167.76 119.546 166.849C119.546 165.938 118.805 165.199 117.892 165.199C116.979 165.199 116.238 165.938 116.238 166.849C116.238 167.76 116.979 168.499 117.892 168.499Z"
        css={{ fill: 'white' }}
      />
      <Path
        d="M88.1899 177.783L92.0434 187.875C92.6344 189.423 91.858 191.155 90.3092 191.743L84.4341 193.976C82.8853 194.564 81.1506 193.787 80.5596 192.239L76.7061 182.146C76.1151 180.598 76.8916 178.866 78.4404 178.278L84.3155 176.045C85.8643 175.457 87.5989 176.235 88.1899 177.783Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M94.7633 184.94C93.5832 185.499 93.0817 186.909 93.6432 188.088C94.2047 189.267 95.6166 189.769 96.7968 189.21L99.7459 187.812C100.926 187.253 101.428 185.844 100.866 184.664C100.305 183.485 98.8926 182.983 97.7125 183.542L94.7633 184.94Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M96.5004 186.722L96.5004 186.722C97.0619 187.902 98.4738 188.404 99.6539 187.845L102.603 186.447C103.783 185.888 104.285 184.478 103.723 183.299L103.723 183.299C103.162 182.12 101.75 181.618 100.57 182.177L97.6205 183.575C96.4403 184.134 95.9388 185.543 96.5004 186.722Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M92.765 182.203L92.765 182.203C93.3265 183.382 94.7384 183.884 95.9186 183.325L98.8677 181.927C100.048 181.368 100.549 179.959 99.9879 178.78L99.9879 178.78C99.4264 177.601 98.0144 177.098 96.8343 177.657L93.8851 179.055C92.705 179.615 92.2035 181.024 92.765 182.203Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M97.6205 176.975C96.4403 177.534 95.9388 178.944 96.5004 180.123C97.0619 181.302 98.4738 181.804 99.654 181.245L102.603 179.847C103.783 179.288 104.285 177.879 103.723 176.7C103.162 175.521 101.75 175.018 100.57 175.577L97.6205 176.975Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M89.6852 177.107L89.6852 177.107C90.2467 178.286 91.6586 178.789 92.8388 178.229L95.7879 176.832C96.9681 176.272 97.4696 174.863 96.908 173.684L96.908 173.684C96.3465 172.505 94.9346 172.002 93.7545 172.562L90.8053 173.96C89.6252 174.519 89.1237 175.928 89.6852 177.107Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M94.3124 172.024C93.1322 172.583 92.6307 173.993 93.1923 175.172C93.7538 176.351 95.1657 176.853 96.3458 176.294L99.295 174.896C100.475 174.337 100.977 172.928 100.415 171.748C99.8536 170.569 98.4417 170.067 97.2615 170.626L94.3124 172.024Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M130.996 182.124C129.803 181.595 128.404 182.132 127.872 183.325C127.34 184.517 127.877 185.914 129.07 186.444L132.054 187.768C133.248 188.297 134.646 187.76 135.178 186.567C135.71 185.374 135.174 183.978 133.98 183.448L130.996 182.124Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M132.834 178.374L132.834 178.374C132.302 179.566 132.839 180.963 134.032 181.492L137.016 182.816C138.21 183.346 139.608 182.809 140.14 181.616L140.14 181.616C140.672 180.423 140.136 179.027 138.942 178.497L135.958 177.173C134.765 176.643 133.366 177.181 132.834 178.374Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M132.65 175.523C131.457 174.993 130.058 175.53 129.526 176.723C128.994 177.916 129.531 179.312 130.724 179.842L133.708 181.166C134.902 181.696 136.3 181.158 136.832 179.966C137.364 178.773 136.828 177.376 135.634 176.847L132.65 175.523Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M135.958 170.574C134.765 170.044 133.366 170.581 132.834 171.774C132.302 172.967 132.839 174.363 134.032 174.893L137.016 176.217C138.21 176.747 139.608 176.209 140.14 175.016C140.672 173.824 140.136 172.427 138.942 171.898L135.958 170.574Z"
        css={{ fill: '#9EA3AC' }}
      />
      <Path
        d="M134.732 62.1329C152.341 57.9853 169.554 64.6804 175.356 70.0183C176.031 70.6396 175.421 71.4811 174.538 71.2296C154.833 65.6133 115.665 66.6239 134.732 62.1329Z"
        css={{ fill: '#F9F4FF' }}
      />
      <Path
        d="M183.224 77.7508C184.594 77.7508 185.705 76.6427 185.705 75.2758C185.705 73.9089 184.594 72.8008 183.224 72.8008C181.854 72.8008 180.743 73.9089 180.743 75.2758C180.743 76.6427 181.854 77.7508 183.224 77.7508Z"
        css={{ fill: '#F9F4FF' }}
      />
      <Path
        d="M138.712 220.251C129.993 223.447 111.267 209.287 107.824 189.57C107.632 188.472 109.131 188.076 109.674 189.049C117.791 203.606 134.186 215.549 138.812 218.733C139.401 219.139 139.385 220.005 138.712 220.251Z"
        css={{ fill: '#CCD3E0' }}
      />
      <Path
        d="M108.744 132.922C108.612 132.476 108.165 132.229 107.719 132.361C107.289 132.476 107.024 132.938 107.156 133.384L108.744 132.938V132.922ZM115.294 131.866C115.277 131.404 114.864 131.057 114.417 131.09C113.954 131.107 113.574 131.519 113.607 131.965L115.294 131.866ZM107.95 133.153C107.421 133.307 107.156 133.384 107.156 133.384C107.156 133.384 107.156 133.4 107.156 133.417C107.156 133.45 107.173 133.483 107.206 133.549C107.239 133.648 107.305 133.796 107.371 133.961C107.52 134.291 107.752 134.737 108.116 135.182C108.827 136.073 110.067 136.931 111.887 136.585L111.572 134.968C110.514 135.166 109.852 134.72 109.406 134.159C109.174 133.862 109.009 133.549 108.893 133.301C108.843 133.186 108.794 133.07 108.777 133.004C108.777 132.971 108.761 132.938 108.744 132.938C108.744 132.938 108.48 133.015 107.95 133.169V133.153ZM111.887 136.601C113.706 136.255 114.566 135.001 114.947 133.912C115.128 133.384 115.211 132.872 115.244 132.509C115.261 132.328 115.261 132.163 115.261 132.064C115.261 132.014 115.261 131.965 115.261 131.932V131.899C115.261 131.899 114.985 131.91 114.434 131.932C113.882 131.954 113.607 131.965 113.607 131.965C113.607 131.965 113.607 132.014 113.607 132.047C113.607 132.113 113.607 132.229 113.59 132.361C113.557 132.641 113.508 133.004 113.375 133.367C113.127 134.093 112.631 134.77 111.556 134.984L111.87 136.601H111.887Z"
        css={{ fill: 'white' }}
      />
    </Svg>
  )
}
