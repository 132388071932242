import { useAlert } from '@positivote/design-system/hooks'
import { createContext, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { IDLE_TIMER_THROTTLE, IDLE_TIMER_TIMEOUT_IN_MS } from '@/common/constants/idle-timer'
import { i18n } from '@/common/i18n'
import {
  getLastActionAtRepository,
  removeLastActionAtRepository,
  saveLastActionAtRepository
} from '@/common/repositories'
import { useAuth } from '@/modules/hub/auth/contexts'

interface IdleTimerProviderProps {
  children: React.ReactNode
}

export const IdleTimerContext = createContext({})

export function IdleTimerProvider({ children }: IdleTimerProviderProps): JSX.Element {
  const { isSigned, logout } = useAuth()
  const { addAlertMessage } = useAlert()

  function onIdle(): void {
    addAlertMessage({
      alertKey: 'unauthorizedException',
      subTitle: i18n().common.exceptions.unauthorizedException,
      severity: 'warning'
    })
    logout()
  }

  function onAction(): void {
    saveLastActionAtRepository(new Date().getTime())
  }

  const idleTimer = useIdleTimer({
    onIdle,
    onAction,
    disabled: !isSigned,
    crossTab: true,
    timeout: IDLE_TIMER_TIMEOUT_IN_MS,
    throttle: IDLE_TIMER_THROTTLE
  })

  useEffect(() => {
    if (!isSigned) {
      idleTimer.pause()
      removeLastActionAtRepository()
    } else {
      const lastActionAt = getLastActionAtRepository()
      const now = new Date().getTime()
      if (lastActionAt && lastActionAt < now - IDLE_TIMER_TIMEOUT_IN_MS) {
        logout()
      } else {
        idleTimer.reset()
        onAction()
      }
    }
    // DOCS: this useEffect can only run if isSigned changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned])

  return <IdleTimerContext.Provider value={{}}>{children}</IdleTimerContext.Provider>
}
