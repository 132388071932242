import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListAccountLoginSupportApiParams,
  ListAccountLoginSupportApiResult
} from './contracts/apis'
import {
  ListAccountLoginSupportServiceParams,
  ListAccountLoginSupportServiceResult
} from './contracts/services'
import { fromAccountLoginSupportApiSanitizer } from './sanitizers'

export async function listAccountLoginSupportService(
  params: ListAccountLoginSupportServiceParams
): Promise<ListAccountLoginSupportServiceResult> {
  const { data } = await hubApiService<
    ListAccountLoginSupportApiParams,
    ListAccountLoginSupportApiResult
  >({
    resource: i18n().modules.hub.accounts.resources.list,
    method: 'get',
    url: '/admin/v1/login-support/form/accounts',
    params: { id_instituicao: params.organizationId, q: params.search }
  })
  return listHubApiResultSanitizer({
    page: 1,
    content_range: data.data.length,
    total_pages: 1,
    total: data.data.length,
    orderBy: 'id',
    order: 'asc',
    data: fromAccountLoginSupportApiSanitizer(data.data)
  })
}
