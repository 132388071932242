import { Pagination } from '@positivote/design-system/components/Pagination'
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import { TextDialog } from '@/common/components/TextDialog'
import { i18n } from '@/common/i18n'
import { ListTeachers } from '@/modules/hub/classroom/components/ListTeachers'
import {
  ClassroomFormatted,
  InclusionTeachersStepperState
} from '@/modules/hub/classroom/contracts'
import { useCreateBulkEnrollment, useRemoveBulkEnrollment } from '@/modules/hub/enrollments/hooks'
import { Teacher } from '@/modules/hub/users/contracts'

interface ListTeachersStepProps {
  stepperState: InclusionTeachersStepperState
  setStepState: (stepperState: Partial<InclusionTeachersStepperState['selectTeachers']>) => void
}
export const ListTeachersStep = forwardRef(function ListTeachersStep(
  { stepperState, setStepState }: ListTeachersStepProps,
  ref
) {
  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
  } | null

  const originalEnrollments = structuredClone(stepperState.selectTeachers.enrollments)

  const [data, setData] = useState(stepperState.selectTeachers.enrollments)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [listClassroomTeachersParams, setListClassroomTeachersParams] = useState({
    page: 1,
    perPage: 5
  })

  const paginatedData = data.slice(
    (listClassroomTeachersParams.page - 1) * listClassroomTeachersParams.perPage,
    listClassroomTeachersParams.page * listClassroomTeachersParams.perPage
  )

  const lastPage = Math.ceil(data.length / listClassroomTeachersParams.perPage)

  const createBulkEnrollment = useCreateBulkEnrollment()
  const removeBulkEnrollment = useRemoveBulkEnrollment()

  const findMissingCourses = (
    teacherArray1: Teacher[],
    teacherArray2: Teacher[]
  ): Array<{ teacherId: number; courseId: number }> => {
    const missingCourses: Array<{ teacherId: number; courseId: number }> = []

    teacherArray1.forEach((teacher1) => {
      const teacher2 = teacherArray2.find((t2) => t2.id === teacher1.id)

      if (teacher2) {
        teacher1.courses.forEach((course1) => {
          const isCourseMissing = !teacher2.courses.some((course2) => course2.id === course1.id)

          if (isCourseMissing) {
            missingCourses.push({ teacherId: teacher1.id, courseId: course1.id })
          }
        })
      }
    })

    return missingCourses
  }

  const onSubmit = useCallback(
    async (setIsLoading: (isLoading: boolean) => void): Promise<void> => {
      setIsLoading(true)

      const removeEnrollments = stepperState.selectTeachers.removeEnrollments

      const missingCourses = findMissingCourses(originalEnrollments, data)

      if (data.length) {
        if (data.filter((teacher) => !teacher.courses.length).length) {
          setIsDialogOpen(true)
          setIsLoading(false)
          return
        }

        const newTeachersToEnrollment = data.flatMap((teacher) =>
          teacher.courses.map((course) => ({
            teacherId: teacher.id,
            courseId: course.id
          }))
        )

        await createBulkEnrollment.mutateAsync({
          model: {
            classroomId: locationState!.classroom.id,
            enrollments: newTeachersToEnrollment
          },
          onError: () => {
            setIsLoading(false)
          }
        })
      }

      if (removeEnrollments.length || missingCourses.length) {
        const newTeachersToRemove = removeEnrollments.flatMap((teacher) =>
          teacher.courses.map((course) => ({
            teacherId: teacher.id,
            courseId: course.id
          }))
        )

        await removeBulkEnrollment.mutateAsync({
          model: {
            classroomId: locationState!.classroom.id,
            enrollments: [...newTeachersToRemove, ...missingCourses]
          },
          onError: () => {
            setIsLoading(false)
          }
        })
      }
      setStepState({
        data: [],
        enrollments: [],
        removeEnrollments: [],
        teachersToEnroll: []
      })
      navigate('/data-management/levels-and-classes/classroom/classroom-users', {
        state: {
          classroom: locationState?.classroom,
          schoolYear: {
            name: locationState?.schoolYear.name
          },
          tab: 1
        }
      })
    },
    [
      createBulkEnrollment,
      data,
      locationState,
      navigate,
      originalEnrollments,
      removeBulkEnrollment,
      setStepState,
      stepperState.selectTeachers.removeEnrollments
    ]
  )

  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])

  function handleDataChange(updatedData: Teacher[]): void {
    setData(updatedData)
  }

  return (
    <>
      <TextDialog
        isOpen={isDialogOpen}
        title={{
          label:
            i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialogNoHaveDiscipline
              .title
        }}
        align="center"
        contentTexts={[
          i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialogNoHaveDiscipline
            .contentText
        ]}
        onCancel={() => setIsDialogOpen(false)}
        acceptAction={{
          label:
            i18n().modules.hub.classroom.pages.enrollments.teacher.form.textDialogNoHaveDiscipline
              .acceptAction,
          handle: () => setIsDialogOpen(false)
        }}
        css={{ maxWidth: 600 }}
      />
      {!data.length ? (
        <EmptyList
          title={
            i18n().modules.hub.classroom.pages.enrollments.teacher.form.enrollment.noHaveTeachers
          }
        />
      ) : (
        <>
          <ListTeachers
            data={paginatedData}
            originalData={data}
            onDataChange={handleDataChange}
            isForm
          />
          {lastPage > 1 && (
            <Pagination
              lastPage={lastPage}
              page={listClassroomTeachersParams.page}
              setPage={(page) =>
                setListClassroomTeachersParams((oldState) => ({ ...oldState, page }))
              }
            />
          )}
        </>
      )}
    </>
  )
})
