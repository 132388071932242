export const whiteLabelPt = {
  resources: {
    show: 'Detalhes do white label',
    showAlias: 'Detalhes do alias',
    update: 'Alterar white label',
    create: 'Criar white label',
    updateSkin: 'Alterar tema'
  },
  page: {
    customization: {
      pageTitle: 'Personalização',
      alert: {
        update: 'Personalização aplicada com sucesso!',
        create: 'Personalização criada com sucesso!'
      },
      activeTheme: {
        title: 'Tema personalizado',
        subTitle: 'Permite você alterar o visual do Hub Educacional de acordo com sua escolha.',
        switchTitle: (params: boolean): string => (params ? 'Ligado' : 'Desligado'),
        inputs: {
          active: 'active'
        }
      },
      chooseTheme: {
        title: 'Escola um tema',
        subTitle:
          'Abaixo temos padrões de cores que ao selecionar serão aplicados em toda a plataforma:',
        preview: 'Pré-visualização'
      },
      uploadImage: {
        title: 'Carregue o logotipo da escola',
        subTitle:
          'Clique abaixo para carregar uma imagem personalizada da instituição no menu lateral, ela substituirá a logo padrão da Hub Educacional. Para melhor visualização, escolha uma imagem de fundo transparente ou branco.',
        inputs: {
          logo: 'Logo',
          icone: 'Ícone',
          helpText:
            'Logo com largura ideal de 400px por altura ideal de 185px. Ícone com largura e altura ideal de 180px por 180px.Nos formatos PNG ou SVG com até 1MB.'
        }
      },
      customAddress: {
        title: 'Endereço personalizado',
        subTitle:
          'Aqui você pode personalizar o endereço da página de login de sua escola. Utilize preferencialmente letras minúsculas, sem espaçamento entre as palavras ou palavras separados por traço.',
        url: 'https://hub.educacional.com/',
        inputs: {
          alias: {
            label: 'Insira o nome da escola...',
            unavailable: 'Nome indisponível',
            invalid: 'Nome inválido'
          }
        }
      },
      buttons: {
        save: 'Aplicar alterações'
      }
    },
    universe: {
      pageTitle: 'Personalização',
      alert: {
        updateWhiteLabel: 'Personalização aplicada com sucesso!'
      },
      activeTheme: {
        title: 'Universo',
        subTitle:
          'Transforma sua interface em um universo de aplicativos. Recomendado para usuários de 6 a 15 anos.',
        switchTitle: (params: boolean): string => (params ? 'Ligado' : 'Desligado')
      }
    }
  }
}
