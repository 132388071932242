import { Navigate, Route, Routes } from 'react-router-dom'

import { MockEdtechRequest } from '@/fixme/pages/MockEdtechRequest'
import { MockEdtechResponse } from '@/fixme/pages/MockEdtechResponse'

import { ChangePassword } from './pages/ChangePassword'
import { Login } from './pages/Login'
import { OrganizationList } from './pages/OrganizationList'
import { ProfileList } from './pages/ProfileList'
import { QrCode } from './pages/QrCode'
import { QuickAccess } from './pages/QuickAccess'
import { RecoverPassword } from './pages/RecoverPassword'
import { ResetPassword } from './pages/ResetPassword'

// DOCS: Remember to insert new routes in RESTRICT_NOT_SIGNED_PATHNAMES constant
export function AuthRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/mock-edtech-request" element={<MockEdtechRequest />} />
      <Route path="/mock-edtech-response" element={<MockEdtechResponse />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Navigate replace to="/" />} />
      <Route path="/:alias" element={<Login />} />
      <Route path="/profiles" element={<ProfileList />} />
      <Route path="/organizations" element={<OrganizationList />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/qr-code" element={<QrCode />} />
      <Route path="/quick-access" element={<QuickAccess />} />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  )
}
