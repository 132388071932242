import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useTheme } from '@positivote/design-system/hooks'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { Breakpoint } from '@positivote/design-system/theme'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { BotSeePhone } from '@/common/assets/icons/BotSeePhone'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { RecoverPasswordServiceParams } from '@/modules/hub/auth/contracts/services'
import { useRecoverPassword } from '@/modules/hub/auth/hooks'
import { recoverPasswordFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { recoverPasswordValidationSchema } from '@/modules/hub/auth/validations'

export function RecoverPassword(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.recoverPassword.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()
  const recoverPassword = useRecoverPassword()
  const {
    control,
    handleSubmit,
    setError,
    setFocus,
    formState: { errors }
  } = useForm<RecoverPasswordServiceParams>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(recoverPasswordValidationSchema)(recoverPasswordFormSanitizer(values), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  function onSubmit(model: RecoverPasswordServiceParams): void {
    recoverPassword.mutate({
      model,
      onSuccess: () => {
        navigate(`/change-password?email=${model.email}`)
      },
      onError: () => {
        setFocus('email')
        setError('email', { message: i18n().common.validators.emailNotFound })
      }
    })
  }

  return (
    <BaseBackground>
      <Grid xl={10} xs={12}>
        <Grid spacing="$lg" css={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid
            xl={6}
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '@sm': { display: 'none' }
            }}
          >
            <BotSeePhone />
          </Grid>
          <Grid xl={6} sm={12}>
            <FormContainer
              formHandleSubmit={handleSubmit}
              onSubmit={onSubmit}
              css={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                variant={
                  breakpoint === Breakpoint.sm
                    ? 'headlineSmall'
                    : breakpoint === Breakpoint.xs
                      ? 'titleLarge'
                      : 'headlineMedium'
                }
                css={{
                  color: '$on-surface',
                  width: '100%',
                  '@xl': { textAlign: 'left' },
                  '@sm': { textAlign: 'center' }
                }}
              >
                {i18n().modules.hub.auth.pages.recoverPassword.title}
              </Typography>
              <Typography
                variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
                css={{
                  color: '$on-surface-variant',
                  width: '100%',
                  marginTop: '$lg',
                  '@xl': { textAlign: 'left' },
                  '@sm': { textAlign: 'center', marginTop: '$md' }
                }}
              >
                {i18n().modules.hub.auth.pages.recoverPassword.subTitle}
              </Typography>
              <Div
                css={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '$lg',
                  '@sm': { marginTop: '$md' }
                }}
              >
                <IconWrapper
                  size="$2xl"
                  css={{
                    backgroundColor: '$primary-container',
                    marginTop: '$2xs',
                    marginRight: '$md',
                    '@xs': { display: 'none' }
                  }}
                >
                  <PersonIcon fill="$on-primary-container" />
                </IconWrapper>
                <FormTextField
                  control={control}
                  name="email"
                  label={i18n().modules.hub.auth.pages.recoverPassword.inputs.email}
                  variant="outlined"
                  errorText={errors.email?.message}
                  inputProps={{ autoComplete: 'new-password', inputMode: 'email', autoFocus: true }}
                  required
                />
              </Div>
              <Div
                css={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '$lg',
                  '@sm': { marginTop: '$md' },
                  '@xs': { flexDirection: 'column-reverse' }
                }}
              >
                <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
                <Button
                  variant="outlined"
                  css={{ flex: 1 }}
                  disabled={recoverPassword.isPending}
                  onClick={() => navigate(-1)}
                >
                  {i18n().modules.hub.auth.pages.recoverPassword.buttons.goBack}
                </Button>
                <Button
                  type="submit"
                  variant="filled"
                  css={{
                    flex: 1,
                    marginLeft: '$md',
                    '@xs': { marginLeft: '$none', marginBottom: '$2xs' }
                  }}
                  disabled={hasAnyError}
                  isLoading={recoverPassword.isPending}
                >
                  {i18n().modules.hub.auth.pages.recoverPassword.buttons.send}
                </Button>
              </Div>
            </FormContainer>
          </Grid>
        </Grid>
      </Grid>
    </BaseBackground>
  )
}
