import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import { AcceptTermHookParams, AcceptTermHookResult } from './contracts/hook'
import { acceptTermService } from './services'

export const hookKey = 'terms'

export function useAcceptTerm(): UseMutationResult<
  AcceptTermHookResult,
  ApplicationException,
  AcceptTermHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [hookKey, 'accept'],
    mutationFn: async ({ model, onSuccess, onError }: AcceptTermHookParams) => {
      try {
        const resultTerms = await acceptTermService(model)
        onSuccess?.(resultTerms)
        return resultTerms
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
