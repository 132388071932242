import { cnpjMask, currencyMask, parseValidDateOrDefault } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import {
  ContractApi,
  ContractFormatted,
  ShortContract,
  ShortContractApi
} from '@/modules/billing/contracts/contracts'

export function fromShortContractFormatSanitizer(contracts: ShortContractApi[]): ShortContract[] {
  return contracts.map((contract) => ({
    id: contract.id,
    endDate: parseValidDateOrDefault(contract.end_date),
    institution: {
      id: contract.institution.id,
      name: contract.institution.name,
      cinNumber: contract.institution.cin_number
    }
  }))
}

export function fromContractFormatSanitizer(contract: ContractApi): ContractFormatted {
  return {
    id: contract.id,
    endDate: contract.end_date,
    endDateFormatted: parseValidDateOrDefault(contract.end_date),
    toBill: contract.to_bill,
    toBillFormatted: currencyMask(contract.to_bill),
    totalContract: contract.total_contract,
    totalContractFormatted: currencyMask(contract.total_contract),
    totalOrders: contract.total_orders,
    totalOrdersFormatted: currencyMask(contract.total_orders),
    frequencyMonths: contract.frequency_months,
    frequencyMonthsFormatted:
      i18n().modules.billing.contracts.frequencyMonths[contract.frequency_months],
    institutionFormatted: {
      typeFormatted: i18n().modules.billing.contracts.typeSchool[contract.institution.type],
      cinNumberFormatted: cnpjMask(contract.institution.cin_number),
      addressFormatted: {
        location: `${contract.institution.address.city}/${contract.institution.address.state}`
      }
    },
    institution: {
      id: contract.institution.id,
      name: contract.institution.name,
      cinNumber: contract.institution.cin_number,
      tradeName: contract.institution.trade_name,
      type: contract.institution.type,
      address: {
        city: contract.institution.address.city,
        country: contract.institution.address.country,
        neighborhood: contract.institution.address.neighborhood,
        number: contract.institution.address.number,
        state: contract.institution.address.state,
        street: contract.institution.address.street
      }
    },
    createdAtFormatted: new Date(contract.created_at).toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    updatedAtFormatted: new Date(contract.updated_at).toLocaleString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    createdAt: contract.created_at,
    updatedAt: contract.updated_at
  }
}
