import { UserFlow, UserFlowApi } from './contracts'

export function fromUserFlowApiSanitizer(
  userFlows: UserFlowApi[],
  decriptValue: (value: string) => string
): UserFlow[] {
  return userFlows.map((userFlow) => ({
    id: userFlow._id,
    flowId: userFlow.flowId,
    type: userFlow.type,
    appId: userFlow.appId,
    userId: userFlow.userId,
    username: userFlow.username,
    domain: userFlow.domain,
    profiles: userFlow.profiles.map((profile) => ({
      name: profile.name,
      orgs: profile.orgs,
      roles: profile.roles,
      inputs: profile.inputs.map((input) => ({
        cls: input._cls,
        id: input.id,
        label: input.label,
        type: input.type,
        data: input.data && decriptValue(input.data)
      }))
    }))
  }))
}

export function toUserFlowApiSanitizer(
  userFlow: Omit<UserFlow, 'id'>,
  encriptValue: (value: string) => string
): Omit<UserFlowApi, '_id'> {
  return {
    flowId: userFlow.flowId,
    type: userFlow.type,
    appId: userFlow.appId,
    userId: userFlow.userId,
    username: userFlow.username,
    domain: userFlow.domain,
    profiles: userFlow.profiles.map((profile) => ({
      name: profile.name,
      orgs: profile.orgs,
      roles: profile.roles,
      inputs: profile.inputs.map((input) => ({
        _cls: input.cls,
        id: input.id,
        label: input.label,
        type: input.type,
        data: input.data && encriptValue(input.data)
      }))
    }))
  }
}

export function userFlowFormSanitizer(params: Record<string, string>): Record<string, string> {
  return Object.keys(params).reduce(
    (previousValue, currentValue) => ({ ...previousValue, [currentValue]: params[currentValue] }),
    {}
  )
}
