import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function KeyboardDoubleArrowLeftIcon({
  fill,
  size = '$lg',
  css,
  ...restProps
}: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path d="M17.59 18L19 16.59L14.42 12L19 7.41L17.59 6L11.59 12L17.59 18Z" css={{ fill }} />
      <Path d="M11 18L12.41 16.59L7.83 12L12.41 7.41L11 6L5 12L11 18Z" css={{ fill }} />
    </Svg>
  )
}
