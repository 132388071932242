import { yupResolver } from '@hookform/resolvers/yup'
import * as Dialog from '@positivote/design-system/components/Dialog'
import { FormCheckbox } from '@positivote/design-system/components-form/Checkbox'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTagInput } from '@positivote/design-system/components-form/TagInput'
import { useAlert } from '@positivote/design-system/hooks'
import { DownloadIcon } from '@positivote/design-system/icons/Download'
import { SendIcon } from '@positivote/design-system/icons/Send'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { downloadLink } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { GenerateInvoiceForm, SalesOrderFormatted } from '@/modules/billing/sales-orders/contracts'
import { useSendInvoiceByEmail } from '@/modules/billing/sales-orders/hooks'
import { generateInvoiceFormSanitizer } from '@/modules/billing/sales-orders/sanitizers'
import { generateInvoiceValidationSchema } from '@/modules/billing/sales-orders/validations'

interface GenerateInvoiceDialogProps {
  salesOrder: SalesOrderFormatted | null
  onClose: () => void
}

export function GenerateInvoiceDialog({
  salesOrder,
  onClose
}: GenerateInvoiceDialogProps): JSX.Element {
  const [downloadLoading, setDownloadLoading] = useState(false)

  const sendInvoiceByEmail = useSendInvoiceByEmail()
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<GenerateInvoiceForm>({
    mode: 'onSubmit',
    resolver: async (value, ...args) =>
      yupResolver(generateInvoiceValidationSchema)(generateInvoiceFormSanitizer(value), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length
  const { addAlertMessage } = useAlert()

  function handleCloseDialog(): void {
    onClose()
    clearErrors()
    reset({ emailsToInvoice: undefined, shouldSave: false })
  }

  function onSubmit(model: GenerateInvoiceForm): void {
    if (!salesOrder?.invoice?.files?.length) {
      return addAlertMessage({
        severity: 'warning',
        subTitle:
          i18n().modules.billing.salesOrders.components.generateInvoiceDialog.alert.withoutInvoiceError(
            'sendByEmail'
          )
      })
    }
    sendInvoiceByEmail.mutate({
      model: { ...model, id: salesOrder.invoice.id },
      salesOrderId: salesOrder.id,
      onSuccess: () => {
        handleCloseDialog()
      }
    })
  }

  function handleDownload(): void {
    if (!salesOrder?.invoice?.files?.length) {
      return addAlertMessage({
        severity: 'warning',
        subTitle:
          i18n().modules.billing.salesOrders.components.generateInvoiceDialog.alert.withoutInvoiceError(
            'download'
          )
      })
    }
    setDownloadLoading(true)
    downloadLink(
      salesOrder.invoice.files.map((file) => ({
        name: i18n().modules.billing.salesOrders.components.generateInvoiceDialog.fileName.single,
        link: file.fileUrl
      })),
      i18n().modules.billing.salesOrders.components.generateInvoiceDialog.fileName.zip
    )
      .then(() => {
        setDownloadLoading(false)
      })
      .catch(() => {
        addAlertMessage({
          severity: 'warning',
          subTitle:
            i18n().modules.billing.salesOrders.components.generateInvoiceDialog.alert
              .downloadInvoiceError
        })
        setDownloadLoading(false)
      })
  }

  useEffect(() => {
    if (salesOrder?.emailsToInvoice?.length) {
      reset({ emailsToInvoice: salesOrder.emailsToInvoice })
    }
  }, [reset, salesOrder?.emailsToInvoice])

  return (
    <Dialog.Container
      data-testid="GenerateInvoice"
      isOpen={!!salesOrder}
      onCancel={() => handleCloseDialog()}
    >
      <FormContainer formHandleSubmit={handleSubmit} onSubmit={onSubmit}>
        <Dialog.Header>
          <Dialog.HeaderTitle data-testid="GenerateInvoice">
            {i18n().modules.billing.salesOrders.components.generateInvoiceDialog.title}
          </Dialog.HeaderTitle>
          <Dialog.HeaderCloseButton data-testid="GenerateInvoice" onCancel={handleCloseDialog} />
        </Dialog.Header>
        <Dialog.Content>
          <Dialog.ContentText data-testid="GenerateInvoice-sendBillEmail">
            {i18n().modules.billing.salesOrders.components.generateInvoiceDialog.sendBillEmail}
          </Dialog.ContentText>
          <FormTagInput
            control={control}
            name="emailsToInvoice"
            label={
              i18n().modules.billing.salesOrders.components.generateInvoiceDialog.inputs
                .emailsToInvoice.label
            }
            required
            variant="outlined"
            errorText={
              errors.emailsToInvoice?.message ??
              errors.emailsToInvoice?.find?.((error) => !!error)?.message
            }
            supportingText={
              i18n().modules.billing.salesOrders.components.generateInvoiceDialog.inputs
                .emailsToInvoice.supportingText
            }
          />
          <FormCheckbox
            register={register}
            name="shouldSave"
            label={
              i18n().modules.billing.salesOrders.components.generateInvoiceDialog.inputs.shouldSave
            }
          />
          <Dialog.ContentText data-testid="GenerateInvoice-preferDownloadBill">
            {i18n().modules.billing.salesOrders.components.generateInvoiceDialog.preferDownloadBill}
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Footer>
          <Dialog.FooterRefuseButton
            data-testid="GenerateInvoice"
            LeadingIcon={<DownloadIcon size={18} />}
            onClick={handleDownload}
            disabled={sendInvoiceByEmail.isPending}
            isLoading={downloadLoading}
          >
            {i18n().modules.billing.salesOrders.components.generateInvoiceDialog.buttons.refuse}
          </Dialog.FooterRefuseButton>
          <Dialog.FooterAcceptButton
            data-testid="GenerateInvoice"
            LeadingIcon={<SendIcon size={18} />}
            type="submit"
            disabled={downloadLoading || hasAnyError}
            isLoading={sendInvoiceByEmail.isPending}
          >
            {i18n().modules.billing.salesOrders.components.generateInvoiceDialog.buttons.accept}
          </Dialog.FooterAcceptButton>
        </Dialog.Footer>
      </FormContainer>
    </Dialog.Container>
  )
}
