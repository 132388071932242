import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  CreateServiceMappingHookParams,
  CreateServiceMappingHookResult,
  ListServiceMappingHookParams,
  ListServiceMappingHookResult,
  RemoveServiceMappingHookParams,
  ShowServiceMappingHookParams,
  ShowServiceMappingHookResult,
  UpdateServiceMappingHookParams,
  UpdateServiceMappingHookResult,
  VerifyServiceMappingHookParams,
  VerifyServiceMappingHookResult
} from './contracts'
import {
  createServiceMappingService,
  listServiceMappingService,
  removeServiceMappingService,
  showServiceMappingService,
  updateServiceMappingService,
  verifyServiceMappingService
} from './services'

export const hookKey = 'service-mapping'

export function useListServiceMapping({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListServiceMappingHookParams): UseQueryResult<
  ListServiceMappingHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listServiceMapping = await listServiceMappingService(model)
        onSuccess?.(listServiceMapping)
        return listServiceMapping
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useCreateServiceMapping(): UseMutationResult<
  CreateServiceMappingHookResult,
  ApplicationException,
  CreateServiceMappingHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({
      model,
      listServiceMappingParams,
      onSuccess,
      onError
    }: CreateServiceMappingHookParams) => {
      try {
        const createdServiceMapping = await createServiceMappingService(model)
        onSuccess?.(createdServiceMapping)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.billing.serviceMapping.pages.form.stepper.preview.alert.created
        })
        queryClient.setQueryData(
          [hookKey, 'show', { id: createdServiceMapping.id }],
          () => createdServiceMapping
        )
        if (listServiceMappingParams) {
          queryClient.setQueryData(
            [hookKey, 'list', listServiceMappingParams],
            (oldData: ListServiceMappingHookResult | undefined) =>
              oldData && {
                ...oldData,
                total: oldData.total + 1,
                registers: [
                  createdServiceMapping,
                  ...oldData.registers.slice(0, (listServiceMappingParams.perPage ?? 1) - 1)
                ]
              }
          )
        }
        return createdServiceMapping
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useVerifyServiceMapping(): UseMutationResult<
  VerifyServiceMappingHookResult,
  ApplicationException,
  VerifyServiceMappingHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'verify'],
    mutationFn: async ({ model, onSuccess, onError }: VerifyServiceMappingHookParams) => {
      try {
        const queryKey = [hookKey, 'verify', model]
        const queryData = queryClient.getQueryData<VerifyServiceMappingHookResult>(queryKey)
        if (queryData) {
          onSuccess?.(queryData)
          return queryData
        }
        const verifiedServiceMapping = await verifyServiceMappingService(model)
        queryClient.setQueryData(queryKey, () => verifiedServiceMapping)
        onSuccess?.(verifiedServiceMapping)
        return verifiedServiceMapping
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowServiceMapping({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowServiceMappingHookParams): UseQueryResult<
  ShowServiceMappingHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showServiceMapping = await showServiceMappingService(model)
        onSuccess?.(showServiceMapping)
        return showServiceMapping
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateServiceMapping(): UseMutationResult<
  UpdateServiceMappingHookResult,
  ApplicationException,
  UpdateServiceMappingHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({
      model,
      listServiceMappingParams,
      onSuccess,
      onError
    }: UpdateServiceMappingHookParams) => {
      try {
        const updatedServiceMapping = await updateServiceMappingService(model)
        onSuccess?.(updatedServiceMapping)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.billing.serviceMapping.pages.form.stepper.preview.alert.updated
        })
        queryClient.setQueryData(
          [hookKey, 'show', { id: updatedServiceMapping.id }],
          () => updatedServiceMapping
        )
        if (listServiceMappingParams) {
          queryClient.setQueryData(
            [hookKey, 'list', listServiceMappingParams],
            (oldData: ListServiceMappingHookResult | undefined) => {
              if (!oldData) {
                return oldData
              }
              const newRegisters = [...oldData.registers]
              const indexFound = newRegisters.findIndex(
                (register) => register.id === updatedServiceMapping.id
              )
              newRegisters.splice(indexFound, 1, updatedServiceMapping)
              return { ...oldData, registers: newRegisters }
            }
          )
        }
        return updatedServiceMapping
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useRemoveServiceMapping(): UseMutationResult<
  void,
  ApplicationException,
  RemoveServiceMappingHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'remove'],
    mutationFn: async ({
      model,
      listServiceMappingParams,
      onSuccess,
      onError
    }: RemoveServiceMappingHookParams) => {
      try {
        await removeServiceMappingService(model)
        onSuccess?.(null)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.billing.serviceMapping.pages.form.stepper.preview.alert.removed
        })
        queryClient.removeQueries({ queryKey: [hookKey, 'show', model] })
        const queryData = queryClient.getQueryData<ListServiceMappingHookResult>([
          hookKey,
          listServiceMappingParams
        ])
        queryClient.setQueryData(
          [hookKey, 'list', listServiceMappingParams],
          (oldData: ListServiceMappingHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => register.id !== model.id)
            }
        )
        if (queryData?.registers.length === 1) {
          void queryClient.invalidateQueries({ queryKey: [hookKey] })
        }
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
