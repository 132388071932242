const lastActionAtKey = '@hub/idleTimer:lastActionAt'

export function saveLastActionAtRepository(lastActionAt: number): void {
  localStorage.setItem(lastActionAtKey, JSON.stringify(lastActionAt))
}

export function getLastActionAtRepository(): number {
  return Number(localStorage.getItem(lastActionAtKey) ?? 0)
}

export function removeLastActionAtRepository(): void {
  localStorage.removeItem(lastActionAtKey)
}
