import {
  CreateOAuthTokenApiResult,
  CreateOAuthTokenServiceResult,
  ListDeviceApiParams,
  ListDeviceApiResult,
  ListDeviceServiceParams,
  ListDeviceServiceResult,
  ShowDeviceApiResult,
  ShowDeviceServiceResult,
  ShowMetabaseDeviceApiParams,
  ShowMetabaseDeviceDetailsApiParams,
  ShowMetabaseDeviceDetailsServiceParams,
  ShowMetabaseDeviceServiceParams,
  ShowMetabaseGeneralInformationApiParams,
  ShowMetabaseGeneralInformationServiceParams
} from './contracts'
import {
  DeviceDetailsForm,
  DevicesFilterForm,
  DevicesSearchForm,
  GeneralInformationForm,
  SetupForm
} from './contracts/form'

export function listDeviceApiParamsSanitizer(params: ListDeviceServiceParams): ListDeviceApiParams {
  return {
    page: params.page,
    startDate: `${params.startDate.split('T')[0]} 00:00:00`,
    endDate: `${params.endDate.split('T')[0]} 00:00:00`,
    paginate_by: params.paginateBy,
    orgId: params.orgId,
    status: params.status ? params.status : undefined
  }
}

export function listDeviceApiResultSanitizer(params: ListDeviceApiResult): ListDeviceServiceResult {
  return {
    page: params.page ?? 1,
    lastPage: params.total_pages ?? 1,
    perPage: params.content_range ?? 1,
    total: params.total ?? 1,
    order: params.order ?? 'desc',
    orderBy: params.orderBy ?? 'desc',
    registers: params.data.map((device) => ({
      ...device,
      lastAccess: device.lastAccess ? device.lastAccess : '-',
      lastUpdate: device.lastUpdate ? device.lastUpdate : '-',
      status: device.status === 'ACTIVE' ? 'Provisionado' : 'Desprovisionado'
    }))
  }
}

export function showDeviceApiResultSanitizer(
  devices: ShowDeviceApiResult[]
): ShowDeviceServiceResult[] {
  return devices.map((device) => ({
    ...device,
    deviceId: device.device_id,
    serialNumber: device.serial_number,
    activeTime: device.active_time,
    osVersion: device.os_version,
    platformVersion: device.platform_version,
    firmwareVersion: device.firmware_version,
    macAddress: device.mac_address,
    lastSync: device.last_sync,
    firstEnrollmentTime: device.first_enrollment_time,
    lastEnrollmentTime: device.last_enrollment_time,
    orgId: device.org_id,
    orgPath: device.org_path
  }))
}

export function showMetabaseGeneralinformationApiParamsSanitizer(
  params: ShowMetabaseGeneralInformationServiceParams
): ShowMetabaseGeneralInformationApiParams {
  return {
    payload: {
      resource: {
        dashboard: params.payload.resource.dashboard
      },
      params: {
        date_from: params.payload.params.dateFrom,
        date_to: params.payload.params.dateTo,
        org_id: params.payload.params.orgId,
        dif_time: params.payload.params.difTime
      }
    }
  }
}

export function showMetabaseDeviceApiParamsSanitizer(
  params: ShowMetabaseDeviceServiceParams
): ShowMetabaseDeviceApiParams {
  return {
    payload: {
      resource: {
        dashboard: params.payload.resource.dashboard
      },
      params: {
        date_from: `${params.payload.params.dateFrom}`,
        date_to: `${params.payload.params.dateTo}`,
        org_id: params.payload.params.orgId,
        situacao: params.payload.params.situacao
      }
    }
  }
}

export function showMetabaseDeviceDetailsApiParamsSanitizer(
  params: ShowMetabaseDeviceDetailsServiceParams
): ShowMetabaseDeviceDetailsApiParams {
  return {
    payload: {
      resource: {
        dashboard: params.payload.resource.dashboard
      },
      params: {
        date_from: params.payload.params.dateFrom,
        date_to: params.payload.params.dateTo,
        device_id: params.payload.params.deviceId,
        dif_time: params.payload.params.difTime
      }
    }
  }
}

export function createOAuthTokenApiResultSanitizer(
  params: CreateOAuthTokenApiResult
): CreateOAuthTokenServiceResult {
  return {
    idToken: params.id_token,
    accessToken: params.access_token,
    refreshToken: params.refresh_token
  }
}

export function deviceDetailsFormSanitizer(params: DeviceDetailsForm): DeviceDetailsForm {
  return {
    period: params.period,
    initialDate: params.initialDate,
    finalDate: params.finalDate
  }
}

export function devicesSearchFormSanitizer(params: DevicesSearchForm): DevicesSearchForm {
  return {
    searchDevice: (params.searchDevice || '').trim()
  }
}

export function deviceFilterFormSanitizer(params: DevicesFilterForm): DevicesFilterForm {
  return {
    organization: params.organization,
    situation: params.situation,
    initialDate: params.initialDate,
    finalDate: params.finalDate
  }
}

export function generalInformationFormSanitizer(
  params: GeneralInformationForm
): GeneralInformationForm {
  return {
    organization: (params.organization || '').trim(),
    initialDate: params.initialDate,
    finalDate: params.finalDate
  }
}

export function setupFormSanitizer(params: SetupForm): SetupForm {
  return {
    clientId: (params.clientId || '').trim(),
    customerId: (params.customerId || '').trim(),
    secret: (params.secret || '').trim()
  }
}
