export const hubotPt = {
  resources: {
    showUserFlow: 'Visualizar configuração de acesso',
    updateUserFlow: 'Atualizar configuração de acesso'
  },
  services: {
    secretKeyNotConfigured: 'Chave secreta não configurada no storage'
  },
  components: {
    configureAccessDialog: {
      accessTo: (applicationName: string): string => `Acesso ao ${applicationName}`,
      contentTexts: {
        editing: 'É preciso atualizar suas informações de acesso abaixo',
        loading: 'Aguarde enquanto seu acesso está sendo atualizado',
        success: 'Acesso configurado com sucesso!',
        error: 'Não foi possível configurar o acesso, tente novamente.'
      },
      alert: {
        updated: 'Configuração de acesso alterada com sucesso!'
      },
      buttons: { refuse: 'Voltar', accept: 'Concluir atualização' }
    }
  }
}
