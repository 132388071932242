import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundTopRightMobile({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="76"
      height="117"
      viewBox="0 0 76 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M8.04536 115.234C8.03613 115.267 8.03152 115.328 8.09839 115.373C9.26628 116.152 10.6867 116.548 12.3203 116.548C14.2768 116.548 15.9035 115.966 17.1544 114.818C18.4192 113.66 19.0602 112.188 19.0602 110.443C19.0602 108.699 18.5379 107.238 17.5072 106.279C16.9596 105.769 16.2194 105.241 15.3075 104.712C15.2498 104.679 15.2003 104.699 15.1761 104.714C15.1518 104.729 15.1103 104.766 15.1161 104.834C15.1957 105.897 15.2118 106.872 15.1622 107.73C15.1599 107.767 15.1772 107.804 15.206 107.831C15.9992 108.534 16.3082 109.287 16.3082 110.515C16.3082 112.507 14.6088 114.009 12.3549 114.009C12.1312 114.009 11.9087 113.998 11.6954 113.975C11.6632 113.971 11.6332 113.977 11.6078 113.993C11.5559 114.024 11.5029 114.056 11.4487 114.086C10.3754 114.706 9.26858 115.061 8.15949 115.14C8.07994 115.146 8.05343 115.201 8.0442 115.235L8.04536 115.234Z"
          css={{ fill }}
        />
        <Path
          d="M6.98353 94.1031C5.7188 95.2618 5.07778 96.7341 5.07778 98.4784C5.07778 100.223 5.60005 101.683 6.63074 102.643C7.17837 103.152 7.91854 103.68 8.83048 104.209C8.88813 104.243 8.9377 104.222 8.96191 104.207C8.98612 104.192 9.02763 104.155 9.02186 104.087C8.94231 103.024 8.92617 102.05 8.97575 101.191C8.97805 101.154 8.96076 101.117 8.93194 101.091C8.13874 100.388 7.82976 99.6348 7.82976 98.4069C7.82976 96.4147 9.52914 94.9125 11.7831 94.9125C12.0067 94.9125 12.2292 94.924 12.4425 94.9471C12.4483 94.9471 12.4541 94.9471 12.4598 94.9471C12.4852 94.9471 12.5094 94.9401 12.5301 94.9275C12.582 94.8963 12.6351 94.8652 12.6892 94.8341C13.7614 94.215 14.8682 93.8599 15.9785 93.7803C16.058 93.7746 16.0845 93.7192 16.0938 93.6858C16.103 93.6524 16.1076 93.5912 16.0407 93.5463C14.874 92.7669 13.4536 92.3715 11.82 92.3715C9.86348 92.3715 8.23674 92.9537 6.98584 94.1008L6.98353 94.1031Z"
          css={{ fill }}
        />
        <Path
          d="M23.4101 102.56C23.4435 102.55 23.4989 102.524 23.5046 102.443C23.5957 101.042 23.2279 99.6152 22.4117 98.2006C21.434 96.5069 20.1162 95.3886 18.4976 94.8779C16.8616 94.3625 15.266 94.5435 13.7557 95.4151C12.1935 96.3167 11.2412 97.4696 10.9253 98.8416C10.757 99.5714 10.6717 100.475 10.6682 101.53C10.6682 101.597 10.7109 101.629 10.7351 101.642C10.7604 101.656 10.8123 101.673 10.8688 101.634C11.7496 101.033 12.5855 100.533 13.3533 100.146C13.3856 100.129 13.4098 100.096 13.4179 100.057C13.63 99.018 14.1281 98.3746 15.191 97.7601C16.9169 96.764 19.0671 97.4846 20.1946 99.4365C20.3065 99.6302 20.4079 99.8285 20.4944 100.024C20.5071 100.054 20.5278 100.076 20.5532 100.091C20.6062 100.121 20.6604 100.151 20.7134 100.182C21.7868 100.802 22.6469 101.583 23.2706 102.504C23.3155 102.571 23.3767 102.565 23.4101 102.556V102.56Z"
          css={{ fill }}
        />
        <Path
          d="M5.64155 114.044C7.27752 114.559 8.87314 114.378 10.3834 113.506C11.9456 112.605 12.8979 111.452 13.2138 110.08C13.3821 109.35 13.4675 108.446 13.4709 107.391C13.4709 107.325 13.4283 107.292 13.404 107.28C13.3787 107.266 13.3268 107.249 13.2703 107.288C12.3895 107.888 11.5536 108.389 10.7858 108.776C10.7535 108.792 10.7293 108.826 10.7212 108.865C10.5091 109.904 10.0111 110.547 8.94808 111.161C7.22218 112.158 5.07202 111.437 3.94448 109.485C3.83265 109.291 3.7312 109.093 3.64473 108.897C3.64242 108.891 3.64012 108.887 3.63666 108.882C3.62398 108.86 3.60668 108.842 3.58478 108.83C3.53175 108.8 3.47756 108.77 3.42453 108.739C2.35232 108.12 1.49111 107.338 0.867387 106.417C0.822423 106.35 0.761321 106.356 0.727885 106.364C0.694451 106.373 0.639111 106.4 0.633347 106.481C0.542268 107.881 0.910044 109.309 1.7263 110.723C2.70396 112.418 4.02173 113.535 5.6404 114.046L5.64155 114.044Z"
          css={{ fill }}
        />
        <Path
          d="M4.75151 95.5904C4.7273 95.5662 4.67542 95.5316 4.60394 95.5673C3.34497 96.1887 2.29353 97.2206 1.47612 98.6352C0.498457 100.329 0.188325 102.029 0.556102 103.686C0.927334 105.361 1.88194 106.652 3.39339 107.524C4.95558 108.426 6.43014 108.674 7.77558 108.262C8.49153 108.043 9.31816 107.665 10.2324 107.14C10.2901 107.107 10.297 107.054 10.2958 107.026C10.2958 106.997 10.2843 106.944 10.222 106.914C9.26167 106.452 8.41082 105.978 7.69141 105.507C7.66144 105.487 7.61993 105.482 7.58304 105.495C6.57771 105.83 5.77068 105.722 4.7077 105.108C2.98181 104.111 2.53102 101.889 3.65741 99.9357C3.76924 99.742 3.8903 99.5552 4.01711 99.3823C4.03671 99.3558 4.04594 99.3269 4.04479 99.2981C4.04363 99.237 4.04248 99.1759 4.04248 99.1137C4.04248 97.8743 4.2892 96.7387 4.77572 95.738C4.81031 95.6665 4.77572 95.6146 4.75151 95.5915V95.5904Z"
          css={{ fill }}
        />
        <Path
          d="M23.5819 105.236C23.2106 103.56 22.256 102.269 20.7446 101.398C19.1824 100.496 17.7078 100.248 16.3624 100.66C15.6464 100.879 14.8198 101.257 13.9056 101.781C13.8479 101.815 13.841 101.868 13.8421 101.896C13.8421 101.924 13.8537 101.977 13.9159 102.007C14.8763 102.47 15.7271 102.944 16.4466 103.415C16.4765 103.435 16.518 103.439 16.5549 103.427C17.5603 103.091 18.3673 103.2 19.4303 103.814C21.1562 104.81 21.6069 107.033 20.4806 108.986C20.3687 109.18 20.2477 109.366 20.1209 109.539C20.1174 109.544 20.1139 109.549 20.1116 109.553C20.0989 109.575 20.092 109.599 20.0932 109.624C20.0943 109.685 20.0955 109.746 20.0955 109.808C20.0955 111.046 19.8488 112.182 19.3634 113.184C19.3288 113.255 19.3634 113.307 19.3876 113.33C19.4118 113.354 19.4637 113.389 19.5352 113.353C20.7941 112.732 21.8456 111.7 22.663 110.285C23.6407 108.593 23.9496 106.893 23.5819 105.236Z"
          css={{ fill }}
        />
        <Path
          d="M51.438 38.7356C51.4225 38.7914 51.4148 38.8936 51.5266 38.9687C53.4785 40.2712 55.8523 40.9321 58.5826 40.9321C61.8524 40.9321 64.5711 39.9591 66.6617 38.0419C68.7754 36.1055 69.8467 33.6449 69.8467 30.7297C69.8467 27.8144 68.9739 25.3731 67.2513 23.77C66.3361 22.9184 65.0991 22.0359 63.575 21.1515C63.4786 21.0956 63.3958 21.1303 63.3553 21.1553C63.3148 21.1804 63.2455 21.242 63.2551 21.3557C63.388 23.1322 63.415 24.7604 63.3322 26.1959C63.3283 26.2575 63.3572 26.3192 63.4054 26.3635C64.731 27.5389 65.2474 28.7971 65.2474 30.8491C65.2474 34.1786 62.4073 36.6893 58.6404 36.6893C58.2666 36.6893 57.8947 36.67 57.5382 36.6315C57.4843 36.6257 57.4342 36.6353 57.3918 36.6623C57.3051 36.7143 57.2165 36.7683 57.1259 36.8184C55.332 37.8531 53.4823 38.4465 51.6287 38.5795C51.4958 38.5891 51.4514 38.6816 51.436 38.7375L51.438 38.7356Z"
          css={{ fill }}
        />
        <Path
          d="M49.6634 3.42093C47.5496 5.35738 46.4783 7.81792 46.4783 10.7332C46.4783 13.6485 47.3512 16.0897 49.0738 17.6928C49.989 18.5445 51.226 19.427 52.7501 20.3114C52.8465 20.3673 52.9293 20.3326 52.9698 20.3075C53.0102 20.2825 53.0796 20.2208 53.07 20.1071C52.937 18.3306 52.91 16.7025 52.9929 15.267C52.9967 15.2053 52.9678 15.1437 52.9197 15.0993C51.594 13.924 51.0776 12.6658 51.0776 10.6137C51.0776 7.28419 53.9178 4.77355 57.6847 4.77355C58.0585 4.77355 58.4304 4.79282 58.7868 4.83136C58.7965 4.83136 58.8061 4.83136 58.8157 4.83136C58.8581 4.83136 58.8986 4.81979 58.9333 4.7986C59.02 4.74657 59.1086 4.69455 59.1992 4.64253C60.9911 3.60783 62.8408 3.01437 64.6964 2.88142C64.8293 2.87179 64.8736 2.7793 64.889 2.72342C64.9044 2.66755 64.9122 2.56543 64.8004 2.49028C62.8505 1.18775 60.4766 0.526855 57.7463 0.526855C54.4765 0.526855 51.7578 1.4999 49.6672 3.41708L49.6634 3.42093Z"
          css={{ fill }}
        />
        <Path
          d="M77.1166 17.5541C77.1725 17.5387 77.265 17.4944 77.2746 17.3595C77.4268 15.0184 76.8121 12.633 75.448 10.2688C73.814 7.43834 71.6117 5.56932 68.9064 4.71574C66.1723 3.85446 63.5056 4.15697 60.9815 5.61364C58.3706 7.12041 56.7791 9.04722 56.2511 11.3401C55.9698 12.5598 55.8272 14.0704 55.8215 15.8335C55.8215 15.9452 55.8927 15.9992 55.9332 16.0204C55.9756 16.0435 56.0623 16.0724 56.1567 16.0069C57.6288 15.003 59.0257 14.1668 60.309 13.5194C60.363 13.4924 60.4034 13.4365 60.4169 13.371C60.7714 11.6349 61.6038 10.5598 63.3803 9.53278C66.2648 7.86801 69.8583 9.07227 71.7427 12.3344C71.9296 12.6581 72.0992 12.9895 72.2437 13.317C72.2649 13.3671 72.2996 13.4037 72.3419 13.4288C72.4306 13.4789 72.5211 13.529 72.6098 13.581C74.4036 14.6157 75.841 15.9221 76.8834 17.4616C76.9586 17.5734 77.0607 17.5637 77.1166 17.5483V17.5541Z"
          css={{ fill }}
        />
        <Path
          d="M47.4206 36.7471C50.1547 37.6084 52.8214 37.3059 55.3455 35.8492C57.9564 34.3424 59.5479 32.4156 60.0759 30.1227C60.3572 28.903 60.4998 27.3924 60.5055 25.6294C60.5055 25.5176 60.4342 25.4637 60.3938 25.4425C60.3514 25.4194 60.2647 25.3905 60.1703 25.456C58.6982 26.4598 57.3012 27.2961 56.018 27.9435C55.964 27.9705 55.9236 28.0263 55.9101 28.0918C55.5556 29.8279 54.7232 30.9031 52.9467 31.9301C50.0622 33.5948 46.4687 32.3906 44.5843 29.1285C44.3974 28.8048 44.2278 28.4734 44.0833 28.1458C44.0795 28.1362 44.0756 28.1285 44.0698 28.1207C44.0486 28.0841 44.0197 28.0533 43.9831 28.034C43.8945 27.9839 43.8039 27.9338 43.7153 27.8818C41.9234 26.8471 40.484 25.5407 39.4416 24.0012C39.3665 23.8895 39.2644 23.8991 39.2085 23.9126C39.1526 23.928 39.0601 23.9723 39.0505 24.1072C38.8983 26.4483 39.5129 28.8337 40.8771 31.1979C42.511 34.0303 44.7134 35.8974 47.4186 36.7509L47.4206 36.7471Z"
          css={{ fill }}
        />
        <Path
          d="M45.9331 5.90652C45.8926 5.86605 45.8059 5.80825 45.6864 5.86798C43.5823 6.90653 41.8251 8.63103 40.459 10.9952C38.825 13.8257 38.3067 16.6678 38.9214 19.4366C39.5418 22.2363 41.1372 24.3943 43.6633 25.851C46.2741 27.3577 48.7385 27.772 50.9871 27.0841C52.1836 26.718 53.5652 26.086 55.0931 25.2093C55.1895 25.1535 55.201 25.0648 55.1991 25.0186C55.1991 24.9704 55.1798 24.8818 55.0758 24.8317C53.4707 24.059 52.0488 23.2671 50.8464 22.479C50.7963 22.4463 50.727 22.4386 50.6653 22.4598C48.9851 23.0205 47.6364 22.8394 45.8598 21.8124C42.9754 20.1476 42.222 16.4327 44.1045 13.1687C44.2914 12.845 44.4937 12.5328 44.7057 12.2438C44.7384 12.1995 44.7538 12.1513 44.7519 12.1031C44.75 12.001 44.7481 11.8989 44.7481 11.7949C44.7481 9.72353 45.1604 7.82562 45.9735 6.15315C46.0313 6.03369 45.9735 5.94698 45.9331 5.90844V5.90652Z"
          css={{ fill }}
        />
        <Path
          d="M77.4037 22.0262C76.7833 19.2266 75.1879 17.0685 72.6618 15.6119C70.051 14.1051 67.5866 13.6908 65.338 14.3787C64.1414 14.7448 62.7599 15.3768 61.2319 16.2535C61.1356 16.3094 61.124 16.398 61.126 16.4443C61.126 16.4924 61.1452 16.5811 61.2493 16.6312C62.8543 17.4038 64.2763 18.1957 65.4786 18.9838C65.5287 19.0166 65.5981 19.0243 65.6598 19.0031C67.3399 18.4424 68.6887 18.6235 70.4652 19.6505C73.3497 21.3152 74.1031 25.0301 72.2206 28.2942C72.0337 28.6179 71.8313 28.93 71.6194 29.219C71.6136 29.2267 71.6078 29.2344 71.604 29.2422C71.5828 29.2788 71.5712 29.3192 71.5732 29.3597C71.5751 29.4618 71.577 29.5639 71.577 29.668C71.577 31.7374 71.1647 33.6353 70.3535 35.3097C70.2957 35.4291 70.3535 35.5159 70.3939 35.5544C70.4344 35.5949 70.5211 35.6527 70.6406 35.5929C72.7447 34.5544 74.5019 32.8299 75.868 30.4657C77.5019 27.6371 78.0183 24.797 77.4037 22.0262Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
