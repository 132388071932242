import { useAlert, useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { ApplicationException } from '@/common/exceptions'
import { formatDatePtBr } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'

import {
  ActivateSchoolYearHookParams,
  CreateSchoolYearHookParams,
  CreateSchoolYearHookResult,
  ListSchoolClassHookParams,
  ListSchoolClassHookResult,
  ListSchoolYearHookParams,
  ListSchoolYearHookResult,
  PromoteSchoolYearHookParams,
  RemoveSchoolYearHookParams,
  ShowSchoolYearHookParams,
  ShowSchoolYearHookResult,
  UpdateSchoolYearHookParams,
  UpdateSchoolYearHookResult
} from './contracts/hooks'
import {
  activateSchoolYearService,
  createSchoolYearService,
  listSchoolClassService,
  listSchoolYearService,
  promoteSchoolYearService,
  removeSchoolYearService,
  showSchoolYearService,
  updateSchoolYearService
} from './services'

export const hookKey = 'school-year'

export function useListSchoolYear({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolYearHookParams): UseQueryResult<ListSchoolYearHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    queryFn: async () => {
      try {
        const listSchoolYear = await listSchoolYearService(model)
        onSuccess?.(listSchoolYear)
        return listSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSchoolClasses({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolClassHookParams): UseQueryResult<ListSchoolClassHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useQuery({
    queryKey: [hookKey, 'listSchoolClass', model],
    queryFn: async () => {
      try {
        const listedSchoolClass = await listSchoolClassService(model)
        onSuccess?.(listedSchoolClass)
        return listedSchoolClass
      } catch (error) {
        const parsedError = error as ApplicationException
        if (parsedError.code === 'ERROR.TERM.SCHOOL_YEAR_NOT_FOUND') {
          addAlertMessage({
            severity: 'warning',
            subTitle: i18n().modules.hub.schoolYear.pages.promotion.alert.activeSchoolYear
          })
          onError?.({ error: parsedError })
        } else {
          handleError({ error: parsedError })
        }
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useActivateSchoolYear(): UseMutationResult<
  void,
  ApplicationException,
  ActivateSchoolYearHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { profile } = useAuth()
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'activate'],
    mutationFn: async ({ model, onSuccess, onError }: ActivateSchoolYearHookParams) => {
      try {
        await activateSchoolYearService(model)
        const newStatus = i18n().modules.hub.schoolYear.pages.list.newStatus(model.status)

        onSuccess?.(null)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.schoolYear.pages.list.alert.activate(newStatus)
        })

        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              perPage: model.perPage,
              page: model.page
            }
          ],
          (oldData: ListSchoolYearHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.map((schoolYear) => {
                if (isSchool) {
                  if (model.idSchoolYear === schoolYear.id) {
                    return {
                      ...schoolYear,
                      statusFormatted: newStatus,
                      status: model.status
                    }
                  } else {
                    return {
                      ...schoolYear,
                      statusFormatted: i18n().modules.hub.schoolYear.pages.list.inactive,
                      status: 'inactive'
                    }
                  }
                } else {
                  if (model.idSchoolYear === schoolYear.id) {
                    return {
                      ...schoolYear,
                      statusFormatted: newStatus,
                      status: model.status
                    }
                  } else {
                    if (
                      model.institutionId === schoolYear.institutionId &&
                      schoolYear.id !== model.idSchoolYear
                    ) {
                      return {
                        ...schoolYear,
                        statusFormatted: i18n().modules.hub.schoolYear.pages.list.inactive,
                        status: 'inactive'
                      }
                    }
                  }
                  return {
                    ...schoolYear
                  }
                }
              })
            }
        )
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useRemoveSchoolYear(): UseMutationResult<
  void,
  ApplicationException,
  RemoveSchoolYearHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'remove'],
    mutationFn: async ({ model, onSuccess, onError }: RemoveSchoolYearHookParams) => {
      try {
        await removeSchoolYearService(model)
        onSuccess?.(null)

        queryClient.removeQueries({
          queryKey: [hookKey, 'show', { termId: String(model.idSchoolYear) }]
        })

        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.schoolYear.pages.list.alert.remove
        })
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              perPage: model.perPage,
              page: model.page
            }
          ],
          (oldData: ListSchoolYearHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => register.id !== model.idSchoolYear)
            }
        )
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useCreateSchoolYear(): UseMutationResult<
  CreateSchoolYearHookResult,
  ApplicationException,
  CreateSchoolYearHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateSchoolYearHookParams) => {
      try {
        const createSchoolYear = await createSchoolYearService(model)
        onSuccess?.(createSchoolYear)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.schoolYear.pages.list.alert.create
        })
        queryClient.setQueryData(
          [hookKey, 'show', { termId: String(createSchoolYear.id) }],
          () => createSchoolYear
        )
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE,
              page: 1
            }
          ],
          (oldData: ListSchoolYearHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: [createSchoolYear, ...oldData.registers].sort((a, b) =>
                a.status === 'active' ? -1 : b.status === 'active' ? 1 : 0
              )
            }
        )
        return createSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateSchoolYear(): UseMutationResult<
  UpdateSchoolYearHookResult,
  ApplicationException,
  UpdateSchoolYearHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError, page }: UpdateSchoolYearHookParams) => {
      try {
        const newStatus = i18n().modules.hub.schoolYear.pages.list.newStatus(model.status)
        const updateSchoolYear = await updateSchoolYearService(model)
        onSuccess?.(updateSchoolYear)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.schoolYear.pages.list.alert.update
        })
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE,
              page
            }
          ],
          (oldData: ListSchoolYearHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.map((schoolYear) => {
                if (isSchool) {
                  if (String(model.idSchoolYear) === String(schoolYear.id)) {
                    return {
                      ...schoolYear,
                      title: model.title,
                      startDate: formatDatePtBr(model.startDate),
                      endDate: formatDatePtBr(model.endDate),
                      statusFormatted: newStatus,
                      status: model.status
                    }
                  } else {
                    return {
                      ...schoolYear,
                      statusFormatted: i18n().modules.hub.schoolYear.pages.list.inactive,
                      status: model.status === 'active' ? 'inactive' : schoolYear.status
                    }
                  }
                } else {
                  if (model.idSchoolYear === String(schoolYear.id)) {
                    return {
                      ...schoolYear,
                      title: model.title,
                      startDate: formatDatePtBr(model.startDate),
                      endDate: formatDatePtBr(model.endDate),
                      statusFormatted: newStatus,
                      status: model.status
                    }
                  } else {
                    if (
                      model.institutionId === String(schoolYear.institutionId) &&
                      String(schoolYear.id) !== model.idSchoolYear
                    ) {
                      return {
                        ...schoolYear,
                        title: model.title,
                        startDate: formatDatePtBr(model.startDate),
                        endDate: formatDatePtBr(model.endDate),
                        statusFormatted: i18n().modules.hub.schoolYear.pages.list.inactive,
                        status: 'inactive'
                      }
                    }
                  }
                  return {
                    ...schoolYear
                  }
                }
              })
            }
        )
        return updateSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function usePromoteSchoolYear(): UseMutationResult<
  void,
  ApplicationException,
  PromoteSchoolYearHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'promote'],
    mutationFn: async ({ model, onSuccess, onError }: PromoteSchoolYearHookParams) => {
      try {
        await promoteSchoolYearService(model)
        onSuccess?.(null)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.schoolYear.pages.list.alert.promote
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowSchoolYear({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowSchoolYearHookParams): UseQueryResult<ShowSchoolYearHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showSchoolYear = await showSchoolYearService(model)
        onSuccess?.(showSchoolYear)
        return showSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
