export const monitooraPt = {
  resources: {
    showMyCredential: 'Visualizar minha credencial',
    createCredential: 'Criar credencial',
    listDevices: 'Listar dispositivos',
    listDevicesDetails: 'Listar detalhes do  dispositivos',
    embeddedGeneralInformation: 'Carregar embedded Informações Gerais',
    embeddedDevices: 'Carregar embedded dispositivos',
    embeddedDeviceDetail: 'Carregar embedded detalhes do  dispositivos'
  },
  pages: {
    dashboard: {
      pageTitle: 'Dashboard',
      appBar: {
        title: 'Dashboard'
      },
      emptyList: {
        title: 'Ops! O dashboard ainda não foi configurado pela escola.',
        subTitle: 'Entre em contato com um administrador da sua instituição de ensino.'
      },
      searchContent: {
        results: 'Resultados da busca...',
        searching: 'Buscando por resultados...',
        emptyList: 'Não há resultados para esta busca!'
      },
      generalInformation: {
        title: 'Informações Gerais',
        allUnits: 'Todas as unidades',
        organizationalUnits: 'Unidades Organizacionais',
        initialDate: 'Data Inicial',
        finalDate: 'Data Final',
        buttons: {
          filter: 'Filtrar'
        }
      },
      devices: {
        title: 'Devices',
        selectPeriod: {
          lastSevenDays: 'Últimos 7 dias',
          lastThirtyDays: 'Últimos 30 dias',
          lastThreeMonths: 'Últimos 3 meses',
          lastSixMonths: 'Últimos 6 meses',
          lastTwelveMonths: 'Últimos 12 meses'
        },
        inputs: {
          searchDevice: 'Busque por dispositivos',
          organization: 'Unidades Organizacionais',
          situation: 'Situação',
          initialDate: 'Data Inicial',
          finalDate: 'Data Final',
          all: 'Todos',
          provisioned: 'Provisionados',
          deprovisioned: 'Deprovisioned',
          supportingText: 'Insira o número de série ou nome de usuário'
        },
        buttons: {
          searchDevice: 'Buscar dispositivo',
          filter: 'Filtrar'
        },
        table: {
          registeredDevices: 'Dispositivos Registrados',
          model: 'Modelo',
          serialNumber: 'Serial Number',
          status: 'Status',
          changedIn: 'Alterado em',
          users: 'Usuários',
          School: 'Escola',
          lastAcess: 'Último acesso'
        }
      },
      setup: {
        title: 'Auto setup',
        subTitle:
          'É necessário preencher as informações abaixo para habilitar a visualização do Dashboard da sua instituição.',
        helpText: 'Onde encontro as informações?',
        consoleAdmin: 'Admin Console',
        inputs: {
          clientId: 'Insira o client_id',
          secret: 'Insira o secret',
          customerId: 'Insira o customer_id'
        },
        buttons: {
          sendData: 'Enviar dados',
          accessTutorial: 'Acessar tutorial'
        },
        alert: {
          failSetup: 'Falha ao configurar o aplicativo'
        }
      }
    },
    deviceDetails: {
      appBar: {
        title: 'Dashboard'
      },
      breadcrumbs: {
        devices: 'Dispositivos',
        infoDevices: (device: string): string => `Informações do dispositivo ${device}`
      },
      inputs: {
        period: 'Período',
        initialDate: 'Data Inicial',
        finalDate: 'Data Final'
      },
      buttons: {
        filter: 'Filtrar'
      }
    }
  }
}
