import { Div } from '@positivote/design-system/components/Div'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Typography } from '@positivote/design-system/components/Typography'
import { LogoutIcon } from '@positivote/design-system/icons/Logout'
import { PersonIcon } from '@positivote/design-system/icons/Person'

import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

export function SupportLoginBar(): JSX.Element {
  const { profile, isLoginSupport, isLoading, logoutSupport } = useAuth()

  return !isLoginSupport || isLoading || !profile ? (
    <></>
  ) : (
    <Div
      className="SupportLoginBar-Container"
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '$4xs $2xs $4xs $lg',
        gap: '$2xs',
        backgroundColor: '$primary-container'
      }}
    >
      <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
        <PersonIcon fill="$on-primary-container" />
        <Typography
          variant="bodySmall"
          color="$on-primary-container"
          data-testid="SupportLoginBar-Typography"
        >
          {i18n().common.components.supportLoginBar.currentUser(profile.name, profile.role.name)}
        </Typography>
      </Div>
      <LinkButton
        color="$on-primary-container"
        fill="$on-primary-container"
        LeadingIcon={<LogoutIcon size={18} />}
        data-testid="logoutSupport"
        onClick={() => logoutSupport()}
      >
        {i18n().common.components.supportLoginBar.exitSupport}
      </LinkButton>
    </Div>
  )
}
