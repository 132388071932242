import { Navigate, Route, Routes } from 'react-router-dom'

import { SalesOrderList } from './pages/List'

export function SalesOrderRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<SalesOrderList />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
