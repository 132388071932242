export const dataAnalysisPt = {
  pages: {
    nedu: {
      pageTitle: 'Análise de dados',
      appBar: {
        title: 'Análise de dados'
      }
    }
  }
}
