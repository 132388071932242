import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import { OnboardingInRegisterList } from './InRegister'
import { OnboardingPreRegisterList } from './PreRegister'

const tabs = [
  {
    label: i18n().modules.hub.onboarding.pages.list.tabs.inRegister.title,
    key: 'inRegister',
    children: <OnboardingInRegisterList />
  },
  {
    label: i18n().modules.hub.onboarding.pages.list.tabs.preRegister.title,
    key: 'preRegister',
    children: <OnboardingPreRegisterList />
  }
]

export function OnboardingList(): JSX.Element {
  changePageTitle(i18n().modules.hub.onboarding.pages.list.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <>
          <AppBar
            title={i18n().modules.hub.onboarding.pages.list.appBar.title}
            goBackFunction={() => navigate(-1)}
            breadcrumbItems={[
              {
                label: i18n().modules.hub.onboarding.pages.list.appBar.breadcrumbs.overview,
                onClick: () => navigate(-1)
              },
              {
                label:
                  i18n().modules.hub.onboarding.pages.list.appBar.breadcrumbs.registrationTracking
              }
            ]}
          />
          <Tabs options={tabs} />
        </>
      )}
    </Main>
  )
}
