import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListAcademicSyncApiParams,
  ListAcademicSyncApiResult,
  ShowAcademicSyncApiResult
} from './contracts'
import {
  ImportAcademicSyncServiceParams,
  ListAcademicSyncServiceParams,
  ListAcademicSyncServiceResult,
  ShowAcademicSyncServiceParams,
  ShowAcademicSyncServiceResult
} from './contracts/services'
import { academicSyncFormatSanitizer, showAcademicSyncFormatSanitizer } from './sanitizers'

export async function listAcademicSyncService({
  institutionId,
  page,
  perPage,
  termId
}: ListAcademicSyncServiceParams): Promise<ListAcademicSyncServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListAcademicSyncApiParams, ListAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.listAcademicSync,
    method: 'get',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import`,
    params: {
      limit: perPage,
      offset: page,
      term_id: termId
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: academicSyncFormatSanitizer(data)
    })
  }
}

export async function showAcademicSyncService({
  institutionId,
  importId
}: ShowAcademicSyncServiceParams): Promise<ShowAcademicSyncServiceResult> {
  const { data } = await hubApiService<null, ShowAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.listAcademicSync,
    method: 'get',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import/${importId}`
  })

  return showAcademicSyncFormatSanitizer(data)
}

export async function importAcademicSyncService({
  file,
  institutionId
}: ImportAcademicSyncServiceParams): Promise<void> {
  await hubApiService<FormData, ShowAcademicSyncApiResult>({
    resource: i18n().modules.hub.academicSync.resources.listAcademicSync,
    method: 'post',
    url: `/academic-sync/v1/processor/sheet/institution/${institutionId}/import`,
    body: file
  })
}
