import { i18n } from '@/common/i18n'
import { listBillingApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  CreateServiceMappingApiParams,
  CreateServiceMappingApiResult,
  CreateServiceMappingServiceParams,
  CreateServiceMappingServiceResult,
  ListServiceMappingApiParams,
  ListServiceMappingApiResult,
  ListServiceMappingServiceParams,
  ListServiceMappingServiceResult,
  RemoveServiceMappingServiceParams,
  ShowServiceMappingApiResult,
  ShowServiceMappingServiceParams,
  ShowServiceMappingServiceResult,
  UpdateServiceMappingApiParams,
  UpdateServiceMappingApiResult,
  UpdateServiceMappingServiceParams,
  UpdateServiceMappingServiceResult,
  VerifyServiceMappingApiParams,
  VerifyServiceMappingApiResult,
  VerifyServiceMappingServiceParams,
  VerifyServiceMappingServiceResult
} from './contracts'
import { fromServiceMappingApiSanitizer, toServiceMappingApiSanitizer } from './sanitizers'

export async function listServiceMappingService(
  params: ListServiceMappingServiceParams
): Promise<ListServiceMappingServiceResult> {
  const { data, headers } = await hubApiService<
    ListServiceMappingApiParams,
    ListServiceMappingApiResult
  >({
    resource: i18n().modules.billing.serviceMapping.resources.list,
    method: 'get',
    url: '/billing/v1/service-mapping',
    params: {
      search_by: params.searchBy,
      limit: params.perPage,
      offset: params.page
    }
  })

  return listBillingApiResultSanitizer({
    headers,
    data: fromServiceMappingApiSanitizer(data.service_mapping)
  })
}

export async function createServiceMappingService(
  params: CreateServiceMappingServiceParams
): Promise<CreateServiceMappingServiceResult> {
  const { data } = await hubApiService<
    CreateServiceMappingApiParams,
    CreateServiceMappingApiResult
  >({
    resource: i18n().modules.billing.serviceMapping.resources.create,
    method: 'post',
    url: '/billing/v1/service-mapping',
    body: toServiceMappingApiSanitizer(params)
  })

  return fromServiceMappingApiSanitizer([data.service_mapping])[0]
}

export async function verifyServiceMappingService(
  params: VerifyServiceMappingServiceParams
): Promise<VerifyServiceMappingServiceResult> {
  const { data } = await hubApiService<
    VerifyServiceMappingApiParams,
    VerifyServiceMappingApiResult
  >({
    resource: i18n().modules.billing.serviceMapping.resources.verify,
    method: 'get',
    url: '/billing/v1/service-mapping/check',
    params: {
      id: params.id,
      app_id: params.appId,
      sap_id: params.sapId
    }
  })

  return data
}

export async function showServiceMappingService(
  params: ShowServiceMappingServiceParams
): Promise<ShowServiceMappingServiceResult> {
  const { data } = await hubApiService<null, ShowServiceMappingApiResult>({
    resource: i18n().modules.billing.serviceMapping.resources.show,
    method: 'get',
    url: `/billing/v1/service-mapping/${params.id}`
  })

  return fromServiceMappingApiSanitizer([data.service_mapping])[0]
}

export async function updateServiceMappingService(
  params: UpdateServiceMappingServiceParams
): Promise<UpdateServiceMappingServiceResult> {
  const { data } = await hubApiService<
    UpdateServiceMappingApiParams,
    UpdateServiceMappingApiResult
  >({
    resource: i18n().modules.billing.serviceMapping.resources.update,
    method: 'put',
    url: `/billing/v1/service-mapping/${params.id}`,
    body: toServiceMappingApiSanitizer(params)
  })

  return fromServiceMappingApiSanitizer([data.service_mapping])[0]
}

export async function removeServiceMappingService(
  params: RemoveServiceMappingServiceParams
): Promise<void> {
  await hubApiService({
    resource: i18n().modules.billing.serviceMapping.resources.remove,
    method: 'delete',
    url: `/billing/v1/service-mapping/${params.id}`
  })
}
