import { i18n } from '@/common/i18n'

export const MILLISECONDS_IN_A_SECOND = 1000

export const SECONDS_IN_A_MINUTE = 60

export const MONTH_OPTIONS: Array<{ key: string; value: string }> = [
  { key: '01', value: i18n().common.months.january },
  { key: '02', value: i18n().common.months.february },
  { key: '03', value: i18n().common.months.march },
  { key: '04', value: i18n().common.months.april },
  { key: '05', value: i18n().common.months.may },
  { key: '06', value: i18n().common.months.june },
  { key: '07', value: i18n().common.months.july },
  { key: '08', value: i18n().common.months.august },
  { key: '09', value: i18n().common.months.september },
  { key: '10', value: i18n().common.months.october },
  { key: '11', value: i18n().common.months.november },
  { key: '12', value: i18n().common.months.december }
]
