import { useAlert } from '@positivote/design-system/hooks'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import { CreateBulkEnrollmentHookParams } from './contracts'
import { createBulkEnrollmentService, removeBulkEnrollmentService } from './services'

export const hookKey = 'enrollments'

export function useCreateBulkEnrollment(): UseMutationResult<
  void,
  ApplicationException,
  CreateBulkEnrollmentHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'createBulkEnrollment'],
    mutationFn: async ({ model, onSuccess, onError }: CreateBulkEnrollmentHookParams) => {
      try {
        await createBulkEnrollmentService(model)
        void queryClient.invalidateQueries()
        onSuccess?.(null)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.classroom.pages.enrollments.form.alertMessage.bulkEnrollments
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useRemoveBulkEnrollment(): UseMutationResult<
  void,
  ApplicationException,
  CreateBulkEnrollmentHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'removeBulkEnrollment'],
    mutationFn: async ({ model, onSuccess, onError }: CreateBulkEnrollmentHookParams) => {
      try {
        await removeBulkEnrollmentService(model)
        void queryClient.invalidateQueries()
        onSuccess?.(null)
        addAlertMessage({
          severity: 'success',
          subTitle:
            i18n().modules.hub.classroom.pages.enrollments.form.alertMessage.removeBulkEnrollments
        })
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
