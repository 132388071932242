import { Button } from '@positivote/design-system/components/Button'
import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { NavigateNextIcon } from '@positivote/design-system/icons/NavigateNext'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { StatusCodes } from '@/common/contracts'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useShowMyCredential } from '@/modules/monitoora/hooks'

import { Devices } from './Devices'
import { GeneralInformation } from './GeneralInformation'
import { Setup } from './Setup'

export function Dashboard(): JSX.Element {
  changePageTitle(i18n().modules.monitoora.pages.dashboard.pageTitle)

  const navigate = useNavigate()
  const { permissions } = useAuth()
  const [setup, setSetup] = useState(false)
  const { data: credential, isLoading: credentialIsLoading } = useShowMyCredential({
    queryOptions: {
      enabled: !!permissions
    },
    onSuccess: () => {
      const permissionFound = permissions?.find((permission) => permission.key === 'setup-dash-ceu')
      permissionFound && setSetup(true)
    },
    onError: (error) => {
      if (error.error.statusCode === StatusCodes.NOT_FOUND) {
        const permissionFound = permissions?.find(
          (permission) => permission.key === 'setup-dash-ceu'
        )
        permissionFound && setSetup(true)
      }
    }
  })

  const monitooraTabs = [
    {
      label: i18n().modules.monitoora.pages.dashboard.generalInformation.title,
      key: 'generalInformation',
      children: <GeneralInformation />
    },
    {
      label: i18n().modules.monitoora.pages.dashboard.devices.title,
      key: 'Devices',
      children: <Devices />
    }
  ]
  return (
    <Main
      className="dashboard"
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <AppBar
        title={i18n().modules.monitoora.pages.dashboard.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={
          <Button
            variant="tonal"
            LeadingIcon={<NavigateNextIcon size={18} />}
            onClick={() => window.open(import.meta.env.VITE_MONITOORA_GOOGLE_ADMIN_URL, '_blank')}
          >
            {i18n().modules.monitoora.pages.dashboard.setup.consoleAdmin}
          </Button>
        }
      />
      {credentialIsLoading || !permissions ? (
        <WrappedLoader />
      ) : (
        <>
          {credential?.active && <Tabs options={monitooraTabs} />}

          {!credential?.active && setup && <Setup />}

          {!credential?.active && !setup && (
            <EmptyList
              title={i18n().modules.monitoora.pages.dashboard.emptyList.title}
              subTitle={i18n().modules.monitoora.pages.dashboard.emptyList.subTitle}
            />
          )}
        </>
      )}
    </Main>
  )
}
