export const settingsPt = {
  pages: {
    list: {
      pageTitle: 'Configurações',
      appBar: {
        title: 'Configurações'
      },
      tabs: {
        myData: 'Meus dados',
        personalization: 'Personalização',
        accessAndSecurity: 'Acesso e segurança',
        myAccounts: 'Minhas contas'
      }
    }
  }
}
