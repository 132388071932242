import { Grid } from '@positivote/design-system/components/Grid'
import { Typography } from '@positivote/design-system/components/Typography'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { changePageTitle, safetyJSONParse } from '@/common/helpers'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { apiService } from '@/common/services'

export function MockEdtechResponse(): JSX.Element {
  changePageTitle('Mock Edtech Response')

  const [searchParams, setSearchParams] = useSearchParams()
  const [userName, setUserName] = useState('Carregando...')

  useEffect(() => {
    let authorization = searchParams.get('access_token') ?? searchParams.get('id_token')

    function callUserInfo(): void {
      apiService<null, { profile: { name: string } }>({
        resource: 'Buscar informações do usuário',
        method: 'get',
        url: '/users/v1/users/info',
        baseURL: import.meta.env.VITE_HUB_API_URL,
        headers: { authorization, 'X-Relationship-Id': searchParams.get('user_id') }
      })
        .then((response) => {
          setUserName(response.profile.name)
        })
        .catch(() => {
          setUserName('Erro ao buscar informações do usuário')
        })
    }

    if (authorization) {
      callUserInfo()
    } else {
      const searchParamsStateParsed = safetyJSONParse<{
        client_id: string
        client_secret: string
        redirect_uri: string
      }>(searchParams.get('state'))
      apiService<
        {
          grant_type: string
          client_id: string
          client_secret: string
          code: string
        },
        { access_token: string; id_token: string }
      >({
        resource: 'Gerar token',
        method: 'post',
        url: '/lti/v1/oauth2/authorize',
        baseURL: import.meta.env.VITE_HUB_API_URL,
        body: {
          grant_type: 'authorization_code',
          client_id: searchParamsStateParsed?.client_id ?? '',
          client_secret: searchParamsStateParsed?.client_secret ?? '',
          code: searchParams.get('code') ?? ''
        }
      })
        .then((response) => {
          authorization = response.access_token
          setSearchParams((oldSearchParams) => ({
            ...Object.fromEntries(oldSearchParams.entries()),
            access_token: response.access_token,
            id_token: response.id_token
          }))
          callUserInfo()
        })
        .catch(() => {
          setUserName('Erro ao gerar token')
        })
    }
    // DOCS: this useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseBackground>
      <Grid
        xl={5}
        lg={6}
        md={7}
        sm={9}
        xs={12}
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}
      >
        <Typography
          variant="headlineMedium"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          Dados do callback
        </Typography>
        <Typography
          variant="titleLarge"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          user_id: {searchParams.get('user_id') ?? 'Não informado'}
        </Typography>
        <Typography
          variant="titleLarge"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          state: {searchParams.get('state') ?? 'Não informado'}
        </Typography>
        <Typography
          variant="titleLarge"
          lineClamp={1}
          css={{
            wordBreak: 'break-all',
            color: '$on-surface',
            textAlign: 'center',
            marginBottom: '$sm'
          }}
        >
          access_token: {searchParams.get('access_token') ?? 'Não informado'}
        </Typography>
        <Typography
          variant="titleLarge"
          lineClamp={1}
          css={{
            wordBreak: 'break-all',
            color: '$on-surface',
            textAlign: 'center',
            marginBottom: '$sm'
          }}
        >
          id_token: {searchParams.get('id_token') ?? 'Não informado'}
        </Typography>
        <Typography
          variant="titleLarge"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          code: {searchParams.get('code') ?? 'Não informado'}
        </Typography>
        <Typography
          variant="titleLarge"
          css={{ color: '$on-surface', textAlign: 'center', marginBottom: '$sm' }}
        >
          user_name: {userName}
        </Typography>
      </Grid>
    </BaseBackground>
  )
}
