export function MascotNotValidated(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="237"
      viewBox="0 0 142 237"
      fill="none"
    >
      <path
        d="M74.8844 15.2614C74.4256 15.2614 74.0615 15.1018 73.7922 14.7826C73.5229 14.4634 73.4181 14.0744 73.478 13.6155C73.5777 12.8973 73.7922 12.259 74.1213 11.7004C74.4505 11.1418 74.9542 10.5633 75.6325 9.96477C76.4704 9.20669 77.1138 8.50347 77.5626 7.85511C78.0115 7.20675 78.2359 6.51351 78.2359 5.77538C78.2359 4.87765 77.9367 4.12955 77.3382 3.53106C76.7397 2.93258 75.9018 2.63333 74.8246 2.63333C74.0864 2.63333 73.473 2.78295 72.9842 3.0822C72.4955 3.38144 72.1014 3.7505 71.8022 4.18939C71.5828 4.50859 71.3035 4.72803 70.9643 4.84773C70.6252 4.96742 70.296 4.95745 69.9768 4.8178C69.5778 4.65821 69.3335 4.36894 69.2437 3.95C69.1539 3.53106 69.2187 3.15202 69.4382 2.81288C69.9968 1.99495 70.7299 1.32165 71.6376 0.792992C72.5453 0.264331 73.6076 0 74.8246 0C76.8195 0 78.3556 0.553598 79.4329 1.6608C80.5102 2.76799 81.0488 4.09962 81.0488 5.65568C81.0488 6.61326 80.8443 7.48106 80.4354 8.25909C80.0264 9.03712 79.373 9.85505 78.4753 10.7129C77.7771 11.3912 77.2883 11.9398 77.009 12.3587C76.7297 12.7777 76.5502 13.2564 76.4704 13.7951C76.4105 14.214 76.231 14.5631 75.9318 14.8424C75.6325 15.1217 75.2834 15.2614 74.8844 15.2614ZM74.8246 22.7424C74.246 22.7424 73.7523 22.5379 73.3433 22.129C72.9343 21.72 72.7299 21.2263 72.7299 20.6477C72.7299 20.0692 72.9343 19.5754 73.3433 19.1665C73.7523 18.7575 74.246 18.553 74.8246 18.553C75.4031 18.553 75.8968 18.7575 76.3058 19.1665C76.7148 19.5754 76.9193 20.0692 76.9193 20.6477C76.9193 21.2263 76.7148 21.72 76.3058 22.129C75.8968 22.5379 75.4031 22.7424 74.8246 22.7424Z"
        fill="#B5C0D1"
      />
      <path
        d="M5.71279 43.1736C5.33024 43.1736 5.02669 43.0413 4.80215 42.7767C4.57761 42.5121 4.49028 42.1896 4.54018 41.8093C4.62335 41.2139 4.80215 40.6847 5.07659 40.2217C5.35103 39.7587 5.771 39.2791 6.33651 38.783C7.03509 38.1546 7.57149 37.5716 7.94573 37.0342C8.31997 36.4967 8.50708 35.9221 8.50708 35.3102C8.50708 34.566 8.25759 33.9459 7.75861 33.4498C7.25963 32.9537 6.56106 32.7056 5.66289 32.7056C5.04748 32.7056 4.53602 32.8296 4.12852 33.0777C3.72102 33.3257 3.39253 33.6317 3.14303 33.9955C2.96007 34.2601 2.72722 34.442 2.44446 34.5412C2.1617 34.6404 1.88727 34.6322 1.62114 34.5164C1.28849 34.3841 1.08474 34.1443 1.00989 33.797C0.935042 33.4498 0.989099 33.1356 1.17206 32.8544C1.63777 32.1764 2.24903 31.6183 3.00581 31.1801C3.7626 30.7418 4.6483 30.5227 5.66289 30.5227C7.32616 30.5227 8.60688 30.9816 9.50505 31.8994C10.4032 32.8172 10.8523 33.9211 10.8523 35.211C10.8523 36.0047 10.6818 36.7241 10.3408 37.3691C9.99987 38.014 9.45515 38.692 8.70668 39.4031C8.12453 39.9654 7.71703 40.4201 7.48417 40.7674C7.25131 41.1147 7.10162 41.5116 7.03509 41.9581C6.98519 42.3054 6.8355 42.5948 6.58601 42.8263C6.33651 43.0578 6.04544 43.1736 5.71279 43.1736ZM5.66289 49.375C5.18054 49.375 4.76888 49.2055 4.42791 48.8665C4.08694 48.5275 3.91646 48.1182 3.91646 47.6386C3.91646 47.159 4.08694 46.7497 4.42791 46.4107C4.76888 46.0717 5.18054 45.9022 5.66289 45.9022C6.14524 45.9022 6.5569 46.0717 6.89787 46.4107C7.23884 46.7497 7.40933 47.159 7.40933 47.6386C7.40933 48.1182 7.23884 48.5275 6.89787 48.8665C6.5569 49.2055 6.14524 49.375 5.66289 49.375Z"
        fill="#B5C0D1"
      />
      <path
        d="M136.818 48.8435C136.505 48.8435 136.256 48.7364 136.073 48.5222C135.889 48.308 135.818 48.0469 135.858 47.739C135.926 47.2571 136.073 46.8287 136.297 46.4539C136.522 46.079 136.865 45.6908 137.328 45.2892C137.9 44.7805 138.338 44.3086 138.645 43.8735C138.951 43.4384 139.104 42.9732 139.104 42.4779C139.104 41.8755 138.9 41.3734 138.492 40.9718C138.083 40.5702 137.512 40.3694 136.777 40.3694C136.273 40.3694 135.855 40.4698 135.522 40.6706C135.188 40.8714 134.919 41.1191 134.715 41.4136C134.566 41.6278 134.375 41.7751 134.144 41.8554C133.912 41.9357 133.688 41.929 133.47 41.8353C133.198 41.7282 133.031 41.5341 132.97 41.253C132.909 40.9718 132.953 40.7175 133.103 40.4899C133.484 39.941 133.984 39.4892 134.603 39.1344C135.222 38.7797 135.947 38.6023 136.777 38.6023C138.138 38.6023 139.186 38.9738 139.92 39.7168C140.655 40.4598 141.023 41.3534 141.023 42.3976C141.023 43.0401 140.883 43.6225 140.604 44.1446C140.325 44.6667 139.88 45.2156 139.267 45.7912C138.791 46.2464 138.458 46.6145 138.267 46.8956C138.077 47.1768 137.954 47.4981 137.9 47.8595C137.859 48.1406 137.736 48.3749 137.532 48.5623C137.328 48.7498 137.09 48.8435 136.818 48.8435ZM136.777 53.8637C136.382 53.8637 136.045 53.7264 135.766 53.452C135.487 53.1776 135.348 52.8462 135.348 52.458C135.348 52.0698 135.487 51.7384 135.766 51.464C136.045 51.1896 136.382 51.0524 136.777 51.0524C137.172 51.0524 137.508 51.1896 137.787 51.464C138.066 51.7384 138.206 52.0698 138.206 52.458C138.206 52.8462 138.066 53.1776 137.787 53.452C137.508 53.7264 137.172 53.8637 136.777 53.8637Z"
        fill="#B5C0D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.5 133C127.433 133 129 131.433 129 129.5C129 125.387 128.599 122.196 127.426 119.783C126.102 117.059 124.02 115.754 121.863 114.971C120.615 114.518 119.436 114.22 118.245 113.919C115.451 113.212 112.589 112.488 108.605 109.71C107.02 108.604 104.838 108.993 103.732 110.578C102.626 112.163 103.015 114.345 104.601 115.451C109.752 119.044 114.55 120.254 117.465 120.99C118.302 121.201 118.984 121.373 119.474 121.551C120.503 121.924 120.837 122.239 121.131 122.844C121.578 123.763 122 125.613 122 129.5C122 131.433 123.567 133 125.5 133Z"
        fill="#B5C0D1"
      />
      <ellipse opacity="0.5" cx="71.8981" cy="220.392" rx="44.8864" ry="9.42614" fill="#A2AFC4" />
      <path
        opacity="0.5"
        d="M107.807 206.926C107.807 205.687 108.778 204.682 109.976 204.682H120.9C122.098 204.682 123.068 205.687 123.068 206.926C123.068 208.166 122.098 209.171 120.9 209.171H109.976C108.778 209.171 107.807 208.166 107.807 206.926Z"
        fill="#A2AFC4"
      />
      <path
        opacity="0.5"
        d="M31.5004 234.756C31.5004 233.516 32.4713 232.511 33.6691 232.511H44.593C45.7908 232.511 46.7617 233.516 46.7617 234.756C46.7617 235.995 45.7908 237 44.593 237H33.6691C32.4713 237 31.5004 235.995 31.5004 234.756Z"
        fill="#A2AFC4"
      />
      <path
        d="M17.3852 145.843L18.4715 155.643C19.9015 168.542 28.5921 179.438 40.7719 183.599L43.3653 184.485C58.4392 189.635 74.6793 190.215 90.0824 186.155L92.7324 185.456C105.178 182.176 114.625 171.928 116.972 159.164L118.755 149.467C122.292 130.213 123.275 110.571 121.679 91.0594L119.652 66.2967C118.604 53.4995 110.392 42.4392 98.5182 37.8351C81.5645 31.2628 62.9172 30.5962 45.5375 35.9412C33.3649 39.6856 24.3847 50.1292 22.4257 62.8189L18.6351 87.3737C15.65 106.723 15.2286 126.383 17.3832 145.841L17.3852 145.843Z"
        fill="#DEE4ED"
      />
      <path
        d="M116.28 71.3321L115.959 68.8909C114.849 60.4542 109.406 53.8609 102.54 52.6366L98.7243 51.9569C92.4195 50.8331 86.0209 50.8595 79.7359 52.0369L73.0454 53.2897C66.0983 54.5899 60.716 61.4006 59.8477 69.9916L59.6264 72.1796C58.9148 79.2095 59.0729 86.3394 60.0954 93.3256C61.2863 101.466 66.5183 107.821 73.1417 109.175L78.0057 110.17C86.0306 111.811 94.2236 111.577 102.159 109.48L104.181 108.947C110.607 107.25 115.478 100.816 116.408 92.7981L116.445 92.4827C117.259 85.469 117.203 78.3396 116.282 71.3321L116.28 71.3321Z"
        fill="#5D6675"
      />
      <circle
        cx="3.01107"
        cy="3.01107"
        r="3.01107"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 110.611 77.1072)"
        fill="white"
      />
      <circle
        cx="3.4113"
        cy="3.4113"
        r="3.4113"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 87.793 75.6724)"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.265 86.8716C109.55 87.2318 109.489 87.7548 109.129 88.0398L105.439 90.9585C105.079 91.2435 104.556 91.1825 104.271 90.8223C103.986 90.4621 104.047 89.939 104.407 89.6541L108.097 86.7353C108.457 86.4504 108.98 86.5114 109.265 86.8716Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.396 87.3525C113.681 87.7127 113.62 88.2358 113.259 88.5207L109.57 91.4395C109.21 91.7244 108.687 91.6634 108.402 91.3032C108.117 90.943 108.178 90.42 108.538 90.135L112.227 87.2163C112.588 86.9313 113.111 86.9923 113.396 87.3525Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1174 87.3254C90.4024 87.6856 90.3414 88.2087 89.9811 88.4936L86.2917 91.4124C85.9315 91.6973 85.4085 91.6363 85.1235 91.2761C84.8386 90.9159 84.8996 90.3929 85.2598 90.1079L88.9492 87.1892C89.3094 86.9042 89.8324 86.9652 90.1174 87.3254Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1622 86.7471C85.4471 87.1073 85.3862 87.6303 85.0259 87.9152L81.3365 90.834C80.9763 91.119 80.4533 91.058 80.1683 90.6977C79.8834 90.3375 79.9444 89.8145 80.3046 89.5296L83.994 86.6108C84.3542 86.3259 84.8772 86.3869 85.1622 86.7471Z"
        fill="white"
      />
      <rect
        width="20.547"
        height="24.2662"
        rx="10.2735"
        transform="matrix(-0.99937 -0.0354915 -0.0354915 0.99937 55.8076 102.819)"
        fill="#CAD3E0"
      />
      <circle
        cx="4.40293"
        cy="4.40293"
        r="4.40293"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 48.9851 110.055)"
        fill="#818FA5"
      />
      <circle
        cx="5.13676"
        cy="5.13676"
        r="5.13676"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 82.3937 119.701)"
        fill="#B971E0"
      />
      <circle
        cx="1.46764"
        cy="1.46764"
        r="1.46764"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 78.5125 123.299)"
        fill="white"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.774 123.812)"
        fill="#CAD3E0"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.774 128.215)"
        fill="#CAD3E0"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.774 119.409)"
        fill="#CAD3E0"
      />
      <path
        d="M71.9347 37.6884C56.1439 36.5443 42.1797 44.6054 37.6446 50.1087C37.0612 50.8167 37.7791 51.5484 38.6123 51.1645C55.183 43.5289 89.1804 38.938 71.9347 37.6884Z"
        fill="#FAFAFB"
      />
      <circle
        cx="2.20147"
        cy="2.20147"
        r="2.20147"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 33.1316 53.5417)"
        fill="#FAFAFB"
      />
      <path
        d="M90.9514 177.136C98.9756 178.466 112.951 163.839 113.499 146.43C113.534 145.316 111.996 145.157 111.601 146.199C106.466 159.751 94.303 172.119 90.6038 175.674C90.0883 176.17 90.246 177.019 90.9514 177.136Z"
        fill="#CAD3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.7215 106.549C87.0917 105.51 84.9279 105.988 83.8886 107.618C73.0402 124.63 64.2602 128.109 59.0513 127.402C53.8122 126.691 49.6741 121.381 48.8076 115.027C48.5464 113.112 46.782 111.771 44.8667 112.032C42.9515 112.293 41.6106 114.058 41.8717 115.973C42.9809 124.106 48.5927 133.047 58.1097 134.339C67.6569 135.635 78.6391 128.87 89.7907 111.382C90.83 109.752 90.3513 107.588 88.7215 106.549Z"
        fill="#B5C0D1"
      />
      <ellipse cx="97.0345" cy="91.1193" rx="2.69318" ry="2.24432" fill="white" />
      <rect
        width="14.9416"
        height="10.9043"
        rx="3"
        transform="matrix(-0.849981 -0.526814 -0.526814 0.849981 96.7655 107.219)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.784604 -0.619997 -0.619997 0.784604 93.0311 95.6165)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.859539 -0.51107 -0.51107 0.859539 83.0802 99.1489)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.784604 -0.619997 -0.619997 0.784604 101.538 101.607)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.784604 -0.619997 -0.619997 0.784604 97.6769 98.1504)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.784604 -0.619997 -0.619997 0.784604 98.2045 97.4827)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.784604 -0.619997 -0.619997 0.784604 93.2037 95.2893)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.859539 -0.51107 -0.51107 0.859539 94.0288 107.96)"
        fill="#818FA5"
      />
      <rect
        x="124.902"
        y="142.363"
        width="14.9416"
        height="10.9043"
        rx="3"
        transform="rotate(-123.928 124.902 142.363)"
        fill="#91A0B8"
      />
      <rect
        x="113.168"
        y="139.064"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-130.454 113.168 139.064)"
        fill="#91A0B8"
      />
      <rect
        x="116.327"
        y="128.988"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-122.873 116.327 128.988)"
        fill="#91A0B8"
      />
      <rect
        x="119.472"
        y="147.341"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-130.454 119.472 147.341)"
        fill="#91A0B8"
      />
      <rect
        x="115.874"
        y="143.612"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-130.454 115.874 143.612)"
        fill="#91A0B8"
      />
      <rect
        x="115.226"
        y="144.164"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-130.454 115.226 144.164)"
        fill="#91A0B8"
      />
      <rect
        x="112.848"
        y="139.248"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-130.454 112.848 139.248)"
        fill="#91A0B8"
      />
      <rect
        x="125.54"
        y="139.6"
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="rotate(-122.873 125.54 139.6)"
        fill="#818FA5"
      />
    </svg>
  )
}
