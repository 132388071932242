import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'

import { DesktopToolCard } from './Desktop'
import { MobileToolCard } from './Mobile'
import { ToolCardProps } from './styles'

export function ToolCard({ applicationIdLoading, ...restProps }: ToolCardProps): JSX.Element {
  const { breakpoint } = useTheme()

  const cardProps = {
    enableOptions: ['configureAccess'],
    canClick: !applicationIdLoading,
    applicationIdLoading,
    ...restProps
  }

  return breakpoint <= Breakpoint.sm ? (
    <MobileToolCard {...cardProps} />
  ) : (
    <DesktopToolCard {...cardProps} />
  )
}
