export function Mascot(): JSX.Element {
  return (
    <svg
      width="160"
      height="237"
      viewBox="0 0 160 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.4582 15.2614C73.9994 15.2614 73.6353 15.1018 73.366 14.7826C73.0966 14.4634 72.9919 14.0744 73.0518 13.6155C73.1515 12.8973 73.366 12.259 73.6951 11.7004C74.0243 11.1418 74.528 10.5633 75.2063 9.96477C76.0442 9.20669 76.6876 8.50347 77.1364 7.85511C77.5853 7.20675 77.8097 6.51351 77.8097 5.77538C77.8097 4.87765 77.5105 4.12955 76.912 3.53106C76.3135 2.93258 75.4756 2.63333 74.3983 2.63333C73.6602 2.63333 73.0468 2.78295 72.558 3.0822C72.0692 3.38144 71.6752 3.7505 71.376 4.18939C71.1566 4.50859 70.8773 4.72803 70.5381 4.84773C70.199 4.96742 69.8698 4.95745 69.5506 4.8178C69.1516 4.65821 68.9072 4.36894 68.8175 3.95C68.7277 3.53106 68.7925 3.15202 69.012 2.81288C69.5706 1.99495 70.3037 1.32165 71.2114 0.792992C72.1191 0.264331 73.1814 0 74.3983 0C76.3933 0 77.9294 0.553598 79.0067 1.6608C80.084 2.76799 80.6226 4.09962 80.6226 5.65568C80.6226 6.61326 80.4181 7.48106 80.0091 8.25909C79.6002 9.03712 78.9468 9.85505 78.0491 10.7129C77.3509 11.3912 76.8621 11.9398 76.5828 12.3587C76.3035 12.7777 76.124 13.2564 76.0442 13.7951C75.9843 14.214 75.8048 14.5631 75.5055 14.8424C75.2063 15.1217 74.8572 15.2614 74.4582 15.2614ZM74.3983 22.7424C73.8198 22.7424 73.3261 22.5379 72.9171 22.129C72.5081 21.72 72.3037 21.2263 72.3037 20.6477C72.3037 20.0692 72.5081 19.5754 72.9171 19.1665C73.3261 18.7575 73.8198 18.553 74.3983 18.553C74.9769 18.553 75.4706 18.7575 75.8796 19.1665C76.2886 19.5754 76.493 20.0692 76.493 20.6477C76.493 21.2263 76.2886 21.72 75.8796 22.129C75.4706 22.5379 74.9769 22.7424 74.3983 22.7424Z"
        fill="#B5C0D1"
      />
      <path
        d="M5.28658 43.1736C4.90403 43.1736 4.60048 43.0413 4.37594 42.7767C4.1514 42.5121 4.06408 42.1896 4.11397 41.8093C4.19714 41.214 4.37594 40.6848 4.65038 40.2217C4.92482 39.7587 5.34479 39.2791 5.91031 38.783C6.60888 38.1546 7.14529 37.5717 7.51952 37.0342C7.89376 36.4968 8.08087 35.9221 8.08087 35.3102C8.08087 34.5661 7.83138 33.9459 7.3324 33.4498C6.83342 32.9537 6.13485 32.7056 5.23668 32.7056C4.62127 32.7056 4.10982 32.8297 3.70231 33.0777C3.29481 33.3258 2.96632 33.6317 2.71683 33.9955C2.53387 34.2601 2.30101 34.442 2.01825 34.5412C1.7355 34.6405 1.46106 34.6322 1.19493 34.5164C0.862279 34.3841 0.658528 34.1444 0.583681 33.7971C0.508834 33.4498 0.56289 33.1356 0.74585 32.8545C1.21157 32.1764 1.82282 31.6183 2.57961 31.1801C3.33639 30.7419 4.22209 30.5227 5.23668 30.5227C6.89995 30.5227 8.18067 30.9816 9.07884 31.8994C9.977 32.8173 10.4261 33.9211 10.4261 35.211C10.4261 36.0048 10.2556 36.7241 9.91463 37.3691C9.57366 38.014 9.02894 38.6921 8.28047 39.4031C7.69832 39.9654 7.29082 40.4202 7.05796 40.7675C6.82511 41.1147 6.67541 41.5116 6.60888 41.9581C6.55898 42.3054 6.40929 42.5948 6.1598 42.8263C5.91031 43.0578 5.61923 43.1736 5.28658 43.1736ZM5.23668 49.375C4.75433 49.375 4.34267 49.2055 4.0017 48.8665C3.66073 48.5275 3.49025 48.1182 3.49025 47.6386C3.49025 47.159 3.66073 46.7497 4.0017 46.4107C4.34267 46.0717 4.75433 45.9022 5.23668 45.9022C5.71903 45.9022 6.13069 46.0717 6.47166 46.4107C6.81263 46.7497 6.98312 47.159 6.98312 47.6386C6.98312 48.1182 6.81263 48.5275 6.47166 48.8665C6.13069 49.2055 5.71903 49.375 5.23668 49.375Z"
        fill="#B5C0D1"
      />
      <path
        d="M136.391 48.8434C136.078 48.8434 135.83 48.7363 135.646 48.5222C135.463 48.308 135.391 48.0469 135.432 47.739C135.5 47.2571 135.646 46.8287 135.871 46.4538C136.095 46.079 136.439 45.6908 136.902 45.2892C137.473 44.7804 137.912 44.3085 138.218 43.8735C138.525 43.4384 138.678 42.9732 138.678 42.4778C138.678 41.8754 138.474 41.3734 138.065 40.9718C137.657 40.5702 137.086 40.3694 136.351 40.3694C135.847 40.3694 135.429 40.4698 135.095 40.6706C134.762 40.8714 134.493 41.1191 134.289 41.4136C134.139 41.6278 133.949 41.775 133.717 41.8553C133.486 41.9357 133.261 41.929 133.044 41.8353C132.772 41.7282 132.605 41.5341 132.544 41.2529C132.482 40.9718 132.527 40.7174 132.676 40.4899C133.057 39.941 133.557 39.4892 134.177 39.1344C134.796 38.7796 135.521 38.6023 136.351 38.6023C137.711 38.6023 138.759 38.9738 139.494 39.7167C140.229 40.4597 140.597 41.3533 140.597 42.3975C140.597 43.0401 140.457 43.6224 140.178 44.1445C139.899 44.6666 139.453 45.2155 138.841 45.7912C138.365 46.2463 138.031 46.6145 137.841 46.8956C137.65 47.1767 137.528 47.498 137.473 47.8595C137.433 48.1406 137.31 48.3749 137.106 48.5623C136.902 48.7497 136.664 48.8434 136.391 48.8434ZM136.351 53.8636C135.956 53.8636 135.619 53.7264 135.34 53.452C135.061 53.1775 134.922 52.8462 134.922 52.458C134.922 52.0697 135.061 51.7384 135.34 51.464C135.619 51.1895 135.956 51.0523 136.351 51.0523C136.745 51.0523 137.082 51.1895 137.361 51.464C137.64 51.7384 137.78 52.0697 137.78 52.458C137.78 52.8462 137.64 53.1775 137.361 53.452C137.082 53.7264 136.745 53.8636 136.351 53.8636Z"
        fill="#B5C0D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.801 105.896C140.992 100.444 140.054 93.7249 136.634 85.7066C135.876 83.9286 133.82 83.1019 132.042 83.8602C130.264 84.6185 129.437 86.6746 130.195 88.4527C133.284 95.6957 133.734 100.869 132.962 104.402C132.219 107.806 130.273 110.025 127.663 111.4C124.955 112.827 121.433 113.376 117.79 113.027C114.151 112.68 110.693 111.463 108.179 109.71C106.594 108.604 104.412 108.993 103.306 110.578C102.2 112.164 102.589 114.345 104.174 115.451C107.817 117.992 112.468 119.551 117.124 119.996C121.777 120.44 126.736 119.8 130.925 117.593C135.213 115.335 138.582 111.476 139.801 105.896Z"
        fill="#B5C0D1"
      />
      <ellipse opacity="0.5" cx="71.4719" cy="220.392" rx="44.8864" ry="9.42614" fill="#A2AFC4" />
      <path
        opacity="0.5"
        d="M107.381 206.926C107.381 205.687 108.352 204.682 109.55 204.682H120.474C121.671 204.682 122.642 205.687 122.642 206.926C122.642 208.166 121.671 209.17 120.474 209.17H109.55C108.352 209.17 107.381 208.166 107.381 206.926Z"
        fill="#A2AFC4"
      />
      <path
        opacity="0.5"
        d="M31.0742 234.756C31.0742 233.516 32.0451 232.511 33.2429 232.511H44.1668C45.3646 232.511 46.3355 233.516 46.3355 234.756C46.3355 235.995 45.3646 237 44.1668 237H33.2429C32.0451 237 31.0742 235.995 31.0742 234.756Z"
        fill="#A2AFC4"
      />
      <path
        d="M16.9591 145.843L18.0453 155.643C19.4753 168.542 28.1659 179.438 40.3458 183.599L42.9391 184.485C58.013 189.635 74.2531 190.215 89.6563 186.155L92.3062 185.456C104.752 182.176 114.199 171.928 116.546 159.164L118.329 149.467C121.866 130.213 122.849 110.571 121.253 91.0594L119.226 66.2967C118.178 53.4995 109.966 42.4392 98.0921 37.8352C81.1384 31.2628 62.4911 30.5962 45.1113 35.9412C32.9387 39.6857 23.9585 50.1293 21.9995 62.819L18.209 87.3737C15.2238 106.723 14.8025 126.383 16.9571 145.841L16.9591 145.843Z"
        fill="#DEE4ED"
      />
      <path
        d="M115.854 71.3322L115.533 68.891C114.423 60.4543 108.98 53.861 102.114 52.6367L98.2982 51.957C91.9933 50.8332 85.5947 50.8596 79.3098 52.037L72.6192 53.2898C65.6721 54.59 60.2898 61.4007 59.4215 69.9917L59.2002 72.1797C58.4886 79.2096 58.6467 86.3395 59.6693 93.3256C60.8602 101.466 66.0921 107.821 72.7156 109.175L77.5796 110.17C85.6044 111.811 93.7975 111.578 101.733 109.48L103.755 108.947C110.18 107.25 115.052 100.816 115.982 92.7982L116.019 92.4828C116.832 85.4691 116.777 78.3397 115.856 71.3322L115.854 71.3322Z"
        fill="#5D6675"
      />
      <circle
        opacity="0.1"
        cx="3.01107"
        cy="3.01107"
        r="3.01107"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 110.185 77.1072)"
        fill="white"
      />
      <circle
        opacity="0.1"
        cx="3.4113"
        cy="3.4113"
        r="3.4113"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 87.3667 75.6723)"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.839 86.8716C109.124 87.2318 109.063 87.7549 108.703 88.0398L105.013 90.9586C104.653 91.2435 104.13 91.1825 103.845 90.8223C103.56 90.4621 103.621 89.9391 103.981 89.6541L107.671 86.7354C108.031 86.4504 108.554 86.5114 108.839 86.8716Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.969 87.3525C113.254 87.7127 113.193 88.2358 112.833 88.5207L109.144 91.4395C108.784 91.7244 108.261 91.6634 107.976 91.3032C107.691 90.943 107.752 90.42 108.112 90.135L111.801 87.2163C112.161 86.9313 112.684 86.9923 112.969 87.3525Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6911 87.3256C89.9761 87.6858 89.9151 88.2088 89.5549 88.4937L85.8654 91.4125C85.5052 91.6974 84.9822 91.6364 84.6973 91.2762C84.4123 90.916 84.4733 90.393 84.8335 90.1081L88.5229 87.1893C88.8831 86.9044 89.4062 86.9654 89.6911 87.3256Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.736 86.7472C85.021 87.1074 84.96 87.6304 84.5998 87.9154L80.9104 90.8341C80.5501 91.1191 80.0271 91.0581 79.7422 90.6979C79.4572 90.3377 79.5182 89.8147 79.8784 89.5297L83.5679 86.6109C83.9281 86.326 84.4511 86.387 84.736 86.7472Z"
        fill="white"
      />
      <rect
        width="20.547"
        height="24.2662"
        rx="10.2735"
        transform="matrix(-0.99937 -0.0354915 -0.0354915 0.99937 55.3814 102.819)"
        fill="#CAD3E0"
      />
      <circle
        cx="4.40293"
        cy="4.40293"
        r="4.40293"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 48.5589 110.055)"
        fill="#818FA5"
      />
      <circle
        cx="5.13676"
        cy="5.13676"
        r="5.13676"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 81.9674 119.701)"
        fill="#B971E0"
      />
      <circle
        cx="1.46764"
        cy="1.46764"
        r="1.46764"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 78.0863 123.299)"
        fill="white"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.348 123.812)"
        fill="#CAD3E0"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.348 128.215)"
        fill="#CAD3E0"
      />
      <rect
        width="12.5374"
        height="2.65389"
        rx="1.32694"
        transform="matrix(-0.999 0.0447005 -0.0913637 -0.995818 118.348 119.409)"
        fill="#CAD3E0"
      />
      <path
        d="M71.5084 37.6885C55.7177 36.5444 41.7535 44.6055 37.2184 50.1088C36.635 50.8168 37.3528 51.5485 38.186 51.1646C54.7568 43.5289 88.7541 38.938 71.5084 37.6885Z"
        fill="#FAFAFB"
      />
      <circle
        cx="2.20147"
        cy="2.20147"
        r="2.20147"
        transform="matrix(-0.987302 0.158854 0.158854 0.987302 32.7053 53.5417)"
        fill="#FAFAFB"
      />
      <path
        d="M90.5253 177.136C98.5495 178.466 112.525 163.839 113.073 146.43C113.108 145.316 111.57 145.157 111.175 146.199C106.039 159.751 93.8768 172.119 90.1777 175.674C89.6622 176.17 89.8198 177.019 90.5253 177.136Z"
        fill="#CAD3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.5339 80.8886C74.6022 80.8196 72.9802 82.3296 72.9112 84.2614C72.4703 96.6081 70.7108 104.818 68.422 110.093C66.1512 115.327 63.5053 117.378 61.3396 118.094C59.1136 118.83 56.5914 118.423 54.0942 117.131C51.5826 115.832 49.4922 113.827 48.3723 112.105C47.3188 110.484 45.151 110.024 43.5303 111.077C41.9096 112.131 41.4497 114.299 42.5032 115.919C44.3007 118.685 47.316 121.506 50.878 123.348C54.4544 125.198 58.9737 126.249 63.5368 124.741C68.1601 123.212 72.0507 119.316 74.8436 112.88C77.6186 106.484 79.45 97.3008 79.9068 84.5113C79.9758 82.5795 78.4657 80.9576 76.5339 80.8886Z"
        fill="#B5C0D1"
      />
      <ellipse cx="96.6082" cy="91.1193" rx="2.69318" ry="2.24432" fill="white" />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 90.775 72.637)"
        fill="#91A0B8"
      />
      <path
        d="M103.183 73.768C103.183 71.1999 105.128 69.0494 107.684 68.793L145.713 64.977C148.657 64.6816 151.212 66.9933 151.212 69.9521V86.9651C151.212 90.034 148.473 92.3786 145.441 91.9053L107.412 85.9694C104.978 85.5894 103.183 83.4929 103.183 81.0292V73.768Z"
        fill="#3B404A"
      />
      <path
        d="M150.498 63.9108L133.152 64.0565L131.945 92.229L148.568 93.32L150.498 63.9108Z"
        fill="#3B404A"
      />
      <ellipse
        cx="132.869"
        cy="78.1639"
        rx="8.96988"
        ry="14.0955"
        transform="rotate(3.75513 132.869 78.1639)"
        fill="#3B404A"
      />
      <ellipse
        cx="149.533"
        cy="78.6156"
        rx="8.96988"
        ry="14.7362"
        transform="rotate(3.75513 149.533 78.6156)"
        fill="#3B404A"
      />
      <ellipse
        cx="149.533"
        cy="78.6153"
        rx="7.68847"
        ry="12.1734"
        transform="rotate(3.75513 149.533 78.6153)"
        fill="#2B2F36"
      />
      <ellipse
        cx="152.468"
        cy="73.0292"
        rx="2.56282"
        ry="3.84423"
        transform="rotate(3.75513 152.468 73.0292)"
        fill="white"
      />
      <ellipse
        cx="154.48"
        cy="81.5084"
        rx="1.28141"
        ry="1.92212"
        transform="rotate(3.75513 154.48 81.5084)"
        fill="white"
      />
      <path
        d="M79.551 73.9847C79.551 71.4166 81.4965 69.2661 84.0518 69.0097L123.566 65.0447C126.51 64.7493 129.065 67.061 129.065 70.0197V87.9102C129.065 90.9791 126.326 93.3237 123.294 92.8504L83.7799 86.6827C81.3457 86.3028 79.551 84.2062 79.551 81.7425V73.9847Z"
        fill="#3B404A"
      />
      <path
        d="M128.329 63.9627L110.447 64.1129L109.203 93.1563L126.339 94.2811L128.329 63.9627Z"
        fill="#454B54"
      />
      <ellipse
        cx="110.154"
        cy="78.6561"
        rx="9.2472"
        ry="14.5313"
        transform="rotate(3.75513 110.154 78.6561)"
        fill="#454B54"
      />
      <ellipse
        cx="127.334"
        cy="79.1219"
        rx="9.2472"
        ry="15.1918"
        transform="rotate(3.75513 127.334 79.1219)"
        fill="#454B54"
      />
      <ellipse
        cx="127.334"
        cy="79.1218"
        rx="7.92617"
        ry="12.5498"
        transform="rotate(3.75513 127.334 79.1218)"
        fill="#2B2F36"
      />
      <ellipse
        cx="130.36"
        cy="73.3629"
        rx="2.64206"
        ry="3.96308"
        transform="rotate(3.75513 130.36 73.3629)"
        fill="white"
      />
      <ellipse
        cx="132.434"
        cy="82.1042"
        rx="1.32103"
        ry="1.98154"
        transform="rotate(3.75513 132.434 82.1042)"
        fill="white"
      />
      <rect
        width="14.9416"
        height="10.9043"
        rx="4"
        transform="matrix(-0.953356 -0.301847 -0.301847 0.953356 86.3859 79.7352)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 79.9154 69.4057)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.958754 -0.284237 -0.284237 0.958754 73.0311 71.2178)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 90.775 72.637)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 85.0413 70.7205)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 85.3887 69.9438)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.912877 -0.408235 -0.408235 0.912877 80.0023 69.046)"
        fill="#91A0B8"
      />
      <rect
        width="4.20486"
        height="7.0957"
        rx="2.10243"
        transform="matrix(-0.958754 -0.284237 -0.284237 0.958754 83.9149 81.1257)"
        fill="#818FA5"
      />
    </svg>
  )
}
