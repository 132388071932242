import { Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function MonitooraIcon(props: Omit<IconProps, 'fill' | 'ref'>): JSX.Element {
  return (
    <Svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ fill: 'none', pointerEvents: 'none', width: 40, height: 40 }}
      {...props}
    >
      <g clipPath="url(#clip0_21230_40473)">
        <path
          d="M17.6225 12.8566C20.2596 12.8566 22.3884 14.9853 22.3884 17.6224C22.3884 20.2595 20.2597 22.3883 17.6226 22.3883C14.9855 22.3883 12.8567 20.2595 12.8567 17.6224C12.8567 14.9853 14.9854 12.8566 17.6225 12.8566Z"
          fill="#5D6675"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3356 3.00637C17.464 -0.122077 22.5363 -0.122078 25.6647 3.00637L36.9938 14.3355C40.1223 17.4639 40.1223 22.5361 36.9938 25.6646L25.6647 36.9937C22.5363 40.1221 17.464 40.1221 14.3356 36.9937L3.00649 25.6646C-0.121956 22.5361 -0.121955 17.4639 3.00649 14.3355L14.3356 3.00637ZM17.6226 24.5064C19.3277 24.5064 20.8951 23.8816 22.1024 22.8437L22.8438 22.1023C23.8817 20.895 24.5065 19.3275 24.5065 17.6224C24.5065 13.8203 21.4246 10.7384 17.6225 10.7384C13.8205 10.7384 10.7386 13.8203 10.7386 17.6224C10.7386 21.4245 13.8205 24.5064 17.6226 24.5064ZM22.3884 23.1402V23.9769L27.6838 29.2616L29.2618 27.6836L23.977 22.3883L23.1403 22.3883L22.3884 23.1402Z"
          fill="#5D6675"
        />
      </g>
      <defs>
        <clipPath id="clip0_21230_40473">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}
