import { useCallback, useEffect, useState } from 'react'

import { i18n } from '@/common/i18n'

interface CountdownProps {
  expiresAt?: Date
  onTimeExpire?: () => void
}

export function useCountdown({ expiresAt, onTimeExpire }: CountdownProps): string {
  const [time, setTime] = useState('')
  const oneMinutesInSeconds = 60000

  const changeTime = useCallback((): void => {
    if (expiresAt) {
      const millisecondsInSecond = 1000
      const secondsInMinute = 60
      const minutesInHour = 60
      const hoursInDay = 24
      const countDown = new Date(expiresAt).getTime()
      const currentTime = new Date().getTime()
      const diffTime = countDown - currentTime
      const hours = Math.floor(
        (diffTime % (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay)) /
          (millisecondsInSecond * secondsInMinute * minutesInHour)
      )
      const minutes = Math.floor(
        (diffTime % (millisecondsInSecond * secondsInMinute * minutesInHour)) /
          (millisecondsInSecond * secondsInMinute)
      )

      if (hours === 0) {
        setTime(`${minutes} ${i18n().common.hooks.countdown.minutes}`)
      } else {
        setTime(`${hours}h ${minutes} ${i18n().common.hooks.countdown.minutes}`)
      }

      if (hours === 0 && minutes === 0) {
        onTimeExpire?.()
      }
    }
  }, [expiresAt, onTimeExpire])

  useEffect(() => {
    changeTime()
    setInterval(() => {
      changeTime()
    }, oneMinutesInSeconds)
  }, [changeTime, expiresAt])

  return time
}
