import { Div } from '@positivote/design-system/components/Div'
import { Iframe } from '@positivote/design-system/components/Iframe'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useShowEngagementPerformance } from '@/modules/hub/data-intelligence/hooks'

export function EngagementPerformance(): JSX.Element {
  changePageTitle(i18n().modules.hub.dataIntelligence.pages.engagementPerformance.pageTitle)
  const { breakpoint } = useTheme()

  const navigate = useNavigate()
  const { profile } = useAuth()
  const { data, isLoading } = useShowEngagementPerformance({
    model: {
      payload: {
        params: {
          escola: profile?.organization.name as unknown as string
        },
        resource: {
          dashboard: Number(import.meta.env.VITE_METABASE_ENGAGEMENT_PERFORMANCE_DASH_ID)
        }
      }
    },
    queryOptions: {
      enabled: !!profile?.organization.name
    }
  })

  return (
    <Main css={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <AppBar
        title={i18n().modules.hub.dataIntelligence.pages.engagementPerformance.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      <>
        {breakpoint <= Breakpoint.md ? (
          <LargerScreensOnly />
        ) : isLoading ? (
          <WrappedLoader />
        ) : (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: '$lg',
              gap: '$lg',
              overflowY: 'scroll',
              overflowX: 'hidden',
              '@sm': { padding: '$md' }
            }}
          >
            <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
              {i18n().modules.hub.dataIntelligence.pages.engagementPerformance.helpText}
            </Typography>
            <Iframe src={data?.url ?? ''} css={{ width: '100%', height: '100%' }} />
          </Div>
        )}
      </>
    </Main>
  )
}
