import { CSS } from '@positivote/design-system/theme'

import { convertDateToMonthYear, currencyMask } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import {
  GenerateInvoiceForm,
  SalesOrderApi,
  SalesOrderFormatted,
  ServiceOperationForm
} from './contracts'
import { ListSalesOrderHookParams } from './contracts/hooks'

const statusColor: Record<string, CSS['color']> = {
  PROCESSED: '$success',
  CREATED: '$primary',
  SENT: '$info',
  ERROR: '$critical'
}

export function fromSalesOrderApiSanitizer(salesOrders: SalesOrderApi[]): SalesOrderFormatted[] {
  return salesOrders.map((salesOrder) => {
    const services = salesOrder.services.map((service) => ({
      serviceId: service.service_id,
      operation: service.operation && {
        type: service.operation.type,
        value: service.operation.value
      },
      appSisId: service.app_sis_id,
      appSisName: service.app_sis_name,
      licenses: service.licenses,
      licensesMonth: service.licenses_month,
      studentValue: service.student_value,
      subtotal: service.subtotal,
      total: service.total
    }))

    return {
      id: salesOrder.id,
      createdAt: salesOrder.created_at,
      updatedAt: salesOrder.updated_at,
      contractId: salesOrder.contract_id,
      orderDate: salesOrder.order_date,
      status: salesOrder.status,
      services,
      emailsToInvoice: salesOrder.emails_to_invoice,
      invoice: salesOrder.invoice && {
        id: salesOrder.invoice.id,
        rps: salesOrder.invoice.rps,
        files:
          salesOrder.invoice.files?.map((file) => ({
            fileId: file.file_id,
            fileUrl: file.file_url,
            duoDate: file.duo_date
          })) ?? null
      },
      errorsLog:
        salesOrder.errors_log?.map((errorLog) => ({
          message: errorLog.message,
          createdAt: errorLog.created_at
        })) ?? null,
      discount: salesOrder.discount,
      addition: salesOrder.addition,
      subtotal: salesOrder.subtotal,
      total: salesOrder.total,
      createdAtFormatted: new Date(salesOrder.created_at).toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }),
      updatedAtFormatted: new Date(salesOrder.updated_at).toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }),
      orderDateFormatted: convertDateToMonthYear(salesOrder.order_date),
      statusColor: statusColor[salesOrder.status],
      statusFormatted:
        i18n().modules.billing.salesOrders.pages.list.filter.input.status[salesOrder.status],
      errorsLogFormatted:
        salesOrder.errors_log?.map((errorLog) => ({
          message: errorLog.message,
          createdAtFormatted: new Date(errorLog.created_at).toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        })) ?? null,
      servicesFormatted: services.map((service) => ({
        ...service,
        operationFormatted: service.operation && {
          type: service.operation.type,
          value: currencyMask(service.operation.value, 'decimal')
        },
        additionFormatted:
          service.operation?.type === 'addition'
            ? currencyMask(service.operation.value)
            : currencyMask(0),
        discountFormatted:
          service.operation?.type === 'discount'
            ? currencyMask(service.operation.value)
            : currencyMask(0),
        subtotalFormatted: currencyMask(service.subtotal),
        studentValueFormatted: currencyMask(service.studentValue),
        totalFormatted: currencyMask(service.total)
      })),
      discountFormatted: currencyMask(salesOrder.discount),
      additionFormatted: currencyMask(salesOrder.addition),
      subtotalFormatted: currencyMask(salesOrder.subtotal),
      totalFormatted: currencyMask(salesOrder.total)
    }
  })
}

export function listSalesOrderFormSanitizer(
  form: ListSalesOrderHookParams['model']
): ListSalesOrderHookParams['model'] {
  return {
    searchBy: form.searchBy ? form.searchBy : undefined,
    orderMonth: form.orderMonth ? form.orderMonth : undefined,
    orderYear: form.orderYear ? form.orderYear : undefined,
    orderStatus: form.orderStatus ? form.orderStatus : undefined
  }
}

export function generateInvoiceFormSanitizer(
  params: Partial<GenerateInvoiceForm>
): GenerateInvoiceForm {
  return {
    emailsToInvoice: params.emailsToInvoice ?? [],
    shouldSave: !!params.shouldSave
  }
}

export function serviceOperationFormSanitizer(params: ServiceOperationForm): ServiceOperationForm {
  return {
    value: params.value
  }
}
