export function cpfMask(value: string): string {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') || ''
  )
}

export function cnpjMask(value: string): string {
  return (value || '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
}

export function cellMask(value: string): string {
  return (
    (value || '')
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1') || ''
  )
}

export function defaultDateMask(value: string): string {
  return (value || '')
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

export function removeMask(value: string): string {
  return (
    (value || '')
      .replace(/\./g, '')
      .replace(/,/g, '')
      .replace(/-/g, '')
      .replace(/\//g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\s/g, '') || ''
  )
}

export function onlyNumberStringMask(value: string): string {
  return value.replace(/\D/g, '')
}

export function currencyMask(value: number, style: 'currency' | 'decimal' = 'currency'): string {
  return value.toLocaleString('pt-BR', { style, currency: 'BRL', minimumFractionDigits: 2 })
}

export function currencyUnmask(value: string): number {
  return Number(value.replace(/[^\d,]/g, '').replace(',', '.'))
}

export function defaultDateIsoStringMask(date: string): string {
  return `${date}.000Z`
}
