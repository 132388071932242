import { yupResolver } from '@hookform/resolvers/yup'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Loader } from '@positivote/design-system/components/Loader'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormAutocomplete } from '@positivote/design-system/components-form/Autocomplete'
import { FormCheckbox } from '@positivote/design-system/components-form/Checkbox'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormMediaInput } from '@positivote/design-system/components-form/MediaInput'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { AddIcon } from '@positivote/design-system/icons/Add'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { cellMask, cpfMask, debounceEvent, genderDictionary } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { useListProfileRoles } from '@/modules/hub/profiles/hooks'
import { ListShortStudent, ListShortStudentHookParams } from '@/modules/hub/users/contracts'
import { UserDataStepForm, UserStepperState } from '@/modules/hub/users/contracts/forms'
import { useAvailableUser, useListShortStudent } from '@/modules/hub/users/hooks'
import {
  userDataValidationSchema,
  userStudentDataValidationSchema
} from '@/modules/hub/users/validations'

interface UserDataStepProps {
  stepState: Partial<UserStepperState['userData']>
  setStepState: (stepperState: Partial<UserStepperState['userData']>) => void
  isLoading: boolean
  onChangeIsDirty: (isDirty: boolean) => void
}

export const UserDataStep = forwardRef(function UserDataStep(
  { stepState, setStepState, isLoading, onChangeIsDirty }: UserDataStepProps,
  ref
) {
  const listProfileRoles = useListProfileRoles({})
  const { profile } = useAuth()
  const params = useParams()
  const genderOptions = Object.values(genderDictionary)
  const [roles, setRoles] = useState<number[]>([])
  const [isLoadingCode, setIsLoadingCode] = useState(false)
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [available, setAvailable] = useState<{ login?: string; code?: string }>({
    login: undefined,
    code: undefined
  })
  const [studentParams, setStudentParams] = useState<ListShortStudentHookParams['model']>({
    perPage: 4,
    page: 1
  })
  const [responsibleStudents, setResponsibleStudents] = useState<Array<
    Pick<ListShortStudent, 'code' | 'id' | 'name' | 'picture'>
  > | null>([])

  const {
    control,
    handleSubmit,
    formState,
    reset,
    register,
    watch,
    setValue,
    setError,
    clearErrors
  } = useForm<UserDataStepForm>({
    mode: 'onSubmit',
    defaultValues: {
      roles: [],
      allowedImageUseOnEdtech: true
    },
    resolver: yupResolver(
      roles.includes(1) ? userStudentDataValidationSchema : userDataValidationSchema
    )
  })

  const listShortStudent = useListShortStudent({
    model: studentParams,
    queryOptions: {
      enabled: !!studentParams.search
    }
  })
  const minSearchAvailableUser = 3
  const availableUser = useAvailableUser({
    model: {
      institutionId: profile!.organizationId,
      profileId: profile!.id,
      code:
        available.code && available.code.length >= minSearchAvailableUser
          ? available.code
          : undefined,
      login:
        available.login && available.login.length >= minSearchAvailableUser
          ? available.login
          : undefined
    },
    queryOptions: {
      enabled:
        Boolean(
          (available.code && available.code.length >= minSearchAvailableUser) ||
            (available.login && available.login.length >= minSearchAvailableUser)
        ) &&
        !!profile &&
        !formState.errors.login &&
        !formState.errors.code
    },
    onSuccess: (data) => {
      if (available.code) {
        if (data.code === 'unavailable' && available.code !== stepState.form?.code) {
          setIsLoadingCode(false)
          setError('code', {
            message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidCode
          })
        } else {
          setIsLoadingCode(false)
          clearErrors('code')
        }
      }
      if (available.login) {
        if (data.login === 'unavailable' && available.login !== stepState.form?.login) {
          setIsLoadingLogin(false)
          setError('login', {
            message:
              i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidLogin
          })
        } else {
          setIsLoadingLogin(false)
          clearErrors('login')
        }
      }
    }
  })

  const validateDataForm = useCallback(async (): Promise<UserDataStepForm | null> => {
    return new Promise((resolve) => {
      void handleSubmit(
        (data) => resolve(data),
        () => resolve(null)
      )()
    })
  }, [handleSubmit])

  useImperativeHandle(ref, () => ({ validateDataForm }), [validateDataForm])

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined

    debounceEvent(() => {
      setStudentParams((oldData) => ({ ...oldData, search: searchBy, page: 1 }))
    })()
  }

  const listFilterResponsibleStudents = useMemo(() => {
    if (!listShortStudent.data?.registers || !responsibleStudents) {
      return []
    }

    return listShortStudent.data.registers.filter(
      (student) => !responsibleStudents.some((responsible) => responsible.id === student.id)
    )
  }, [listShortStudent, responsibleStudents])

  const isDisableForm = !watch('roles').length || !!formState.errors.roles?.message

  function handleRemoveStudent(id: number): void {
    const filterStudents = responsibleStudents?.filter((student) => student.id !== id)
    setResponsibleStudents(filterStudents!)
  }
  const regexSpace = /\s/
  const regexSpecialCharacters = /[^\w\s]/
  function handleChangeCode(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      if (
        regexSpace.test(watch('code')) ||
        (regexSpecialCharacters.test(watch('code')) && !params.id)
      ) {
        setError('code', {
          message:
            i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidCharacterCode
        })
      } else {
        clearErrors('code')
        setIsLoadingCode(true)
        setAvailable((oldData) => ({ ...oldData, code: search }))
      }
    })()
  }

  function handleChangeLogin(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      if (
        regexSpace.test(watch('login')) ||
        (regexSpecialCharacters.test(watch('login')) && !params.id)
      ) {
        setError('login', {
          message:
            i18n().modules.hub.users.pages.form.stepper.registerData.validators
              .invalidCharacterLogin
        })
      } else {
        clearErrors('login')
        setIsLoadingLogin(true)
        setAvailable((oldData) => ({ ...oldData, login: search }))
      }
    })()
  }

  const hasError = !!Object.keys(formState.errors).length
  const isDirty =
    watch('name') ||
    watch('document') ||
    watch('email') ||
    watch('gender') ||
    watch('login') ||
    watch('lastName') ||
    watch('phone') ||
    watch('roles').length

  useEffect(() => {
    const studentIds = responsibleStudents?.map((currentStudentIds) => currentStudentIds.id)
    setValue('students', studentIds)
    setStepState({
      ...stepState,
      students: responsibleStudents
    })
    // DOCS: only render when responsibleStudents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleStudents, setValue])

  useEffect(() => {
    if (stepState.form) {
      reset(stepState.form)
      setResponsibleStudents(stepState.students!)
    }
    // DOCS: only render when stepState.form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, stepState.form])

  useEffect(() => {
    const currentRoles = watch('roles')
    setRoles(watch('roles'))
    if (currentRoles.includes(1) && currentRoles.length >= 2) {
      setError('roles', {
        message: i18n().modules.hub.users.pages.form.stepper.registerData.validators.invalidRole
      })
    } else {
      clearErrors('roles')
    }
    // DOCS: only render when roles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('roles')])

  useEffect(() => {
    setStepState({
      ...stepState,
      hasError,
      canGoNext: !hasError && !availableUser.isFetching
    })
    // DOCS: only render when hasError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, availableUser.isFetching])

  useEffect(() => {
    if (isDirty) {
      onChangeIsDirty(!!isDirty)
    } else {
      onChangeIsDirty(false)
    }
    // DOCS: only render when isDirty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  return (
    <>
      {isLoading ? (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader size={80} />
        </Div>
      ) : (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '$sm'
          }}
        >
          <Typography
            data-testid="Typography-requiredFields"
            variant="bodyMedium"
            css={{ color: '$on-surface-variant' }}
          >
            {i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.title}
          </Typography>
          <Grid>
            <Div
              css={{
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderRadius: '$xl',
                borderColor: '$outline-variant',
                padding: '$lg'
              }}
            >
              <Grid css={{ display: 'flex', gap: '$lg' }}>
                <Grid
                  xl={1}
                  lg={2}
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '$sm'
                  }}
                >
                  <Typography variant="bodyLarge">
                    {i18n().modules.hub.users.pages.form.stepper.registerData.profilePhoto}
                  </Typography>

                  <FormMediaInput
                    control={control}
                    name="picture"
                    htmlFor="urlLogo"
                    alt="urlLogo"
                  />
                </Grid>
                <Grid
                  xl={11}
                  lg={10}
                  css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}
                >
                  <Typography variant="titleMedium">
                    {i18n().modules.hub.users.pages.form.stepper.registerData.userProfiles}
                  </Typography>
                  <FormAutocomplete
                    isLoading={listProfileRoles.isFetching}
                    name="roles"
                    control={control}
                    label={i18n().modules.hub.users.pages.form.stepper.registerData.assignProfile}
                    variant="outlined"
                    options={listProfileRoles.data?.registers ?? []}
                    optionKeyField="id"
                    optionTitleField="name"
                    supportingText={
                      i18n().modules.hub.disciplines.components.assingDisciplineDialog
                        .canSelectMultipleOptions
                    }
                    errorText={formState.errors.roles?.message}
                    multiple
                    withCheckbox
                    gridProps={{ xl: 6 }}
                  />
                  <Divider />
                  <Grid spacing="$md">
                    <FormTextField
                      disabled={isDisableForm}
                      required
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.name}
                      gridProps={{ xl: 6 }}
                      control={control}
                      name="name"
                      variant="outlined"
                      errorText={formState.errors.name?.message}
                    />
                    <FormTextField
                      disabled={isDisableForm}
                      required
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.lastName}
                      gridProps={{ xl: 6 }}
                      control={control}
                      name="lastName"
                      variant="outlined"
                      errorText={formState.errors.lastName?.message}
                    />
                  </Grid>
                  <Grid spacing="$md">
                    <FormTextField
                      disabled={isDisableForm}
                      required
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.registration}
                      gridProps={{ xl: 6 }}
                      control={control}
                      name="code"
                      variant="outlined"
                      inputProps={{
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeCode(event)
                      }}
                      trailingIcon={
                        availableUser.isLoading && isLoadingCode
                          ? {
                              icon: (props) => <Loader {...props} fill="$primary" />,
                              changeIconOnError: false
                            }
                          : undefined
                      }
                      errorText={formState.errors.code?.message}
                    />
                    <FormTextField
                      disabled={isDisableForm}
                      required
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.login}
                      gridProps={{ xl: 6 }}
                      control={control}
                      name="login"
                      variant="outlined"
                      inputProps={{
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeLogin(event)
                      }}
                      trailingIcon={
                        availableUser.isLoading && isLoadingLogin
                          ? {
                              icon: (props) => <Loader {...props} fill="$primary" />,
                              changeIconOnError: false
                            }
                          : undefined
                      }
                      errorText={formState.errors.login?.message}
                    />
                  </Grid>
                  <Grid spacing="$md">
                    <FormDatePicker
                      disabled={isDisableForm}
                      required
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.birthday}
                      gridProps={{ xl: 4 }}
                      control={control}
                      name="birthday"
                      variant="outlined"
                      errorText={formState.errors.birthday?.message}
                    />
                    <FormTextField
                      disabled={isDisableForm}
                      required={!watch('roles').includes(1)}
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.email}
                      gridProps={{ xl: 8 }}
                      control={control}
                      name="email"
                      variant="outlined"
                      errorText={formState.errors.email?.message}
                    />
                  </Grid>
                  <Grid spacing="$md">
                    <FormSelect
                      disabled={isDisableForm}
                      name="gender"
                      optionKeyField="key"
                      optionTitleField="value"
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.gender}
                      variant="outlined"
                      control={control}
                      options={genderOptions}
                      gridProps={{ xl: 4 }}
                    />
                    <FormTextField
                      disabled={isDisableForm}
                      required={!watch('roles').includes(1)}
                      control={control}
                      name="document"
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.document}
                      variant="outlined"
                      inputProps={{ mask: cpfMask }}
                      gridProps={{ xl: 4 }}
                      errorText={formState.errors.document?.message}
                    />
                    <FormTextField
                      disabled={isDisableForm}
                      label={i18n().modules.hub.users.pages.form.stepper.registerData.phone}
                      gridProps={{ xl: 4 }}
                      control={control}
                      inputProps={{ mask: cellMask }}
                      name="phone"
                      variant="outlined"
                    />
                  </Grid>
                  <FormCheckbox
                    disabled={isDisableForm}
                    register={register}
                    name="allowedImageUseOnEdtech"
                    label={
                      i18n().modules.hub.users.pages.form.stepper.registerData
                        .allowedImageUseOnEdtech
                    }
                  />
                  {watch('roles').includes(2) && !isDisableForm && (
                    <>
                      <Divider />
                      <Div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderWidth: '$thin',
                          borderStyle: 'solid',
                          borderRadius: '$md',
                          borderColor: '$outline-variant',
                          padding: '$md',
                          gap: '$md'
                        }}
                      >
                        <Typography variant="titleMedium">
                          {i18n().modules.hub.users.pages.form.stepper.registerData.linkStudents}
                        </Typography>
                        <Grid xl={6}>
                          <TextField
                            inputProps={{
                              onChange: handleChangeSearchText
                            }}
                            label={
                              i18n().modules.hub.users.pages.form.stepper.registerData
                                .searchStudents
                            }
                            variant="outlined"
                            supportingText={
                              i18n().modules.hub.users.pages.form.stepper.registerData
                                .searchStudentsSupportingText
                            }
                          />
                        </Grid>
                        {!!studentParams.search?.length && (
                          <Typography variant="titleSmall">
                            {i18n().modules.hub.users.pages.form.stepper.registerData.searchResult}
                          </Typography>
                        )}

                        <Grid
                          spacing="$md"
                          css={{
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            justifyContent: listShortStudent.isFetching ? 'center' : 'unset',
                            minHeight: listShortStudent.isFetching ? 200 : '$none'
                          }}
                        >
                          {!listShortStudent.data?.registers.length ? (
                            <Div
                              css={{
                                display: 'flex',
                                flex: 1
                              }}
                            >
                              <Div
                                css={{
                                  display: 'flex',
                                  flex: 1,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  height: listShortStudent.isFetching ? 200 : 'auto'
                                }}
                              >
                                {listShortStudent.isFetching && (
                                  <Loader
                                    data-testid="Loader-Container-ServiceMappingList"
                                    size={80}
                                  />
                                )}
                                {!listShortStudent.isFetching && studentParams.search && (
                                  <EmptySearch />
                                )}
                              </Div>
                            </Div>
                          ) : (
                            <>
                              {!!studentParams.search?.length && (
                                <>
                                  {listShortStudent.isFetching && (
                                    <Loader
                                      data-testid="Loader-Container-ServiceMappingList"
                                      size={80}
                                    />
                                  )}
                                  {!listFilterResponsibleStudents.length && (
                                    <Div
                                      css={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        opacity: listShortStudent.isFetching
                                          ? '$transparent'
                                          : '$default'
                                      }}
                                    >
                                      <EmptyList
                                        title={
                                          i18n().modules.hub.users.pages.form.stepper.registerData
                                            .emptyList
                                        }
                                      />
                                    </Div>
                                  )}
                                  {listFilterResponsibleStudents.map((students, index) => (
                                    <Grid xl={6} key={students.id}>
                                      <BaseCard
                                        css={{
                                          opacity: listShortStudent.isFetching
                                            ? '$transparent'
                                            : '$default',
                                          borderRadius: '$lg',
                                          flex: 1,
                                          backgroundColor: '$surface-2',
                                          '& .BaseCard-StateLayer': {
                                            padding: '$md $lg',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: '$md',
                                            justifyContent: 'space-between'
                                          }
                                        }}
                                      >
                                        <Div
                                          css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '$md'
                                          }}
                                        >
                                          <Image
                                            alt={`${students.name} picture`}
                                            src={students.picture}
                                            FallbackImage={() => (
                                              <IconWrapper
                                                data-testid="UsersList-FallbackImage"
                                                size="$2xl"
                                                css={{ backgroundColor: '$primary-container' }}
                                              >
                                                <PersonIcon fill="$on-primary-container" />
                                              </IconWrapper>
                                            )}
                                            css={{
                                              height: '$2xl',
                                              width: '$2xl',
                                              borderRadius: '$full',
                                              objectFit: 'cover'
                                            }}
                                          />
                                          <Typography
                                            variant="bodyMedium"
                                            lineClamp={1}
                                            css={{ color: '$on-surface' }}
                                            data-testid={`Typography-listItemUsername-${index}`}
                                          >
                                            {students.name}
                                          </Typography>
                                        </Div>
                                        <IconButton
                                          onClick={() =>
                                            setResponsibleStudents((oldData) => [
                                              ...oldData!,
                                              students
                                            ])
                                          }
                                          size={24}
                                          css={{ background: '$on-surface-variant' }}
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </BaseCard>
                                    </Grid>
                                  ))}
                                  {listShortStudent.data.lastPage > 1 && (
                                    <Pagination
                                      lastPage={listShortStudent.data.lastPage}
                                      page={studentParams.page ?? 1}
                                      setPage={(page) =>
                                        setStudentParams((oldState) => ({ ...oldState, page }))
                                      }
                                      css={{ marginTop: '$lg' }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </Div>
                      {!!responsibleStudents?.length && (
                        <>
                          <Typography variant="titleMedium">
                            {
                              i18n().modules.hub.users.pages.form.stepper.registerData
                                .responsibleFor
                            }
                          </Typography>
                          <Grid spacing="$md">
                            {responsibleStudents.map((students, index) => (
                              <Grid xl={6} key={students.id}>
                                <BaseCard
                                  css={{
                                    borderRadius: '$lg',
                                    flex: 1,
                                    backgroundColor: '$surface-2',
                                    '& .BaseCard-StateLayer': {
                                      padding: '$md $lg',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: '$md',
                                      justifyContent: 'space-between'
                                    }
                                  }}
                                >
                                  <Div css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                                    <Image
                                      alt={`${students.name} picture`}
                                      src={students.picture}
                                      FallbackImage={() => (
                                        <IconWrapper
                                          data-testid="UsersList-FallbackImage"
                                          size="$2xl"
                                          css={{ backgroundColor: '$primary-container' }}
                                        >
                                          <PersonIcon fill="$on-primary-container" />
                                        </IconWrapper>
                                      )}
                                      css={{
                                        height: '$2xl',
                                        width: '$2xl',
                                        borderRadius: '$full',
                                        objectFit: 'cover'
                                      }}
                                    />
                                    <Typography
                                      variant="bodyMedium"
                                      lineClamp={1}
                                      css={{ color: '$on-surface' }}
                                      data-testid={`Typography-listItemUsername-${index}`}
                                    >
                                      {students.name}
                                    </Typography>
                                  </Div>
                                  <IconButton
                                    onClick={() => handleRemoveStudent(students.id)}
                                    variant="standard"
                                  >
                                    <DeleteIcon size={24} />
                                  </IconButton>
                                </BaseCard>
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Div>
          </Grid>
        </Div>
      )}
    </>
  )
})
