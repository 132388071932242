import { WhiteLabel } from './contracts'

const storageWhiteLabelKey = '@hub/whiteLabel:whiteLabel'

export function getWhiteLabelSessionRepository(): WhiteLabel | null {
  const token = sessionStorage.getItem(storageWhiteLabelKey)
  if (token !== null) {
    return JSON.parse(token) as WhiteLabel
  }
  return token
}

export function saveWhiteLabelSessionRepository(whiteLabel: WhiteLabel): void {
  sessionStorage.setItem(storageWhiteLabelKey, JSON.stringify(whiteLabel))
}

export function removeWhiteLabelSessionRepository(): void {
  sessionStorage.removeItem(storageWhiteLabelKey)
}
