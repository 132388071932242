import { Div } from '@positivote/design-system/components/Div'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Drawer } from '@/common/components/Drawer'
import { SupportLoginBar } from '@/common/components/SupportLoginBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { Footer } from '@/common/layouts/Footer'
import { BillingRoutes } from '@/modules/billing/routes'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ProfileRoutes } from '@/modules/hub/profiles/routes'
import { HubRoutes } from '@/modules/hub/routes'
import { SettingsRoutes } from '@/modules/hub/settings/routes'
import { TermRoutes } from '@/modules/hub/terms/routes'
import { MONITOORA_ROLES } from '@/modules/monitoora/constants'
import { MonitooraRoutes } from '@/modules/monitoora/routes'

function Content(): JSX.Element {
  const { profile, isLoading } = useAuth()

  const isGlobalLoading = isLoading || !profile

  let element = <HubRoutes />

  if (isGlobalLoading) {
    element = <WrappedLoader />
  }

  if (profile?.role) {
    if (MONITOORA_ROLES.includes(profile.role.code)) {
      element = <MonitooraRoutes />
    }

    if (profile.role.code === 'billing') {
      element = <BillingRoutes />
    }
  }

  return (
    <Routes>
      <Route path="/*" element={element} />

      <Route path="/profiles/*" element={isGlobalLoading ? <WrappedLoader /> : <ProfileRoutes />} />
      <Route
        path="/settings/*"
        element={isGlobalLoading ? <WrappedLoader /> : <SettingsRoutes />}
      />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}

export function SignedRoutes(): JSX.Element {
  const { breakpoint } = useTheme()
  const { isLoading } = useAuth()

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <Drawer />
            <Div css={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', flex: 1 }}>
              <SupportLoginBar />
              <Content />
              {(breakpoint !== Breakpoint.xs || isLoading) && <Footer />}
            </Div>
          </>
        }
      />

      <Route path="/terms/*" element={<TermRoutes />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
