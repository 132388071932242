import { AccountMeApi, ListAccountLoginSupportApiResult } from './contracts/apis'
import { Account, AccountLoginSupport } from './contracts/models'

export function fromMeAccountApiSanitizer(account: AccountMeApi): Account {
  return {
    id: account.id,
    code: account.code,
    login: account.code_login,
    type: account.id_type_login,
    skin: account.skin
  }
}

export function fromAccountLoginSupportApiSanitizer(
  accounts: ListAccountLoginSupportApiResult['data']
): AccountLoginSupport[] {
  return accounts.map((account) => ({
    profilePicture: account.profile_picture,
    profileId: account.profile_id,
    codUser: account.cod_user,
    institutionName: account.institution_name,
    profileEmail: account.profile_email,
    profileCpf: account.profile_cpf,
    profileRole: account.profile_role,
    profileRoleCode: account.profile_role_code,
    profileNameShort: account.profile_name_short,
    codLogin: account.cod_login,
    loginType: account.login_type,
    profileName: account.profile_name,
    enabled: account.enabled
  }))
}
