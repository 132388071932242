import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Main } from '@positivote/design-system/components/Main'
import { Switch } from '@positivote/design-system/components/Switch'
import { Typography } from '@positivote/design-system/components/Typography'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import {
  useListOrganizationPermission,
  useUpdateOrganizationPermission
} from '@/modules/hub/organizations/hooks'

export function ListSecurityConfiguration(): JSX.Element {
  changePageTitle(i18n().modules.hub.securityConfiguration.pages.list.pageTitle)

  const navigate = useNavigate()

  const { session } = useAuth()

  const { data: listOrganizationPermissions, isLoading } = useListOrganizationPermission({
    model: {
      organizationId: session?.orgId ?? null
    },
    queryOptions: {
      enabled: !!session?.schoolId
    }
  })

  const updateOrganizationPermission = useUpdateOrganizationPermission()

  const [loadingPermission, setLoadingPermission] = useState<Record<string, boolean>>({})

  function handleSecurityOption(permission: { id: number; enable: boolean; roleId: string }): void {
    setLoadingPermission((oldState) => ({
      ...oldState,
      [`${permission.roleId}-${permission.id}`]: true
    }))
    updateOrganizationPermission.mutate({
      model: {
        roleId: permission.roleId,
        institutionId: session?.orgId ?? null,
        permissionId: permission.id,
        enable: !permission.enable
      },
      onSuccess: () => {
        setLoadingPermission((oldState) => ({
          ...oldState,
          [`${permission.roleId}-${permission.id}`]: false
        }))
      },
      onError: () => {
        setLoadingPermission((oldState) => ({
          ...oldState,
          [`${permission.roleId}-${permission.id}`]: false
        }))
      }
    })
  }

  return (
    <Main
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
    >
      <AppBar
        title={i18n().modules.hub.securityConfiguration.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.securityConfiguration.pages.list.appBar.breadcrumbs.overview,
            onClick: () => navigate(-1)
          },
          {
            label:
              i18n().modules.hub.securityConfiguration.pages.list.appBar.breadcrumbs
                .securityConfiguration
          }
        ]}
      />
      {isLoading ? (
        <WrappedLoader />
      ) : (
        <Grid xl={12} css={{ padding: '$lg', overflowY: 'auto', '@sm': { padding: '$md' } }}>
          <BaseCard
            css={{
              '& .BaseCard-StateLayer': {
                padding: '$lg',
                overflowY: 'hidden',
                flexDirection: 'row'
              }
            }}
          >
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$md',
                minWidth: 200
              }}
            >
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface' }}
                data-testid="Typography-permissions"
              >
                {i18n().modules.hub.securityConfiguration.pages.list.roles.permissions}
              </Typography>
              {listOrganizationPermissions?.[0].permissions.map((permission) => (
                <Fragment key={permission.code}>
                  <Div css={{ display: 'flex', alignItems: 'center', height: 40 }}>
                    <Typography
                      variant="bodyLarge"
                      css={{ color: '$on-surface' }}
                      data-testid={`Typography-permission-${permission.code}`}
                    >
                      {(
                        i18n().modules.hub.securityConfiguration.pages.list.permissions as Record<
                          string,
                          string
                        >
                      )[permission.code] || permission.name}
                    </Typography>
                  </Div>
                  <Divider css={{ '&:last-child': { display: 'none' } }} />
                </Fragment>
              ))}
            </Div>
            <Div css={{ display: 'flex', overflowX: 'auto', flex: 1 }}>
              {listOrganizationPermissions?.map((roleItem) => (
                <Div
                  key={roleItem.id}
                  css={{
                    flex: 1,
                    minWidth: 180,
                    display: 'flex',
                    gap: '$md',
                    flexDirection: 'column'
                  }}
                >
                  <Div css={{ height: 24, display: 'flex', alignItems: 'flex-end' }}>
                    <Typography
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                      data-testid={`Typography-header-${roleItem.id}`}
                    >
                      {(
                        i18n().modules.hub.securityConfiguration.pages.list.roles as Record<
                          string,
                          string
                        >
                      )[roleItem.name] || roleItem.name}
                    </Typography>
                  </Div>
                  {roleItem.permissions.map((permissionItem) => (
                    <Fragment key={permissionItem.code}>
                      <Div
                        css={{
                          display: 'flex',
                          gap: '$2xs',
                          alignItems: 'center'
                        }}
                      >
                        <Switch
                          data-testid={`Switch-Container-${roleItem.id}-${permissionItem.id}`}
                          inputProps={{
                            checked: permissionItem.enable,
                            'data-testid': `Switch-Input-${roleItem.id}-${permissionItem.id}`
                          }}
                          isLoading={!!loadingPermission[`${roleItem.id}-${permissionItem.id}`]}
                          onChange={() =>
                            handleSecurityOption({ ...permissionItem, roleId: roleItem.id })
                          }
                        />
                        <Typography
                          variant="bodyLarge"
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-${roleItem.id}-${permissionItem.id}`}
                        >
                          {i18n().modules.hub.securityConfiguration.pages.list.switchLabel(
                            permissionItem.enable
                          )}
                        </Typography>
                      </Div>
                      <Divider css={{ '&:last-child': { display: 'none' } }} />
                    </Fragment>
                  ))}
                </Div>
              ))}
            </Div>
          </BaseCard>
        </Grid>
      )}
    </Main>
  )
}
