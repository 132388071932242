import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListOnboardingPreRegisterApiParams,
  ListOnboardingPreRegisterApiResult,
  ListOnboardingPreRegisterServiceParams,
  ListOnboardingPreRegisterServiceResult,
  ResendOnboardingCodeApiParams,
  ResendOnboardingCodeServiceParams,
  ShowOnboardingInRegisterDetailsApiResult,
  ShowOnboardingInRegisterDetailsServiceParams,
  ShowOnboardingInRegisterDetailsServiceResult
} from './contracts'
import {
  onboardingInRegisterDetailsApiSanitizer,
  onboardingPreRegisterApiSanitizer
} from './sanitizers'

export async function listOnboardingPreRegisterService(
  params: ListOnboardingPreRegisterServiceParams
): Promise<ListOnboardingPreRegisterServiceResult> {
  const { data, headers } = await hubApiService<
    ListOnboardingPreRegisterApiParams,
    ListOnboardingPreRegisterApiResult
  >({
    resource: i18n().modules.hub.onboarding.resources.listPreRegisterService,
    method: 'get',
    url: '/onboarding/v1/register',
    params: {
      page: params.page,
      limit: params.perPage,
      sort: 'name',
      sortType: 'ASC',
      search: params.search,
      type: params.type
    }
  })
  return listHubApiResultSanitizer({
    page: params.page,
    content_range: params.perPage,
    total_pages: Number(headers['total-pages'] || 1),
    total: Number(headers['content-range'] || 1),
    orderBy: 'name',
    order: 'asc',
    data: onboardingPreRegisterApiSanitizer(data)
  })
}

export async function resendOnboardingCodeService(
  params: ResendOnboardingCodeServiceParams
): Promise<void> {
  await hubApiService<ResendOnboardingCodeApiParams, null>({
    resource: i18n().modules.hub.onboarding.resources.resendCode,
    method: 'post',
    url: '/onboarding/v1/resend-code',
    body: params
  })
}

export async function showOnboardingInRegisterDetailsService(
  params: ShowOnboardingInRegisterDetailsServiceParams
): Promise<ShowOnboardingInRegisterDetailsServiceResult> {
  const { data } = await hubApiService<null, ShowOnboardingInRegisterDetailsApiResult>({
    resource: i18n().modules.hub.onboarding.resources.showInRegisterDetails,
    method: 'get',
    url: `/onboarding/v1/organization/${params.id}`
  })
  return onboardingInRegisterDetailsApiSanitizer(data)
}
