export type LoginType = 'AUTOLOGIN' | 'CUSTOM' | 'HUB_AVA' | 'LINK' | 'LTI1.0' | 'OAUTH2' | 'SAML'

export enum ApplicationCategory {
  EDUCATION = 1,
  DEMO = 2,
  TEST = 3,
  BILLING = 4,
  COMPLEMENTARY = 5,
  HUB_LAB = 6,
  INTEGRATED = 7
}

export interface Application {
  id: number
  code: string
  name: string
  categoryId: ApplicationCategory
  loginType: LoginType | null
  shortDescription: string
  clientId: string
  iconUrl: string
  createdAt: string
  signatureMethod: string
  samlProviderToken: string | null
  signatureMethods: { SAML: { providerToken: string } } | null
  compatibleDevices: DevicesCompatibility[]
}

export interface DevicesCompatibility {
  name: string
  compatibleSystems: SystemsCompatibility[]
}

export interface SystemsCompatibility {
  name: string
  roles: string[]
}

export interface EdtechApplication {
  id: number
  name: string
  code: string
  clientId: string
  loginType: string | null
  iconUrl: string
  launchUrl: string
  launchMethod: string
  edtechId: number
  edtechName: string
  edtechCode: string
  classificationId: number
  categoryId: number
}

export type AutologinPosition = 'first' | 'last' | 'middle'

export interface AutologinMessageEvent {
  action: string
  error: { code: string; details: { position: AutologinPosition } }
}
