import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Image } from '@positivote/design-system/components/Image'
import { List } from '@positivote/design-system/components/List'
import { ListItem } from '@positivote/design-system/components/ListItem'
import { ProgressBar } from '@positivote/design-system/components/ProgressBar'
import { Typography } from '@positivote/design-system/components/Typography'
import { AddIcon } from '@positivote/design-system/icons/Add'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { WrappedLoader } from '@/common/components/WrappedLoader'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { SettingLicense } from '@/modules/hub/licenses/contracts/models'
import { checkProgressBarColor } from '@/modules/hub/licenses/helpers'
import { useListLicense, useUpdateLicense } from '@/modules/hub/licenses/hooks'

export interface AccessDistributionProps {
  licenseId: number
}

export function AccessDistribution({ licenseId }: AccessDistributionProps): JSX.Element {
  const updateLicense = useUpdateLicense()
  const navigate = useNavigate()
  const { isLoading: authIsLoading } = useAuth()
  const { data: licenses, isLoading: licenseIsLoading } = useListLicense({
    model: { licenseId },
    onError: () => {
      navigate(-1)
    }
  })
  const [settings, setSettings] = useState<SettingLicense[]>([])

  const isLoading = licenseIsLoading || authIsLoading

  useEffect(() => {
    setSettings(licenses?.settings ?? [])
  }, [licenses])

  function handleSelectAllLevels(): void {
    setSettings((oldSettings) =>
      oldSettings.map((license) => ({ ...license, isActive: Number(!isAllLevelsSelected) }))
    )
  }

  const isAllLevelsSelected = useMemo(() => {
    const activeSettings = settings.filter((setting) => setting.isActive)
    return activeSettings.length === settings.length
  }, [settings])

  const indeterminate = useMemo(() => {
    const activeSetting = settings.some((setting) => setting.isActive)
    return !isAllLevelsSelected && activeSetting
  }, [settings, isAllLevelsSelected])

  function handleSelectLevel(setting: SettingLicense): void {
    setSettings((oldSettings) =>
      oldSettings.map((license) =>
        license.id === setting.id ? { ...license, isActive: Number(!license.isActive) } : license
      )
    )
  }

  function handleSaveLicenses(): void {
    const parsedActiveSettings = settings
      .filter((setting) => setting.isActive)
      .map((setting) => ({ id: setting.id, name: setting.name }))
    updateLicense.mutate({ model: { licenseId, settings: parsedActiveSettings } })
  }

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '$lg',
        padding: '$lg',
        overflowY: 'auto',
        '@sm': { padding: '$md' }
      }}
    >
      {isLoading ? (
        <WrappedLoader />
      ) : (
        <>
          <Grid spacing="$lg">
            <Grid xl={7} md={12}>
              <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.cardTitle}
                </Typography>

                <List css={{ borderRadius: '$md' }}>
                  <ListItem
                    css={{ alignItems: 'center', justifyContent: 'space-between', padding: '$md' }}
                  >
                    <Checkbox
                      label={
                        i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution
                          .allLevels
                      }
                      onClick={handleSelectAllLevels}
                      indeterminate={indeterminate}
                      inputProps={{ checked: isAllLevelsSelected }}
                    />

                    <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                      {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.totalStudents(
                        licenses?.app.totalStudents ?? 0
                      )}
                    </Typography>
                  </ListItem>
                  <Divider />
                  {settings.map((setting) => (
                    <Fragment key={setting.id}>
                      <ListItem
                        css={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '$md'
                        }}
                      >
                        <Checkbox
                          label={setting.name}
                          inputProps={{ checked: !!setting.isActive }}
                          onClick={() => handleSelectLevel(setting)}
                        />
                        <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                          {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.totalStudents(
                            setting.students
                          )}
                        </Typography>
                      </ListItem>
                      <Divider css={{ '&:last-child': { display: 'none' } }} />
                    </Fragment>
                  ))}
                </List>
              </BaseCard>
            </Grid>
            <Grid xl={5} md={12}>
              <BaseCard
                css={{
                  '& .BaseCard-StateLayer': {
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Image
                  css={{
                    height: 80,
                    width: 80,
                    marginBottom: '$md',
                    borderRadius: '$full',
                    objectFit: 'contain'
                  }}
                  alt={
                    licenses?.app.name ??
                    i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution
                      .applicationImageAlt
                  }
                  src={licenses?.app.iconUrl}
                />
                <Typography
                  variant="titleMedium"
                  css={{ marginBottom: '$4xs', color: '$on-surface' }}
                >
                  {licenses?.app.name}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  css={{
                    marginBottom: '$md',
                    color: '$on-surface-variant'
                  }}
                >
                  {licenses?.app.shortDescription}
                </Typography>
                <Divider css={{ marginBottom: '$md' }} />
                <Typography
                  variant="bodyMedium"
                  css={{
                    marginBottom: '$md',
                    color: '$on-surface-variant'
                  }}
                >
                  {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.activeAccess(
                    {
                      totalAvailableLicenses: licenses?.app.totalAvailableLicenses ?? 0,
                      totalLicenses: licenses?.app.totalLicenses ?? 0
                    }
                  )}
                </Typography>

                <ProgressBar
                  filledPercentage={licenses?.app.totalLicensePercentFomated ?? 0}
                  css={{
                    width: '200px',
                    height: '8px',
                    '& .ProgressBar-Content': {
                      backgroundColor: checkProgressBarColor(
                        licenses?.app.totalLicensePercentFomated ?? 0
                      )
                    }
                  }}
                />
              </BaseCard>
            </Grid>
          </Grid>
          <Divider />
          <Div css={{ display: 'flex', justifyContent: 'end', paddingBottom: '$lg' }}>
            <Button
              LeadingIcon={<AddIcon size={18} />}
              onClick={handleSaveLicenses}
              isLoading={updateLicense.isPending}
              variant="filled"
            >
              {i18n().modules.hub.licenses.pages.applicationAccess.accessDistribution.applyChanges}
            </Button>
          </Div>
        </>
      )}
    </Div>
  )
}
