import { Navigate, Route, Routes } from 'react-router-dom'

import { ContractDetails } from './pages/Details'
import { ContractList } from './pages/List'

export function ContractRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ContractList />} />
      <Route path="/:id" element={<ContractDetails />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
