import * as yup from 'yup'

import { formatPtBrDateToIsoString, isValidDate } from '@/common/helpers'
import { i18n } from '@/common/i18n'

export function validateSchoolYearDateRange(initialDate: string, finalDate: string): boolean {
  const currentInitialDate = new Date(formatPtBrDateToIsoString(initialDate))
  const currentFinalDate = new Date(formatPtBrDateToIsoString(finalDate))
  return currentInitialDate < currentFinalDate
}

export const schoolYearDataValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators
        .requiredNameSchoolYear
    )
    .min(
      3,
      i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.minNameLength
    ),
  initialDate: yup
    .string()
    .required(
      i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.requiredDate(
        'initial'
      )
    )
    .test({
      name: 'validateDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateDate(
          'initial'
        ),
      test: (value) => {
        return isValidDate(value).isValid
      }
    })
    .test({
      name: 'validateRangeDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateRangeDate(
          'initial'
        ),
      test: (value, context) => {
        const parentWithType = context.parent as { finalDate: string }
        const finalDate = parentWithType.finalDate
        if (finalDate) {
          return validateSchoolYearDateRange(value, finalDate)
        }
      }
    }),

  finalDate: yup
    .string()
    .required(
      i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.requiredDate(
        'final'
      )
    )
    .test({
      name: 'validateDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateDate(
          'final'
        ),
      test: (value) => {
        return isValidDate(value).isValid
      }
    })
    .test({
      name: 'validateRangeDate',
      message:
        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.validateRangeDate(
          'final'
        ),
      test: (value, context) => {
        const parentWithType = context.parent as { initialDate: string }
        const initialDate = parentWithType.initialDate
        if (initialDate) {
          return validateSchoolYearDateRange(initialDate, value)
        }
      }
    }),
  isCopy: yup.boolean().optional(),
  schoolYear: yup
    .number()
    .optional()
    .when('isCopy', {
      is: true,
      then: () =>
        yup
          .number()
          .required(
            i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators
              .requiredSchoolYear
          )
    }),
  teacher: yup
    .boolean()
    .optional()
    .when('isCopy', {
      is: true,
      then: () =>
        yup
          .boolean()
          .required(
            i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators.requiredLink
          )
    })
})
