import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EmptySearch } from '@/common/components/EmptySearch'
import { MAX_PER_PAGE } from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { INPUT_SCROLL_DELAY_IN_MS } from '@/modules/hub/auth/constants'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ProfileCardButton } from '@/modules/hub/profiles/components/ProfileCardButton'
import { ShortProfile } from '@/modules/hub/profiles/contracts'
import { useListProfile } from '@/modules/hub/profiles/hooks'

export function ProfileList(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.profileList.pageTitle)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [searchText, setSearchText] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as { orgId?: number } | null
  const { changeProfile, isLoading: authIsLoading, whiteLabel, ssoApplication } = useAuth()
  const { data, isFetching: profileIsFetching } = useListProfile({
    ignoreLogout: true,
    model: {
      perPage: MAX_PER_PAGE,
      search: searchText || undefined,
      orgId: whiteLabel?.org.id ?? locationState?.orgId,
      appId: ssoApplication?.id
    },
    onError: () => {
      navigate('/', { replace: true, state: { keepLoginProps: true } })
    }
  })

  const isLoading = authIsLoading || profileIsFetching

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchText(value)
    })()
  }

  function handleSelectProfile(profile: ShortProfile): void {
    void changeProfile({ model: profile })
  }

  return (
    <BaseBackground>
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          minHeight: 400,
          width: '100%',
          position: 'relative'
        }}
      >
        <Grid
          ref={wrapperRef}
          xl={5}
          lg={6}
          md={7}
          sm={9}
          xs={12}
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            backgroundColor: '$surface-2',
            borderRadius: '$xl',
            overflow: 'hidden',
            position: 'absolute',
            height: '100%',
            // DOCS: calc(paddingTop + headerLabels + gap + textField + gap + (buttonHeight + gap) * registersLength || defaultLength + gap + button + paddingBottom)
            maxHeight: `calc($lg + 40px + $lg + 56px + $lg + (76px + $2xs) * ${data?.registers.length ? data.registers.length : 2} + $lg + 43px + $lg)`,
            '@sm': { padding: '$md' }
          }}
        >
          <Div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="titleSmall" css={{ color: '$on-surface', textAlign: 'center' }}>
              {i18n().modules.hub.auth.pages.profileList.title}
            </Typography>
            <Typography
              variant="bodySmall"
              css={{ color: '$on-surface-variant', textAlign: 'center', marginTop: '$4xs' }}
            >
              {i18n().modules.hub.auth.pages.profileList.subTitle}
            </Typography>
          </Div>
          <TextField
            label={i18n().modules.hub.auth.pages.profileList.searchInput}
            variant="outlined"
            leadingIcon={{ icon: SearchIcon }}
            css={{ marginTop: '$lg', '@sm': { marginTop: '$md' } }}
            inputProps={{
              // TODO: put these props by default in text fields input
              autoComplete: 'new-password',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              spellCheck: false,
              autoFocus: true,
              onChange: handleChangeSearchText,
              onFocus: () =>
                debounceEvent(
                  () => wrapperRef.current?.scrollIntoView({ behavior: 'smooth' }),
                  INPUT_SCROLL_DELAY_IN_MS
                )()
            }}
          />
          <Div
            css={{ flex: 1, position: 'relative', marginTop: '$lg', '@sm': { marginTop: '$md' } }}
          >
            <Div
              css={{
                display: isLoading ? 'flex' : 'block',
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflowY: 'auto'
              }}
            >
              {!data?.registers.length || isLoading ? (
                <Div
                  css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  {isLoading ? (
                    <Loader size="$5xl" />
                  ) : (
                    <EmptySearch
                      mascotProps={{ css: { height: '142px', width: '180px' } }}
                      titleVariant="titleMedium"
                    />
                  )}
                </Div>
              ) : (
                data.registers.map((profile) => (
                  <ProfileCardButton
                    key={profile.id}
                    profile={profile}
                    onClick={() => handleSelectProfile(profile)}
                  />
                ))
              )}
            </Div>
          </Div>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            disabled={isLoading}
            css={{ marginTop: '$lg', '@sm': { marginTop: '$md' } }}
          >
            {i18n().modules.hub.auth.pages.profileList.goBack}
          </Button>
        </Grid>
      </Div>
    </BaseBackground>
  )
}
