export function Mascot(): JSX.Element {
  return (
    <svg
      width="149"
      height="205"
      viewBox="0 0 149 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_23663_3)">
        <path
          d="M122.924 98.47C98.0625 104.039 86.0436 77.8055 77.1269 65.8704"
          stroke="#BFC4CE"
          strokeWidth="4.8626"
          strokeLinecap="round"
        />
        <ellipse
          cx="58.313"
          cy="188.467"
          rx="44.5244"
          ry="9.63766"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M93.7144 175.154C93.7144 173.966 94.6772 173.003 95.8649 173.003H106.697C107.885 173.003 108.848 173.966 108.848 175.154C108.848 176.342 107.885 177.305 106.697 177.305H95.8649C94.6772 177.305 93.7144 176.342 93.7144 175.154Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M17.9761 202.828C17.9761 201.641 18.9389 200.678 20.1266 200.678H30.959C32.1468 200.678 33.1096 201.641 33.1096 202.828C33.1096 204.016 32.1468 204.979 30.959 204.979H20.1266C18.9389 204.979 17.9761 204.016 17.9761 202.828Z"
          fill="#A2ACBE"
          fillOpacity="0.5"
        />
        <path
          d="M4.30826 114.468L5.38628 124.194C6.80543 136.995 15.4303 147.809 27.5179 151.938L30.0916 152.818C45.0514 157.929 61.1686 158.505 76.4551 154.475L79.085 153.782C91.4366 150.526 100.812 140.356 103.141 127.688L104.911 118.065C108.421 98.9564 109.397 79.463 107.813 60.0994L105.801 35.5242C104.761 22.8239 96.6114 11.8473 84.8271 7.27811C68.0017 0.755485 49.4956 0.0939319 32.2474 5.3985C20.1669 9.11459 11.2547 19.4791 9.31052 32.0728L5.5487 56.4416C2.5861 75.6447 2.16796 95.156 4.30627 114.466L4.30826 114.468Z"
          fill="#DAE0EC"
        />
        <path
          d="M102.462 40.5217L102.144 38.0989C101.042 29.7261 95.6401 23.1828 88.8262 21.9677L85.0393 21.2931C78.7822 20.1779 72.432 20.2041 66.1947 21.3726L59.5547 22.6159C52.6602 23.9062 47.3187 30.6653 46.4569 39.1913L46.2373 41.3627C45.5311 48.3395 45.688 55.4153 46.7028 62.3486C47.8847 70.4274 53.0771 76.734 59.6504 78.0785L64.4775 79.0658C72.4417 80.6941 80.5727 80.4624 88.4478 78.3809L90.4552 77.8516C96.8316 76.168 101.667 69.7821 102.589 61.8252L102.626 61.5121C103.433 54.5516 103.378 47.4761 102.464 40.5217L102.462 40.5217Z"
          fill="#545B6D"
        />
        <ellipse
          cx="2.98828"
          cy="2.98828"
          rx="2.98828"
          ry="2.98828"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 86.5542 46.2529)"
          fill="white"
        />
        <ellipse
          cx="3.38548"
          cy="3.38548"
          rx="3.38548"
          ry="3.38548"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 63.9097 44.8286)"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.2164 55.9431C85.4992 56.3006 85.4387 56.8196 85.0812 57.1024L81.4197 59.9991C81.0622 60.2819 80.5432 60.2214 80.2604 59.8639C79.9776 59.5064 80.0381 58.9873 80.3956 58.7045L84.0571 55.8079C84.4146 55.5251 84.9336 55.5856 85.2164 55.9431Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.318 56.4205C89.6008 56.778 89.5403 57.297 89.1828 57.5798L85.5213 60.4765C85.1638 60.7593 84.6447 60.6988 84.3619 60.3413C84.0791 59.9838 84.1397 59.4648 84.4972 59.182L88.1587 56.2853C88.5161 56.0025 89.0352 56.063 89.318 56.4205Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.2125 56.3937C66.4953 56.7511 66.4348 57.2702 66.0773 57.553L62.4158 60.4496C62.0583 60.7324 61.5393 60.6719 61.2565 60.3144C60.9737 59.957 61.0342 59.4379 61.3917 59.1551L65.0532 56.2585C65.4107 55.9756 65.9297 56.0362 66.2125 56.3937Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.2946 55.8194C61.5774 56.1769 61.5168 56.696 61.1594 56.9788L57.4978 59.8754C57.1404 60.1582 56.6213 60.0977 56.3385 59.7402C56.0557 59.3827 56.1162 58.8637 56.4737 58.5809L60.1352 55.6842C60.4927 55.4014 61.0118 55.462 61.2946 55.8194Z"
          fill="white"
          fillOpacity="0.1"
        />
        <rect
          width="20.3915"
          height="24.0825"
          rx="10.1957"
          transform="matrix(-0.99937 -0.0354915 -0.0354915 0.99937 42.4487 71.7699)"
          fill="#CCD3E0"
        />
        <ellipse
          cx="4.3696"
          cy="4.3696"
          rx="4.3696"
          ry="4.3696"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 35.6714 78.9509)"
          fill="#635F68"
        />
        <ellipse
          cx="5.09787"
          cy="5.09787"
          rx="5.09787"
          ry="5.09787"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 68.8315 88.5245)"
          fill="#B177D1"
        />
        <ellipse
          cx="1.45653"
          cy="1.45653"
          rx="1.45653"
          ry="1.45653"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 64.98 92.0948)"
          fill="white"
        />
        <rect
          width="12.4425"
          height="2.6338"
          rx="1.3169"
          transform="matrix(-0.999 0.0447005 -0.0913638 -0.995818 104.937 92.604)"
          fill="#CCD3E0"
        />
        <rect
          width="12.4425"
          height="2.6338"
          rx="1.3169"
          transform="matrix(-0.999 0.0447005 -0.0913638 -0.995818 104.937 96.9735)"
          fill="#CCD3E0"
        />
        <rect
          width="12.4425"
          height="2.6338"
          rx="1.3169"
          transform="matrix(-0.999 0.0447005 -0.0913638 -0.995818 104.937 88.2342)"
          fill="#CCD3E0"
        />
        <rect
          width="14.8285"
          height="10.8217"
          rx="2.08397"
          transform="matrix(0.115413 -0.993318 -0.993318 -0.115413 129.976 106.913)"
          fill="#9EA3AC"
        />
        <rect
          x="131.367"
          y="107.184"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 131.367 107.184)"
          fill="#A49EA9"
        />
        <rect
          x="134.164"
          y="107.276"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 134.164 107.276)"
          fill="#9EA3AC"
        />
        <rect
          x="133.085"
          y="102.219"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 133.085 102.219)"
          fill="#A49EA9"
        />
        <rect
          x="136.847"
          y="102.108"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 136.847 102.108)"
          fill="#9EA3AC"
        />
        <rect
          x="132.757"
          y="96.9772"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 132.757 96.9772)"
          fill="#A49EA9"
        />
        <rect
          x="136.281"
          y="96.887"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 136.281 96.887)"
          fill="#9EA3AC"
        />
        <path
          d="M58.4513 7.1324C42.1812 5.95355 27.865 14.6218 23.9462 20.0731C23.5742 20.5905 24.1103 21.1105 24.6846 20.8343C40.8484 13.06 75.8118 8.39026 58.4513 7.1324Z"
          fill="#F9F4FF"
        />
        <ellipse
          cx="2.1848"
          cy="2.1848"
          rx="2.1848"
          ry="2.1848"
          transform="matrix(-0.987302 0.158854 0.158854 0.987302 19.937 22.8657)"
          fill="#F9F4FF"
        />
        <path
          d="M76.8821 145.433C85.0085 147.444 100.046 131.716 99.7088 113.397C99.6945 112.623 98.6168 112.531 98.3706 113.265C93.5154 127.74 80.0003 141.202 76.6546 144.38C76.2945 144.722 76.3999 145.314 76.8821 145.433Z"
          fill="#CCD3E0"
        />
        <rect
          x="133.296"
          y="70.4509"
          width="16.0219"
          height="34.9568"
          rx="1.38931"
          transform="rotate(18.3388 133.296 70.4509)"
          fill="#505050"
        />
        <rect
          x="133.757"
          y="73.6742"
          width="10.1957"
          height="27.6742"
          transform="rotate(18.3388 133.757 73.6742)"
          fill="white"
        />
        <ellipse
          cx="127.825"
          cy="103.162"
          rx="0.728267"
          ry="0.728267"
          transform="rotate(18.3388 127.825 103.162)"
          fill="#7E7E7E"
        />
        <path
          d="M32.2495 82.6984C27.6372 96.0499 19.432 126.831 23.5103 143.145"
          stroke="#BFC4CE"
          strokeWidth="4.8626"
          strokeLinecap="round"
        />
        <rect
          width="14.8285"
          height="10.8217"
          rx="2.08397"
          transform="matrix(0.948575 -0.316551 -0.316551 -0.948575 17.6987 151.55)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 18.0386 158.209)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 28.9292 156.064)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.942584 -0.333968 -0.333968 -0.942584 32.687 145.072)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 18.855 161.361)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 24.0425 158)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 24.8667 161.153)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.978404 -0.2067 -0.2067 -0.978404 29.7456 159.217)"
          fill="#9EA3AC"
        />
        <rect
          x="124.257"
          y="90.6327"
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="rotate(-87.9322 124.257 90.6327)"
          fill="#9EA3AC"
        />
        <rect
          width="4.17303"
          height="7.04199"
          rx="2.08651"
          transform="matrix(0.942584 -0.333968 -0.333968 -0.942584 18.8784 148.991)"
          fill="#878C96"
        />
        <circle cx="73.5853" cy="59.9086" r="2.12872" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_23663_3">
          <rect
            width="148.001"
            height="204.958"
            fill="white"
            transform="translate(0.499512 0.0209961)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
