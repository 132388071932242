import { jsPDF as JsPDF } from 'jspdf'
import JSZip from 'jszip'
import { ChangeEvent } from 'react'

import { ApplicationException } from '@/common/exceptions'
import { i18n } from '@/common/i18n'

import { urlRegex } from './regex'
import { downloadFile } from './window'

export async function fileToBase64(event: ChangeEvent<HTMLInputElement>): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64String = reader.result as string
      resolve(base64String)
    }

    reader.onerror = () => {
      reject(new Error('Failed to read the file.'))
    }

    if (event.target.files) {
      reader.readAsDataURL(event.target.files[0])
    }
  })
}

export function validateMaxFileSize(size: number, maxMbSize: number): boolean {
  const mbSize = 1024
  const fileMbSize = Math.round(size / mbSize)
  return fileMbSize > maxMbSize
}

export function validateBase64OrUrl(
  src: string,
  types: string[] = ['image/png', 'image/jpg']
): string | undefined {
  const parsedTypes = types.map((type) => `data:${type};base64`)
  const isBase64 = parsedTypes.some((type) => src.startsWith(type))
  const isUrl = urlRegex.test(src)
  return isBase64 || isUrl ? src : undefined
}

export function parseFileName(fileName: string, contentType?: string | null): string {
  const [type] = contentType?.split('/')[1]?.split(/,|;/) ?? []
  return `${fileName.replace(/\//g, '_')}${type ? `.${type}` : ''}`
}

export async function downloadLink(
  files: Array<{ name: string; link: string }>,
  fileName: string
): Promise<void> {
  files.sort((prev, curr) => (prev.name < curr.name ? -1 : 1))
  const blobFiles = await Promise.all(
    files.map(async (file, index) => {
      const response = await fetch(file.link)
      if (response.status >= 300 || response.status < 200) {
        throw new ApplicationException({})
      }
      return {
        name: parseFileName(
          `${files.length > 1 ? `${index + 1}_` : ''}${file.name}`,
          response.headers.get('Content-Type')
        ),
        data: await response.blob()
      }
    })
  )

  if (blobFiles.length === 1) {
    downloadFile(blobFiles[0].data, blobFiles[0].name)
  } else {
    const jsZip = new JSZip()
    blobFiles.forEach((blobFile) => {
      jsZip.file(blobFile.name, blobFile.data)
    })
    const zipFile = await jsZip.generateAsync({ type: 'blob' })
    downloadFile(zipFile, parseFileName(fileName, 'zip'))
  }
}

export function base64PNGToPDF(base64ImageData: string, pdfFileName: string): void {
  try {
    const base64WithoutHeader = base64ImageData.replace(/^data:image\/png;base64,/, '')
    const a4Height = 297
    const a4Width = 210
    const pdf = new JsPDF()

    pdf.addImage(base64WithoutHeader, 'PNG', 0, 0, a4Width, a4Height)

    const pdfBlob = pdf.output('blob')
    const link = document.createElement('a')

    link.href = URL.createObjectURL(pdfBlob)
    link.download = pdfFileName

    link.click()
  } catch (error) {
    throw new ApplicationException({ message: i18n().common.file.base64PNGToPDFError })
  }
}
