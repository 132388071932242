import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import { ListCityServiceParams, ListStateApiResult, ListStateServiceResult } from './contracts'
import { listStateFormatSanitizer } from './sanitizers'

export async function listStateService(): Promise<ListStateServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<null, ListStateApiResult>({
    resource: i18n().modules.hub.locales.resources.listState,
    method: 'get',
    // TODO: Put current country instead of hardcoded "BR"
    url: '/admin/v1/locales/BR'
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: listStateFormatSanitizer(data)
    })
  }
}

export async function listCityService({
  state
}: ListCityServiceParams): Promise<ListStateServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<null, ListStateApiResult>({
    resource: i18n().modules.hub.locales.resources.listCity,
    method: 'get',
    // TODO: Put current country instead of hardcoded "BR"
    url: `/admin/v1/locales/BR/${state}`
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: listStateFormatSanitizer(data)
    })
  }
}
