import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

export const authPt = {
  role: {
    STUDENT: 'Aluno',
    RESPONSIBLE: 'responsavel',
    TEACHER: 'Professor',
    EDTECH: 'Edtech',
    INSTITUTION: 'Instituição',
    TE: 'TE',
    DIRECTOR: 'Diretor',
    COORDINATOR: 'Coordenador',
    ADMINISTRATOR: 'Administrador',
    CONTRIBUTOR: 'Colaborador',
    ...MONITOORA_ROLES.reduce(
      (prevRoles, role) => ({ ...prevRoles, [role.toUpperCase()]: 'Monitoora' }),
      {}
    ),
    BILLING: 'Billing',
    INTERNAL_ADMINISTRATOR: 'Administrador Interno',
    INTERNAL_SUPPORT: 'Suporte Interno'
  },
  resources: {
    createSession: 'Criar sessão',
    showSession: 'Carregar sessão',
    recoverPassword: 'Recuperação de Senha',
    changePassword: 'Alterar senha'
  },
  components: {
    loginSupport: {
      title: 'Login Suporte',
      subTitle: 'Insira os dados da instituição e da conta do usuário que deseja acessar:',
      inputs: {
        organizationId: 'Selecione a instituição',
        userId: {
          label: 'Conta do usuário',
          supportingText: 'Selecione uma instituição antes',
          codLogin: (login?: string): string => `Código Login: ${login}`
        }
      },
      buttons: {
        login: 'Efetuar Login',
        cancel: 'Cancelar'
      },
      errors: {
        neverLoginBefore:
          'Esse usuário ainda não realizou seu primeiro login ou não possui conta criada no Cognito'
      }
    }
  },
  pages: {
    login: {
      pageTitle: 'Login',
      title: 'Para continuar, insira seu login',
      titleSso: 'Utilize seu login do Hub Educacional para iniciar a sessão em',
      subTitleSso: 'Você está acessando a',
      discoverTheApplication: (applicationName: string): string => `Conheça o ${applicationName}`,
      inputs: {
        username: 'E-mail ou Usuário de Acesso',
        password: 'Senha'
      },
      buttons: {
        forgotPassword: 'Esqueci minha senha',
        next: 'Avançar'
      },
      dividerOrLabel: 'ou',
      footerCards: {
        google: 'Continue com Google',
        microsoft: 'Continue com Microsoft',
        qrCode: 'Acesso via QR Code',
        quickAccess: 'Acesso Rápido'
      },
      errors: {
        wrongUserOrPassword: 'E-mail, Usuário de Acesso ou Senha incorretos',
        profileNotFound: 'Este login não possui perfil nesta instituição ou aplicação',
        loginMicrosoft: 'Ocorreu um problema ao tentar fazer login com a Microsoft',
        loginGoogle: 'Ocorreu um problema ao tentar fazer login com o Google',
        inactiveWhiteLabel: 'Customização de escola inativa',
        clientId:
          'Id do cliente (applicationClientId ou client_id) deve ser informado nos parâmetros de URL',
        launchMethod:
          'Método de lançamento (launchMethod ou launch_method) deve ser informado "post" ou "get" nos parâmetros de URL',
        responseType:
          'Tipo de resposta (responseType ou response_type) deve ser informado "code", "access_token" ou "id_token" nos parâmetros de URL',
        scope: 'Escopo (scope) deve ser informado nos parâmetros de URL'
      }
    },
    profileList: {
      pageTitle: 'Selecione o perfil',
      title: 'Selecione o perfil de acesso para continuar',
      subTitle:
        'Você poderá trocar o perfil de acesso a qualquer momento clicando no seu perfil pelo menu lateral.',
      searchInput: 'Pesquisar escola ou perfil',
      goBack: 'Voltar ao início'
    },
    organizationList: {
      pageTitle: 'Selecionar escola',
      title: 'Para continuar, preencha o campo abaixo',
      searchInput: {
        label: 'Que escola você quer acessar?',
        error: 'Acesso não autorizado a essa escola ou credências inválidas'
      },
      buttons: {
        goBack: 'Voltar ao início',
        next: 'Avançar'
      }
    },
    recoverPassword: {
      pageTitle: 'Recuperar senha',
      title: 'Recupere sua senha',
      subTitle:
        'Enviaremos um código para seu e-mail para que você possa cadastrar uma nova senha de acesso no Hub Educacional.',
      alert: {
        sendRecoveryCode: 'Código de Recuperação de Senha enviado por e-mail!'
      },
      inputs: {
        email: 'Digite seu e-mail'
      },
      buttons: {
        goBack: 'Voltar ao início',
        send: 'Enviar'
      }
    },
    changePassword: {
      pageTitle: 'Confirmar código',
      title: 'Confirmação de código',
      subTitle:
        'Insira abaixo o código de recuperação recebido por e-mail e em seguida sua nova senha. A senha deve ter no mínimo 6 caracteres, podendo conter números, letras e caracteres especiais (!@#$%&.).',
      alert: {
        successChangePassword: 'Senha alterada com sucesso!'
      },
      inputs: {
        recoverCode: 'Código de recuperação',
        newPassword: 'Nova senha',
        confirmNewPassword: 'Repita sua nova senha'
      },
      buttons: {
        goBack: 'Voltar ao início',
        continue: 'Continuar'
      },
      successChangePassword: {
        title: 'Senha alterada',
        subTitle: 'Você já pode acessar a plataforma com a sua nova senha.',
        goBack: 'Voltar ao login'
      }
    },
    resetPassword: {
      pageTitle: 'Senha temporária',
      title: 'Você usou sua senha temporária',
      subTitle:
        'Para finalizar seu cadastro, crie uma nova senha de no mínimo 6 caracteres, podendo conter números, letras e caracteres especiais (!@#$%&.).',
      alert: {
        successChangePassword: 'Senha alterada com sucesso. Aguarde enquanto o login é realizado'
      },
      inputs: {
        newPassword: 'Nova senha',
        confirmNewPassword: 'Repita sua nova senha'
      },
      buttons: {
        goBack: 'Voltar ao início',
        save: 'Salvar'
      }
    },
    qrCode: {
      pageTitle: 'QR Code',
      title: 'Acesso por QR Code',
      error:
        'O Hub Educacional necessita da permissão de uso da câmera para o login por QR Code.\nSe não funcionar, tente outro navegador ou reinicie o aplicativo.',
      instructions: {
        title: 'Instruções',
        one: 'Habilite a sua câmera',
        two: 'Certifique-se de que o local tem boa iluminação',
        three: 'Aponte seu QR Code para a câmera'
      },
      notWorkingDialog: {
        title: 'QR Code não funciona',
        contentTexts: [
          'Verifique se o seu código QR é válido. Escanear outros códigos que não sejam os do login não habilitará o acesso.',
          'Lembre-se: para utilizar a função de login por QR Code é necessário que seu dispositivo esteja com a permissão de uso da câmera habilitado.',
          'Verifique nas configurações do seu navegador ou reinicie o aplicativo.'
        ],
        acceptAction: 'Fechar'
      },
      buttons: {
        qrCodeNotWorking: 'Meu QR Code não funciona',
        goBack: 'Voltar ao início'
      }
    },
    quickAccess: {
      pageTitle: 'Acesso Rápido',
      title: 'Acesso rápido',
      instructions: 'Insira o código gerado pelo professor para acessar:',
      inputs: {
        username: 'Usuário de Acesso'
      },
      buttons: {
        goBack: 'Voltar',
        continue: 'Avançar'
      },
      error: {
        usernameRequired: 'Nome de usuário é obrigatório.',
        codeEqual6Chars: 'O código deve ter exatamente 6 caracteres.',
        expiredCode: 'Este código expirou.',
        generateCode: 'Peça para o(a) professor(a) gerar outro código para sua turma.',
        invalidCode: 'Usuário de Acesso ou Código incorretos.'
      },
      loadingDialog: {
        title: 'Carregando Hub Educacional',
        subTitle: 'Está demorando demais? Entre em contato'
      }
    }
  }
}
