import { defaultDateIsoStringMask } from '@/common/helpers'

import { CreateQuickAccessApiResult } from './contracts/api'
import { QuickAccessFormatted } from './contracts/models'

export function fromQuickAccessApiSanitizer(
  quickAccess: CreateQuickAccessApiResult
): QuickAccessFormatted {
  return {
    id: quickAccess.id,
    classId: quickAccess.class_id,
    profileId: quickAccess.profile_id,
    code: quickAccess.code,
    createdAtTimeFormatted: new Date(defaultDateIsoStringMask(quickAccess.created_at)),
    expiresAtTimeFormatted: new Date(defaultDateIsoStringMask(quickAccess.expires_at)),
    duration: quickAccess.duration,
    expiresAt: quickAccess.expires_at,
    createdAt: quickAccess.created_at,
    updatedAt: quickAccess.updated_at
  }
}
