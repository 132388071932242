import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Main } from '@positivote/design-system/components/Main'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { MAX_PER_PAGE } from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { Footer } from '@/common/layouts/Footer'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ProfileCardButton } from '@/modules/hub/profiles/components/ProfileCardButton'
import { ShortProfile } from '@/modules/hub/profiles/contracts'
import { useListProfile } from '@/modules/hub/profiles/hooks'

export function ProfileList(): JSX.Element {
  changePageTitle(i18n().modules.hub.profiles.pages.list.pageTitle)

  const [searchText, setSearchText] = useState('')
  const [selectedProfile, setSelectedProfile] = useState<ShortProfile | null>()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const { profile, isLoading: authIsLoading, changeProfile } = useAuth()
  const { data, isFetching: profileIsFetching } = useListProfile({
    model: {
      perPage: MAX_PER_PAGE,
      search: searchText || undefined
    },
    onError: () => {
      navigate(-1)
    }
  })

  const isLoading = authIsLoading || profileIsFetching

  function onAccept(): void {
    if (selectedProfile && selectedProfile.id !== profile?.id) {
      void changeProfile({ model: selectedProfile })
    }
    handleClose()
  }

  function handleOpen(newProfile: ShortProfile): void {
    setSelectedProfile(newProfile)
    setIsModalOpen(true)
  }

  function handleClose(): void {
    setSelectedProfile(null)
    setIsModalOpen(false)
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setSearchText(value)
    })()
  }

  function ProfileCard(): React.ReactNode {
    return (
      profile && (
        <Grid xl={4} sm={12}>
          <Typography
            variant="titleLarge"
            css={{ marginBottom: '$md', color: '$on-surface', textAlign: 'center' }}
          >
            {i18n().modules.hub.profiles.pages.list.loggedProfile}
          </Typography>
          <BaseCard
            css={{
              '& .BaseCard-StateLayer': {
                justifyContent: 'center',
                alignItems: 'center',
                padding: '$lg'
              }
            }}
          >
            <IconWrapper
              size={80}
              css={{ backgroundColor: '$primary-container', marginBottom: '$md' }}
            >
              <Image
                alt={profile.name}
                src={profile.picture}
                FallbackImage={() => <PersonIcon size={48} fill="$on-primary-container" />}
                css={{
                  width: 80,
                  objectFit: 'cover',
                  objectPosition: 'center',
                  borderRadius: '$full'
                }}
              />
            </IconWrapper>
            <Typography
              variant="titleLarge"
              css={{ color: '$on-surface', marginBottom: '$md', textAlign: 'center' }}
            >
              {profile.role.name}
            </Typography>
            <Typography
              variant="titleMedium"
              css={{ color: '$on-surface', marginBottom: '$2xs', textAlign: 'center' }}
            >
              {profile.organization.name}
            </Typography>
            <Typography
              variant="bodyMedium"
              css={{ color: '$on-surface-variant', textAlign: 'center' }}
            >
              {profile.organization.address}
            </Typography>
          </BaseCard>
        </Grid>
      )
    )
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
      <TextDialog
        isOpen={isModalOpen}
        onCancel={handleClose}
        title={{ label: i18n().modules.hub.profiles.pages.list.confirmModal.title }}
        contentTexts={[i18n().modules.hub.profiles.pages.list.confirmModal.content]}
        refuseAction={{
          handle: handleClose,
          label: i18n().modules.hub.profiles.pages.list.confirmModal.refuse
        }}
        acceptAction={{
          handle: onAccept,
          label: i18n().modules.hub.profiles.pages.list.confirmModal.accept,
          icon: <CheckCircleIcon size={18} />
        }}
      />
      <AppBar
        title={i18n().modules.hub.profiles.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        <Div css={{ display: 'flex', flex: 1, padding: '$lg', '@sm': { padding: '$md' } }}>
          <Grid spacing="$lg">
            {/** DOCS: wrap reverse or row-reverse do not works properly */}
            {breakpoint <= Breakpoint.sm && <ProfileCard />}

            <Grid xl={8} sm={12}>
              <BaseCard
                css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg', minHeight: 300 } }}
              >
                <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.profiles.pages.list.profileClick}
                </Typography>
                <TextField
                  variant="outlined"
                  leadingIcon={{ icon: SearchIcon }}
                  label={i18n().modules.hub.profiles.pages.list.searchInput}
                  inputProps={{ onChange: handleChangeSearchText }}
                />

                {isLoading && <WrappedLoader />}
                {!isLoading && searchText && !data?.registers.length && (
                  <Div
                    css={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <EmptySearch
                      mascotProps={{ css: { height: '142px', width: '180px' } }}
                      titleVariant="titleMedium"
                    />
                  </Div>
                )}
                {!isLoading && !!data?.registers.length && (
                  <Div css={{ maxHeight: 360, overflowY: 'auto' }}>
                    {data.registers.map((newProfile) => (
                      <ProfileCardButton
                        key={newProfile.id}
                        profile={newProfile}
                        selected={profile && newProfile.id === profile.id}
                        onClick={() =>
                          profile && newProfile.id !== profile.id && handleOpen(newProfile)
                        }
                      />
                    ))}
                  </Div>
                )}
              </BaseCard>
            </Grid>

            {/** DOCS: wrap reverse or row-reverse do not works properly */}
            {breakpoint > Breakpoint.sm && <ProfileCard />}
          </Grid>
        </Div>
        {breakpoint === Breakpoint.xs && <Footer />}
      </Div>
    </Main>
  )
}
