import { Navigate, Route, Routes } from 'react-router-dom'

import { ServiceMappingForm } from './pages/Form'
import { ServiceMappingList } from './pages/List'

export function ServiceMappingRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ServiceMappingList />} />
      <Route path="/form" element={<ServiceMappingForm />} />
      <Route path="/form/:id" element={<ServiceMappingForm />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
