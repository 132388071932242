import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function SupervisedUserCircleIcon({
  fill,
  size = '$lg',
  css,
  ...restProps
}: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.61 8.34C16.68 8.34 17.54 9.2 17.54 10.27C17.54 11.34 16.68 12.2 15.61 12.2C14.54 12.2 13.68 11.34 13.68 10.27C13.67 9.2 14.54 8.34 15.61 8.34ZM9.61 6.76C10.91 6.76 11.97 7.82 11.97 9.12C11.97 10.42 10.91 11.48 9.61 11.48C8.31 11.48 7.25 10.42 7.25 9.12C7.25 7.81 8.3 6.76 9.61 6.76ZM9.61 15.89V19.64C7.21 18.89 5.31 17.04 4.47 14.68C5.52 13.56 8.14 12.99 9.61 12.99C10.14 12.99 10.81 13.07 11.51 13.21C9.87 14.08 9.61 15.23 9.61 15.89ZM12 20C11.73 20 11.47 19.99 11.21 19.96V15.89C11.21 14.47 14.15 13.76 15.61 13.76C16.68 13.76 18.53 14.15 19.45 14.91C18.28 17.88 15.39 20 12 20Z"
        css={{ fill }}
      />
    </Svg>
  )
}
