import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { LI } from '@positivote/design-system/components/LI'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { LinkIcon } from '@positivote/design-system/icons/Link'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SchoolIcon } from '@positivote/design-system/icons/School'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { parseValidDateOrDefault } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ClassroomFormatted } from '@/modules/hub/classroom/contracts/models'
import { AssingDisciplineDialog } from '@/modules/hub/disciplines/components/AssingDisciplineDialog/component'
import { DisciplineSelectForm } from '@/modules/hub/disciplines/contracts'
import { Teacher } from '@/modules/hub/users/contracts'

interface ListTeachersProps {
  isForm?: boolean
  data: Teacher[]
  originalData: Teacher[]
  onDataChange?: (updatedData: Teacher[]) => void
}

export function ListTeachers({
  isForm = false,
  data,
  originalData,
  onDataChange
}: ListTeachersProps): JSX.Element {
  const navigate = useNavigate()

  const location = useLocation()
  const locationState = location.state as {
    classroom: ClassroomFormatted
    schoolYear: { name: string }
  } | null

  const [openAssingDiscipline, setOpenAssingDiscipline] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher>()

  function handleOpenAssingDiscipline(teacher: Teacher): void {
    setSelectedTeacher(teacher)
    setOpenAssingDiscipline(true)
  }

  function selectDiscipline(value: DisciplineSelectForm): void {
    const teacherFind = originalData.find((teacher) => teacher.id === selectedTeacher?.id)
    if (teacherFind) {
      teacherFind.courses = value.disciplines
    }

    if (onDataChange) {
      onDataChange(originalData)
    }
  }

  function removeDiscipline(teacherId: number, disciplineId: number): void {
    const updatedData = originalData.map((teacher) => {
      if (teacher.id === teacherId) {
        return {
          ...teacher,
          courses: teacher.courses.filter((course) => course.id !== disciplineId)
        }
      }
      return teacher
    })

    if (onDataChange) {
      onDataChange(updatedData)
    }
  }

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '$2xs',
        padding: '$md',
        overflowY: 'auto',
        borderWidth: '$thin',
        borderStyle: 'solid',
        borderRadius: '$xl',
        borderColor: '$outline-variant'
      }}
    >
      <AssingDisciplineDialog
        isOpen={openAssingDiscipline}
        onClose={() => setOpenAssingDiscipline(false)}
        teacher={selectedTeacher}
        selectDiscipline={selectDiscipline}
      />
      <Div css={{ display: 'flex', gap: '$2xs', flexDirection: 'column' }}>
        <Div
          css={{
            display: 'flex',
            gap: '$2xs',
            alignSelf: 'stretch',
            alignItems: 'center'
          }}
        >
          <SchoolIcon />
          <Typography variant="titleLarge" css={{ color: '$on-surface', flex: '1 0 0' }}>
            {i18n().modules.hub.classroom.pages.enrollments.teacher.list.title}
          </Typography>
          {!isForm && (
            <Button
              variant="tonal"
              LeadingIcon={<EditIcon size={18} />}
              css={{ alignSelf: 'end' }}
              onClick={() =>
                navigate(
                  '/data-management/levels-and-classes/classroom/classroom-users/form-teacher',
                  {
                    state: {
                      classroom: locationState?.classroom,
                      schoolYear: {
                        name: locationState?.schoolYear.name
                      },
                      beforeHistory: 'teacher-list'
                    }
                  }
                )
              }
            >
              {i18n().modules.hub.classroom.pages.enrollments.teacher.list.editTeachers}
            </Button>
          )}
        </Div>
        <Grid spacing="$md" css={{ padding: '$md $lg' }}>
          <Grid
            xl={4}
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '$md',
              paddingTop: 0,
              '& .Grid-Container': {
                paddingTop: '0px'
              }
            }}
          >
            <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
              {i18n().modules.hub.classroom.pages.enrollments.teacher.list.teachersName}
            </Typography>
          </Grid>
          {!isForm && (
            <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
              <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
                {i18n().modules.hub.classroom.pages.enrollments.teacher.list.enrollmentDate}
              </Typography>
            </Grid>
          )}
          <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
            <Typography variant="titleMedium" lineClamp={1} css={{ color: '$on-surface' }}>
              {i18n().modules.hub.classroom.pages.enrollments.teacher.list.discipline}
            </Typography>
          </Grid>
        </Grid>
      </Div>
      <Div css={{ overflow: 'scroll' }}>
        <UL
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$sm'
          }}
        >
          {data.map((teacher) => (
            <BaseCard
              key={teacher.id}
              css={{
                borderRadius: '$lg',
                backgroundColor: '$surface-1',
                '& .BaseCard-StateLayer': {
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '$lg',
                  padding: '$sm $md'
                }
              }}
            >
              <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                <Grid xl={4} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                  <Image
                    alt={teacher.name}
                    FallbackImage={() => (
                      <IconWrapper size="$2xl" css={{ backgroundColor: '$primary-container' }}>
                        <PersonIcon fill="$on-primary-container" />
                      </IconWrapper>
                    )}
                    src={teacher.picture}
                    css={{
                      height: '$2xl',
                      width: '$2xl',
                      borderRadius: '$full',
                      objectFit: 'cover'
                    }}
                  />
                  <Typography lineClamp={1} variant="bodyMedium" css={{ color: '$on-surface' }}>
                    {teacher.name}
                  </Typography>
                </Grid>
                {!isForm && (
                  <Grid xl={2} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                    <Typography lineClamp={2} variant="bodyMedium" css={{ color: '$on-surface' }}>
                      {parseValidDateOrDefault(teacher.lastEnrollmentDate)}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  xl={5}
                  css={{ display: 'flex', gap: '$2xs', ...(isForm && { flexWrap: 'wrap' }) }}
                >
                  {!isForm ? (
                    <>
                      {teacher.courses.slice(0, 3).map((course) => (
                        <Tooltip key={course.id} placement="top" arrow label={course.name}>
                          <Chip
                            label={course.name}
                            color="neutral"
                            size="sm"
                            variant="outlined"
                            css={{ lineClamp: 1, maxWidth: '40%' }}
                          />
                        </Tooltip>
                      ))}
                      {teacher.courses.length > 3 && (
                        <Tooltip
                          placement="top"
                          arrow
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          label={
                            <UL>
                              {teacher.courses.slice(3).map((course, index) => (
                                <LI
                                  key={index}
                                  css={{ color: '$inverse-on-surface', marginLeft: 14 }}
                                >
                                  <Typography variant="labelMedium">{course.name}</Typography>
                                </LI>
                              ))}
                            </UL>
                          }
                        >
                          <Chip
                            label={`+${teacher.courses.slice(3).length}`}
                            color="neutral"
                            size="sm"
                            variant="outlined"
                          />
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    teacher.courses.map((course) => (
                      <Chip
                        key={course.id}
                        label={course.name}
                        color="neutral"
                        size="sm"
                        variant="outlined"
                        css={{ lineClamp: 1, '& .Chip-Label': { maxWidth: 'unset' } }}
                        onRemove={() => {
                          removeDiscipline(teacher.id, course.id)
                        }}
                      />
                    ))
                  )}
                </Grid>

                {isForm && (
                  <Grid
                    xl={3}
                    css={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '$md'
                    }}
                  >
                    <LinkButton
                      LeadingIcon={<LinkIcon size={18} />}
                      onClick={() => handleOpenAssingDiscipline(teacher)}
                    >
                      {i18n().modules.hub.classroom.pages.enrollments.teacher.list.assingDiscipline}
                    </LinkButton>
                  </Grid>
                )}
              </Grid>
            </BaseCard>
          ))}
        </UL>
      </Div>
    </Div>
  )
}
