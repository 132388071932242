import { ShowLtiApplicationApiResult } from './contracts/apis'
import { LtiApplication } from './contracts/models'

export function fromShowLtiApplicationApiSanitizer(
  applications: ShowLtiApplicationApiResult
): LtiApplication {
  return {
    id: applications.id,
    code: applications.code,
    clientId: applications.client_id,
    oAuth2ClientId: applications.oauth2_client_id,
    name: applications.name,
    shortDescription: applications.short_description,
    iconUrl: applications.icon_url,
    siteUrl: applications.site_url
  }
}
