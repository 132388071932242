import * as yup from 'yup'

import { formatPtBrDateToIsoString, isDateSmallerToNow, validateDateRange } from '@/common/helpers'
import { i18n } from '@/common/i18n'

export const generalInformationSchema = yup.object().shape({
  organization: yup.string().required(i18n().common.validators.required),
  initialDate: yup.string().required(i18n().common.validators.required),
  finalDate: yup
    .string()
    .required(i18n().common.validators.required)
    .test({
      name: 'validateDateRange',
      message: i18n().common.validators.validateDateRange,
      test: (value, context) => {
        const parentWithType = context.parent as { initialDate: string }
        const initialDate = parentWithType.initialDate
        return validateDateRange(initialDate, value)
      }
    })
    .test({
      name: 'validateLaterDate',
      message: i18n().common.validators.validateLaterDate,
      test: (date) => isDateSmallerToNow(formatPtBrDateToIsoString(date))
    })
})

export const devicesFilterSchema = yup.object().shape({
  organization: yup.string().required(i18n().common.validators.required),
  situation: yup.string().required(i18n().common.validators.required),
  initialDate: yup.string().required(i18n().common.validators.required),
  finalDate: yup
    .string()
    .required(i18n().common.validators.required)
    .test({
      name: 'validateDateRange',
      message: i18n().common.validators.validateDateRange,
      test: (value, context) => {
        const parentWithType = context.parent as { initialDate: string }
        const initialDate = parentWithType.initialDate
        return validateDateRange(initialDate, value)
      }
    })
    .test({
      name: 'validateLaterDate',
      message: i18n().common.validators.validateLaterDate,
      test: (date) => isDateSmallerToNow(formatPtBrDateToIsoString(date))
    })
})

export const devicesSearchSchema = yup.object().shape({
  searchDevice: yup.string().required()
})

export const devicesDetailsSchema = yup.object().shape({
  period: yup.number().required(i18n().common.validators.required),
  initialDate: yup.string().required(i18n().common.validators.required),
  finalDate: yup
    .string()
    .required(i18n().common.validators.required)
    .test({
      name: 'validateDateRange',
      message: i18n().common.validators.validateDateRange,
      test: (value, context) => {
        const parentWithType = context.parent as { initialDate: string }
        const initialDate = parentWithType.initialDate
        return validateDateRange(initialDate, value)
      }
    })
    .test({
      name: 'validateLaterDate',
      message: i18n().common.validators.validateLaterDate,
      test: (date) => isDateSmallerToNow(formatPtBrDateToIsoString(date))
    })
})

export const setupSchema = yup.object().shape({
  clientId: yup.string().required(i18n().common.validators.required),
  secret: yup.string().required(i18n().common.validators.required),
  customerId: yup.string().required(i18n().common.validators.required)
})
