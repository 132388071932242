import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'

import {
  ShowEngagementPerformanceApiParams,
  ShowEngagementPerformanceApiResult
} from './contracts/apis'
import {
  ShowEngagementPerformanceServiceParams,
  ShowEngagementPerformanceServiceResult
} from './contracts/services'

export async function showEngagementPerformanceService(
  params: ShowEngagementPerformanceServiceParams
): Promise<ShowEngagementPerformanceServiceResult> {
  const { data } = await hubApiService<
    ShowEngagementPerformanceApiParams,
    ShowEngagementPerformanceApiResult
  >({
    resource: i18n().modules.hub.dataIntelligence.resources.show,
    method: 'post',
    url: '/v2/metabase/linkembedded',
    body: params,
    baseURL: import.meta.env.VITE_METABASE_URL
  })

  return data
}
