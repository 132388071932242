import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function CloudDoneIcon({ fill, size = '$lg', css, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={{
        fill: 'none',
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...css
      }}
      {...restProps}
    >
      <Path
        d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM10 17L6.5 13.5L7.91 12.09L10 14.17L15.18 9L16.59 10.41L10 17Z"
        css={{ fill }}
      />
    </Svg>
  )
}
