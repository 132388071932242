import { i18n } from '@/common/i18n'
import { hubApiService } from '@/common/services'

import {
  MakeLtiLaunchApiParams,
  MakeLtiLaunchApiResult,
  ShowLtiApplicationApiParams,
  ShowLtiApplicationApiResult
} from './contracts/apis'
import {
  MakeLtiLaunchServiceParams,
  MakeLtiLaunchServiceResult,
  ShowLtiApplicationServiceParams,
  ShowLtiApplicationServiceResult
} from './contracts/services'
import { fromShowLtiApplicationApiSanitizer } from './sanitizers'

export async function makeLtiLaunchService(
  params: MakeLtiLaunchServiceParams
): Promise<MakeLtiLaunchServiceResult> {
  const { data } = await hubApiService<MakeLtiLaunchApiParams, MakeLtiLaunchApiResult>({
    resource: i18n().modules.hub.lti.resources.makeLaunch,
    method: 'post',
    url: '/lti/v2/lti/launch',
    body: {
      app: params.applicationId,
      launch_method: params.launchMethod,
      custom_url_redirect: params.customUrlRedirect,
      state: params.state,
      redirect_uri: params.redirectUri,
      response_type: params.responseType,
      scope: params.scope
    }
  })
  return data
}

export async function showLtiApplicationService(
  params: ShowLtiApplicationServiceParams
): Promise<ShowLtiApplicationServiceResult> {
  const { data } = await hubApiService<ShowLtiApplicationApiParams, ShowLtiApplicationApiResult>({
    resource: i18n().modules.hub.lti.resources.showApplication,
    method: 'get',
    url: `/lti/v1/lti/application/${params.clientId}`,
    params: { redirect_uri: params.redirectUri }
  })
  return fromShowLtiApplicationApiSanitizer(data)
}
