import { Colors } from '@positivote/design-system/tokens'
import * as yup from 'yup'

import { aliasRegex } from '@/common/helpers'
import { i18n } from '@/common/i18n'

export const whiteLabelSchema = yup.object().shape({
  active: yup.boolean().required(i18n().common.validators.required),
  primaryColor: yup.mixed<keyof Colors>().required(i18n().common.validators.required),
  urlLogo: yup.string().required(i18n().common.validators.required),
  urlIcon: yup.string().required(i18n().common.validators.required),
  alias: yup
    .string()
    .required(i18n().common.validators.required)
    .matches(
      aliasRegex,
      i18n().modules.hub.whiteLabel.page.customization.customAddress.inputs.alias.invalid
    )
})
