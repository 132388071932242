import { Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function Mascot({ css, ...restProps }: Omit<IconProps, 'fill'>): JSX.Element {
  return (
    <Svg
      viewBox="0 0 280 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ width: '280px', height: '229px', ...css }}
      {...restProps}
    >
      <path
        opacity="0.2"
        d="M210.674 126.551C230.538 121.543 250.407 116.555 270.296 111.718C276.433 109.756 279.94 101.002 279.998 92.6249V38.2675C280.091 30.604 276.345 22.5084 270.281 23.5993C250.388 27.8049 230.475 31.8458 210.576 35.976C204.542 37.7872 200.189 46.5689 200.399 55.1585V112.082C200.277 120.136 204.458 128.006 210.674 126.544V126.551Z"
        fill="#B971E0"
      />
      <path
        opacity="0.2"
        d="M69.3258 126.551C49.4616 121.543 29.5924 116.555 9.70373 111.718C3.56645 109.756 0.0601284 101.002 0.00144524 92.6249V38.2675C-0.0914698 30.6041 3.65447 22.5084 9.7184 23.5993C29.612 27.8049 49.5252 31.8458 69.4237 35.976C75.4582 37.7872 79.8106 46.5689 79.6003 55.1585V112.082C79.7225 120.136 75.5414 128.006 69.3258 126.544V126.551Z"
        fill="#B971E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.1695 140.733C84.2079 140.627 84.8689 140.559 85.3825 140.559L86.9687 132.301C86.0687 132.301 85.0221 132.409 84.113 132.503C84.0065 132.514 83.9019 132.525 83.7996 132.535C82.7364 132.644 81.6838 132.741 80.6007 132.752C78.4648 132.773 76.3396 132.454 74.4014 131.25C70.6083 128.893 66.317 122.315 66.317 103.165L58.5841 104.859C58.5841 124.933 63.043 134.564 69.5562 138.61C72.7712 140.608 76.1882 141.039 79.0842 141.01C80.5172 140.995 81.853 140.867 82.9384 140.756C83.0174 140.748 83.0944 140.741 83.1695 140.733Z"
        fill="#B5C0D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.935 137.659C196.159 139.517 193.321 140.559 190.89 140.559L189.304 132.301C189.986 132.301 191.669 131.914 193.926 130.404C196.103 128.946 198.61 126.574 200.987 122.994C205.727 115.855 209.954 103.903 209.954 84.9295L217.687 86.6234C217.687 106.873 213.166 120.249 207.6 128.631C204.824 132.811 201.789 135.748 198.935 137.659Z"
        fill="#B5C0D1"
      />
      <ellipse opacity="0.5" cx="138.873" cy="218.98" rx="43.8642" ry="9.52052" fill="#A2AFC4" />
      <g opacity="0.5">
        <path
          d="M187.602 214.73C187.602 213.557 188.551 212.606 189.721 212.606H200.393C201.563 212.606 202.511 213.557 202.511 214.73C202.511 215.904 201.563 216.855 200.393 216.855H189.721C188.551 216.855 187.602 215.904 187.602 214.73Z"
          fill="#A2AFC4"
        />
        <path
          d="M76.1763 214.73C76.1763 213.557 77.1248 212.606 78.2949 212.606H88.9667C90.1368 212.606 91.0854 213.557 91.0854 214.73C91.0854 215.904 90.1368 216.855 88.9667 216.855H78.2949C77.1248 216.855 76.1763 215.904 76.1763 214.73Z"
          fill="#A2AFC4"
        />
      </g>
      <path
        d="M191.297 158.531L189.828 168.441C187.895 181.484 178.613 192.231 166.011 196.013L163.328 196.818C147.731 201.499 131.109 201.499 115.512 196.818L112.828 196.013C100.226 192.231 90.9442 181.484 89.0111 168.441L87.5426 158.531C84.6313 138.856 84.3435 118.879 86.688 99.1284L89.6654 74.0623C91.2042 61.1083 100.002 50.1777 112.308 45.9345C129.879 39.8773 148.965 39.8773 166.535 45.9345C178.841 50.1777 187.639 61.1062 189.178 74.0601L192.156 99.1262C194.5 118.879 194.212 138.853 191.299 158.529L191.297 158.531Z"
        fill="#DEE4ED"
      />
      <path
        d="M101.586 78.5155L101.966 75.8646C103.277 66.7033 110.399 59.4697 119.525 58.0315L124.596 57.233C132.976 55.9129 141.504 55.8378 149.903 57.0119L158.845 58.2612C168.129 59.5577 175.437 66.8514 176.766 76.1479L177.104 78.5155C178.193 86.1227 178.124 93.8522 176.901 101.44C175.476 110.281 168.63 117.253 159.83 118.829L153.368 119.986C142.706 121.894 131.783 121.774 121.166 119.629L118.46 119.084C109.863 117.35 103.243 110.455 101.843 101.781L101.788 101.44C100.564 93.8522 100.496 86.1248 101.584 78.5155H101.586Z"
        fill="#5D6675"
      />
      <ellipse cx="129.379" cy="81.7182" rx="3.84498" ry="3.85542" fill="white" />
      <ellipse cx="161.708" cy="81.7182" rx="3.84498" ry="3.85542" fill="white" />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.704 89.5711C122.102 89.9705 122.102 90.6182 121.704 91.0176L118.408 94.3223C118.01 94.7217 117.364 94.7217 116.965 94.3223C116.567 93.9228 116.567 93.2752 116.965 92.8757L120.261 89.5711C120.659 89.1716 121.305 89.1716 121.704 89.5711Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.881 89.5711C162.279 89.9705 162.279 90.6182 161.881 91.0176L158.585 94.3223C158.187 94.7217 157.541 94.7217 157.143 94.3223C156.744 93.9228 156.744 93.2752 157.143 92.8757L160.438 89.5711C160.837 89.1716 161.482 89.1716 161.881 89.5711Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.255 89.5711C126.654 89.9705 126.654 90.6182 126.255 91.0176L122.96 94.3223C122.561 94.7217 121.915 94.7217 121.517 94.3223C121.119 93.9228 121.119 93.2752 121.517 92.8757L124.813 89.5711C125.211 89.1716 125.857 89.1716 126.255 89.5711Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.431 89.5711C166.83 89.9705 166.83 90.6182 166.431 91.0176L163.135 94.3223C162.737 94.7217 162.091 94.7217 161.693 94.3223C161.294 93.9228 161.294 93.2752 161.693 92.8757L164.989 89.5711C165.387 89.1716 166.033 89.1716 166.431 89.5711Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.749 89.5711C132.147 89.9705 132.147 90.6182 131.749 91.0176L128.453 94.3223C128.054 94.7217 127.409 94.7217 127.01 94.3223C126.612 93.9228 126.612 93.2752 127.01 92.8757L130.306 89.5711C130.704 89.1716 131.35 89.1716 131.749 89.5711Z"
        fill="white"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.924 89.5711C172.323 89.9705 172.323 90.6182 171.924 91.0176L168.629 94.3223C168.23 94.7217 167.584 94.7217 167.186 94.3223C166.788 93.9228 166.788 93.2752 167.186 92.8757L170.482 89.5711C170.88 89.1716 171.526 89.1716 171.924 89.5711Z"
        fill="white"
      />
      <ellipse cx="110.153" cy="138.092" rx="8.55312" ry="8.57633" fill="#B971E0" />
      <ellipse cx="113.213" cy="135.81" rx="2.35407" ry="2.36046" fill="white" />
      <path
        d="M152.762 134.63C152.762 133.978 153.289 133.449 153.939 133.449H171.359C172.009 133.449 172.536 133.978 172.536 134.63C172.536 135.281 172.009 135.81 171.359 135.81H153.939C153.289 135.81 152.762 135.281 152.762 134.63Z"
        fill="#CAD3E0"
      />
      <path
        d="M152.762 139.35C152.762 138.699 153.289 138.17 153.939 138.17H171.359C172.009 138.17 172.536 138.699 172.536 139.35C172.536 140.002 172.009 140.531 171.359 140.531H153.939C153.289 140.531 152.762 140.002 152.762 139.35Z"
        fill="#CAD3E0"
      />
      <path
        d="M152.762 144.071C152.762 143.42 153.289 142.891 153.939 142.891H171.359C172.009 142.891 172.536 143.42 172.536 144.071C172.536 144.723 172.009 145.252 171.359 145.252H153.939C153.289 145.252 152.762 144.723 152.762 144.071Z"
        fill="#CAD3E0"
      />
      <rect x="84.4941" y="126.683" width="3.76651" height="15.579" rx="1.88326" fill="#CAD3E0" />
      <path
        d="M124.685 193.037C114.114 193.572 95.7581 178.041 92.6326 157.236C92.4762 156.196 93.8957 155.834 94.3894 156.762C102.314 171.665 118.171 184.248 125.199 189.348C126.836 190.535 126.703 192.934 124.685 193.037Z"
        fill="#CAD3E0"
      />
      <rect
        width="14.9203"
        height="10.3782"
        rx="5.18912"
        transform="matrix(-0.849435 0.527693 0.52563 0.850713 65.4707 95.9043)"
        fill="#91A0B8"
      />
      <rect
        width="14.9203"
        height="10.3782"
        rx="5.18912"
        transform="matrix(0.849435 0.527693 -0.52563 0.850713 210.801 77.3246)"
        fill="#91A0B8"
      />
      <rect
        width="2.71283"
        height="6.49235"
        rx="1.35642"
        transform="matrix(-0.861122 0.508397 0.506355 0.862325 64.791 98.187)"
        fill="#818FA5"
      />
      <rect
        width="2.71283"
        height="6.49235"
        rx="1.35642"
        transform="matrix(0.861122 0.508397 -0.506355 0.862325 211.481 79.6074)"
        fill="#818FA5"
      />
      <rect
        width="3.96912"
        height="6.71571"
        rx="1.98456"
        transform="matrix(-0.994957 -0.100299 -0.0997623 0.995011 72.4424 92.5442)"
        fill="#91A0B8"
      />
      <rect
        width="3.96912"
        height="6.71571"
        rx="1.98456"
        transform="matrix(0.994957 -0.100299 0.0997623 0.995011 203.829 73.9645)"
        fill="#91A0B8"
      />
      <rect
        width="3.97274"
        height="6.70961"
        rx="1.98637"
        transform="matrix(-0.808106 -0.589037 -0.586952 0.809622 73.0312 93.9657)"
        fill="#818FA5"
      />
      <rect
        width="3.97687"
        height="6.70263"
        rx="1.98844"
        transform="matrix(0.519081 -0.854725 0.853472 0.521138 203.823 76.4451)"
        fill="#818FA5"
      />
      <rect
        width="3.97064"
        height="6.71316"
        rx="1.98532"
        transform="matrix(-0.921332 0.388778 0.386992 0.922083 60.5903 90.8246)"
        fill="#91A0B8"
      />
      <rect
        width="3.97064"
        height="6.71316"
        rx="1.98532"
        transform="matrix(0.921332 0.388778 -0.386992 0.922083 215.682 72.2449)"
        fill="#91A0B8"
      />
      <rect
        width="3.97076"
        height="8.96185"
        rx="1.98538"
        transform="matrix(-0.914857 0.403778 0.401949 0.915662 58.5342 86.1205)"
        fill="#91A0B8"
      />
      <rect
        width="3.97076"
        height="8.96185"
        rx="1.98538"
        transform="matrix(0.914857 0.403778 -0.401949 0.915662 217.72 67.5408)"
        fill="#91A0B8"
      />
      <rect
        width="3.97353"
        height="6.70828"
        rx="1.98676"
        transform="matrix(-0.761623 0.648021 0.645982 0.763353 54.6543 92.4891)"
        fill="#91A0B8"
      />
      <rect
        width="3.97353"
        height="6.70828"
        rx="1.98676"
        transform="matrix(0.761623 0.648021 -0.645982 0.763353 221.618 73.9094)"
        fill="#91A0B8"
      />
      <rect
        width="3.97311"
        height="8.95656"
        rx="1.98656"
        transform="matrix(0.786443 0.617663 -0.615591 0.788065 221.003 74.4221)"
        fill="#818FA5"
      />
      <rect
        width="3.97353"
        height="6.70828"
        rx="1.98676"
        transform="matrix(-0.761623 0.648021 0.645982 0.763353 51.9844 96.6133)"
        fill="#91A0B8"
      />
      <rect
        width="3.97353"
        height="6.70828"
        rx="1.98676"
        transform="matrix(0.761623 0.648021 -0.645982 0.763353 224.288 78.0336)"
        fill="#91A0B8"
      />
      <rect
        width="3.97315"
        height="7.69782"
        rx="1.98658"
        transform="matrix(0.784222 0.62048 -0.61841 0.785855 223.682 77.8628)"
        fill="#818FA5"
      />
      <rect x="190.486" y="126.683" width="3.76651" height="15.579" rx="1.88326" fill="#CAD3E0" />
      <path
        d="M148.687 93.5626C148.746 94.0193 149.142 94.3316 149.597 94.2725C150.034 94.2271 150.364 93.8166 150.305 93.3599L148.69 93.5465L148.687 93.5626ZM142.145 93.5672C142.089 94.0218 142.437 94.4258 142.877 94.4636C143.33 94.5202 143.765 94.1764 143.803 93.7353L142.145 93.5672ZM149.496 93.4613C150.035 93.3937 150.305 93.3599 150.305 93.3599C150.305 93.3599 150.308 93.3438 150.31 93.3276C150.315 93.2954 150.305 93.2605 150.283 93.1907C150.266 93.0886 150.225 92.933 150.186 92.7611C150.093 92.4149 149.938 91.9426 149.654 91.4496C149.101 90.4661 148.028 89.4318 146.203 89.4851L146.255 91.1166C147.317 91.0891 147.891 91.6289 148.237 92.2479C148.416 92.5749 148.528 92.9076 148.602 93.1679C148.632 93.2887 148.662 93.4095 148.668 93.4767C148.663 93.509 148.673 93.5439 148.69 93.5465C148.69 93.5465 148.959 93.5127 149.499 93.4451L149.496 93.4613ZM146.206 89.469C144.38 89.5223 143.346 90.6141 142.805 91.6199C142.545 92.1079 142.384 92.5954 142.295 92.9454C142.25 93.1204 142.224 93.2818 142.209 93.3787C142.201 93.4271 142.193 93.4755 142.188 93.5078L142.183 93.5401C142.183 93.5401 142.453 93.5726 142.993 93.6377C143.533 93.7027 143.803 93.7353 143.803 93.7353C143.803 93.7353 143.811 93.6868 143.816 93.6546C143.827 93.59 143.845 93.477 143.881 93.3504C143.958 93.0812 144.063 92.7338 144.249 92.3994C144.604 91.728 145.194 91.1441 146.274 91.103L146.222 89.4716L146.206 89.469Z"
        fill="white"
      />
      <rect
        width="3.97135"
        height="8.96054"
        rx="1.98567"
        transform="matrix(-0.884812 0.465949 0.463973 0.885849 54.9956 92.3137)"
        fill="#818FA5"
      />
      <rect
        width="3.97138"
        height="7.70126"
        rx="1.98569"
        transform="matrix(-0.883133 0.469122 0.467141 0.884183 51.5461 95.8785)"
        fill="#818FA5"
      />
      <path
        opacity="0.2"
        d="M211.544 81.0168C218.46 79.3924 225.378 77.7753 232.302 76.2111C234.442 75.5641 235.714 72.5236 235.788 69.5929L236.14 50.5734C236.222 47.8926 234.976 45.0359 232.868 45.3785C225.946 46.7218 219.019 48.0074 212.096 49.3242C209.993 49.9191 208.428 52.9637 208.445 55.9705L208.077 75.8878C207.982 78.7052 209.38 81.4856 211.544 81.0144L211.544 81.0168Z"
        fill="#B971E0"
      />
      <path
        opacity="0.2"
        d="M210.006 118.645C216.922 117.021 223.84 115.403 230.764 113.839C232.904 113.192 234.176 110.152 234.25 107.221L234.602 88.2016C234.684 85.5208 233.438 82.664 231.33 83.0066C224.408 84.35 217.481 85.6355 210.558 86.9524C208.455 87.5472 206.89 90.5919 206.907 93.5987L206.539 113.516C206.444 116.333 207.842 119.114 210.006 118.643L210.006 118.645Z"
        fill="#B971E0"
      />
      <path
        opacity="0.2"
        d="M68.0887 119.152C61.8959 117.697 55.7018 116.25 49.5025 114.849C47.5868 114.27 46.4481 111.547 46.3813 108.923L46.0663 91.8944C45.9931 89.4941 47.1085 86.9363 48.9964 87.2431C55.1936 88.4458 61.3959 89.5969 67.5942 90.7759C69.4771 91.3085 70.8785 94.0345 70.863 96.7267L71.1929 114.56C71.2775 117.082 70.0257 119.572 68.0886 119.15L68.0887 119.152Z"
        fill="#B971E0"
      />
      <path
        opacity="0.2"
        d="M28.971 66.8533C22.7783 65.399 16.5841 63.9511 10.3848 62.5506C8.46909 61.9713 7.33038 59.249 7.26363 56.625L6.94865 39.5959C6.87541 37.1957 7.99083 34.6379 9.87874 34.9446C16.0759 36.1474 22.2782 37.2984 28.4765 38.4775C30.3595 39.01 31.7608 41.7361 31.7454 44.4282L32.0752 62.2611C32.1598 64.7837 30.908 67.2731 28.971 66.8512L28.971 66.8533Z"
        fill="#B971E0"
      />
      <mask
        id="mask0_18002_19"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="255"
        y="32"
        width="6"
        height="84"
      >
        <path
          d="M258.039 32.4458L258.039 115.022"
          stroke="#B971E0"
          strokeOpacity="0.2"
          strokeWidth="5"
        />
      </mask>
      <g mask="url(#mask0_18002_19)">
        <path
          d="M248.682 108.011C263.752 104.171 278.825 100.347 293.913 96.6391C298.569 95.1348 301.229 88.4232 301.273 82.0009V40.3268C301.344 34.4516 298.502 28.2449 293.902 29.0812C278.81 32.3055 263.703 35.4036 248.608 38.5701C244.03 39.9587 240.728 46.6913 240.888 53.2767V96.9179C240.795 103.093 243.967 109.126 248.682 108.006V108.011Z"
          fill="#B971E0"
        />
      </g>
      <mask
        id="mask1_18002_19"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="265"
        y="31"
        width="6"
        height="83"
      >
        <path
          d="M268.333 31.0695L268.333 113.646"
          stroke="#B971E0"
          strokeOpacity="0.2"
          strokeWidth="5"
        />
      </mask>
      <g mask="url(#mask1_18002_19)">
        <path
          d="M256.231 106.635C271.3 102.795 286.374 98.971 301.462 95.2628C306.117 93.7584 308.777 87.0468 308.822 80.6245V38.9505C308.892 33.0752 306.051 26.8686 301.45 27.7049C286.359 30.9292 271.252 34.0273 256.157 37.1937C251.579 38.5823 248.277 45.315 248.437 51.9003V95.5415C248.344 101.717 251.516 107.75 256.231 106.629V106.635Z"
          fill="#B971E0"
        />
      </g>
      <mask
        id="mask2_18002_19"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="35"
        y="36"
        width="6"
        height="78"
      >
        <path
          d="M38.4312 36.5746L38.4311 113.646"
          stroke="#B971E0"
          strokeOpacity="0.2"
          strokeWidth="5"
        />
      </mask>
      <g mask="url(#mask2_18002_19)">
        <path
          d="M50.5331 112.14C35.4637 108.3 20.3905 104.476 5.30256 100.768C0.646685 99.2636 -2.01328 92.552 -2.0578 86.1297V44.4556C-2.12829 38.5803 0.713463 32.3737 5.31368 33.21C20.4054 36.4343 35.5119 39.5324 50.6073 42.6988C55.1853 44.0874 58.4871 50.8201 58.3275 57.4055V101.047C58.4203 107.222 55.2483 113.255 50.5331 112.134V112.14Z"
          fill="#B971E0"
        />
      </g>
      <ellipse opacity="0.2" cx="241.569" cy="41.0475" rx="2.05882" ry="2.40848" fill="#B971E0" />
      <ellipse opacity="0.2" cx="241.569" cy="49.3051" rx="2.05882" ry="2.40848" fill="#B971E0" />
      <ellipse opacity="0.2" cx="240.196" cy="94.0341" rx="2.05882" ry="2.40848" fill="#B971E0" />
      <ellipse opacity="0.2" cx="28.8232" cy="73.3899" rx="2.05882" ry="2.40848" fill="#B971E0" />
      <ellipse opacity="0.2" cx="21.9604" cy="71.3255" rx="2.05882" ry="2.40848" fill="#B971E0" />
      <path
        d="M134.557 47.4461C148.299 44.1928 161.787 47.9095 169.249 52.1689C171.501 53.4545 170.239 55.5221 167.702 54.991C148.15 50.897 117.837 51.4045 134.557 47.4461Z"
        fill="#FAFAFB"
      />
      <ellipse cx="175.21" cy="57.3391" rx="1.96173" ry="1.96705" fill="#FAFAFB" />
      <path
        d="M137.457 15.2614C136.998 15.2614 136.634 15.1018 136.365 14.7826C136.096 14.4634 135.991 14.0744 136.051 13.6155C136.15 12.8973 136.365 12.259 136.694 11.7004C137.023 11.1418 137.527 10.5633 138.205 9.96477C139.043 9.20669 139.687 8.50347 140.135 7.85511C140.584 7.20675 140.809 6.51351 140.809 5.77538C140.809 4.87765 140.509 4.12955 139.911 3.53106C139.312 2.93258 138.475 2.63333 137.397 2.63333C136.659 2.63333 136.046 2.78295 135.557 3.0822C135.068 3.38144 134.674 3.7505 134.375 4.18939C134.156 4.50859 133.876 4.72803 133.537 4.84773C133.198 4.96742 132.869 4.95745 132.55 4.8178C132.151 4.65821 131.906 4.36894 131.816 3.95C131.727 3.53106 131.792 3.15202 132.011 2.81288C132.57 1.99495 133.303 1.32165 134.21 0.792992C135.118 0.264331 136.18 0 137.397 0C139.392 0 140.928 0.553598 142.006 1.6608C143.083 2.76799 143.622 4.09962 143.622 5.65568C143.622 6.61326 143.417 7.48106 143.008 8.25909C142.599 9.03712 141.946 9.85505 141.048 10.7129C140.35 11.3912 139.861 11.9398 139.582 12.3587C139.302 12.7777 139.123 13.2564 139.043 13.7951C138.983 14.214 138.804 14.5631 138.505 14.8424C138.205 15.1217 137.856 15.2614 137.457 15.2614ZM137.397 22.7424C136.819 22.7424 136.325 22.5379 135.916 22.129C135.507 21.72 135.303 21.2263 135.303 20.6477C135.303 20.0692 135.507 19.5754 135.916 19.1665C136.325 18.7575 136.819 18.553 137.397 18.553C137.976 18.553 138.47 18.7575 138.879 19.1665C139.288 19.5754 139.492 20.0692 139.492 20.6477C139.492 21.2263 139.288 21.72 138.879 22.129C138.47 22.5379 137.976 22.7424 137.397 22.7424Z"
        fill="#B5C0D1"
      />
      <path
        d="M84.2863 43.1736C83.9037 43.1736 83.6002 43.0413 83.3756 42.7767C83.1511 42.5121 83.0638 42.1896 83.1137 41.8093C83.1968 41.2139 83.3756 40.6847 83.6501 40.2217C83.9245 39.7587 84.3445 39.2791 84.91 38.783C85.6086 38.1546 86.145 37.5716 86.5192 37.0342C86.8935 36.4967 87.0806 35.9221 87.0806 35.3102C87.0806 34.566 86.8311 33.9459 86.3321 33.4498C85.8331 32.9537 85.1345 32.7056 84.2364 32.7056C83.621 32.7056 83.1095 32.8296 82.702 33.0777C82.2945 33.3257 81.966 33.6317 81.7165 33.9955C81.5336 34.2601 81.3007 34.442 81.0179 34.5412C80.7352 34.6404 80.4608 34.6322 80.1946 34.5164C79.862 34.3841 79.6582 34.1443 79.5834 33.797C79.5085 33.4498 79.5626 33.1356 79.7455 32.8544C80.2113 32.1764 80.8225 31.6183 81.5793 31.1801C82.3361 30.7418 83.2218 30.5227 84.2364 30.5227C85.8996 30.5227 87.1804 30.9816 88.0785 31.8994C88.9767 32.8172 89.4258 33.9211 89.4258 35.211C89.4258 36.0047 89.2553 36.7241 88.9143 37.3691C88.5734 38.014 88.0286 38.692 87.2802 39.4031C86.698 39.9654 86.2905 40.4201 86.0577 40.7674C85.8248 41.1147 85.6751 41.5116 85.6086 41.9581C85.5587 42.3054 85.409 42.5948 85.1595 42.8263C84.91 43.0578 84.6189 43.1736 84.2863 43.1736ZM84.2364 49.375C83.754 49.375 83.3424 49.2055 83.0014 48.8665C82.6604 48.5275 82.4899 48.1182 82.4899 47.6386C82.4899 47.159 82.6604 46.7497 83.0014 46.4107C83.3424 46.0717 83.754 45.9022 84.2364 45.9022C84.7187 45.9022 85.1304 46.0717 85.4714 46.4107C85.8123 46.7497 85.9828 47.159 85.9828 47.6386C85.9828 48.1182 85.8123 48.5275 85.4714 48.8665C85.1304 49.2055 84.7187 49.375 84.2364 49.375Z"
        fill="#B5C0D1"
      />
      <path
        d="M191.39 48.8435C191.077 48.8435 190.829 48.7364 190.645 48.5222C190.461 48.308 190.39 48.0469 190.431 47.739C190.499 47.2571 190.645 46.8287 190.87 46.4539C191.094 46.079 191.438 45.6908 191.9 45.2892C192.472 44.7805 192.911 44.3086 193.217 43.8735C193.523 43.4384 193.676 42.9732 193.676 42.4779C193.676 41.8755 193.472 41.3734 193.064 40.9718C192.656 40.5702 192.084 40.3694 191.349 40.3694C190.846 40.3694 190.427 40.4698 190.094 40.6706C189.76 40.8714 189.492 41.1191 189.288 41.4136C189.138 41.6278 188.947 41.7751 188.716 41.8554C188.485 41.9357 188.26 41.929 188.042 41.8353C187.77 41.7282 187.604 41.5341 187.542 41.253C187.481 40.9718 187.525 40.7175 187.675 40.4899C188.056 39.941 188.556 39.4892 189.175 39.1344C189.795 38.7797 190.519 38.6023 191.349 38.6023C192.71 38.6023 193.758 38.9738 194.493 39.7168C195.228 40.4598 195.595 41.3534 195.595 42.3976C195.595 43.0401 195.456 43.6225 195.177 44.1446C194.898 44.6667 194.452 45.2156 193.84 45.7912C193.363 46.2464 193.03 46.6145 192.839 46.8956C192.649 47.1768 192.526 47.4981 192.472 47.8595C192.431 48.1406 192.309 48.3749 192.105 48.5623C191.9 48.7498 191.662 48.8435 191.39 48.8435ZM191.349 53.8637C190.955 53.8637 190.618 53.7264 190.339 53.452C190.06 53.1776 189.92 52.8462 189.92 52.458C189.92 52.0698 190.06 51.7384 190.339 51.464C190.618 51.1896 190.955 51.0524 191.349 51.0524C191.744 51.0524 192.081 51.1896 192.36 51.464C192.639 51.7384 192.778 52.0698 192.778 52.458C192.778 52.8462 192.639 53.1776 192.36 53.452C192.081 53.7264 191.744 53.8637 191.349 53.8637Z"
        fill="#B5C0D1"
      />
    </Svg>
  )
}
