import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Typography } from '@positivote/design-system/components/Typography'
import { useNavigate } from 'react-router-dom'

import bgClouds from '@/common/assets/backgrounds/bg-clouds.svg'
import { Error404 } from '@/common/assets/images/Error404'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'

export function NotFound(): JSX.Element {
  changePageTitle(i18n().common.pages.notFound.title)
  const navigate = useNavigate()

  return (
    <BaseBackground shouldShowFooter={false}>
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$lg',
          margin: '$lg auto',
          flex: 1,
          width: '100%',
          height: '100%',
          backgroundImage: `url("${bgClouds}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          '@sm': { backgroundSize: '100%' }
        }}
      >
        <Error404 />
        <Div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '$2xs' }}>
          <Typography variant="headlineMedium" css={{ color: '$on-surface', textAlign: 'center' }}>
            {i18n().common.pages.notFound.message}
          </Typography>
          <Typography
            variant="bodyMedium"
            css={{ color: '$on-surface-variant', textAlign: 'center' }}
          >
            {i18n().common.pages.notFound.verifyTypedAddress}
          </Typography>
        </Div>
        <Button variant="filled" onClick={() => navigate('/')}>
          {i18n().common.pages.notFound.buttons.goBack}
        </Button>
      </Div>
    </BaseBackground>
  )
}
