import { i18n } from '@/common/i18n'

export enum ProfileGender {
  FEMALE = 'F',
  MALE = 'M',
  OTHER = 'O'
}

export interface GenderDictionaryValue extends Record<string, string> {
  value: string
  key: string
}

export type GenderDictionary = Record<string, GenderDictionaryValue>

export const genderDictionary: GenderDictionary = {
  F: { key: ProfileGender.FEMALE, value: i18n().common.helpers.gender.female },
  M: { key: ProfileGender.MALE, value: i18n().common.helpers.gender.male },
  O: { key: ProfileGender.OTHER, value: i18n().common.helpers.gender.other }
}
