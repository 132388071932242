import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Main } from '@positivote/design-system/components/Main'
import { Switch } from '@positivote/design-system/components/Switch'
import { Typography } from '@positivote/design-system/components/Typography'
import { useEffect, useState } from 'react'

import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { theme } from '@/modules/hub/white-label/constants'
import { useUpdateSkin } from '@/modules/hub/white-label/hooks'
import { Hubot } from '@/modules/hub/white-label/icons/Hubot'

export function WhiteLabelCustomizationUniverse(): JSX.Element {
  const { account } = useAuth()
  const updateUniverse = useUpdateSkin()
  const [isActiveUniverseTheme, setIsActiveUniverseTheme] = useState(false)

  function handleSave(): void {
    const newIsActiveUniverseTheme = !isActiveUniverseTheme
    const activeTheme = newIsActiveUniverseTheme ? theme.universe : theme.default

    if (account?.code) {
      updateUniverse.mutate({
        model: {
          skin: activeTheme,
          accountCode: account.code
        }
      })
    }
    setIsActiveUniverseTheme(newIsActiveUniverseTheme)
  }

  useEffect(() => {
    setIsActiveUniverseTheme(account?.skin === theme.universe)
  }, [account?.skin])

  return (
    <Main
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden'
      }}
    >
      <Grid
        xl={12}
        spacing="$lg"
        css={{ display: 'flex', padding: '$lg', '@sm': { padding: '$md' } }}
      >
        <Grid xl={7}>
          <BaseCard
            css={{
              display: 'flex',
              '& .BaseCard-StateLayer': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '$lg',
                gap: '$lg'
              }
            }}
          >
            <Div>
              <Div css={{ display: 'flex', gap: '$2xs', flexDirection: 'column' }}>
                <Typography variant="titleMedium" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.whiteLabel.page.universe.activeTheme.title}
                </Typography>
                <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                  {i18n().modules.hub.whiteLabel.page.universe.activeTheme.subTitle}
                </Typography>
              </Div>
            </Div>
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '$2xs'
              }}
            >
              <Typography variant="bodyMedium" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.whiteLabel.page.universe.activeTheme.switchTitle(
                  isActiveUniverseTheme
                )}
              </Typography>
              <Switch
                name="active"
                isLoading={updateUniverse.isPending}
                inputProps={{ checked: isActiveUniverseTheme, onChange: handleSave }}
              />
            </Div>
          </BaseCard>
        </Grid>
        <Grid xl={5} css={{ display: 'flex', justifyContent: 'center' }}>
          <Hubot />
        </Grid>
      </Grid>
    </Main>
  )
}
