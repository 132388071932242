import { i18n } from '@/common/i18n'

import { ptBrDateRegex } from './regex'

export function formatPtBrDateToIsoString(date: string): string {
  const dateTimeString = date

  const [day, month, year] = dateTimeString.split('/')
  const dateObj = new Date(+year, +month - 1, +day)

  return dateObj.toISOString()
}

export function isDateSmallerToNow(date: string): boolean {
  const currentDate = new Date()
  const data = new Date(date)
  return data < currentDate
}

export function isValidDate(date: string, mask?: RegExp): { isValid: boolean; parsedDate: Date } {
  const MIN_YEAR = 1900
  if (ptBrDateRegex.test(date)) {
    const [day, month, year] = date.split('/')
    const parsedDate = new Date(`${year}-${month}-${day}`)
    const gmtDiff = -3
    parsedDate.setHours(parsedDate.getHours() + gmtDiff)
    const isValid =
      !isNaN(parsedDate.getTime()) &&
      parsedDate.getFullYear() >= MIN_YEAR &&
      (!mask || mask.test(date))
    return { isValid, parsedDate }
  }
  const parsedDate = new Date(date)
  const isValid =
    !isNaN(parsedDate.getTime()) &&
    parsedDate.getFullYear() >= MIN_YEAR &&
    (!mask || mask.test(date))
  return { isValid, parsedDate }
}

export function parseValidDateOrDefault(date: string): string {
  const { isValid, parsedDate } = isValidDate(date)
  if (isValid) {
    return parsedDate.toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  return new Date().toLocaleString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

export function currentDatePtFormatted(): string {
  return new Date().toLocaleString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

export function getOldDatePtFormatted(oldDay: number): string {
  const oldDays = new Date().setDate(new Date().getDate() - oldDay)
  return new Date(oldDays).toLocaleString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

export function validateDateRange(initialDate: string, finalDate: string): boolean {
  const currentInitialDate = new Date(formatPtBrDateToIsoString(initialDate))
  const currentFinalDate = new Date(formatPtBrDateToIsoString(finalDate))
  return currentInitialDate <= currentFinalDate
}

export function getClientTimezone(): number {
  const hours = 60
  const timezone = -new Date().getTimezoneOffset() / hours
  return Math.round(timezone)
}

export function convertDateToMonthYear(date: string): string {
  const [year, month] = date.split('-')
  const abbreviatedMonths = Object.values(i18n().common.abbreviatedMonths)
  return `${abbreviatedMonths[Number(month) - 1]}/${year}`
}

export function formatHour(time: number): string {
  const minutesInHour = 60
  const hours = Math.floor(time / minutesInHour)
  const minutes = time % minutesInHour
  if (time < minutesInHour) {
    return `${minutes} min`
  }
  return `${hours}h ${minutes} min`
}

export function formatDatePtBr(dateString: string): string {
  const date = new Date(dateString + 'T00:00:00')
  return date.toLocaleDateString('pt-BR')
}

export function isValidDatePtBr(dateString: string): boolean {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/
  if (!regex.exec(dateString)) {
    return false
  }

  const [day, month, year] = dateString.split('/').map(Number)

  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1000 || year > 9999) {
    return false
  }

  const date = new Date(year, month - 1, day)

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day
}

export function convertDateToISO(date: string): string {
  const [day, month, year] = date.split('/')

  return `${year}-${month}-${day}`
}

export function convertDateParsedTime(data: string): string {
  const date = new Date(data)

  const brazilianTimeZone = new Date(date.getTime() - 3 * 60 * 60 * 1000)
  const hours = brazilianTimeZone.getHours().toString().padStart(2, '0')
  const minutes = brazilianTimeZone.getMinutes().toString().padStart(2, '0')
  const seconds = brazilianTimeZone.getSeconds().toString().padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}
