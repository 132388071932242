import { BreadcrumbsProps } from '@positivote/design-system/components/Breadcrumbs'
import { Main } from '@positivote/design-system/components/Main'
import { Tabs } from '@positivote/design-system/components/Tabs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useListLicense } from '@/modules/hub/licenses/hooks'
import { ListApplicationPermissions } from '@/modules/hub/licenses/pages/ApplicationAccess/ApplicationPermissions'

import { AccessDistribution } from './AccessDistribution'

export function ApplicationAccess(): JSX.Element {
  changePageTitle(i18n().modules.hub.licenses.pages.applicationAccess.pageTitle)

  const navigate = useNavigate()
  const params = useParams()
  const { data: licenses } = useListLicense({
    model: { licenseId: Number(params.licenseId) },
    onError: () => {
      navigate(-1)
    }
  })
  const location = useLocation()
  const locationState = location.state as { breadcrumbItems?: BreadcrumbsProps['items'] } | null

  const breadcrumbItems = [
    ...(locationState?.breadcrumbItems
      ? locationState.breadcrumbItems.map((breadcrumbItem, index) => ({
          ...breadcrumbItem,
          onClick: () => navigate(-((locationState.breadcrumbItems?.length ?? index + 1) - index))
        }))
      : [
          {
            label: i18n().modules.hub.licenses.pages.applicationAccess.appBar.breadcrumbs.overview,
            onClick: () => navigate(-2)
          },
          {
            label:
              i18n().modules.hub.licenses.pages.applicationAccess.appBar.breadcrumbs
                .accessConfiguration,
            onClick: () => navigate(-1)
          }
        ]),
    {
      label: licenses?.app.name ?? '...'
    }
  ]

  const dataManagementTabs = [
    {
      label: i18n().modules.hub.licenses.pages.applicationAccess.tabs.accessDistribution,
      key: 'accessDistribution',
      children: <AccessDistribution licenseId={Number(params.licenseId)} />
    },
    {
      label: i18n().modules.hub.licenses.pages.applicationAccess.tabs.applicationPermission,
      key: 'applicationPermissions',
      children: <ListApplicationPermissions licenseId={Number(params.licenseId)} />
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.licenses.pages.applicationAccess.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={breadcrumbItems}
      />
      <Tabs options={dataManagementTabs} />
    </Main>
  )
}
