import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { ErrorIcon } from '@positivote/design-system/icons/Error'

import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'

import { DefaultAcademicSyncData, TeacherAcademicSync } from '../contracts'

export interface ListDetailsTeacherProps {
  data?: DefaultAcademicSyncData<TeacherAcademicSync>
  filterStatus: string
}

export function ListDetailsTeacher({ data, filterStatus }: ListDetailsTeacherProps): JSX.Element {
  const newDataFilter =
    filterStatus === 'all'
      ? data?.data
      : data?.data.filter((status) => status.status === filterStatus)
  return (
    <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      {!data?.data.length ? (
        <EmptyList title="Não há professores para exibir" />
      ) : !newDataFilter?.length ? (
        <EmptySearch />
      ) : (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            backgroundColor: '$surface-2',
            borderRadius: '$md',
            overflow: 'hidden',
            flexDirection: 'column'
          }}
        >
          <Grid
            css={{
              display: 'flex',
              padding: '$2xs $md',
              background: '$outline-variant'
            }}
          >
            <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
              <Typography
                data-testid="Typography-header-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Status
              </Typography>
            </Grid>

            <Grid xl={1}>
              <Typography
                data-testid="Typography-header-code"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Linha
              </Typography>
            </Grid>
            <Grid xl={1.5}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Matrícula *
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Nome completo *
              </Typography>
            </Grid>
            <Grid xl={2.5} lg={2}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Data Nascimento *
              </Typography>
            </Grid>
            <Grid xl={1}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                Gênero
              </Typography>
            </Grid>
            <Grid xl={2}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                E-mail
              </Typography>
            </Grid>
            <Grid xl={1} lg={1.5}>
              <Typography
                data-testid="Typography-header-base-discipline"
                variant="titleMedium"
                lineClamp={1}
                css={{ color: '$on-surface' }}
              >
                CPF
              </Typography>
            </Grid>
          </Grid>

          {newDataFilter.map((student) => (
            <Grid
              key={student.line}
              css={{
                display: 'flex',
                padding: '$2xs $md',
                borderWidth: '$thin',
                borderStyle: 'solid',
                borderColor: '$surface-variant',
                alignItems: 'center'
              }}
            >
              <Grid xl={1} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                {student.status === 'SUCCESS' && (
                  <Tooltip label={student.message} placement="top">
                    <CheckCircleIcon fill="$success" />
                  </Tooltip>
                )}
                {student.status === 'ERROR' && (
                  <Tooltip label={student.message} placement="top">
                    <ErrorIcon css={{ pointerEvents: 'unset' }} fill="$critical" />
                  </Tooltip>
                )}
              </Grid>

              <Grid xl={1}>
                <Typography
                  data-testid="Typography-header-code"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.systemId}
                </Typography>
              </Grid>
              <Grid xl={1.5}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.code}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.name}
                </Typography>
              </Grid>
              <Grid xl={2.5} lg={2}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.birthday}
                </Typography>
              </Grid>

              <Grid xl={1}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="bodySmall"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {student.instance.genre}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Tooltip label={student.instance.email}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="bodySmall"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {student.instance.email}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid xl={1} lg={1.5}>
                <Tooltip label={student.instance.document}>
                  <Typography
                    data-testid="Typography-header-base-discipline"
                    variant="bodySmall"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                  >
                    {student.instance.document}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Div>
      )}
    </Div>
  )
}
