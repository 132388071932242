import { i18n } from '@/common/i18n'

import {
  ListOrganizationApiResult,
  ListOrganizationPermissionApiResult,
  ListOrganizationPermissionServiceResult,
  ListShortOrganizationApiResult,
  Organization,
  OrganizationStatus,
  ShortOrganizationFormatted
} from './contracts'

export function fromShortOrganizationApiSanitizer(
  organizations: ListShortOrganizationApiResult['data']
): ShortOrganizationFormatted[] {
  return organizations.map((organization) => {
    return {
      id: organization.id_instituicao,
      code: organization.cod_hub,
      name: organization.nom_instituicao,
      address: organization.dsc_endereco,
      kind: organization.id_org_kind,
      status: organization.status,
      kindFormatted: i18n().modules.hub.organizations.kind[organization.id_org_kind],
      statusColor: organization.status === OrganizationStatus.ACTIVE ? '$success' : '$critical',
      statusFormatted: i18n().modules.hub.organizations.status[organization.status],
      city: organization.city,
      stateCode: organization.state_code,
      codHub: organization.cod_hub
    }
  })
}

export function fromOrganizationPermissionApiSanitizer(
  permissions: ListOrganizationPermissionApiResult
): ListOrganizationPermissionServiceResult {
  return permissions.map((permission) => ({
    id: permission.role_id,
    name: permission.role_name,
    permissions: permission.permissions
  }))
}

export function organizationFormatSanitizer(
  organizations: ListOrganizationApiResult['data']
): Organization[] {
  return organizations.map((organization) => ({
    codHub: organization.cod_hub,
    dscEndereco: organization.dsc_endereco,
    idInstituicao: organization.id_instituicao,
    nameInstituicao: organization.nom_instituicao,
    city: organization.city,
    stateCode: organization.state_code
  }))
}
