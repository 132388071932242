import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete } from '@positivote/design-system/components/Autocomplete'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { ANIMATION_DELAY } from '@positivote/design-system/constants'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { SapServiceList } from '@/modules/billing/service-mapping/components/ServiceList'
import {
  AddSapServiceForm,
  ServiceMappingApplication,
  ServiceMappingServiceDataStepForm,
  ServiceMappingStepperState
} from '@/modules/billing/service-mapping/contracts'
import { useVerifyServiceMapping } from '@/modules/billing/service-mapping/hooks'
import { addServiceFormSanitizer } from '@/modules/billing/service-mapping/sanitizers'
import { addServiceValidationSchema } from '@/modules/billing/service-mapping/validations'
import { useListEdtechApplication } from '@/modules/hub/applications/hooks'

interface ServiceMappingServiceDataStepProps {
  stepState: ServiceMappingStepperState['serviceData']
  setStepState: (stepperState: Partial<ServiceMappingStepperState['serviceData']>) => void
}

export const ServiceMappingServiceDataStep = forwardRef(function ServiceMappingServiceDataStep(
  { stepState, setStepState }: ServiceMappingServiceDataStepProps,
  ref
): JSX.Element {
  const [selectedApplication, setSelectedApplication] = useState<ServiceMappingApplication | null>(
    null
  )
  const [applicationSearch, setApplicationSearch] = useState('')
  const [formErrors, setFormErrors] = useState({ selectApplication: '', addService: '' })
  const [sapServices, setSapServices] = useState<AddSapServiceForm[]>([])

  const params = useParams()
  const listEdtechApplication = useListEdtechApplication({
    model: { search: applicationSearch || undefined }
  })
  const verifyServiceMappingApplication = useVerifyServiceMapping()
  const verifyServiceMappingSapId = useVerifyServiceMapping()

  const parsedApplications = useMemo<ServiceMappingApplication[]>(() => {
    const applications = (listEdtechApplication.data?.registers ?? []).map((application) => ({
      id: application.id,
      name: application.name
    }))
    if (
      !applicationSearch &&
      selectedApplication &&
      !applications.some((application) => application.id === selectedApplication.id)
    ) {
      applications.push(selectedApplication)
    }
    return applications
    // DOCS: This useEffect can not re-run when applicationSearch changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEdtechApplication.data?.registers, selectedApplication])

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setFocus,
    formState: { errors }
  } = useForm<AddSapServiceForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(addServiceValidationSchema)(addServiceFormSanitizer(values), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  async function onSubmit(model: AddSapServiceForm): Promise<void> {
    const serviceFound = sapServices.find((sapService) => sapService.id === model.id)
    if (serviceFound) {
      setError('id', {
        message:
          i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields.id.error
      })
      return
    }
    const { exists } = await verifyServiceMappingSapId.mutateAsync({
      model: { id: params.id, sapId: model.id }
    })
    if (exists) {
      setError('id', {
        message:
          i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields.id.error
      })
      return
    }
    setFormErrors((oldData) => ({ ...oldData, addService: '' }))
    reset({ name: '', id: '' })
    setSapServices([...sapServices, model])
    setTimeout(() => setFocus('name'), ANIMATION_DELAY)
  }

  function handleSearchApplication(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value
    debounceEvent(() => {
      setApplicationSearch(value)
    })()
  }

  function handleSelectApplication(application: ServiceMappingApplication | null): void {
    setSelectedApplication(application)
    if (application) {
      setFormErrors((oldData) => ({ ...oldData, selectApplication: '' }))
      verifyServiceMappingApplication.mutate({
        model: { id: params.id, appId: application.id },
        onSuccess: ({ exists }) => {
          setFormErrors((oldData) => ({
            ...oldData,
            selectApplication: exists
              ? i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields
                  .selectApplication.error
              : ''
          }))
        }
      })
    }
  }

  function handleRemove(model: AddSapServiceForm): void {
    const newSapServices = sapServices.filter((sapService) => sapService.id !== model.id)
    setSapServices(newSapServices)
  }

  function handleEdit(model: AddSapServiceForm): void {
    reset(model)
    handleRemove(model)
  }

  const validateServiceDataForm = useCallback((): ServiceMappingServiceDataStepForm | null => {
    setFormErrors((oldData) => ({
      ...oldData,
      selectApplication: selectedApplication ? '' : i18n().common.validators.required,
      addService: sapServices.length
        ? ''
        : i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.addServices.error
    }))
    if (!selectedApplication || !sapServices.length) {
      setStepState({ hasError: true, canGoNext: false })
      return null
    }
    return {
      application: selectedApplication,
      sapServices
    }
  }, [selectedApplication, sapServices, setStepState])

  useImperativeHandle(ref, () => ({ validateServiceDataForm }), [validateServiceDataForm])

  useEffect(() => {
    const hasFormErrors = !!formErrors.addService || !!formErrors.selectApplication
    setStepState({
      hasError: hasFormErrors,
      isValid: !!sapServices.length && !!selectedApplication && !hasFormErrors,
      canGoNext:
        !hasFormErrors &&
        (!hasAnyError || !!sapServices.length) &&
        !verifyServiceMappingApplication.isPending &&
        !verifyServiceMappingSapId.isPending
    })
    // DOCS: This useEffect can not re-run for setStepState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formErrors,
    hasAnyError,
    verifyServiceMappingApplication.isPending,
    sapServices.length,
    verifyServiceMappingSapId.isPending,
    selectedApplication
  ])

  useEffect(() => {
    if (stepState.form) {
      setSelectedApplication(stepState.form.application)
      setSapServices(stepState.form.sapServices)
    }
  }, [stepState.form])

  return (
    <Div css={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '$lg' }}>
      <Typography
        data-testid="Typography-requiredFields"
        variant="bodyMedium"
        css={{ color: '$on-surface-variant', alignSelf: 'center' }}
      >
        {i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.requiredFields}
      </Typography>
      <Grid spacing="$lg">
        <Grid xl={9} sm={7}>
          <Autocomplete
            label={
              i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields
                .selectApplication.label
            }
            required
            variant="outlined"
            optionKeyField="id"
            optionTitleField="name"
            value={selectedApplication?.id}
            onChange={handleSelectApplication}
            data-testid="selectApplication"
            inputProps={{
              onChange: handleSearchApplication,
              autoFocus: !stepState.form?.application
            }}
            errorText={formErrors.selectApplication}
            isLoading={
              listEdtechApplication.isFetching || verifyServiceMappingApplication.isPending
            }
            options={parsedApplications}
          />
        </Grid>
        <Grid xl={3} sm={5}>
          <TextField
            data-testid="hubId"
            label={
              i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields.hubId
            }
            required
            inputProps={{
              // DOCS: nullish to suppress uncontrolled react warning
              value: selectedApplication?.id ?? ''
            }}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid xl={12}>
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$md',
              padding: '$lg',
              borderRadius: '$xl',
              borderStyle: 'solid',
              borderWidth: '$thin',
              borderColor: '$outline-variant',
              '@sm': { padding: '$md' }
            }}
          >
            <Typography
              data-testid="Typography-addServices"
              variant="titleMedium"
              css={{ color: '$on-surface-variant' }}
            >
              {
                i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.addServices
                  .label
              }
            </Typography>
            <FormContainer
              formHandleSubmit={handleSubmit}
              onSubmit={onSubmit}
              css={{ display: 'flex', gap: '$md' }}
            >
              <Grid spacing="$md">
                <FormTextField
                  control={control}
                  name="name"
                  label={
                    i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields.name
                  }
                  required
                  variant="outlined"
                  errorText={errors.name?.message}
                  hasError={!!formErrors.addService}
                  gridProps={{ xl: 6 }}
                />
                <FormTextField
                  control={control}
                  name="id"
                  label={
                    i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData.fields.id
                      .label
                  }
                  required
                  variant="outlined"
                  errorText={errors.id?.message}
                  hasError={!!formErrors.addService}
                  gridProps={{ xl: 6 }}
                />
                {formErrors.addService && (
                  <Grid
                    xl={12}
                    css={{ display: 'flex', justifyContent: 'center', marginBottom: '-$md' }}
                  >
                    <Typography
                      data-testid="Typography-addServiceError"
                      variant="bodyMedium"
                      css={{ color: '$critical' }}
                    >
                      {
                        i18n().modules.billing.serviceMapping.pages.form.stepper.serviceData
                          .addServices.error
                      }
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <IconButton
                data-testid="addService"
                type="submit"
                size="$2xl"
                variant="outlined"
                disabled={hasAnyError}
                css={{ marginTop: '$2xs' }}
                isLoading={verifyServiceMappingSapId.isPending}
              >
                <AddCircleIcon />
              </IconButton>
            </FormContainer>
            <SapServiceList
              sapServices={sapServices}
              handleEdit={handleEdit}
              handleRemove={handleRemove}
            />
          </Div>
        </Grid>
      </Grid>
    </Div>
  )
})
