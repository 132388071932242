import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { Switch } from '@positivote/design-system/components/Switch'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { WrappedLoader } from '@/common/components/WrappedLoader'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ApplicationPermission } from '@/modules/hub/licenses/contracts'
import {
  useListApplicationPermission,
  useListLicense,
  useUpdateApplicationPermission
} from '@/modules/hub/licenses/hooks'

interface ListApplicationPermissionsProps {
  licenseId: number
}

export function ListApplicationPermissions({
  licenseId
}: ListApplicationPermissionsProps): JSX.Element {
  const navigate = useNavigate()
  const { session, isLoading: authIsLoading } = useAuth()
  const [applicationIsLoading, setApplicationIsLoading] = useState({
    applicationPermissionId: 0,
    permissionName: ''
  })
  const { data: licenses, isLoading: licensesIsLoading } = useListLicense({
    model: { licenseId }
  })
  const applicationPermissionIsEnabled = !!licenses?.app.id && !!session?.orgId
  const { data: applicationPermissions, isLoading: applicationPermissionsIsLoading } =
    useListApplicationPermission({
      model: {
        applicationId: licenses?.app.id as unknown as number,
        organizationId: session?.orgId as unknown as number
      },
      queryOptions: {
        enabled: applicationPermissionIsEnabled
      },
      onError: () => {
        navigate(-1)
      }
    })
  const updateApplicationPermissions = useUpdateApplicationPermission()
  const isLoading =
    licensesIsLoading ||
    (applicationPermissionsIsLoading && applicationPermissionIsEnabled) ||
    authIsLoading

  function handleChangePermission(
    permissionModel: ApplicationPermission,
    propertyToUpdate: keyof ApplicationPermission
  ): void {
    const payload = {
      [propertyToUpdate]: !permissionModel[propertyToUpdate],
      permissionId: permissionModel.id,
      permissionName: propertyToUpdate
    }

    savePermissions(payload)
  }

  function savePermissions(params: {
    permissible?: boolean
    anonymizable?: boolean
    permissionId: number
    permissionName: string
  }): void {
    setApplicationIsLoading({
      applicationPermissionId: params.permissionId,
      permissionName: params.permissionName
    })
    updateApplicationPermissions.mutate({
      model: {
        permissible: params.permissible,
        anonymizable: params.anonymizable,
        applicationId: licenses?.app.id as unknown as number,
        organizationId: session?.orgId as unknown as number,
        permissionId: params.permissionId
      },
      onSuccess: () => {
        setApplicationIsLoading({
          applicationPermissionId: 0,
          permissionName: ''
        })
      },
      onError: () => {
        setApplicationIsLoading({
          applicationPermissionId: 0,
          permissionName: ''
        })
      }
    })
  }

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: '$lg',
        padding: '$lg',
        overflowY: 'auto',
        '@sm': { padding: '$md' }
      }}
    >
      {isLoading ? (
        <WrappedLoader />
      ) : (
        <Grid xl={10} md={12}>
          <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
            <Grid spacing="$lg">
              <Grid xl={6} sm={4}>
                <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                  {
                    i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                      .permissionsPerStudents
                  }
                </Typography>
              </Grid>
              <Grid xl={3} sm={4} css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
                  {
                    i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                      .sendData
                  }
                </Typography>
                <Tooltip
                  label={
                    i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                      .helpSendData
                  }
                >
                  <Div css={{ display: 'flex' }}>
                    <InfoIcon size={18} />
                  </Div>
                </Tooltip>
              </Grid>
              <Grid xl={3} sm={4} css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                <Typography variant="titleSmall" css={{ color: '$on-surface' }}>
                  {
                    i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                      .minimization
                  }
                </Typography>
                <Tooltip
                  label={
                    i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                      .helpSendPermissible
                  }
                >
                  <Div css={{ display: 'flex' }}>
                    <InfoIcon size={18} />
                  </Div>
                </Tooltip>
              </Grid>
            </Grid>
            {applicationPermissions?.map((permission) => (
              <Fragment key={permission.id}>
                <Grid spacing="$lg">
                  <Grid xl={6} sm={4}>
                    <Div css={{ display: 'flex', alignItems: 'center', height: 40 }}>
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {permission.title}
                      </Typography>
                    </Div>
                  </Grid>
                  <Grid xl={3} sm={4} css={{ display: 'flex', flexDirection: 'column' }}>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Switch
                        isLoading={
                          applicationIsLoading.applicationPermissionId === permission.id &&
                          applicationIsLoading.permissionName === 'permissible'
                        }
                        inputProps={{
                          checked: permission.permissible,
                          onChange: () => handleChangePermission(permission, 'permissible')
                        }}
                        css={{ padding: '$2xs' }}
                      />
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission.switchLabel(
                          permission.permissible
                        )}
                      </Typography>
                    </Div>
                    <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                      {
                        i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission
                          .recommended
                      }
                    </Typography>
                  </Grid>
                  <Grid xl={3} sm={4} css={{ display: 'flex', flexDirection: 'column' }}>
                    <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                      <Switch
                        isLoading={
                          applicationIsLoading.applicationPermissionId === permission.id &&
                          applicationIsLoading.permissionName === 'anonymizable'
                        }
                        inputProps={{
                          checked: permission.anonymizable,
                          onChange: () => handleChangePermission(permission, 'anonymizable')
                        }}
                        css={{ padding: '$2xs' }}
                      />
                      <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                        {i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission.switchLabel(
                          permission.anonymizable
                        )}
                      </Typography>
                    </Div>
                    <Typography variant="labelSmall" css={{ color: '$on-surface-variant' }}>
                      {i18n().modules.hub.licenses.pages.applicationAccess.applicationPermission.anonymizedExample(
                        permission.anonymizedExample
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider css={{ '&:last-child': { display: 'none' } }} />
              </Fragment>
            ))}
          </BaseCard>
        </Grid>
      )}
    </Div>
  )
}
