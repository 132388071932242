import { UnprocessableEntityException } from '@/common/exceptions'
import { i18n } from '@/common/i18n'
import { decryptValue, encryptValue, hubApiService } from '@/common/services'

import {
  ShowUserFlowApiParams,
  ShowUserFlowApiResult,
  ShowUserFlowServiceParams,
  ShowUserFlowServiceResult,
  UpdateUserFlowApiParams,
  UpdateUserFlowApiResult,
  UpdateUserFlowServiceParams,
  UpdateUserFlowServiceResult
} from './contracts'
import { getHubotSecretKeyRepository } from './repositories'
import { fromUserFlowApiSanitizer, toUserFlowApiSanitizer } from './sanitizers'

export async function showUserFlowService(
  params: ShowUserFlowServiceParams
): Promise<ShowUserFlowServiceResult> {
  const { data } = await hubApiService<ShowUserFlowApiParams, ShowUserFlowApiResult>({
    resource: i18n().modules.hub.hubot.resources.showUserFlow,
    method: 'get',
    url: '/hubot/v1/user-flows',
    params: {
      aid: params.applicationId,
      uid: params.profileId
    }
  })

  const hubotSecretKey = getHubotSecretKeyRepository()

  if (!hubotSecretKey) {
    throw new UnprocessableEntityException({
      message: i18n().modules.hub.hubot.services.secretKeyNotConfigured
    })
  }

  return fromUserFlowApiSanitizer(data, (value) => decryptValue(value, hubotSecretKey))[0]
}

export async function updateUserFlowService({
  id,
  ...params
}: UpdateUserFlowServiceParams): Promise<UpdateUserFlowServiceResult> {
  const hubotSecretKey = getHubotSecretKeyRepository()

  if (!hubotSecretKey) {
    throw new UnprocessableEntityException({
      message: i18n().modules.hub.hubot.services.secretKeyNotConfigured
    })
  }

  await hubApiService<UpdateUserFlowApiParams, UpdateUserFlowApiResult>({
    resource: i18n().modules.hub.hubot.resources.updateUserFlow,
    method: 'put',
    url: `/hubot/v1/user-flows/${id}`,
    body: toUserFlowApiSanitizer(params, (value) => encryptValue(value, hubotSecretKey))
  })

  return { ...params, id }
}
