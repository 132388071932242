import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundBottomRightMobile({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="72"
      height="226"
      viewBox="0 0 72 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M88.8876 142.327C88.9393 142.139 88.9651 141.797 88.5903 141.545C82.0433 137.176 74.0809 134.959 64.9228 134.959C53.9552 134.959 44.8359 138.223 37.8235 144.654C30.7336 151.149 27.1402 159.402 27.1402 169.181C27.1402 178.959 30.068 187.148 35.8459 192.525C38.9158 195.382 43.065 198.342 48.1772 201.308C48.5004 201.496 48.7783 201.379 48.914 201.295C49.0497 201.211 49.2824 201.004 49.2501 200.623C48.8042 194.664 48.7137 189.203 48.9916 184.388C49.0045 184.181 48.9076 183.974 48.746 183.826C44.2994 179.883 42.5674 175.663 42.5674 168.78C42.5674 157.612 52.0938 149.191 64.729 149.191C65.9828 149.191 67.2301 149.255 68.4258 149.385C68.6067 149.404 68.7748 149.372 68.917 149.281C69.2078 149.107 69.5051 148.926 69.8089 148.758C75.8259 145.287 82.0304 143.296 88.2478 142.85C88.6937 142.818 88.8424 142.508 88.8941 142.32L88.8876 142.327Z"
          css={{ fill }}
        />
        <Path
          d="M94.84 260.781C101.93 254.285 105.523 246.032 105.523 236.254C105.523 226.475 102.596 218.286 96.8177 212.909C93.7478 210.053 89.5985 207.093 84.4863 204.126C84.1631 203.939 83.8852 204.055 83.7495 204.139C83.6138 204.223 83.3811 204.43 83.4134 204.811C83.8594 210.77 83.9499 216.231 83.672 221.046C83.659 221.253 83.756 221.46 83.9175 221.608C88.3641 225.551 90.0962 229.771 90.0962 236.654C90.0962 247.822 80.5697 256.244 67.9346 256.244C66.6808 256.244 65.4334 256.179 64.2378 256.05C64.2055 256.05 64.1731 256.05 64.1408 256.05C63.9986 256.05 63.8629 256.088 63.7466 256.16C63.4557 256.334 63.1585 256.509 62.8547 256.683C56.8441 260.154 50.6396 262.144 44.4158 262.59C43.9698 262.623 43.8212 262.933 43.7695 263.12C43.7178 263.308 43.6919 263.65 44.0668 263.902C50.6073 268.271 58.5697 270.488 67.7278 270.488C78.6955 270.488 87.8148 267.224 94.8271 260.794L94.84 260.781Z"
          css={{ fill }}
        />
        <Path
          d="M2.75537 213.375C2.56795 213.426 2.25772 213.575 2.22541 214.027C1.71483 221.88 3.77653 229.881 8.35232 237.811C13.8329 247.305 21.2201 253.574 30.2942 256.437C39.4651 259.326 48.4099 258.312 56.8764 253.426C65.6338 248.372 70.9722 241.909 72.7431 234.218C73.6867 230.127 74.1649 225.06 74.1843 219.146C74.1843 218.771 73.9452 218.59 73.8094 218.519C73.6673 218.442 73.3764 218.345 73.0597 218.564C68.122 221.932 63.4364 224.737 59.132 226.908C58.951 226.999 58.8153 227.186 58.7701 227.406C57.5809 233.229 54.7889 236.835 48.83 240.28C39.1549 245.864 27.1014 241.825 20.7806 230.883C20.1537 229.797 19.585 228.685 19.1003 227.587C19.0292 227.419 18.9128 227.296 18.7707 227.212C18.4734 227.044 18.1696 226.876 17.8723 226.701C11.8553 223.231 7.03387 218.849 3.53739 213.685C3.28534 213.31 2.9428 213.342 2.75537 213.394V213.375Z"
          css={{ fill }}
        />
        <Path
          d="M102.363 148.997C93.1919 146.108 84.2472 147.122 75.7807 152.008C67.0233 157.063 61.6849 163.526 59.914 171.217C58.9704 175.308 58.4922 180.375 58.4728 186.288C58.4728 186.663 58.7119 186.844 58.8476 186.915C58.9898 186.993 59.2807 187.09 59.5973 186.87C64.5351 183.503 69.2207 180.698 73.5251 178.526C73.706 178.436 73.8418 178.248 73.887 178.028C75.0762 172.205 77.8682 168.599 83.8271 165.154C93.5022 159.57 105.556 163.61 111.876 174.551C112.503 175.637 113.072 176.749 113.557 177.848C113.57 177.88 113.583 177.906 113.602 177.932C113.673 178.054 113.77 178.158 113.893 178.222C114.19 178.39 114.494 178.558 114.791 178.733C120.802 182.204 125.63 186.585 129.126 191.749C129.378 192.124 129.721 192.092 129.908 192.047C130.096 191.995 130.406 191.846 130.438 191.394C130.949 183.541 128.887 175.54 124.311 167.61C118.831 158.11 111.443 151.847 102.369 148.984L102.363 148.997Z"
          css={{ fill }}
        />
        <Path
          d="M107.352 252.443C107.488 252.579 107.779 252.773 108.18 252.573C115.237 249.089 121.131 243.305 125.714 235.375C131.194 225.88 132.933 216.348 130.871 207.06C128.79 197.669 123.439 190.431 114.966 185.545C106.208 180.491 97.9422 179.101 90.3999 181.409C86.3864 182.637 81.7525 184.756 76.6273 187.697C76.3042 187.885 76.2654 188.182 76.2719 188.337C76.2719 188.499 76.3365 188.796 76.6855 188.964C82.0691 191.556 86.8388 194.212 90.8717 196.855C91.0398 196.965 91.2724 196.991 91.4793 196.92C97.115 195.039 101.639 195.647 107.598 199.091C117.273 204.675 119.8 217.136 113.486 228.084C112.859 229.17 112.18 230.217 111.469 231.187C111.359 231.335 111.308 231.497 111.314 231.658C111.321 232.001 111.327 232.343 111.327 232.692C111.327 239.64 109.944 246.006 107.217 251.616C107.023 252.017 107.217 252.308 107.352 252.437V252.443Z"
          css={{ fill }}
        />
        <Path
          d="M1.79239 198.374C3.87347 207.765 9.22482 215.003 17.6978 219.889C26.4551 224.943 34.7213 226.333 42.2636 224.026C46.2771 222.798 50.9111 220.678 56.0362 217.737C56.3594 217.55 56.3982 217.252 56.3917 217.097C56.3917 216.936 56.3271 216.638 55.9781 216.47C50.5944 213.879 45.8247 211.222 41.7918 208.579C41.6238 208.469 41.3911 208.443 41.1843 208.514C35.5486 210.395 31.0245 209.788 25.0656 206.343C15.3905 200.759 12.8635 188.298 19.1778 177.35C19.8047 176.264 20.4833 175.217 21.1943 174.248C21.2137 174.222 21.2331 174.196 21.246 174.17C21.3171 174.047 21.3559 173.912 21.3494 173.776C21.3429 173.433 21.3365 173.091 21.3365 172.742C21.3365 165.8 22.7195 159.434 25.4405 153.818C25.6343 153.417 25.4405 153.127 25.3047 152.997C25.169 152.862 24.8782 152.668 24.4775 152.868C17.4199 156.352 11.5256 162.136 6.94339 170.066C1.46278 179.554 -0.269305 189.08 1.79239 198.374Z"
          css={{ fill }}
        />
        <Path
          d="M28.3316 48.7313C28.3122 48.8017 28.3024 48.9303 28.4432 49.0249C30.9011 50.6651 33.8905 51.4974 37.3287 51.4974C41.4463 51.4974 44.8699 50.2721 47.5026 47.8578C50.1643 45.4193 51.5134 42.3207 51.5134 38.6496C51.5134 34.9784 50.4143 31.9042 48.245 29.8854C47.0925 28.813 45.5348 27.7017 43.6155 26.588C43.4941 26.5176 43.3898 26.5613 43.3389 26.5928C43.2879 26.6243 43.2006 26.702 43.2127 26.8451C43.3801 29.0823 43.4141 31.1326 43.3097 32.9403C43.3049 33.0179 43.3413 33.0956 43.4019 33.1514C45.0713 34.6315 45.7216 36.2159 45.7216 38.8C45.7216 42.9929 42.1451 46.1545 37.4015 46.1545C36.9307 46.1545 36.4624 46.1302 36.0136 46.0817C35.9456 46.0744 35.8825 46.0865 35.8291 46.1205C35.72 46.186 35.6083 46.2539 35.4943 46.317C33.2353 47.62 30.906 48.3674 28.5718 48.5348C28.4044 48.5469 28.3485 48.6634 28.3291 48.7337L28.3316 48.7313Z"
          css={{ fill }}
        />
        <Path
          d="M26.0968 4.26018C23.4351 6.69872 22.086 9.79723 22.086 13.4684C22.086 17.1395 23.1852 20.2138 25.3544 22.2326C26.5069 23.305 28.0647 24.4163 29.9839 25.53C30.1053 25.6004 30.2096 25.5567 30.2606 25.5252C30.3115 25.4936 30.3989 25.416 30.3867 25.2728C30.2193 23.0357 30.1853 20.9854 30.2897 19.1777C30.2945 19.1001 30.2581 19.0224 30.1975 18.9666C28.5281 17.4865 27.8778 15.9021 27.8778 13.3179C27.8778 9.12512 31.4543 5.96352 36.198 5.96352C36.6687 5.96352 37.137 5.98777 37.5859 6.0363C37.598 6.0363 37.6101 6.0363 37.6223 6.0363C37.6756 6.0363 37.7266 6.02175 37.7703 5.99506C37.8795 5.92954 37.9911 5.86403 38.1051 5.79852C40.3617 4.49554 42.691 3.74822 45.0276 3.5808C45.1951 3.56866 45.2509 3.45219 45.2703 3.38182C45.2897 3.31145 45.2994 3.18285 45.1587 3.08823C42.7031 1.44798 39.7138 0.615723 36.2756 0.615723C32.158 0.615723 28.7343 1.84105 26.1017 4.25533L26.0968 4.26018Z"
          css={{ fill }}
        />
        <Path
          d="M60.6682 22.0578C60.7386 22.0384 60.8551 21.9826 60.8672 21.8128C61.0589 18.8647 60.2849 15.8608 58.567 12.8836C56.5094 9.31924 53.736 6.96561 50.3293 5.89072C46.8863 4.80611 43.5281 5.18706 40.3495 7.02142C37.0618 8.91887 35.0575 11.3453 34.3927 14.2327C34.0385 15.7686 33.8589 17.6709 33.8516 19.8911C33.8516 20.0318 33.9414 20.0997 33.9924 20.1264C34.0457 20.1556 34.1549 20.1919 34.2738 20.1095C36.1276 18.8453 37.8867 17.7922 39.5027 16.977C39.5707 16.943 39.6216 16.8726 39.6386 16.7901C40.0851 14.6039 41.1333 13.25 43.3704 11.9567C47.0027 9.86031 51.528 11.3768 53.901 15.4847C54.1364 15.8924 54.3499 16.3097 54.5319 16.7222C54.5586 16.7853 54.6022 16.8314 54.6556 16.8629C54.7672 16.926 54.8813 16.9891 54.9929 17.0546C57.2519 18.3576 59.062 20.0027 60.3746 21.9414C60.4693 22.0821 60.5979 22.07 60.6682 22.0506V22.0578Z"
          css={{ fill }}
        />
        <Path
          d="M23.2725 46.2273C26.7156 47.3119 30.0737 46.9309 33.2523 45.0966C36.5401 43.1991 38.5443 40.7727 39.2091 37.8853C39.5634 36.3494 39.7429 34.4471 39.7502 32.2269C39.7502 32.0862 39.6604 32.0182 39.6095 31.9915C39.5561 31.9624 39.4469 31.926 39.328 32.0085C37.4742 33.2727 35.7151 34.3258 34.0991 35.141C34.0312 35.175 33.9802 35.2454 33.9632 35.3279C33.5168 37.514 32.4686 38.868 30.2314 40.1613C26.5991 42.2577 22.0739 40.7412 19.7009 36.6333C19.4655 36.2256 19.252 35.8083 19.07 35.3958C19.0651 35.3837 19.0603 35.3739 19.053 35.3642C19.0263 35.3181 18.9899 35.2793 18.9438 35.2551C18.8322 35.192 18.7182 35.1289 18.6065 35.0634C16.35 33.7604 14.5375 32.1153 13.2248 30.1766C13.1301 30.0359 13.0016 30.048 12.9312 30.065C12.8608 30.0844 12.7444 30.1402 12.7322 30.31C12.5405 33.2581 13.3146 36.262 15.0325 39.2392C17.09 42.806 19.8634 45.1572 23.2701 46.2321L23.2725 46.2273Z"
          css={{ fill }}
        />
        <Path
          d="M21.3993 7.39024C21.3484 7.33929 21.2392 7.2665 21.0887 7.34172C18.4391 8.64955 16.2262 10.8212 14.5059 13.7984C12.4483 17.3628 11.7956 20.9417 12.5696 24.4285C13.351 27.954 15.36 30.6716 18.541 32.506C21.8288 34.4034 24.9322 34.9251 27.7638 34.0588C29.2706 33.5978 31.0103 32.802 32.9344 31.698C33.0558 31.6276 33.0703 31.516 33.0679 31.4577C33.0679 31.3971 33.0436 31.2855 32.9126 31.2224C30.8914 30.2494 29.1007 29.2521 27.5867 28.2597C27.5236 28.2185 27.4362 28.2088 27.3586 28.2355C25.2428 28.9416 23.5443 28.7135 21.3071 27.4202C17.6748 25.3238 16.7261 20.6457 19.0967 16.5354C19.332 16.1277 19.5868 15.7346 19.8537 15.3707C19.895 15.3149 19.9144 15.2542 19.9119 15.1936C19.9095 15.065 19.9071 14.9364 19.9071 14.8053C19.9071 12.1969 20.4263 9.80694 21.4503 7.70082C21.5231 7.55038 21.4503 7.4412 21.3993 7.39267V7.39024Z"
          css={{ fill }}
        />
        <Path
          d="M61.0298 27.6895C60.2485 24.164 58.2394 21.4464 55.0584 19.612C51.7706 17.7146 48.6672 17.1929 45.8356 18.0591C44.3288 18.5202 42.5891 19.316 40.665 20.42C40.5436 20.4904 40.5291 20.602 40.5315 20.6602C40.5315 20.7209 40.5558 20.8325 40.6868 20.8956C42.708 21.8686 44.4987 22.8658 46.0128 23.8582C46.0758 23.8995 46.1632 23.9092 46.2408 23.8825C48.3567 23.1764 50.0551 23.4045 52.2923 24.6978C55.9246 26.7942 56.8733 31.4723 54.5027 35.5826C54.2674 35.9903 54.0126 36.3833 53.7457 36.7473C53.7384 36.757 53.7311 36.7667 53.7263 36.7764C53.6996 36.8225 53.685 36.8735 53.6875 36.9244C53.6899 37.053 53.6923 37.1816 53.6923 37.3127C53.6923 39.9186 53.1731 42.3086 52.1516 44.4172C52.0788 44.5676 52.1516 44.6768 52.2025 44.7253C52.2535 44.7763 52.3627 44.8491 52.5131 44.7738C55.1627 43.466 57.3756 41.2944 59.0959 38.3172C61.1535 34.7552 61.8038 31.1787 61.0298 27.6895Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
