import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { InfoIcon } from '@positivote/design-system/icons/Info'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useShowContract } from '@/modules/billing/contracts/hooks'
import {
  SalesOrderAccordionList,
  SalesOrderAccordionListRef
} from '@/modules/billing/sales-orders/components/AccordionList'
import { ListSalesOrderHookParams } from '@/modules/billing/sales-orders/contracts'
import { useListSalesOrder } from '@/modules/billing/sales-orders/hooks'

export function ContractDetails(): JSX.Element {
  changePageTitle(i18n().modules.billing.contracts.pages.details.pageTitle)

  const params = useParams()
  const navigate = useNavigate()
  const salesOrderAccordionListRef = useRef<SalesOrderAccordionListRef>(null)

  const [listSalesOrderParams, setListSalesOrderParams] = useState<
    ListSalesOrderHookParams['model']
  >({ perPage: 5, page: 1, contractId: params.id })

  const listSalesOrder = useListSalesOrder({
    model: listSalesOrderParams,
    queryOptions: {
      enabled: !!params.id
    }
  })

  const showContract = useShowContract({
    model: {
      contractId: params.id as unknown as string
    },
    queryOptions: {
      enabled: !!params.id
    },
    onError: () => {
      navigate('/not-found', { replace: true })
    }
  })

  useEffect(() => {
    if (listSalesOrder.isFetching) {
      salesOrderAccordionListRef.current?.setOpenSalesOrders({})
    }
  }, [listSalesOrder.isFetching])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.billing.contracts.pages.details.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.billing.contracts.pages.details.appBar.breadcrumbs.overview,
            onClick: () => navigate(-1)
          },
          {
            label: i18n().modules.billing.contracts.pages.details.appBar.breadcrumbs.contract(
              params.id ?? ''
            )
          }
        ]}
      />
      {listSalesOrder.isFetching || showContract.isFetching ? (
        <WrappedLoader />
      ) : (
        showContract.data && (
          <Div css={{ padding: '$lg', overflow: 'auto', '@sm': { padding: '$md' } }}>
            <BaseCard
              css={{
                backgroundColor: '$surface-5',
                borderRadius: '$xl',
                '& .BaseCard-StateLayer': { padding: '$lg', gap: '$md' }
              }}
            >
              <Div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flex: 1
                }}
              >
                <Div css={{ display: 'flex', gap: '$4xs', flexDirection: 'column' }}>
                  <Chip
                    data-testid="contract"
                    label={`${i18n().modules.billing.contracts.pages.details.contract} ${
                      showContract.data.id
                    }`}
                  />
                  <Typography
                    data-testid="Typography-institutionName"
                    variant="titleLarge"
                    css={{ color: '$on-surface' }}
                  >
                    {showContract.data.institution.name}
                  </Typography>
                  <Typography
                    data-testid="Typography-tradeName"
                    variant="bodySmall"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {showContract.data.institution.tradeName}
                  </Typography>
                </Div>
                <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                  <TodayIcon data-testid="TodayIcon" size={18} />
                  <Typography
                    data-testid="Typography-endDateFormatted"
                    variant="labelMedium"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.billing.contracts.pages.details.endContract}{' '}
                    {showContract.data.endDateFormatted}
                  </Typography>
                </Div>
              </Div>
              <Divider />
              <Div css={{ display: 'flex', gap: '$lg', '@sm': { flexDirection: 'column' } }}>
                <Div
                  css={{ display: 'flex', flexDirection: 'column', gap: '$4xs', minWidth: '260px' }}
                >
                  <Typography
                    data-testid="Typography-information-Label"
                    variant="labelMedium"
                    css={{ color: '$on-surface' }}
                  >
                    {i18n().modules.billing.contracts.pages.details.information}
                  </Typography>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-numberContract-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.numberContract}
                    </Typography>
                    <Typography
                      data-testid="Typography-numberContract-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.id}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-sapClient-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.sapClient}
                    </Typography>
                    <Typography
                      data-testid="Typography-sapClient-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.institution.id}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-cnpj-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.cnpj}
                    </Typography>
                    <Typography
                      data-testid="Typography-cnpj-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.institutionFormatted.cinNumberFormatted}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-location-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.location}
                    </Typography>
                    <Typography
                      data-testid="Typography-location-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.institutionFormatted.addressFormatted.location}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-typeInvoice-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.typeInvoice}
                    </Typography>
                    <Typography
                      data-testid="Typography-typeInvoice-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.frequencyMonthsFormatted}
                    </Typography>
                  </Div>
                  <Div css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}>
                    <Typography
                      data-testid="Typography-typeSchoolLabel-Label"
                      variant="labelMedium"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.typeSchoolLabel}
                    </Typography>
                    <Typography
                      data-testid="Typography-typeSchoolLabel-Value"
                      variant="titleSmall"
                      css={{ color: '$on-surface' }}
                    >
                      {showContract.data.institutionFormatted.typeFormatted}
                    </Typography>
                  </Div>
                </Div>
                <Div css={{ display: 'flex', gap: '$2xs', flexDirection: 'column', flex: 1 }}>
                  <Typography
                    data-testid="Typography-overview"
                    css={{ color: '$on-surface' }}
                    variant="labelMedium"
                  >
                    {i18n().modules.billing.contracts.pages.details.overview}
                  </Typography>
                  <Div css={{ display: 'flex', gap: '$md', justifyContent: 'space-between' }}>
                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '$surface',
                        alignItems: 'center',
                        flex: 1,
                        gap: '$2xs',
                        padding: '$md',
                        borderRadius: '$lg'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '$md',
                          minWidth: '100%'
                        }}
                      >
                        <Typography
                          data-testid="Typography-totalContract-Label"
                          variant="titleMedium"
                          css={{ color: '$on-surface' }}
                        >
                          {i18n().modules.billing.contracts.pages.details.totalContract}
                        </Typography>
                        <Tooltip
                          data-testid="Tooltip-totalContract"
                          placement="top-end"
                          arrow
                          label={
                            i18n().modules.billing.contracts.pages.details.tooltipTotalContract
                          }
                        >
                          <InfoIcon
                            data-testid="InfoIcon-tooltipTotalContract"
                            size={18}
                            css={{ '&:hover path': { fill: '$primary' } }}
                          />
                        </Tooltip>
                      </Div>

                      <Div
                        css={{
                          display: 'flex',
                          height: '72px',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          data-testid="Typography-totalContract-Value"
                          variant="headlineSmall"
                          css={{ color: '$on-surface' }}
                        >
                          {showContract.data.totalContractFormatted}
                        </Typography>
                      </Div>
                    </Div>

                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '$surface',
                        gap: '$2xs',
                        padding: '$md',
                        flex: 1,
                        alignItems: 'center',
                        borderRadius: '$lg'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '$md',
                          minWidth: '100%'
                        }}
                      >
                        <Typography
                          data-testid="Typography-invoicedAmount-Label"
                          variant="titleMedium"
                          css={{ color: '$on-surface' }}
                        >
                          {i18n().modules.billing.contracts.pages.details.invoicedAmount}
                        </Typography>
                        <Tooltip
                          data-testid="Tooltip-invoicedAmount"
                          placement="top-end"
                          arrow
                          label={
                            i18n().modules.billing.contracts.pages.details.tooltipInvoicedAmount
                          }
                        >
                          <InfoIcon
                            data-testid="InfoIcon-tooltipInvoicedAmount"
                            size={18}
                            css={{ '&:hover path': { fill: '$primary' } }}
                          />
                        </Tooltip>
                      </Div>

                      <Div
                        css={{
                          display: 'flex',
                          height: '72px',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          data-testid="Typography-invoicedAmount-Value"
                          variant="headlineSmall"
                          css={{ color: '$on-surface' }}
                        >
                          {showContract.data.totalOrdersFormatted}
                        </Typography>
                      </Div>
                    </Div>

                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '$surface',
                        gap: '$2xs',
                        padding: '$md',
                        flex: 1,
                        alignItems: 'center',
                        borderRadius: '$lg'
                      }}
                    >
                      <Div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '$md',
                          minWidth: '100%'
                        }}
                      >
                        <Typography
                          data-testid="Typography-amountToBeInvoiced-Label"
                          variant="titleMedium"
                          css={{ color: '$on-surface' }}
                        >
                          {i18n().modules.billing.contracts.pages.details.amountToBeInvoiced}
                        </Typography>
                        <Tooltip
                          data-testid="Tooltip-amountToBeInvoiced"
                          placement="top-end"
                          arrow
                          label={
                            i18n().modules.billing.contracts.pages.details.tooltipAmountToBeInvoiced
                          }
                        >
                          <InfoIcon
                            data-testid="InfoIcon-tooltipAmountToBeInvoiced"
                            size={18}
                            css={{ '&:hover path': { fill: '$primary' } }}
                          />
                        </Tooltip>
                      </Div>

                      <Div
                        css={{
                          display: 'flex',
                          height: '72px',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          data-testid="Typography-amountToBeInvoiced-Value"
                          variant="headlineSmall"
                          css={{ color: '$on-surface' }}
                        >
                          {showContract.data.toBillFormatted}
                        </Typography>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>
              {!!listSalesOrder.data?.registers.length && (
                <>
                  <Divider data-testid="Divider-List-SalesOrder" />
                  <Div
                    data-testid="Container-List-SalesOrder"
                    css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}
                  >
                    <Typography
                      data-testid="Typography-salesOrder"
                      variant="labelMedium"
                      css={{ color: '$on-surface' }}
                    >
                      {i18n().modules.billing.contracts.pages.details.salesOrder}
                    </Typography>
                    <SalesOrderAccordionList
                      ref={salesOrderAccordionListRef}
                      showContractId={false}
                      accordionCss={{
                        backgroundColor: '$surface',
                        borderRadius: '$md',
                        '& .SalesOrderAccordionList-Container-Service': {
                          backgroundColor: '$surface-1'
                        }
                      }}
                      isLoading={listSalesOrder.isFetching}
                      salesOrders={listSalesOrder.data.registers}
                    />
                    {listSalesOrder.data.lastPage > 1 && (
                      <Pagination
                        lastPage={listSalesOrder.data.lastPage}
                        page={listSalesOrderParams.page ?? 1}
                        setPage={(page) =>
                          setListSalesOrderParams((oldState) => ({ ...oldState, page }))
                        }
                      />
                    )}
                  </Div>
                </>
              )}
            </BaseCard>
          </Div>
        )
      )}
    </Main>
  )
}
