import * as Dialog from '@positivote/design-system/components/Dialog'
import { Div } from '@positivote/design-system/components/Div'
import { LI } from '@positivote/design-system/components/LI'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { CloseIcon } from '@positivote/design-system/icons/Close'

import { i18n } from '@/common/i18n'

export interface AttentionDialogProps {
  /**
   * The contente error fields
   */
  errorFields: Array<{ key: string; value: string }>
  /**
   * The accept action.
   */
  acceptAction: () => void
  /**
   * The cancel action.
   */
  onCancel: () => void
  /**
   * The data-testid.
   */
  'data-testid': string
}

export function AttentionDialog({
  errorFields,
  acceptAction,
  onCancel,
  'data-testid': dataTestid
}: AttentionDialogProps): JSX.Element {
  return (
    <Dialog.Container data-testid={dataTestid} onCancel={onCancel} isOpen={!!errorFields.length}>
      <Dialog.Header data-testid={dataTestid} align="center">
        <Dialog.HeaderTitle data-testid={dataTestid}>
          {i18n().modules.hub.profiles.components.attentionDialog.title}
        </Dialog.HeaderTitle>
        <Dialog.HeaderCloseButton data-testid={dataTestid} onCancel={onCancel} />
      </Dialog.Header>
      <Dialog.Content data-testid={dataTestid} align="center">
        <Dialog.ContentText data-testid={`subtitle-${dataTestid}`} css={{ textAlign: 'center' }}>
          {i18n().modules.hub.profiles.components.attentionDialog.subtitle}
        </Dialog.ContentText>
        <Dialog.ContentText
          data-testid={`subtitleBody-${dataTestid}`}
          css={{ textAlign: 'center' }}
        >
          {i18n().modules.hub.profiles.components.attentionDialog.subtitleBody}
        </Dialog.ContentText>
        <Div css={{ display: 'flex', justifyContent: 'center' }}>
          <UL>
            {errorFields.map((errorField, index) => (
              <LI
                key={errorField.key}
                css={{
                  '&::marker': {
                    color: '$critical'
                  }
                }}
              >
                <Typography
                  css={{ color: '$critical' }}
                  data-testid={`Typography-${index}-${dataTestid}`}
                  variant="bodyLarge"
                >
                  {(
                    i18n().modules.hub.profiles.components.attentionDialog.fields as Record<
                      string,
                      string
                    >
                  )[errorField.key] || errorField.key}{' '}
                  - {errorField.value}
                </Typography>
              </LI>
            ))}
          </UL>
        </Div>
      </Dialog.Content>
      <Dialog.Footer data-testid={dataTestid} align="center">
        <Dialog.FooterAcceptButton
          data-testid={dataTestid}
          LeadingIcon={<CloseIcon size={18} />}
          onClick={acceptAction}
        >
          {i18n().modules.hub.profiles.components.attentionDialog.acceptAction}
        </Dialog.FooterAcceptButton>
      </Dialog.Footer>
    </Dialog.Container>
  )
}
