import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Typography } from '@positivote/design-system/components/Typography'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'

import { i18n } from '@/common/i18n'
import { AddSapServiceForm } from '@/modules/billing/service-mapping/contracts'

interface SapServiceListProps {
  sapServices: AddSapServiceForm[]
  handleEdit?: (model: AddSapServiceForm) => void
  handleRemove?: (model: AddSapServiceForm) => void
}

export function SapServiceList({
  sapServices,
  handleEdit,
  handleRemove
}: SapServiceListProps): JSX.Element {
  return (
    <Div css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
      {sapServices.map((sapService, index) => (
        <BaseCard
          key={sapService.id}
          css={{
            borderRadius: '$md',
            '& .BaseCard-StateLayer': {
              flexDirection: 'row',
              alignItems: 'center',
              gap: '$md',
              padding: '$2xs $md'
            }
          }}
        >
          <Div css={{ display: 'flex', flex: 1, gap: '$4xs' }}>
            <Typography
              data-testid={`Typography-serviceLabel-${index}`}
              variant="labelLarge"
              css={{ color: '$on-surface' }}
            >
              {i18n().modules.billing.serviceMapping.components.sapServiceList.service}
            </Typography>
            <Typography
              data-testid={`Typography-serviceValue-${index}`}
              variant="bodyMedium"
              css={{ color: '$on-surface' }}
            >
              {sapService.name}
            </Typography>
          </Div>
          <Div css={{ display: 'flex', flex: 1, gap: '$4xs' }}>
            <Typography
              data-testid={`Typography-sapIdLabel-${index}`}
              variant="labelLarge"
              css={{ color: '$on-surface' }}
            >
              {i18n().modules.billing.serviceMapping.components.sapServiceList.sapId}
            </Typography>
            <Typography
              data-testid={`Typography-sapIdValue-${index}`}
              variant="bodyMedium"
              css={{ color: '$on-surface' }}
            >
              {sapService.id}
            </Typography>
          </Div>
          {handleEdit && (
            <IconButton
              data-testid={`edit-${index}`}
              variant="standard"
              size="$lg"
              onClick={() => handleEdit(sapService)}
            >
              <EditIcon />
            </IconButton>
          )}
          {handleRemove && (
            <IconButton
              data-testid={`delete-${index}`}
              variant="standard"
              size="$lg"
              onClick={() => handleRemove(sapService)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </BaseCard>
      ))}
    </Div>
  )
}
