import * as yup from 'yup'

import { currencyUnmask } from '@/common/helpers'
import { i18n } from '@/common/i18n'

import { SalesOrderOperationType, ServiceOperationForm } from './contracts'

export const generateInvoiceValidationSchema = yup.object().shape({
  emailsToInvoice: yup
    .array()
    .of(
      yup
        .string()
        .required(i18n().common.validators.required)
        .email(i18n().common.validators.emailInvalid)
    )
    .required(i18n().common.validators.required)
    .min(1, i18n().common.validators.required),
  shouldSave: yup.boolean().required(i18n().common.validators.required)
})

export function makeServiceOperationValidationSchema(
  operationType: SalesOrderOperationType,
  maxValue: number,
  maxValueFormatted: string
): yup.ObjectSchema<ServiceOperationForm> {
  return yup.object().shape({
    value: yup.string().test({
      name: 'smallerThanCurrency',
      message: i18n().common.validators.maxValue(maxValueFormatted),
      test: (value) => operationType === 'addition' || currencyUnmask(value ?? '0') <= maxValue
    })
  })
}
