import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { QrCodeReader } from '@positivote/design-system/components/QrCodeReader'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CameraIcon } from '@positivote/design-system/icons/Camera'
import { QrCodeIcon } from '@positivote/design-system/icons/QrCode'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TextDialog } from '@/common/components/TextDialog'
import { changePageTitle } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { BaseBackground } from '@/common/layouts/BaseBackground'
import { useAuth } from '@/modules/hub/auth/contexts'

let alreadyLogin = false

export function QrCode(): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.qrCode.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()
  const { createSession, isLoading } = useAuth()
  const { handleError } = useErrorHandler({ ignoreLogout: true })
  const [isNotWorkingDialogOpen, setIsNotWorkingDialogOpen] = useState(false)
  const [qrCodeError, setQrCodeError] = useState<string>()

  function onSubmit(code: string): void {
    if (!alreadyLogin) {
      alreadyLogin = true
      void createSession({
        model: {
          authFlow: 'QRCODE',
          code
        },
        onSuccess: () => {
          alreadyLogin = false
        },
        onError: ({ error }) => {
          alreadyLogin = false
          handleError({ error })
        }
      })
    }
  }

  return (
    <BaseBackground>
      <TextDialog
        data-testid="notWorking"
        align="center"
        isOpen={isNotWorkingDialogOpen}
        onCancel={() => setIsNotWorkingDialogOpen(false)}
        title={{ label: i18n().modules.hub.auth.pages.qrCode.notWorkingDialog.title }}
        contentTexts={i18n().modules.hub.auth.pages.qrCode.notWorkingDialog.contentTexts}
        acceptAction={{
          handle: () => setIsNotWorkingDialogOpen(false),
          label: i18n().modules.hub.auth.pages.qrCode.notWorkingDialog.acceptAction
        }}
        css={{
          maxWidth: 424,
          '& .Dialog-Content': { overflowY: 'auto' }
        }}
      />
      <Grid
        xl={4}
        lg={5}
        md={6}
        sm={8}
        xs={12}
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography
          variant={
            breakpoint === Breakpoint.sm
              ? 'headlineSmall'
              : breakpoint === Breakpoint.xs
                ? 'titleLarge'
                : 'headlineMedium'
          }
          css={{
            color: '$on-surface',
            textAlign: 'center',
            marginBottom: '$lg',
            '@sm': { marginBottom: '$md' }
          }}
        >
          {i18n().modules.hub.auth.pages.qrCode.title}
        </Typography>
        <Grid
          xl={8}
          md={10}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '$surface-5',
            borderRadius: '$lg',
            height: 200,
            overflow: 'hidden',
            position: 'relative',
            marginBottom: '$lg',
            '@sm': { marginBottom: '$md' }
          }}
        >
          {isLoading ? (
            <Loader size="$5xl" />
          ) : (
            <>
              {qrCodeError === 'PERMISSION' ? (
                <Div
                  css={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '$md',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CameraIcon size="$lg" fill="$on-surface-variant" />
                  <Typography
                    variant="bodySmall"
                    css={{
                      color: '$on-surface-variant',
                      textAlign: 'center',
                      marginTop: '$2xs',
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {i18n().modules.hub.auth.pages.qrCode.error}
                  </Typography>
                </Div>
              ) : (
                <QrCodeIcon
                  removePadding
                  size="$5xl"
                  fill="$on-surface"
                  css={{ position: 'absolute' }}
                />
              )}

              <QrCodeReader onRead={onSubmit} onError={setQrCodeError} />
            </>
          )}
        </Grid>
        <Typography
          variant={breakpoint <= Breakpoint.sm ? 'titleMedium' : 'headlineSmall'}
          css={{
            color: '$on-surface',
            textAlign: 'center',
            marginBottom: '$md',
            '@sm': { marginBottom: '$2xs' }
          }}
        >
          {i18n().modules.hub.auth.pages.qrCode.instructions.title}
        </Typography>
        <Typography
          variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
          css={{ color: '$on-surface-variant', textAlign: 'center' }}
        >
          1. {i18n().modules.hub.auth.pages.qrCode.instructions.one}
        </Typography>
        <Typography
          variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
          css={{ color: '$on-surface-variant', textAlign: 'center' }}
        >
          2. {i18n().modules.hub.auth.pages.qrCode.instructions.two}
        </Typography>
        <Typography
          variant={breakpoint <= Breakpoint.sm ? 'bodyMedium' : 'bodyLarge'}
          css={{ color: '$on-surface-variant', textAlign: 'center', marginBottom: '$md' }}
        >
          3. {i18n().modules.hub.auth.pages.qrCode.instructions.three}
        </Typography>
        <Button
          css={{ width: '100%', marginBottom: '$md', '@sm': { marginBottom: '$2xs' } }}
          onClick={() => setIsNotWorkingDialogOpen(true)}
          disabled={isLoading}
        >
          {i18n().modules.hub.auth.pages.qrCode.buttons.qrCodeNotWorking}
        </Button>
        <Button
          css={{ width: '100%' }}
          variant="outlined"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          {i18n().modules.hub.auth.pages.qrCode.buttons.goBack}
        </Button>
      </Grid>
    </BaseBackground>
  )
}
