import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  ListOnboardingPreRegisterHookParams,
  ListOnboardingPreRegisterHookResult,
  ResendOnboardingCodeHookParams,
  ShowOnboardingInRegisterDetailsHookParams,
  ShowOnboardingInRegisterDetailsHookResult
} from './contracts'
import {
  listOnboardingPreRegisterService,
  resendOnboardingCodeService,
  showOnboardingInRegisterDetailsService
} from './services'

export const hookKey = 'onboarding'

export function useListOnboardingPreRegister({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListOnboardingPreRegisterHookParams): UseQueryResult<
  ListOnboardingPreRegisterHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listPreRegister', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedOnboardingPreRegister = await listOnboardingPreRegisterService(model)
        onSuccess?.(listedOnboardingPreRegister)
        return listedOnboardingPreRegister
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useResendOnboardingCode(): UseMutationResult<
  void,
  ApplicationException,
  ResendOnboardingCodeHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'resendCode'],
    mutationFn: async ({ model, onSuccess, onError }: ResendOnboardingCodeHookParams) => {
      try {
        await resendOnboardingCodeService(model)
        addAlertMessage({
          alertKey: 'codeResent',
          severity: 'success',
          subTitle: i18n().modules.hub.onboarding.pages.list.tabs.preRegister.alert.codeResent
        })
        onSuccess?.(null)
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowOnboardingInRegisterDetails({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowOnboardingInRegisterDetailsHookParams): UseQueryResult<
  ShowOnboardingInRegisterDetailsHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'showInRegisterDetails', model],
    queryFn: async () => {
      try {
        const onboardingInRegisterDetailsShown = await showOnboardingInRegisterDetailsService(model)
        onSuccess?.(onboardingInRegisterDetailsShown)
        return onboardingInRegisterDetailsShown
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
