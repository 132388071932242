import * as yup from 'yup'

import { i18n } from '@/common/i18n'

export function makeUserFlowFormSchema(inputs: string[]): yup.ObjectSchema<Record<string, string>> {
  return yup.object().shape(
    inputs.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: yup.string().required(i18n().common.validators.required)
      }),
      {}
    )
  )
}
