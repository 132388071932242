import { Path, Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function BaseBackgroundBottomLeftMobile({ fill, ...restProps }: IconProps): JSX.Element {
  return (
    <Svg
      width="83"
      height="109"
      viewBox="0 0 83 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g opacity="0.05">
        <Path
          d="M15.8055 45.8195C15.7871 45.8861 15.7779 46.0079 15.9111 46.0975C18.2383 47.6504 21.0685 48.4384 24.3237 48.4384C28.2221 48.4384 31.4635 47.2783 33.956 44.9925C36.4761 42.6838 37.7533 39.7502 37.7533 36.2744C37.7533 32.7987 36.7127 29.8881 34.659 27.9768C33.5678 26.9614 32.0929 25.9093 30.2758 24.8548C30.161 24.7882 30.0622 24.8296 30.0139 24.8594C29.9657 24.8893 29.883 24.9628 29.8945 25.0984C30.053 27.2164 30.0851 29.1576 29.9864 30.869C29.9818 30.9425 30.0162 31.016 30.0737 31.0689C31.6542 32.4702 32.2698 33.9703 32.2698 36.4169C32.2698 40.3865 28.8837 43.3798 24.3926 43.3798C23.9469 43.3798 23.5035 43.3569 23.0785 43.3109C23.0142 43.304 22.9545 43.3155 22.904 43.3477C22.8006 43.4097 22.6949 43.474 22.5869 43.5338C20.4482 44.7674 18.2428 45.4749 16.0329 45.6334C15.8744 45.6449 15.8216 45.7552 15.8032 45.8218L15.8055 45.8195Z"
          css={{ fill }}
        />
        <Path
          d="M13.6897 3.71561C11.1696 6.02434 9.89237 8.95791 9.89237 12.4336C9.89237 15.9094 10.933 18.82 12.9868 20.7313C14.0779 21.7467 15.5528 22.7988 17.3699 23.8532C17.4848 23.9199 17.5835 23.8785 17.6318 23.8486C17.68 23.8188 17.7627 23.7453 17.7512 23.6097C17.5927 21.4917 17.5606 19.5505 17.6594 17.8391C17.6639 17.7655 17.6295 17.692 17.5721 17.6392C15.9916 16.2379 15.3759 14.7378 15.3759 12.2912C15.3759 8.32158 18.762 5.32826 23.2531 5.32826C23.6988 5.32826 24.1422 5.35124 24.5672 5.39719C24.5786 5.39719 24.5901 5.39719 24.6016 5.39719C24.6522 5.39719 24.7004 5.38339 24.7417 5.35812C24.8451 5.2961 24.9508 5.23409 25.0588 5.17206C27.1952 3.93844 29.4006 3.23088 31.6128 3.07237C31.7713 3.06089 31.8242 2.95062 31.8425 2.884C31.8609 2.81738 31.8701 2.69563 31.7369 2.60603C29.412 1.0531 26.5818 0.265137 23.3266 0.265137C19.4282 0.265137 16.1868 1.42524 13.6943 3.711L13.6897 3.71561Z"
          css={{ fill }}
        />
        <Path
          d="M46.4208 20.5659C46.4875 20.5475 46.5977 20.4947 46.6092 20.3339C46.7907 17.5427 46.0579 14.6987 44.4314 11.88C42.4834 8.50535 39.8576 6.27702 36.6323 5.25934C33.3725 4.23247 30.1931 4.59315 27.1837 6.32986C24.071 8.12631 22.1734 10.4236 21.544 13.1573C21.2086 14.6114 21.0386 16.4125 21.0317 18.5144C21.0317 18.6477 21.1167 18.712 21.1649 18.7373C21.2155 18.7648 21.3189 18.7993 21.4314 18.7212C23.1865 17.5243 24.852 16.5273 26.382 15.7555C26.4463 15.7233 26.4946 15.6567 26.5106 15.5786C26.9333 13.5088 27.9257 12.2269 30.0438 11.0025C33.4828 9.01764 37.7671 10.4534 40.0138 14.3427C40.2367 14.7286 40.4388 15.1237 40.6111 15.5143C40.6364 15.574 40.6777 15.6176 40.7283 15.6475C40.834 15.7072 40.9419 15.7669 41.0476 15.829C43.1863 17.0626 44.9001 18.6201 46.1429 20.4556C46.2325 20.5889 46.3542 20.5774 46.4208 20.559V20.5659Z"
          css={{ fill }}
        />
        <Path
          d="M11.0157 43.4487C14.2755 44.4756 17.4549 44.115 20.4643 42.3782C23.577 40.5818 25.4746 38.2845 26.104 35.5508C26.4394 34.0967 26.6094 32.2956 26.6163 30.1936C26.6163 30.0604 26.5313 29.9961 26.4831 29.9708C26.4325 29.9432 26.3291 29.9088 26.2166 29.9869C24.4615 31.1837 22.796 32.1808 21.266 32.9526C21.2017 32.9848 21.1535 33.0514 21.1374 33.1295C20.7147 35.1993 19.7223 36.4812 17.6042 37.7056C14.1652 39.6904 9.88088 38.2547 7.63417 34.3654C7.41134 33.9795 7.20919 33.5844 7.0369 33.1938C7.0323 33.1824 7.02771 33.1732 7.02082 33.164C6.99554 33.1203 6.96109 33.0836 6.91744 33.0606C6.81176 33.0009 6.7038 32.9411 6.59812 32.8791C4.46169 31.6455 2.74564 30.088 1.50283 28.2525C1.41324 28.1192 1.29149 28.1307 1.22487 28.1468C1.15825 28.1652 1.04798 28.218 1.03649 28.3788C0.855014 31.17 1.58783 34.014 3.21428 36.8327C5.16234 40.2096 7.78809 42.4357 11.0134 43.4533L11.0157 43.4487Z"
          css={{ fill }}
        />
        <Path
          d="M9.24224 6.67905C9.194 6.63081 9.09063 6.56189 8.9482 6.6331C6.43961 7.87132 4.34452 9.92735 2.71578 12.7461C0.767719 16.1207 0.149753 19.5092 0.882572 22.8103C1.62229 26.1482 3.5244 28.7211 6.53609 30.4578C9.64886 32.2543 12.587 32.7482 15.2679 31.9281C16.6945 31.4916 18.3416 30.7381 20.1633 29.6928C20.2782 29.6262 20.292 29.5206 20.2897 29.4654C20.2897 29.408 20.2667 29.3023 20.1427 29.2426C18.2291 28.3214 16.5337 27.3772 15.1002 26.4377C15.0405 26.3986 14.9578 26.3894 14.8843 26.4147C12.8811 27.0832 11.273 26.8672 9.15495 25.6428C5.71598 23.658 4.81775 19.2289 7.06216 15.3374C7.28499 14.9514 7.52621 14.5793 7.7789 14.2347C7.81795 14.1818 7.83633 14.1244 7.83403 14.067C7.83174 13.9452 7.82944 13.8235 7.82944 13.6994C7.82944 11.2299 8.32105 8.96709 9.29048 6.97308C9.3594 6.83065 9.29049 6.72728 9.24224 6.68134V6.67905Z"
          css={{ fill }}
        />
        <Path
          d="M46.7631 25.8978C46.0234 22.5599 44.1213 19.987 41.1096 18.2503C37.9968 16.4538 35.0587 15.9599 32.3778 16.78C30.9512 17.2165 29.3041 17.97 27.4824 19.0153C27.3675 19.0819 27.3537 19.1875 27.356 19.2427C27.356 19.3001 27.379 19.4058 27.503 19.4655C29.4166 20.3867 31.112 21.3309 32.5455 22.2704C32.6052 22.3095 32.6879 22.3187 32.7614 22.2934C34.7646 21.6249 36.3727 21.8408 38.4908 23.0653C41.9297 25.0501 42.828 29.4792 40.5835 33.3707C40.3607 33.7567 40.1195 34.1288 39.8668 34.4734C39.8599 34.4826 39.853 34.4918 39.8484 34.501C39.8232 34.5446 39.8094 34.5929 39.8117 34.6411C39.814 34.7629 39.8163 34.8846 39.8163 35.0087C39.8163 37.4759 39.3247 39.7387 38.3575 41.735C38.2886 41.8774 38.3575 41.9808 38.4058 42.0267C38.454 42.075 38.5574 42.1439 38.6998 42.0727C41.2084 40.8345 43.3035 38.7784 44.9322 35.9597C46.8803 32.5874 47.4959 29.2012 46.7631 25.8978Z"
          css={{ fill }}
        />
        <Path
          d="M64.2623 107.275C64.2516 107.314 64.2462 107.385 64.3241 107.437C65.6855 108.346 67.3413 108.807 69.2456 108.807C71.5263 108.807 73.4226 108.128 74.8807 106.791C76.355 105.44 77.1023 103.724 77.1023 101.69C77.1023 99.6571 76.4935 97.9544 75.292 96.8362C74.6536 96.2422 73.7908 95.6267 72.7278 95.0098C72.6606 94.9708 72.6028 94.995 72.5745 95.0125C72.5463 95.03 72.4979 95.073 72.5047 95.1523C72.5974 96.3914 72.6162 97.527 72.5584 98.5282C72.5557 98.5712 72.5759 98.6142 72.6095 98.6451C73.5341 99.4649 73.8943 100.343 73.8943 101.774C73.8943 104.096 71.9133 105.847 69.2859 105.847C69.0252 105.847 68.7658 105.834 68.5172 105.807C68.4796 105.803 68.4446 105.81 68.4151 105.828C68.3546 105.865 68.2928 105.902 68.2296 105.937C66.9784 106.659 65.6882 107.073 64.3954 107.166C64.3026 107.172 64.2717 107.237 64.261 107.276L64.2623 107.275Z"
          css={{ fill }}
        />
        <Path
          d="M63.0245 82.6429C61.5502 83.9936 60.803 85.7098 60.803 87.7431C60.803 89.7765 61.4118 91.4793 62.6133 92.5974C63.2517 93.1915 64.1145 93.807 65.1775 94.4239C65.2447 94.4628 65.3025 94.4386 65.3307 94.4211C65.359 94.4037 65.4073 94.3607 65.4006 94.2814C65.3079 93.0423 65.2891 91.9067 65.3469 90.9054C65.3495 90.8624 65.3294 90.8194 65.2958 90.7885C64.3712 89.9687 64.011 89.0911 64.011 87.6598C64.011 85.3375 65.992 83.5864 68.6193 83.5864C68.8801 83.5864 69.1395 83.5998 69.3881 83.6267C69.3948 83.6267 69.4015 83.6267 69.4082 83.6267C69.4378 83.6267 69.466 83.6186 69.4902 83.6038C69.5507 83.5675 69.6125 83.5312 69.6757 83.495C70.9255 82.7733 72.2157 82.3593 73.5099 82.2666C73.6026 82.2599 73.6336 82.1954 73.6443 82.1564C73.6551 82.1174 73.6604 82.0462 73.5825 81.9938C72.2224 81.0853 70.5667 80.6243 68.6623 80.6243C66.3817 80.6243 64.4854 81.303 63.0272 82.6402L63.0245 82.6429Z"
          css={{ fill }}
        />
        <Path
          d="M82.1729 92.5007C82.2119 92.4899 82.2764 92.459 82.2831 92.3649C82.3893 90.732 81.9606 89.0683 81.0091 87.4193C79.8694 85.445 78.3333 84.1414 76.4464 83.546C74.5394 82.9453 72.6794 83.1563 70.9188 84.1723C69.0978 85.2233 67.9877 86.5672 67.6195 88.1665C67.4232 89.0172 67.3238 90.0708 67.3198 91.3005C67.3198 91.3785 67.3695 91.4161 67.3977 91.4309C67.4273 91.447 67.4877 91.4672 67.5536 91.4215C68.5804 90.7213 69.5547 90.138 70.4498 89.6865C70.4874 89.6676 70.5156 89.6287 70.525 89.583C70.7723 88.3721 71.3529 87.6222 72.592 86.9059C74.6039 85.7447 77.1103 86.5847 78.4247 88.86C78.5551 89.0857 78.6733 89.3169 78.7741 89.5453C78.7889 89.5803 78.8131 89.6059 78.8427 89.6233C78.9045 89.6583 78.9676 89.6932 79.0295 89.7295C80.2807 90.4512 81.2832 91.3624 82.0103 92.4362C82.0627 92.5141 82.134 92.5074 82.1729 92.4966V92.5007Z"
          css={{ fill }}
        />
        <Path
          d="M61.4602 105.888C63.3672 106.488 65.2273 106.277 66.9878 105.261C68.8088 104.21 69.9189 102.866 70.2872 101.267C70.4834 100.416 70.5828 99.3628 70.5869 98.1331C70.5869 98.0552 70.5371 98.0175 70.5089 98.0027C70.4793 97.9866 70.4189 97.9665 70.353 98.0121C69.3263 98.7123 68.3519 99.2956 67.4568 99.7472C67.4192 99.766 67.391 99.805 67.3816 99.8506C67.1343 101.062 66.5537 101.811 65.3146 102.528C63.3027 103.689 60.7963 102.849 59.4819 100.574C59.3516 100.348 59.2333 100.117 59.1325 99.8883C59.1298 99.8816 59.1271 99.8762 59.1231 99.8708C59.1083 99.8453 59.0882 99.8238 59.0626 99.8103C59.0008 99.7754 58.9376 99.7404 58.8758 99.7042C57.626 98.9825 56.622 98.0713 55.895 96.9975C55.8426 96.9195 55.7713 96.9263 55.7324 96.9357C55.6934 96.9464 55.6289 96.9773 55.6222 97.0714C55.516 98.7043 55.9447 100.368 56.8962 102.017C58.0359 103.993 59.572 105.295 61.4589 105.89L61.4602 105.888Z"
          css={{ fill }}
        />
        <Path
          d="M60.4227 84.3766C60.3945 84.3484 60.334 84.308 60.2507 84.3497C58.7831 85.0741 57.5574 86.2769 56.6046 87.9259C55.4649 89.9001 55.1034 91.8825 55.5321 93.8137C55.9649 95.7664 57.0776 97.2716 58.8395 98.2877C60.6606 99.3386 62.3795 99.6275 63.9478 99.1478C64.7824 98.8924 65.746 98.4516 66.8118 97.8401C66.879 97.8011 66.887 97.7393 66.8857 97.7071C66.8857 97.6735 66.8722 97.6117 66.7997 97.5767C65.6802 97.0378 64.6883 96.4854 63.8497 95.9358C63.8148 95.9129 63.7664 95.9075 63.7234 95.9223C62.5515 96.3134 61.6107 96.1871 60.3716 95.4708C58.3597 94.3096 57.8343 91.7185 59.1473 89.4419C59.2776 89.2161 59.4188 88.9984 59.5666 88.7968C59.5894 88.7659 59.6002 88.7323 59.5989 88.6987C59.5975 88.6274 59.5962 88.5562 59.5962 88.4836C59.5962 87.0389 59.8838 85.7151 60.4509 84.5486C60.4912 84.4653 60.4509 84.4048 60.4227 84.3779V84.3766Z"
          css={{ fill }}
        />
        <Path
          d="M82.3732 95.6199C81.9404 93.6672 80.8277 92.162 79.0658 91.146C77.2447 90.095 75.5258 89.8061 73.9575 90.2859C73.1229 90.5412 72.1593 90.982 71.0935 91.5935C71.0263 91.6325 71.0183 91.6943 71.0196 91.7266C71.0196 91.7602 71.0331 91.822 71.1056 91.8569C72.2251 92.3959 73.2169 92.9482 74.0556 93.4979C74.0905 93.5207 74.1389 93.5261 74.1819 93.5113C75.3538 93.1202 76.2946 93.2466 77.5337 93.9629C79.5455 95.1241 80.071 97.7151 78.758 99.9918C78.6276 100.218 78.4865 100.435 78.3387 100.637C78.3346 100.642 78.3306 100.648 78.3279 100.653C78.3131 100.679 78.3051 100.707 78.3064 100.735C78.3078 100.806 78.3091 100.877 78.3091 100.95C78.3091 102.393 78.0215 103.717 77.4557 104.885C77.4154 104.968 77.4557 105.029 77.4839 105.056C77.5122 105.084 77.5726 105.124 77.656 105.083C79.1235 104.358 80.3492 103.155 81.3021 101.506C82.4417 99.5335 82.8019 97.5525 82.3732 95.6199Z"
          css={{ fill }}
        />
      </g>
    </Svg>
  )
}
