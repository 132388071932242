import { Path, Rect, Svg } from '@positivote/design-system/icons/Base'

export function FrameSkeleton(): JSX.Element {
  return (
    <Svg
      width="195"
      height="176"
      viewBox="0 0 195 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Rect width="195" height="176" rx="8" css={{ fill: '#F3F4F6' }} />
      <Path
        d="M26.1539 21.1075H26.6419L28.0328 19.7166L28.1011 19.1261C27.3044 18.4228 26.3414 17.9343 25.3034 17.7066C24.2653 17.479 23.1863 17.5197 22.1683 17.825C21.1504 18.1303 20.227 18.6901 19.4856 19.4514C18.7441 20.2128 18.2089 21.1506 17.9307 22.1763C18.0856 22.1128 18.2573 22.1025 18.4187 22.147L21.2004 21.6882C21.2004 21.6882 21.342 21.454 21.4152 21.4686C22.0112 20.814 22.8349 20.412 23.7177 20.3449C24.6005 20.2777 25.4755 20.5506 26.1637 21.1075H26.1539Z"
        css={{ fill: '#EA4335' }}
      />
      <Path
        d="M30.0128 22.1761C29.6931 20.9988 29.0367 19.9405 28.1241 19.1309L26.172 21.083C26.5788 21.4154 26.9049 21.8359 27.1255 22.3127C27.3462 22.7895 27.4557 23.3101 27.4458 23.8354V24.1819C27.6739 24.1819 27.8998 24.2269 28.1106 24.3142C28.3214 24.4015 28.5129 24.5295 28.6743 24.6908C28.8356 24.8521 28.9636 25.0437 29.0509 25.2544C29.1382 25.4652 29.1831 25.6912 29.1831 25.9193C29.1831 26.1475 29.1382 26.3734 29.0509 26.5842C28.9636 26.795 28.8356 26.9865 28.6743 27.1478C28.5129 27.3091 28.3214 27.4371 28.1106 27.5244C27.8998 27.6117 27.6739 27.6567 27.4458 27.6567H23.971L23.6245 28.0081V30.0919L23.971 30.4384H27.4458C28.4161 30.446 29.363 30.141 30.1466 29.5687C30.9302 28.9963 31.5087 28.1869 31.7966 27.2603C32.0845 26.3336 32.0665 25.3389 31.7452 24.4233C31.424 23.5077 30.8165 22.7198 30.0128 22.1761Z"
        css={{ fill: '#4285F4' }}
      />
      <Path
        d="M20.4922 30.4196H23.967V27.6379H20.4922C20.2447 27.6378 20 27.5845 19.7748 27.4817L19.2868 27.633L17.8862 29.0239L17.7642 29.5119C18.5496 30.105 19.508 30.4239 20.4922 30.4196Z"
        css={{ fill: '#34A853' }}
      />
      <Path
        d="M20.4921 21.395C19.5506 21.4007 18.6344 21.7002 17.8714 22.2518C17.1084 22.8034 16.5368 23.5796 16.2363 24.4719C15.9358 25.3641 15.9216 26.328 16.1955 27.2288C16.4694 28.1296 17.0178 28.9223 17.7641 29.4963L19.7796 27.4807C19.5235 27.365 19.2992 27.1889 19.1261 26.9675C18.953 26.746 18.8363 26.4859 18.7858 26.2094C18.7353 25.9329 18.7527 25.6483 18.8364 25.38C18.9201 25.1117 19.0677 24.8677 19.2664 24.6689C19.4652 24.4702 19.7092 24.3226 19.9775 24.2389C20.2458 24.1552 20.5304 24.1379 20.8069 24.1883C21.0834 24.2388 21.3435 24.3556 21.5649 24.5287C21.7864 24.7018 21.9625 24.926 22.0782 25.1821L24.0938 23.1666C23.6707 22.6135 23.1255 22.1658 22.5007 21.8585C21.8759 21.5512 21.1884 21.3926 20.4921 21.395Z"
        css={{ fill: '#FBBC05' }}
      />
      <Rect opacity="0.2" x="36" y="16" width="45" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Rect opacity="0.2" x="16" y="40" width="163" height="16" rx="8" css={{ fill: '#5D6675' }} />
      <Rect opacity="0.5" x="16" y="68" width="37.75" height="8" rx="4" css={{ fill: '#2196F3' }} />
      <Rect
        opacity="0.5"
        x="57.75"
        y="68"
        width="37.75"
        height="8"
        rx="4"
        css={{ fill: '#2196F3' }}
      />
      <Rect
        opacity="0.5"
        x="99.5"
        y="68"
        width="37.75"
        height="8"
        rx="4"
        css={{ fill: '#2196F3' }}
      />
      <Rect
        opacity="0.5"
        x="141.25"
        y="68"
        width="37.75"
        height="8"
        rx="4"
        css={{ fill: '#2196F3' }}
      />
      <Rect x="16" y="88" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
      <Rect x="73" y="88" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
      <Rect x="130" y="88" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
      <Rect x="16" y="128" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
      <Rect x="73" y="128" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
      <Rect x="130" y="128" width="49" height="32" rx="16" css={{ fill: '#DEE4ED' }} />
    </Svg>
  )
}
