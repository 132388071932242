import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'

import { HubLabDesktopAppCard } from './Desktop'
import { HubLabMobileAppCard } from './Mobile'
import { HubLabAppCardProps } from './styles'

export function HubLabAppCard({
  applicationIdLoading,
  ...restProps
}: HubLabAppCardProps): JSX.Element {
  const { breakpoint } = useTheme()

  const cardProps = {
    enableOptions: [],
    canClick: !applicationIdLoading,
    applicationIdLoading,
    ...restProps
  }

  return breakpoint <= Breakpoint.sm ? (
    <HubLabMobileAppCard {...cardProps} />
  ) : (
    <HubLabDesktopAppCard {...cardProps} />
  )
}
